import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import TablesPaginatedRoomsField from './TablesFormPaginatedRoomsField';
import TablesFormPaginatedDevicesField from './TablesFormPaginatedDevicesField';

import styles from './TablesForm.scss';
import classNames from 'classnames';
import TextField from '../Fields/TextField';
import NumberField from '../Fields/NumberField';

const Form = ({
  model,
  submitTitle,
  disabled,
  currentTastingRoom,
  setCurrentTastingRoom,
  currentWarehouse,
  setCurrentWarehouse,
  currentDevice,
  setCurrentDevice,
  onSubmit,
}) => {
  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        id: model?.id,
        ...values,
        device_id: currentDevice?.id,
        tasting_room_id: currentTastingRoom?.id,
      });
    },
    [onSubmit, currentDevice, currentTastingRoom]
  );

  const [warehouseTouched, setWarehouseTouched] = useState(false);

  const handleWarehouseChange = e => {
    setCurrentWarehouse(e);
    setCurrentDevice(null);
    setCurrentTastingRoom(null);
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="6">
          <TextField
            label="Title"
            name="title"
            placeholder="Title"
            errorMessage="Enter Title"
            isRequired
          />
        </Col>
        <Col md="6">
          <NumberField
            name="seats"
            label="Seats"
            errorMessage="Enter Seats"
            isRequired
          />
        </Col>

        <Col lg="12">
          <FormGroup>
            <Label required htmlFor="warehouse">
              Warehouse
            </Label>

            <WarehousesPaginatedField
              value={currentWarehouse}
              isMulti={false}
              onBlur={() => setWarehouseTouched(true)}
              onChange={handleWarehouseChange}
              classNames={classNames(
                warehouseTouched && !currentWarehouse && styles.error
              )}
              error={warehouseTouched && !currentWarehouse && 'Enter warehouse'}
            />
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup>
            <Label htmlFor="device_id">Device</Label>
            <TablesFormPaginatedDevicesField
              value={currentDevice}
              onChange={setCurrentDevice}
              currentWarehouse={currentWarehouse}
              isDisabled={!currentWarehouse?.value}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label required htmlFor="tasting_room_id">
              Room
            </Label>
            <TablesPaginatedRoomsField
              value={currentTastingRoom}
              onChange={setCurrentTastingRoom}
              currentWarehouse={currentWarehouse}
              isDisabled={!currentWarehouse?.value}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  currentTastingRoom: PropTypes.any,
  setCurrentTastingRoom: PropTypes.func,
  currentWarehouse: PropTypes.object,
  setCurrentWarehouse: PropTypes.func,
  currentDevice: PropTypes.any,
  setCurrentDevice: PropTypes.func,
};

export default Form;
