import { createSelector } from 'reselect';

export const rootSelector = state => state.importCustomers;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const importIsPendingSelector = createSelector(
  rootSelector,
  ({ importIsPending }) => importIsPending
);

export const importsSelector = createSelector(
  rootSelector,
  ({ imports }) => imports
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const isUploadModalOpenedSelector = createSelector(
  rootSelector,
  ({ isUploadModalOpened }) => {
    return isUploadModalOpened;
  }
);
