import { createSlice } from 'redux-starter-kit';

import actionTypes from 'utils/actionTypes';

const exciseSlice = createSlice({
  name: 'excise',
  initialState: {
    isPending: false,
    pagination: {},
    exciseLicenses: [],
    exciseLicense: {},
    exciseReturns: [],
    exciseReturn: {},
    isLoadingSummary: false,
    exciseReturnSummary: {},
  },
  reducers: {
    fetchExciseLicenses: state => {
      state.isPending = true;
    },
    fetchExciseLicensesSuccess(state, { payload }) {
      state.isPending = false;
      state.exciseLicenses = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchExciseLicensesFailure: state => {
      state.isPending = false;
    },
    fetchExciseLicense: state => {
      state.isPending = true;
      state.exciseLicense = {};
      state.exciseReturnSummary = {};
    },
    fetchExciseLicenseSuccess(state, { payload }) {
      state.isPending = false;
      state.exciseLicense = payload.data;
    },
    fetchExciseLicenseFailure: state => {
      state.isPending = false;
    },
    updateExciseLicense: state => {
      state.isPending = true;
    },
    updateExciseLicenseSuccess(state, { payload }) {
      state.isPending = false;
      state.exciseLicense = payload.data;
    },
    updateExciseLicenseFailure: state => {
      state.isPending = false;
    },
    createExciseLicense: state => {
      state.isPending = true;
      state.exciseReturnSummary = {};
    },
    createExciseLicenseSuccess: state => {
      state.isPending = false;
    },
    createExciseLicenseFailure: state => {
      state.isPending = false;
    },
    fetchExciseReturn(state) {
      state.isPending = true;
    },
    fetchExciseReturnSuccess(state, { payload }) {
      state.isPending = false;
      state.exciseReturn = payload.data;
    },
    fetchExciseReturnFailure(state) {
      state.isPending = false;
    },
    fetchExciseReturns: state => {
      state.isPending = true;
    },
    fetchExciseReturnsSuccess(state, { payload }) {
      state.isPending = false;
      state.exciseReturns = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchExciseReturnsFailure: state => {
      state.isPending = false;
    },
    createExciseReturn(state) {
      state.isPending = true;
    },
    createExciseReturnSuccess(state) {
      state.isPending = false;
    },
    createExciseReturnFailure(state) {
      state.isPending = false;
    },
    fetchExciseReturnSummary(state) {
      state.isLoadingSummary = true;
    },
    fetchExciseReturnSummarySuccess(state, { payload }) {
      state.isLoadingSummary = false;
      state.exciseReturnSummary = payload.data;
    },
    fetchExciseReturnSummaryFailure(state) {
      state.isLoadingSummary = false;
    },
  },
});

export const actions = actionTypes(exciseSlice.actions);

export default exciseSlice.reducer;
