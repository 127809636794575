import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './UserForm.scss';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import RoleCard from './RoleCard';
import { RolesOptions } from '../RolesField/RolesField';
import getUID from 'utils/getUID';
import ManagerFields from './ManagerFields';
import HostFields from './HostFields';
import useSelector from 'hooks/useSelector';
import {
  rolesSelector,
  userPinPendingSelector,
  userPinSelector,
} from 'models/user/selectors';
import useAction from 'hooks/useAction';
import { actions } from 'models/user/slice';
import FakePasswordField from '../Fields/FakePasswordField';
import TextField from '../Fields/TextField';
import EmailField from '../Fields/EmailField';
import CheckboxField from '../Fields/CheckboxField';
import SalesRepCommission from './SalesRepCommission';
import { showErrorMessage } from 'utils/notification';

import { getCommissionInitialData, getPreparedCommissions } from './utis';

const getSelectedValues = data => data.map(({ value }) => value);

const getDefaultRoles = roles => {
  return roles.reduce((previous, current) => {
    const id = getUID();
    return { ...previous, [id]: { value: current } };
  }, {});
};

const UserForm = ({ defaultValues, onSubmit, isOwnerOrManager }) => {
  const generatePin = useAction(actions.generatePin);
  const resetPin = useAction(actions.resetPin);
  const pin = useSelector(userPinSelector);
  const pinPending = useSelector(userPinPendingSelector);
  const [pinValue, setPinValue] = useState(defaultValues?.pin ?? '');
  const [selectedBrands, setSelectedBrands] = useState(
    defaultValues?.brands ?? []
  );
  const [selectedRoles, setSelectedRoles] = useState(
    getDefaultRoles(defaultValues?.roles ?? [])
  );
  const [commissions, setCommissions] = useState(
    getCommissionInitialData(defaultValues)
  );
  const [email, setEmail] = useState('');

  const currentRoles = useSelector(rolesSelector);

  const selectedRolesValues = useMemo(
    () =>
      Object.values(selectedRoles)
        .map(({ value }) => value)
        .filter(value => !!value),
    [selectedRoles]
  );

  const availableRoles = useMemo(
    () =>
      RolesOptions.filter(role => {
        const isSelectedRole = selectedRolesValues?.includes(role.value);
        if (role.value === 'owner' || role.value === 'bookkeeper') {
          return !isSelectedRole && currentRoles?.includes('owner');
        }
        if (role.value === 'sales_rep') {
          return selectedRolesValues?.length === 0;
        }
        return !isSelectedRole;
      }),
    [selectedRolesValues]
  );
  const firstDropdownAvailableRoles = RolesOptions;

  const selectedBrandsValues = useMemo(
    () => getSelectedValues(selectedBrands),
    [selectedBrands]
  );
  const isHost = selectedRolesValues?.includes('host');
  const isSalesRep = selectedRolesValues?.includes('sales_rep');

  const validateFormValues = () => {
    if (isHost && selectedBrands?.length === 0) {
      showErrorMessage('Error', 'Host does not have any brands.');
      return false;
    }
    if (selectedRolesValues?.length === 0) {
      showErrorMessage('Error', 'User must have at least one role.');
      return false;
    }

    if (!isSalesRep && (pinValue?.length !== 4 || pinPending)) {
      showErrorMessage('Error', 'Pin code must be 4 digits.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    resetPin();
  }, []);

  useEffect(() => {
    setPinValue(pin);
  }, [pin]);

  useEffect(() => {
    setPinValue(defaultValues?.pin);
  }, [defaultValues]);

  const handleSubmit = useCallback(
    (_, data) => {
      if (validateFormValues()) {
        const isPasswordChanged =
          data?.password !== '' && !data?.password?.includes('●');

        onSubmit?.({
          ...data,
          ...(isSalesRep ? getPreparedCommissions(commissions) : {}),
          active_brand_ids: isHost ? selectedBrandsValues : null,
          roles: selectedRolesValues,
          is_bookkeeper: selectedRolesValues?.includes('bookkeeper'),
          password: isPasswordChanged ? data.password : undefined,
          pin: pinValue,
        });
      }
    },
    [
      selectedRolesValues,
      selectedBrandsValues,
      pinValue,
      commissions,
      isSalesRep,
    ]
  );

  const handleAddRole = useCallback(() => {
    const id = getUID();
    setSelectedRoles(prevState => ({
      ...prevState,
      [id]: { value: null, new: true },
    }));
  }, []);

  const handleSelectRole = useCallback(
    (id, value) => {
      setSelectedRoles(prevState => {
        if (value === 'sales_rep') {
          return {
            [id]: { ...prevState[id], value },
          };
        }
        return {
          ...prevState,
          [id]: { ...prevState[id], value },
        };
      });
    },
    [selectedRoles]
  );

  const handleDeleteRole = useCallback(
    id => {
      const copiedRoles = { ...selectedRoles };
      delete copiedRoles[id];
      setSelectedRoles(copiedRoles);
    },
    [selectedRoles]
  );

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const onGeneratePin = useCallback(() => {
    generatePin();
  }, [generatePin]);

  const handlePinCodeChange = useCallback(event => {
    const { value } = event.target;
    setPinValue(value.substring(0, 4));
  }, []);

  return (
    <AvForm onValidSubmit={handleSubmit} model={defaultValues}>
      <Row>
        <Col md={3}>
          <TextField
            errorMessage="Enter First Name"
            isRequired
            name="first_name"
            label="First Name"
          />
        </Col>
        <Col md={3}>
          <TextField
            errorMessage="Enter Last Name"
            isRequired
            name="last_name"
            label="Last Name"
          />
        </Col>
        <Col md={3}>
          <EmailField
            name="email"
            isRequired
            label="Email"
            errorMessage="Enter Email"
            value={email}
            onChange={handleEmailChange}
          />
        </Col>
        {isOwnerOrManager && !selectedRolesValues?.includes('owner') && (
          <Col md={3}>
            <FakePasswordField
              name="password"
              isFilled={defaultValues?.confirmed_at}
            />
          </Col>
        )}
        <Col md={3}>
          <FormGroup className={styles.pinWrapper}>
            <Label htmlFor="pin">Pin Code</Label>
            <Input
              name="pin"
              type="number"
              placeholder="Generate Pin Code"
              id="pin"
              value={pinValue}
              onFocus={e =>
                e.target.addEventListener(
                  'wheel',
                  function(evt) {
                    evt.preventDefault();
                  },
                  { passive: false }
                )
              }
              onChange={handlePinCodeChange}
              errorMessage="Enter Pin Code"
              className={styles.field}
            />
            <button
              onClick={onGeneratePin}
              type="button"
              className={styles.pinButton}
            >
              <i className="ri-qr-code-line font-size-20" />
            </button>
          </FormGroup>
        </Col>
      </Row>
      {isSalesRep && (
        <Row>
          <Col md={3}>
            <CheckboxField
              name="can_edit_default_warehouse"
              label="Edit default warehouse"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Roles</h4>

              {Object.entries(selectedRoles).map(([key, roleItem], index) => (
                <RoleCard
                  key={key}
                  roles={
                    index === 0 ? firstDropdownAvailableRoles : availableRoles
                  }
                  onChange={role => handleSelectRole(key, role)}
                  onDelete={() => handleDeleteRole(key)}
                  defaultValue={RolesOptions.find(
                    role => roleItem.value === role?.value
                  )}
                  isEditable={roleItem?.new}
                  content={
                    <>
                      {roleItem?.value === 'host' && (
                        <HostFields
                          name="host.is_host_active"
                          selectedBrands={selectedBrands}
                          setSelectedBrands={setSelectedBrands}
                          isEditable={!!defaultValues}
                        />
                      )}
                      {roleItem?.value === 'manager' && (
                        <ManagerFields isEditable={!!defaultValues} />
                      )}
                    </>
                  }
                />
              ))}

              <Button
                onClick={handleAddRole}
                type="button"
                className="mt-4"
                color="success"
                disabled={!availableRoles?.length || isSalesRep}
              >
                Add Role
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isSalesRep && commissions ? (
        <SalesRepCommission
          onChange={setCommissions}
          defaultValues={commissions}
        />
      ) : null}
      <Button className="mt-3" color="primary" type="submit">
        Save
      </Button>
    </AvForm>
  );
};

UserForm.propTypes = {
  defaultValues: PropTypes.object,
  isOwnerOrManager: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default memo(UserForm);
