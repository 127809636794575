import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import styles from './AcquirersForm.scss';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import TextField from '../Fields/TextField';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import CheckboxField from '../Fields/CheckboxField';

const TYPES = [
  { value: 'stripe', label: 'Stripe' },
  { value: 'stripe_connect', label: 'Stripe Connect' },
];

const ACCOUNT_TYPES = [
  { value: 'standard', label: 'Standard' },
  { value: 'express', label: 'Express' },
];

const Form = ({
  submitTitle,
  disabled,
  currentBrand,
  setCurrentBrand,
  onSubmit,
}) => {
  const [resourcetype, setResourcetype] = useState(TYPES[0]);
  const [account_type, setAccountType] = useState(ACCOUNT_TYPES[0].value);

  const isStripeConnect = resourcetype?.value === 'stripe_connect';
  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        ...values,
        brand_id: currentBrand,
        resourcetype: resourcetype?.value,
        stripe_account_type: account_type,
      });
    },
    [onSubmit, currentBrand, resourcetype]
  );

  return (
    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="resourcetype">Type</Label>
            <Select
              options={TYPES}
              onChange={data => setResourcetype(data)}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
              defaultValue={resourcetype}
              styles={selectStyles}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <TextField
            isRequired
            name="title"
            label="Title"
            errorMessage="Enter Title"
            placeholder="Title"
          />
        </Col>
        {!isStripeConnect && (
          <>
            <Col lg="6">
              <TextField
                isRequired
                name="public_key"
                label="Public Key"
                errorMessage="Enter Public Key"
                placeholder="Public Key"
              />
            </Col>
            <Col lg="6">
              <TextField
                isRequired
                name="private_key"
                label="Private Key"
                errorMessage="Enter Private Key"
                placeholder="Private Key"
              />
            </Col>
          </>
        )}
        {isStripeConnect && (
          <Col lg="6">
            <FormGroup>
              <Label required htmlFor="stripe_account_type">
                Stripe Account Type
              </Label>
              <Select
                options={ACCOUNT_TYPES}
                onChange={data => setAccountType(data.apiValue)}
                getOptionValue={option => option.apiValue}
                getOptionLabel={option => option.userValue}
                defaultValue={account_type}
                styles={selectStyles}
              />
            </FormGroup>
          </Col>
        )}
        <Col lg="6">
          <FormGroup>
            <Label required htmlFor="brand_id">
              Brand
            </Label>
            <BrandsPaginatedField
              defaultValue={currentBrand}
              onChange={data => setCurrentBrand(data.value)}
              isMulti={false}
            />
          </FormGroup>
        </Col>

        <Col md="6" className={styles.checkbox}>
          <div className="custom-control custom-checkbox">
            <CheckboxField name="is_active" label="Is Active?" />
          </div>
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  currentBrand: PropTypes.any,
  setCurrentBrand: PropTypes.func,
};

export default Form;
