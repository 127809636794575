import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as organizationsActions } from 'models/organizations/slice';
import { organizationSelector } from 'models/organizations/selectors';
import NotePopup from 'components/Popup/NotePopup';
import { HUMAN_ACTIVITY_TYPES } from 'constants/organizations';

const prepareValue = value => value ?? '-';

const ActivityTable = ({ activities }) => {
  // This function gets the current time and formats it as HH:mm.
  const getCurrentTimeFormatted = () => {
    const currentTime = new Date();
    const hours = currentTime
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = currentTime
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`; // Returns the formatted time string.
  };
  const [openModal, setOpenModal] = useState(false);
  const [timeValue, setTimeValue] = useState(getCurrentTimeFormatted());
  const [dateValue, setDateValue] = useState('');
  const [activityType, setActivityType] = useState({
    label: 'Choose type',
    value: '',
  });
  const organization = useSelector(organizationSelector);
  const addActivityNote = useAction(
    organizationsActions.addOrganizationActivity
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmitActivity = (event, data) => {
    // Convert to UTC format
    const dateTime = new Date(`${dateValue}T${timeValue}`);
    const utcDateTime = dateTime.toISOString();
    addActivityNote({
      id: organization.id,
      type: activityType,
      activity_date: utcDateTime,
      ...data,
    });
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" bordered responsive>
          <thead>
            <tr>
              <th data-priority="1">ID</th>
              <th data-priority="1">Date</th>
              <th data-priority="1">Type</th>
              <th width="65%">Comments</th>
            </tr>
          </thead>
          <tbody>
            {activities.map(activity => (
              <tr key={`${activity.id}`}>
                <td>{prepareValue(activity?.id)}</td>
                <td>
                  {format(
                    new Date(activity?.activity_date),
                    'yyyy-MM-dd, h:mm aaa'
                  )}
                </td>
                <td>{prepareValue(HUMAN_ACTIVITY_TYPES[activity?.type])}</td>
                <td>{prepareValue(activity?.text)}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <Button
                  color="success"
                  type="button"
                  onClick={handleOpenModal}
                  className="waves-effect waves-light text-nowrap d-flex align-items-center"
                >
                  <i className="ri-sticky-note-line mr-2" /> Add Activity
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        {openModal && (
          <NotePopup
            onClose={handleCloseModal}
            handleSubmit={handleSubmitActivity}
            header="Add Activity"
            placeholder="Comment"
            name="comment"
            timeValue={timeValue}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setTimeValue={setTimeValue}
            activityType={activityType}
            setActivityType={setActivityType}
            isActivity
          />
        )}
      </div>
    </div>
  );
};

ActivityTable.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default ActivityTable;
