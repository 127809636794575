import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as exciseActions } from 'models/excise/slice';
import { AvForm } from 'availity-reactstrap-validation';
import {
  DATE_FIELDS,
  INITIAL_STATE,
  DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_SPIRITS,
  DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE,
  EXCISE_WAREHOUSE_TABLE_COLUMNS_SPIRITS,
  EXCISE_WAREHOUSE_TABLE_COLUMNS_WINE,
  COLUMNS_SPIRITS,
  COLUMNS_WINE,
  ADDITIONS_TO_INVENTORY_SPIRITS_COLS,
  ADDITIONS_TO_INVENTORY_WINE_COLS,
  REDUCTIONS_TO_INVENTORY_SPIRITS_NON_DUTY_COLS,
  REDUCTIONS_TO_INVENTORY_SPIRITS_DUTY_COLS,
  REDUCTIONS_TO_INVENTORY_WINE_NON_DUTY_COLS,
  REDUCTIONS_TO_INVENTORY_WINE_DUTY_COLS,
} from './constants';
import PropTypes from 'prop-types';
import { Button, Col, Row, Alert } from 'reactstrap';
import styles from 'pages/ExciseReturn/ExciseReturn.scss';
import NumberField from 'components/Fields/NumberField';
import {
  DateFields,
  MultipleFieldRow,
  renderTitleFields,
  TotalRow,
} from 'utils/exciseReturns';
import { exciseReturnsSelector } from 'models/excise/selectors';
import formatPrice from 'utils/formatPrice';

const WarehouseForm = ({
  model,
  onSubmit,
  disabled,
  submitTitle,
  exciseLicense,
}) => {
  let formRef = useRef(null);

  const fetchExciseReturns = useAction(exciseActions.fetchExciseReturns);
  const fetchExciseReturnSummary = useAction(
    exciseActions.fetchExciseReturnSummary
  );
  const exciseReturnsList = useSelector(exciseReturnsSelector);

  const [state, setState] = useState(!model ? INITIAL_STATE : model);
  const [spiritsState, setSpiritsState] = useState(
    DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_SPIRITS
  );
  const [wineState, setWineState] = useState(
    DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE
  );
  const [dateState, setDateState] = useState({
    period_start: model?.period_start || '',
    period_end: model?.period_end || '',
    due_date: model?.due_date || '',
  });
  const [showForm, setShowForm] = useState(!!model);

  const setVariable = (key, value) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };
  const setSpiritsVariable = (key, value) => {
    setSpiritsState(prevState => ({ ...prevState, [key]: value }));
  };
  const setWineVariable = (key, value) => {
    setWineState(prevState => ({ ...prevState, [key]: value }));
  };

  const validateDateFields = () => {
    const { period_start, period_end, due_date } = dateState;
    const errors = {};

    if (Date.parse(period_start) >= Date.parse(period_end)) {
      errors.period_start = 'Start date must be before end date.';
      errors.period_end = 'End date must be after start date.';
    }

    if (Date.parse(period_end) >= Date.parse(due_date)) {
      errors.period_end = 'End date must be before due date.';
      errors.due_date = 'Due date must be after end date.';
    }

    return errors;
  };

  const handleChangeDates = useCallback(
    e => {
      const { name, value } = e.target;
      setDateState(prevDateState => ({
        ...prevDateState,
        [name]: value,
      }));

      const { period_start, period_end, due_date } = {
        ...dateState,
        [name]: value,
      };

      // Perform the date validation
      const isStartBeforeEndDate =
        period_start &&
        period_end &&
        Date.parse(period_start) < Date.parse(period_end);
      const areDatesValid = isStartBeforeEndDate;
      setShowForm(areDatesValid);

      // Check if either start date or end date has changed
      const isStartDateChanged =
        name === 'period_start' && value !== dateState.period_start;
      const isEndDateChanged =
        name === 'period_end' && value !== dateState.period_end;

      // Proceed with fetching summary if dates are valid
      if (
        (areDatesValid && exciseLicense && !due_date) ||
        (isStartDateChanged && period_end) ||
        (isEndDateChanged && period_start)
      ) {
        fetchExciseReturnSummary({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
        });
        fetchExciseReturns({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
          page: 1,
          per_page: 999,
        });
      }
    },
    [dateState, exciseLicense, fetchExciseReturnSummary]
  );

  const handleSubmit = useCallback(
    (_, values) => {
      const payload = {
        ...values,
        ...dateState,
      };
      onSubmit({
        id: model?.id,
        excise_license_id: exciseLicense.id,
        license_type: exciseLicense.license_type,
        ...payload,
      });
    },
    [onSubmit, model, dateState, exciseLicense]
  );

  const calculateValues = () => {
    const formValues = formRef.getValues();
    calculateSpiritsValues(formValues);
    calculateWineValues(formValues);
  };

  const calculateSpiritsValues = formValues => {
    const spiritsMoreThanSevenPercentReturnDetailTotalAdditions =
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .domestic_non_duty_paid_packaged
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .imported_non_duty_paid_packaged
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .returned_from_duty_paid_sources
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .returned_from_non_duty_paid_sources
      );

    // Not more than 7% return detail
    // Total additions
    const spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions =
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .domestic_non_duty_paid_packaged
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .imported_non_duty_paid_packaged
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .returned_from_duty_paid_sources
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .returned_from_non_duty_paid_sources
      );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_additions',
      parseFloat(spiritsMoreThanSevenPercentReturnDetailTotalAdditions).toFixed(
        2
      )
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_additions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions
      ).toFixed(2)
    );

    // More than 7% return detail
    // Non-duty reductions

    const spiritsMoreThanSevenPercentReturnDetailNonDutyReductions =
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_registered_users
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_accredited_representatives
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_duty_free_shops
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_as_ships_stores
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_licensed_users_domestic
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_licensed_users_imported
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_delivery_to_other_excise_warehouses
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .exported_spirits
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .returned_to_spirits_licensee
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail.breakage
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .spirits_removed_for_other_purposes_non_duty
      );

    // More than 7% return detail
    // Duty reductions

    const spiritsMoreThanSevenPercentReturnDetailDutyReductions =
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .packaged_excluding_marked_special_containers
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .packaged_in_marked_special_containers
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .spirits_removed_for_other_purposes_duty
      );

    // Not more than 7% return detail
    // Non-duty reductions
    const spiritsNotMoreThanSevenPercentReturnDetailNonDutyReductions =
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_registered_users
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_accredited_representatives
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_duty_free_shops
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_as_ships_stores
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_licensed_users_domestic
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_licensed_users_imported
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_delivery_to_other_excise_warehouses
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .exported_spirits
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .returned_to_spirits_licensee
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail.breakage
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .spirits_removed_for_other_purposes_non_duty
      );

    // Not More than 7% return detail
    // Duty reductions

    const spiritsNotMoreThanSevenPercentReturnDetailDutyReductions =
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .packaged_excluding_marked_special_containers
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .packaged_in_marked_special_containers
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .spirits_removed_for_other_purposes_duty
      );

    const spiritsNotMoreThanSevenPercentReturnDetailTotalReductions =
      spiritsNotMoreThanSevenPercentReturnDetailNonDutyReductions +
      spiritsNotMoreThanSevenPercentReturnDetailDutyReductions;

    const spiritsMoreThanSevenPercentReturnDetailTotalReductions =
      spiritsMoreThanSevenPercentReturnDetailNonDutyReductions +
      spiritsMoreThanSevenPercentReturnDetailDutyReductions;

    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_non_duty_paid_reductions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailNonDutyReductions
      ).toFixed(2)
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_duty_paid_reductions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailDutyReductions
      ).toFixed(2)
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_non_duty_paid_reductions',
      parseFloat(
        spiritsMoreThanSevenPercentReturnDetailNonDutyReductions
      ).toFixed(2)
    );
    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_duty_paid_reductions',
      parseFloat(spiritsMoreThanSevenPercentReturnDetailDutyReductions).toFixed(
        2
      )
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_reductions',
      parseFloat(
        spiritsMoreThanSevenPercentReturnDetailTotalReductions
      ).toFixed(2)
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_reductions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailTotalReductions
      ).toFixed(2)
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_not_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_not_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_not_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_not_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_quantity',
      parseFloat(
        Number(formValues.spirits_more_than_seven_percent_quantity)
      ).toFixed(2)
    );

    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_quantity',
      parseFloat(
        Number(formValues.spirits_not_more_than_seven_percent_quantity)
      ).toFixed(2)
    );

    setVariable(
      'spirits_delivered_to_licensed_users_quantity',
      parseFloat(
        Number(formValues.spirits_delivered_to_licensed_users_quantity)
      ).toFixed(2)
    );

    // Spirits duty payable
    setVariable(
      'spirits_more_than_seven_percent_return_detail_duty_payable',
      +(
        state.spirits_more_than_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.spirit_duty_rate_over_7 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_duty_payable',
      +(
        state.spirits_not_more_than_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.spirit_duty_rate_over_7 || 0)
      ).toFixed(2) || '0.00'
    );
    setVariable(
      'spirits_delivered_to_licensed_users_duty_payable',
      +(
        state.spirits_delivered_to_licensed_users_quantity *
        parseFloat(exciseLicense.spirit_duty_rate_special || 0)
      ).toFixed(2) || '0.00'
    );
  };

  const calculateWineValues = formValues => {
    const wineSevenPercentReturnDetailTotalAdditions =
      Number(formValues.wine_seven_percent_return_detail.domestic_wine) +
      Number(formValues.wine_seven_percent_return_detail.imported_wine) +
      Number(
        formValues.wine_seven_percent_return_detail
          .returned_from_duty_paid_sources
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .returned_from_non_duty_paid_sources
      );

    const wineOnePercentReturnDetailTotalAdditions =
      Number(formValues.wine_one_percent_return_detail.domestic_wine) +
      Number(formValues.wine_one_percent_return_detail.imported_wine) +
      Number(
        formValues.wine_one_percent_return_detail
          .returned_from_duty_paid_sources
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .returned_from_non_duty_paid_sources
      );

    const wineZeroPercentReturnDetailTotalAdditions =
      Number(formValues.wine_zero_percent_return_detail.domestic_wine) +
      Number(formValues.wine_zero_percent_return_detail.imported_wine) +
      Number(
        formValues.wine_zero_percent_return_detail
          .returned_from_duty_paid_sources
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .returned_from_non_duty_paid_sources
      );

    setVariable(
      'wine_seven_percent_return_detail_total_additions',
      parseFloat(wineSevenPercentReturnDetailTotalAdditions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_additions',
      parseFloat(wineOnePercentReturnDetailTotalAdditions).toFixed(2)
    );
    setVariable(
      'wine_zero_percent_return_detail_total_additions',
      parseFloat(wineZeroPercentReturnDetailTotalAdditions).toFixed(2)
    );

    const wineSevenPercentReturnDetailNonDutyReductions =
      Number(
        formValues.wine_seven_percent_return_detail
          .removed_for_delivery_to_accredited_representatives
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .removed_for_delivery_to_duty_free_shops
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .removed_for_delivery_as_ships_stores
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .removed_for_delivery_to_licensed_users
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .removed_for_delivery_to_other_excise_warehouses
      ) +
      Number(formValues.wine_seven_percent_return_detail.exported_wine) +
      Number(
        formValues.wine_seven_percent_return_detail.returned_to_wine_licensee
      ) +
      Number(formValues.wine_seven_percent_return_detail.breakage) +
      Number(
        formValues.wine_seven_percent_return_detail
          .wine_removed_for_other_purposes_non_duty
      );

    const wineOnePercentReturnDetailNonDutyReductions =
      Number(
        formValues.wine_one_percent_return_detail
          .removed_for_delivery_to_accredited_representatives
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .removed_for_delivery_to_duty_free_shops
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .removed_for_delivery_as_ships_stores
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .removed_for_delivery_to_licensed_users
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .removed_for_delivery_to_other_excise_warehouses
      ) +
      Number(formValues.wine_one_percent_return_detail.exported_wine) +
      Number(
        formValues.wine_one_percent_return_detail.returned_to_wine_licensee
      ) +
      Number(formValues.wine_one_percent_return_detail.breakage) +
      Number(
        formValues.wine_one_percent_return_detail
          .wine_removed_for_other_purposes_non_duty
      );

    const wineZeroPercentReturnDetailNonDutyReductions =
      Number(
        formValues.wine_zero_percent_return_detail
          .removed_for_delivery_to_accredited_representatives
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .removed_for_delivery_to_duty_free_shops
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .removed_for_delivery_as_ships_stores
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .removed_for_delivery_to_licensed_users
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .removed_for_delivery_to_other_excise_warehouses
      ) +
      Number(formValues.wine_zero_percent_return_detail.exported_wine) +
      Number(
        formValues.wine_zero_percent_return_detail.returned_to_wine_licensee
      ) +
      Number(formValues.wine_zero_percent_return_detail.breakage) +
      Number(
        formValues.wine_zero_percent_return_detail
          .wine_removed_for_other_purposes_non_duty
      );

    // WINE
    // Duty reductions
    const wineSevenPercentReturnDetailDutyReductions =
      Number(
        formValues.wine_seven_percent_return_detail
          .packaged_excluding_marked_special_containers
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .packaged_in_marked_special_containers
      ) +
      Number(
        formValues.wine_seven_percent_return_detail
          .wine_removed_for_other_purposes_duty
      );

    const wineOnePercentReturnDetailDutyReductions =
      Number(
        formValues.wine_one_percent_return_detail
          .packaged_excluding_marked_special_containers
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .packaged_in_marked_special_containers
      ) +
      Number(
        formValues.wine_one_percent_return_detail
          .wine_removed_for_other_purposes_duty
      );

    const wineZeroPercentReturnDetailDutyReductions =
      Number(
        formValues.wine_zero_percent_return_detail
          .packaged_excluding_marked_special_containers
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .packaged_in_marked_special_containers
      ) +
      Number(
        formValues.wine_zero_percent_return_detail
          .wine_removed_for_other_purposes_duty
      );

    const wineSevenPercentReturnDetailTotalReductions =
      wineSevenPercentReturnDetailNonDutyReductions +
      wineSevenPercentReturnDetailDutyReductions;

    const wineOnePercentReturnDetailTotalReductions =
      wineOnePercentReturnDetailNonDutyReductions +
      wineOnePercentReturnDetailDutyReductions;

    const wineZeroPercentReturnDetailTotalReductions =
      wineZeroPercentReturnDetailNonDutyReductions +
      wineZeroPercentReturnDetailDutyReductions;

    setVariable(
      'wine_seven_percent_return_detail_total_non_duty_paid_reductions',
      parseFloat(wineSevenPercentReturnDetailNonDutyReductions).toFixed(2)
    );
    setVariable(
      'wine_seven_percent_return_detail_total_duty_paid_reductions',
      parseFloat(wineSevenPercentReturnDetailDutyReductions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_non_duty_paid_reductions',
      parseFloat(wineOnePercentReturnDetailNonDutyReductions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_duty_paid_reductions',
      parseFloat(wineOnePercentReturnDetailDutyReductions).toFixed(2)
    );
    setVariable(
      'wine_zero_percent_return_detail_total_non_duty_paid_reductions',
      parseFloat(wineZeroPercentReturnDetailNonDutyReductions).toFixed(2)
    );
    setVariable(
      'wine_zero_percent_return_detail_total_duty_paid_reductions',
      parseFloat(wineZeroPercentReturnDetailDutyReductions).toFixed(2)
    );

    setVariable(
      'wine_seven_percent_return_detail_total_reductions',
      parseFloat(wineSevenPercentReturnDetailTotalReductions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_reductions',
      parseFloat(wineOnePercentReturnDetailTotalReductions).toFixed(2)
    );

    setVariable(
      'wine_zero_percent_return_detail_total_reductions',
      parseFloat(wineZeroPercentReturnDetailTotalReductions).toFixed(2)
    );

    setVariable(
      'wine_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_seven_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_seven_percent_return_detail.inventory_adjustment
          ) +
          Number(wineSevenPercentReturnDetailTotalAdditions) -
          Number(wineSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_one_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_one_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_one_percent_return_detail.inventory_adjustment
          ) +
          Number(wineOnePercentReturnDetailTotalAdditions) -
          Number(wineOnePercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_zero_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_zero_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_zero_percent_return_detail.inventory_adjustment
          ) +
          Number(wineZeroPercentReturnDetailTotalAdditions) -
          Number(wineZeroPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_seven_percent_return_detail_quantity',
      parseFloat(Number(formValues.wine_seven_percent_quantity)).toFixed(2)
    );

    setVariable(
      'wine_one_percent_return_detail_quantity',
      parseFloat(Number(formValues.wine_one_percent_quantity)).toFixed(2)
    );

    setVariable(
      'wine_zero_percent_return_detail_quantity',
      parseFloat(Number(formValues.wine_zero_percent_quantity)).toFixed(2)
    );

    setVariable(
      'wine_seven_percent_return_detail_duty_payable',
      +(
        state.wine_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_7 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_one_percent_return_detail_duty_payable',
      +(
        state.wine_one_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_1 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_zero_percent_return_detail_duty_payable',
      +(
        state.wine_zero_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_0 || 0)
      ).toFixed(2) || '0.00'
    );
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      ref={node => {
        formRef = node;
      }}
    >
      <Row>
        <Col md={2} />
        <DateFields
          fields={DATE_FIELDS}
          disabled={disabled}
          validateDateFields={validateDateFields}
          onInput={handleChangeDates}
        />
      </Row>
      {!!model && exciseReturnsList?.length > 0 && (
        <Alert color="warning">
          <div>
            <i className="mdi mdi-information" />{' '}
            <b>
              There are one or more existing returns that overlap the selected
              period.
            </b>
            <p>
              Please make sure the selected period start and period end dates
              are correct.
            </p>
            <br />
            {exciseReturnsList?.map(item => (
              <div>
                <Link
                  to={`/reports/excise/license/${item.excise_license_id}/return/warehouse/${item.id}`}
                >
                  Excise return #{item.id} from {item.period_start} to{' '}
                  {item.period_end}
                </Link>
              </div>
            ))}
          </div>
        </Alert>
      )}
      <div
        className={classNames(styles.paddedRow, {
          [styles.show]: showForm,
          [styles.hide]: !showForm,
        })}
      >
        {/* ========== WINE ========== */}
        <Row className={styles.paddedRow}>
          <Col md={2}>
            <h4 className="card-title">WINE</h4>
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} />
          {renderTitleFields(EXCISE_WAREHOUSE_TABLE_COLUMNS_WINE)}
        </Row>
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_WINE}
          state={state}
          title="Opening Inventory"
          name="opening_inventory"
          min="0"
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to inventory</h4>
          </Col>
        </Row>
        {ADDITIONS_TO_INVENTORY_WINE_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_WINE}
            onBlur={calculateValues}
            state={wineState}
            setVariable={setWineVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Total Additions"
          name="total_additions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">
              Reductions to inventory (non-duty paid)
            </h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_WINE_NON_DUTY_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_WINE}
            onBlur={calculateValues}
            setVariable={setWineVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Total non-duty paid reductions"
          name="total_non_duty_paid_reductions"
          disabled
        />
        <Col md={2} className={styles.right}>
          <h4 className="card-title">Reductions to inventory (duty paid)</h4>
        </Col>
        {REDUCTIONS_TO_INVENTORY_WINE_DUTY_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_WINE}
            onBlur={calculateValues}
            setVariable={setWineVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Total duty paid reductions"
          name="total_duty_paid_reductions"
          disabled
        />
        <TotalRow
          fields={COLUMNS_WINE}
          setVariable={setWineState}
          state={state}
          title="Total Reductions"
          name="total_reductions"
          disabled
        />
        <TotalRow
          fields={COLUMNS_WINE}
          onBlur={calculateValues}
          setVariable={setVariable}
          state={state}
          title="Inventory Adjustment"
          name="inventory_adjustment"
        />
        <TotalRow
          fields={COLUMNS_WINE}
          setVariable={setWineState}
          state={state}
          title="Closing Inventory"
          name="closing_inventory"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Duty Payable</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_seven_percent_quantity"
              value={state.wine_seven_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              onBlur={calculateValues}
              disabled={disabled}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_one_percent_quantity"
              value={state.wine_one_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_one_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_zero_percent_quantity"
              value={state.wine_zero_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_zero_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_seven_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_one_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_one_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_zero_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_zero_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        {/* ========== SPIRITS ========== */}
        <Row>
          <Col md={2}>
            <h4 className="card-title">SPIRITS</h4>
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} />
          {renderTitleFields(EXCISE_WAREHOUSE_TABLE_COLUMNS_SPIRITS)}
        </Row>
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Opening Inventory"
          name="opening_inventory"
          min="0"
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to inventory</h4>
          </Col>
        </Row>
        {ADDITIONS_TO_INVENTORY_SPIRITS_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_SPIRITS}
            onBlur={calculateValues}
            state={spiritsState}
            setVariable={setSpiritsVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Total Additions"
          name="total_additions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">
              Reductions to inventory (non-duty paid)
            </h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_SPIRITS_NON_DUTY_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_SPIRITS}
            onBlur={calculateValues}
            setVariable={setSpiritsVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Total non-duty paid reductions"
          name="total_non_duty_paid_reductions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Reductions to inventory (duty paid)</h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_SPIRITS_DUTY_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_SPIRITS}
            onBlur={calculateValues}
            setVariable={setSpiritsVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_SPIRITS}
          setVariable={setSpiritsVariable}
          state={state}
          title="Total duty paid reductions"
          name="total_duty_paid_reductions"
          disabled
        />
        <TotalRow
          fields={COLUMNS_SPIRITS}
          setVariable={setSpiritsVariable}
          state={state}
          title="Total Reductions"
          name="total_reductions"
          disabled
        />
        <TotalRow
          fields={COLUMNS_SPIRITS}
          onBlur={calculateValues}
          setVariable={setVariable}
          state={state}
          title="Inventory Adjustment"
          name="inventory_adjustment"
        />
        <TotalRow
          fields={COLUMNS_SPIRITS}
          setVariable={setSpiritsVariable}
          state={state}
          title="Closing Inventory"
          name="closing_inventory"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Spirits delivered to licensed users</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_delivered_to_licensed_users_quantity"
              value={state.spirits_delivered_to_licensed_users_quantity}
              onChange={event => {
                setVariable(
                  'spirits_delivered_to_licensed_users_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_delivered_to_licensed_users_duty_payable"
              value={formatPrice(
                Number(
                  state.spirits_delivered_to_licensed_users_duty_payable ||
                    '0.00'
                )
              )}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Duty Payable</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_more_than_seven_percent_quantity"
              value={
                state.spirits_more_than_seven_percent_return_detail_quantity
              }
              onChange={event => {
                setVariable(
                  'spirits_more_than_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_not_more_than_seven_percent_quantity"
              value={
                state.spirits_not_more_than_seven_percent_return_detail_quantity
              }
              onChange={event => {
                setVariable(
                  'spirits_not_more_than_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_more_than_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.spirits_more_than_seven_percent_return_detail_duty_payable ||
                    '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_not_more_than_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.spirits_not_more_than_seven_percent_return_detail_duty_payable ||
                    '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </div>
    </AvForm>
  );
};

WarehouseForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  exciseLicense: PropTypes.object,
};

export default WarehouseForm;
