import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './CommentPopup.scss';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PopupBase from '../PopupBase';
import classNames from 'classnames';
import TextareaField from '../../Fields/TextareaField';

const CommentPopup = ({ onClose, onSubmit, title }) => {
  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        <AvForm className="needs-validation" onValidSubmit={onSubmit}>
          <TextareaField
            name="comment"
            label="Comment"
            placeholder="Comment"
            errorMessage="Enter comment"
            className={classNames(styles.textarea, 'form-control')}
            isRequired
          />
          <div className="d-flex justify-content-end">
            <Button className="mr-2" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Confirm
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </PopupBase>
  );
};

CommentPopup.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CommentPopup.defaultProps = {
  title: 'Comment',
};

export default memo(CommentPopup);
