export const customStyles = {
  control: provided => {
    return {
      ...provided,
      borderColor: '#ccc',
      backgroundColor: '#fff',
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      color: '#333',
    };
  },
};
