import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PopupBase from '../PopupBase';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import styles from './SendBillPopup.scss';
import Select from 'react-select';
import EmailField from '../../Fields/EmailField';

const getEmails = order => {
  const options = [];

  if (order?.customer?.email) {
    options.push({
      value: order?.customer?.email,
      label: order?.customer?.email,
    });
  }

  if (order?.organization?.contact_person?.email) {
    options.push({
      value: order?.organization?.contact_person?.email,
      label: order?.organization?.contact_person?.email,
    });
  }

  if (order?.shipping_info?.email) {
    options.push({
      value: order?.shipping_info?.email,
      label: order?.shipping_info?.email,
    });
  }

  return options;
};

const SendBillPopup = ({ onClose, order, onSubmit }) => {
  const emailOptions = useMemo(() => getEmails(order), [order]);
  const [selectedEmail, setSelectedEmail] = useState(emailOptions[0]?.value);
  const shippingEmail = order?.shipping_info?.email;
  const customerEmail = order?.customer?.email;
  const organizationEmail = order?.organization?.contact_person?.email;
  const isDisabled =
    (shippingEmail &&
      (shippingEmail === organizationEmail ||
        shippingEmail === customerEmail)) ||
    !shippingEmail;

  const handleSubmit = (event, values) => {
    if (values.customEmail || selectedEmail) {
      onSubmit({
        id: order?.id,
        email: values?.customEmail.toLowerCase() || selectedEmail.toLowerCase(),
      });
    }
  };

  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <AvForm onValidSubmit={handleSubmit}>
        <Row md="12">
          <Col md="6">
            <FormGroup>
              <Label>Select Email</Label>
              <Select
                isDisabled={isDisabled}
                name="selectedEmail"
                defaultValue={emailOptions[0]}
                options={emailOptions}
                onChange={data => setSelectedEmail(data?.value)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <EmailField name="customEmail" label="Custom Email" />
          </Col>
        </Row>
        <Button className="d-block ml-auto mt-2" color="primary">
          Resend receipt
        </Button>
      </AvForm>
    </PopupBase>
  );
};

SendBillPopup.propTypes = {
  onClose: PropTypes.func,
  order: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default memo(SendBillPopup);
