import { createSelector } from 'reselect';

export const rootSelector = state => state.hosts;

export const hostsSelector = createSelector(rootSelector, ({ hosts }) => hosts);
export const hostSelector = createSelector(rootSelector, ({ host }) => host);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);
