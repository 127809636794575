import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkCell = ({ id, field, link }) => (
  <td>
    <Link className="text-dark font-weight-bold" to={`${link}${id}`}>
      {field}
    </Link>
  </td>
);

LinkCell.propTypes = {
  id: PropTypes.number,
  field: PropTypes.string,
  link: PropTypes.string,
};

export default LinkCell;
