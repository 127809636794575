import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import { REFUND_ITEM_TYPES } from 'constants';

const ItemStateValue = {
  [REFUND_ITEM_TYPES.RETURN]: 'Return',
  [REFUND_ITEM_TYPES.VOID]: 'Void',
};

const SwitchToggle = ({ onChange, id }) => {
  const [state, setState] = useState(REFUND_ITEM_TYPES.RETURN);

  const onChangeHandler = () => {
    const currentState = (state + 1) % 3;
    setState(currentState);
    onChange?.(currentState);
  };

  return (
    <>
      {state === REFUND_ITEM_TYPES.NOT_CHOSEN ? (
        <CustomInput
          type="checkbox"
          id={`product${id}`}
          name={`product${id}`}
          onChange={onChangeHandler}
        />
      ) : (
        <>
          <CustomInput
            id={`${id}+${Math.random()}`}
            onChange={onChangeHandler}
            type="switch"
          />
          <span>{ItemStateValue[state]}</span>
        </>
      )}
    </>
  );
};

SwitchToggle.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.number,
};

export default SwitchToggle;
