import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import TextField from '../Fields/TextField/TextField';
import styles from './BrandsDataSourcesForm.scss';
import CheckboxField from '../Fields/CheckboxField/CheckboxField';

const SettingsForm = ({ onSubmit, model }) => {
  return (
    <AvForm onValidSubmit={onSubmit} model={model}>
      <div className="d-flex justify-content-between align-items-end mb-4">
        <div className={styles.subHeading}>Settings</div>
        <Button color="primary" type="submit">
          Save
        </Button>
      </div>
      <Row>
        <Col md="4">
          <TextField
            name="wine_direct_username"
            value={model?.wine_direct_username}
            label="Username"
            placeholder="Username"
          />
        </Col>
        <Col md="4">
          <TextField
            name="wine_direct_password"
            value={model?.wine_direct_password}
            label="Password"
            type="password"
            placeholder="Password"
          />
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <TextField
            name="password_reset_url"
            value={model?.password_reset_url}
            label="Password Reset URL"
            placeholder="Password Reset URL"
          />
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <CheckboxField
            name="sync_products"
            checked={model?.sync_products}
            label="Import/Export Products"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
          <CheckboxField
            name="sync_customers"
            checked={model?.sync_customers}
            label="Import Customers"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
          <CheckboxField
            name="export_customers"
            checked={model?.export_customers}
            label="Export Customers"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
          <CheckboxField
            name="sync_orders"
            checked={model?.sync_orders}
            label="Import Orders"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
          <CheckboxField
            name="sync_wineclubs"
            checked={model?.sync_wineclubs}
            label="Import Wine Clubs"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
        </Col>
      </Row>
    </AvForm>
  );
};

SettingsForm.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
};

export default SettingsForm;
