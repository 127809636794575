import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as wineDirectSyncActions } from 'models/wineDirectSync/slice';
import {
  wineDirectSyncSettingsSelector,
  wineDirectSyncsSelector,
} from 'models/wineDirectSync/selectors';

import PaginationTable from '../PaginationTable';
import styles from './BrandsDataSourcesForm.scss';
import { WINE_DIRECT_STATUSES } from 'constants';
import SettingsForm from './SettingsForm';

const BrandsDataSourcesForm = () => {
  // Hooks
  const { brandID } = useParams();

  // Vars
  const brandId = parseInt(brandID, 10);

  // Actions
  const createWineDirectSyncSettings = useAction(
    wineDirectSyncActions.createWineDirectSyncSettings
  );
  const updateWineDirectSyncSettings = useAction(
    wineDirectSyncActions.updateWineDirectSyncSettings
  );
  const fetchWineDirectSettingsSync = useAction(
    wineDirectSyncActions.fetchWineDirectSyncSettings
  );
  const wineDirectSyncNow = useAction(wineDirectSyncActions.wineDirectSyncNow);
  const fetchWineDirectSyncs = useAction(
    wineDirectSyncActions.fetchWineDirectSyncs
  );

  // Selectors
  const wineDirectSyncSettings = useSelector(wineDirectSyncSettingsSelector);
  const wineDirectSyncs = useSelector(wineDirectSyncsSelector);

  // Local States
  const [currentPage, setCurrentPage] = useState(
    wineDirectSyncs?.pagination?.current_page
  );

  // Effects
  useEffect(() => {
    fetchWineDirectSettingsSync(brandId);
  }, [fetchWineDirectSettingsSync, brandId, updateWineDirectSyncSettings]);

  useEffect(() => {
    fetchWineDirectSyncs({
      brand_id: brandId,
      page: currentPage,
      per_page: 10,
    });
  }, [fetchWineDirectSyncs, brandId, currentPage]);

  const handleSubmit = (_, settings) => {
    if (!passwordChanged(settings.wine_direct_password)) {
      delete settings.wine_direct_password;
    }

    if (wineDirectSyncSettings?.wine_direct_username) {
      updateWineDirectSyncSettings({
        settings_id: wineDirectSyncSettings.id,
        ...settings,
      });
    } else {
      createWineDirectSyncSettings({
        brand_id: brandId,
        ...settings,
      });
    }
  };

  const handleSync = () => {
    wineDirectSyncNow({
      brand_id: brandId,
    });
  };

  // Utilities
  const passwordChanged = newPassword => {
    const oldPassword = wineDirectSyncSettings?.wine_direct_password;
    return oldPassword !== newPassword;
  };

  const isObjectEmpty = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const formatErrors = errors => {
    if (isObjectEmpty(errors)) {
      return 'No errors';
    }

    const formattedErrors = Object.entries(errors).map(
      ([key, value]) => `${key}: ${value}`
    );

    return formattedErrors.join(',\n');
  };

  const getRowErrorClass = item => {
    if (
      item.import_items_with_errors ||
      item.export_items_with_errors ||
      (item.errors && !isObjectEmpty(item.errors))
    ) {
      return styles.warning;
    }
    if (item.status === WINE_DIRECT_STATUSES?.FAILED) {
      return styles.danger;
    }
    return '';
  };

  return (
    <>
      <SettingsForm model={wineDirectSyncSettings} onSubmit={handleSubmit} />
      <PaginationTable
        setCurrentPage={setCurrentPage}
        pagination={wineDirectSyncs?.pagination}
      >
        <div className="d-flex justify-content-between align-items-end mb-4">
          <div className={styles.subHeading}>History</div>
          <Button type="button" color="primary" onClick={handleSync}>
            Sync Now
          </Button>
        </div>
        <Card>
          <CardBody className={styles.table}>
            <Table bordered responsive className="table-centered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Import Summary</th>
                  <th>Export Summary</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody>
                {wineDirectSyncs?.results?.map(item => (
                  <tr key={item.id} className={getRowErrorClass(item)}>
                    <td>
                      {format(
                        Date.parse(item.created_at),
                        'yyyy-MM-dd, h:mm aaa'
                      )}
                    </td>
                    <td>{item.type}</td>
                    <td>{item.status}</td>
                    <td>
                      Processed: {item.total_import_items_processed} <br />
                      Updated: {item.import_items_updated} <br />
                      Created: {item.import_items_created} <br />
                      Errors: {item.import_items_with_errors}
                    </td>
                    <td>
                      Processed: {item.total_export_items_processed} <br />
                      Updated: {item.export_items_updated} <br />
                      Created: {item.export_items_created} <br />
                      Errors: {item.export_items_with_errors}
                    </td>
                    <td>{formatErrors(item.errors)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </PaginationTable>
    </>
  );
};

export default BrandsDataSourcesForm;
