import { createSelector } from 'reselect';
import { denormalize } from 'utils/normalizeById';

export const rootSelector = state => state.productsPresets;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const productsPresetSelector = createSelector(
  rootSelector,
  ({ productsPreset }) => productsPreset
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const productsPresetsSelector = createSelector(
  rootSelector,
  ({ productsPresets }) => denormalize(productsPresets)
);
