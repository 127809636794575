import React from 'react';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import { Button, CustomInput } from 'reactstrap';
import SwitchToggle from './Switch';
import styles from './ProductRow.scss';
import classNames from 'classnames';
import { REFUND_ITEM_TYPES } from 'constants';

const ProductRow = ({
  title,
  number,
  total_price,
  resourcetype,
  selectedCount,
  backToInventoryCount,
  onChange,
  disabled,
}) => {
  const onChangeHandler = (value, isDefault = false) => {
    if (isDefault) {
      const selectedNumber = +value * number;
      onChange?.({
        number: selectedNumber,
        back_to_inventory_number:
          resourcetype === 'ProductOrderItem' ? selectedNumber : 0,
      });
      return;
    }
    // eslint-disable-next-line no-nested-ternary
    const addedNumber = value !== REFUND_ITEM_TYPES.VOID ? (value ? 1 : -1) : 0;
    const addedInventoryNumber = value === REFUND_ITEM_TYPES.VOID ? -1 : value;
    onChange?.({
      number: selectedCount + addedNumber,
      back_to_inventory_number: backToInventoryCount + addedInventoryNumber,
    });
  };

  return (
    <>
      {selectedCount === null && (
        <tr className={classNames(disabled && styles.disabledContainer)}>
          <td className={classNames(styles.col, styles.cellCheckbox)}>
            {!!onChange && (
              <div className={styles.checkbox}>
                <CustomInput
                  type="checkbox"
                  id={title}
                  onChange={onChange}
                  disabled={disabled}
                />
              </div>
            )}
          </td>
          <td>{title}</td>
          <td />
          <td>${formatPrice(total_price)}</td>
        </tr>
      )}
      {selectedCount === 0 && (
        <tr className={classNames(disabled && styles.disabledContainer)}>
          <td className={classNames(styles.col, styles.cellCheckbox)}>
            <div className={styles.checkbox}>
              <CustomInput
                disabled={disabled}
                type="checkbox"
                checked={disabled}
                id={`${title}-${Math.random()}`}
                onChange={event => onChangeHandler(event.target.checked, true)}
              />
            </div>
          </td>
          <td>
            <p>{title}</p>
            {resourcetype === 'ProductSampleOrderItem' && (
              <p className={styles.tag}>SAMPLE</p>
            )}
          </td>
          <td>{number}</td>
          <td>${formatPrice(+total_price)}</td>
        </tr>
      )}
      {selectedCount > 0 &&
        Array.from(Array(number).keys()).map(index => (
          <tr
            key={index}
            className={classNames(disabled && styles.disabledContainer)}
          >
            <td>
              <div className={classNames(styles.checkbox)}>
                {index === 0 && (
                  <Button
                    close
                    className={styles.button}
                    disabled={disabled}
                    onClick={() => onChange({ number: 0 })}
                  />
                )}
                {resourcetype === 'ProductOrderItem' ? (
                  <SwitchToggle
                    id={index}
                    disabled={disabled}
                    onChange={onChangeHandler}
                  />
                ) : (
                  <CustomInput
                    defaultChecked
                    type="checkbox"
                    id={`${title}-${index}-${Math.random()}`}
                    disabled={disabled}
                    onChange={event => onChangeHandler(event.target.checked)}
                  />
                )}
              </div>
            </td>
            <td>
              <p>{title}</p>
              {resourcetype === 'ProductSampleOrderItem' && (
                <p className={styles.tag}>SAMPLE</p>
              )}
            </td>
            <td>1</td>
            <td>${formatPrice(total_price / number)}</td>
          </tr>
        ))}
    </>
  );
};

ProductRow.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
  total_price: PropTypes.number.isRequired,
  resourcetype: PropTypes.string,
  selectedCount: PropTypes.number,
  backToInventoryCount: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ProductRow;
