import React from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import { isPendingSelector } from 'models/products/selectors';
import useSelector from 'hooks/useSelector';
import Preloader from 'components/Preloader';
import ProductCategoryForm from 'components/ProductCategoryForm';

const CreateMerchCategory = ({ title }) => {
  const createCategory = useAction(productsActions.createProductCategory);
  const pending = useSelector(isPendingSelector);

  const handleSubmit = values => {
    createCategory({
      ...values,
      parent: 'Merchandise',
      redirectUrl: '/merchandise_categories/',
    });
  };

  if (pending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new merch category"
        breadcrumbItems={[
          {
            title: 'Back to categories',
            link: '/merchandise_categories/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ProductCategoryForm onSubmit={handleSubmit} submitTitle="Save" />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateMerchCategory.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CreateMerchCategory;
