import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Filter.scss';
import { Button, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from '../../../components/PaginatedFields/WarehousesPaginatedField';
import Select from 'react-select';
import {
  perPagePagination,
  TRANSFER_TYPES,
  WRITE_OFF_REASON_TYPES,
} from 'constants';
import { selectStyles } from '../../../constants/selectStyles';
import { getQuery } from '../../../utils/getQuery';
import { getInitialOption } from '../../../utils/getInitialOption';
import useQuery from '../../../hooks/useQuery';
import SearchField from '../../../components/SearchField';
import DateRangePicker from 'components/DateRangePicker';
import { Link } from 'react-router-dom';
import ProductsPaginatedField from '../../../components/PaginatedFields/ProductsPaginatedField';
import { formatDate } from 'utils/formatDate';
import { getEndDate, getStartDate } from 'utils/date';

const PER_PAGE_OPTIONS = perPagePagination.map(per => ({
  value: per,
  label: per,
}));

const TYPES_TRANSFER_OPTIONS = [
  { value: '', label: 'All types of movement' },
  ...TRANSFER_TYPES,
];

const WRITE_OFF_REASONS = [
  { value: '', label: 'Any reason' },
  ...Object.keys(WRITE_OFF_REASON_TYPES).map(reason => ({
    value: reason,
    label: WRITE_OFF_REASON_TYPES[reason],
  })),
];

const getQueryProducts = (ids, titles) => {
  const arrIds = ids?.split(',');
  const arrTitles = titles?.split(',');

  return (arrIds || []).map((id, idx) => ({
    value: id,
    label: arrTitles?.[idx],
  }));
};

const Filter = ({ filter, setFilter, onExport, isPending }) => {
  const query = useQuery();
  const [dateRange, setDateRange] = useState({
    startDate: query.get('date_range_start'),
    endDate: query.get('date_range_end'),
  });
  const [brand, setBrand] = useState(getQuery('brand', 'All brands'));
  const [sender, setSender] = useState(getQuery('sender', 'All senders'));
  const [receiver, setReceiver] = useState(
    getQuery('receiver', 'All receivers')
  );
  const [perPage, setPerPage] = useState(
    getInitialOption(query, 'per_page', PER_PAGE_OPTIONS)
  );
  const [type, setType] = useState(
    getInitialOption(query, 'type', TYPES_TRANSFER_OPTIONS)
  );
  const [reason, setReason] = useState(
    getInitialOption(query, 'write_off_reasons', WRITE_OFF_REASONS)
  );
  const [search, setSearch] = useState(query.get('title_contains') || '');
  const [selectedProducts, setSelectedProducts] = useState(
    getQueryProducts(query.get('product_ids'), query.get('product_titles'))
  );

  const handleDateChange = value => {
    setDateRange({
      startDate: formatDate(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: formatDate(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    setFilter({
      ...filter,
      date_range_start: startDate,
      date_range_end: endDate,
      brand_id: brand?.value,
      brand_title: brand?.label,
      sender_id: sender?.id,
      sender_title: sender?.label,
      receiver_id: receiver?.id,
      receiver_title: receiver?.label,
      per_page: perPage?.value,
      type: type?.value === 'AdjustmentTransfer' ? null : type.value,
      is_adjustment_transfer:
        type?.value && type?.value === 'AdjustmentTransfer',
      title_contains: search,
      product_ids: selectedProducts?.map(({ value }) => value),
      product_titles: selectedProducts?.map(({ label }) => label),
      write_off_reasons: reason?.value,
    });
  }, [
    dateRange,
    brand,
    sender,
    receiver,
    perPage,
    type,
    selectedProducts,
    reason,
    search,
  ]);

  return (
    <Row md="12" className="mb-3">
      <Col md="12" className="d-flex mb-3">
        <div className={classNames(styles.small, 'mr-1')}>
          <Select
            className="w-100"
            options={PER_PAGE_OPTIONS}
            value={perPage}
            onChange={setPerPage}
            styles={selectStyles}
          />
        </div>
        <div className={styles.w300}>
          <DateRangePicker
            placement="bottom-start"
            startDate={Date.parse(query.get('date_range_start'))}
            endDate={Date.parse(query.get('date_range_end'))}
            onDateChange={handleDateChange}
          />
        </div>
      </Col>
      <Col md="12" className="d-flex mb-3">
        <div className={classNames(styles.normal, 'mr-1')}>
          <BrandsPaginatedField
            isInitialLabel
            name="brand_id"
            value={brand}
            onChange={setBrand}
            isMulti={false}
          />
        </div>
        <div className={classNames(styles.normal, 'mr-1')}>
          <WarehousesPaginatedField
            value={sender}
            parameters={{ brand_id: brand?.value }}
            isMulti={false}
            onChange={setSender}
            isInitialLabel
            initialLabel="All senders"
          />
        </div>
        <div className={classNames(styles.normal, 'mr-1')}>
          <WarehousesPaginatedField
            value={receiver}
            parameters={{ brand_id: brand?.value }}
            isMulti={false}
            onChange={setReceiver}
            isInitialLabel
            initialLabel="All receivers"
          />
        </div>
        <div className={classNames(styles.normal, 'mr-1')}>
          <Select
            className="w-100"
            options={TYPES_TRANSFER_OPTIONS}
            value={type}
            onChange={setType}
            styles={selectStyles}
          />
        </div>
        {type?.value === 'WriteOffTransfer' && (
          <div className={classNames(styles.normal, 'mr-1')}>
            <Select
              className="w-100"
              options={WRITE_OFF_REASONS}
              value={reason}
              onChange={setReason}
              styles={selectStyles}
            />
          </div>
        )}
        <div className={classNames(styles.large, 'mr-1')}>
          <ProductsPaginatedField
            className="w-100"
            defaultValue={selectedProducts}
            placeholder="Select products..."
            onChange={setSelectedProducts}
            withSKU
            isMulti
            options={{ brand_id: brand?.value }}
          />
        </div>
      </Col>
      <Col md="12" className="d-flex">
        <SearchField
          value={search}
          setValue={setSearch}
          className="mr-3"
          placeholder="Search... (By transfer ID, invoice number)"
        />
        <Button
          type="button"
          color="secondary"
          onClick={onExport}
          disabled={isPending}
          className="waves-effect waves-light text-nowrap mr-3"
        >
          <i className="ri-download-line align-middle mr-2" />
          <span>Export to Excel</span>
        </Button>
        <Link to="/transfers/create">
          <Button
            color="success"
            type="button"
            className="waves-effect waves-light mr-1 text-nowrap"
          >
            <i className="ri-add-line align-middle mr-2" /> Create
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  onExport: PropTypes.func,
  isPending: PropTypes.bool,
};

export default Filter;
