import React, { useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import CardItem from 'components/CardItem';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/formatDate';
import CommissionsTable from './CommissionsTable';
import CommissionActions from './CommissionActions';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { COMMISSIONS_STATUSES } from 'constants/commissions';

import { actions as commissionsActions } from 'models/commissions/slice';
import {
  hasOwnerOrManagerRoleSelector,
  rolesSelector,
} from 'models/user/selectors';
import { currentCommissionSelector } from 'models/commissions/selectors';
import { PERMISSION_TYPES } from 'constants/permissions';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { useParams } from 'react-router-dom';

const CommissionsShow = ({ title }) => {
  const { id } = useParams();

  const fetchCommissionById = useAction(commissionsActions.fetchCommissionById);
  const updateCommission = useAction(commissionsActions.updateCommission);

  const currentCommission = useSelector(currentCommissionSelector);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const roles = useSelector(rolesSelector);

  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);

  const onUpdateHandler = data => {
    updateCommission({ id, ...data });
  };

  useEffect(() => {
    if (id) {
      fetchCommissionById(id);
    }
  }, [id]);

  if (!currentCommission) {
    return <></>;
  }

  const { status, ordersInfo } = currentCommission;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          { title: 'Back to commissions', link: '/reports/commissions' },
        ]}
      />
      <Row className="mb-3">
        <Col className="d-flex justify-content-end" md={12}>
          <CommissionActions
            status={status}
            isSalesRep={isSalesRep}
            isOwner={isOwnerOrManagerRole}
            onSubmit={onUpdateHandler}
          />
        </Col>
      </Row>
      <Row>
        {currentCommission?.commissions_report_id && (
          <Col md={3}>
            <CardItem
              title="Report ID"
              value={currentCommission.commissions_report_id}
            />
          </Col>
        )}
        <Col md={3}>
          <CardItem
            title="Commission Total"
            value={`$${currentCommission.commissions_total}`}
          />
        </Col>
        <Col md={3}>
          <CardItem
            className="text-capitalize"
            title="GST(5%)"
            value={`$${(
              (parseFloat(currentCommission.commissions_total) * 5) /
              100
            ).toFixed(2)}`}
          />
        </Col>
        <Col md={3}>
          <CardItem
            className="text-capitalize"
            title="Total"
            value={`$${(
              parseFloat(currentCommission.commissions_total) +
              parseFloat(
                (parseFloat(currentCommission.commissions_total) * 5) / 100
              )
            ).toFixed(2)}`}
          />
        </Col>
        <Col md={3}>
          <CardItem
            title="Period Start"
            value={formatDate(currentCommission.period_start, 'yyyy-MM-dd')}
          />
        </Col>
        <Col md={3}>
          <CardItem
            title="Period End"
            value={formatDate(currentCommission.period_end, 'yyyy-MM-dd')}
          />
        </Col>
        <Col md={3}>
          <CardItem
            className="text-capitalize"
            title="Status"
            value={COMMISSIONS_STATUSES?.[status]}
          />
        </Col>

        {currentCommission?.approved_at && (
          <Col md={3}>
            <CardItem
              title="Approved at"
              value={formatDate(currentCommission.approved_at, 'yyyy-MM-dd')}
            />
          </Col>
        )}
        {currentCommission?.approved_by && (
          <Col md={3}>
            <CardItem
              title="Approved by"
              value={currentCommission.approved_by}
            />
          </Col>
        )}
        {currentCommission?.accepted_at && (
          <Col md={3}>
            <CardItem
              title="Accepted at"
              value={formatDate(currentCommission.accepted_at, 'yyyy-MM-dd')}
            />
          </Col>
        )}
      </Row>
      <Row>
        {currentCommission?.dispute_comment && (
          <Col md={6}>
            <CardItem
              title="Dispute Comment"
              value={currentCommission.dispute_comment}
            />
          </Col>
        )}
        {currentCommission?.archive_comment && (
          <Col md={6}>
            <CardItem
              title="Archive Comment"
              value={currentCommission.archive_comment}
            />
          </Col>
        )}
      </Row>
      {ordersInfo && (
        <Row>
          <Col md={12}>
            <CommissionsTable orderList={ordersInfo} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

CommissionsShow.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CommissionsShow;
