export const formatPhoneNumber = phone => {
  if (!phone) return '';

  const cleaned = `${phone}`.replace(/\D/g, '');

  let localNumber = cleaned;

  if (cleaned.startsWith('1') && cleaned.length === 11) {
    localNumber = cleaned.slice(1);
  }

  const match = localNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return cleaned.startsWith('1')
      ? `+1 (${match[1]}) ${match[2]}-${match[3]}`
      : `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phone;
};
