export const getAvailableCasesCount = (productCount, productInCase) => {
  if (productInCase === 0) {
    return 0;
  }
  return Math.ceil(productCount / productInCase);
};

export const getCasePrice = (productPrice, productInCase) => {
  return productPrice * productInCase;
};

export const getTableHeadList = isCases => {
  const heads = ['Product', 'Quantity', 'Stock'];
  const headsWithCases = [
    'Product',
    'Case Size',
    'Stock',
    'Cases Available',
    'Cases To Order',
    'Bottles To Order',
    'Bottle Price',
  ];

  const headsList = isCases ? headsWithCases : heads;
  return [
    ...headsList.map(item => ({ label: item, priority: 1 })),
    { label: isCases ? 'Case Price' : 'Price' },
    ...(isCases ? [] : [{ label: 'Discount %' }, { label: 'Discount $' }]),
    { label: 'Total Price' },
  ];
};
