import React, { useEffect, useRef, useState } from 'react';
import useAction from 'hooks/useAction';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import styles from './ImageForm.scss';
import { actions as productsActions } from 'models/products/slice';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ImageCard from './ImageCard';
import useSelector from 'hooks/useSelector';
import { imageFetchingSuccessSelector } from 'models/products/selectors';

const ImageForm = ({ images, productId, type }) => {
  const [image, setLogo] = useState(null);
  const [imageError, setError] = useState(null);
  const imageRef = useRef(null);

  const createImage = useAction(productsActions.createProductImage);
  const setImages = useAction(productsActions.setImages);
  const swapProductImages = useAction(productsActions.swapProductImages);
  const patchImage = useAction(productsActions.patchProductImage);
  const deleteImage = useAction(productsActions.deleteProductImage);

  const imageFetchingSuccess = useSelector(imageFetchingSuccessSelector);

  useEffect(() => {
    if (imageFetchingSuccess) {
      imageRef.current.value = '';
      setLogo(null);
    }
  }, [imageFetchingSuccess]);

  const handleSetImage = e => {
    setError(null);
    setLogo(e.target.files[0]);
  };

  const handleUpdateImage = (id, newImage) => {
    patchImage({
      id,
      productId,
      image: newImage,
    });
  };

  const handleDeleteImage = id => () => {
    deleteImage({
      id,
      type,
      productId,
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      images,
      result.source.index,
      result.destination.index
    );

    setImages({ images: items, type });
    swapProductImages({
      id: productId,
      id1: items[result.source.index]?.id,
      id2: items[result.destination.index]?.id,
    });
  };

  const handleUploadImage = () => {
    if (!image) {
      setError('Please select an image');
      return;
    }
    createImage({ image, type, id: productId });
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className={styles.main}>
              <img className="mr-2 mb-2" src={image} alt="" height="60" />
              <input
                name="image"
                type="file"
                id="image"
                accept="image/jpeg,image/png"
                onChange={handleSetImage}
                ref={imageRef}
              />
              {imageError && (
                <span className="text-danger mr-1">{imageError}</span>
              )}
              <Button color="primary" onClick={handleUploadImage}>
                Upload
              </Button>
            </div>
          </CardBody>
        </Card>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="images">
            {provided => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {images.map((item, i) => (
                    <ImageCard
                      key={i.toString()}
                      item={item}
                      index={i}
                      handleUpdateImage={file =>
                        handleUpdateImage(item.id, file)
                      }
                      handleDeleteImage={handleDeleteImage}
                      imageFetchingSuccess={imageFetchingSuccess}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Col>
    </Row>
  );
};

ImageForm.propTypes = {
  images: PropTypes.array,
  productId: PropTypes.number,
  type: PropTypes.string,
};

export default ImageForm;
