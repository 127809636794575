import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const acquirersSlice = createSlice({
  name: 'acquirers',
  initialState: {
    isPending: false,
    acquirers: [],
    acquirer: {},
    pagination: {},
  },
  reducers: {
    fetchAcquirers: state => {
      state.isPending = true;
    },
    fetchAcquirersSuccess(state, { payload }) {
      state.isPending = false;
      state.acquirers = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchAcquirersFailure: state => {
      state.isPending = false;
    },
    fetchAcquirer: state => {
      state.isPending = true;
    },
    fetchAcquirerSuccess(state, { payload }) {
      state.isPending = false;
      state.acquirer = payload.data;
    },
    fetchAcquirerFailure: state => {
      state.isPending = false;
    },
    createAcquirer: state => {
      state.isPending = true;
    },
    createAcquirerSuccess(state, { payload }) {
      state.acquirers = state.acquirers.concat(payload.data);
      state.isPending = false;
    },
    createAcquirerFailure: state => {
      state.isPending = false;
    },
    updateAcquirer: state => {
      state.isPending = true;
    },
    updateAcquirerSuccess(state, { payload }) {
      state.acquirers = state.acquirers.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateAcquirerFailure: state => {
      state.isPending = false;
    },
    setActiveAcquirer: state => {
      state.isPending = true;
    },
    setActiveAcquirerSuccess(state, { payload }) {
      state.acquirers = state.acquirers.map(item => {
        if (item.id !== payload.inResponseTo) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    setActiveAcquirerFailure: state => {
      state.isPending = false;
    },
    setStripeConnectAccountId: state => {
      state.isPending = true;
    },
    setStripeConnectAccountIdSuccess: state => {
      state.isPending = false;
    },
    setStripeConnectAccountIdFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(acquirersSlice.actions);

export default acquirersSlice.reducer;
