import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchTimeEntrySaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/time_entry',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchTimeEntryDetailsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/time_entry',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* approveTimeEntrySaga(action) {
  yield api({
    action,
    method: 'patch',
    url: '/time_entry',
    data: action.payload.entries,
    successMessage: `Successfully approved timesheet for ${action.payload.user.first_name} ${action.payload.user.last_name}.`,
    showErrorMessage: 'Something went wrong timesheet.',
  });
}

export function* approveTimeEntrySuccessSaga(action) {
  const payload = {
    start_date: action.payload.inResponseTo.start_date,
    end_date: action.payload.inResponseTo.end_date,
    per_page: action.payload.inResponseTo.per_page,
  };
  yield put({ type: actions.fetchTimeEntryDetails, payload });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTimeEntry, fetchTimeEntrySaga),
    takeLatest(actions.fetchTimeEntryDetails, fetchTimeEntryDetailsSaga),
    takeLatest(actions.approveTimeEntry, approveTimeEntrySaga),
    takeLatest(actions.approveTimeEntrySuccess, approveTimeEntrySuccessSaga),
  ]);
}
