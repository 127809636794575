import { createSelector } from 'reselect';

export const rootSelector = state => state.brands;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const brandsSelector = createSelector(
  rootSelector,
  ({ brands }) => brands
);

export const brandsStepSelector = createSelector(
  rootSelector,
  ({ step }) => step
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const brandSelector = createSelector(rootSelector, ({ brand }) => brand);

export const allBrandsSelector = createSelector(
  rootSelector,
  ({ allBrands }) => allBrands
);

export const isAllBrandsPendingSelector = createSelector(
  rootSelector,
  ({ isAllBrandsPending }) => isAllBrandsPending
);
