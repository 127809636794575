import React, { useEffect, useMemo, useState } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';

import useQuery from 'hooks/useQuery';
import { getProductsQuery } from 'utils/getProductsQuery';
import { getQuery } from 'utils/getQuery';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions } from 'models/inventories/slice';
import useSelector from 'hooks/useSelector';
import {
  inventoryManualCountingSelector,
  initializedManualCountSelector,
  inventoryManualCountingPaginationSelector,
  inventoryManualCountingPendingSelector,
} from 'models/inventories/selectors';
import DateRangePicker from 'components/DateRangePicker';
import InventoryManualCountingTable from 'components/InventoryManualCountingTable';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import { Link } from 'react-router-dom';
import { hostBrandSelector, isOnlyHostSelector } from 'models/user/selectors';
import { formatDate } from 'utils/formatDate';
import { getEndDate, getStartDate } from 'utils/date';

const InventoryCountSummary = ({ title }) => {
  const query = useQuery();

  const fetchInventoryManualCounting = useAction(
    actions.fetchInventoryManualCounting
  );
  const inventoryManualCounting = useSelector(inventoryManualCountingSelector);

  const fetchInitializedManualCount = useAction(
    actions.fetchInitializedManualCount
  );
  const initializedManualCount = useSelector(initializedManualCountSelector);

  const pagination = useSelector(inventoryManualCountingPaginationSelector);
  const loading = useSelector(inventoryManualCountingPendingSelector);
  const isHost = useSelector(isOnlyHostSelector);
  const hostBrand = useSelector(hostBrandSelector);

  const [page, setPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [selectedBrand, setSelectedBrand] = useState(
    getQuery('brand', 'All brands')
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    getQuery('warehouse', 'All warehouses')
  );
  const [selectedProducts, setSelectedProducts] = useState(
    getProductsQuery(query.get('product_ids'), query.get('product_labels')) ??
      []
  );
  const [date, setDate] = useState({});

  const inventoryManualCountingValues = useMemo(
    () => Object.values(inventoryManualCounting).sort((a, b) => b.id - a.id),
    [inventoryManualCounting]
  );

  const initializedManualCountValues = useMemo(
    () =>
      (selectedWarehouse?.value &&
        Object.values(initializedManualCount || {})
          .sort((a, b) => b.id - a.id)
          .filter(count => count.warehouse.id === selectedWarehouse.value)) ||
      (selectedBrand?.value &&
        Object.values(initializedManualCount || {})
          .sort((a, b) => b.id - a.id)
          .filter(count => count.warehouse.brand_id === selectedBrand.value)) ||
      Object.values(initializedManualCount || {}).sort((a, b) => b.id - a.id),
    [initializedManualCount, selectedBrand, selectedWarehouse]
  );

  useEffect(() => {
    setPage(1);
  }, [selectedBrand, selectedWarehouse, selectedProducts, date]);

  useEffect(() => {
    fetchInventoryManualCounting({
      page,
      brand_id: selectedBrand?.value,
      brand_title: selectedBrand?.label,
      warehouse_id: selectedWarehouse?.value,
      warehouse_title: selectedWarehouse?.label,
      product_ids: selectedProducts?.map(item => item.value),
      product_labels: selectedProducts?.map(item => item.label),
      date_range_start: date?.startDate,
      date_range_end: date?.endDate,
    });
  }, [selectedBrand, selectedWarehouse, page, selectedProducts, date]);

  useEffect(() => {
    setPage(1);
    fetchInitializedManualCount();
  }, []);

  useEffect(() => {
    if (isHost && hostBrand) {
      const { brand_title, brand_id } = hostBrand;
      setSelectedBrand({
        label: brand_title,
        value: brand_id,
      });
    }
  }, [isHost, hostBrand]);

  const handleDateChange = value => {
    setDate({
      startDate: formatDate(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: formatDate(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="mb-3">
                {!isHost && (
                  <Col md={3}>
                    <BrandsPaginatedField
                      isMulti={false}
                      value={selectedBrand}
                      onChange={setSelectedBrand}
                      isInitialLabel
                    />
                  </Col>
                )}
                {selectedBrand?.value && (
                  <Col md={3}>
                    <WarehousesPaginatedField
                      value={selectedWarehouse}
                      onChange={setSelectedWarehouse}
                      parameters={{ brand_id: selectedBrand?.value }}
                      isInitialLabel
                    />
                  </Col>
                )}
                <Col md={5}>
                  <ProductsPaginatedField
                    id="wine"
                    placeholder="Select products..."
                    value={selectedProducts}
                    onChange={setSelectedProducts}
                    isMulti
                    withSKU
                  />
                </Col>
              </Row>
              <Row className="d-flex mb-3">
                <Col className="d-flex">
                  <DateRangePicker
                    placement="bottom"
                    onDateChange={handleDateChange}
                  />
                  <Link to="/inventory-count/create">
                    <Button
                      color="success"
                      type="button"
                      className="waves-effect waves-light mr-1 ml-3 text-nowrap"
                    >
                      <i className="ri-add-line align-middle mr-2" /> Create
                    </Button>
                  </Link>
                </Col>
              </Row>
              {pagination?.total_count > 0 && (
                <InventoryManualCountingTable
                  pagination={pagination}
                  data={inventoryManualCountingValues}
                  initializedData={initializedManualCountValues}
                  loading={loading}
                  onPageChange={setPage}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

InventoryCountSummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default InventoryCountSummary;
