import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as warehousesActions } from 'models/warehouses/slice';
import {
  currentWarehouseSelector,
  warehouseInventoriesSelector,
  warehouseInventoriesPaginationSelector,
  warehouseInventoriesPendingSelector,
  warehouseInventoriesLastFetchedSelector,
  inventoryLimitsPaginationSelector,
  inventoryLimitsPendingSelector,
  inventoryLimitsSelector,
  isPendingSelector as warehousesIsPendingSelector,
} from 'models/warehouses/selectors';
import Header from './Header';
import FeeLayout from './FeeLayout';
import WarehouseInventoryTable from 'components/WarehouseInventoryTable';
import WarehouseInventoryLimitsTable from 'components/WarehouseInventoryLimitsTable';

const WarehouseShow = ({ title }) => {
  const { warehouseID } = useParams();
  const fetchWarehouse = useAction(warehousesActions.fetchWarehouse);
  const fetchWarehouseInventories = useAction(
    warehousesActions.fetchWarehouseInventories
  );
  const fetchInventoryLimits = useAction(
    warehousesActions.fetchInventoryLimits
  );

  const updateContainerWorldStatus = useAction(
    warehousesActions.updateContainerWorldInventoryStatus
  );
  const warehouseIsPending = useSelector(warehousesIsPendingSelector);
  const warehouseInventoriesPending = useSelector(
    warehouseInventoriesPendingSelector
  );
  const inventoryLimitsPending = useSelector(inventoryLimitsPendingSelector);
  const warehouse = useSelector(currentWarehouseSelector);
  const warehouseInventories = useSelector(warehouseInventoriesSelector);
  const inventoryLimits = useSelector(inventoryLimitsSelector);
  const warehouseInventoriesPagination = useSelector(
    warehouseInventoriesPaginationSelector
  );
  const inventoryLimitsPagination = useSelector(
    inventoryLimitsPaginationSelector
  );
  const warehouseInventoriesLastFetched = useSelector(
    warehouseInventoriesLastFetchedSelector
  );
  const [inventoryPage, setInventoryPage] = useState(1);
  const [limitsPage, setLimitsPage] = useState(1);

  const isContainerWorld = !!warehouse?.container_world_warehouse_id;

  useEffect(() => {
    if (warehouseID) {
      fetchWarehouse(warehouseID);
    }
  }, [fetchWarehouse, warehouseID]);

  useEffect(() => {
    fetchInventoryLimits({ warehouseId: warehouseID, page: limitsPage });
  }, [fetchInventoryLimits, limitsPage]);

  useEffect(() => {
    fetchWarehouseInventories({
      warehouseId: warehouseID,
      page: inventoryPage,
    });
  }, [warehouseID, inventoryPage]);

  const onUpdate = () => {
    if (warehouseID) {
      updateContainerWorldStatus({ id: warehouseID });
    }
  };

  if (warehouseIsPending || warehouseInventoriesPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/warehouses/${warehouseID}/edit`}
        title={`Edit ${warehouse?.title ?? ''}`}
        breadcrumbItems={[
          {
            title: 'Back to warehouses',
            link: '/warehouses/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg="12">
          <Header warehouse={warehouse} />
        </Col>
      </Row>
      {warehouseInventoriesPagination && (
        <Row>
          <Col md="12">
            <WarehouseInventoryTable
              title={
                isContainerWorld ? 'Container World Inventories' : 'Inventories'
              }
              isContainerWorld={!!warehouse?.container_world_warehouse_id}
              pagination={warehouseInventoriesPagination}
              inventories={warehouseInventories ?? []}
              onPageChange={setInventoryPage}
              pending={warehouseInventoriesPending}
              onUpdate={onUpdate}
              lastFetched={warehouseInventoriesLastFetched}
              warehouseID={warehouseID}
            />
          </Col>
        </Row>
      )}
      {inventoryLimitsPagination && (
        <Row>
          <Col md="12">
            <WarehouseInventoryLimitsTable
              data={inventoryLimits}
              pagination={inventoryLimitsPagination ?? {}}
              pending={inventoryLimitsPending}
              onPageChange={setLimitsPage}
            />
          </Col>
        </Row>
      )}
      <Label>Taxes</Label>

      <Row>
        <Col md={12}>
          <FeeLayout fees={warehouse?.warehouses_fees} />
        </Col>
      </Row>
    </PageWrapper>
  );
};

WarehouseShow.propTypes = {
  title: PropTypes.string,
};

export default WarehouseShow;
