import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import {
  discountsSelector,
  isPendingSelector,
  paginationSelector,
} from 'models/discounts/selectors';
import { actions as discountActions } from 'models/discounts/slice';

import { STATUSES } from './filterConstants';
import DataTable from './DataTable';
import cx from 'classnames';

const DiscountsSummary = ({ title }) => {
  const fetchDiscounts = useAction(discountActions.fetchDiscounts);
  const discounts = useSelector(discountsSelector);
  const discountsIsPending = useSelector(isPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [sortField, setSortField] = useState(
    query.get('sort_field') || 'created_at'
  );
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'desc'
  );
  const [activeTab, setActiveTab] = useState(
    query.get('is_coupon') === 'true' ? '2' : '1'
  );

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      const isCoupon = activeTab === '2';
      fetchDiscounts({
        ...filters,
        status: filters?.statuses,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        is_coupon: isCoupon,
      });
    }
  }, [
    fetchDiscounts,
    currentPage,
    sortDirection,
    sortField,
    filters,
    activeTab,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortDirection, sortField, filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '1',
                })}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                Discounts
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '2',
                })}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                Coupon Codes
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <TabContent className="w-100" activeTab={activeTab}>
          <TabPane tabId="1">
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                    <Col lg="12">
                      <Filters
                        filters={filters}
                        setFilters={setFilters}
                        hasBrandsFilter
                        hasPerPageFilter
                        setCurrentPage={setCurrentPage}
                        disabled={discountsIsPending}
                        hasFilterByStatus
                        statusFields={STATUSES}
                        hasWarehousesFilter
                        hasCreateItem
                        hasTextSearch
                        createItemLink="/discounts/create"
                        placeholder="Search... (By discount title)"
                      />
                    </Col>
                  </Row>
                  <PaginationTable
                    setCurrentPage={setCurrentPage}
                    pagination={pagination}
                  >
                    <DataTable
                      sortField={sortField}
                      setSortField={setSortField}
                      sortDirection={sortDirection}
                      setSortDirection={setSortDirection}
                      isPending={discountsIsPending}
                      discounts={discounts}
                    />
                  </PaginationTable>
                </CardBody>
              </Card>
            </Col>
          </TabPane>
          <TabPane tabId="2">
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                    <Col lg="12">
                      <Filters
                        filters={filters}
                        setFilters={setFilters}
                        hasBrandsFilter
                        hasTextSearch
                        hasPerPageFilter
                        setCurrentPage={setCurrentPage}
                        disabled={discountsIsPending}
                        hasFilterByStatus
                        statusFields={STATUSES}
                        hasFilterByCustomer
                        hasCreateItem
                        createItemLink="/discount-codes/create"
                        placeholder="Search... (By coupon code, coupon title)"
                      />
                    </Col>
                  </Row>
                  <PaginationTable
                    setCurrentPage={setCurrentPage}
                    pagination={pagination}
                  >
                    <DataTable
                      sortField={sortField}
                      setSortField={setSortField}
                      sortDirection={sortDirection}
                      setSortDirection={setSortDirection}
                      isPending={discountsIsPending}
                      discounts={discounts}
                      isCouponCode={activeTab === '2'}
                    />
                  </PaginationTable>
                </CardBody>
              </Card>
            </Col>
          </TabPane>
        </TabContent>
      </Row>
    </PageWrapper>
  );
};

DiscountsSummary.propTypes = {
  title: PropTypes.string,
};

export default DiscountsSummary;
