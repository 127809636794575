import React from 'react';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from '../NotificationsForm.scss';

const types = {
  CUSTOM: 1,
  NEW: 2,
};

const TableRow = ({
  products,
  value,
  warehouseId,
  onChange,
  onSubmit,
  onRemove,
}) => {
  const creationDisabled = !!products.find(
    i => i.product_id === value.product_id
  );
  const onProductChange = item => {
    onChange({ ...value, product_id: item.value, product_title: item.label });
  };

  const onLimitChange = event => {
    const inputValue = event.target.value;
    if (!inputValue) {
      onChange({ ...value, limit_value: 0 });
    } else if (inputValue.match(/^((0[1-9]))/)) {
      const preparedValue = inputValue.replace(/^0+/, '') || 0;
      onChange({ ...value, limit_value: Number(preparedValue) });
    } else if (inputValue.match(/^((0|([1-9][0-9]*)))$/)) {
      onChange({ ...value, limit_value: Number(inputValue) });
    }
  };

  return (
    <tr>
      <td className={styles.withoutPadding}>
        {value.type === types.NEW ? (
          <>
            <ProductsPaginatedField
              warehouseIds={warehouseId}
              value={{ label: value.product_title, value: value.product_id }}
              onChange={onProductChange}
            />
            {creationDisabled && (
              <p className={styles.error}>
                You already have a Notification Limit for this product.
              </p>
            )}
          </>
        ) : (
          <p>{value.product_title}</p>
        )}
      </td>
      <td>
        <input
          type="text"
          id="limit-input"
          value={value?.limit_value}
          placeholder="Limit"
          onChange={onLimitChange}
          className="form-control"
        />
      </td>
      <td>
        <Button
          color="danger"
          type="button"
          className="waves-effect waves-light text-nowrap"
          onClick={() => onRemove(value)}
        >
          <i className="mdi mdi-trash-can font-size-14" />
        </Button>
        <Button
          onClick={() => onSubmit(value)}
          disabled={
            value.prev_limit === value.limit_value ||
            (value.type === types.NEW && creationDisabled)
          }
          color={value.type === types.NEW ? 'success' : 'primary'}
          type="button"
          style={{ marginLeft: 20 }}
          className="waves-effect waves-light mr-1 text-nowrap"
        >
          {value.type === types.NEW ? 'Create' : 'Update'}
        </Button>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  products: PropTypes.array,
  warehouseId: PropTypes.number,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  value: PropTypes.object,
};

export default TableRow;
