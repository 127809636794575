export const checkPasswordsSame = (value, ctx) => {
  if (value !== ctx.password) {
    return 'Passwords must match';
  }
  return true;
};

export const validatePassword = value => {
  const regex = /.*?(?:[a-z].*?[0-9]|[0-9].*?[a-z]).*?/;
  if (value.length < 8 || !regex.test(value)) {
    return 'Password must be at least 8 characters and includes numbers and letters';
  }
  return true;
};
