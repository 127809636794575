import { createSelector } from 'reselect';

export const rootSelector = state => state.sharedCustomer;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const sharedCustomerSelector = createSelector(
  rootSelector,
  ({ sharedCustomer }) => sharedCustomer
);

export const fieldErrorsSelector = createSelector(
  rootSelector,
  ({ fieldErrors }) => fieldErrors
);
