import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';

const TablesFormPaginatedDevicesField = ({
  onChange,
  value,
  isDisabled,
  currentWarehouse,
}) => {
  const request = useApiRequest();

  const fetchDevices = ({ page = 1, warehouse_id }) => {
    return request({
      url: `/devices/?page=${page}&warehouse_id=${warehouse_id}`,
    });
  };

  const loadDeviceOptions = async (
    search,
    loadedOptions,
    { page, warehouse_id }
  ) => {
    try {
      const res = await fetchDevices({
        page,
        warehouse_id,
      });
      return {
        options:
          res?.results?.filter(item => !item.has_an_active_session) || [],
        hasMore: res?.pagination?.next_page,
        additional: {
          page: page + 1,
          warehouse_id,
        },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      name="device_id"
      isSearchable={false}
      value={value}
      loadOptions={loadDeviceOptions}
      onChange={onChange}
      additional={{
        page: 1,
        warehouse_id: currentWarehouse?.value,
      }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.identifier}
      cacheUniqs={[currentWarehouse]}
      isDisabled={isDisabled}
      menuPlacement="top"
      maxMenuHeight={190}
    />
  );
};

TablesFormPaginatedDevicesField.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  currentWarehouse: PropTypes.object,
  value: PropTypes.any,
};

export default TablesFormPaginatedDevicesField;
