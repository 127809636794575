import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as devicesActions } from 'models/devices/slice';
import useSelector from 'hooks/useSelector';
import {
  devicesSelector,
  isPendingSelector as devicesIsPendingSelector,
  paginationSelector,
} from 'models/devices/selectors';

import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const DevicesSummary = ({ title }) => {
  const fetchDevices = useAction(devicesActions.fetchDevices);
  const deleteDevice = useAction(devicesActions.deleteDevice);
  const devices = useSelector(devicesSelector);
  const pagination = useSelector(paginationSelector);
  const isPending = useSelector(devicesIsPendingSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [deviceForDelete, setDeviceForDelete] = useState(null);

  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchDevices({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        set_query: true,
      });
    }
  }, [filters, fetchDevices, currentPage, sortDirection, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortDirection, sortField]);

  const handleDeleteTable = () => {
    deleteDevice(deviceForDelete);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasWarehousesFilter
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/devices/create"
                    hasDeviceTypes
                    disabled={isPending}
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  setDeviceID={setDeviceForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  devices={devices}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    onSaveClick={handleDeleteTable}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

DevicesSummary.propTypes = {
  title: PropTypes.string,
};

export default DevicesSummary;
