import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';

const DropdownItemId = Object.freeze({
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
});

const ProductActionDropdown = ({
  onArchive,
  onRestore,
  isArchived,
  product,
}) => {
  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.ARCHIVE]: onArchive,
      [DropdownItemId.RESTORE]: onRestore,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/products/${product.id}/edit`,
    },

    {
      id: DropdownItemId.DUPLICATE,
      icon: (
        <i className="mdi mdi-plus-box-multiple align-middle font-size-18 mr-2" />
      ),
      label: 'Duplicate',
      href: `/products/${product.id}/duplicate`,
    },
    {
      id: DropdownItemId.RESTORE,
      icon: <i className="mdi mdi-restore font-size-18 mr-2" />,
      label: 'Restore',
      isHidden: !isArchived,
    },

    {
      id: DropdownItemId.ARCHIVE,
      icon: <i className="mdi mdi-archive font-size-18 mr-2" />,
      label: 'Archive',
      isHidden: isArchived,
    },
  ];

  return (
    <ActionDropdown
      onItemClick={onItemClickHandler}
      items={dropdownItem}
      right
    />
  );
};

ProductActionDropdown.propTypes = {
  onArchive: PropTypes.func,
  onRestore: PropTypes.func,
  isArchived: PropTypes.bool,
  product: PropTypes.object,
};

export default ProductActionDropdown;
