import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/TablesForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { isPendingSelector as devicesIsPendingSelector } from 'models/devices/selectors';
import { isPendingSelector as tablesIsPendingSelector } from 'models/tables/selectors';
import { actions as tablesActions } from 'models/tables/slice';

const CreateTable = ({ title }) => {
  const createTable = useAction(tablesActions.createTable);

  const devicesIsPending = useSelector(devicesIsPendingSelector);
  const tablesIsPending = useSelector(tablesIsPendingSelector);

  const [currentTastingRoomID, setCurrentTastingRoomID] = useState(null);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(null);
  const isPending = tablesIsPending || devicesIsPending;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new table"
        breadcrumbItems={[
          { title: 'Back to tables', link: '/tables/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            onSubmit={createTable}
            submitTitle="Save"
            currentDevice={currentDevice}
            setCurrentDevice={setCurrentDevice}
            currentWarehouse={currentWarehouse}
            setCurrentWarehouse={setCurrentWarehouse}
            currentTastingRoom={currentTastingRoomID}
            setCurrentTastingRoom={setCurrentTastingRoomID}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateTable.propTypes = {
  title: PropTypes.string,
};

export default CreateTable;
