import { createSelector } from 'reselect';

export const rootSelector = state => state.tables;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const tablesSelector = createSelector(
  rootSelector,
  ({ tables }) => tables
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const tableSelector = createSelector(rootSelector, ({ table }) => table);
