import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import {
  foodCategoriesSelector,
  isPendingSelector,
} from 'models/products/selectors';
import ProductCategoryForm from 'components/ProductCategoryForm';

const EditFoodCategory = ({ title }) => {
  const { id } = useParams();
  const updateCategory = useAction(productsActions.updateProductCategory);
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const isPending = useSelector(isPendingSelector);
  const foodCategories = useSelector(foodCategoriesSelector);
  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    if (!foodCategories) {
      fetchCategories();
    }
  }, [fetchCategories, foodCategories]);

  useEffect(() => {
    if (foodCategories && id) {
      setCurrentCategory(foodCategories?.[id]);
    }
  }, [foodCategories, id]);

  const handleSubmit = values => {
    updateCategory({ ...values, id, redirectUrl: '/food-categories/' });
  };

  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={currentCategory?.title}
        breadcrumbItems={[
          {
            title: 'Back to categories',
            link: '/food-categories/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {currentCategory && (
        <Row>
          <Col md={12}>
            <ProductCategoryForm
              submitTitle="Save"
              onSubmit={handleSubmit}
              model={currentCategory}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditFoodCategory.propTypes = {
  title: PropTypes.string,
};

export default EditFoodCategory;
