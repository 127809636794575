import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const initialState = {
  isPending: false,
  tags: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    fetchTags: state => {
      state.isPending = true;
    },
    fetchTagsSuccess: (state, { payload }) => {
      const { data } = payload;
      state.isPending = false;
      state.tags = data;
    },
    fetchTagsFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(tagsSlice.actions);

export default tagsSlice.reducer;
