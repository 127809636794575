import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DateRangePicker from '../DateRangePicker';
import styles from './Filters.scss';
import classNames from 'classnames';

import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';

import {
  perPagePagination,
  DEVICE_TYPES,
  UNPAID_STATUSES,
  PAID_STATUS_OPTIONS,
} from 'constants';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import TastingRoomsPaginatedField from '../PaginatedFields/TastingRoomsPaginatedField/TastingRoomsPaginatedField';
import WineclubsPaginatedField from 'components/PaginatedFields/WineclubsPaginatedField';
import CustomerPaginatedField from 'components/PaginatedFields/CustomerPaginatedField';
// TODO rewrite all filters as fullPagesOptions;

const Filters = ({
  filters,
  setFilters,
  workspaceID,
  disabled,
  createItemLink,
  setCurrentPage,
  hasWorkspaceFilter,
  hasBrandsFilter,
  hasWarehousesFilter,
  hasPerPageFilter,
  hasTextSearch,
  hasCreateItem,
  hasDateRangeFilter,
  hasWineclubsFilter,
  hasFilterByStatus,
  hasFilterByOrderType,
  statusFields,
  hasFilterByLeadStatus,
  leadStatusFields,
  hasFilterByPeriodInMonths,
  periodInMonthsFields,
  hasFilterByActiveState,
  isActiveItemsFields,
  hasFilterByTastingRoom,
  isTastingRoomFields,
  hasFilterByRoom,
  typeFields,
  hasFilterByRoleIs,
  setDateRange,
  exportButtonText,
  hasFilterByRoleIsNot,
  roleIsNot,
  setRoleIsNot,
  rolesFields,
  hasFilterByCompanyType,
  companyTypeOptions,
  hasFilterByConfirmed,
  confirmedOptions,
  hasDeviceTypes,
  hasProductsFilter,
  hasFilterByCustomer,
  wineclubOption,
  hasOrderContains,
  hasFilterByPaid,
  placeholder,
  onExportfunc,
}) => {
  let fullPeriodInMonthsOptions = [];
  let fullIsTastingRoomOptions = [];

  const [title_contains, setTitleContains] = useState(
    filters.title_contains ?? ''
  );

  useEffect(() => {
    setFilters({
      ...filters,
      excluded_statuses: [...UNPAID_STATUSES, 'PENDING_FULFILLMENT'],
    });
  }, []);

  useEffect(() => {
    if (filters.title_contains !== title_contains && !hasOrderContains) {
      setTitleContains(filters.title_contains ?? '');
    }
  }, [filters.title_contains, hasOrderContains]);

  const [order_contains, setOrderContains] = useState(
    filters.order_contains ?? ''
  );

  const [paid, setPaid] = useState({ value: 'PAID', label: 'Paid' });

  useEffect(() => {
    if (filters.order_contains !== order_contains && hasOrderContains) {
      setOrderContains(filters.order_contains ?? '');
    }
  }, [filters.order_contains, hasOrderContains]);

  const handleSubmit = useCallback(() => {
    if (hasTextSearch && !hasOrderContains) {
      setFilters({ ...filters, title_contains });
    }
    if (hasTextSearch && hasOrderContains) {
      setFilters({ ...filters, order_contains });
    }

    setCurrentPage(1);
  }, [
    filters,
    title_contains,
    setCurrentPage,
    hasTextSearch,
    order_contains,
    hasOrderContains,
  ]);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const fullDevicesTypes = useMemo(() => {
    if (hasDeviceTypes) {
      return [
        {
          value: undefined,
          label: 'All device types',
        },
        ...DEVICE_TYPES.map(type => {
          return {
            value: type,
            label: `${type
              .charAt(0)
              .toUpperCase()}${type.toLowerCase().substring(1, type.length)}`,
          };
        }),
      ];
    }

    return [];
  }, [hasDeviceTypes, rolesFields]);

  const fullRoleIsOptions = useMemo(() => {
    if (hasFilterByRoleIs) {
      return [
        {
          value: undefined,
          label: 'All roles',
        },
        ...rolesFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }

    return [];
  }, [hasFilterByRoleIs, rolesFields]);

  const fullRoleIsNotOptions = useMemo(() => {
    if (hasFilterByRoleIsNot) {
      return [
        {
          value: '',
          label: 'Role is not',
        },
        ...rolesFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }

    return [];
  }, [hasFilterByRoleIsNot, rolesFields]);

  const fullPagesOptions = useMemo(() => {
    if (hasPerPageFilter) {
      return [
        ...perPagePagination?.map(pages => ({
          value: pages,
          label: pages,
        })),
      ];
    }

    return [];
  }, [hasPerPageFilter]);

  const fullStatusOptions = useMemo(() => {
    if (hasFilterByStatus) {
      return [
        {
          value: undefined,
          label: 'All statuses',
        },
        ...statusFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }

    return [];
  }, [hasFilterByStatus, statusFields]);

  const fullLeadStatusOptions = useMemo(() => {
    if (hasFilterByLeadStatus) {
      return [
        {
          value: undefined,
          label: 'All statuses',
        },
        ...leadStatusFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }

    return [];
  }, [hasFilterByLeadStatus, leadStatusFields]);

  const fullOrderTypesOptions = useMemo(() => {
    if (hasFilterByOrderType) {
      return [
        {
          value: undefined,
          label: 'All Types',
        },
        ...typeFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }

    return [];
  }, [hasFilterByOrderType, typeFields]);

  if (hasFilterByPeriodInMonths) {
    fullPeriodInMonthsOptions = [
      {
        value: undefined,
        label: 'All periods',
      },
      ...periodInMonthsFields?.map(item => ({
        value: item.toUpperCase(),
        label: item,
      })),
    ];
  }

  const fullCompanyTypeOptions = useMemo(() => {
    if (hasFilterByCompanyType) {
      return [
        {
          value: undefined,
          label: 'All types',
        },
        ...companyTypeOptions?.map(item => ({
          value: item,
          label: item,
        })),
      ];
    }
    return [];
  }, [hasFilterByCompanyType, companyTypeOptions]);

  const fullIsActiveOptions = useMemo(() => {
    if (hasFilterByActiveState) {
      return [
        {
          value: undefined,
          label: 'All states',
        },
        ...isActiveItemsFields?.map(item => ({
          value: item.apiValue,
          label: item.userValue,
        })),
      ];
    }
    return [];
  }, [hasFilterByCompanyType, companyTypeOptions]);

  if (hasFilterByTastingRoom) {
    fullIsTastingRoomOptions = [
      {
        value: undefined,
        label: 'Tasting Room',
      },
      ...isTastingRoomFields?.map(item => ({
        value: item.apiValue,
        label: item.userValue,
      })),
    ];
  }

  const handleBrandsChange = selectedOption => {
    setFilters({
      ...filters,
      brand_id: selectedOption.value,
      brand_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handleRoleIsChange = selectedOption => {
    setFilters({
      ...filters,
      role: selectedOption.value,
      role_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleRoleIsNotChange = selectedOption => {
    setRoleIsNot(selectedOption.value);
    setCurrentPage(1);
  };
  const handleWarehouseChange = selectedOption => {
    setFilters({
      ...filters,
      warehouse_id: selectedOption.value,
      warehouse_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleWineclubsChange = selectedOption => {
    setFilters({
      ...filters,
      wineclub_id: selectedOption.value,
      wineclub_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleStatusChange = selectedOption => {
    setFilters({
      ...filters,
      statuses: selectedOption.value,
      statuses_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleLeadStatusChange = selectedOption => {
    setFilters({
      ...filters,
      lead_status: selectedOption.value,
      lead_status_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleOrderTypeChange = selectedOption => {
    setFilters({
      ...filters,
      resourcetypes: selectedOption.value,
      resourcetypes_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleProductsChange = selectedOptions => {
    setFilters({
      ...filters,
      products: selectedOptions,
      product_ids: selectedOptions?.map(item => item.value),
      product_labels: selectedOptions?.map(item => item.label),
    });
    setCurrentPage(1);
  };
  const handlePeriodInMonthChange = selectedOption => {
    setFilters({
      ...filters,
      period_in_months: selectedOption.value,
      period_in_months_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleIsActiveChange = selectedOption => {
    setFilters({
      ...filters,
      is_active: selectedOption.value,
      is_active_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handleIsConfirmedChange = selectedOption => {
    setFilters({
      ...filters,
      is_confirmed: selectedOption.value,
      is_confirmed_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handleCompanyTypeChange = selectedOption => {
    setFilters({
      ...filters,
      company_type: selectedOption.value,
      company_type_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handleIsTastingRoomChange = selectedOption => {
    setFilters({
      ...filters,
      is_used_as_tasting_room: selectedOption.value,
      is_used_as_tasting_room_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handlePagesChange = selectedOption => {
    setFilters({
      ...filters,
      per_page: selectedOption.value,
    });
    setCurrentPage(1);
  };
  const handleRoomsChange = selectedOption => {
    setFilters({
      ...filters,
      tasting_room_id: selectedOption.value,
      tasting_room_title: selectedOption.label,
    });
    setCurrentPage(1);
  };
  const handleDeviceTypeChange = selectedOption => {
    setFilters({
      ...filters,
      type: selectedOption.value,
      type_title: selectedOption.label,
    });
    setCurrentPage(1);
  };

  const handleCustomerChange = selectedOption => {
    setFilters({
      ...filters,
      customer_id: selectedOption.value,
      customer_title: selectedOption.label,
    });
    setCurrentPage?.(1);
  };

  const handleDateChange = value => {
    setCurrentPage?.(1);
    setDateRange(value);
  };

  const placeholder_text = placeholder || 'Search..';

  const handlePaidChange = selectedOption => {
    setPaid(selectedOption);
    if (selectedOption.value === 'PAID') {
      delete filters.statuses;
      setFilters({
        ...filters,
        excluded_statuses: [...UNPAID_STATUSES, 'PENDING_FULFILLMENT'],
      });
    } else {
      delete filters.excluded_statuses;
      setFilters({
        ...filters,
        statuses: UNPAID_STATUSES,
      });
    }
    setCurrentPage(1);
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex">
        {hasFilterByPaid && (
          <div
            style={{ width: '120px' }}
            className={classNames(styles.normal, 'mr-1')}
          >
            <Select
              value={paid}
              onChange={handlePaidChange}
              options={PAID_STATUS_OPTIONS}
              isSearchable={false}
            />
          </div>
        )}
        {hasPerPageFilter && (
          <div style={{ width: '100px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.per_page ?? 10,
                value: filters.per_page ?? 10,
              }}
              name="per_page"
              isSearchable={false}
              options={fullPagesOptions}
              onChange={handlePagesChange}
            />
          </div>
        )}
        {hasWorkspaceFilter && (
          <select
            name="workspace"
            className="custom-select w-auto mr-1"
            defaultValue={workspaceID || 0}
          >
            <option value={1}>All workspaces</option>
          </select>
        )}
        {hasFilterByRoleIs && (
          <div style={{ width: '200px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.role_title ?? 'All roles',
                value: filters.role,
              }}
              isSearchable
              debounceTimeout={1000}
              name="role"
              options={fullRoleIsOptions}
              onChange={handleRoleIsChange}
            />
          </div>
        )}
        {hasFilterByRoleIsNot && (
          <div style={{ width: '200px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              defaultValue={
                fullRoleIsNotOptions?.find(x => x.value === roleIsNot) || ''
              }
              isSearchable
              debounceTimeout={1000}
              name="brand"
              options={fullRoleIsNotOptions}
              onChange={handleRoleIsNotChange}
            />
          </div>
        )}
        {hasBrandsFilter && (
          <div style={{ width: '200px' }}>
            <BrandsPaginatedField
              isMulti={false}
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.brand_title ?? 'All brands',
                value: filters.brand_id,
              }}
              isSearchable
              name="brand"
              onChange={handleBrandsChange}
              withShortOptions
              isInitialLabel
            />
          </div>
        )}
        {hasWarehousesFilter && (
          <div style={{ width: '200px' }}>
            <WarehousesPaginatedField
              classNames="basic-single mr-1"
              isMulti={false}
              parameters={{ brand_id: filters.brand_id }}
              value={{
                label: filters.warehouse_title ?? 'All warehouses',
                value: filters.warehouse_id,
              }}
              onChange={handleWarehouseChange}
              name="warehouse"
              isSearchable
              isInitialLabel
            />
          </div>
        )}
        {hasWineclubsFilter && (
          <div style={{ width: '200px' }}>
            <WineclubsPaginatedField
              classNames="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.wineclub_title ?? 'All wineclubs',
                value: filters.wineclub_id,
              }}
              isSearchable
              debounceTimeout={1000}
              name="wineclubs"
              onChange={handleWineclubsChange}
              isInitialLabel
            />
          </div>
        )}
        {hasFilterByRoom && (
          <div style={{ width: '200px' }}>
            <TastingRoomsPaginatedField
              className="basic-single mr-1"
              value={{
                label: filters.tasting_room_title ?? 'All rooms',
                value: filters.tasting_room_id,
              }}
              warehouseId={filters.warehouse_id}
              isSearchable
              debounceTimeout={1000}
              name="room"
              onChange={handleRoomsChange}
              isInitialLabel
            />
          </div>
        )}
        {hasDeviceTypes && (
          <div style={{ width: '200px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              name="device_types"
              isSearchable={false}
              value={{
                label: filters.type_title ?? 'All device types',
                value: filters.type,
              }}
              options={fullDevicesTypes}
              onChange={handleDeviceTypeChange}
            />
          </div>
        )}
        {hasFilterByCustomer && (
          <div style={{ width: '250px' }}>
            <CustomerPaginatedField
              className="mr-1"
              parameters={{ wineclub_id: wineclubOption }}
              value={{
                label: filters.customer_title ?? 'All customers',
                value: filters.customer_id,
              }}
              onChange={handleCustomerChange}
              isInitialLabel
            />
          </div>
        )}
        {hasFilterByStatus && (!hasFilterByPaid || paid.value === 'PAID') && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.statuses_title ?? 'All statuses',
                value: filters.statuses,
              }}
              name="status"
              options={fullStatusOptions}
              onChange={handleStatusChange}
            />
          </div>
        )}
        {hasFilterByLeadStatus && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters?.lead_status_title ?? 'All statuses',
                value: filters?.lead_status,
              }}
              name="lead_status"
              options={fullLeadStatusOptions}
              onChange={handleLeadStatusChange}
            />
          </div>
        )}
        {hasFilterByOrderType && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters?.resourcetypes_title ?? 'All types',
                value: filters?.resourcetypes,
              }}
              name="status"
              options={fullOrderTypesOptions}
              onChange={handleOrderTypeChange}
            />
          </div>
        )}

        {hasFilterByPeriodInMonths && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              defaultValue={{
                label: filters.period_in_months_title ?? 'All periods',
                value: filters.period_in_months,
              }}
              name="period_in_months"
              options={fullPeriodInMonthsOptions}
              onChange={handlePeriodInMonthChange}
            />
          </div>
        )}
        {hasFilterByActiveState && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.is_active_title ?? 'All states',
                value: filters.is_active,
              }}
              name="is_active"
              options={fullIsActiveOptions}
              onChange={handleIsActiveChange}
            />
          </div>
        )}
        {hasFilterByConfirmed && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.is_confirmed_title ?? 'All statuses',
                value: filters.is_confirmed,
              }}
              name="is_confirmed"
              options={confirmedOptions}
              onChange={handleIsConfirmedChange}
            />
          </div>
        )}

        {hasFilterByTastingRoom && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.is_used_as_tasting_room_title ?? 'Tasting Room',
                value: filters.is_used_as_tasting_room,
              }}
              name="is_used_as_tasting_room"
              options={fullIsTastingRoomOptions}
              onChange={handleIsTastingRoomChange}
            />
          </div>
        )}

        {hasProductsFilter && (
          <div className="mr-1" style={{ width: '300px' }}>
            <ProductsPaginatedField
              id="wine"
              placeholder="Select products..."
              value={filters.products ? filters.products : []}
              onChange={handleProductsChange}
              isMulti
              withSKU
            />
          </div>
        )}

        {hasDateRangeFilter && (
          <div>
            <DateRangePicker
              placement="bottom"
              onDateChange={handleDateChange}
            />
          </div>
        )}

        {onExportfunc && (
          <Col md={3}>
            <div className="d-flex">
              <Button
                type="button"
                color="secondary"
                onClick={onExportfunc}
                disabled={disabled}
                className="waves-effect waves-light text-nowrap w-100"
              >
                <i className="ri-download-line align-middle mr-2" />
                <span>{exportButtonText}</span>
              </Button>
            </div>
          </Col>
        )}

        {hasFilterByCompanyType && (
          <div style={{ width: '150px' }}>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={{
                label: filters.company_type_title ?? 'All types',
                value: filters.company_type,
              }}
              name="sort"
              options={fullCompanyTypeOptions}
              onChange={handleCompanyTypeChange}
            />
          </div>
        )}
      </div>

      <div className={classNames('d-flex', 'mt-4')}>
        <div className={classNames('d-flex', 'align-items-center')}>
          {hasTextSearch && (
            <>
              <Input
                type="text"
                name="title_contains"
                className={classNames('form-control', styles.wAutoMin, 'mr-1')}
                placeholder={placeholder_text}
                value={hasOrderContains ? order_contains : title_contains}
                onChange={e =>
                  hasOrderContains
                    ? setOrderContains(e.target.value)
                    : setTitleContains(e.target.value)
                }
                onKeyPress={handleKeyPress}
              />

              <Button
                style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                type="button"
                onClick={handleSubmit}
                disabled={disabled}
                className="waves-effect waves-light mr-3 text-nowrap "
              >
                <i className="ri-search-line align-middle" />
              </Button>
            </>
          )}
        </div>
        {hasCreateItem && (
          <Link to={createItemLink}>
            <Button
              color="success"
              type="button"
              disabled={disabled}
              className="waves-effect waves-light mr-1 text-nowrap"
            >
              <i className="ri-add-line align-middle mr-2" /> Create
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  disabled: PropTypes.bool,
  workspaceID: PropTypes.number,
  createItemLink: PropTypes.string,
  setCurrentPage: PropTypes.func,
  hasWorkspaceFilter: PropTypes.bool,
  hasBrandsFilter: PropTypes.bool,
  hasWarehousesFilter: PropTypes.bool,
  hasPerPageFilter: PropTypes.bool,
  hasWineclubsFilter: PropTypes.bool,
  hasTextSearch: PropTypes.bool,
  hasCreateItem: PropTypes.bool,
  hasFilterByStatus: PropTypes.bool,
  statusFields: PropTypes.array,
  hasFilterByPeriodInMonths: PropTypes.bool,
  periodInMonthsFields: PropTypes.array,
  hasFilterByActiveState: PropTypes.bool,
  isActiveItemsFields: PropTypes.array,
  hasFilterByTastingRoom: PropTypes.bool,
  isTastingRoomFields: PropTypes.array,
  hasFilterByOrderType: PropTypes.bool,
  typeFields: PropTypes.array,
  hasFilterByRoleIs: PropTypes.bool,
  setDateRange: PropTypes.func,
  hasFilterByRoleIsNot: PropTypes.bool,
  roleIsNot: PropTypes.string,
  setRoleIsNot: PropTypes.func,
  rolesFields: PropTypes.array,
  hasFilterByRoom: PropTypes.bool,
  hasDateRangeFilter: PropTypes.bool,
  hasFilterByCompanyType: PropTypes.bool,
  companyTypeOptions: PropTypes.array,
  hasDeviceTypes: PropTypes.bool,
  hasProductsFilter: PropTypes.bool,
  hasFilterByConfirmed: PropTypes.bool,
  confirmedOptions: PropTypes.array,
  hasFilterByCustomer: PropTypes.bool,
  wineclubOption: PropTypes.number,
  hasOrderContains: PropTypes.bool,
  placeholder: PropTypes.string,
  hasFilterByLeadStatus: PropTypes.bool,
  hasFilterByPaid: PropTypes.bool,
  leadStatusFields: PropTypes.array,
  exportButtonText: PropTypes.string,
  onExportfunc: PropTypes.func,
};

Filters.defaultProps = {
  hasWineclubsFilter: false,
  hasWorkspaceFilter: false,
  hasBrandsFilter: false,
  hasWarehousesFilter: false,
  hasPerPageFilter: false,
  hasTextSearch: false,
  hasCreateItem: false,
  hasFilterByStatus: false,
  hasFilterByPeriodInMonths: false,
  hasFilterByActiveState: false,
  hasFilterByTastingRoom: false,
  hasDateRangeFilter: false,
  hasFilterByOrderType: false,
  hasFilterByCompanyType: false,
  hasDeviceTypes: false,
  hasProductsFilter: false,
  hasFilterByConfirmed: false,
  hasFilterByCustomer: false,
  hasOrderContains: false,
  hasFilterByLeadStatus: false,
  hasFilterByPaid: false,
  placeholder: '',
};

export default Filters;
