import { takeLatest, all, call, put } from 'redux-saga/effects';
import { last, get } from 'lodash';
import api from 'api';
import { actions } from './slice';
import { saveAs } from 'file-saver';
import { showErrorMessage } from 'utils/notification';
import { generateURL } from 'utils/generateURL';
import { push } from 'connected-react-router';

const SUCCESS_LOCATION = Object.freeze({
  CommercialOrder: '/commercial-orders/',
  RetailOrder: '/orders/',
});

const getSuccessLocation = type => {
  return SUCCESS_LOCATION[type] || '/orders/';
};

export function* fetchOrdersSaga(action) {
  const { url } = yield generateURL({
    payload: {
      ...action.payload,
    },
    url: '/orders/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: action.payload.setQuery,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* confirmCommercialOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `commercial_orders/${action.payload?.id}/confirm`,
  });
}

export function* fetchCommercialOrdersSaga(action) {
  const { requestURL } = yield generateURL({
    payload: {
      ...action.payload,
    },
    url: '/commercial-orders/',
    requestURL: '/orders/',
    options: { arrayFormat: 'comma' },
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchPendingFulfillmentOrdersSaga(action) {
  const { requestURL } = yield generateURL({
    payload: {
      ...action.payload,
      statuses: ['PENDING_FULFILLMENT'],
    },
    requestURL: '/orders/',
    options: { arrayFormat: 'comma' },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchOrderSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}`,
  });
}

export function* fetchOrderShipmentLabelSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}`,
  });
}

export function* fetchOrderShipmentLabelSuccessSaga({ payload }) {
  const { data } = payload;
  const shipment = get(last(data.shipping_info?.shipments), 'label_url', null);
  if (shipment) {
    const win = window.open(shipment, '_blank');
    yield call([win, 'focus']);
  }
}

export function* fetchOrderShipmentPublicUrlSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}`,
  });
}

export function* fetchOrderShipmentPublicUrlSuccessSaga({ payload }) {
  const { data } = payload;
  const shipment = get(last(data.shipping_info?.shipments), 'public_url', null);
  if (shipment) {
    const win = window.open(shipment, '_blank');
    yield call([win, 'focus']);
  }
}

export function* cancelOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/orders/${action.payload}/cancel`,
  });
}

export function* createOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    url: '/retail_orders/',
    data: action.payload,
    failureMessage: 'An error occurred while creating order',
  });
}

export function* updateOrderSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/retail_orders/${action.payload.id}`,
    data: action.payload,
    failureMessage: 'An error occurred while updating order',
  });
}

export function* sendOrderBillSaga(action) {
  const order = action.payload.data;
  yield api({
    action,
    method: 'post',
    url: `/orders/${order?.id}/bill`,
    data: {
      email: order?.shipping_info.email || order?.customer?.email,
      payment_type: order?.payment_type,
    },
    failureMessage: 'An error occurred while sending bill',
  });
}

export function* sendRefundOrderBillSaga(action) {
  const order = action.payload.data;
  yield api({
    action,
    method: 'post',
    url: `/orders/${order?.id}/refund_bill`,
    data: {
      email: order?.shipping_info.email || order?.customer?.email,
      refund_type: order?.refund_type,
    },
    failureMessage: 'An error occurred while sending bill',
  });
}

export function* createCommercialOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    url: '/commercial_orders/',
    data: action.payload,
    failureMessage: 'An error occurred while creating order',
  });
}

export function* updateCommercialOrderSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/commercial_orders/${action.payload.id}`,
    data: action.payload,
    failureMessage: 'An error occurred while updating order',
  });
}

export function* patchCommercialOrderSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/commercial_orders/${action.payload.id}`,
    data: action.payload,
    failureMessage: 'An error occurred while patching order',
  });
}

export function* capturePaymentSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    url: `/retail_orders/${payload.id}/capture`,
    data: payload,
    successMessage: 'Successfully paid by card!',
    successNavigateTo: `/orders/`,
  });
}

export function* captureCommercialPaymentSaga(action) {
  try {
    const { payload } = action;
    const response = yield api({
      action,
      method: 'post',
      url: `/commercial_orders/${payload.id}/capture`,
      data: payload,
      successMessage: 'Successfully paid by card!',
    });

    // If the response is successful (status 200), dispatch the success action
    if (response.status === 200) {
      yield put({
        type: actions.captureCommercialPaymentSuccess,
      });
    }
    return response;
  } catch (error) {
    // Dispatch failure action with error message
    yield put({
      type: actions.captureCommercialPaymentFailure,
      payload: { err: error.message || 'An error occurred during payment' },
    });
    return { status: 'error', error: error.message };
  }
}

export function* captureCommercialPaymentSuccessSaga() {
  yield put({
    type: actions.fetchPendingFulfillmentOrders,
  });
  yield put({
    type: actions.fetchCommercialOrders,
    payload: {
      sort_field: 'created_at',
      sort_direction: 'desc',
    },
  });

  yield put(push('/commercial-orders/'));
}

export function* fetchCommercialReportSaga(action) {
  const { id } = action.payload;
  const url = `/commercial_orders/${id}/sales_form`;
  yield api({
    action,
    method: 'get',
    url,
    responseType: 'blob',
    showResponseError: true,
  });
}

export function* fetchCommercialReportSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { id },
    } = payload;
    const filename = `commercial_order_${id}.pdf`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading CSV file'
    );
  }
}

function fetchCommercialReportFailureSaga({ payload }) {
  if (payload.err) {
    showErrorMessage('Error!', payload.err);
  }
}

export function* fetchOrderStatusesSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}/status_changes`,
  });
}

export function* patchOrderSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/retail_orders/${action.payload.id}`,
    data: action.payload,
    failureMessage: 'An error occurred while updating order',
  });
}

export function* patchOrderandChangeStatusSaga(action) {
  const {
    id,
    expected_payment_type,
    email,
    status,
    orderType,
  } = action.payload;
  const patchOrderAction = {
    type: action.patchOrder,
    payload: {
      id,
      expected_payment_type,
    },
  };
  yield call(patchOrderSaga, patchOrderAction);
  const changeStatusAction = {
    type: action.changeOrderStatus,
    payload: {
      id,
      email,
      status,
      orderType,
    },
  };
  yield call(changeOrderStatusSaga, changeStatusAction);
}

export function* changeOrderStatusSaga(action) {
  const { payload } = action;
  const successLocation = getSuccessLocation(action.payload.orderType);
  yield api({
    action,
    method: 'post',
    url: `/orders/${payload.id}/status_changes`,
    data: payload,
    successMessage: 'Successfully changed status!',
    successNavigateTo: successLocation,
  });
}

export function* changeOrderStatusSuccessSaga() {
  yield put({
    type: actions.fetchPendingFulfillmentOrders,
  });
  yield put({
    type: actions.fetchCommercialOrders,
    payload: {
      sort_field: 'created_at',
      sort_direction: 'desc',
    },
  });
}

function* fetchOrderRefundSaga(action) {
  const { orderId } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/orders/${orderId}/returns`,
  });
}

function* refundOrderSaga(action) {
  const { orderId, data } = action.payload;
  yield api({
    action,
    data,
    url: `/orders/${orderId}/refund`,
    method: 'POST',
    successMessage: 'The refund was successful',
    successNavigateTo: `/orders/${orderId}`,
  });
}

export function* printFileSaga(action) {
  const url = `/orders/multi_order_labels`;
  yield api({
    action,
    method: 'post',
    data: action.payload,
    url,
    responseType: 'blob',
    showResponseError: true,
  });
}

export function* printFileSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { packing_slip, shipping_label, orders_ids, parcel_id },
    } = payload;

    const fileTypes = [];

    if (packing_slip) {
      fileTypes.push(`packing_slip${orders_ids.length === 1 ? '' : 's'}`);
    }
    if (shipping_label) {
      fileTypes.push(`shipping_label${orders_ids.length === 1 ? '' : 's'}`);
    }

    orders_ids.sort((a, b) => a - b);
    const orders = `_${orders_ids[0]}${
      orders_ids.length > 1 ? `-${orders_ids[orders_ids.length - 1]}` : ''
    }`;

    yield call(
      saveAs,
      data,
      `${fileTypes.join('_and_')}${orders}${
        parcel_id ? `_${parcel_id}` : ''
      }.pdf`
    );
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading CSV file'
    );
  }
}

export function printFileFailureSaga({ payload }) {
  if (payload.err) {
    showErrorMessage('Error!', payload.err);
  }
}

export function* fetchExportHistorySaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/orders/exports/',
    setQuerty: action.payload.set_querty,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchPendingFulfillmentAllOrdersSaga(action) {
  const { url } = yield generateURL({
    payload: {
      ...action.payload,
      statuses: ['PENDING_FULFILLMENT'],
    },
    url: '/orders/',
    options: { arrayFormat: 'comma' },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchPendingFulfillmentCommercialOrdersSaga(action) {
  const { url } = yield generateURL({
    payload: {
      ...action.payload,
      statuses: ['PENDING_FULFILLMENT'],
    },
    url: '/orders/',
    options: { arrayFormat: 'comma' },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* completeOrderSaga(action) {
  const { payload } = action;
  const url = `/orders/${payload.id}/complete`;
  yield api({
    action,
    method: 'post',
    url,
    showResponseError: true,
    successMessage: 'Successfully fulfilled order!',
    successNavigateTo: '/orders/',
  });
}

export function* completeOrderSuccessSaga(action) {
  const payload = { page: action.payload.inResponseTo.page };
  yield put({ type: actions.fetchPendingFulfillmentAllOrders, payload });
}

export function* createOrderImportSaga(action) {
  const formData = new FormData();
  formData.append('brand_id', action.payload.brand_id);
  formData.append('csv_file', action.payload.csv_file);
  yield api({
    action,
    method: 'POST',
    url: `/orders/imports`,
    data: formData,
    successMessage: 'Successfully started order import!',
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchOrders, fetchOrdersSaga),
    takeLatest(actions.fetchCommercialOrders, fetchCommercialOrdersSaga),
    takeLatest(actions.fetchOrder, fetchOrderSaga),
    takeLatest(actions.cancelOrder, cancelOrderSaga),
    takeLatest(actions.fetchOrderShipmentLabel, fetchOrderShipmentLabelSaga),
    takeLatest(
      actions.fetchOrderShipmentLabelSuccess,
      fetchOrderShipmentLabelSuccessSaga
    ),
    takeLatest(
      actions.fetchOrderShipmentPublicUrl,
      fetchOrderShipmentPublicUrlSaga
    ),
    takeLatest(
      actions.fetchOrderShipmentPublicUrlSuccess,
      fetchOrderShipmentPublicUrlSuccessSaga
    ),
    takeLatest(actions.createOrder, createOrderSaga),
    takeLatest(actions.updateOrder, updateOrderSaga),
    takeLatest(actions.capturePayment, capturePaymentSaga),
    takeLatest(actions.sendOrderBill, sendOrderBillSaga),
    takeLatest(actions.createCommercialOrder, createCommercialOrderSaga),
    takeLatest(actions.updateCommercialOrder, updateCommercialOrderSaga),
    takeLatest(actions.captureCommercialPayment, captureCommercialPaymentSaga),
    takeLatest(
      actions.captureCommercialPaymentSuccess,
      captureCommercialPaymentSuccessSaga
    ),
    takeLatest(actions.fetchCommercialReport, fetchCommercialReportSaga),
    takeLatest(
      actions.fetchCommercialReportSuccess,
      fetchCommercialReportSuccessSaga
    ),
    takeLatest(
      actions.fetchCommercialReportFailure,
      fetchCommercialReportFailureSaga
    ),
    takeLatest(
      actions.fetchPendingFulfillmentOrders,
      fetchPendingFulfillmentOrdersSaga
    ),
    takeLatest(
      actions.fetchPendingFulfillmentCommercialOrders,
      fetchPendingFulfillmentCommercialOrdersSaga
    ),
    takeLatest(actions.fetchOrderStatuses, fetchOrderStatusesSaga),
    takeLatest(actions.changeOrderStatus, changeOrderStatusSaga),
    takeLatest(actions.changeOrderStatusSuccess, changeOrderStatusSuccessSaga),
    takeLatest(
      actions.patchOrderandChangeStatus,
      patchOrderandChangeStatusSaga
    ),
    takeLatest(actions.patchCommercialOrder, patchCommercialOrderSaga),
    takeLatest(actions.patchOrder, patchOrderSaga),
    takeLatest(actions.fetchOrderRefund, fetchOrderRefundSaga),
    takeLatest(actions.refundOrder, refundOrderSaga),
    takeLatest(actions.sendRefundOrderBill, sendRefundOrderBillSaga),
    takeLatest(actions.confirmCommercialOrder, confirmCommercialOrderSaga),
    takeLatest(actions.printFile, printFileSaga),
    takeLatest(actions.printFileSuccess, printFileSuccessSaga),
    takeLatest(actions.printFileFailure, printFileFailureSaga),
    takeLatest(actions.fetchExportHistory, fetchExportHistorySaga),
    takeLatest(
      actions.fetchPendingFulfillmentAllOrders,
      fetchPendingFulfillmentAllOrdersSaga
    ),
    takeLatest(actions.completeOrder, completeOrderSaga),
    takeLatest(actions.completeOrderSuccess, completeOrderSuccessSaga),
    takeLatest(actions.createOrderImport, createOrderImportSaga),
  ]);
}
