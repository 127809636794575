import React, { useEffect, useState } from 'react';
import styles from './ReportsLayout.scss';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { Row, Col, CardBody, Card, Button, Spinner } from 'reactstrap';
import Breadcrumbs from 'components/Breadcrumbs';
import SalesDatesForm from '../SalesDatesForm';
import { format } from 'date-fns';
import classNames from 'classnames';
import { getEndDate, getMockDate, getStartDate } from '../../utils/date';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';

const getSelectionLabel = dateRange => {
  return `${format(dateRange.startDate, 'MM/dd/yyyy')} - ${format(
    dateRange.endDate,
    'MM/dd/yyyy'
  )}`;
};

const getRequestFormatDateWithUpdateHours = dataRange => {
  return {
    startDate: format(
      getStartDate(new Date(dataRange?.startDate)),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    ),
    endDate: format(
      getEndDate(new Date(dataRange?.endDate)),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    ),
  };
};

const ReportsLayout = ({
  title,
  onExport,
  onDateChange,
  onBrandChange,
  loading,
  children,
  actionChildren,
  exportButtonText,
  isDownloadDisabled,
  date,
  lastExportDate,
  setLastExportDate,
  disableDateRange,
  isExportPage = false,
}) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: date?.startDate ? new Date(date?.startDate) : getMockDate(),
      endDate: date?.endDate ? new Date(date?.endDate) : getEndDate(new Date()),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (isExportPage) {
      if (lastExportDate) {
        setDateRange([
          {
            startDate: new Date(date?.startDate),
            endDate: new Date(date?.endDate),
            key: 'selection',
          },
        ]);
        setLastExportDate(null);
      }
      if (onDateChange && !lastExportDate) {
        onDateChange(getRequestFormatDateWithUpdateHours(dateRange[0]));
      }
    }
    if (onDateChange && !isExportPage) {
      onDateChange(getRequestFormatDateWithUpdateHours(dateRange[0]));
    }
  }, [dateRange, onDateChange, lastExportDate, isExportPage]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <div className={classNames(styles.container, loading && styles.loading)}>
        {loading && (
          <div className={styles['spinner-container']}>
            <Spinner color="primary" />
          </div>
        )}
        <Card>
          <CardBody>
            <Row className="align-items-end">
              {isExportPage && (
                <Col md={3}>
                  <p className="mb-1">Brand</p>
                  <BrandsPaginatedField
                    onChange={onBrandChange}
                    isMulti={false}
                  />
                </Col>
              )}
              {!disableDateRange ? (
                <Col>
                  <p className="mb-1">Period</p>
                  <SalesDatesForm
                    label={getSelectionLabel(dateRange[0])}
                    value={dateRange}
                    exportButtonText={exportButtonText}
                    onChange={setDateRange}
                    onExport={onExport}
                    isDownloadDisabled={isDownloadDisabled}
                    calendarButtonText={
                      isExportPage ? 'Select period' : undefined
                    }
                  >
                    {actionChildren}
                  </SalesDatesForm>
                </Col>
              ) : (
                <Col md={3}>
                  <div className="d-flex h-100 w-100 justify-content-end align-items-end">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={onExport}
                      disabled={isDownloadDisabled}
                      className="waves-effect waves-light text-nowrap w-100"
                    >
                      <i className="ri-download-line align-middle mr-2" />
                      <span>{exportButtonText}</span>
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col md={12}>{children}</Col>
        </Row>
      </div>
    </PageWrapper>
  );
};

ReportsLayout.propTypes = {
  title: PropTypes.string.isRequired,
  isDownloadDisabled: PropTypes.bool,
  exportButtonText: PropTypes.string,
  onExport: PropTypes.func,
  children: PropTypes.element,
  actionChildren: PropTypes.element,
  onDateChange: PropTypes.func,
  loading: PropTypes.bool,
  date: PropTypes.object,
  lastExportDate: PropTypes.object,
  setLastExportDate: PropTypes.func,
  isExportPage: PropTypes.bool,
  onBrandChange: PropTypes.func,
  disableDateRange: PropTypes.bool,
};
export default ReportsLayout;
