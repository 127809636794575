export const getPreparedRefundData = refunds => {
  return refunds.map(refund => ({
    ...refund,
    returned_order_items: getRefundedOrderItems(refund.returned_order_items),
  }));
};

const getRefundedOrderItems = orderItems => {
  return orderItems.map(item => ({
    ...item,
    id: item?.product_id ?? item?.tasting_menu_id,
    title: item?.product_title ?? item.tasting_menu_title,
  }));
};
