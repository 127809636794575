import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';

export function* fetchDataSourcesSaga(action) {
  yield api({
    action,
    method: 'get',
    url: '/import_data_sources/',
  });
}

export function* runImportSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/import_data_sources/${action?.payload?.id}/run`,
    data: action.payload,
    successMessage: 'Successfully imported data!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchDataSources, fetchDataSourcesSaga),
    takeLatest(actions.runImport, runImportSaga),
  ]);
}
