import {
  all,
  takeEvery,
  takeLatest,
  race,
  take,
  put,
  delay,
  call,
} from 'redux-saga/effects';

import api from 'api';

import { generateURL } from 'utils/generateURL';
import { actions } from './slice';

export function* fetchCamerasSaga(action) {
  yield api({
    action,
    method: 'get',
    url: '/devices/?type=CAMERA&per_page=50',
  });
}

export function* fetchCameraSaga(action) {
  const { payload } = action;

  const { url } = yield generateURL({
    payload: action.payload,
    url: `/devices/${payload.id}`,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchCameraStatsSaga(action) {
  const { payload } = action;
  const startDate = encodeURIComponent(payload.date_range_start);
  const endDate = encodeURIComponent(payload.date_range_end);

  const url = payload.isCoreApi
    ? `${payload.url
        .replace('{warehouse_id}', payload.warehouseId)
        .replace(
          '{camera_id}',
          payload.id
        )}?date_range_start=${startDate}&date_range_end=${endDate}`
    : `/cameras/${payload.cameraIdentifier}${payload.url}?start=${startDate}&end=${endDate}`;

  if (action.type === actions.fetchCameraStatsPolling) {
    while (true) {
      try {
        yield put({
          type: actions.fetchCameraStats,
          payload,
        });
        yield delay(20000);
      } catch (err) {
        yield put({
          type: actions.fetchCameraStatsFailure,
          message: err,
        });
        yield put({ type: actions.fetchCameraStatsStopPolling });
      }
    }
  } else {
    yield api({
      baseURL: payload.isCoreApi ? null : 'https://ftc.test.getcellar.co/',
      action,
      method: 'get',
      url,
    });
  }
}

function* fetchCameraStatsPolling(action) {
  yield race({
    task: call(fetchCameraStatsSaga, action),
    cancel: take(actions.fetchCameraStatsStopPolling),
  });
}

export function* addCameraSaga(action) {
  yield api({
    action,
    data: action.payload,
    method: 'post',
    url: '/devices/',
  });
}

function* addCameraSuccessSaga() {
  yield fetchCamerasSaga({ type: actions.fetchCameras });
}

export function* removeCameraSaga(action) {
  const { payload } = action;
  const { url } = yield generateURL({
    payload,
    url: `/devices/${payload}`,
  });

  yield api({
    action,
    method: 'delete',
    url,
  });
}

function* removeCameraSuccessSaga() {
  yield fetchCamerasSaga({ type: actions.fetchCameras });
}

export function* updateCameraSaga(action) {
  const { payload } = action;
  const { url } = yield generateURL({
    url: `/devices/${payload.id}`,
  });

  yield api({
    action,
    data: action.payload,
    method: 'put',
    url,
  });
}

function* updateCameraSuccessSaga() {
  yield fetchCamerasSaga({ type: actions.fetchCameras });
}

export default function*() {
  yield all([takeLatest(actions.fetchCameras, fetchCamerasSaga)]);
  yield all([takeLatest(actions.addCamera, addCameraSaga)]);
  yield all([takeLatest(actions.addCameraSuccess, addCameraSuccessSaga)]);
  yield all([takeLatest(actions.removeCamera, removeCameraSaga)]);
  yield all([takeLatest(actions.removeCameraSuccess, removeCameraSuccessSaga)]);
  yield all([takeLatest(actions.updateCamera, updateCameraSaga)]);
  yield all([takeLatest(actions.updateCameraSuccess, updateCameraSuccessSaga)]);
  yield all([takeLatest(actions.fetchCamera, fetchCameraSaga)]);
  yield all([
    takeLatest(actions.fetchCameraStatsPolling, fetchCameraStatsPolling),
  ]);
  yield all([takeEvery(actions.fetchCameraStats, fetchCameraStatsSaga)]);
}
