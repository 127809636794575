import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';

const TablesFormPaginatedRoomsField = ({
  onChange,
  value,
  isDisabled,
  currentWarehouse,
}) => {
  const request = useApiRequest();

  const fetchTastingRooms = ({ page = 1, warehouse_id }) => {
    return request({
      url: `/tasting_rooms/?page=${page}&warehouse_id=${warehouse_id}`,
    });
  };

  const loadTastingRoomOptions = async (
    search,
    loadedOptions,
    { page, warehouse_id }
  ) => {
    try {
      const res = await fetchTastingRooms({
        page,
        warehouse_id,
      });

      return {
        options:
          res?.results?.filter(
            t => t.warehouse_id === currentWarehouse?.value
          ) || [],
        hasMore: res?.pagination?.next_page,
        additional: {
          page: page + 1,
          warehouse_id,
        },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      name="tasting_room_id"
      isSearchable={false}
      value={value}
      loadOptions={loadTastingRoomOptions}
      onChange={onChange}
      additional={{
        page: 1,
        warehouse_id: currentWarehouse?.value,
      }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      cacheUniqs={[currentWarehouse]}
      isDisabled={isDisabled}
      menuPlacement="top"
      maxMenuHeight={190}
    />
  );
};

TablesFormPaginatedRoomsField.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  currentWarehouse: PropTypes.object,
  value: PropTypes.any,
};

export default TablesFormPaginatedRoomsField;
