import React, { memo } from 'react';
import styles from './Chip.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Chip = ({ value, active, onClick, className }) => {
  return (
    <button
      type="button"
      className={classNames(
        styles.container,
        active && styles.activeContainer,
        className
      )}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

Chip.propTypes = {
  value: PropTypes.any.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default memo(Chip);
