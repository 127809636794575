import { takeLatest, all, takeEvery } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';

export function* fetchImportsSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/import_data_sources/import_customers_from_file`,
  });
}

export function* uploadImportSaga(action) {
  const formData = new FormData();
  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });
  yield api({
    action,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/import_data_sources/import_customers_from_file',
  });
}

function* uploadImportSuccessSaga() {
  // yield fetchImportsSaga({ type: actions.fetchImports });
}

export default function*() {
  yield all([takeEvery(actions.fetchImports, fetchImportsSaga)]);
  yield all([takeLatest(actions.uploadImport, uploadImportSaga)]);
  yield all([takeEvery(actions.uploadImportSuccess, uploadImportSuccessSaga)]);
}
