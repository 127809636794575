import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';

const DataTable = ({
  isPending,
  tables,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
}) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="1"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>

          <th data-priority="3">Seats</th>
          <th data-priority="3">Device</th>
          <th data-priority="3">Room</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {tables?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/tables/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/tables/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/tables/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.seats}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/devices/${item.device_id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.device_title}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/tasting_rooms/${item.tasting_room_id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.tasting_room_title}
              </Link>{' '}
            </td>
            <td>
              <>
                <Link
                  to={`/tables/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
                <Link
                  to={`/tables/${item.id}/duplicate`}
                  className="mr-3 text-primary"
                  id={`duplicate${item.id}`}
                >
                  <i className="mdi mdi-plus-box-multiple font-size-18" />
                </Link>
                <UncontrolledTooltip
                  placement="top"
                  target={`duplicate${item.id}`}
                >
                  Duplicate
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  tables: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};

export default DataTable;
