import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import s from './DataTable.scss';

const DataTable = ({ isPending, imports }) => {
  return !isPending && imports?.length === 0 ? (
    <h3>The list of imports is empty. </h3>
  ) : (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th data-priority="1">Status</th>
          <th data-priority="1">Brand</th>
          <th data-priority="1">File Name</th>
          <th data-priority="1">Processed Customers</th>
          <th data-priority="1">Created Customers</th>
          <th data-priority="1">Created Customers (Wine Club)</th>
          <th data-priority="1">Skipped Customers</th>
          <th data-priority="1">Created At</th>
          <th data-priority="1">Updated At</th>
          <th data-priority="1">Finished At</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {imports.map(item => {
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td
                className={cx([
                  'font-weight-bold',
                  s[item.status.toLowerCase()],
                ])}
              >
                {item.status.split('_').join(' ')}
              </td>
              <td>{item.brand.title}</td>
              <td>{item.filename}</td>
              <td>{item.customers_processed_count ?? '-'}</td>
              <td>{item.customers_created_count ?? '-'}</td>
              <td>{item.wine_club_members_created_count ?? '-'}</td>
              <td>{item.customers_skipped_count ?? '-'}</td>
              <td>
                {item.created_at
                  ? format(Date.parse(item.created_at), 'yyyy-MM-dd, h:mm aaa')
                  : '-'}
              </td>
              <td>
                {item.updated_at
                  ? format(Date.parse(item.created_at), 'yyyy-MM-dd, h:mm aaa')
                  : '-'}
              </td>
              <td>
                {item.finished_at
                  ? format(Date.parse(item.created_at), 'yyyy-MM-dd, h:mm aaa')
                  : '-'}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  imports: PropTypes.array,
};

export default DataTable;
