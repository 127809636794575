import PropTypes from 'prop-types';

export const OrderItemRowProps = {
  id: PropTypes.string.isRequired,
  warehouseId: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  productTitle: PropTypes.string,
  productId: PropTypes.number,
  productCount: PropTypes.number,
  productBottlesInCase: PropTypes.number,
  productPrice: PropTypes.number,
  selectedProductsIds: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  totalPrice: PropTypes.number,
  isCases: PropTypes.bool,
};
