import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  CardText,
  Spinner,
} from 'reactstrap';
import { PAYMENT_TYPES } from 'constants';
import styles from './RefundOrderInfo.scss';
import CardItem from 'components/CardItem';
import { isEmpty } from 'lodash';
import formatPrice from 'utils/formatPrice';
import Select from 'react-select';
import CustomerInfo from './CustomerInfo';

const notificationForAlternativeRefund =
  'Please be careful. ' +
  "The money won't be automatically returned to the card, " +
  'you will need to return it through an alternative payment processor ' +
  'that was used for this order.';

export const capitalizeString = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getCardText = card => {
  const cardText = isEmpty(card)
    ? 'The order was paid by CARD via alternative payment processor'
    : capitalizeString(`${card.brand} ****\u00A0${card.last4}`);
  return cardText;
};

const getCardOptionText = card => {
  const cardText = isEmpty(card)
    ? 'CARD'
    : `${card.brand} ****\u00A0${card.last4}`;
  return capitalizeString(cardText);
};

const orderItems = items =>
  Object.keys(items).reduce((prev, current) => prev + items[current].number, 0);

const RefundOrderInfo = ({
  order,
  total,
  setComment,
  onSubmit,
  setRefundType,
  orderRefundIsPending,
  refundType,
  selectedProducts,
}) => {
  const card = order?.payment_method;

  const totalPrice = order?.total_price - (order?.refund_amount ?? 0);
  const isCard = order?.payment_type?.includes('CARD');
  const isRefund = total > 0 || orderItems(selectedProducts) > 0;

  const optionsForType = [];
  if (isCard) {
    optionsForType.push({
      label: getCardOptionText(card),
      value: order?.payment_type,
    });
  }
  optionsForType.push({ label: 'CASH', value: 'CASH' });

  return (
    <>
      <Row xs="1">
        <Col>
          <Card>
            <CardBody className={styles.refundBlock}>
              <div className={styles.refundContent}>
                <div className={styles.selectBlock}>
                  <CardText className={styles.selectTotal}>
                    ${formatPrice(total)}
                  </CardText>
                  <Select
                    menuPlacement="top"
                    isSearchable={false}
                    value={refundType}
                    className={styles.select}
                    placeholder="Select type"
                    options={optionsForType}
                    onChange={data => setRefundType(data)}
                  />
                </div>
                <Button
                  disabled={!isRefund || orderRefundIsPending || !refundType}
                  color="primary"
                  onClick={onSubmit}
                  className={styles.button}
                >
                  {orderRefundIsPending ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className={styles.loading}>Loading...</span>
                    </>
                  ) : (
                    'Refund'
                  )}
                </Button>
              </div>
              {refundType?.value ===
                PAYMENT_TYPES.CARD_ALTERNATIVE.apiValue && (
                <CardText className={styles.refundDescription}>
                  {notificationForAlternativeRefund}
                </CardText>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomerInfo order={order} />
      <Row xs="2">
        <Col>
          <CardItem title="Order Total" value={`$${formatPrice(totalPrice)}`} />
        </Col>
        <Col>
          <CardItem
            title="Payment method"
            value={!isCard ? 'CASH' : getCardText(card)}
          />
        </Col>
      </Row>
      <Row xs="1">
        <Col>
          <Input
            className={styles.textarea}
            type="textarea"
            onChange={e => setComment(e.target.value)}
            placeholder="Comment..."
          />
        </Col>
      </Row>
    </>
  );
};

RefundOrderInfo.propTypes = {
  order: PropTypes.object,
  total: PropTypes.number,
  setComment: PropTypes.func,
  onSubmit: PropTypes.func,
  setRefundType: PropTypes.func,
  orderRefundIsPending: PropTypes.bool,
  refundType: PropTypes.object,
  selectedProducts: PropTypes.object,
};

export default memo(RefundOrderInfo);
