import { setHours, isBefore } from 'date-fns';

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateURL = str => {
  return str.indexOf('://') > -1;
};

const convertTo24 = time => {
  const split = time.split(' ');
  let num = 0;
  if (split[1].indexOf('p') > -1) {
    num = 12;
  }

  num += Number(split[0].split(':')[0]);
  return num;
};

export const validateTimeFrame = (start, end) => {
  const startDate = setHours(new Date(), convertTo24(start));
  const endDate = setHours(new Date(), convertTo24(end));

  return !isBefore(endDate, startDate);
};
