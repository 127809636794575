import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/HostsForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentDidMount from 'hooks/useComponentDidMount';

import { actions as brandsActions } from 'models/brands/slice';
import { isPendingSelector as brandsIsPendingSelector } from 'models/brands/selectors';

import { actions as hostsActions } from 'models/hosts/slice';
import { isPendingSelector as hostsIsPendingSelector } from 'models/hosts/selectors';

const InviteHost = ({ title }) => {
  const fetchBrands = useAction(brandsActions.fetchBrands);

  const hostsIsPending = useSelector(hostsIsPendingSelector);
  const brandsIsPending = useSelector(brandsIsPendingSelector);

  const [currentBrandID, setCurrentBrandID] = useState(null);

  const isPending = hostsIsPending || brandsIsPending;
  const inviteHost = useAction(hostsActions.inviteHost);

  useComponentDidMount(() => {
    fetchBrands();
  });

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Invite new host"
        breadcrumbItems={[{ title: 'Back to hosts', link: '/hosts/' }]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Invite</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Invite"
            currentBrand={currentBrandID}
            setCurrentBrand={setCurrentBrandID}
            onSubmit={inviteHost}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

InviteHost.propTypes = {
  title: PropTypes.string,
};

export default InviteHost;
