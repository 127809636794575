import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchTerminalsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/terminals',
    setQuery: action.payload.set_query,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
export function* fetchTerminalSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/terminals/${action.payload}`,
  });
}

export function* createTerminalSaga(action) {
  try {
    yield api({
      action,
      method: 'post',
      url: `/terminals`,
      data: action.payload,
      successMessage: 'Successfully created new terminal!',
      successNavigateTo: `/terminals/`,
    });
  } catch (error) {
    console.log('error:', error);
  }
}

export function* updateTerminalSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/terminals/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated terminal!',
    successNavigateTo: `/terminals/`,
  });
}

export function* deleteTerminalSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/terminals/${action.payload}`,
    successMessage: 'Successfully deleted terminal!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTerminals, fetchTerminalsSaga),
    takeLatest(actions.deleteTerminal, deleteTerminalSaga),
    takeLatest(actions.fetchTerminal, fetchTerminalSaga),
    takeLatest(actions.createTerminal, createTerminalSaga),
    takeLatest(actions.updateTerminal, updateTerminalSaga),
  ]);
}
