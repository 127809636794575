import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchWineclubsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/wineclubs/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchWineclubSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/wineclubs/${action.payload}`,
  });
}

export function* createWineclubSaga(action) {
  const formData = new FormData();

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });

  yield api({
    action,
    method: 'post',
    url: `/wineclubs/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully created new wineclub!',
    successNavigateTo: `/wineclubs/`,
  });
}

export function* updateWineclubSaga(action) {
  const formData = new FormData();

  Object.keys(action.payload).forEach(key => {
    if (key === 'tiers') {
      if (action.payload[key]?.lenght > 0) {
        formData.append(key, JSON.stringify(action.payload[key]));
      } else {
        formData.append(key, JSON.stringify([]));
      }
    } else {
      formData.append(
        key,
        action.payload[key] === null ? '' : action.payload[key]
      );
    }
  });

  yield api({
    action,
    method: 'patch',
    url: `/wineclubs/${action.payload.id}`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    successMessage: 'Successfully updated wineclub!',
    successNavigateTo: `/wineclubs/`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchWineclubs, fetchWineclubsSaga),
    takeLatest(actions.fetchWineclub, fetchWineclubSaga),
    takeLatest(actions.createWineclub, createWineclubSaga),
    takeLatest(actions.updateWineclub, updateWineclubSaga),
  ]);
}
