export const loadFromLocalStorageByKey = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const saveToLocalStorage = (key, value) => {
  try {
    if (value !== null) {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } else {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.error(error);
  }
};
