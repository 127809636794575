import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const transfersSlice = createSlice({
  name: 'transfers',
  initialState: {
    isPending: false,
    transfers: [],
    inProgressTransfers: [],
    inProgressTransfersPagination: {},
    inProgressTransfersNotification: [],
    inProgressTransfersNotificationPagination: {},
    completedTransfers: [],
    transfer: {},
    pagination: {},
    quantities: {},
  },
  reducers: {
    fetchTransfers: state => {
      state.isPending = true;
    },
    fetchTransfersSuccess(state, { payload }) {
      state.isPending = false;
      state.transfers = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchTransfersFailure: state => {
      state.isPending = false;
    },
    fetchInProgressTransfers: state => {
      state.isPending = true;
    },
    fetchInProgressTransfersSuccess(state, { payload }) {
      state.isPending = false;
      state.inProgressTransfers = payload.data.results;
      state.inProgressTransfersPagination = payload.data.pagination;
    },
    fetchInProgressTransfersFailure: state => {
      state.isPending = false;
    },
    fetchInProgressTransfersNotification: state => {
      state.isPending = true;
    },
    fetchInProgressTransfersNotificationSuccess(state, { payload }) {
      state.isPending = false;
      state.inProgressTransfersNotification = payload.data.results;
      state.inProgressTransfersNotificationPagination = payload.data.pagination;
    },
    fetchInProgressTransfersNotificationFailure: state => {
      state.isPending = false;
    },
    fetchCompletedTransfers: state => {
      state.isPending = true;
    },
    fetchCompletedTransfersSuccess(state, { payload }) {
      state.isPending = false;
      state.completedTransfers = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchCompletedTransfersFailure: state => {
      state.isPending = false;
    },
    fetchTransfer: state => {
      state.isPending = true;
    },
    fetchTransferSuccess(state, { payload }) {
      state.isPending = false;
      state.transfer = payload.data;
    },
    fetchTransferFailure: state => {
      state.isPending = false;
    },
    createTransfer: state => {
      state.isPending = true;
    },
    createTransferSuccess(state, { payload }) {
      state.transfers[payload.data.id] = payload.data;
      state.transfer = payload.data;
      state.isPending = false;
    },
    createTransferFailure: state => {
      state.isPending = false;
    },
    deleteTransfer: state => {
      state.isPending = true;
    },
    deleteTransferSuccess(state, { payload }) {
      delete state.transfers[payload.inResponseTo];
      state.isPending = false;
    },
    deleteTransferFailure: state => {
      state.isPending = false;
    },
    updateTransfer: state => {
      state.isPending = true;
    },
    updateTransferSuccess(state, { payload }) {
      state.transfers[payload.inResponseTo.id] = payload.data;
      state.isPending = false;
    },
    updateTransferFailure: state => {
      state.isPending = false;
    },
    setQuantity(state, { payload: { id, value } }) {
      state.quantities[id] = value;
    },
    resetQuantities(state) {
      state.quantities = {};
    },
    disputeTransfer: state => {
      state.isPending = true;
    },
    disputeTransferSuccess(state, { payload }) {
      state.isPending = false;
      state.transfer = payload.data;
    },
    disputeTransferFailure: state => {
      state.isPending = false;
    },
    approveTransfer: state => {
      state.isPending = true;
    },
    approveTransferSuccess(state, { payload }) {
      state.isPending = false;
      state.transfer = payload.data;
    },
    approveTransferFailure: state => {
      state.isPending = false;
    },
    resubmitTransfer: state => {
      state.isPending = true;
    },
    resubmitTransferSuccess(state, { payload }) {
      state.isPending = false;
      state.transfer = payload.data;
    },
    resubmitTransferFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(transfersSlice.actions);

export default transfersSlice.reducer;
