import React from 'react';
import styles from './WarehouseInventoryLimitsTable.scss';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import PaginationTable from '../PaginationTable';
import PropTypes from 'prop-types';

const prepareValue = value => value ?? '-';

const WarehouseInventoryLimitsTable = ({
  data,
  pending,
  pagination,
  onPageChange,
}) => {
  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-3">Inventory Limit Notifications</h4>
        <Row>
          <Col md={12}>
            {pagination?.total_count > 0 ? (
              <PaginationTable
                pagination={pagination}
                setCurrentPage={onPageChange}
              >
                <Table
                  className={pending && styles.pendingContainer}
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th data-priority="1">Product ID</th>
                      <th data-priority="3">Product Title</th>
                      <th data-priority="1">Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => (
                      <tr key={item.id.toString()}>
                        <td>{prepareValue(item?.product_id)}</td>
                        <td>{prepareValue(item?.product_title)}</td>
                        <td>{prepareValue(item?.limit_value)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </PaginationTable>
            ) : (
              <p>Missing product limits!</p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

WarehouseInventoryLimitsTable.propTypes = {
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  pending: PropTypes.bool,
  onPageChange: PropTypes.func,
};
export default WarehouseInventoryLimitsTable;
