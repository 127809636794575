import cx from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { Button, Nav, NavItem, NavLink, Tooltip } from 'reactstrap';
import { CAMERA_STATUS } from '../../pages/FootTraffic/constants';
import styles from './CamerasNavigationTabs.scss';

const getIconByStatus = status => {
  if (status === CAMERA_STATUS.OFFLINE) {
    return 'ri-cloud-off-line';
  }
  return 'ri-settings-5-fill';
};

const getToolTipContent = status => {
  if (status === CAMERA_STATUS.OFFLINE) {
    return 'Camera Offline';
  }
  return 'Settings';
};

const getTabTitle = tab => {
  if (tab.status === CAMERA_STATUS.OFFLINE || !tab.title) {
    return '[ERROR]';
  }

  return tab.title;
};

const CameraNavigationTabs = ({
  tabs,
  onSelectTab,
  selectedId,
  onSelectCameraSettings,
  onClickAddCamera,
}) => {
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const toggle = () => setTooltipOpened(!tooltipOpened);

  return (
    <Nav tabs className={cx('nav-tabs-simple mb-4', styles.navigationTabs)}>
      {tabs.map(tab => (
        <NavItem
          active={selectedId === tab.id}
          className={styles.tab}
          key={`camera-${tab.id}`}
        >
          <NavLink
            active={selectedId === tab.id}
            className={cx(styles.tab)}
            id={`camera-tab-${tab.id}`}
            onClick={() => {
              onSelectTab(tab.id);
            }}
          >
            <div
              className={cx(
                styles.tabBullet,
                styles[`tabBullet--${tab.status}`]
              )}
            />
            {getTabTitle(tab)}
            {selectedId === tab.id && (
              <Button
                className={cx(
                  styles.toggleCamera,
                  styles[`toggleCamera--${tab.status}`]
                )}
                onClick={() => {
                  if (tab.status === CAMERA_STATUS.OFFLINE) {
                    return;
                  }

                  onSelectCameraSettings(tab);
                }}
              >
                <Tooltip
                  target={`icon-${tab.id}`}
                  isOpen={tooltipOpened}
                  toggle={toggle}
                >
                  {getToolTipContent(tab.status)}
                </Tooltip>
                <i
                  className={getIconByStatus(tab.status)}
                  id={`icon-${tab.id}`}
                />
              </Button>
            )}
          </NavLink>
        </NavItem>
      ))}
      <button
        className={styles.addCamera}
        onClick={onClickAddCamera}
        id="add-new-camera-button"
      >
        <i className="ri-add-line" />
        <span>Add a Camera</span>
      </button>
    </Nav>
  );
};

CameraNavigationTabs.propTypes = {
  tabs: PropTypes.array,
  selectedId: PropTypes.string,
  onSelectTab: PropTypes.func,
  onSelectCameraSettings: PropTypes.func,
  onClickAddCamera: PropTypes.func,
};

export default CameraNavigationTabs;
