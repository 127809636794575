import React, { useCallback, useEffect, useState } from 'react';
import ReportsLayout from 'components/ReportsLayout';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions as reportsActions } from 'models/reports/slice';
import useSelector from 'hooks/useSelector';
import { wineAssociatesSalesSelector } from 'models/reports/selectors';
import ReportFilters from '../../components/ReportFilters';

import formatPrice from 'utils/formatPrice';

import { Row, Col, Table, CardBody, Card } from 'reactstrap';

import styles from './ReportsAssociatesSummary.scss';

const ReportsAssociatesSummary = ({ title }) => {
  const [dateRange, setDateRange] = useState({});
  const [filters, setFilters] = useState({});

  const fetchWineAssociatesXslx = useAction(
    reportsActions.fetchWineAssociatesXslx
  );

  const fetchWineAssociates = useAction(reportsActions.fetchWineAssociates);
  const wineAssociatesSales = useSelector(wineAssociatesSalesSelector);

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      fetchWineAssociates({
        date_range_start: startDate,
        date_range_end: endDate,
        sales_associate_id: filters?.selectedAssociate?.value,
        sales_associate_name: filters?.selectedAssociate?.label,
        brand_id: filters?.selectedBrands?.value,
      });
    }
  }, [dateRange, filters]);

  const onExport = useCallback(() => {
    const { startDate, endDate } = dateRange;
    fetchWineAssociatesXslx({
      date_range_start: startDate,
      date_range_end: endDate,
      sales_associate_id: filters?.selectedAssociate?.value,
      sales_associate_name: filters?.selectedAssociate?.label,
      brand_id: filters?.selectedBrands?.value,
    });
  }, [fetchWineAssociatesXslx, dateRange, filters]);

  return (
    <ReportsLayout
      title={title}
      exportButtonText="Export to CSV"
      onExport={onExport}
      onDateChange={setDateRange}
    >
      <ReportFilters
        setFilters={setFilters}
        showAssociate
        showBrand
        isInitialLabelBrand
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Table bordered responsive>
                <tbody className={styles.table}>
                  <tr>
                    <td>Sales associate name</td>
                    <td>{wineAssociatesSales?.wine_associate ?? ''}</td>
                  </tr>
                  <tr>
                    <td>Number of orders</td>
                    <td>{wineAssociatesSales?.number_of_orders ?? ''}</td>
                  </tr>
                  <tr>
                    <td>Total revenue</td>
                    <td>
                      {formatPrice(
                        Number(wineAssociatesSales?.total_revenue),
                        'CAD'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>% of overall sales</td>
                    <td>{`${wineAssociatesSales?.percent_overall_sales}%`}</td>
                  </tr>
                  <tr>
                    <td>Total tips</td>
                    <td>
                      {formatPrice(
                        Number(wineAssociatesSales?.total_tips),
                        'CAD'
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ReportsLayout>
  );
};

ReportsAssociatesSummary.propTypes = {
  title: PropTypes.string,
};
export default ReportsAssociatesSummary;
