import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import DataTable from './DataTable/DataTable';

import {
  importsSelector,
  isPendingSelector,
  importIsPendingSelector,
  paginationSelector,
  isUploadModalOpenedSelector,
} from 'models/importCustomers/selectors';
import { actions as importCustomerActions } from 'models/importCustomers/slice';
import FileInput from '../../components/FileField/FileInput';
import BrandsPaginatedField from '../../components/PaginatedFields/BrandsPaginatedField';
import useAction from '../../hooks/useAction';
import useSelector from '../../hooks/useSelector';

const ImportCustomers = ({ title }) => {
  const fetchImports = useAction(importCustomerActions.fetchImports);
  const uploadCustomers = useAction(importCustomerActions.uploadImport);
  const setIsUploadModalOpened = useAction(
    importCustomerActions.setIsUploadModalOpened
  );
  const pagination = useSelector(paginationSelector);
  const imports = useSelector(importsSelector);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page || 1);

  const isPending = useSelector(isPendingSelector);
  const importIsPending = useSelector(importIsPendingSelector);
  const isUploadModalOpened = useSelector(isUploadModalOpenedSelector);
  const [file, setFile] = useState(null);
  const [brand, setBrand] = useState(null);

  const [sortField, setSortField] = useState('created_at');

  const [sortDirection, setSortDirection] = useState('desc');

  const onAddFileHandler = useCallback(
    files => {
      setFile(files[0]);
    },
    [file]
  );

  useEffect(() => {
    fetchImports({
      page: currentPage,
      per_page: 50,
    });
  }, [fetchImports]);

  const handleSaveClick = useCallback(() => {
    uploadCustomers({
      file,
      brand_id: brand.id,
    });
  }, [file, brand]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row className="mt-4 mb-4">
        <Col lg={12}>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              setIsUploadModalOpened(true);
            }}
          >
            <i className="ri-upload-line align-middle mr-2" /> Import List
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-4">
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending || importIsPending}
                  imports={imports}
                  setSortField={setSortField}
                  setSortDirection={setSortDirection}
                  sortField={sortField}
                  sortDirection={sortDirection}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isUploadModalOpened && (
        <ConfirmationPopup
          title="Import Customers list"
          active={isUploadModalOpened}
          setActive={value => {
            setIsUploadModalOpened(value);
          }}
          submitButtonTitle="Import"
          submitButtonColor="primary"
          submitButtonDisabled={!file || !brand}
          onSaveClick={handleSaveClick}
        >
          <div className="mb-4">
            <p className="font-weight-bold mb-1">Brand</p>
            <BrandsPaginatedField
              isMulti={false}
              onChange={value => {
                setBrand(value);
              }}
            />
          </div>
          <FileInput
            accept="text/csv"
            onAddFile={onAddFileHandler}
            multiple={false}
            label={file ? file.name : 'File'}
          />
        </ConfirmationPopup>
      )}
    </PageWrapper>
  );
};

ImportCustomers.propTypes = {
  title: PropTypes.string,
};

export default ImportCustomers;
