import { createSelector } from 'reselect';

export const rootSelector = state => state.orders;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const isPendingChangeStatusSelector = createSelector(
  rootSelector,
  ({ isPendingChangeStatus }) => isPendingChangeStatus
);

export const isSendBillPendingSelector = createSelector(
  rootSelector,
  ({ isSendBillPending }) => isSendBillPending
);

export const isPendingActionSelector = createSelector(
  rootSelector,
  ({ isPendingAction }) => isPendingAction
);

export const ordersSelector = createSelector(
  rootSelector,
  ({ orders }) => orders
);

export const pendingFulfillmentOrdersSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentOrders }) => pendingFulfillmentOrders
);

export const pendingFulfillmentAllOrdersSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentAllOrders }) => pendingFulfillmentAllOrders
);

export const pendingFulfillmentCommercialOrdersSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentCommercialOrders }) => pendingFulfillmentCommercialOrders
);

export const pendingFulfillmentCommercialOrdersPaginationSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentCommercialOrdersPagination }) =>
    pendingFulfillmentCommercialOrdersPagination
);

export const ordersIdsSelector = createSelector(
  rootSelector,
  ({ orders_ids }) => orders_ids
);

export const pendingFulfillmentAllOrdersPaginationSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentAllOrdersPagination }) =>
    pendingFulfillmentAllOrdersPagination
);

export const orderSelector = createSelector(rootSelector, ({ order }) => order);

export const ordersRefundPendingSelector = createSelector(
  rootSelector,
  ({ ordersRefundPending }) => ordersRefundPending
);

export const ordersRefundSelector = createSelector(
  rootSelector,
  ({ ordersRefund }) => ordersRefund
);

export const ordersRefundByIdSelector = id =>
  createSelector(rootSelector, ({ ordersRefund }) => ordersRefund?.[id]);

export const createdOrderSelector = createSelector(
  rootSelector,
  ({ createdOrder }) => createdOrder
);

export const productsInOrderSelector = createSelector(
  rootSelector,
  ({ productsInOrder }) => productsInOrder
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const pendingFulfillmentPaginationSelector = createSelector(
  rootSelector,
  ({ pendingFulfillmentPagination }) => pendingFulfillmentPagination
);

export const activeTabSelector = createSelector(
  rootSelector,
  ({ createOrderTab }) => createOrderTab
);

export const paymentStatusModalOpenSelector = createSelector(
  rootSelector,
  ({ paymentStatusModalOpen }) => paymentStatusModalOpen
);

export const isReportPendingSelector = createSelector(
  rootSelector,
  ({ isReportPending }) => isReportPending
);

export const isOrderSendRefundPending = createSelector(
  rootSelector,
  ({ orderSendRefundPending }) => orderSendRefundPending
);

export const exportHistoryPaginationSelector = createSelector(
  rootSelector,
  ({ exportHistoryPagination }) => exportHistoryPagination
);

export const exportHistorySelector = createSelector(
  rootSelector,
  ({ exportHistory }) => exportHistory
);

export const isExportHistoryPendingSelector = createSelector(
  rootSelector,
  ({ isExportHistoryPending }) => isExportHistoryPending
);

export const orderImportSyncSelector = createSelector(
  rootSelector,
  ({ orderImportSync }) => orderImportSync
);
