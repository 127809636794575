import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as productsActions } from 'models/products/slice';

import DataTable from './DataTable';

import {
  foodAllergiesSelector,
  isPendingSelector,
  foodAllergyPaginationSelector,
} from 'models/products/selectors';

const FoodAllergiesSummary = ({ title }) => {
  const fetchAllergies = useAction(productsActions.fetchFoodAllergies);

  const allergies = useSelector(foodAllergiesSelector);
  const pending = useSelector(isPendingSelector);
  const pagination = useSelector(foodAllergyPaginationSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchAllergies({
        page: currentPage,
        ...filters,
      });
    }
  }, [fetchAllergies, currentPage, filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    disabled={pending}
                    hasCreateItem
                    createItemLink="/food-allergies/create"
                    placeholder="Search... (By allergy name)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable isPending={pending} allergies={allergies} />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

FoodAllergiesSummary.propTypes = {
  title: PropTypes.string,
};

export default FoodAllergiesSummary;
