import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { selectStyles } from 'constants/selectStyles';
import { getPreparedCustomerOption } from 'utils/getPreparedCustomerOption';
import * as queryString from 'query-string';

const initialLabel = {
  value: undefined,
  label: 'All associates',
};

const AssociatesPaginatedField = ({
  onChange,
  isMulti,
  value,
  isInitialLabel,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchAssociates = ({ page = 1, search }) => {
    const stringified = queryString.stringify(
      {
        page,
        is_active: true,
        customer_info_contains: search,
        role_is: 'host',
      },
      {
        arrayFormat: 'comma',
      }
    );
    return apiRequest({
      url: `/users/?${stringified}`,
    });
  };

  const loadAssociates = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchAssociates({ page, search });
      const options = response?.results.map(getPreparedCustomerOption);
      return {
        options:
          isInitialLabel && page === 1 ? [initialLabel, ...options] : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="associates"
      isSearchable
      value={value || ''}
      placeholder="Select associate..."
      additional={{
        page: 1,
      }}
      loadOptions={loadAssociates}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

AssociatesPaginatedField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  isMulti: PropTypes.bool,
  isInitialLabel: PropTypes.bool,
  warehouseId: PropTypes.number,
};

export default AssociatesPaginatedField;
