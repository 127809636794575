export const getProductsQuery = (ids, labels, initial = []) => {
  const arrTitle = labels?.split(',');

  if (!ids) {
    return initial;
  }

  return ids?.split(',')?.map((id, index) => ({
    value: Number(id) || id,
    label: arrTitle?.[index],
  }));
};
