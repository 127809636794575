import React, { memo } from 'react';
import styles from './Popup.scss';
import PropTypes from 'prop-types';
import PopupLayout from './PopupLayout';
import classNames from 'classnames';

const PopupBase = ({ className, title, onClose, children }) => {
  return (
    <PopupLayout onClose={onClose}>
      <div className={classNames(styles.popupBase, className)}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {children}
      </div>
    </PopupLayout>
  );
};

PopupBase.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default memo(PopupBase);
