import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';

const DropdownItemId = Object.freeze({
  EDIT: 'edit',
  DELETE: 'delete',
});

const ProductPresetsActionDropdown = ({ onDelete, product }) => {
  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.DELETE]: onDelete,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/products-presets/${product.id}/edit`,
    },
    {
      id: DropdownItemId.DELETE,
      icon: <i className="mdi mdi-delete font-size-18 mr-2" />,
      label: 'Delete',
    },
  ];

  return (
    <ActionDropdown
      onItemClick={onItemClickHandler}
      items={dropdownItem}
      right
    />
  );
};

ProductPresetsActionDropdown.propTypes = {
  onDelete: PropTypes.func,
  product: PropTypes.object,
};

export default ProductPresetsActionDropdown;
