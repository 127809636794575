import React, { useState } from 'react';
import styles from './RecoverPassword.scss';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';
import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions } from 'models/user/slice';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/user/selectors';
import RecoverPasswordPopup from 'components/Popup/RecoverPasswordPopup';
import EmailField from 'components/Fields/EmailField';

const RecoverPassword = ({ title }) => {
  const query = useQuery();
  const recoverPassword = useAction(actions.recoverPassword);
  const loading = useSelector(isPendingSelector);
  const [recoverModal, setRecoverModal] = useState(false);
  const [email, setEmail] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const onSubmit = (_, data) => {
    recoverPassword(data);
    setRecoverModal(true);
  };

  return (
    <PageWrapper title={title}>
      {recoverModal && (
        <RecoverPasswordPopup
          onClose={() => setRecoverModal(false)}
          loading={loading}
        />
      )}
      <div className={styles.container}>
        <h4 className={styles.title}>Reset Password</h4>
        <AvForm
          onValidSubmit={onSubmit}
          model={{ email: query.get('email') }}
          className={styles.form}
        >
          <EmailField
            className="auth-form-group-custom"
            icon={<i className="ri-mail-line auti-custom-input-icon" />}
            name="email"
            label="Email"
            isRequired
            placeholder="Enter email"
            value={email}
            onChange={handleEmailChange}
          />
          <Button color="primary" type="submit">
            Reset Password
          </Button>
        </AvForm>
      </div>
    </PageWrapper>
  );
};

RecoverPassword.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RecoverPassword;
