import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import NumberField from '../../Fields/NumberField';
import styles from './CommissionPercentages.scss';
import PropTypes from 'prop-types';

const CommissionPercentages = ({ percentagesData, onChange }) => {
  const onPercentageChange = (event, companyType) => {
    const { valueAsNumber } = event.target;
    const value =
      Number.isNaN(valueAsNumber) || valueAsNumber < 0
        ? 0
        : Math.min(Number(valueAsNumber), 100);
    onChange?.({ ...percentagesData, [companyType]: value });
  };

  return (
    <Row>
      <Col md={6}>
        <Table bordered responsive>
          <thead>
            <th>Company Type</th>
            <th>Commission Percentage</th>
          </thead>
          <tbody>
            {Object.keys(percentagesData).map(companyType => (
              <tr key={companyType}>
                <td className={styles.fieldCell}>{companyType}</td>
                <td className={styles.fieldWrapper} width="50%">
                  <NumberField
                    className={styles.field}
                    name={companyType}
                    value={percentagesData[companyType]}
                    isRequired={false}
                    min="0"
                    max="100"
                    onChange={event => onPercentageChange(event, companyType)}
                    errorMessage={`${companyType} commission percentage must be a number between 0 and 100`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

CommissionPercentages.propTypes = {
  percentagesData: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default CommissionPercentages;
