import React, { useEffect, useMemo, useState } from 'react';
import styles from './OrderItemRow.scss';
import { UncontrolledTooltip } from 'reactstrap';
import WarehouseInventoryPaginatedField from '../../PaginatedFields/WarehouseInventoryPaginatedField';
import useSelector from 'hooks/useSelector';
import { productCountsSelector } from 'models/products/selectors';
import formatPrice from 'utils/formatPrice';
import { getAvailableCasesCount, getCasePrice } from '../utils';
import { OrderItemRowProps } from './OrderItemRowProps';
import classNames from 'classnames';

const OrderItemRowWithCases = ({
  id,
  number,
  numberCases,
  warehouseId,
  productTitle,
  productCount,
  productBottlesInCase,
  productId,
  productPrice,
  selectedProductsIds,
  onChange,
  onDelete,
  totalPrice,
}) => {
  const [count, setCount] = useState(0);
  const productCounts = useSelector(productCountsSelector);

  const availableCasesCount = getAvailableCasesCount(
    count,
    productBottlesInCase
  );
  const casePrice = getCasePrice(productPrice, productBottlesInCase);

  const isDisabledRow = useMemo(
    () => !selectedProductsIds.includes(productId),
    [selectedProductsIds, productId]
  );

  useEffect(() => {
    const actualProductCount = productCount ?? productCounts?.[productId] ?? 0;
    setCount(actualProductCount);
  }, [productId, productCount, productCounts]);

  const onValueChangeCases = event => {
    const { value } = event.target;
    let preparedInputValue = '';

    if (value.match(/^((0[1-9]))/)) {
      const preparedValue = value.replace(/^0+/, '') || 0;
      preparedInputValue = Math.min(+preparedValue, availableCasesCount);
    } else if (value.match(/^((0|([1-9][0-9]*)))$/)) {
      preparedInputValue = Math.min(Number(value), availableCasesCount);
    }

    onChange?.({ numberCases: preparedInputValue });
  };

  const onValueBlurCases = event => {
    const { value } = event.target;

    onChange?.({ numberCases: Number(value) || 0 });
  };

  const onValueChangeBottles = event => {
    const { value } = event.target;
    let preparedInputValue = '';

    if (value.match(/^((0[1-9]))/)) {
      const preparedValue = value.replace(/^0+/, '') || 0;
      preparedInputValue = Math.min(+preparedValue, count);
    } else if (value.match(/^((0|([1-9][0-9]*)))$/)) {
      preparedInputValue = Math.min(Number(value), count);
    }

    onChange?.({ number: preparedInputValue });
  };

  const onValueBlurBottles = event => {
    const { value } = event.target;

    onChange?.({ number: Number(value) || 0 });
  };

  const onProductChange = data => {
    const productInBottle = data?.product_bottles_in_case || 0;
    onChange?.({
      ...data,
      numberCases: productInBottle > 0 ? 1 : 0,
      number: 0,
    });
  };

  return (
    <tr>
      <td className={styles.cell}>
        <div className={styles.inventoryCell}>
          <button
            onClick={onDelete}
            type="button"
            className={styles.trashButton}
          >
            <i className="mdi mdi-trash-can" id={`delete${id}`} />
          </button>
          <UncontrolledTooltip placement="top" target={`delete${id}`}>
            Delete this product from order
          </UncontrolledTooltip>
          <WarehouseInventoryPaginatedField
            onChange={onProductChange}
            warehouseId={warehouseId}
            options={{
              product_types: ['Wine', 'Merchandise'],
            }}
            filterOption={({ value }) => !selectedProductsIds.includes(value)}
            defaultValue={{
              value: productId,
              label: productTitle,
            }}
          />
        </div>
      </td>
      <td>{productBottlesInCase}</td>
      <td>{count}</td>
      <td>{availableCasesCount}</td>
      <td
        className={classNames(
          styles.inputWidth,
          isDisabledRow && styles.disabledCell
        )}
      >
        <input
          type="text"
          name="quantityCases"
          value={numberCases}
          className="form-control"
          onChange={onValueChangeCases}
          onBlur={onValueBlurCases}
          disabled={isDisabledRow}
        />
      </td>
      <td
        className={classNames(
          styles.inputWidth,
          isDisabledRow && styles.disabledCell
        )}
      >
        <input
          type="text"
          name="quantity"
          value={number}
          className="form-control"
          onChange={onValueChangeBottles}
          onBlur={onValueBlurBottles}
          disabled={isDisabledRow}
        />
      </td>
      <td className={(isDisabledRow && styles.disabledCell) || ''}>
        $ {formatPrice(productPrice)}
      </td>
      <td className={(isDisabledRow && styles.disabledCell) || ''}>
        $ {formatPrice(casePrice)}
      </td>
      <td className={(isDisabledRow && styles.disabledCell) || ''}>
        $ {formatPrice(totalPrice)}
      </td>
    </tr>
  );
};

OrderItemRowWithCases.propTypes = OrderItemRowProps;
export default OrderItemRowWithCases;
