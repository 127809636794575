import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as acquirersActions } from 'models/acquirers/slice';
import {
  acquirersSelector,
  isPendingSelector as acquirersIsPendingSelector,
  paginationSelector,
} from 'models/acquirers/selectors';

import DataTable from './DataTable';

const AcquirersSummary = ({ title }) => {
  const fetchAcquirers = useAction(acquirersActions.fetchAcquirers);
  const setActiveAcquirer = useAction(acquirersActions.setActiveAcquirer);
  const setStripeConnectAccountId = useAction(
    acquirersActions.setStripeConnectAccountId
  );

  const acquirers = useSelector(acquirersSelector);
  const acquirerIsPending = useSelector(acquirersIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      if (query.get('state') && query.get('code')) {
        setStripeConnectAccountId({
          stripe_authorization_code: query.get('code'),
          acquirer_id: JSON.parse(query.get('state')).acquirer_id,
        });
      }
      fetchAcquirers({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
      });
    }
  }, [filters, fetchAcquirers, currentPage, sortDirection, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortDirection, sortField]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/acquirers/create"
                    disabled={acquirerIsPending}
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={acquirerIsPending}
                  acquirers={acquirers}
                  setActiveAcquirer={setActiveAcquirer}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

AcquirersSummary.propTypes = {
  title: PropTypes.string,
};

export default AcquirersSummary;
