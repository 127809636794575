import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import { isPendingSelector } from 'models/brands/selectors';
import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';

import { actions as organizationsActions } from 'models/organizations/slice';
import OrganizationForm from 'components/OrganizationForm';

import Preloader from 'components/Preloader';
import { tabs } from 'constants/organizations';

const CreateOrganization = ({ title }) => {
  const createOrganization = useAction(organizationsActions.createOrganization);
  const setCreatedOrganization = useAction(
    organizationsActions.setCreatedOrganization
  );
  const setCurrentTab = useAction(organizationsActions.setCurrentTab);
  const pending = useSelector(isPendingSelector);

  useEffect(() => {
    setCreatedOrganization(null);
    setCurrentTab(tabs[0]);
  }, []);

  if (pending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new commercial customer"
        breadcrumbItems={[
          {
            title: 'Back to commercial customers',
            link: '/commercial-customers/',
            withSearch: true,
          },
        ]}
      />

      <Col md="12">
        <OrganizationForm
          onSubmit={createOrganization}
          submitTitle="Save"
          isCreate
        />
      </Col>
    </PageWrapper>
  );
};

CreateOrganization.propTypes = {
  title: PropTypes.string,
};

export default CreateOrganization;
