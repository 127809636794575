import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './SortArrows.scss';

const SortArrows = ({ isActive, sortDirection }) => {
  return (
    <div className={s.root}>
      <span
        className={cx(`${s.arrow} fa fa-sort-up  ml-2`, {
          [s.opacity]: sortDirection === 'desc' && isActive,
        })}
      />
      <span
        className={cx(`${s.arrow} fa fa-sort-down ml-2`, {
          [s.opacity]: sortDirection === 'asc' && isActive,
        })}
      />
    </div>
  );
};

SortArrows.propTypes = {
  isActive: PropTypes.bool,
  sortDirection: PropTypes.string,
};

export default SortArrows;
