import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const wineclubMembersSlice = createSlice({
  name: 'wineclubMembers',
  initialState: {
    isPending: false,
    wineclubMembers: [],
    pagination: {},
  },
  reducers: {
    fetchWineclubMembers: state => {
      state.isPending = true;
    },
    fetchWineclubMembersSuccess(state, { payload }) {
      state.isPending = false;
      state.wineclubMembers = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchWineclubMembersFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(wineclubMembersSlice.actions);

export default wineclubMembersSlice.reducer;
