import { createSelector } from 'reselect';

export const rootSelector = state => state.common;

export const isOpenConfirmationModalSelector = createSelector(
  rootSelector,
  ({ isOpenConfirmationModal }) => isOpenConfirmationModal
);

export const isOpenImportDataModalSelector = createSelector(
  rootSelector,
  ({ isOpenImportDataModal }) => isOpenImportDataModal
);

export const isOpenEmailModalSelector = createSelector(
  rootSelector,
  ({ isOpenEmailModal }) => isOpenEmailModal
);

export const isOpenSuccessModalSelector = createSelector(
  rootSelector,
  ({ isOpenSuccessModal }) => isOpenSuccessModal
);

export const dataSelector = createSelector(rootSelector, ({ data }) => data);
