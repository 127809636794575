import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const wineclubsSlice = createSlice({
  name: 'wineclubs',
  initialState: {
    isPending: false,
    wineclubs: [],
    pagination: {},
    wineclub: {},
  },
  reducers: {
    fetchWineclubs: state => {
      state.isPending = true;
    },
    fetchWineclubsSuccess(state, { payload }) {
      state.isPending = false;
      state.wineclubs = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchWineclubsFailure: state => {
      state.isPending = false;
    },
    fetchWineclub: state => {
      state.isPending = true;
    },
    fetchWineclubSuccess(state, { payload }) {
      state.isPending = false;
      state.wineclub = payload.data;
    },
    fetchWineclubFailure: state => {
      state.isPending = false;
    },
    createWineclub: state => {
      state.isPending = true;
    },
    createWineclubSuccess(state, { payload }) {
      state.wineclubs[payload.data.id] = {
        ...payload.data,
      };
      state.isPending = false;
    },
    createWineclubFailure: state => {
      state.isPending = false;
    },
    updateWineclub: state => {
      state.isPending = true;
    },
    updateWineclubSuccess(state, { payload }) {
      state.wineclubs = state.wineclubs.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateWineclubFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(wineclubsSlice.actions);

export default wineclubsSlice.reducer;
