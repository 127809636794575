import { createSlice } from 'redux-starter-kit';

import actionTypes from 'utils/actionTypes';

const timeEntrySlice = createSlice({
  name: 'timeEntry',
  initialState: {
    timeEntry: {},
    timeEntryDetails: {},
    isPending: false,
  },
  reducers: {
    fetchTimeEntry: state => {
      state.isPending = true;
    },
    fetchTimeEntrySuccess(state, { payload }) {
      state.isPending = false;
      state.timeEntry = payload;
    },
    fetchTimeEntryFailure: state => {
      state.isPending = false;
    },
    fetchTimeEntryDetails: state => {
      state.isPending = true;
    },
    fetchTimeEntryDetailsSuccess(state, { payload }) {
      state.isPending = false;
      state.timeEntryDetails = payload;
    },
    fetchTimeEntryDetailsFailure: state => {
      state.isPending = false;
    },
    approveTimeEntry: state => {
      state.isPending = true;
    },
    approveTimeEntrySuccess: state => {
      state.isPending = false;
    },
    approveTimeEntryFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(timeEntrySlice.actions);

export default timeEntrySlice.reducer;
