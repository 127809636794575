import React from 'react';
import styles from './OrderRefundTable.scss';
import { Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import { format } from 'date-fns';
import { ORDER_TYPES_ITEMS } from 'constants';
import classNames from 'classnames';

const OrderRefundTable = ({
  data,
  amount,
  comment,
  date,
  refundType,
  invoiceNumber,
  className,
  shipment,
  tips,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Row className="mb-2">
        <Col>
          <p>
            Payment type:
            <span className="ml-2">{refundType}</span>
          </p>
        </Col>
        <Col md={6}>
          <p className="text-right">
            Refunded at: {format(Date.parse(date), 'yyyy-MM-dd, h:mm aaa')}
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <p>Comment: {comment || '-'}</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <p>Invoice number: {invoiceNumber || '-'}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table className={styles.table} bordered responsive>
            <thead>
              <tr>
                <th>Product Id</th>
                <th>Product SKU</th>
                <th>Product Title</th>
                <th>Type</th>
                <th>Number</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id.toString()}>
                  <td>{item.id}</td>
                  <td>{item.sku}</td>
                  <td>{item.title}</td>
                  <td>{ORDER_TYPES_ITEMS[item.resourcetype]}</td>
                  <td>{item.number}</td>
                </tr>
              ))}
              {shipment > 0 && (
                <tr>
                  <td />
                  <td />
                  <td>Shipping</td>
                  <td />
                  <td>1</td>
                </tr>
              )}
              {tips > 0 && (
                <tr>
                  <td />
                  <td />
                  <td>Tips</td>
                  <td />
                  <td>1</td>
                </tr>
              )}
              <tr>
                <td colSpan={4}>
                  <p className="font-weight-bolder">Refund Amount</p>
                </td>
                <td>
                  <p className="font-weight-bolder">${formatPrice(amount)}</p>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

OrderRefundTable.propTypes = {
  data: PropTypes.array.isRequired,
  amount: PropTypes.number.isRequired,
  comment: PropTypes.string,
  date: PropTypes.any.isRequired,
  refundType: PropTypes.string.isRequired,
  className: PropTypes.string,
  shipment: PropTypes.number,
  tips: PropTypes.number,
  invoiceNumber: PropTypes.string,
};
export default OrderRefundTable;
