import { createSelector } from 'reselect';

export const rootSelector = state => state.excise;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const exciseLicensesSelector = createSelector(
  rootSelector,
  ({ exciseLicenses }) => exciseLicenses
);

export const exciseLicenseSelector = createSelector(
  rootSelector,
  ({ exciseLicense }) => exciseLicense
);

export const exciseReturnsSelector = createSelector(
  rootSelector,
  ({ exciseReturns }) => exciseReturns
);

export const exciseReturnSelector = createSelector(
  rootSelector,
  ({ exciseReturn }) => exciseReturn
);

export const exciseReturnSummarySelector = createSelector(
  rootSelector,
  ({ exciseReturnSummary }) => exciseReturnSummary
);
