import React, { memo, useState } from 'react';
import styles from './CustomEmailForm.scss';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import TextField from '../Fields/TextField';
import EditorJodit from 'components/EditorJodit';
import { useEditorState } from 'hooks/useEditorState';
import FileField from '../FileField/FileField';
import LinkField from '../Fields/LinkField';
import { isLinkValid } from '../Fields/LinkField/LinkField';
import PropTypes from 'prop-types';
import WineclubsPaginatedField from '../PaginatedFields/WineclubsPaginatedField';

const CustomEmailForm = ({ onSubmit, onPreviewClick, defaultValues }) => {
  const [subtitle, setSubtitle] = useEditorState(defaultValues?.text_1 ?? '');
  const [description, setDescription] = useEditorState(
    defaultValues?.text_2 ?? ''
  );
  const [footerText, setFooterText] = useEditorState(
    defaultValues?.text_4 ?? ''
  );
  const [selectedWineClub, setSelectedWineClub] = useState(
    defaultValues?.wineClub
  );
  const [banner, setBanner] = useState(defaultValues?.logo_2);
  const [logo, setLogo] = useState(defaultValues?.logo_1);
  const [title, setTitle] = useState(defaultValues?.title ?? '');
  const [subject, setSubject] = useState(defaultValues?.subject ?? '');
  const [buttonText, setButtonText] = useState(defaultValues?.text_3 ?? '');
  const [link, setLink] = useState(defaultValues?.link ?? '');

  const onSubmitHandler = (_, data) => {
    if (selectedWineClub?.value) {
      onSubmit?.({
        ...data,
        wineclub_id: selectedWineClub.value,
        text_1: subtitle,
        text_2: description,
        text_4: footerText,
        logo_1: logo,
        logo_2: banner,
        text_3: buttonText,
      });
    }
  };

  const onPreviewHandler = () => {
    onPreviewClick?.({
      title,
      subject,
      link,
      logo_1: logo,
      logo_2: banner,
      text_1: subtitle,
      text_2: description,
      text_3: buttonText,
      text_4: footerText,
      wineclub_id: selectedWineClub?.value,
    });
  };

  const previewDisabled =
    !title || !subject || !selectedWineClub || (link && !isLinkValid(link));

  return (
    <AvForm onValidSubmit={onSubmitHandler} model={defaultValues}>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label required>Wine Club</Label>
            <WineclubsPaginatedField
              value={selectedWineClub}
              onChange={setSelectedWineClub}
            />
          </FormGroup>
        </Col>
        {!!selectedWineClub?.id && (
          <>
            <Col md={3}>
              <TextField
                label="Title"
                name="title"
                isRequired
                onChange={({ target }) => setTitle(target.value)}
              />
            </Col>
            <Col md={3}>
              <TextField
                label="Subject"
                name="subject"
                isRequired
                value={subject}
                onChange={({ target }) => setSubject(target.value)}
              />
            </Col>
            <Col md={3}>
              <TextField
                label="Button text"
                name="buttonText"
                value={buttonText}
                onChange={({ target }) => setButtonText(target.value)}
              />
            </Col>
            <Col md={3}>
              <LinkField
                name="link"
                value={link}
                onChange={({ target }) => setLink(target.value)}
              />
            </Col>
          </>
        )}
      </Row>
      {!!(selectedWineClub?.id || selectedWineClub?.value) && (
        <>
          <Row>
            <Col md={6}>
              <EditorJodit
                label="Subtitle text"
                name="subtitle"
                placeholder="Enter Subtitle"
                id="subtitle"
                defaultValue={defaultValues?.text_1}
                brandId={selectedWineClub?.brand_id}
                onChange={setSubtitle}
              />
            </Col>
            <Col md={6}>
              <EditorJodit
                label="Description"
                name="description"
                placeholder="Enter Description"
                id="description"
                defaultValue={defaultValues?.text_2}
                brandId={selectedWineClub?.brand_id}
                onChange={setDescription}
              />
            </Col>
            <Col md={6}>
              <EditorJodit
                label="Footer text"
                name="footerText"
                placeholder="Enter Footer Text"
                id="footerText"
                defaultValue={defaultValues?.text_4}
                brandId={selectedWineClub?.brand_id}
                onChange={setFooterText}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={styles.filesWrapper}>
                <FileField
                  label="Logo"
                  className={styles.fileField}
                  accept="image/jpeg,image/png,image/jpg,image/gif"
                  value={logo}
                  onChange={setLogo}
                />
                <FileField
                  label="Banner"
                  className={styles.fileField}
                  accept="image/jpeg,image/png,image/jpg,image/gif"
                  value={banner}
                  onChange={setBanner}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <div className={styles.actionWrapper}>
              <Button
                disabled={!selectedWineClub}
                color="success"
                type="submit"
              >
                {defaultValues ? 'Save' : 'Create'}
              </Button>
              <Button
                className="ml-3"
                disabled={previewDisabled}
                color="primary"
                type="button"
                onClick={onPreviewHandler}
              >
                Preview
              </Button>
            </div>
          </Row>
        </>
      )}
    </AvForm>
  );
};

CustomEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  onPreviewClick: PropTypes.func,
  defaultValues: PropTypes.object,
};
export default memo(CustomEmailForm);
