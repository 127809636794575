import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Label, CustomInput, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import useAction from 'hooks/useAction';
import { actions as brandsCompaniesActions } from 'models/brandsCompanies/slice';
import styles from './BrandsCompaniesForm.scss';

const TableRow = ({ item, companies, companiesOptions, deleteItem }) => {
  const onChange = useAction(
    brandsCompaniesActions.changeCurrentBrandCompanyField
  );

  const onCompanyChange = obj => {
    const { value } = obj;
    onChange({
      itemId: item.itemId,
      assignedObject: {
        shipping_company_id: companies[value].shipping_company_id,
        shipping_company_title: companies[value].shipping_company_title,
        changed: true,
      },
    });
  };

  const onApiKeyChange = event => {
    const { value } = event.target;
    onChange({
      itemId: item.itemId,
      assignedObject: { api_key: value, changed: true },
    });
  };

  const onMessageChange = event => {
    const { value } = event.target;
    onChange({
      itemId: item.itemId,
      assignedObject: { message: value, changed: true },
    });
  };

  const onActiveChange = event => {
    const { checked } = event.target;
    onChange({
      itemId: item.itemId,
      assignedObject: {
        is_active: checked,
        changed: true,
      },
    });
  };

  const companyValue = useMemo(() => {
    if (!item.shipping_company_id || !item.shipping_company_title) return null;
    return {
      value: item.shipping_company_id,
      label: item.shipping_company_title,
    };
  }, [item]);

  return (
    <tr>
      <td className={styles.cell}>
        <div className="d-flex align-items-center w-100">
          <div>
            <i
              id={`delete${item.itemId}`}
              className={cx(
                styles.pointer,
                styles.trash,
                'mr-1 mdi mdi-trash-can font-size-14'
              )}
              onClick={() => deleteItem(item.entity_id, item.itemId)}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete${item.itemId}`}
            >
              Detach company from the brand
            </UncontrolledTooltip>
          </div>
          {item.brand_id === null ? (
            <Select
              value={companyValue}
              className="w-100"
              options={companiesOptions}
              onChange={onCompanyChange}
              placeholder="Select company"
            />
          ) : (
            <div>{companyValue.label}</div>
          )}
        </div>
      </td>
      <td className={styles.cell}>
        <input
          type="text"
          id="api-key-input"
          value={item.api_key}
          placeholder="API Key"
          onChange={onApiKeyChange}
          className="form-control py-2 border"
        />
      </td>
      <td className={styles.cell}>
        <input
          type="text"
          id="api-key-input"
          value={item.message}
          placeholder="Message"
          onChange={onMessageChange}
          className="form-control py-2 border"
        />
      </td>
      <td>
        <div className={cx(styles.checkbox, 'custom-control custom-checkbox')}>
          <Label htmlFor={`company_is_active_${item.itemId}`}>Active</Label>
          <CustomInput
            type="checkbox"
            checked={item?.is_active}
            onChange={onActiveChange}
            id={`company_is_active_${item.itemId}`}
            name={`company_is_active_${item.itemId}`}
          />
        </div>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  companiesOptions: PropTypes.array.isRequired,
};

export default React.memo(TableRow);
