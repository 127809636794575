import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchTiersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/tiers/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchTierSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/tiers/${action.payload}`,
  });
}

export function* createTierSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/tiers/`,
    data: action.payload,
    successMessage: 'Successfully created new tier!',
  });
}

export function* updateTierSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/tiers/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated tier!',
    successNavigateTo: `/tiers/`,
  });
}

function* fetchTierPrefillsSaga(action) {
  yield api({
    action,
    method: 'GET',
    url: `/tiers/${action.payload}/tier_prefills`,
  });
}

function* createTierPrefillSaga(action) {
  const { tierId, data } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/tiers/${tierId}/tier_prefills`,
    data,
    successMessage: 'Successfully created prefill!',
  });
}

function* updateTierPrefillSaga(action) {
  const { id, tierId, data } = action.payload;
  yield api({
    action,
    method: 'PATCH',
    url: `/tiers/${tierId}/tier_prefills/${id}`,
    data,
    successMessage: 'Successfully updated prefill!',
  });
}

function* deleteTierPrefillSaga(action) {
  const { id, tierId } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/tiers/${tierId}/tier_prefills/${id}`,
    successMessage: 'Successfully deleted prefill!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTiers, fetchTiersSaga),
    takeLatest(actions.fetchTier, fetchTierSaga),
    takeLatest(actions.createTier, createTierSaga),
    takeLatest(actions.updateTier, updateTierSaga),
    takeLatest(actions.fetchTierPrefills, fetchTierPrefillsSaga),
    takeLatest(actions.createTierPrefill, createTierPrefillSaga),
    takeLatest(actions.updateTierPrefill, updateTierPrefillSaga),
    takeLatest(actions.deleteTierPrefill, deleteTierPrefillSaga),
  ]);
}
