export const getAvailableRefundedOrderItems = orderItems => {
  const availableOrderItems = [];
  const refundedOrderItems = [];

  orderItems.forEach(item => {
    if (item?.after_return_number == null) {
      availableOrderItems.push(item);
      return;
    }
    const refundedCount = item.number - item.after_return_number;
    if (item.after_return_number > 0) {
      availableOrderItems.push({
        ...item,
        number: item.after_return_number,
        total_price: +item?.after_return_total_price,
      });
    }
    if (refundedCount > 0) {
      refundedOrderItems.push({ ...item, number: refundedCount });
    }
  });
  return {
    availableOrderItems,
    refundedOrderItems,
  };
};
