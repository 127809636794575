/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import { Col } from 'reactstrap';

import LocationSearchInput from '../LocationSearchInput/LocationSearchInput';
import PhoneField from '../Fields/PhoneField';

const ShippingInformation = ({ addressData, setAddressData }) => {
  const {
    phone,
    city,
    country,
    state,
    postal_code,
    line1,
    line2,
  } = addressData;

  const handleChangeAddress = (key, value) => {
    setAddressData(prev => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <Col md="8">
        <LocationSearchInput
          country={country}
          state={state}
          postalCode={postal_code}
          city={city}
          line1={line1}
          line2={line2}
          setCountry={value => handleChangeAddress('country', value)}
          setState={value => handleChangeAddress('state', value)}
          setPostalCode={value => handleChangeAddress('postal_code', value)}
          setCity={value => handleChangeAddress('city', value)}
          setLine1={value => handleChangeAddress('line1', value)}
          setLine2={value => handleChangeAddress('line2', value)}
        />
      </Col>
      <Col md="4">
        <AvForm>
          <PhoneField
            isRequired
            name="phone"
            label="Phone"
            value={phone}
            onChange={value => handleChangeAddress('phone', value)}
          />
        </AvForm>
      </Col>
    </>
  );
};

ShippingInformation.propTypes = {
  addressData: PropTypes.object,
  setAddressData: PropTypes.func,
};

export default ShippingInformation;
