import { takeLatest, all } from 'redux-saga/effects';
import * as queryString from 'query-string';

import api from 'api';
import { defaultCurrency } from 'constants';
import { generateURL } from 'utils/generateURL';
import { actions } from './slice';

export function* fetchProductsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/products/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchProductsOptionsSaga(action) {
  const stringified = queryString.stringify(action.payload);
  const url = `/products/?${stringified}`;

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchProductSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/products/${action.payload.id}`,
  });
}

export function* createProductSaga(action) {
  const formData = new FormData();

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });
  formData.append('price_currency', defaultCurrency);
  formData.append('dfs_price_currency', defaultCurrency);
  formData.append('wholesale_price_currency', defaultCurrency);

  yield api({
    action,
    method: 'post',
    url: `/products/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully created new product!',
    successNavigateTo: `/products/`,
    showResponseError: true,
  });
}

export function* updateProductSaga(action) {
  const formData = new FormData();

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });

  formData.append('price_currency', defaultCurrency);
  formData.append('dfs_price_currency', defaultCurrency);
  formData.append('wholesale_price_currency', defaultCurrency);

  yield api({
    action,
    method: 'patch',
    url: `/products/${action.payload.id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully updated product!',
    successNavigateTo: `/products/?page=${action.payload.page}`,
    showResponseError: true,
  });
}

export function* fetchFoodAllergiesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/food_allergies/',
    setQuery: true,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* updateFoodAllergySaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/food_allergies/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated allergy!',
    successNavigateTo: `/food-allergies/`,
  });
}

export function* createFoodAllergySaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/food_allergies/`,
    data: action.payload,
    successMessage: 'Successfully created new allergy!',
    failureMessage: 'An error occurred during creating an allergy',
    successNavigateTo: `/food-allergies/`,
  });
}

export function* fetchFoodAllergySaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/food_allergies/${action.payload.id}`,
  });
}

export function* fetchProductsCategoriesSaga(action) {
  const { requestURL } = yield generateURL({
    payload: action.payload?.params,
    requestURL: '/product_categories/',
    url: action.payload?.url,
    setQuery: !!action.payload?.url,
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchProductCategorySaga(action) {
  yield api({
    action,
    method: 'GET',
    url: `/product_categories/${action.payload.id}`,
  });
}

export function* createProductCategorySaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/product_categories/`,
    data: action.payload,
    successMessage: 'Category successfully created!',
    successNavigateTo: action.payload?.redirectUrl,
  });
}

export function* updateProductCategorySaga(action) {
  yield api({
    action,
    method: 'PATCH',
    url: `/product_categories/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated category!',
    successNavigateTo: action.payload?.redirectUrl,
  });
}

export function* deleteProductCategorySaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/product_categories/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully deleted category!',
  });
}

export function* fetchProductImagesSaga(action) {
  yield api({
    action,
    method: 'GET',
    url: `/products/${action.payload.id}/images`,
  });
}

export function* createProductImageSaga(action) {
  const formData = new FormData();
  formData.append('image', action.payload.image);
  formData.append('type', action.payload.type);
  yield api({
    action,
    method: 'POST',
    url: `/products/${action.payload.id}/images`,
    data: formData,
    successMessage: 'Successfully created an image!',
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function* swapProductImagesSaga(action) {
  yield api({
    action,
    method: 'PATCH',
    url: `/products/${action.payload.id}/images/swap/`,
    data: action.payload,
  });
}

export function* patchProductImageSaga(action) {
  // TODO: now patch works only with reuploading image by id. Refactor if more Fields will need patch
  const formData = new FormData();
  formData.append('image', action.payload.image);
  yield api({
    action,
    method: 'PATCH',
    url: `/products/${action.payload.productId}/images/${action.payload.id}`,
    data: formData,
    successMessage: 'Successfully updated an image!',
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function* deleteProductImageSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/products/${action.payload.productId}/images/${action.payload.id}`,
    successMessage: 'Successfully deleted an image!',
  });
}

export function* fetchProductsCount(action) {
  const { warehouseId, productIds, isSubscription } = action.payload;

  const { url } = yield generateURL({
    payload: {
      product_ids: productIds,
      is_it_can_be_used_for_subscription_orders: isSubscription,
    },
    url: `/warehouses/${warehouseId}/inventories`,
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'GET',
    url,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchProducts, fetchProductsSaga),
    takeLatest(actions.fetchProductsOptions, fetchProductsOptionsSaga),
    takeLatest(actions.fetchProduct, fetchProductSaga),
    takeLatest(actions.createProduct, createProductSaga),
    takeLatest(actions.updateProduct, updateProductSaga),
    takeLatest(actions.fetchFoodAllergies, fetchFoodAllergiesSaga),
    takeLatest(actions.updateFoodAllergy, updateFoodAllergySaga),
    takeLatest(actions.createFoodAllergy, createFoodAllergySaga),
    takeLatest(actions.fetchFoodAllergy, fetchFoodAllergySaga),
    takeLatest(actions.fetchProductsCategories, fetchProductsCategoriesSaga),
    takeLatest(actions.fetchProductCategory, fetchProductCategorySaga),
    takeLatest(actions.createProductCategory, createProductCategorySaga),
    takeLatest(actions.updateProductCategory, updateProductCategorySaga),
    takeLatest(actions.deleteProductCategory, deleteProductCategorySaga),
    takeLatest(actions.fetchProductImages, fetchProductImagesSaga),
    takeLatest(actions.createProductImage, createProductImageSaga),
    takeLatest(actions.swapProductImages, swapProductImagesSaga),
    takeLatest(actions.patchProductImage, patchProductImageSaga),
    takeLatest(actions.deleteProductImage, deleteProductImageSaga),
    takeLatest(actions.fetchProductsCount, fetchProductsCount),
  ]);
}
