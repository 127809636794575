import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { prepareAddress } from 'utils/prepareAddress';

const Header = ({ warehouse }) => {
  return (
    <>
      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Title</h4>
              <div className="mt-4">{warehouse?.title}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Workspace Title</h4>
              <div className="mt-4">{warehouse?.workspace_title}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Room</h4>
              <div className="mt-4">
                {warehouse?.is_used_as_tasting_room ? 'Yes' : 'No'}
              </div>
            </CardBody>
          </Card>
        </Col>
        {warehouse?.container_world_warehouse_title && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Warehouse Name</h4>
                <div className="mt-4">
                  {warehouse.container_world_warehouse_title}
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Address</h4>
              <div className="mt-4">
                {prepareAddress({
                  country: warehouse?.country,
                  state: warehouse?.state,
                  city: warehouse?.city,
                  line1: warehouse?.line1,
                  line2: warehouse?.line2,
                  postal_code: warehouse?.postal_code,
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Header.propTypes = {
  warehouse: PropTypes.object,
};

export default Header;
