export const calculateOrderItemTotalPrice = (orderItem, priceType) => {
  if (!orderItem?.number && !orderItem?.numberCases) {
    return 0;
  }
  const productCount =
    (orderItem?.product_bottles_in_case ?? 0) * (orderItem.numberCases ?? 0) +
    (orderItem.number ?? 0);
  return (orderItem[priceType] || orderItem.product_price || 0) * productCount;
};

export const calculateOrderItemTotalDiscountedPrice = (
  orderItemsArray,
  priceType,
  isCases
) => {
  return isCases
    ? orderItemsArray.reduce(
        // Commercial
        (acc, item) =>
          acc +
            item?.[priceType] *
              (item?.number +
                item?.numberCases * item?.product_bottles_in_case) || 0,
        0
      )
    : orderItemsArray
        .reduce(
          // DTC
          (acc, item) => {
            if (item?.discount_percentage)
              return (
                acc +
                Number(
                  item?.number *
                    item?.[priceType] *
                    (1 - item?.discount_percentage / 100)
                )
              );
            if (item?.discount_amount)
              return (
                acc +
                Number(item?.number * item?.[priceType] - item?.discount_amount)
              );
            return acc + item?.number * (item?.[priceType] || 0);
          },
          0
        )
        .toFixed(2);
};
