import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { RouterContext } from 'constants';

const getMainPathname = pathname => pathname?.split('/')?.[1];

const RouterProvider = ({ children }) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: getMainPathname(location.pathname),
    fullTo: location.pathname,
    search: location.search,
    from: null,
  });

  useEffect(() => {
    const fullTo = location.pathname;
    const to = getMainPathname(fullTo);

    if (
      to !== route.to ||
      (fullTo === route.fullTo && route.search !== location.search)
    ) {
      setRoute(prev => {
        return { to, fullTo, from: prev.to, search: location.search };
      });
    }
  }, [location]);

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  );
};

RouterProvider.propTypes = {
  children: PropTypes.object,
};
export default RouterProvider;
