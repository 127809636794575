import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as productsPresetsActions } from 'models/productsPresets/slice';
import useSelector from 'hooks/useSelector';
import {
  productsPresetsSelector,
  isPendingSelector as productsIsPendingSelector,
  paginationSelector,
} from 'models/productsPresets/selectors';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';

import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const ProductsPresetsSummary = ({ title }) => {
  const fetchProductsPresets = useAction(
    productsPresetsActions.fetchProductsPresets
  );
  const deleteProductsPreset = useAction(
    productsPresetsActions.deleteProductsPreset
  );
  const productsPresets = useSelector(productsPresetsSelector);
  const warehousesIsPending = useSelector(warehousesIsPendingSelector);
  const productsIsPending = useSelector(productsIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [productsPresetForDelete, setProductsPresetForDelete] = useState(null);
  const isPending = productsIsPending || warehousesIsPending;
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchProductsPresets({
        page: currentPage,
        ...filters,
      });
    }
  }, [fetchProductsPresets, currentPage, filters]);

  const handleDeleteProductsPreset = () => {
    deleteProductsPreset(productsPresetForDelete);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasWarehousesFilter
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/products-preset/create"
                    disabled={isPending}
                    placeholder="Search... (By menu title)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  handleDeleteProductsPreset={handleDeleteProductsPreset}
                  setProductsPresetID={setProductsPresetForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  productsPresets={productsPresets}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    onSaveClick={handleDeleteProductsPreset}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ProductsPresetsSummary.propTypes = {
  title: PropTypes.string,
};

export default ProductsPresetsSummary;
