import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { startOfYear, endOfYear, isSameDay, addYears } from 'date-fns';

const checkSelected = (range, definedRange) => {
  return (
    isSameDay(range.startDate, definedRange.startDate) &&
    isSameDay(range.endDate, definedRange.endDate)
  );
};

const Datepicker = ({ value, onChange, ...props }) => {
  return (
    <DateRangePicker
      months={1}
      ranges={value}
      direction="horizontal"
      moveRangeOnFirstSelection={false}
      onChange={item => onChange([item.selection])}
      showSelectionPreview
      maxDate={addYears(new Date(), 5)}
      minDate={addYears(new Date(), -5)}
      staticRanges={[
        ...defaultStaticRanges,
        {
          label: 'This Year',
          range() {
            return {
              startDate: startOfYear(new Date()),
              endDate: endOfYear(new Date()),
            };
          },
          isSelected(range) {
            const definedRange = this.range();
            return checkSelected(range, definedRange);
          },
        },
        {
          label: 'Last Year',
          range() {
            return {
              startDate: startOfYear(addYears(new Date(), -1)),
              endDate: endOfYear(addYears(new Date(), -1)),
            };
          },
          isSelected(range) {
            const definedRange = this.range();
            return checkSelected(range, definedRange);
          },
        },
      ]}
      {...props}
    />
  );
};

Datepicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    key: PropTypes.string,
    endDate: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
  }).isRequired,
};

export default Datepicker;
