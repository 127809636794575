import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import styles from './DataTable.scss';
import classNames from 'classnames';

const DataTable = ({
  isPending,
  acquirers,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  setActiveAcquirer,
}) => {
  const handleSetActiveAcquirer = (e, id) => {
    e.preventDefault();
    setActiveAcquirer(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="1"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>

          <th data-priority="3">Public Key</th>
          <th data-priority="3">Type</th>
          <th data-priority="3">Active</th>
        </tr>
      </thead>
      <tbody className={classNames(isPending && styles.preloader)}>
        {acquirers?.map(item => (
          <tr key={item.id}>
            <td className="text-dark font-weight-bold">{item.id}</td>
            <td className="text-dark font-weight-bold">{item.title}</td>
            <td
              className={classNames(styles.width, 'text-dark font-weight-bold')}
            >
              {item.public_key}
            </td>
            <td className="text-dark font-weight-bold">{item.resourcetype}</td>
            <td className="text-dark font-weight-bold">
              {item.is_active ? (
                'Yes'
              ) : (
                <Button
                  color="primary"
                  onClick={e => handleSetActiveAcquirer(e, item.id)}
                >
                  Set Active
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  acquirers: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  setActiveAcquirer: PropTypes.func,
};

export default DataTable;
