import React, { useEffect, useState, memo } from 'react';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import useSelector from 'hooks/useSelector';
import { abstractCustomerByEmailSelector } from 'models/customers/selectors';
import useAction from 'hooks/useAction';
import { actions as customerActions } from 'models/customers/slice';
import { actions as orderActions } from 'models/orders/slice';
import { Col, Row } from 'reactstrap';
import CardItem from 'components/CardItem';
import {
  isPendingSelector,
  ordersSelector,
  paginationSelector,
} from 'models/orders/selectors';
import OrderTable from 'components/OrderTable';
import AbstractCustomerAddressTable from './AbstractCustomerAddressTable';

const getCustomerNamesList = (firstNames, lastNames) => {
  return firstNames.map((firstName, index) => {
    const lastName = lastNames?.[index] ?? '';
    return `${firstName} ${lastName}`;
  });
};

const prepareCustomerInfo = data => data.join('; ');

const AbstractCustomerShow = ({ title }) => {
  const { email } = useParams();
  const fetchAbstractCustomer = useAction(
    customerActions.fetchAbstractCustomer
  );
  const fetchOrders = useAction(orderActions.fetchOrders);
  const customer = useSelector(abstractCustomerByEmailSelector(email));
  const orders = useSelector(ordersSelector);
  const ordersPagination = useSelector(paginationSelector);
  const ordersPending = useSelector(isPendingSelector);

  const [orderPage, setOrderPage] = useState(1);

  useEffect(() => {
    if (!customer) {
      fetchAbstractCustomer({ email });
    }
  }, [customer, email]);

  useEffect(() => {
    fetchOrders({
      customer_email_contains: email,
      page: orderPage,
    });
  }, [email, orderPage]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={email}
        breadcrumbItems={[
          { title: 'Back to customers', link: '/wineclub-customers' },
        ]}
      />
      {customer && (
        <Row>
          <Col md={4}>
            <CardItem title="Email" value={customer.email} />
          </Col>
          <Col md={4}>
            <CardItem
              title="Names"
              value={prepareCustomerInfo(
                getCustomerNamesList(customer.first_names, customer.last_names)
              )}
            />
          </Col>
          <Col md={4}>
            <CardItem
              title="Phones"
              value={prepareCustomerInfo(customer.phones)}
            />
          </Col>
          <Col md={12}>
            <AbstractCustomerAddressTable addresses={customer.addresses} />
          </Col>
          <Col md={12}>
            {ordersPagination?.total_count > 0 && (
              <OrderTable
                data={orders}
                pagination={ordersPagination}
                onPageChange={setOrderPage}
                loading={ordersPending}
              />
            )}
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

AbstractCustomerShow.propTypes = {
  title: PropTypes.string.isRequired,
};
export default memo(AbstractCustomerShow);
