import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/AwardsForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as awardsActions } from 'models/awards/slice';
import { isPendingSelector } from 'models/awards/selectors';

const AwardCreate = ({ title }) => {
  const isPending = useSelector(isPendingSelector);
  const createAward = useAction(awardsActions.createAward);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new award"
        breadcrumbItems={[
          { title: 'Back to awards', link: '/awards/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Save"
            onSubmit={createAward}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

AwardCreate.propTypes = {
  title: PropTypes.string,
};

export default AwardCreate;
