export default value => {
  switch (value) {
    case 'TastingRoomOrder':
      return 'Tasting Room Order';
    case 'RetailOrder':
      return 'Dashboard Order';
    case 'FoodOrderItem':
      return 'Food Order Item';
    case 'SubscriptionOrder':
      return 'Subscription Order';
    case 'CommercialOrder':
      return 'Commercial Order';
    case 'EcommerceOrder':
      return 'Ecommerce Order';
    case 'TastingMenuOrderItem':
      return 'Tasting Menu Order Item';
    case 'ProductOrderItem':
      return 'Product Order Item';
    case 'ProductSampleOrderItem':
      return 'Product Sample Order Item';
    case 'ImportedOrder':
      return 'Imported Order';
    case 'ImportedOrderItem':
      return 'Imported Order Item';
    default:
      return '';
  }
};
