import { createSlice } from 'redux-starter-kit';

import { actions as userActions } from 'models/user/slice';

function sharedReducer(state, { payload }) {
  state.currentWorkspace = payload.data.workspaces?.[0]?.id;
}

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState: {
    workspaces: [],
    currentWorkspace: 1,
  },
  extraReducers: {
    [userActions.loginSuccess]: sharedReducer,
    [userActions.getCurrentUserSuccess]: sharedReducer,
  },
});

export default workspacesSlice.reducer;
