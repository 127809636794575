import React from 'react';
import useSelector from 'hooks/useSelector';
import { salesSourcesSelector } from 'models/reports/selectors';
import { Card, CardBody } from 'reactstrap';
import formatPrice from 'utils/formatPrice';
import styles from './SalesSourcesCard.scss';

const typeNames = {
  productorderitem: 'Product',
  productsampleorderitem: 'Sample',
  tastingmenuorderitem: 'Tasting menu',
};

const SalesSourcesCard = () => {
  const sources = useSelector(salesSourcesSelector);
  // let total = 0;

  const formatData = data => {
    const formattedData = [];
    data.forEach(item => {
      const types = Object.values(item)[0];
      let total_net = 0;
      const types_product = [];
      const types_sold = [];
      const types_price = [];
      const types_net = [];
      types.forEach(product => {
        total_net += product['Net Sales'];
        types_product.push(typeNames[product['Product Type']]);
        types_sold.push(product['Total Sold']);
        types_price.push(product['Sold Price']);
        types_net.push(product['Net Sales']);
      });
      // total += total_net;
      const currentProduct = types[0];
      formattedData.push({
        product: `${currentProduct['Product Name']} ${currentProduct.Vintage ??
          ''}`,
        sku: currentProduct?.SKU,
        total_net,
        types_product,
        types_sold,
        types_price,
        types_net,
      });
    });
    return formattedData;
  };

  const data = formatData(sources);

  return (
    <Card>
      <CardBody>
        <div className={styles.title}>Products</div>
        {/* <div className={styles.number}>$ {formatPrice(total)}</div> */}
        <table className="table mt-3">
          <thead>
            <th>Product</th>
            <th>SKU</th>
            <th>Count</th>
            <th>Price per item</th>
            <th>Total price</th>
          </thead>
          <tbody>
            {data?.map((source, index) => (
              <tr key={index}>
                <td>
                  {source.product}
                  {source.types_product.map(item => (
                    <>
                      <br /> {item}
                    </>
                  ))}
                </td>
                <td>{source.sku}</td>
                <td>
                  -
                  {source.types_sold.map(item => (
                    <>
                      <br /> {item}
                    </>
                  ))}
                </td>
                <td>
                  -
                  {source.types_price.map(item => (
                    <>
                      <br /> $ {formatPrice(item)}
                    </>
                  ))}
                </td>
                <td>
                  $ {formatPrice(source.total_net)}
                  {source.types_net.map(item => (
                    <>
                      <br /> $ {formatPrice(item)}
                    </>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SalesSourcesCard;
