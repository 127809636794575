export const projects = [
  { label: 'Everywhere', value: 'EVERYWHERE' },
  { label: 'Ecommerce', value: 'ECOMMERCE' },
  { label: 'POS', value: 'POS' },
];

export const terminalsLabelMap = projects.reduce(
  (res, curr) => ({ ...res, [curr.value]: curr.label }),
  {}
);
