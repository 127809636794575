import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { getRedactedValues } from 'utils/getRedactedValues';
import TextField from '../Fields/TextField';

const FoodAllergiesForm = ({ onSubmit, disabled, model, submitTitle }) => {
  const handleSubmit = (event, values) => {
    onSubmit({
      id: model?.id,
      ...getRedactedValues(model, values),
    });
  };
  return (
    <>
      <AvForm
        model={model}
        className="needs-validation"
        onValidSubmit={handleSubmit}
      >
        <Row>
          <Col md="6">
            <TextField
              isRequired
              name="title"
              label="Title"
              errorMessage="Enter Title"
              placeholder="Title"
            />
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </AvForm>
    </>
  );
};

FoodAllergiesForm.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
};

export default FoodAllergiesForm;
