import React from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, Col, Row } from 'reactstrap';
import FakePasswordField from '../Fields/FakePasswordField';
import PropTypes from 'prop-types';
import TextField from '../Fields/TextField';

const BrandsContainerWorldForm = ({ defaultValues, onSubmit }) => {
  const onSubmitHandler = (_, data) => {
    const isPasswordChanged =
      data?.container_world_password !== '' &&
      !data?.container_world_password?.includes('●');

    if (!isPasswordChanged) {
      delete data.container_world_password;
    }

    onSubmit?.(data);
  };

  return (
    <AvForm
      model={defaultValues}
      className="needs-validation"
      onValidSubmit={onSubmitHandler}
    >
      <Row>
        <Col md={3}>
          <TextField name="container_world_username" label="Username" />
        </Col>
        <Col md={3}>
          <FakePasswordField
            isFilled={defaultValues?.container_world_username}
            name="container_world_password"
            placeholder=""
          />
        </Col>
        <Col md={3}>
          <TextField
            name="container_world_cust_number"
            label="Customer Number"
          />
        </Col>
        <Col md={3}>
          <TextField
            name="container_world_supplier_number"
            label="Supplier Number"
          />
        </Col>
        {defaultValues && (
          <Col>
            <Button color="primary" type="submit">
              Save
            </Button>
          </Col>
        )}
      </Row>
    </AvForm>
  );
};

BrandsContainerWorldForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default BrandsContainerWorldForm;
