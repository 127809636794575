import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { useParams } from 'react-router-dom';

import { actions as organizationsActions } from 'models/organizations/slice';
import {
  organizationSelector,
  isPendingSelector as isOrganizationsPendingSelector,
} from 'models/organizations/selectors';
import Preloader from 'components/Preloader';
import OrganizationForm from 'components/OrganizationForm';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';

const EditOrganization = ({ title }) => {
  const { id } = useParams();
  const editOrganization = useAction(organizationsActions.updateOrganization);
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const organization = useSelector(organizationSelector);
  const organizationsPending = useSelector(isOrganizationsPendingSelector);

  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const isFormDisabled = !isOwnerOrManagerRole;

  React.useEffect(() => {
    fetchOrganization(id);
  }, [fetchOrganization, id]);

  if (organizationsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Commercial Customer"
        breadcrumbItems={[
          {
            title: 'Back to commercial customers',
            link: '/commercial-customers/',
            withSearch: true,
          },
        ]}
      />

      <OrganizationForm
        onSubmit={editOrganization}
        submitTitle="Save"
        model={{
          ...organization,
          contact_person_first_name: organization?.contact_person?.first_name,
          contact_person_last_name: organization?.contact_person?.last_name,
          contact_person_email: organization?.contact_person?.email,
          contact_person_phone: organization?.contact_person?.phone,
        }}
        disabled={isFormDisabled}
        disableEditing
        isEditing
      />
    </PageWrapper>
  );
};

EditOrganization.propTypes = {
  title: PropTypes.string,
};

export default EditOrganization;
