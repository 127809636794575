import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { RouterContext } from 'constants';
import QueryLink from '../QueryLink';

const Breadcrumbs = ({ link, title, breadcrumbItems }) => {
  const route = useContext(RouterContext);

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            {link ? (
              <Link to={link} className="btn-link">
                <h4 className="mb-0 btn-link">{title}</h4>
              </Link>
            ) : (
              <h4 className="mb-0">{title}</h4>
            )}
            {breadcrumbItems && (
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  {breadcrumbItems.map((item, key) =>
                    key + 1 !== breadcrumbItems?.length ? (
                      <BreadcrumbItem key={key} active>
                        {item.title}
                      </BreadcrumbItem>
                    ) : (
                      <BreadcrumbItem key={key}>
                        <QueryLink
                          to={item.link}
                          search={item.withSearch ? route.search : null}
                        >
                          {item.title}
                        </QueryLink>
                      </BreadcrumbItem>
                    )
                  )}
                </Breadcrumb>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Breadcrumbs.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

export default Breadcrumbs;
