import React from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';

import config from 'config';

const PageWrapper = ({ title, children }) => (
  <>
    <Helmet {...config.app} title={title} />
    {children}
  </>
);

PageWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageWrapper;
