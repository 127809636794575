export const getMockDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getStartDate = date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getStartDateNow = date => {
  const now = new Date();
  date.setHours(
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    now.getMilliseconds()
  );
  return date;
};

export const getEndDate = date => {
  date.setHours(23, 59, 59, 999);
  return date;
};
