import React, { memo } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';

const AbstractCustomerAddressTable = ({ addresses }) => {
  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-3">Addresses</h4>
        <Table responsive bordered>
          <tbody>
            {addresses.map((address, index) => (
              <tr key={index.toString()}>
                <td>{address}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

AbstractCustomerAddressTable.propTypes = {
  addresses: PropTypes.array.isRequired,
};
export default memo(AbstractCustomerAddressTable);
