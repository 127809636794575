import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';
import { actions } from './slice';

function* fetchTagsSaga(action) {
  const { brandId } = action.payload;

  yield api({
    action,
    url: `/tags/?brand_id=${brandId}`,
    method: 'GET',
  });
}

export default function*() {
  yield all([takeLatest(actions.fetchTags, fetchTagsSaga)]);
}
