import { createSelector } from 'reselect';
import { quantitiesSelector } from '../transfers/selectors';

export const rootSelector = state => state.inventories;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const inventoriesSelector = createSelector(
  rootSelector,
  ({ inventories }) => inventories
);

export const totalBottlesSelector = createSelector(
  rootSelector,
  ({ totalBottles }) => totalBottles
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const initializedManualCountPaginationSelector = createSelector(
  rootSelector,
  ({ initializedManualCountPagination }) => initializedManualCountPagination
);

export const lowInventoryPaginationSelector = createSelector(
  rootSelector,
  ({ lowInventoryPagination }) => lowInventoryPagination
);

export const containerWorldExceptionPaginationSelector = createSelector(
  rootSelector,
  ({ containerWorldExceptionPagination }) => containerWorldExceptionPagination
);

export const inventoriesForTransferSelector = createSelector(
  [inventoriesSelector, quantitiesSelector],
  (inventories, quantities) =>
    inventories.map(item => {
      const quantity = quantities[item.id] || 0;

      return {
        ...item,
        quantity,
        isError: quantity > item.inventories?.[0]?.product_count,
      };
    })
);

export const hasErrorsInventoriesForTransferSelector = createSelector(
  inventoriesForTransferSelector,
  items => items.some(({ isError }) => isError)
);

export const inventoryManualCountingSelector = createSelector(
  rootSelector,
  ({ inventoryManualCounting }) => inventoryManualCounting
);

export const initializedManualCountSelector = createSelector(
  rootSelector,
  ({ initializedManualCount }) => initializedManualCount
);

export const inventoryManualCountingByIdSelector = id =>
  createSelector(
    rootSelector,
    ({ inventoryManualCounting }) => inventoryManualCounting?.[id]
  );

export const inventoryManualCountingPendingSelector = createSelector(
  rootSelector,
  ({ manualCountingPending }) => manualCountingPending
);

export const inventoryManualCountingPaginationSelector = createSelector(
  rootSelector,
  ({ manualCountingPagination }) => manualCountingPagination
);

export const inventoryStatusBeforeCheckSelector = createSelector(
  rootSelector,
  ({ inventoryStatusBeforeCheck }) => inventoryStatusBeforeCheck
);

export const inventoryStatusPendingSelector = createSelector(
  rootSelector,
  ({ inventoryStatusPending }) => inventoryStatusPending
);

export const inventoryStatusPaginationSelector = createSelector(
  rootSelector,
  ({ inventoryStatusPagination }) => inventoryStatusPagination
);

export const containerWorldExceptionSelector = createSelector(
  rootSelector,
  ({ containerWorldException }) => containerWorldException
);

export const lowInventorySelector = createSelector(
  rootSelector,
  ({ lowInventory }) => lowInventory
);

export const tasterCountsByWarehouseIdSelector = createSelector(
  rootSelector,
  ({ tasterCountsByWarehouseId }) => tasterCountsByWarehouseId
);
