import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import Select from 'react-select';

const prepareValue = value => value ?? '-';

const getInitialBrandsOptions = products => {
  const brandsTitles = products.map(product => product.brand_title);
  return [...new Set(brandsTitles)].map((brand, index) => ({
    label: brand,
    value: index + 1,
  }));
};

const ProductsTable = ({ products }) => {
  const [filteredProducts, setFilteredProducts] = useState(products);

  const brands = [
    { label: 'All Brands', value: 0 },
    ...getInitialBrandsOptions(products),
  ];

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const onSelect = ({ label, value }) => {
    if (value === 0) {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter(product => product?.brand_title === label)
      );
    }
  };

  return (
    <div>
      <Row>
        <Col md={3}>
          <Select
            className="mb-3 mt-1"
            placeholder="Select Brand"
            options={brands}
            onChange={onSelect}
          />
        </Col>
      </Row>
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" bordered responsive>
            <thead>
              <tr>
                <th data-priority="1">Title</th>
                <th data-priority="1">Brand</th>
                <th data-priority="1">Count</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={`${index}`}>
                  <td>{prepareValue(product?.title)}</td>
                  <td>{prepareValue(product?.brand_title)}</td>
                  <td>{prepareValue(product?.count)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.object.isRequired,
};
export default ProductsTable;
