import React from 'react';
import styles from './InventoryManualCountingTable.scss';
import PropTypes from 'prop-types';
import PaginationTable from '../PaginationTable';
import { Table } from 'reactstrap';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { INVENTORY_STATUSES } from 'constants/inventory';

const COUNT_TYPES = {
  Wine: 'Wine',
  Merchandise: 'Merchandise',
  Food: 'Food',
  WineMerch: 'Wine & Merchandise',
  Taster: 'Taster Bottles',
};

const getFormattedDate = date => {
  return format(Date.parse(date), 'yyyy-MM-dd, h:mm aaa');
};

const LinkedCell = ({ id, children, className }) => {
  return (
    <td className={className}>
      <Link className="text-dark d-block" to={`/inventory-count/${id}`}>
        {children}
      </Link>
    </td>
  );
};

const getPersonName = data => {
  const firstName = data?.first_name ?? '';
  const lastName = data?.last_name ?? '';
  const email = data?.email ?? '-';
  return firstName || lastName ? `${firstName} ${lastName}` : email;
};

const InventoryCountTable = ({ data, noDataMessage }) => {
  return (
    <>
      {data.length !== 0 && (
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Brand</th>
            <th>Warehouse</th>
            <th>Manager</th>
            <th>Operator</th>
          </tr>
        </thead>
      )}
      <tbody>
        {data.length === 0 && <div>{noDataMessage}</div>}
        {data.length > 0 &&
          data.map(item => (
            <tr>
              <LinkedCell id={item.id} key={`${item.id}_id`}>
                {item?.id}
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_count_type`}>
                {COUNT_TYPES[item?.count_type] ?? '-'}
              </LinkedCell>
              <LinkedCell
                className={styles.dateCell}
                id={item.id}
                key={`${item.id}_createdAt`}
              >
                {item?.created_at ? getFormattedDate(item?.created_at) : '-'}
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_status`}>
                <span
                  className={
                    (item?.status === INVENTORY_STATUSES.REJECTED &&
                      styles.redText) ||
                    (item?.status === INVENTORY_STATUSES.INITIALIZED &&
                      styles.blueText) ||
                    (item?.status === INVENTORY_STATUSES.COMPLETED &&
                      styles.greenText) ||
                    ''
                  }
                >
                  {item?.status ?? '-'}
                </span>
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_brand`}>
                {item?.warehouse?.brand_title ?? '-'}
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_warehouse`}>
                {item?.warehouse?.title ?? '-'}
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_manager`}>
                {getPersonName(item?.controller)}
              </LinkedCell>
              <LinkedCell id={item.id} key={`${item.id}_operator`}>
                {getPersonName(item?.operator)}
              </LinkedCell>
            </tr>
          ))}
      </tbody>
    </>
  );
};

InventoryCountTable.propTypes = {
  data: PropTypes.array,
  noDataMessage: PropTypes.string,
};

const InventoryManualCountingTable = ({
  data,
  initializedData,
  pagination,
  loading,
  onPageChange,
}) => {
  return (
    <PaginationTable setCurrentPage={onPageChange} pagination={pagination}>
      <div className={styles.subHeading}>Initialized Counts</div>
      <Table className={loading && styles.loadingContainer} bordered responsive>
        <InventoryCountTable
          data={initializedData}
          noDataMessage="There are no initialized inventory counts for the selected brands or warehouses."
        />
      </Table>

      <div className={styles.subHeading}>Count History</div>
      <Table className={loading && styles.loadingContainer} bordered responsive>
        <InventoryCountTable
          data={data?.filter(
            item => item.status !== INVENTORY_STATUSES.INITIALIZED
          )}
          noDataMessage="There are no inventory counts."
        />
      </Table>
    </PaginationTable>
  );
};

InventoryManualCountingTable.propTypes = {
  data: PropTypes.array,
  initializedData: PropTypes.array,
  pagination: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
};

LinkedCell.propTypes = {
  id: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
};
export default InventoryManualCountingTable;
