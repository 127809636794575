import React from 'react';
import s from './DataTable.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

const DataTable = ({ isPending, allergies }) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th className="text-nowrap" data-priority="3">
            Title
          </th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {allergies?.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.title}</td>
            <td>
              <Link
                to={`/food-allergies/${item.id}/edit`}
                className="mr-3 text-primary"
                id={`edit${item.id}`}
              >
                <i className="mdi mdi-pencil font-size-18" />
              </Link>
              <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                Edit
              </UncontrolledTooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  allergies: PropTypes.array,
};

export default DataTable;
