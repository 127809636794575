export default [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'align',
  'indent',
  'outdent',
  '|',
  'ul',
  'ol',
  '|',
  'font',
  'fontsize',
  'paragraph',
  'lineHeight',
  '|',
  'superscript',
  'subscript',
  '|',
  'cut',
  'copy',
  'paste',
  'selectall',
  'copyformat',
  '\n',
  'table',
  'hr',
  '|',
  'link',
  'symbols',
  'file',
  'video',
  '|',
  'brush',
  'undo',
  'redo',
  '|',
  'find',
  'fullsize',
];
