import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Card, CardHeader, CardFooter, CardBody, Badge } from 'reactstrap';
import styles from './TimeEntryPeriod.scss';
import { Link } from 'react-router-dom';

const TimeEntryPeriod = ({
  period,
  numOfStaff,
  numOfIncomplete,
  numOfLateEntry,
  numOfOvertime,
  approved,
}) => {
  return (
    <Card outline style={{ border: `3px solid ${period.color}` }}>
      <CardHeader
        className={styles.periodCardHeader}
        style={{
          backgroundColor: period.color,
        }}
      >
        <h6>
          {period.title}{' '}
          {approved && numOfStaff > 0 && (
            <Badge color="success" className="float-right">
              Approved
            </Badge>
          )}
          {!approved && numOfStaff > 0 && (
            <Badge color="secondary" className="float-right">
              Pending
            </Badge>
          )}
        </h6>
      </CardHeader>
      <CardBody className={styles.periodCardBody}>
        <Badge color="dark">{`${numOfStaff} Staff`}</Badge>
        {numOfOvertime > 0 && (
          <Badge className={styles.periodBadge} color="danger">
            {numOfOvertime} Overtime
          </Badge>
        )}
        {numOfLateEntry > 0 && (
          <Badge className={styles.periodBadge} color="info">
            {numOfLateEntry} Late Entry
          </Badge>
        )}
        {numOfIncomplete > 0 && (
          <Badge className={styles.periodBadge} color="info">
            {numOfIncomplete} Incomplete
          </Badge>
        )}
      </CardBody>
      <CardFooter className={styles.cardFooter}>
        {numOfStaff ? (
          <Link
            to={`/timesheet-details?title=${
              period?.title
            }&start=${period?.start.toISOString()}&end=${period?.end.toISOString()}`}
            className={cx(
              styles.reviewPeriod,
              'btn btn-sm btn-link font-size-14 float-right'
            )}
          >
            Review Pay Period <i className="mdi mdi-arrow-right-circle mr-1" />
          </Link>
        ) : (
          <span
            to="#"
            className={cx(
              styles.noTimesheets,
              'btn btn-sm btn-link font-size-14 float-right disabled-link'
            )}
          >
            No timesheets submitted
          </span>
        )}
      </CardFooter>
    </Card>
  );
};

TimeEntryPeriod.propTypes = {
  period: PropTypes.object,
  numOfStaff: PropTypes.number,
  numOfIncomplete: PropTypes.number,
  numOfLateEntry: PropTypes.number,
  numOfOvertime: PropTypes.number,
  approved: PropTypes.bool,
};

export default TimeEntryPeriod;
