import { takeLatest, all } from 'redux-saga/effects';
import * as queryString from 'query-string';

import api from 'api';
import { history } from 'src/client';

import { actions } from './slice';

export function* fetchHostsSaga(action) {
  const stringified = queryString.stringify(action.payload);
  const url = `/hosts/?${stringified}`;
  if (history.location.pathname.includes('/hosts/')) {
    yield history.push(url);
  }

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchHostSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/hosts/${action.payload}`,
  });
}

export function* attachHostSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/hosts/attach/`,
    data: action.payload,
    successMessage: 'Successfully created new host!',
    successNavigateTo: `/hosts/`,
  });
}

export function* inviteHostSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/hosts/invite/`,
    data: action.payload,
    successMessage: 'Successfully invited host!',
    successNavigateTo: `/hosts/`,
  });
}

export function* deleteHostSaga(action) {
  yield api({
    action,
    method: 'post',
    data: { id: action.payload },
    url: `/hosts/${action.payload}/detach/`,
    successMessage: 'Successfully detached host!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchHosts, fetchHostsSaga),
    takeLatest(actions.attachHost, attachHostSaga),
    takeLatest(actions.inviteHost, inviteHostSaga),
    takeLatest(actions.fetchHost, fetchHostSaga),
    takeLatest(actions.deleteHost, deleteHostSaga),
  ]);
}
