import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as exciseActions } from 'models/excise/slice';
import { isPendingSelector } from 'models/excise/selectors';

import Form from './Form';

const ExciseLicenseCreate = ({ title }) => {
  const createExciseLicense = useAction(exciseActions.createExciseLicense);
  const isPending = useSelector(isPendingSelector);

  const onSubmit = values => {
    createExciseLicense({
      data: values,
    });
  };

  if (isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create excise license"
        breadcrumbItems={[
          { title: 'Back to excise licenses', link: '/reports/excise' },
        ]}
      />
      <Row>
        <Col md="12">
          <Form onSubmit={onSubmit} disabled={isPending} submitTitle="Save" />
        </Col>
      </Row>
    </PageWrapper>
  );
};

ExciseLicenseCreate.propTypes = {
  title: PropTypes.string,
};

export default ExciseLicenseCreate;
