import React, { useEffect } from 'react';
import {
  FRESHDESK_SCRIPT,
  FRESHDESK_STYLESHEET,
  FRESHDESK_FEEDBACK_FORM,
} from './constants';

const SupportSummary = () => {
  useEffect(() => {
    // Append the Freshdesk script to the document head
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = FRESHDESK_SCRIPT;
    document.head.appendChild(script);

    // Append the Freshdesk stylesheet to the document head
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = FRESHDESK_STYLESHEET;
    document.head.appendChild(link);

    // Cleanup function to remove the script and stylesheet when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div>
      <iframe
        title="Feedback Form"
        className="freshwidget-embedded-form"
        id="freshwidget-embedded-form"
        src={FRESHDESK_FEEDBACK_FORM}
        scrolling="no"
        height="800px"
        width="90%"
        frameBorder="0"
      />
    </div>
  );
};

export default SupportSummary;
