import React, { memo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export const RolesOptions = [
  { value: 'owner', label: 'Owner' },
  { value: 'manager', label: 'Manager' },
  { value: 'host', label: 'Host' },
  { value: 'sales_rep', label: 'Sales Rep' },
  { value: 'bookkeeper', label: 'Bookkeeper' },
];

const RolesField = ({ options, ...props }) => {
  return <Select {...props} options={options || RolesOptions} />;
};

RolesField.propTypes = {
  options: PropTypes.array,
};
export default memo(RolesField);
