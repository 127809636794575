import React, { useEffect } from 'react';
import styles from './Popup.scss';
import PropTypes from 'prop-types';

const PopupLayout = ({ onClose, children }) => {
  useEffect(() => {
    const onKeyDown = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    // @ts-ignore
    document.addEventListener('keydown', onKeyDown);

    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.marginRight = `${scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'inherit';
      document.body.style.marginRight = '0';
      // @ts-ignore
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const containerClickHandler = event => {
    event.stopPropagation();
    onClose();
  };

  return (
    <div className={styles.container} onClick={containerClickHandler}>
      <div onClick={e => e.stopPropagation()} className={styles.content}>
        {children}
      </div>
    </div>
  );
};

PopupLayout.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
};
export default PopupLayout;
