import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const importDataSourcesSlice = createSlice({
  name: 'importDataSources',
  initialState: {
    isPending: false,
    importIsPending: false,
    dataSources: [],
    dataImports: {},
  },
  reducers: {
    fetchDataSources: state => {
      state.isPending = true;
    },
    fetchDataSourcesSuccess(state, { payload }) {
      state.isPending = false;
      state.dataSources = payload.data;
    },
    fetchDataSourcesFailure: state => {
      state.isPending = false;
    },
    runImport: state => {
      state.importIsPending = true;
    },
    runImportSuccess: (state, { payload }) => {
      state.dataImports = payload.data;
      state.importIsPending = false;
    },
    runImportFailure: state => {
      state.importIsPending = false;
    },

    resetModalDataImported: state => {
      state.dataImports = {};
    },
  },
});

export const actions = actionTypes(importDataSourcesSlice.actions);

export default importDataSourcesSlice.reducer;
