import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    isPending: false,
    devices: [],
    device: {},
    pagination: {},
  },
  reducers: {
    fetchDevices: state => {
      state.isPending = true;
    },
    fetchDevicesSuccess(state, { payload }) {
      state.isPending = false;
      state.devices = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchDevicesFailure: state => {
      state.isPending = false;
    },
    fetchDevice: state => {
      state.isPending = true;
    },
    fetchDeviceSuccess(state, { payload }) {
      state.isPending = false;
      state.device = payload.data;
    },
    fetchDeviceFailure: state => {
      state.isPending = false;
    },
    createDevice: state => {
      state.isPending = true;
    },
    createDeviceSuccess(state, { payload }) {
      state.devices = state.devices.concat(payload.data);
      state.isPending = false;
    },
    createDeviceFailure: state => {
      state.isPending = false;
    },
    deleteDevice: state => {
      state.isPending = true;
    },
    deleteDeviceSuccess(state, { payload }) {
      state.devices = state.devices.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteDeviceFailure: state => {
      state.isPending = false;
    },
    updateDevice: state => {
      state.isPending = true;
    },
    updateDeviceSuccess(state, { payload }) {
      state.devices.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateDeviceFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(devicesSlice.actions);

export default devicesSlice.reducer;
