import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import styles from './Tabs.scss';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  const mapTabs = (tabs || []).map(tab => (
    <NavItem key={tab.id}>
      <NavLink
        onClick={() => {
          setActiveTab(tab.id);
          tab?.handler?.();
        }}
        className={classNames(activeTab === tab.id && 'active')}
        disabled={activeTab <= tab.id && tab.disabled}
      >
        <span
          className={classNames(
            'step-number',
            activeTab <= tab.id && tab.disabled && styles.disabled
          )}
        >
          {String(tab.id).padStart(2, '0')}
        </span>
        <span className="step-title">{tab.title}</span>
      </NavLink>
    </NavItem>
  ));

  return (
    <Row>
      <Col lg={12}>
        <div id="checkout-nav-pills-wizard" className="twitter-bs-wizard">
          <Nav pills justified className="twitter-bs-wizard-nav">
            {mapTabs}
          </Nav>
        </div>
      </Col>
    </Row>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
};

export default Tabs;
