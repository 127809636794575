import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { lowInventoryPaginationSelector } from 'models/inventories/selectors';
import useSelector from 'hooks/useSelector';
import { actions } from 'models/inventories/slice';
import useAction from 'hooks/useAction';
import { MILLISECONDS_IN_AN_HOUR } from 'constants/time';

const LowInventoryCountTitle = ({ title }) => {
  // Actions
  const fetchLowInventory = useAction(actions.fetchLowInventory);

  // Selectors
  const pagination = useSelector(lowInventoryPaginationSelector);

  // Effects
  useEffect(() => {
    fetchLowInventory({ setQuery: false });

    const interval = setInterval(
      () => fetchLowInventory({ setQuery: false }),
      MILLISECONDS_IN_AN_HOUR
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

LowInventoryCountTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LowInventoryCountTitle;
