import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import { VOLUME_UNIT } from 'constants';

import styles from './ProductsPresetForm.scss';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import { getRedactedValues } from 'utils/getRedactedValues';
import TextField from '../Fields/TextField';
import NumberField from '../Fields/NumberField';
import { selectStyles } from 'constants/selectStyles';

const Form = ({
  model,
  submitTitle,
  inventories,
  disabled,
  currentWarehouse,
  setCurrentWarehouse,
  onSubmit,
}) => {
  const [defaultProducts, setDefaultProducts] = useState(
    model?.products?.map(product => ({
      value: product.id,
      label: product.title,
    }))
  );
  const [volume, setVolume] = useState(
    VOLUME_UNIT.find(_volume => model?.volume_unit === _volume.value) ||
      VOLUME_UNIT[0]
  );

  useEffect(() => {
    if (model) {
      setDefaultProducts(
        model.products.map(product => ({
          value: product.id,
          label: product.title,
        }))
      );
    }
  }, [model]);

  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        id: model?.id,
        ...getRedactedValues(model, {
          ...values,
          volume_unit: volume?.value,
          warehouse_id: currentWarehouse?.value,
          price: parseFloat(values.price),
          products: defaultProducts.map(product => product.value),
        }),
      });
    },
    [onSubmit, defaultProducts]
  );

  const handleProductsChange = selectedProducts => {
    setDefaultProducts(selectedProducts);
  };

  const handleWarehouseChange = data => {
    setCurrentWarehouse(data);
    setDefaultProducts([]);
  };

  const productsOptions = inventories
    ?.filter(inventory => inventory.total_inventory_count > 0)
    .map(product => ({
      value: product.id,
      label: product.title,
    }));

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="3">
          <TextField
            isRequired
            name="title"
            label="Title"
            placeholder="Tasting menu title"
            errorMessage="Enter Title"
            value={model?.title}
          />
        </Col>
        <Col md="3">
          <NumberField
            isRequired
            name="price"
            label="Price"
            placeholder="CAD"
            errorMessage="Enter Price"
            value={model?.price}
          />
        </Col>
        <Col md="3">
          <TextField isRequired name="sku" label="SKU" value={model?.sku} />
        </Col>
        <Col md="3">
          <NumberField
            isRequired
            name="volume_value"
            label="Volume"
            value={model?.volume_value}
          />
        </Col>
        <Col md="3">
          <FormGroup>
            <Label htmlFor="volume_unit">Volume Unit</Label>
            <Select
              options={VOLUME_UNIT}
              onChange={data => setVolume(data)}
              defaultValue={volume}
              styles={selectStyles}
            />
          </FormGroup>
        </Col>
        <div className={classNames('w-100', disabled && styles.preloader)}>
          <Col lg="12">
            <FormGroup>
              <Label required htmlFor="warehouse_id">
                Warehouse
              </Label>
              <WarehousesPaginatedField
                parameters={{ is_used_as_tasting_room: true }}
                value={currentWarehouse}
                onChange={handleWarehouseChange}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Label htmlFor="products">Products</Label>
              <Select
                value={defaultProducts}
                isMulti
                name="products"
                options={productsOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleProductsChange}
                isDisabled={!currentWarehouse}
              />
            </FormGroup>
          </Col>
        </div>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  inventories: PropTypes.array,
  currentWarehouse: PropTypes.any,
  setCurrentWarehouse: PropTypes.func,
};

export default Form;
