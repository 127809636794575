import React from 'react';
import classNames from 'classnames';
import { formatDate } from 'utils/formatDate';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip, CustomInput } from 'reactstrap';
import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';
import useAction from 'hooks/useAction';
import { actions as discountActions } from 'models/discounts/slice';
import styles from './DataTable.scss';

const DataTable = ({
  isPending,
  discounts,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  isCouponCode,
}) => {
  const updateCode = useAction(discountActions.updateCode);
  const onChangeHandler = event => {
    updateCode({
      id: event.target.id,
      status: event.target.checked ? 'ACTIVE' : 'INACTIVE',
    });
  };

  const formatAmount = item => {
    if (item.percentage_amount) {
      return `${item.percentage_amount}%`;
    } else if (item.money_amount) {
      return `$${item.money_amount}`;
    }
    return '-';
  };

  const discountStatus = (startDate, endDate) => {
    const currentDate = formatDate(new Date(), 'yyyy-MM-dd');
    if (startDate > currentDate) {
      return 'Scheduled';
    }
    if (startDate <= currentDate && endDate >= currentDate) {
      return 'Active';
    }
    if (endDate < currentDate) {
      return 'Expired';
    }
    return '-';
  };

  return (
    <>
      <thead>
        <tr>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'created_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="3"
          >
            Created At
            <SortArrows
              isActive={sortField === 'created_at'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="1">Brand</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="3"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          {isCouponCode && <th data-priority="1">Code</th>}
          <th data-priority="3">Amount</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'promotion_start_date',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Start Date
            <SortArrows
              isActive={sortField === 'promotion_start_date'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'promotion_end_date',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="3"
          >
            End Date
            <SortArrows
              isActive={sortField === 'promotion_end_date'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Status</th>
          <th data-priority="3">Enabled</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={classNames(isPending && styles.preloader)}>
        {discounts?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.created_at ? formatDate(item.created_at) : ''}
              </Link>
            </td>
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.brand_title}
              </Link>
            </td>
            <td>
              {' '}
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>{' '}
            </td>
            {isCouponCode && (
              <td>
                <Link
                  to={`/discount-codes/${item.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {item.coupon}
                </Link>
              </td>
            )}
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {formatAmount(item)}
              </Link>
            </td>
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {' '}
                {item.promotion_start_date
                  ? formatDate(item.promotion_start_date, 'yyyy-MM-dd')
                  : '-'}{' '}
              </Link>
            </td>
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {' '}
                {item.promotion_end_date
                  ? formatDate(item.promotion_end_date, 'yyyy-MM-dd')
                  : '-'}
              </Link>
            </td>
            <td>
              <Link
                to={`/discount-codes/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {discountStatus(
                  item.promotion_start_date,
                  item.promotion_end_date
                )}
              </Link>
            </td>
            <td>
              <CustomInput
                id={item.id}
                type="switch"
                checked={item.status === 'ACTIVE'}
                onChange={onChangeHandler}
              />
            </td>
            <td>
              <>
                <Link
                  to={`/discount-codes/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  discounts: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  isCouponCode: PropTypes.bool,
};

export default DataTable;
