import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PopupBase from '../PopupBase';
import styles from './SelectionPopup.scss';

const SelectionPopup = ({
  isOpen,
  setIsOpen,
  pending,
  disabled,
  title,
  onSaveClick,
  children,
  submitButtonColor,
  submitButtonTitle,
  withFooter,
}) => {
  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const handleSaveClick = useCallback(() => {
    onSaveClick();
    toggleModal();
  }, [onSaveClick, toggleModal]);

  return (
    <PopupBase className={styles.popup} onClose={toggleModal}>
      <ModalHeader toggle={toggleModal} closeButton>
        {title}
      </ModalHeader>
      <ModalBody>{pending ? 'Loading...' : children}</ModalBody>
      {withFooter && (
        <ModalFooter>
          <Button
            color="light"
            type="button"
            onClick={toggleModal}
            className="waves-effect"
          >
            Close
          </Button>
          <Button
            type="button"
            onClick={handleSaveClick}
            color={submitButtonColor}
            className="waves-effect waves-light"
            disabled={pending || disabled}
          >
            {submitButtonTitle}
          </Button>
        </ModalFooter>
      )}
    </PopupBase>
  );
};

SelectionPopup.propTypes = {
  isOpen: PropTypes.bool,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.string,
  setIsOpen: PropTypes.func,
  onSaveClick: PropTypes.func,
  submitButtonTitle: PropTypes.string,
  submitButtonColor: PropTypes.string,
  withFooter: PropTypes.bool,
};

SelectionPopup.defaultProps = {
  title: 'Select items',
  submitButtonTitle: 'Submit',
  submitButtonColor: 'success',
  pending: false,
  disabled: false,
  withFooter: true,
};

export default memo(SelectionPopup);
