import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import WineFields from '../WineFields';
import { isCreatingSelector } from 'models/products/selectors';

import useSelector from 'hooks/useSelector';
import SelectField from 'components/SelectField/SelectField';
import { FormGroup, Label, Button, Row, Col } from 'reactstrap';

import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import BrandsPaginatedField from '../../PaginatedFields/BrandsPaginatedField';
import { useCategoriesOptions } from 'hooks/useCategoriesOptions';
import AwardPaginatedField from '../../PaginatedFields/AwardPaginatedField';
import useAction from 'hooks/useAction';
import { actions as tagsAction } from 'models/tags/slice';
import { tagsSelector } from 'models/tags/selectors';
import { getRedactedValues } from 'utils/getRedactedValues';
import TextField from '../../Fields/TextField';
import { SORTS_OF_WINE_OPTIONS, WEIGHT_UNIT } from 'constants';
import { showErrorMessage } from 'utils/notification';

const maxDate = new Date().getFullYear();

const getPreparedTags = tags => {
  return tags.reduce(
    (previous, current) => ({
      ...previous,
      [current]: true,
    }),
    {}
  );
};

const getSelectedTags = tags => {
  return Object.entries(tags)
    .filter(([, value]) => value)
    .map(([key]) => key);
};

const Form = ({
  model,
  onSubmit,
  categories,
  resourcetype,
  disabled,
  submitTitle,
  isDuplicate,
}) => {
  const {
    selectedBrand,
    selectedCategories,
    brandError,
    categoriesError,
    setBrandTouched,
    setCategoriesTouched,
    onBrandChange,
    onCategoriesChange,
  } = useCategoriesOptions({
    defaultValues: { brand: model?.brand, categories: model?.categories ?? [] },
  });
  const isCreating = useSelector(isCreatingSelector);
  const [selectedTags, setSelectedTags] = useState(
    getPreparedTags(model?.tags ?? [])
  );
  const [label, setLabel] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [isRemoveLabel, setIsRemoveLabel] = useState(false);
  const [isRemoveMainImage, setIsRemoveMainImage] = useState(false);
  const [canBeASample, setCanBeASample] = useState(model?.can_be_a_sample);
  const [awards, setAwards] = useState(model?.awards || []);
  const [weightUnit, setWeightUnit] = useState(WEIGHT_UNIT[0]);
  const [sortOfWine, setSortOfWine] = useState(
    model?.sort
      ? SORTS_OF_WINE_OPTIONS.find(i => i.label === model?.sort)
      : null
  );

  const vintageYears = () => {
    const startYear = 1960;
    const endYear = maxDate;
    const yearsArray = [];
    yearsArray.push({ id: 0, title: 'Non Vintage' });

    for (let year = endYear; year >= startYear; year -= 1) {
      yearsArray.push({ id: year, title: year.toString() });
    }
    return yearsArray;
  };

  const [vintageYear, setVintageYear] = useState(
    Object.keys(model).length === 0
      ? null
      : { id: model.vintage, title: model.vintage } || {
          id: 0,
          title: 'Non Vintage',
        }
  );

  const fetchTags = useAction(tagsAction.fetchTags);
  const tags = useSelector(tagsSelector);

  const availableCategories = useMemo(() => {
    return categories?.filter(
      category =>
        !category.brand_id || category.brand_id === selectedBrand?.value
    );
  }, [categories, selectedBrand]);

  useEffect(() => {
    setCanBeASample(model?.can_be_a_sample ?? false);
    setVintageYear(
      { id: model.vintage, title: model.vintage } || {
        id: 0,
        title: 'Non Vintage',
      }
    );
  }, [model]);

  useEffect(() => {
    if (selectedBrand?.value) {
      fetchTags({ brandId: selectedBrand.value });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (tags) {
      setSelectedTags(
        tags.reduce(
          (previous, current) => ({
            ...previous,
            [current.name]: model?.tags?.includes(current.name),
          }),
          {}
        )
      );
    }
  }, [tags]);

  const handleError = (event, errors) => {
    // Necessary measure: AVForm does not allow access to Fields reference
    const firstErrorName = errors[0];
    const field = document.querySelector(`[name="${firstErrorName}"]`);
    field.focus();
    setBrandTouched(true);
    setCategoriesTouched(true);
  };

  const handleSubmit = useCallback(
    (event, values) => {
      const vintage = vintageYear?.id === 0 ? null : vintageYear?.id;
      if (!vintage && !values.lot) {
        showErrorMessage('Error!', 'One of "Lot" or "Vintage" is required.');
        return;
      }

      const newValues = omitBy(values, isNil);
      const awardList = awards.map(a => a.id);
      const data = {
        ...newValues,
        awards: awardList,
        tags: getSelectedTags(selectedTags),
        resourcetype: resourcetype?.value,
        brand_id: selectedBrand?.value,
        categories: selectedCategories.map(item => item.id),
        weight_unit: weightUnit?.value,
        sort: sortOfWine?.value,
        vintage,
      };

      if (label || isRemoveLabel) {
        data.label = label;
      }
      if (mainImage || isRemoveMainImage) {
        data.main_image = mainImage;
      }

      if (isDuplicate) {
        onSubmit({
          id: model?.id,
          ...model,
          ...data,
        });
        return;
      }

      onSubmit({
        id: model?.id,
        ...getRedactedValues(model, data),
      });
    },
    [
      onSubmit,
      label,
      selectedTags,
      awards,
      isRemoveLabel,
      resourcetype,
      mainImage,
      isRemoveMainImage,
      selectedCategories,
      sortOfWine,
      weightUnit,
      selectedBrand,
      vintageYear,
    ]
  );

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onInvalidSubmit={handleError}
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="3">
          <FormGroup>
            <Label htmlFor="brand_id">Brand*</Label>
            <BrandsPaginatedField
              name="brand_id"
              value={selectedBrand}
              onChange={onBrandChange}
              isMulti={false}
              onBlur={() => setBrandTouched(true)}
            />
            {brandError && (
              <small className="text-danger">This field is required</small>
            )}
          </FormGroup>
        </Col>
        <Col md="3">
          <TextField
            isRequired
            name="title"
            label="Title"
            placeholder="Title"
            errorMessage="Enter Title"
            value={model?.title}
          />
        </Col>
        <Col md="3">
          <TextField
            isRequired
            label="SKU"
            name="sku"
            value={model?.sku}
            placeholder="SKU"
          />
        </Col>
        <Col md="3">
          <TextField
            isRequired
            name="price"
            label="Price"
            value={model?.price}
            errorMessage="Enter Price"
            rules={{
              pattern: {
                value: '^[0-9.]+$',
                errorMessage: 'Only positive number',
              },
            }}
          />
        </Col>
        <Col md="3">
          <TextField
            name="wholesale_price"
            isRequired
            label="Wholesale Price"
            value={model?.wholesale_price}
            errorMessage="Enter Wholesale Price"
            rules={{
              pattern: {
                value: '^[0-9.]+$',
                errorMessage: 'Only positive number',
              },
            }}
          />
        </Col>
        <Col md="3">
          <TextField
            isRequired
            name="dfs_price"
            label="DFS Price"
            value={model?.dfs_price}
            errorMessage="Enter DFS Price"
            rules={{
              pattern: {
                value: '^[0-9.]+$',
                errorMessage: 'Only positive number',
              },
            }}
          />
        </Col>
        <Col md="3">
          <FormGroup>
            <SelectField
              name="categories"
              options={availableCategories}
              label="Categories*"
              onChange={onCategoriesChange}
              value={selectedCategories}
              multiple
              onBlur={() => setCategoriesTouched(true)}
              isDisabled={!selectedBrand}
              error={categoriesError && 'Field is required'}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <SelectField
            name="vintage"
            options={vintageYears()}
            label="Vintage*"
            value={vintageYear}
            onChange={setVintageYear}
            errorMessage="Field is Required"
          />
        </Col>
        <Col md="3">
          <TextField
            name="lot"
            label="Lot"
            value={model?.lot}
            errorMessage="Enter Valid Lot Number"
          />
        </Col>
        <Col md="3">
          <TextField name="container_world_sku" label="Container World SKU" />
        </Col>
      </Row>
      {(resourcetype?.value || resourcetype) === 'Wine' && (
        <WineFields
          tags={selectedTags}
          isRemoveLabel={isRemoveLabel}
          setIsRemoveLabel={setIsRemoveLabel}
          canBeASample={canBeASample}
          setCanBeASample={setCanBeASample}
          isRemoveMainImage={isRemoveMainImage}
          setIsRemoveMainImage={setIsRemoveMainImage}
          model={model}
          setLabel={setLabel}
          setMainImage={setMainImage}
          setTags={setSelectedTags}
          selectedBrand={selectedBrand}
          weightUnit={weightUnit}
          setWeightUnit={setWeightUnit}
          sortOfWine={sortOfWine}
          setSortOfWine={setSortOfWine}
        />
      )}

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label htmlFor="awards">Awards</Label>
            <div>
              <AwardPaginatedField
                name="awards"
                id="awards"
                menuPlacement="top"
                value={awards}
                onChange={setAwards}
                isMulti
              />
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" type="submit" disabled={isCreating || disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
  resourcetype: PropTypes.string,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  isDuplicate: PropTypes.bool,
};

export default Form;
