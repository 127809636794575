import { useCallback, useState } from 'react';

export const useIncludedExcludedCategoriesOptions = ({
  defaultValues: { includedCategories, excludedCategories },
}) => {
  const [selectedIncludedCategories, setSelectedIncludedCategories] = useState(
    includedCategories
  );
  const [selectedExcludedCategories, setSelectedExcludedCategories] = useState(
    excludedCategories
  );

  const onIncludedCategoriesChange = useCallback(
    data => setSelectedIncludedCategories(data),
    []
  );
  const onExcludedCategoriesChange = useCallback(
    data => setSelectedExcludedCategories(data),
    []
  );

  return {
    selectedIncludedCategories,
    selectedExcludedCategories,
    onIncludedCategoriesChange,
    onExcludedCategoriesChange,
  };
};
