import React from 'react';
import PropTypes from 'prop-types';
import WineForm from './Wine/WineForm';
import UserForm from './User/UserForm';
import WarehouseForm from './Warehouse/WarehouseForm';

// Conditional component for Wine, User, and Warehouse Returns
const Form = props => (
  <>
    {props.exciseLicense?.license_type === 'WINE' && <WineForm {...props} />}
    {props.exciseLicense?.license_type === 'USER' && <UserForm {...props} />}
    {props.exciseLicense?.license_type === 'WAREHOUSE' && (
      <WarehouseForm {...props} />
    )}
  </>
);

Form.propTypes = {
  exciseLicense: PropTypes.object.isRequired,
};

export default Form;
