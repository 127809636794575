/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Table, Card, CardBody } from 'reactstrap';

const Main = ({
  awards,
  inventories,
  food_additions,
  productID,
  wine_pairings,
}) => {
  return (
    <>
      {inventories?.length !== 0 ? (
        <>
          <Card>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-4">Inventory</h4>
                    <Link
                      to={`/transfers/create?productID=${productID}`}
                      className="btn btn-primary mb-4"
                    >
                      Add Inventory
                    </Link>
                  </div>
                  <Table id="tech-companies-1" bordered responsive>
                    <thead>
                      <tr>
                        <th data-priority="1">Product Count</th>
                        <th data-priority="3">Warehouse</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventories?.map((item, index) => (
                        <tr key={index}>
                          <td> {item.product_count}</td>
                          <td>
                            <Link
                              to={`/warehouses/${item.warehouse_id}/edit`}
                              className="text-dark font-weight-bold"
                            >
                              {item.warehouse_title}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
            </div>
          </Card>
        </>
      ) : (
        <Card className="no-inv-card">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="card-title mb-4">Inventories</h4>
              <Link
                to={`/transfers/create?productID=${productID}`}
                className="btn btn-primary mb-4"
              >
                Add Inventory
              </Link>
            </div>
            <p>There is no inventory for this product</p>
          </CardBody>
        </Card>
      )}
      {awards?.length !== 0 && (
        <>
          <Card>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <CardBody>
                  <h4 className="card-title mb-4">Awards</h4>
                  <Table id="tech-companies-1" bordered responsive>
                    <thead>
                      <tr>
                        <th data-priority="1">Title</th>
                        <th data-priority="3">Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {awards?.map(item => (
                        <tr key={item.id}>
                          <td>{item.title}</td>
                          <td>
                            <img
                              src={item.image_v_100x100_url}
                              alt=""
                              height="60"
                            />{' '}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
            </div>
          </Card>
        </>
      )}
      {food_additions?.length !== 0 && (
        <>
          <Card>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <CardBody>
                  <h4 className="card-title mb-4">Food Additions</h4>
                  <Table id="tech-companies-1" bordered responsive>
                    <thead>
                      <tr>
                        <th data-priority="1">Id</th>
                        <th data-priority="3">Image</th>
                        <th data-priority="3">Title</th>
                        <th data-priority="3">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {food_additions?.map(item => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>
                            <img
                              src={item.label_v_300x300_url}
                              alt=""
                              height="60"
                            />{' '}
                          </td>
                          <td>{item.title}</td>
                          <td>{`${item.price} ${item.price_currency}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
            </div>
          </Card>
        </>
      )}
      {wine_pairings?.length !== 0 && (
        <>
          <Card>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <CardBody>
                  <h4 className="card-title mb-4">Wine Pairings</h4>
                  <Table id="tech-companies-1" bordered responsive>
                    <thead>
                      <tr>
                        <th data-priority="1">Id</th>
                        <th data-priority="3">Title</th>
                        <th data-priority="3">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wine_pairings?.map(item => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>{`${item.price} ${item.price_currency}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

Main.propTypes = {
  awards: PropTypes.array,
  productID: PropTypes.string,
  inventories: PropTypes.array,
  food_additions: PropTypes.array,
  wine_pairings: PropTypes.array,
};

Main.defaultProps = {
  productID: '',
};

export default Main;
