export const COMMISSIONS_STATUSES = Object.freeze({
  INITIALIZED: 'INITIALIZED',
  DISPUTED: 'DISPUTED',
  ACCEPTED: 'ACCEPTED',
  ARCHIVED: 'ARCHIVED',
  APPROVED: 'APPROVED',
});

export const PERIOD_OPTIONS = [
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Biweekly', value: 'BIWEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
];
