import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import styles from '../OrderItemsTable.scss';

import formatPrice from 'utils/formatPrice';
import WarehouseInventoryPaginatedField from '../../../PaginatedFields/WarehouseInventoryPaginatedField';

const TableRow = ({
  setProductsInOrder,
  productsInOrder,
  deleteRow,
  orderItem,
  warehouseId,
  productsOrderIds,
  count,
}) => {
  const [changedProduct, setChangedProduct] = useState(
    orderItem.product.id
      ? {
          value: orderItem.product.id,
          label: orderItem.product.title,
        }
      : false
  );

  const productCount = count || changedProduct?.product_count;
  const productPrice = changedProduct?.retail_price || orderItem?.product_price;
  const isDisabled =
    !productsInOrder[orderItem?.id]?.product_id && !changedProduct?.product_id;

  const onValueChange = event => {
    const { value } = event.target;
    let preparedInputValue = '';

    if (value.match(/^((0[1-9]))/)) {
      const preparedValue = value.replace(/^0+/, '') || 0;
      preparedInputValue = Math.min(+preparedValue, productCount);
    } else if (value.match(/^((0|([1-9][0-9]*)))$/)) {
      preparedInputValue = Math.min(Number(value), productCount);
    }

    setProductsInOrder?.({
      id: Number(orderItem?.id),
      number: preparedInputValue,
    });
  };

  const onValueBlur = event => {
    const { value } = event.target;

    setProductsInOrder?.({
      id: Number(orderItem?.id),
      number: Number(value) || 1,
    });
  };

  const handleChangedProduct = item => {
    setProductsInOrder?.({
      id: Number(orderItem?.id),
      number: 1,
      price: item?.retail_price ?? 0,
      ...item,
    });
    setChangedProduct(item);
  };

  return (
    <tr>
      <td className={styles.cell}>
        <div className="d-flex align-items-center w-100">
          <div>
            <i
              id={`delete${orderItem.id}`}
              className={classNames(
                styles.pointer,
                styles.trash,
                'mr-1 mdi mdi-trash-can font-size-14'
              )}
              onClick={() => deleteRow(orderItem.id)}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete${orderItem.id}`}
            >
              Delete this product from order
            </UncontrolledTooltip>
          </div>
          <WarehouseInventoryPaginatedField
            onChange={handleChangedProduct}
            warehouseId={warehouseId}
            options={{ product_types: ['Wine'] }}
            isSubscription
            filterOption={({ value }) => !productsOrderIds.includes(value)}
            defaultValue={changedProduct}
          />
        </div>
      </td>
      <td className={classNames(isDisabled && styles.preloader)}>
        <input
          type="text"
          className="form-control"
          value={productsInOrder[orderItem?.id]?.number ?? ''}
          id="number"
          name="number"
          onChange={onValueChange}
          onBlur={onValueBlur}
          disabled={isDisabled}
        />
      </td>
      <td className={classNames(isDisabled && styles.preloader)}>
        {productCount || 0}
      </td>
      <td className={classNames(isDisabled && styles.preloader)}>
        {formatPrice(productPrice, orderItem?.product_price_currency)}
      </td>
      <td className={classNames(isDisabled && styles.preloader)}>
        {formatPrice(
          productsInOrder[orderItem.id]?.number * productPrice,
          orderItem?.product_price_currency
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  orderItem: PropTypes.object,
  deleteRow: PropTypes.func,
  productsInOrder: PropTypes.object,
  warehouseId: PropTypes.number,
  setProductsInOrder: PropTypes.func,
  productsOrderIds: PropTypes.array,
  count: PropTypes.number,
};

export default TableRow;
