import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const wineDirectSyncSlice = createSlice({
  name: 'wineDirectSync',
  initialState: {
    isPending: false,
    wineDirectSyncSettings: {},
    wineDirectSyncNow: {},
    wineDirectSyncs: {},
    failedWineDirectSyncs: {},
  },
  reducers: {
    createWineDirectSyncSettings: state => {
      state.isPending = true;
    },
    createWineDirectSyncSettingsSuccess(state, { payload }) {
      state.isPending = false;
      state.wineDirectSyncSettings = payload.data.results;
    },
    createWineDirectSyncSettingsFailure: state => {
      state.isPending = false;
    },
    updateWineDirectSyncSettings: state => {
      state.isPending = true;
    },
    updateWineDirectSyncSettingsSuccess(state, { payload }) {
      state.isPending = false;
      state.wineDirectSyncSettings = payload.data;
    },
    updateWineDirectSyncSettingsFailure: state => {
      state.isPending = false;
    },
    fetchWineDirectSyncSettings: state => {
      state.isPending = true;
    },
    fetchWineDirectSyncSettingsSuccess(state, { payload }) {
      state.isPending = false;
      state.wineDirectSyncSettings = payload.data;
    },
    fetchWineDirectSyncSettingsFailure: state => {
      state.isPending = false;
    },
    wineDirectSyncNow: state => {
      state.isPending = true;
    },
    wineDirectSyncNowSuccess(state, { payload }) {
      state.isPending = false;
      state.wineDirectSyncNow = payload.data;
    },
    wineDirectSyncNowFailure: state => {
      state.isPending = false;
    },
    fetchWineDirectSyncs: state => {
      state.isPending = true;
    },
    fetchFailedWineDirectSyncs: state => {
      state.isPending = true;
    },
    fetchWineDirectSyncsSuccess(state, { payload }) {
      state.isPending = false;
      state.wineDirectSyncs = payload.data;
    },
    fetchWineDirectSyncsFailure: state => {
      state.isPending = false;
    },
    fetchFailedWineDirectSyncsSuccess(state, { payload }) {
      state.isPending = false;
      state.failedWineDirectSyncs = payload.data;
    },
    fetchFailedWineDirectSyncsFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(wineDirectSyncSlice.actions);

export default wineDirectSyncSlice.reducer;
