import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { hasPermissions, hasSettings } from 'utils/hasPermissions';
import styles from './NavLinkRenderer.scss';

const hasActive = (node, location) => {
  const paths = [];

  if (node?.children) {
    node?.children?.forEach(({ path, children }) => {
      if (children) {
        children.forEach(child => paths.push(child.path));
      }
      paths.push(path);
    });
  }

  const currentPath = location?.pathname || '';

  return (
    currentPath.includes(node?.path) ||
    paths.some(path => currentPath.includes(path))
  );
};

const NavLinkRenderer = ({ node, roles, settings }) => {
  const location = useLocation();

  const hasChildren = useMemo(
    () => node?.children && node.children.length > 0,
    [node]
  );
  const isActive = useMemo(() => hasActive(node, location), [
    node.path,
    location.pathname,
  ]);

  if (!hasPermissions(node?.permissions, roles)) {
    return <></>;
  }

  if (
    node?.workspace_settings &&
    !hasSettings(node?.workspace_settings, settings)
  ) {
    return <></>;
  }

  return (
    <li
      className={classNames(isActive && 'mm-active', isActive && styles.active)}
    >
      <NavLink
        to={node.path}
        className={classNames(
          'waves-effect',
          hasChildren && 'has-arrow',
          isActive && styles.linkActive
        )}
        aria-expanded="false"
      >
        {node?.icon && (
          <i className={classNames(node.icon, isActive && styles.activeIcon)} />
        )}
        <span className="ml-1">{node.title}</span>
      </NavLink>
      {hasChildren && (
        <ul className="sub-menu mm-active">
          {node.children.map(child => (
            <NavLinkRenderer
              key={child.path}
              node={child}
              roles={roles}
              settings={settings}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

NavLinkRenderer.propTypes = {
  node: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
};

export default NavLinkRenderer;
