import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as terminalsActions } from 'models/terminals/slice';
import {
  terminalSelector,
  isPendingSelector as terminalsIsPendingSelector,
} from 'models/terminals/selectors';

import Main from './Main';

const TerminalShow = ({ title }) => {
  const { terminalID } = useParams();

  const terminal = useSelector(terminalSelector);
  const fetchTerminal = useAction(terminalsActions.fetchTerminal);
  const terminalIsPending = useSelector(terminalsIsPendingSelector);

  useEffect(() => {
    fetchTerminal(terminalID);
  }, [fetchTerminal, terminalID]);

  if (terminalIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/terminals/${terminal?.id}/edit`}
        title={`${title} #${terminal?.id}`}
        breadcrumbItems={[{ title: 'Back to terminals', link: '/terminals/' }]}
      />
      <Row>
        <Col md="12">
          <Main terminal={terminal} />
        </Col>
      </Row>
    </PageWrapper>
  );
};

TerminalShow.propTypes = {
  title: PropTypes.string,
};

export default TerminalShow;
