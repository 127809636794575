import { takeLatest, all, select, put } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

import { paginationSelector } from './selectors';

function* fetchImagesSaga(action) {
  const { url } = yield generateURL({
    payload: { ...(action.payload ?? {}) },
    url: '/email_image/',
  });
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* addImageSaga(action) {
  const formData = new FormData();

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });

  yield api({
    action,
    method: 'post',
    url: `/email_image/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully added new image!',
  });
}

function* addImageSuccessSaga(action) {
  const pagination = yield select(paginationSelector);

  if (pagination.current_page !== 1) {
    const payload = {
      page: 1,
      brand_id: action.payload.inResponseTo.brand_id,
    };
    yield put({ type: actions.fetchImages, payload });
  }
}

function* deleteImageSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/email_image/${action.payload}`,
    successMessage: 'Successfully deleted image!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchImages, fetchImagesSaga),
    takeLatest(actions.addImage, addImageSaga),
    takeLatest(actions.addImageSuccess, addImageSuccessSaga),
    takeLatest(actions.deleteImage, deleteImageSaga),
  ]);
}
