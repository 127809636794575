import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { useParams } from 'react-router-dom';

import { actions as organizationsActions } from 'models/organizations/slice';
import {
  organizationSelector,
  isPendingSelector as isOrganizationsPendingSelector,
} from 'models/organizations/selectors';
import Preloader from 'components/Preloader';
import OrganizationForm from 'components/OrganizationForm';

const EditLead = ({ title }) => {
  const { id } = useParams();
  const editLead = useAction(organizationsActions.updateOrganization);
  const fetchLead = useAction(organizationsActions.fetchOrganization);
  const lead = useSelector(organizationSelector);
  const leadsPending = useSelector(isOrganizationsPendingSelector);

  React.useEffect(() => {
    fetchLead(id);
  }, [fetchLead, id]);

  if (leadsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Lead"
        breadcrumbItems={[
          {
            title: 'Back to Lead',
            link: '/leads/',
            withSearch: true,
          },
        ]}
      />

      <OrganizationForm
        onSubmit={editLead}
        submitTitle="Save"
        model={{
          ...lead,
          contact_person_first_name: lead?.contact_person?.first_name,
          contact_person_last_name: lead?.contact_person?.last_name,
          contact_person_email: lead?.contact_person?.email,
          contact_person_phone: lead?.contact_person?.phone,
        }}
        disableEditing
        isLead
        isEditing
      />
    </PageWrapper>
  );
};

EditLead.propTypes = {
  title: PropTypes.string,
};

export default EditLead;
