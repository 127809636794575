import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import CardItem from 'components/CardItem';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as transfersActions } from 'models/transfers/slice';
import { transferSelector } from 'models/transfers/selectors';

import SaleTransfer from './SaleTransfer';
import OtherTransfers from './OtherTransfers';
import WarehouseTransfer from './WarehouseTransfer';
import TransferPopup from 'components/Popup/TransferPopup/TransferPopup';
import { showErrorMessage } from 'utils/notification';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';

const ShowTransfer = ({ title }) => {
  const { transferID } = useParams();
  const fetchTransfer = useAction(transfersActions.fetchTransfer);
  const transfer = useSelector(transferSelector);
  const [previewLayoutModal, setPreviewLayoutModal] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [clicked, setClicked] = useState('');
  const [isInvalid, setIsInvalid] = useState({});
  const [exciseReason, setExciseReason] = useState({});

  const hasOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const approveTransfer = useAction(transfersActions.approveTransfer);
  const disputeTransfer = useAction(transfersActions.disputeTransfer);
  const resubmitTransfer = useAction(transfersActions.resubmitTransfer);

  useEffect(() => {
    fetchTransfer(transferID);
  }, [fetchTransfer, transferID]);

  const transferStatus = transfer?.status;

  const onButtonClick = button => {
    if (button === 'Resubmit') {
      const allQuantitiesUpdated = transfer?.transfer_items?.every(item => {
        const itemId = item.product.id.toString();
        return quantity[itemId] !== undefined && quantity[itemId] !== null;
      });

      if (!allQuantitiesUpdated) {
        showErrorMessage(
          'Error',
          'Please update quantities for all items before resubmitting.'
        );
        return;
      }

      if (
        Object.values(isInvalid)
          .map(item => item)
          .includes(true)
      ) {
        showErrorMessage(
          'Error',
          'Please type valid quantities for all items before resubmitting.'
        );
        return;
      }
    }
    setClicked(button);
    setPreviewLayoutModal(true);
  };

  const processApprove = data => {
    const payload = {
      transfer_id: transfer?.id,
      comment: data.comment,
    };

    approveTransfer(payload);
  };

  const processResubmit = data => {
    const payload = {
      transfer_id: transfer?.id,
      sender_id: transfer?.sender?.id,
      receiver_id: transfer?.receiver?.id,
      comment: data.comment,
      status: transferStatus,
      excise_reason: exciseReason,
      transfer_items: Object.keys(quantity).map(key => ({
        product_id: Number(key),
        quantity: quantity[key],
      })),
    };

    resubmitTransfer(payload);
  };

  const processDispute = data => {
    const payload = {
      transfer_id: transfer?.id,
      comment: transfer?.comment,
      disputing_manager_comment: data.comment,
      status: transfer?.status,
    };

    disputeTransfer(payload);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`${title} #${transferID}`}
        breadcrumbItems={[
          {
            title: 'Back to Product Movements',
            link: '/transfers/',
            withSearch: true,
          },
        ]}
      />
      {previewLayoutModal && clicked === 'Dispute' && (
        <TransferPopup
          handleSubmit={processDispute}
          active={previewLayoutModal}
          setActive={setPreviewLayoutModal}
          heading="Dispute Transfer"
        />
      )}
      {previewLayoutModal && clicked === 'Approve' && (
        <TransferPopup
          handleSubmit={processApprove}
          active={previewLayoutModal}
          setActive={setPreviewLayoutModal}
          heading="Complete Transfer"
        />
      )}
      {previewLayoutModal && clicked === 'Resubmit' && (
        <TransferPopup
          handleSubmit={processResubmit}
          active={previewLayoutModal}
          setActive={setPreviewLayoutModal}
          heading="Resubmit Transfer"
        />
      )}
      {transferStatus === 'PENDING_RECEIPT' &&
        transfer?.resourcetype === 'WarehouseToWarehouseTransfer' &&
        hasOwnerOrManagerRole && (
          <Row className="justify-content-end mb-3">
            <Col md={2}>
              <Button
                className="w-100"
                color="danger"
                onClick={() => onButtonClick('Dispute')}
              >
                Dispute
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className="w-100"
                color="success"
                onClick={() => onButtonClick('Approve')}
              >
                Approve
              </Button>
            </Col>
          </Row>
        )}
      {transferStatus === 'DISPUTED' &&
        transfer?.resourcetype === 'WarehouseToWarehouseTransfer' &&
        hasOwnerOrManagerRole && (
          <Row className="justify-content-end mb-3">
            <Col md={2}>
              <Button
                className="w-100"
                color="success"
                onClick={() => onButtonClick('Resubmit')}
              >
                Update and Resubmit
              </Button>
            </Col>
          </Row>
        )}
      <Row>
        <Col lg="3">
          <CardItem
            title="Invoice Number"
            value={transfer?.invoice_number ?? transfer.transfer_number ?? '-'}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {transfer?.resourcetype === 'SaleTransfer' ? (
            <SaleTransfer transfer={transfer} />
          ) : null}
          {transfer?.resourcetype === 'WarehouseToWarehouseTransfer' ? (
            <WarehouseTransfer
              transfer={transfer}
              transferStatus={transferStatus}
              quantity={quantity}
              setQuantity={setQuantity}
              setIsInvalid={setIsInvalid}
              exciseReason={exciseReason}
              setExciseReason={setExciseReason}
            />
          ) : null}
          {transfer?.resourcetype !== 'SaleTransfer' &&
            transfer.resourcetype !== 'WarehouseToWarehouseTransfer' && (
              <OtherTransfers transfer={transfer} />
            )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

ShowTransfer.propTypes = {
  title: PropTypes.string,
};

export default ShowTransfer;
