import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Col, Row } from 'reactstrap';
import EmailTemplateForm from 'components/EmailTemplateForm';
import useAction from 'hooks/useAction';
import { actions } from 'models/emailTemplates/slice';
import {
  previewLayoutModalSelector,
  previewLayoutSelector,
  previewLayoutPendingSelector,
} from 'models/emailTemplates/selectors';
import useSelector from 'hooks/useSelector';
import EmailTemplatePopup from 'components/Popup/EmailTemplatePopup';

const CreateEmailTemplate = ({ title }) => {
  const createEmailTemplate = useAction(actions.createEmailTemplate);
  const emailTemplatePreview = useAction(actions.emailTemplatePreview);
  const deleteEmailTemplate = useAction(actions.deleteEmailTemplate);
  const setPreviewLayoutModal = useAction(actions.setPreviewLayoutModal);
  const previewLayout = useSelector(previewLayoutSelector);
  const previewLayoutModal = useSelector(previewLayoutModalSelector);
  const previewLayoutPending = useSelector(previewLayoutPendingSelector);

  const emailPopupCloseHandler = useCallback(() => {
    const { id, wineClubId } = previewLayout;
    if (id && wineClubId) {
      deleteEmailTemplate({
        wineClubId,
        templateId: id,
      });
    }
    setPreviewLayoutModal(false);
  }, [previewLayout]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create Email Template"
        breadcrumbItems={[
          {
            title: 'Back to email templates',
            link: `/email-templates/`,
          },
        ]}
      />

      {previewLayoutModal && (
        <EmailTemplatePopup
          onClose={emailPopupCloseHandler}
          layout={previewLayout?.data}
          loading={previewLayoutPending}
        />
      )}
      <Row>
        <Col md={12}>
          <EmailTemplateForm
            onSubmit={createEmailTemplate}
            onPreviewClick={emailTemplatePreview}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateEmailTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CreateEmailTemplate;
