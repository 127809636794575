import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Row, Col, FormGroup, Label, Button, Card, CardBody } from 'reactstrap';
import { COMPANY_TYPES, HUMAN_LEADS_STATUSES } from 'constants';
import LocationSearchInput from 'components/LocationSearchInput';
import TextField from 'components/Fields/TextField';
import PhoneField from 'components/Fields/PhoneField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import DateField from 'components/Fields/DateField';
import SalesRepPaginatedField from 'components/PaginatedFields/SalesRepPaginatedField';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import ContactPersonActionDropdown from '../ContactPersonActionDropdown';
import PopupBase from 'components/Popup/PopupBase';
import styles from './Main.scss';
import classNames from 'classnames';
import EmailField from 'components/Fields/EmailField';

const getAddressFieldError = (key, value) =>
  value ? `${key}: ${value[0]}` : undefined;

const CardItemWithButton = ({ title, values, children }) => (
  <Card>
    <CardBody>
      <Row>
        <Col className="text-dark">
          <h4 className="card-title">{title}</h4>
          {values.map((val, index) => (
            <div key={index}>{val}</div>
          ))}
        </Col>
        <Col className="d-flex justify-content-end">
          <div>{children}</div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

const Main = ({
  model,
  disabled,
  currentType,
  currentLeadStatus,
  setCurrentType,
  setCurrentLeadStatus,
  defaultWarehouse,
  handleDefaultWarehouseChange,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  submitTitle,
  handleEmailChange,
  fieldErrors,
  isSalesRep,
  defaultWarehouseDisabled,
  isLead,
  followUpDate,
  setFollowUpDate,
  salesRep,
  setSalesRep,
  setLicenseNumber,
  licenseNumber,
  isEditing,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhone,
  setContactFirstName,
  setContactLastName,
  setContactPhone,
  setContactEmail,
  isCreate,
  isReplaceContactPerson,
  setIsReplaceContactPerson,
  businessName,
  setBusinessName,
  contactPersonEditable,
  setContactPersonEditable,
}) => {
  // States
  const [isOpenEditEmailModal, setIsOpenEditEmailModal] = useState(false);
  const [newContactPersonEmail, setNewContactPersonEmail] = useState('');
  const [
    newContactPersonEmailConfirm,
    setNewContactPersonEmailConfirm,
  ] = useState('');
  const [hasEdits, setHasEdits] = useState(false);

  // Variables
  const hasAddressFieldError =
    getAddressFieldError('Country', fieldErrors?.country) ||
    getAddressFieldError('City', fieldErrors?.city) ||
    getAddressFieldError('State', fieldErrors?.state) ||
    getAddressFieldError('Line 1', fieldErrors?.line1) ||
    getAddressFieldError('Line 2', fieldErrors?.line2) ||
    getAddressFieldError('Postal code', fieldErrors?.postalCode);

  const isButtonDisabled = () => {
    // If there are no edits, disable the button
    if (!hasEdits) return true;
    const addressPresence = country && state && postalCode && city && line1;
    const isLeadValid = currentLeadStatus && currentType && addressPresence;
    const isContactPersonValid =
      contactFirstName && contactLastName && contactPhone && contactEmail;
    const isFormValid =
      currentType && defaultWarehouse && licenseNumber && addressPresence;

    // If replacing contact person, ensure all contact person fields are filled
    if (isReplaceContactPerson) return !isContactPersonValid;

    // If the entity is a lead, check if valid
    if (isLead) return !isLeadValid;

    // Default case: if the form is valid, enable the button
    return !isFormValid;
  };

  // Functions
  const getAvailableStatuses = () => {
    if (!isEditing) {
      const { CONNECTED, ...statuses } = HUMAN_LEADS_STATUSES;
      return statuses;
    }
    return HUMAN_LEADS_STATUSES;
  };

  const onReplaceContactPerson = () => {
    model.contact_person_first_name = '';
    model.contact_person_last_name = '';
    model.contact_person_email = '';
    model.contact_person_phone = '';
    setContactFirstName('');
    setContactLastName('');
    setContactPhone('');
    setContactEmail('');
    setIsReplaceContactPerson(true);
  };

  // Handlers
  const onEditContactPerson = () => {
    setContactPersonEditable(true);
  };

  const handleRemoveEditState = () => {
    setContactPersonEditable(false);
    setIsReplaceContactPerson(false);
    setContactFirstName(model?.contact_person?.first_name);
    setContactLastName(model?.contact_person?.last_name);
    setContactPhone(model?.contact_person?.phone);
    setContactEmail(model?.contact_person?.email);
  };

  // Effects
  useEffect(() => {
    // Track if any fields have been edited
    if (
      model?.contact_person_first_name !== contactFirstName ||
      model?.contact_person_last_name !== contactLastName ||
      model?.contact_person_phone !== contactPhone ||
      model?.contact_person_email !== contactEmail ||
      model?.company_type !== currentType?.value ||
      model?.default_warehouse?.id !== defaultWarehouse?.value ||
      model?.license_number !== licenseNumber ||
      model?.address?.line1 !== line1 ||
      model?.address?.line2 !== line2 ||
      model?.address?.city !== city ||
      model?.address?.state !== state ||
      model?.address?.postal_code !== postalCode ||
      model?.address?.country !== country ||
      model?.title !== businessName
    ) {
      setHasEdits(true);
    } else {
      setHasEdits(false);
    }
  }, [
    contactFirstName,
    contactLastName,
    contactPhone,
    contactEmail,
    currentType,
    defaultWarehouse,
    licenseNumber,
    line1,
    line2,
    city,
    state,
    postalCode,
    country,
    model,
    businessName,
  ]);

  return (
    <>
      <Row>
        <Col md="4">
          <TextField
            placeholder="Business Name"
            isRequired
            label="Business Name"
            name="title"
            disabled={disabled}
            onChange={event => setBusinessName(event.target.value)}
            errorMessage="Enter Business Name"
          />
        </Col>
        {isLead && (
          <Col md={4}>
            <FormGroup>
              <Label required htmlFor="lead_status">
                Lead Status
              </Label>
              <Select
                name="lead_status"
                options={Object.entries(getAvailableStatuses()).map(
                  ([key, label]) => ({
                    label,
                    value: key,
                  })
                )}
                isDisabled={disabled}
                placeholder="Select status"
                onChange={setCurrentLeadStatus}
                value={model || currentLeadStatus ? currentLeadStatus : ''}
              />
            </FormGroup>
          </Col>
        )}
        <Col md="4">
          <TextField
            placeholder="License Number"
            isRequired={!isLead}
            label="License Number"
            name="license_number"
            onChange={event => setLicenseNumber(event.target.value)}
          />
        </Col>
        {isLead && (
          <Col md="4">
            <Label htmlFor="sales_rep">Sales Rep</Label>
            <SalesRepPaginatedField
              onChange={({ value }) =>
                setSalesRep(prevState => ({
                  ...prevState,
                  value,
                }))
              }
              defaultValue={salesRep}
            />
          </Col>
        )}
        <Col md="4">
          <FormGroup>
            <Label required htmlFor="company_type">
              Company Type
            </Label>
            <Select
              name="company_type"
              options={COMPANY_TYPES.map(type => ({
                label: type,
                value: type,
              }))}
              placeholder="Select Type"
              onChange={setCurrentType}
              value={model || currentType ? currentType : ''}
              isRequired={!isLead}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label required={!isLead} htmlFor="default_warehouse">
              Default Warehouse
            </Label>
            <WarehousesPaginatedField
              value={defaultWarehouse}
              onChange={handleDefaultWarehouseChange}
              name="default_warehouse"
              placeholder="Select Warehouse"
              isMulti={false}
              isDisabled={!isLead && defaultWarehouseDisabled}
            />
          </FormGroup>
        </Col>
        {isLead && (
          <Col md="4">
            <DateField
              name="followUpDate"
              label="Follow-up Date"
              errorMessage="Enter Follow up Date"
              value={followUpDate}
              onChange={event => setFollowUpDate(event.target.value)}
            />
          </Col>
        )}
        <Col md="8">
          <LocationSearchInput
            model={model?.address || {}}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            error={hasAddressFieldError}
            isRequired
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="mb-3 d-flex justify-content-between align-items-center">
          <div className="font-size-18 font-weight-bold">Contact Person</div>
          {(contactPersonEditable || isReplaceContactPerson) && (
            <div className={styles.close} onClick={handleRemoveEditState}>
              Close
            </div>
          )}
        </Col>
      </Row>
      {!contactPersonEditable && !isCreate && !isReplaceContactPerson && (
        <Row>
          <Col md={6}>
            <CardItemWithButton
              title="Contact Person"
              values={[
                `${contactFirstName} ${contactLastName}`,
                contactEmail,
                formatPhoneNumber(contactPhone),
              ]}
            >
              <ContactPersonActionDropdown
                onReplaceContactPerson={onReplaceContactPerson}
                onEdit={onEditContactPerson}
              />
            </CardItemWithButton>
          </Col>
        </Row>
      )}
      <Row>
        {(contactPersonEditable || isReplaceContactPerson || isCreate) && (
          <>
            <Col md="3">
              <TextField
                name="contact_person_first_name"
                label="First Name"
                placeholder="First Name"
                errorMessage="Enter First Name"
                isRequired={isReplaceContactPerson}
                value={contactFirstName}
                onChange={({ target }) => setContactFirstName(target.value)}
              />
            </Col>
            <Col md="3">
              <TextField
                errorMessage="Enter Last Name"
                label="Last name"
                name="contact_person_last_name"
                placeholder="Last Name"
                value={contactLastName}
                isRequired={isReplaceContactPerson}
                onChange={({ target }) => setContactLastName(target.value)}
              />
            </Col>
            <Col md="3">
              <PhoneField
                errorMessage="Enter Phone"
                label="Phone"
                name="contact_person_phone"
                isRequired={isReplaceContactPerson}
                placeholder="Phone"
                value={contactPhone}
                onChange={setContactPhone}
              />
            </Col>
          </>
        )}

        {!contactPersonEditable && (isCreate || isReplaceContactPerson) && (
          <Col md="3">
            <EmailField
              name="contact_person_email"
              label="Email"
              placeholder="Email"
              errorMessage="Enter Email"
              isRequired={isSalesRep && isReplaceContactPerson}
              value={contactEmail}
              onChange={event =>
                setContactEmail(event.target.value.toLowerCase())
              }
            />
          </Col>
        )}
      </Row>
      {contactPersonEditable && !isCreate && (
        <Row>
          <Col md="6" className="mx-3">
            <Row>
              <Label>Email</Label>
            </Row>
            <Row>
              <Col className="pl-0 d-flex align-items-center">
                <span className="font-size-15">{contactEmail}</span>
              </Col>
              <Col className="pl-0">
                <Button
                  color="primary"
                  onClick={() => setIsOpenEditEmailModal(true)}
                >
                  Change Email
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Button
        disabled={isButtonDisabled()}
        color="primary"
        type="submit"
        className="mt-3"
      >
        {submitTitle}
      </Button>
      {isOpenEditEmailModal && (
        <PopupBase
          className={classNames('p-4', styles.popup)}
          onClose={() => setIsOpenEditEmailModal(false)}
        >
          <div>
            This action will update the current contact person’s email address.
            Please use this option only if the contact person’s email has
            changed. To update the contact person please use the{' '}
            <b>Replace Contact Person</b> option.
          </div>
          <div className="mt-3 mb-4">
            <input
              placeholder="New Email Address"
              onChange={event =>
                setNewContactPersonEmail(event.target.value.toLowerCase())
              }
              className="form-control my-2"
            />
            <input
              placeholder="Confirm Email Address"
              onChange={event =>
                setNewContactPersonEmailConfirm(
                  event.target.value.toLowerCase()
                )
              }
              className="form-control my-2"
            />
          </div>

          <Button
            color="primary"
            className="mx-auto mb-2"
            onClick={() => {
              handleEmailChange({
                email: newContactPersonEmail,
                confirm_email: newContactPersonEmailConfirm,
              });
            }}
          >
            Confirm
          </Button>
        </PopupBase>
      )}
    </>
  );
};

Main.propTypes = {
  model: PropTypes.object,
  disabled: PropTypes.bool,
  currentType: PropTypes.string,
  currentLeadStatus: PropTypes.string,
  setCurrentType: PropTypes.func,
  setCurrentLeadStatus: PropTypes.func,
  defaultWarehouse: PropTypes.object,
  handleDefaultWarehouseChange: PropTypes.func,
  defaultWarehouseDisabled: PropTypes.bool,
  state: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  submitTitle: PropTypes.string,
  handleEmailChange: PropTypes.func,
  fieldErrors: PropTypes.object,
  isSalesRep: PropTypes.bool,
  isLead: PropTypes.bool,
  followUpDate: PropTypes.string,
  setFollowUpDate: PropTypes.func,
  salesRep: PropTypes.string,
  setSalesRep: PropTypes.func,
  setLicenseNumber: PropTypes.func,
  licenseNumber: PropTypes.string,
  isEditing: PropTypes.bool,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
  setContactFirstName: PropTypes.func,
  setContactLastName: PropTypes.func,
  setContactPhone: PropTypes.func,
  setContactEmail: PropTypes.func,
  isCreate: PropTypes.bool,
  isReplaceContactPerson: PropTypes.bool,
  setIsReplaceContactPerson: PropTypes.func,
  businessName: PropTypes.string,
  setBusinessName: PropTypes.func,
  contactPersonEditable: PropTypes.bool,
  setContactPersonEditable: PropTypes.func,
};

CardItemWithButton.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default Main;
