import { createSelector } from 'reselect';

export const rootSelector = state => state.transfers;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const transfersSelector = createSelector(
  rootSelector,
  ({ transfers }) => transfers
);

export const inProgressTransfersSelector = createSelector(
  rootSelector,
  ({ inProgressTransfers }) => inProgressTransfers
);

export const inProgressTransfersPaginationSelector = createSelector(
  rootSelector,
  ({ inProgressTransfersPagination }) => inProgressTransfersPagination
);

export const inProgressTransfersNotificationSelector = createSelector(
  rootSelector,
  ({ inProgressTransfersNotification }) => inProgressTransfersNotification
);

export const inProgressTransfersNotificationPaginationSelector = createSelector(
  rootSelector,
  ({ inProgressTransfersNotificationPagination }) =>
    inProgressTransfersNotificationPagination
);

export const completedTransfersSelector = createSelector(
  rootSelector,
  ({ completedTransfers }) => completedTransfers
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const transferSelector = createSelector(
  rootSelector,
  ({ transfer }) => transfer
);

export const quantitiesSelector = createSelector(
  rootSelector,
  ({ quantities }) => quantities
);
