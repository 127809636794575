import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as exciseActions } from 'models/excise/slice';
import {
  exciseLicenseSelector,
  exciseReturnSummarySelector,
} from 'models/excise/selectors';

import Form from './Form';

const ExciseReturnCreate = ({ title }) => {
  const { excise_license_id } = useParams();
  const exciseReturnSummary = useSelector(exciseReturnSummarySelector);
  const exciseLicense = useSelector(exciseLicenseSelector);

  const fetchExciseLicense = useAction(exciseActions.fetchExciseLicense);
  const createExciseReturn = useAction(exciseActions.createExciseReturn);

  const onSubmit = values => {
    createExciseReturn({
      data: values,
    });
  };

  useEffect(() => {
    if (excise_license_id && exciseLicense?.id !== excise_license_id)
      fetchExciseLicense(excise_license_id);
  }, [fetchExciseLicense, excise_license_id]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`Create ${exciseLicense?.license_type || ''} excise return`}
        breadcrumbItems={[
          {
            title: 'Back to excise license',
            link: `/reports/excise/license/${excise_license_id}`,
          },
        ]}
      />
      <Row>
        <Col md="12">
          <Form
            key={
              exciseReturnSummary?.period_start &&
              exciseReturnSummary?.period_end
                ? `form-with-summary-${exciseReturnSummary.period_start}-${exciseReturnSummary.period_end}}`
                : 'form-without-summary'
            }
            exciseLicense={exciseLicense}
            onSubmit={onSubmit}
            submitTitle="Save"
            model={
              exciseReturnSummary?.period_start
                ? exciseReturnSummary
                : undefined
            }
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

ExciseReturnCreate.propTypes = {
  title: PropTypes.string,
};

export default ExciseReturnCreate;
