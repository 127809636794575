import React, { memo } from 'react';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FieldType } from '../types.d';

const CheckboxField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  errorMessage,
  className,
  ...props
}) => {
  return (
    <FormGroup className={className}>
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <AvField
        tag={CustomInput}
        name={name}
        type="checkbox"
        validate={{
          required: { value: !!isRequired },
          ...rules,
        }}
        error
        errorMessage={errorMessage}
        onChange={onChange}
        {...props}
      />
    </FormGroup>
  );
};

CheckboxField.propTypes = FieldType;

export default memo(CheckboxField);
