import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const PaymentAlert = ({ to }) => {
  return (
    <Alert color="warning">
      <p>
        <i className="mdi mdi-information font-size-14 mr-2" />
        This Customer does not have any payment methods available.
      </p>
      <p>
        <Link to={to}>Click here</Link> to update this Customers details.
      </p>
    </Alert>
  );
};

PaymentAlert.propTypes = {
  to: PropTypes.string.isRequired,
};
export default PaymentAlert;
