import { createSelector } from 'reselect';

export const rootSelector = state => state.awards;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const awardsSelector = createSelector(
  rootSelector,
  ({ awards }) => awards
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const awardSelector = createSelector(rootSelector, ({ award }) => award);
