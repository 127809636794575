import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';

import * as queryString from 'query-string';

const initialLabel = {
  label: 'All tiers',
  value: null,
};

const getTierLabel = (title, wineClubTitle, showWineClubTitle) => {
  const wineClubTitleText = wineClubTitle ? ` (${wineClubTitle})` : '';
  return `${title}${showWineClubTitle ? wineClubTitleText : ''}`;
};

const TiersPaginatedField = ({
  onChange,
  isMulti,
  defaultValue,
  isInitialLabel,
  showWineclubTitle,
  brandId,
  styles,
  reqOptions,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchBrands = ({ page, brand }) => {
    const stringified = queryString.stringify(
      {
        page,
        brand_id: brand,
        ...reqOptions,
      },
      {
        arrayFormat: 'comma',
      }
    );
    return apiRequest({
      url: `/tiers/?${stringified}`,
    });
  };

  const loadBrands = async (search, loadedOptions, { page, brand }) => {
    try {
      const response = await fetchBrands({ page, brand });
      const options = response?.results.map(item => ({
        value: item.id,
        label: getTierLabel(
          item.title,
          item?.wineclub_title,
          showWineclubTitle
        ),
        warehouses: item.warehouses,
      }));
      return {
        options:
          isInitialLabel && page === 1 ? [initialLabel, ...options] : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1, brand: brandId },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page, brand: brandId },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="tiers"
      isSearchable={false}
      cacheUniqs={[brandId]}
      additional={{
        page: 1,
        brand: brandId,
      }}
      loadOptions={loadBrands}
      onChange={onChange}
      isMulti={isMulti}
      styles={{ ...selectStyles, ...styles }}
    />
  );
};

TiersPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool,
  brandId: PropTypes.number,
  showWineclubTitle: PropTypes.bool,
  styles: PropTypes.bool,
  reqOptions: PropTypes.object,
};

TiersPaginatedField.defaultProps = {
  defaultValue: [],
  isMulti: true,
};

export default TiersPaginatedField;
