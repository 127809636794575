import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button } from 'reactstrap';

import getAmountOfProductsInOrder from 'utils/getAmountOfProductsInOrder';

import TableRow from './TableRow';
import s from './OrderItemsTable.scss';
import formatPrice from '../../../utils/formatPrice';
import useSelector from '../../../hooks/useSelector';
import { productCountsSelector } from '../../../models/products/selectors';

const OrderItemsTable = ({
  orderItems,
  productsInOrder,
  className,
  disabledAddNewRowButton,
  tierCapacity,
  warehouseId,
  setProductsInOrder,
  deleteProductsInOrder,
  withCapacity,
}) => {
  // TODO: refactor

  const [orders, setOrders] = useState(orderItems);
  const productCounts = useSelector(productCountsSelector);

  useEffect(() => {
    if (Object.keys(productsInOrder).length === 0) {
      orders?.forEach(item => {
        setProductsInOrder({
          id: item.id,
          product_id: item.product?.id,
          number: item.number,
          price: item.product_price,
          isNewOrderItem: item.isNewOrderItem,
        });
      });
    }
  }, [orders, setProductsInOrder, productsInOrder]);

  const addRow = () => {
    const newID = Math.floor(Math.random() * 100);
    const newOrderItem = {
      id: newID,
      product: { id: 0 },
      number: 1,
      product_price: 0,
      product_price_currency: 'CAD',
      isNewOrderItem: true,
    };
    setProductsInOrder({
      id: newID,
      product_id: 0,
      number: 1,
      price: 0,
      isNewOrderItem: true,
    });
    setOrders(orders.concat(newOrderItem));
  };

  const deleteRow = id => {
    const newOrders = orders.filter(item => item.id !== id);
    deleteProductsInOrder(id);

    setOrders(newOrders);
  };

  return (
    <Card className={className}>
      <CardBody className={s.table}>
        <Table bordered responsive>
          <thead>
            <tr>
              <th data-priority="1">Product</th>
              {withCapacity ? (
                <th data-priority="1">
                  The order should include at least {tierCapacity} products (
                  <div
                    className={`badge ml-1 font-size-12 ${
                      getAmountOfProductsInOrder(productsInOrder) < tierCapacity
                        ? 'badge-danger'
                        : 'badge-success'
                    }`}
                  >
                    {getAmountOfProductsInOrder(productsInOrder)}/{tierCapacity}
                  </div>
                  ).
                </th>
              ) : (
                <th data-priority="1">
                  Quantity
                  <div className="badge ml-1 font-size-12 badge-success">
                    {getAmountOfProductsInOrder(productsInOrder)}
                  </div>
                </th>
              )}
              <th>Stock</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map(item => (
              <TableRow
                key={item.id}
                count={productCounts[[productsInOrder[item?.id]?.product_id]]}
                orderItem={item}
                warehouseId={warehouseId}
                deleteRow={deleteRow}
                productsInOrder={productsInOrder}
                setProductsInOrder={setProductsInOrder}
                productsOrderIds={Object.values(productsInOrder).map(
                  data => data?.product_id
                )}
              />
            ))}

            <tr>
              <td colSpan={4} />
              <td>
                {formatPrice(
                  Object.values(productsInOrder).reduce(
                    (acc, { price, number }) => acc + (price || 0) * number,
                    0
                  )
                )}{' '}
                {orders?.[0]?.product_price_currency}
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <Button
                  color="success"
                  type="button"
                  disabled={disabledAddNewRowButton}
                  className="waves-effect waves-light mr-1 text-nowrap"
                  onClick={addRow}
                >
                  <i className="ri-add-line align-middle mr-2" /> Add new
                  product
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

OrderItemsTable.propTypes = {
  tierCapacity: PropTypes.number,
  warehouseId: PropTypes.number,
  disabledAddNewRowButton: PropTypes.bool,
  className: PropTypes.string,
  orderItems: PropTypes.array,
  productsInOrder: PropTypes.object,
  setProductsInOrder: PropTypes.func,
  deleteProductsInOrder: PropTypes.func,
  withCapacity: PropTypes.bool,
};

OrderItemsTable.defaultProps = {
  withCapacity: true,
};

export default OrderItemsTable;
