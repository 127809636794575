import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import * as inventoriesSelector from 'models/inventories/selectors';
import { actions as inventoriesActions } from 'models/inventories/slice';

import InventoriesTable from './InventoriesTable';
import WarehousesPaginatedField from '../../PaginatedFields/WarehousesPaginatedField';
import styles from './WriteOffFields.scss';
import classNames from 'classnames';

const WriteOffFields = ({
  isPending,
  senderID,
  setSenderID,
  resetQuantities,
  setWarehouse,
}) => {
  const [warehouseTouched, setWarehouseTouched] = useState(false);
  const fetchInventories = useAction(inventoriesActions.fetchInventories);
  const inventories = useSelector(
    inventoriesSelector.inventoriesForTransferSelector
  );
  useEffect(() => {
    if (senderID) {
      fetchInventories({
        left_joined_warehouse_ids: `${senderID}`,
        sort_field: 'title',
        sort_direction: 'asc',
        disable_pagination: true,
      });
    }
  }, [fetchInventories, senderID]);

  const handleChangeWarehouse = wh => {
    setSenderID(wh.value || '');
    resetQuantities();
    setWarehouse(wh);
  };

  return (
    <Row className={classNames(isPending && styles.preloader)}>
      <Col lg="4">
        <FormGroup>
          <Label required htmlFor="sender_id">
            Sending Warehouse
          </Label>
          <WarehousesPaginatedField
            onBlur={() => setWarehouseTouched(true)}
            onChange={handleChangeWarehouse}
            menuPlacement="top"
            classNames={classNames(
              warehouseTouched && !senderID && styles.error
            )}
            error={warehouseTouched && !senderID && 'Sending Warehouse'}
          />
        </FormGroup>
      </Col>

      <Col lg="12">
        {inventories.length !== 0 && (
          <InventoriesTable
            className={classNames(isPending && 'preloader')}
            senderID={senderID}
            inventories={inventories}
          />
        )}
      </Col>
    </Row>
  );
};

WriteOffFields.propTypes = {
  isPending: PropTypes.bool,
  senderID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSenderID: PropTypes.func,
  resetQuantities: PropTypes.func,
  setWarehouse: PropTypes.func,
};

export default WriteOffFields;
