import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';
import Form from 'components/DeviceForm';

import useAction from 'hooks/useAction';
import {
  deviceSelector,
  isPendingSelector as deviceIsPendingSelector,
} from 'models/devices/selectors';
import { actions as devicesActions } from 'models/devices/slice';
import { isEmpty } from 'lodash';
import { getRedactedValues } from 'utils/getRedactedValues';

const EditDevice = ({ title }) => {
  const { deviceID } = useParams();
  const device = useSelector(deviceSelector);
  const deviceIsPending = useSelector(deviceIsPendingSelector);
  const updateDevice = useAction(devicesActions.updateDevice);
  const fetchDevice = useAction(devicesActions.fetchDevice);

  useEffect(() => {
    fetchDevice(deviceID);
  }, [fetchDevice, deviceID]);

  const onSubmit = values => {
    updateDevice({ id: device?.id, ...getRedactedValues(device, values) });
  };

  if (deviceIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={device?.identifier}
        breadcrumbItems={[
          { title: 'Back to devices', link: '/devices/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {!isEmpty(device) && (
        <Row>
          <Col md="12">
            <Form
              submitTitle="Save"
              onSubmit={onSubmit}
              model={device}
              disabled={deviceIsPending}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditDevice.propTypes = {
  title: PropTypes.string,
};

export default EditDevice;
