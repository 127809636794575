import React, { memo, useCallback, useEffect, useState } from 'react';
import styles from './TagsWrapper.scss';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import Chip from '../Chip/Chip';

const TagsWrapper = ({ label, data, onChange, disabled, creatable }) => {
  const [tags, setTags] = useState({});
  const [tagValue, setTagValue] = useState('');

  useEffect(() => {
    setTags(data);
  }, [data]);

  const onTagClick = useCallback(
    key => {
      const currentTags = {
        ...tags,
        [key]: !tags[key],
      };
      setTags(currentTags);
      onChange(currentTags);
    },
    [tags]
  );

  const onAddTag = useCallback(() => {
    const preparedValue = tagValue.trim();
    if (!preparedValue) return;
    const currentTags = {
      ...tags,
      [preparedValue]: true,
    };
    setTags(tags);
    setTagValue('');
    onChange(currentTags);
  }, [tagValue, tags]);

  return (
    <Row>
      <Col md={4}>
        <AvForm>
          <FormGroup className="mb-0">
            <Label>{label}</Label>
            {creatable && (
              <div className="d-flex">
                <Input
                  value={tagValue}
                  onChange={event => setTagValue(event.target.value)}
                  placeholder="Tag name"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      onAddTag();
                      e.preventDefault();
                    }
                  }}
                  disabled={disabled}
                />
                <Button
                  className="ml-2"
                  color="primary"
                  disabled={disabled}
                  onClick={onAddTag}
                >
                  Add
                </Button>
              </div>
            )}
          </FormGroup>
        </AvForm>
      </Col>
      <Col md={12}>
        <div className={styles.tagsWrapper}>
          {Object.entries(tags).map(([key, value]) => (
            <Chip
              key={key}
              value={key}
              active={value}
              className={styles.tag}
              onClick={() => onTagClick(key)}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

TagsWrapper.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool,
};
export default memo(TagsWrapper);
