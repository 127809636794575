import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'pages/ExciseLicense/DataTable/DataTable';
import PaginationTable from 'components/PaginationTable';
import { useSelector } from 'react-redux';
import {
  isPendingSelector,
  paginationSelector,
  exciseReturnsSelector,
} from 'models/excise/selectors';
import useAction from 'hooks/useAction';
import { actions as exciseActions } from 'models/excise/slice';
import { EXCISE_USER_TABLE_COLUMNS } from 'pages/ExciseReturn/Form/User/constants';

const ExciseReturnsUserSummary = ({ exciseLicense }) => {
  const isPending = useSelector(isPendingSelector);
  const pagination = useSelector(paginationSelector);
  const exciseReturns = useSelector(exciseReturnsSelector);

  const fetchExciseReturns = useAction(exciseActions.fetchExciseReturns);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page);

  useEffect(() => {
    fetchExciseReturns({
      excise_license_id: exciseLicense?.id,
      license_type: exciseLicense?.license_type,
      page: currentPage,
    });
  }, [fetchExciseReturns, currentPage]);

  return (
    <PaginationTable setCurrentPage={setCurrentPage} pagination={pagination}>
      <DataTable
        isPending={isPending}
        exciseReturns={exciseReturns}
        exciseLicense={exciseLicense}
        tableColumns={EXCISE_USER_TABLE_COLUMNS}
      />
    </PaginationTable>
  );
};

ExciseReturnsUserSummary.propTypes = {
  exciseLicense: PropTypes.object.isRequired,
};

export default ExciseReturnsUserSummary;
