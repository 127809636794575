import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { showErrorMessage } from 'utils/notification';
import useApiRequest from 'hooks/useApiRequest';
import useAction from 'hooks/useAction';

import Inputs from './Inputs';
import TableOfTransfers from './TableOfTransfers';
import styles from '../TransferForm.scss';

import { getProductTitleWithSKUVintage } from 'utils/preparedProductTitle';
import { actions as warehousesActions } from 'models/warehouses/slice';

const ArrivalFields = ({
  isPending,
  setReceiverID,
  quantity,
  limitValue,
  setQuantity,
  setLimitValue,
  productId,
  productTitle,
  productType,
  setProductId,
  transferItems,
  setTransferItems,
  setProductTitle,
  setProductType,
  productIDParam,
  warehouseIDParam,
  warehouse,
  setWarehouse,
  currentWarehouse,
}) => {
  const request = useApiRequest();
  const fetchWarehouse = useAction(warehousesActions.fetchWarehouse);

  useEffect(() => {
    if (warehouseIDParam) {
      fetchWarehouse(warehouseIDParam);
    }
  }, []);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [
    inventoryNotificationLimits,
    setInventoryNotificationLimits,
  ] = useState(null);

  const showInventoryNotificationLimits = Boolean(
    warehouse &&
      selectedProduct &&
      inventoryNotificationLimits &&
      !inventoryNotificationLimits?.some(
        limit => limit.warehouse_id === warehouse?.id
      )
  );

  const fetchProducts = ({ brand_id, title_contains, product_types, page }) => {
    const brandParameter = brand_id ? `brand_id=${brand_id}` : '';
    const titleContainsParameter = title_contains
      ? `&title_contains=${title_contains}`
      : '';
    const productTypesParameter = product_types
      ? `&product_types=${product_types}`
      : '';
    const pageParameter = page ? `&page=${page}` : '';
    const url = `/products/?${brandParameter}${titleContainsParameter}${productTypesParameter}${pageParameter}`;
    return request({ url });
  };

  const fetchInitialProduct = id => {
    try {
      const productIdParameter = id ? `${id}` : '';
      const url = `/products/${productId || productIdParameter}`;
      request({ url }).then(initialProduct => {
        setSelectedProduct(initialProduct);
        setProductTitle(initialProduct.title);
        setProductId(initialProduct.id);
        setInventoryNotificationLimits(
          initialProduct.inventory_notification_limits
        );
        setProductType(initialProduct.resourcetype);
      });
    } catch (error) {
      showErrorMessage('Error!', 'Unable to retrieve product with this ID');
      throw error;
    }
  };

  useEffect(() => {
    fetchInitialProduct(productIDParam || productId);
  }, []);

  const filterProducts = (items, inputValue) => {
    return items
      ?.filter(product =>
        product.title.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map(product => ({
        ...product,
        title: getProductTitleWithSKUVintage(
          product.title,
          product?.sku,
          product?.vintage
        ),
      }));
  };

  const loadOptions = async (search, _, { page }) => {
    const { results, pagination } = await fetchProducts({
      warehouse_ids: warehouseIDParam || warehouse?.brand_id,
      title_contains: search,
      product_types: ['Wine', 'Merchandise', 'Food'],
      page,
    });

    return {
      options: filterProducts(results || [], search),
      hasMore: pagination?.next_page,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleAddInventory = () => {
    const inventoryNotificationLimit = showInventoryNotificationLimits
      ? limitValue || 500
      : '';

    setTransferItems([
      {
        id: productIDParam || productId,
        productTitle,
        product_id: productIDParam || productId,
        quantity,
        limit_value: inventoryNotificationLimit,
        product_type: productType,
      },
    ]);
  };

  useEffect(() => {
    if (productIDParam) handleAddInventory();
  }, [quantity, limitValue]);

  const handleSaveTransferItems = () => {
    if (productId === '' || quantity === '') {
      showErrorMessage('Error!', 'Choose product, and quantity first');
      return;
    }
    const inventoryNotificationLimit = showInventoryNotificationLimits
      ? limitValue || 500
      : '';

    if (transferItems.length === 0) {
      setTransferItems([
        {
          id: productIDParam || productId,
          productTitle,
          product_id: productIDParam || productId,
          quantity,
          limit_value: inventoryNotificationLimit,
          product_type: productType,
        },
      ]);
      resetFields();
      return;
    }

    if (
      transferItems.some(e => e.id === productId || e.id === productIDParam)
    ) {
      const newTransferItems = transferItems.map(item => {
        if (item.id === productId || item.id === productIDParam)
          return {
            ...item,
            quantity,
            limit_value: inventoryNotificationLimit,
          };
        return item;
      });
      setTransferItems(newTransferItems);
    } else {
      setTransferItems(prev => [
        ...prev,
        {
          id: productIDParam || productId,
          productTitle,
          product_id: productIDParam || productId,
          quantity,
          limit_value: inventoryNotificationLimit,
          product_type: productType,
        },
      ]);
    }
    resetFields();
  };

  const resetFields = () => {
    setSelectedProduct(null);
    setProductId('');
    setQuantity('');
    setLimitValue('');
    setProductType('');
  };

  const handleProductInputChange = selectedOption => {
    setSelectedProduct(selectedOption);
    setProductId(selectedOption.id);
    setProductTitle(selectedOption.title);
    setInventoryNotificationLimits(
      selectedOption.inventory_notification_limits
    );
    setProductType(selectedOption.resourcetype);
  };

  const handleQuantityInputChange = e => {
    setQuantity(Number(e.target.value));
  };

  const handleLimitValueInputChange = e => {
    setLimitValue(e.target.value);
  };

  const handleChangeWarehouse = wh => {
    setWarehouse(wh);
    setReceiverID(wh.value);
  };

  return (
    <div className={cx('w-100', isPending && styles.preloader)}>
      <Row>
        <Col lg="7" className={styles.warehouseInfoHeadingContainer}>
          {(warehouse?.title ||
            (warehouseIDParam && currentWarehouse?.title)) && (
            <h3 className={styles.warehouseInfoHeading}>
              Inventory To Add at {warehouse?.title || currentWarehouse?.title}:
            </h3>
          )}
        </Col>
        <Inputs
          quantity={quantity}
          limitValue={limitValue}
          productId={productId}
          selectedProduct={selectedProduct}
          brandId={warehouse?.brand_id}
          warehouseTitle={warehouse?.title}
          loadOptions={loadOptions}
          handleProductInputChange={handleProductInputChange}
          handleQuantityInputChange={handleQuantityInputChange}
          handleLimitValueInputChange={handleLimitValueInputChange}
          handleSaveTransferItems={handleSaveTransferItems}
          handleChangeWarehouse={handleChangeWarehouse}
          showInventoryNotificationLimits={showInventoryNotificationLimits}
          productIDParam={productIDParam}
        />
        {!!productIDParam ||
          (transferItems.length !== 0 && (
            <Col lg="8">
              <TableOfTransfers
                transferItems={transferItems}
                setTransferItems={setTransferItems}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

ArrivalFields.propTypes = {
  isPending: PropTypes.bool,
  setReceiverID: PropTypes.func,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limitValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setQuantity: PropTypes.func,
  setLimitValue: PropTypes.func,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productTitle: PropTypes.string,
  productType: PropTypes.string,
  setProductId: PropTypes.func,
  transferItems: PropTypes.array,
  setTransferItems: PropTypes.func,
  setProductTitle: PropTypes.func,
  setProductType: PropTypes.func,
  productIDParam: PropTypes.string,
  warehouseIDParam: PropTypes.string,
  warehouse: PropTypes.object,
  setWarehouse: PropTypes.func,
  currentWarehouse: PropTypes.object,
};

export default ArrivalFields;
