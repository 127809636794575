import React, { useState, useEffect, useCallback } from 'react';
import styles from './InventoryTransferTable.scss';
import { Button, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TRANSFER_TABLE_MODES } from './constants';
import { normalizeTableData, reorganizeTableData } from './utils';
import TableCell from './TableCell';
import { Link } from 'react-router-dom';
import { getProductTitleWithVintage } from 'utils/preparedProductTitle';
import { showErrorMessage } from 'utils/notification';

// TODO: Refactor the fuck out of this mess

const InventoryTransferTable = ({
  data,
  editorMode,
  isViewing,
  isEditing,
  setEditorMode,
  onSubmit,
  isManual,
  onChangeData,
  disableClearManualData = false,
  inventoryCountType,
  tasterCount,
}) => {
  const tasters =
    inventoryCountType === 'Taster'
      ? Object.fromEntries(
          data.map(product => [product.product_id, product.product_count])
        )
      : Object.fromEntries(
          data.map(product => [
            product.product_id,
            product.real_taster_count ||
              tasterCount.inventory_results.find(
                taster => taster.product_id === product.product_id
              )?.product_count,
          ])
        );

  const getNormalizedData = () => {
    if (inventoryCountType === 'Taster') {
      const tasterData = Object.values(data).reduce((prev, cur) => {
        if (cur) {
          prev[cur.product_id] = {
            product_count: cur.product_count,
            product_id: cur.product_id,
            product_sku: cur.product_sku,
            product_title: cur.product_title,
          };
        }
        return prev;
      }, {});
      return tasterData;
    }
    if (typeof Object.values(data)[0]?.real_product_id === 'number') {
      const realData = Object.values(data).reduce((prev, cur) => {
        if (cur) {
          prev[cur.product_id] = {
            bottles_count: cur.real_bottles_count,
            bottles_in_case: cur.real_bottles_in_case,
            cases_count: cur.real_cases_count,
            cases_in_pallet: cur.real_cases_in_pallet,
            pallets_count: cur.real_pallets_count,
            product_count: cur.real_product_count,
            product_id: cur.real_product_id,
            product_sku: cur.real_product_sku,
            product_title: cur.real_product_title,
            product_type: cur.real_product_type,
            product_vintage: cur.real_product_vintage,
            taster_writeoff: cur.real_taster_writeoff,
          };
        }
        return prev;
      }, {});
      return realData;
    }
    return normalizeTableData(data);
  };
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem('inventory_reports')) || getNormalizedData()
  );

  const getIsMerchandise = item => {
    return item.product_type === 'Merchandise';
  };

  const getIsPreviousCountVersion = value => typeof value !== 'number';

  useEffect(() => {
    clearTableData();
  }, []);

  useEffect(() => {
    if (onChangeData) {
      onChangeData(tableState);
    }
  }, [tableState]);

  const handleInputChange = id => e => {
    const inputValue = e.target.value;
    const fieldName = e.target.name;
    const isMerchandise = getIsMerchandise(tableState[id]);
    const isTaster = inventoryCountType === 'Taster';

    let productCount;
    let preparedValue;

    if (!inputValue) {
      preparedValue = 0;
      productCount = getTotalValue({
        ...tableState[id],
        [fieldName]: 0,
      });
    } else if (inputValue.match(/^((0[1-9]))/)) {
      preparedValue = Number(inputValue.replace(/^0+/, '')) || 0;
      productCount =
        isMerchandise || isTaster
          ? preparedValue
          : getTotalValue({
              ...tableState[id],
              [fieldName]: preparedValue,
            });
    } else if (inputValue.match(/^((0|([1-9][0-9]*)))$/)) {
      preparedValue = Number(inputValue);
      productCount =
        isMerchandise || isTaster
          ? Number(inputValue)
          : getTotalValue({
              ...tableState[id],
              [fieldName]: Number(inputValue),
            });
    } else {
      return;
    }

    setTableState({
      ...tableState,
      [id]: {
        ...tableState[id],
        [fieldName]: preparedValue,
        product_count: productCount,
      },
    });
  };

  const clearTableData = () => {
    localStorage.removeItem('inventory_reports');
    const normalizedTableData = getNormalizedData();
    localStorage.setItem(
      'inventory_reports',
      JSON.stringify(normalizedTableData)
    );
    setTableState(normalizedTableData);
  };

  const handleProcessCount = () => {
    const result = tableState;
    data.forEach(product => {
      const id = product.product_id;
      if (product.product_type !== 'Merchandise') {
        result[id].taster_count = tasters[id];
        const totalValue = getTotalValue(result[id]);
        if (product.product_count === totalValue) {
          result[id].product_count = totalValue;
          if (result[id].bottles_count === '') {
            result[id].bottles_count = 0;
          }
          if (result[id].cases_count === '') {
            result[id].cases_count = 0;
          }
          if (result[id].pallets_count === '') {
            result[id].pallets_count = 0;
          }
        }
      }
    });
    localStorage.setItem('inventory_reports', JSON.stringify(result));
    setTableState(result);
    setEditorMode(TRANSFER_TABLE_MODES.PROCESS);
    return result;
  };

  const getTotalValue = item => {
    return (
      (tasters[item.product_id] || 0) +
      (item.bottles_count || 0) +
      (item.cases_count || 0) * item.bottles_in_case +
      (item.pallets_count || 0) * item.bottles_in_case * item.cases_in_pallet
    );
  };

  const getInputsTotalValueWithSign = useCallback(item => {
    const inputsTotal =
      (tableState[item.product_id]?.real_product_count ??
        tableState[item.product_id]?.product_count) - item.product_count;
    if (inputsTotal > 0) {
      return `+${inputsTotal}`;
    }
    return inputsTotal;
  });

  const getColor = useCallback((a, b) => {
    return a === b ? 'green' : 'red';
  });

  const getRowColor = item => {
    const isMerchandise = getIsMerchandise(item);
    const isWithoutBottles = getIsPreviousCountVersion(
      tableState[item.product_id]?.bottles_count
    );
    const isWithoutCases = getIsPreviousCountVersion(
      tableState[item.product_id]?.cases_count
    );
    const isWithoutPallets = getIsPreviousCountVersion(
      tableState[item.product_id]?.pallets_count
    );
    const isWithoutProducts = getIsPreviousCountVersion(
      tableState[item.product_id]?.product_count
    );

    if (
      isMerchandise &&
      !isWithoutProducts &&
      getInputsTotalValueWithSign(item) !== 0
    ) {
      return styles.danger;
    }

    if (
      !isMerchandise &&
      !isWithoutBottles &&
      !isWithoutCases &&
      !isWithoutPallets &&
      getInputsTotalValueWithSign(item) !== 0
    ) {
      return styles.danger;
    }

    return '';
  };

  const onSubmitHandler = useCallback(() => {
    const result = handleProcessCount();
    const hasEmptyProductCount = Object.values(result).some(
      product => product.product_count === ''
    );
    if (hasEmptyProductCount || editorMode !== TRANSFER_TABLE_MODES.PROCESS) {
      showErrorMessage(
        'Count not completed.',
        'Count must be complete before submitting.'
      );
      return;
    }

    if (!hasEmptyProductCount) {
      onSubmit(result);
    }
  }, [tableState]);

  useEffect(() => {
    setTableState(state => reorganizeTableData({ ...state }, data));
  }, [data, tasterCount]);

  const Subhead = () => (
    <div className={styles.subhead}>
      <div className={styles.subheadCol}>INV</div>
      <div className={styles.subheadCol}>REAL</div>
      <div className={styles.subheadCol}>DIFF</div>
    </div>
  );

  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <div className={styles.actionWrapper}>
            <div className={styles.left}>
              {editorMode !== TRANSFER_TABLE_MODES.DEFAULT &&
                !disableClearManualData && (
                  <Button color="danger" onClick={() => clearTableData()}>
                    Clear Counts
                  </Button>
                )}
            </div>
            <div className={styles.buttonsContainer}>
              {isManual && editorMode === TRANSFER_TABLE_MODES.DEFAULT && (
                <Link to="/inventory-count/create">
                  <Button
                    color="primary"
                    className="mr-3"
                    onClick={() => setEditorMode(TRANSFER_TABLE_MODES.MANUAL)}
                  >
                    Manual Count
                  </Button>
                </Link>
              )}
              {onSubmit && (
                <Button
                  color="success"
                  className="ml-3"
                  onClick={onSubmitHandler}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table className={styles.table} bordered responsive>
            <thead>
              <th
                className={classNames(
                  styles.head,
                  ['Wine', 'WineMerch'].includes(inventoryCountType)
                    ? styles.titleCell40
                    : styles.titleCell60
                )}
              >
                Product Title
              </th>
              <th
                className={classNames(
                  styles.head,
                  ['Wine', 'WineMerch'].includes(inventoryCountType)
                    ? styles.skuCell20
                    : styles.skuCell30
                )}
              >
                SKU
              </th>
              {['Wine', 'WineMerch', 'Taster'].includes(inventoryCountType) && (
                <th className={classNames(styles.head, styles.headWithSubhead)}>
                  Taster Bottles
                </th>
              )}
              {['Wine', 'WineMerch'].includes(inventoryCountType) && (
                <>
                  <th
                    className={classNames(styles.head, styles.headWithSubhead)}
                  >
                    Bottle Count
                    {(isEditing || isViewing) && <Subhead />}
                  </th>
                  <th
                    className={classNames(styles.head, styles.headWithSubhead)}
                  >
                    Case Count
                    {(isEditing || isViewing) && <Subhead />}
                  </th>
                  <th
                    className={classNames(styles.head, styles.headWithSubhead)}
                  >
                    Pallet Count
                    {(isEditing || isViewing) && <Subhead />}
                  </th>
                </>
              )}
              {!['Taster'].includes(inventoryCountType) && (
                <th className={classNames(styles.head, styles.headWithSubhead)}>
                  Product Sum
                  {(isEditing || isViewing) && <Subhead />}
                </th>
              )}
            </thead>
            <tbody>
              {data?.map(item => {
                const isMerchandise = getIsMerchandise(item);
                const isWithoutBottles = getIsPreviousCountVersion(
                  tableState[item.product_id]?.bottles_count
                );
                const isWithoutCases = getIsPreviousCountVersion(
                  tableState[item.product_id]?.cases_count
                );
                const isWithoutPallets = getIsPreviousCountVersion(
                  tableState[item.product_id]?.pallets_count
                );
                const realProductCount =
                  tableState[item.product_id]?.real_product_count ??
                  tableState[item.product_id]?.product_count;
                return (
                  tableState[item.product_id] && (
                    <tr key={item.product_id} className={getRowColor(item)}>
                      {/* Product title */}
                      <td>
                        <div>
                          {getProductTitleWithVintage(
                            item.product_title,
                            item?.product_vintage
                          )}
                        </div>
                      </td>
                      {/* SKU */}
                      <td>{item?.product_sku || item?.sku || '-'}</td>
                      {/* Taster Bottles */}
                      {(['Wine', 'WineMerch'].includes(inventoryCountType) ||
                        (inventoryCountType === 'Taster' && !isEditing)) && (
                        <td>
                          <div
                            className={styles.cell}
                            style={{
                              color: tasters[item.product_id] ? 'red' : '',
                            }}
                          >
                            {tasters[item.product_id] && '-'}
                            {tasters[item.product_id] || '0'}
                          </div>
                        </td>
                      )}
                      {inventoryCountType === 'Taster' && isEditing && (
                        <TableCell
                          item={item}
                          isViewing={isViewing}
                          isEditing={isEditing}
                          editorMode={editorMode}
                          handleInputChange={handleInputChange}
                          getColor={getColor}
                          tableState={tableState}
                          field="product_count"
                          className={styles.inputCellBorder}
                        />
                      )}
                      {/* Bottle Count */}
                      {['Wine', 'WineMerch'].includes(inventoryCountType) && (
                        <>
                          <td
                            className={classNames(styles.withoutPadding, {
                              [styles.emptyCell]:
                                isMerchandise ||
                                (isWithoutBottles && isViewing),
                            })}
                          >
                            {!isMerchandise &&
                              !(isWithoutBottles && isViewing) && (
                                <TableCell
                                  item={item}
                                  isViewing={isViewing}
                                  isEditing={isEditing}
                                  editorMode={editorMode}
                                  handleInputChange={handleInputChange}
                                  getColor={getColor}
                                  tableState={tableState}
                                  field="bottles_count"
                                  tasterBottles={tasters[item.product_id] || 0}
                                />
                              )}
                          </td>
                          {/* Case Count */}
                          <td
                            className={classNames(styles.withoutPadding, {
                              [styles.emptyCell]:
                                isMerchandise || (isWithoutCases && isViewing),
                            })}
                          >
                            {!isMerchandise &&
                              !(isWithoutCases && isViewing) && (
                                <TableCell
                                  item={item}
                                  isViewing={isViewing}
                                  isEditing={isEditing}
                                  editorMode={editorMode}
                                  handleInputChange={handleInputChange}
                                  getColor={getColor}
                                  tableState={tableState}
                                  field="cases_count"
                                />
                              )}
                          </td>
                          {/* Pallet Count */}
                          <td
                            className={classNames(styles.withoutPadding, {
                              [styles.emptyCell]:
                                isMerchandise ||
                                (isWithoutPallets && isViewing),
                            })}
                          >
                            {!isMerchandise &&
                              !(isWithoutPallets && isViewing) && (
                                <TableCell
                                  item={item}
                                  isViewing={isViewing}
                                  isEditing={isEditing}
                                  editorMode={editorMode}
                                  handleInputChange={handleInputChange}
                                  getColor={getColor}
                                  tableState={tableState}
                                  field="pallets_count"
                                />
                              )}
                          </td>
                        </>
                      )}
                      {/* Product Sum */}
                      {!['Taster'].includes(inventoryCountType) && (
                        <td
                          className={classNames(styles.withoutPadding, {
                            [styles.extraMargin]: isMerchandise,
                          })}
                        >
                          {isMerchandise ? (
                            <TableCell
                              item={item}
                              isViewing={isViewing}
                              isEditing={isEditing}
                              editorMode={editorMode}
                              handleInputChange={handleInputChange}
                              getColor={getColor}
                              tableState={tableState}
                              field="product_count"
                            />
                          ) : (
                            <div
                              className={classNames('d-flex', {
                                [styles.cellContainer]: !isEditing,
                              })}
                            >
                              <div className={styles.cell}>
                                {item.product_count}
                              </div>
                              {(editorMode !== TRANSFER_TABLE_MODES.DEFAULT ||
                                isViewing) && (
                                <div
                                  className={classNames(
                                    'ml-1',
                                    styles.sum,
                                    styles.cell
                                  )}
                                  style={{
                                    color: getColor(
                                      item.product_count,
                                      realProductCount
                                    ),
                                  }}
                                >
                                  {realProductCount}
                                </div>
                              )}
                              {(editorMode === TRANSFER_TABLE_MODES.PROCESS ||
                                isViewing) && (
                                <div
                                  className={styles.cell}
                                  style={{
                                    /* stylelint-disable */
                                    color: getColor(
                                      realProductCount - item.product_count,
                                      0
                                    ),
                                  }}
                                >
                                  {getInputsTotalValueWithSign(item)}
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

InventoryTransferTable.propTypes = {
  data: PropTypes.array.isRequired,
  editorMode: PropTypes.number,
  setEditorMode: PropTypes.func,
  isViewing: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  isManual: PropTypes.bool,
  disableClearManualData: PropTypes.bool,
  onChangeData: PropTypes.func,
  inventoryCountType: PropTypes.string.isRequired,
  tasterCount: PropTypes.object,
};

InventoryTransferTable.defaultProps = {
  tasterCount: { inventory_results: [] },
};

export default InventoryTransferTable;
