import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchTastingRoomsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/tasting_rooms/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
export function* fetchTastingRoomSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/tasting_rooms/${action.payload}`,
  });
}

export function* createTastingRoomSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/tasting_rooms/`,
    data: action.payload,
    successMessage: 'Successfully created new room!',
    successNavigateTo: `/tasting_rooms/`,
  });
}

export function* updateTastingRoomSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/tasting_rooms/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated room!',
    successNavigateTo: `/tasting_rooms/`,
  });
}

export function* deleteTastingRoomSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/tasting_rooms/${action.payload}`,
    successMessage: 'Successfully deleted room!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTastingRooms, fetchTastingRoomsSaga),
    takeLatest(actions.fetchTastingRoom, fetchTastingRoomSaga),
    takeLatest(actions.createTastingRoom, createTastingRoomSaga),
    takeLatest(actions.deleteTastingRoom, deleteTastingRoomSaga),
    takeLatest(actions.updateTastingRoom, updateTastingRoomSaga),
  ]);
}
