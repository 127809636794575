import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PageWrapper from 'components/PageWrapper';
import styles from '../authentication.scss';
import PasswordField from 'components/Fields/PasswordField';
import useAction from 'hooks/useAction';
import { actions as userActions } from 'models/user/slice';
import useQuery from 'hooks/useQuery';

const Register = ({ title }) => {
  // Queries
  const query = useQuery();
  const firstName = query.get('name');
  const organization_id = query.get('organization_id');

  // Actions
  const register = useAction(userActions.register);

  // Handlers
  const handleSubmit = useCallback(
    (event, values) => {
      values.organization_id = organization_id;
      register(values);
    },
    [register]
  );

  return (
    <PageWrapper title={title}>
      <div className={styles.root}>
        <div className="text-center">
          <h4 className="font-size-18 mt-4">
            Hey {firstName}, welcome to Cellar!
          </h4>
        </div>
        <div className="p-2 mt-4">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <PasswordField
              className="auth-form-group-custom mb-4"
              name="password"
              icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
              label="Password"
              placeholder="Enter password"
            />
            <PasswordField
              name="confirm_password"
              label="Confirm password"
              icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
              className="auth-form-group-custom mb-4"
              placeholder="Confirm password"
            />

            <div className="text-center">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
              >
                Register
              </Button>
            </div>
          </AvForm>
        </div>

        <div className="mt-5 text-center">
          <p>
            Already have an account?{' '}
            <Link
              to="/login?customer=1"
              className="font-weight-medium text-primary"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

Register.propTypes = {
  title: PropTypes.string,
};

export default Register;
