import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Col, Row } from 'reactstrap';
import CustomerForm from 'components/CustomerForm';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as wineclubsActions } from 'models/wineclubs/slice';
import {
  wineclubsSelector,
  isPendingSelector as wineclubsPendingSelector,
} from 'models/wineclubs/selectors';
import { actions as customersActions } from 'models/customers/slice';
import { customerSelector } from 'models/customers/selectors';
import Preloader from 'components/Preloader';

const CreateCustomer = ({ title }) => {
  const fetchWineclubs = useAction(wineclubsActions.fetchWineclubs);
  const createCustomer = useAction(customersActions.createCustomer);
  const resetCustomer = useAction(customersActions.resetCustomer);
  const wineclubs = useSelector(wineclubsSelector);
  const wineclubsPending = useSelector(wineclubsPendingSelector);
  const customer = useSelector(customerSelector);

  useEffect(() => {
    resetCustomer();
  }, [resetCustomer]);

  useEffect(() => {
    fetchWineclubs();
  }, [fetchWineclubs]);

  const handleSubmit = data => {
    createCustomer(data);
  };

  if (wineclubsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new customer"
        breadcrumbItems={[
          {
            title: 'Back to customers',
            link: '/wineclub-customers',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <CustomerForm
            onSubmit={handleSubmit}
            wineclubs={wineclubs}
            buttonTitle="Create"
            model={customer}
            needFormLock
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateCustomer.propTypes = {
  title: PropTypes.string,
};

export default CreateCustomer;
