import React, { memo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import CheckboxField from '../Fields/CheckboxField/CheckboxField';

const ManagerFields = ({ isEditable }) => {
  return (
    <Row className="align-items-center">
      {isEditable && (
        <Col md={3}>
          <CheckboxField
            name="is_manager_active"
            label="Is Manager Active"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
        </Col>
      )}
    </Row>
  );
};

ManagerFields.propTypes = {
  isEditable: PropTypes.bool,
};
export default memo(ManagerFields);
