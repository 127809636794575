import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './DataTable.scss';

const DataTable = ({ isPending, wineclubs }) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="3">ID</th>
          <th className="align-top" data-priority="3">
            Title
          </th>
          <th data-priority="1">Description</th>
          <th data-priority="3">Brand</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {wineclubs?.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>
              {item.label_v_200x200_url && (
                <>
                  <img src={item.label_v_200x200_url} alt="" height="60" />
                  &nbsp;
                </>
              )}
              {item.title}
            </td>
            <td className={s.desc}>{item.description}</td>
            <td>{item.brand_title}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = { isPending: PropTypes.bool, wineclubs: PropTypes.array };

export default DataTable;
