import { store } from 'react-notifications-component';

const commonNotificationProps = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
};

export const showSuccessMessage = (
  title = 'success',
  message = '',
  duration = 6000
) => {
  store.addNotification({
    title,
    message,
    type: 'success',
    ...commonNotificationProps,
    dismiss: {
      duration,
      onScreen: true,
    },
  });
};

export const showErrorMessage = (title = 'Error!', message = '') => {
  store.addNotification({
    title,
    message,
    type: 'danger',
    ...commonNotificationProps,
    dismiss: {
      duration: 30000,
      onScreen: true,
    },
  });
};

export const showWarningMessage = (
  title = 'Warning!',
  message = '',
  duration = 15000
) => {
  store.addNotification({
    title,
    message,
    type: 'warning',
    ...commonNotificationProps,
    dismiss: {
      duration,
      onScreen: true,
    },
  });
};
