import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/OrderForm';

const CreateOrder = ({ title }) => {
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create Order"
        breadcrumbItems={[
          { title: 'Back to orders', link: '/orders/', withSearch: true },
        ]}
      />
      <Row>
        <Col md="12">
          <Form isCreateForm submitTitle="Save" />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateOrder.propTypes = {
  title: PropTypes.string,
};

export default CreateOrder;
