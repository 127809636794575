import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import getUID from 'utils/getUID';

import { actions as ordersActions } from 'models/orders/slice';
import { actions as shipmentActions } from 'models/orderShipmentRates/slice';
import { actions as subscriptionOrdersActions } from 'models/subscriptionOrders/slice';
import {
  orderSelector,
  ordersRefundByIdSelector,
  ordersRefundPendingSelector,
  isReportPendingSelector,
  isSendBillPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
} from 'models/orders/selectors';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';

import { PAYMENT_TYPES } from 'constants';
import { ORDER_STATUS_FOR_EDIT } from 'constants/orders';

import Main from './Main';
import Header from './Header';
import OrderRefundTable from 'components/OrderRefundTable';
import {
  shipmentRatesModalOpenSelector,
  shipmentUpdatedSelector,
  shippingUpdatePendingSelector,
} from 'models/orderShipmentRates/selectors';
import styles from './OrdersShow.scss';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import ShipmentEditPopup from 'components/Popup/ShipmentEditPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import SendBillPopup from 'components/Popup/SendBillPopup';
import OrderErrorsTable from 'components/OrderErrorsTable';

export const EXACT_SUCCESS_STATUSES = [
  'PAID_BY_CARD',
  'PAID_IN_CASH',
  'PAID_BY_CHEQUE',
  'PAID_BY_MONEY_TRANSFER',
  'PARTIALLY_RETURNED',
];

const statusesForSubscriptionActions = [
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'PAYMENT_ERROR',
];

const statusesForSkip = [
  'DRAFT',
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'CUSTOMER_APPROVED',
  'MANAGER_APPROVED',
  'PAYMENT_ERROR',
];

const finalStatuses = [
  'PAID_BY_CARD',
  'PAID_IN_CASH',
  'PAID_BY_CHEQUE',
  'PAID_BY_MONEY_TRANSFER',
  'SHIPPED',
  'RETURNED',
  'PARTIALLY_RETURNED',
  'PENDING_FULFILLMENT',
  'SKIPPED',
  'FINISHED',
  'CANCELLED',
];

const OrdersShow = ({ title }) => {
  const { orderID } = useParams();
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const fetchReport = useAction(ordersActions.fetchCommercialReport);
  const fetchOrderRefund = useAction(ordersActions.fetchOrderRefund);
  const sendOrderBill = useAction(ordersActions.sendOrderBill);
  const sendRefundOrderBill = useAction(ordersActions.sendRefundOrderBill);
  const printFile = useAction(ordersActions.printFile);
  const updateShipping = useAction(shipmentActions.updateShipping);
  const updateShipment = useAction(shipmentActions.updateShipment);
  const setShipmentRatesModalOpen = useAction(
    shipmentActions.setShipmentRatesModalOpen
  );
  const captureSubscriptionOrder = useAction(
    subscriptionOrdersActions.captureSubscriptionOrder
  );
  const skipSubscriptionOrder = useAction(
    subscriptionOrdersActions.skipSubscriptionOrder
  );
  const orderIsPending = useSelector(ordersIsPendingSelector);
  const ordersRefundPending = useSelector(ordersRefundPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const order = useSelector(orderSelector);
  const orderRefund = useSelector(ordersRefundByIdSelector(orderID));
  const shipmentRatesModalOpen = useSelector(shipmentRatesModalOpenSelector);
  const shipmentUpdated = useSelector(shipmentUpdatedSelector);
  const isSendBillPending = useSelector(isSendBillPendingSelector);
  const isUpdateShippingStatus = useSelector(shippingUpdatePendingSelector);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const [isEditShipmentOpen, setIsEditShipmentOpen] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const orderShipment = [...(order?.shipping_info?.shipments ?? [])].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];

  const location = useLocation();
  const previousPath = location.state?.previousPath || '/orders/';
  const getOrderLink = () => {
    switch (order?.resourcetype) {
      case 'SubscriptionOrder':
        return `/orders/subscription/${order?.id}/edit`;
      case 'CommercialOrder':
        return `/commercial-orders/${order?.id}/edit`;
      case 'RetailOrder':
        return `/orders/${order?.id}/edit`;
      default:
        return null;
    }
  };
  const getOrderTitle = () => {
    if (!ORDER_STATUS_FOR_EDIT.includes(order?.status)) return title;
    switch (order?.resourcetype) {
      case 'SubscriptionOrder':
        return `Edit ${title}`;
      case 'CommercialOrder':
        return `Edit ${title}`;
      case 'RetailOrder':
        return `Edit ${title}`;
      default:
        return title;
    }
  };

  const onShipmentEdit = useCallback(
    data => {
      if (!orderShipment?.id) return;
      updateShipment({ ...data, id: orderShipment.id });
    },
    [orderShipment]
  );

  const [modalBill, setModalBill] = useState(null);
  const [modalRefundBill, setModalRefundBill] = useState(null);

  useEffect(() => {
    fetchOrder(orderID);
  }, [fetchOrder, orderID]);

  useEffect(() => {
    if (shipmentUpdated) {
      setIsEditShipmentOpen(false);
    }
  }, [shipmentUpdated]);

  useEffect(() => {
    if (isOwnerOrManagerRole) {
      fetchOrderRefund({ orderId: orderID });
    }
  }, [fetchOrderRefund, orderID, isOwnerOrManagerRole]);

  const onReportDownload = () => {
    fetchReport({ id: orderID });
  };

  const handlePrintShippingLabel = useCallback(
    parcel_id => {
      const payload = {
        orders_ids: [orderID],
        packing_slip: false,
        shipping_label: true,
        parcel_id,
      };
      printFile(payload);
    },
    [orderID]
  );

  const handleChargeOrder = () => {
    captureSubscriptionOrder({
      orderId: order?.id,
      force_capture: false,
      email: order?.shipping_info?.email || order?.customer?.email,
    });
  };

  const handleSkipOrder = () => {
    skipSubscriptionOrder(orderID);
    setConfirmModal(false);
  };

  const handleSendBill = data => {
    const payload = {
      id: data?.id,
      shipping_info: {
        email: data?.email,
      },
      payment_type: order?.payment_type,
    };

    sendOrderBill({
      data: payload,
    });
    setModalBill(false);
  };

  const handleSendRefundBill = data => {
    const payload = {
      id: data?.id,
      shipping_info: {
        email: data?.email,
      },
      refund_type: orderRefund[0]?.return_type,
    };
    sendRefundOrderBill({
      data: payload,
    });
    setModalRefundBill(false);
  };

  const isRefundButtonShow =
    (EXACT_SUCCESS_STATUSES.includes(order?.status) ||
      (order?.status === 'SHIPPED' &&
        order?.resourcetype === 'SubscriptionOrder')) &&
    order?.resourcetype !== 'ContainerWorldOrder';

  if (orderIsPending || ordersRefundPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={
          ORDER_STATUS_FOR_EDIT.includes(order?.status) ? getOrderLink() : null
        }
        title={`${getOrderTitle()} #${order?.id}`}
        breadcrumbItems={[
          { title: 'Back to orders', link: previousPath, withSearch: true },
        ]}
      />
      {modalBill && (
        <SendBillPopup
          onSubmit={handleSendBill}
          onClose={() => setModalBill(null)}
          order={modalBill}
        />
      )}
      {modalRefundBill && (
        <SendBillPopup
          onSubmit={handleSendRefundBill}
          onClose={() => setModalRefundBill(null)}
          order={modalRefundBill}
        />
      )}
      {isEditShipmentOpen && (
        <ShipmentEditPopup
          onSubmit={onShipmentEdit}
          onClose={() => setIsEditShipmentOpen(false)}
          defaultValues={orderShipment}
          brandId={order?.brand?.id}
          price={Number(order?.shipping_info?.shipments.slice(-1)[0]?.price)}
          cantPriceBeChanged={ORDER_STATUS_FOR_EDIT.includes(order?.status)}
        />
      )}
      {confirmModal && (
        <ConfirmationPopup
          active={confirmModal}
          setActive={setConfirmModal}
          title="Are you sure you want to skip this  order?"
          submitButtonTitle="Skip"
          onSaveClick={handleSkipOrder}
        />
      )}

      {shipmentRatesModalOpen && (
        <SelectShippingPopup
          isOpen={shipmentRatesModalOpen}
          setIsOpen={setShipmentRatesModalOpen}
          withFooter={false}
          setIsChargeMode={setIsChargeMode}
          isChargeMode={isChargeMode}
          captureSubscriptionOrder={handleChargeOrder}
          order={order}
        />
      )}

      <Row>
        <Col lg="12">
          <Header
            order={order}
            orderShipment={orderShipment}
            onReportDownload={onReportDownload}
            onLabelDownload={handlePrintShippingLabel}
            reportDisabled={isReportPending}
            onShipmentEditClick={() => setIsEditShipmentOpen(true)}
            onShipmentSelectClick={() => setShipmentRatesModalOpen(true)}
            onUpdateShipping={() => updateShipping(orderShipment?.id)}
            updatePending={isUpdateShippingStatus}
          />
        </Col>
      </Row>
      {finalStatuses.includes(order?.status) &&
        order.resourcetype !== 'ContainerWorldOrder' && (
          <Button
            color="primary"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap"
            onClick={() => setModalBill(order)}
            disabled={isSendBillPending}
          >
            Resend Order Receipt
          </Button>
        )}
      <Row>
        <Col md="12">
          <Main
            flavourProfile={order?.flavour_profile ?? []}
            orderItems={order?.order_items}
          />
        </Col>
      </Row>
      {order.resourcetype === 'SubscriptionOrder' &&
        statusesForSubscriptionActions.includes(order?.status) && (
          <Button
            color="primary"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap "
            onClick={() => {
              setIsChargeMode(true);
              setShipmentRatesModalOpen(true);
            }}
          >
            Charge Order
          </Button>
        )}
      {order.resourcetype === 'SubscriptionOrder' &&
        statusesForSkip.includes(order?.status) && (
          <Button
            color="danger"
            className="waves-effect waves-light mr-3 mb-2 text-nowrap "
            onClick={() => setConfirmModal(true)}
          >
            Skip Order
          </Button>
        )}
      {orderRefund && (
        <Button
          color="primary"
          className="waves-effect waves-light mr-3 mb-2 text-nowrap"
          onClick={() => setModalRefundBill(order)}
          disabled={isSendBillPending}
        >
          Resend refund receipt
        </Button>
      )}
      {orderRefund && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="card-title">Refunded Order Items</h3>
                {orderRefund.map(refund => (
                  <OrderRefundTable
                    className="mt-3"
                    data={refund.returned_order_items}
                    amount={+refund.return_amount}
                    comment={refund.comment}
                    date={refund.created_at}
                    invoiceNumber={refund.invoice_number}
                    shipment={+refund.shipment_return_amount}
                    tips={refund.tips_return_amount}
                    refundType={PAYMENT_TYPES[refund.return_type]?.userValue}
                  />
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {isRefundButtonShow && (
        <Row className={styles.button}>
          <Col>
            <NavLink to={`/refund/${orderID}`}>
              <Button color="primary">Refund</Button>
            </NavLink>
          </Col>
        </Row>
      )}
      {order?.errors && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="card-title">System Errors</h3>
                <OrderErrorsTable
                  data={order?.errors.map(error => ({
                    id: getUID(),
                    code: Object.keys(error)[0],
                    message: Object.values(error)[0],
                  }))}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

OrdersShow.propTypes = {
  title: PropTypes.string,
};

export default OrdersShow;
