export const ADDITIONS_EXCISE_REASONS = [
  { label: 'Domestic Wine', value: 'DOMESTIC_WINE' },
  { label: 'Imported Wine', value: 'IMPORTED_WINE' },
  {
    label: 'Returned From Duty Paid Sources',
    value: 'RETURNED_FROM_DUTY_PAID_SOURCES',
  },
  {
    label: 'Returned From Non Duty Paid Sources',
    value: 'RETURNED_FROM_NON_DUTY_PAID_SOURCES',
  },
];

export const REDUCTIONS_EXCISE_REASONS = [
  {
    label: 'Removed For Delivery To Accredited Representatives',
    value: 'REMOVED_FOR_DELIVERY_TO_ACCREDITED_REPRESENTATIVES',
  },
  {
    label: 'Removed For Delivery To Duty Free Shops',
    value: 'REMOVED_FOR_DELIVERY_TO_DUTY_FREE_SHOPS',
  },
  {
    label: 'Removed For Delivery To Ships Stores',
    value: 'REMOVED_FOR_DELIVERY_TO_SHIPS_STORES',
  },
  {
    label: 'Removed For Delivery To Licensed Users',
    value: 'REMOVED_FOR_DELIVERY_TO_LICENSED_USERS',
  },
  {
    label: 'Removed For Delivery To Other Excise Warehouses',
    value: 'REMOVED_FOR_DELIVERY_TO_OTHER_EXCISE_WAREHOUSES',
  },
  { label: 'Exported Wine', value: 'EXPORTED_WINE' },
  { label: 'Return To Wine Licensee', value: 'RETURN_TO_WINE_LICENSEE' },
  { label: 'Breakage', value: 'BREAKAGE' },
  {
    label: 'Wine Removed For Other Purposes Non Duty',
    value: 'WINE_REMOVED_FOR_OTHER_PURPOSES_NON_DUTY',
  },
  {
    label: 'Packaged Excluding Marked Special Containers',
    value: 'PACKAGED_EXCLUDING_MARKED_SPECIAL_CONTAINERS',
  },
  {
    label: 'Packaged In Marked Special Containers',
    value: 'PACKAGED_IN_MARKED_SPECIAL_CONTAINERS',
  },
  {
    label: 'Wine Removed For Other Purposes Duty',
    value: 'WINE_REMOVED_FOR_OTHER_PURPOSES_DUTY',
  },
];
