import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';

export function* resetDemoWorkspaceSaga(action) {
  const { workspaceId } = action.payload;
  yield api({
    action,
    method: 'POST',
    data: { workspace_id: workspaceId },
    url: `/workspaces/reset_demo_data`,
  });
}

export default function*() {
  yield all([takeLatest(actions.resetDemoWorkspace, resetDemoWorkspaceSaga)]);
}
