export const getPreparedCustomerOption = customer => {
  const value = customer.id;
  const label = customer.first_name
    ? `${customer.first_name || ''} ${customer.last_name || ''}${
        customer?.email ? ` (${customer?.email})` : ''
      }`
    : customer.email;

  return { ...customer, value, label };
};
