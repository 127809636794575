import React from 'react';
import formatPrice from 'utils/formatPrice';
import formatMonth from 'utils/formatMonth';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, CardBody, Table } from 'reactstrap';
import CardItem from 'components/CardItem';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/orders/selectors';
import OrderInfoBlock from 'components/OrderInfoBlock';

const getOrderItemCasesLabel = orderItem => {
  const count = orderItem.number;
  const bottlesInCase = orderItem.product?.product_bottles_in_case;

  if (!bottlesInCase) {
    return '-';
  }

  return `${Math.floor(count / bottlesInCase)} (${count -
    (count % bottlesInCase)})`;
};

const getOrderItemBottlesLabel = orderItem => {
  const count = orderItem.number;
  const bottlesInCase = orderItem.product?.product_bottles_in_case;

  if (!bottlesInCase) {
    return '-';
  }

  return `${count % bottlesInCase}`;
};

const OrderInfo = ({
  organization,
  order,
  createdShipment,
  currentCard,
  onPay,
}) => {
  const isPending = useSelector(isPendingSelector);

  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Commercial Customer" value={organization?.title} />
        </Col>
        <Col lg="3">
          <CardItem title="Brand" value={order?.brand?.title} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Shipping"
            value={
              createdShipment
                ? `${
                    createdShipment?.brand_shipping_company_title
                      ? createdShipment?.brand_shipping_company_title
                      : 'Unknown company'
                  } - $${createdShipment?.price} ${
                    createdShipment?.free_shipping ? '(Free shipping)' : ''
                  }`
                : 'Pickup'
            }
          />
        </Col>
        {currentCard && (
          <Col lg="3">
            <CardItem
              title="Card"
              value={`**** **** **** ${currentCard?.card_last_digits} ${
                currentCard?.card_exp_month
              }/${formatMonth(
                currentCard?.card_exp_year.toString()
              )} ${currentCard.card_brand.toUpperCase()}`}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th data-priority="1">Invoice Number</th>
                  <th data-priority="1">Product</th>
                  <th data-priority="1">Cases To Order</th>
                  <th data-priority="1">Additional Bottles</th>
                  <th data-priority="3">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {order.order_items?.map(item => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{getOrderItemCasesLabel(item)}</td>
                    <td>{getOrderItemBottlesLabel(item)}</td>
                    <td>
                      {formatPrice(item.total_price)}&nbsp;
                      {item.total_price_currency}{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <OrderInfoBlock
                title="Product Subtotal"
                value={`${formatPrice(
                  order?.order_items?.reduce(
                    (acc, value) => acc + value.number * value.product_price,
                    0
                  )
                )} CAD`}
              />
              {order?.total_order_discount > 0 && (
                <OrderInfoBlock
                  title="Discounts"
                  value={`-${formatPrice(order?.total_order_discount)} ${
                    order?.total_price_currency
                  }`}
                />
              )}
              {Object.keys(order.applied_fees).length > 0 && (
                <OrderInfoBlock title="Taxes" value="" />
              )}
              {Object.keys(order.applied_fees).map(fee => (
                <OrderInfoBlock
                  title={fee}
                  value={`${formatPrice(order?.applied_fees[fee])} ${
                    order?.total_price_currency
                  }`}
                />
              ))}
              <OrderInfoBlock
                title="Shipping"
                value={
                  createdShipment
                    ? `${
                        createdShipment.free_shipping
                          ? '0.00'
                          : createdShipment?.price.toFixed(2)
                      } ${order?.total_price_currency}`
                    : 'Pickup'
                }
              />
              <hr />
              <OrderInfoBlock
                title="Total"
                value={`${formatPrice(order?.total_price)} ${
                  order?.total_price_currency
                }`}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Button
        disabled={isPending}
        className="mr-2"
        color="primary"
        onClick={onPay}
      >
        Place order
      </Button>
    </>
  );
};

OrderInfo.propTypes = {
  organization: PropTypes.object,
  order: PropTypes.object,
  createdShipment: PropTypes.object,
  currentCard: PropTypes.object,
  onPay: PropTypes.func.isRequired,
};

export default OrderInfo;
