import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { stringify } from 'query-string';

export function* fetchInventoriesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/inventories/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchInventoryManualCountingSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/inventory_manual_countings/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: true,
  });

  yield api({
    action,
    method: 'GET',
    url,
  });
}

function* fetchTasterCountByWarehouseIdSaga(action) {
  yield api({
    action,
    method: 'PUT',
    url: `/inventory_manual_countings/`,
    data: { warehouse_id: action.payload, count_type: 'Taster' },
  });
}

function* fetchInitializedManualCountSaga(action) {
  const { url } = yield generateURL({
    payload: {
      brand_title: 'All brands',
      warehouse_title: 'All warehouses',
      status: 'INITIALIZED',
      per_page: 999,
    },
    url: '/inventory_manual_countings/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'GET',
    url,
  });
}

function* fetchInventoryManualCountingByIdSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'GET',
    url: `/inventory_manual_countings/${payload}`,
  });
}

function* createInventoryManualCountingSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/inventory_manual_countings/`,
    data: action.payload,
    successNavigateTo: '/inventory-count/',
  });
}

function removeInventoryReportsFromLocalStorage() {
  localStorage.removeItem('inventory_reports');
}

function* approveInventoryManualCountingSaga(action) {
  yield api({
    action,
    method: 'POST',
    data: action.payload,
    url: `/inventory_manual_countings/${action.payload.id}/approve`,
    successNavigateTo: '/inventory-count/',
  });
}

function* rejectInventoryManualCountingSaga(action) {
  yield api({
    action,
    method: 'POST',
    data: action.payload,
    url: `/inventory_manual_countings/${action.payload.id}/reject`,
    successNavigateTo: '/inventory-count/',
  });
}

function* fetchInventoryStatusBeforeCheckSaga(action) {
  const { id, page } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/inventory_manual_countings/${id}/inventory_status_before_check?page=${page}&per_page=10`,
  });
}

function* fetchInventoryWarehouseSaga(action) {
  const { page, warehouse_ids, brand_id, product_ids } = action.payload;
  const data = warehouse_ids.length
    ? { page, warehouse_ids, product_ids }
    : { page, brand_id, product_ids };
  const parameters = stringify(data, { arrayFormat: 'comma' });

  yield api({
    action,
    method: 'GET',
    url: `/reports/warehouse_inventory/?${parameters}`,
  });
}

function* fetchContainerWorldExceptionSaga(action) {
  const { url } = yield generateURL({
    url: '/container_world_inventory_exceptions/',
    payload: action.payload,
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });
  yield api({
    action,
    method: 'GET',
    url,
  });
}

function* fetchLowInventorySaga(action) {
  const { url } = yield generateURL({
    payload: {
      is_low: true,
      per_page: 99999,
    },
    url: `/inventory_limits/`,
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });
  yield api({
    action,
    method: 'GET',
    url,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchInventories, fetchInventoriesSaga),
    takeLatest(
      actions.fetchInventoryManualCounting,
      fetchInventoryManualCountingSaga
    ),
    takeLatest(
      actions.fetchInitializedManualCount,
      fetchInitializedManualCountSaga
    ),
    takeLatest(
      actions.fetchInventoryManualCountingById,
      fetchInventoryManualCountingByIdSaga
    ),
    takeLatest(
      actions.createInventoryManualCounting,
      createInventoryManualCountingSaga
    ),
    takeLatest(
      actions.createInventoryManualCountingSuccess,
      removeInventoryReportsFromLocalStorage
    ),
    takeLatest(
      actions.approveInventoryManualCounting,
      approveInventoryManualCountingSaga
    ),
    takeLatest(
      actions.approveInventoryManualCountingSuccess,
      removeInventoryReportsFromLocalStorage
    ),
    takeLatest(
      actions.rejectInventoryManualCounting,
      rejectInventoryManualCountingSaga
    ),
    takeLatest(
      actions.rejectInventoryManualCountingSuccess,
      removeInventoryReportsFromLocalStorage
    ),
    takeLatest(
      actions.fetchInventoryStatusBeforeCheck,
      fetchInventoryStatusBeforeCheckSaga
    ),
    takeLatest(
      actions.fetchContainerWorldException,
      fetchContainerWorldExceptionSaga
    ),
    takeLatest(actions.fetchLowInventory, fetchLowInventorySaga),
    takeLatest(
      actions.fetchTasterCountByWarehouseId,
      fetchTasterCountByWarehouseIdSaga
    ),
  ]);
  yield all([
    takeLatest(actions.fetchInventoryWarehouse, fetchInventoryWarehouseSaga),
  ]);
}
