import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/OrderForm';

import useSelector from 'hooks/useSelector';

import useAction from 'hooks/useAction';

import Preloader from 'components/Preloader';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';
import { useLocation, useParams } from 'react-router-dom';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as productsActions } from 'models/products/slice';

import useComponentDidMount from 'hooks/useComponentDidMount';
import { createdOrderSelector } from 'models/orders/selectors';

const EditOrder = ({ title }) => {
  const { orderID } = useParams();
  const fetchProductsCount = useAction(productsActions.fetchProductsCount);
  const fetchOrder = useAction(ordersActions.fetchOrder);

  const warehousesLoading = useSelector(warehousesIsPendingSelector);
  const order = useSelector(createdOrderSelector);

  const isPending = warehousesLoading;

  const location = useLocation();
  const previousPath = location.state?.previousPath || '/orders/';

  useComponentDidMount(() => {
    fetchOrder(orderID);
  });

  useEffect(() => {
    if (order) {
      const orderItems = order?.order_items ?? [];
      const warehouseId = order?.warehouse.id;
      const productIds = orderItems.map(orderItem => orderItem.product.id);
      fetchProductsCount({ warehouseId, productIds: [...productIds] });
    }
  }, [order]);

  if (isPending) {
    return <Preloader />;
  }

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Order"
        breadcrumbItems={[
          { title: 'Back to orders', link: previousPath, withSearch: true },
        ]}
      />
      {!isEmpty(order) && (
        <Row>
          <Col md="12">
            <Form order={order} isCreateOrder={false} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditOrder.propTypes = {
  title: PropTypes.string,
};

export default EditOrder;
