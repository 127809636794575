import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchWineclubMembersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/wineclub-members/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchWineclubMembers, fetchWineclubMembersSaga),
  ]);
}
