import React from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { customStyles } from 'constants/disabledAsync';

const Inputs = ({
  quantity,
  limitValue,
  selectedProduct,
  productId,
  brandId,
  warehouseTitle,
  loadOptions,
  handleProductInputChange,
  handleQuantityInputChange,
  handleLimitValueInputChange,
  handleSaveTransferItems,
  showInventoryNotificationLimits,
  productIDParam,
}) => {
  return (
    <Col md="12">
      <Row className="d-flex align-items-end">
        <Col lg="4">
          <FormGroup>
            <Label required htmlFor="Quantity">
              Select Product
            </Label>
            {productIDParam ? (
              <AsyncPaginate
                name="products"
                defaultValue={productIDParam}
                value={selectedProduct}
                isDisabled
                getOptionLabel={option => option.title}
                menuPlacement="auto"
                styles={customStyles}
              />
            ) : (
              <AsyncPaginate
                debounceTimeout={200}
                name="products"
                defaultValue={productId}
                value={selectedProduct}
                loadOptions={loadOptions}
                onChange={handleProductInputChange}
                additional={{ page: 1 }}
                getOptionValue={option => option.id}
                cacheUniqs={[brandId]}
                getOptionLabel={option => option.title}
                menuPlacement="auto"
              />
            )}
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label required htmlFor="quantity">
              Quantity
            </Label>
            <Input
              type="number"
              className="form-control"
              value={quantity}
              placeholder="0"
              id="quantity"
              name="quantity"
              onFocus={e =>
                e.target.addEventListener(
                  'wheel',
                  function(evt) {
                    evt.preventDefault();
                  },
                  { passive: false }
                )
              }
              onChange={e => {
                handleQuantityInputChange(e);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {showInventoryNotificationLimits && [
        <Row>
          <Col md="7">
            <Alert color="info">
              <i className="mdi mdi-information" />{' '}
              <b>
                There is no Inventory Notification Limit for this product at{' '}
                {warehouseTitle}.
              </b>
              <br />A default limit will be set, or you can set a custom limit
              below. You will be notified if the quantity of Product falls below
              this value at {warehouseTitle}.
            </Alert>
          </Col>
        </Row>,
        <Row>
          <Col md="7">
            <FormGroup>
              <Label required htmlFor="limitValue">
                Inventory Notification Limit
              </Label>
              <Input
                type="number"
                className="form-control"
                value={limitValue}
                placeholder="500"
                id="limitValue"
                name="limitValue"
                onFocus={e =>
                  e.target.addEventListener(
                    'wheel',
                    function(evt) {
                      evt.preventDefault();
                    },
                    { passive: false }
                  )
                }
                onChange={e => {
                  handleLimitValueInputChange(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>,
      ]}
      <Row>
        <Col md="3">
          <FormGroup>
            {!productIDParam && (
              <Button
                color={productId === '' ? 'info' : 'primary'}
                className="d-flex waves-effect waves-light mr-3 text-nowrap"
                onClick={() => {
                  handleSaveTransferItems();
                }}
                disabled={!selectedProduct || !quantity}
              >
                Add Product
              </Button>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Col>
  );
};

Inputs.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limitValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedProduct: PropTypes.any,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  brandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouseTitle: PropTypes.string,
  loadOptions: PropTypes.func,
  handleProductInputChange: PropTypes.func,
  handleQuantityInputChange: PropTypes.func,
  handleLimitValueInputChange: PropTypes.func,
  handleSaveTransferItems: PropTypes.func,
  showInventoryNotificationLimits: PropTypes.bool,
  productIDParam: PropTypes.string,
};

export default Inputs;
