import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BodyModal from '../BodyModal';
import { Button } from 'reactstrap';

const SelectData = ({ isOpen, setIsOpen, data, setData }) => {
  return (
    <BodyModal
      title="Select items"
      setIsOpen={setIsOpen}
      footer={
        <Button onClick={() => setData([])} color="primary">
          Submit
        </Button>
      }
    >
      {data.map(s => (
        <div key={s.id} className="d-flex align-items-center">
          <input
            type="radio"
            checked={isOpen === s.id}
            name="importdata"
            onChange={() => setIsOpen(s.id)}
          />
          <label className="ml-2">{s.id}</label>
        </div>
      ))}
    </BodyModal>
  );
};

SelectData.propTypes = {
  isOpen: PropTypes.number,
  setIsOpen: PropTypes.func,
  data: PropTypes.array,
  setData: PropTypes.func,
};

export default memo(SelectData);
