import { takeLatest, all } from 'redux-saga/effects';
import { stringify } from 'query-string';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchWarehousesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/warehouses/',
    setQuery: action.payload.set_query,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchSenderWarehousesSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    url: `/warehouses/?&is_used_as_sender=true&brand_title=${payload?.brand_title}&brand_id=${payload?.brand_id}`,
  });
}

export function* fetchWarehouseSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/warehouses/${action.payload}`,
  });
}

export function* createWarehouseSaga(action) {
  const data = {
    ...action.payload,
    brand_id: Number.parseInt(action.payload.brand_id, 10),
  };
  yield api({
    action,
    method: 'post',
    url: `/warehouses/`,
    data,
    successMessage: 'Successfully created new space!',
    successNavigateTo: `/warehouses`,
  });
}

export function* updateWarehouseSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/warehouses/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated warehouse!',
    successNavigateTo: `/warehouses`,
  });
}

export function* deleteWarehouseSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/warehouses/${action.payload}`,
    successMessage: 'Successfully deleted warehouse!',
  });
}

function* fetchWarehouseInventoriesSaga(action) {
  const { warehouseId, page } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `warehouses/${warehouseId}/inventories?page=${page}`,
  });
}

function* updateContainerWorldInventoryStatusSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'POST',
    url: `warehouses/${payload.id}/update_container_world_inventory_status`,
  });
}

function* fetchInventoryLimitsSaga(action) {
  const { warehouseId, page } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/inventory_limits/?warehouse_id=${warehouseId}&page=${page}`,
  });
}

function* fetchReportsInventorySaga(action) {
  const { page, warehouse_ids, brand_id, product_ids } = action.payload;
  const data = warehouse_ids.length
    ? { page, warehouse_ids, product_ids }
    : { page, brand_id, product_ids };
  const parameters = stringify(data, { arrayFormat: 'comma' });

  yield api({
    action,
    method: 'GET',
    url: `/reports/warehouse_inventory/?${parameters}`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchWarehouses, fetchWarehousesSaga),
    takeLatest(actions.fetchSenderWarehouses, fetchSenderWarehousesSaga),
    takeLatest(actions.fetchWarehouse, fetchWarehouseSaga),
    takeLatest(actions.createWarehouse, createWarehouseSaga),
    takeLatest(actions.deleteWarehouse, deleteWarehouseSaga),
    takeLatest(actions.updateWarehouse, updateWarehouseSaga),
    takeLatest(
      actions.updateContainerWorldInventoryStatus,
      updateContainerWorldInventoryStatusSaga
    ),
    takeLatest(
      actions.fetchWarehouseInventories,
      fetchWarehouseInventoriesSaga
    ),
    takeLatest(actions.fetchInventoryLimits, fetchInventoryLimitsSaga),
    takeLatest(actions.fetchReportsInventory, fetchReportsInventorySaga),
  ]);
}
