import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import TextField from 'components/Fields/TextField';
import NumberField from 'components/Fields/NumberField';
import SelectField from 'components/SelectField/SelectField';
import { EXCISE_LICENSE_TYPES } from 'constants';
import LocationSearchInput from 'components/LocationSearchInput';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';

const Form = ({ model, onSubmit, disabled, submitTitle }) => {
  const [selectedLicenseType, setSelectedLicenseType] = useState(
    model?.license_type
  );

  const [brandTouched, setBrandTouched] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(model?.brand);
  const [warehouseTouched, setWarehouseTouched] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(model?.warehouse);

  const [country, setCountry] = useState(model?.country);
  const [state, setState] = useState(model?.state);
  const [city, setCity] = useState(model?.city);
  const [line1, setLine1] = useState(model?.line1);
  const [line2, setLine2] = useState(model?.line2);
  const [postalCode, setPostalCode] = useState(model?.postal_code);

  const handleSubmit = useCallback(
    (_, values) => {
      const payload = {
        ...values,
        brand_id: selectedBrand.id,
        warehouse_id: selectedWarehouse?.id,
        license_type: selectedLicenseType,
        country,
        state,
        city,
        line1,
        line2,
        postal_code: postalCode,
      };
      onSubmit({ id: model?.id, ...payload });
    },
    [
      onSubmit,
      model,
      selectedLicenseType,
      selectedWarehouse,
      selectedBrand,
      country,
      state,
      city,
      line1,
      line2,
      postalCode,
    ]
  );

  return (
    <AvForm
      model={model || {}}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="6">
          <FormGroup>
            <Label
              className={brandTouched && !selectedBrand && 'text-danger'}
              error={brandTouched && !selectedBrand && 'This field is invalid'}
              required
              htmlFor="brand_id"
            >
              Brand
            </Label>
            <BrandsPaginatedField
              value={{ ...selectedBrand, label: selectedBrand?.title }}
              onChange={setSelectedBrand}
              isMulti={false}
              onBlur={() => setBrandTouched(true)}
              error={brandTouched && !selectedBrand && 'This field is invalid'}
              isDisabled={model?.brand?.id}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <SelectField
            name="license_type"
            label="License Type"
            options={EXCISE_LICENSE_TYPES}
            value={EXCISE_LICENSE_TYPES.find(x => x.id === selectedLicenseType)}
            onChange={item => setSelectedLicenseType(item.id)}
            isRequired
            isDisabled={model?.license_type}
          />
        </Col>
      </Row>
      {selectedLicenseType === 'WAREHOUSE' && (
        <Row>
          <Col md="6" />
          <Col md="6">
            <FormGroup>
              <Label
                className={
                  warehouseTouched && !selectedWarehouse && 'text-danger'
                }
                required
                htmlFor="warehouse_id"
              >
                Warehouse
              </Label>
              <WarehousesPaginatedField
                value={{
                  ...selectedWarehouse,
                  label: selectedWarehouse?.title,
                }}
                isMulti={false}
                onChange={warehouse => {
                  setSelectedWarehouse(warehouse);
                }}
                onBlur={() => setWarehouseTouched(true)}
                error={
                  warehouseTouched && !selectedWarehouse
                    ? 'This field is invalid'
                    : ''
                }
                isDisabled={model?.warehouse?.id}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="6">
          <TextField
            name="business_name"
            label="Business Name"
            placeholder="Business name..."
            errorMessage="Enter Business Name"
            isRequired
          />
        </Col>
        <Col md="6">
          <TextField
            name="account_number"
            label="Account Number"
            placeholder="Account number..."
            errorMessage="Enter account number"
            isRequired
          />
        </Col>
        <Col md="4">
          <NumberField
            name="wine_duty_rate_0"
            label="Wine Duty Rate under 1.2% ($ Per Liter)"
            placeholder="Duty rate..."
            errorMessage="Enter duty rate"
          />
        </Col>
        <Col md="4">
          <NumberField
            name="wine_duty_rate_1"
            label="Wine Duty Rate under 7% ($ Per Liter)"
            placeholder="Duty rate..."
            errorMessage="Enter duty rate"
          />
        </Col>
        <Col md="4">
          <NumberField
            name="wine_duty_rate_7"
            label="Wine Duty Rate over 7% (Per Liter)"
            placeholder="Duty rate..."
            errorMessage="Enter duty rate"
          />
        </Col>
        {selectedLicenseType !== 'WINE' && (
          <>
            <Col md="4">
              <NumberField
                name="spirit_duty_rate_under_7"
                label="Spirit Duty Rate under 7% ($ Per Liter)"
                placeholder="Duty rate..."
                errorMessage="Enter duty rate"
              />
            </Col>
            <Col md="4">
              <NumberField
                name="spirit_duty_rate_over_7"
                label="Spirit Duty Rate over 7% ($ Per Liter)"
                placeholder="Duty rate..."
                errorMessage="Enter duty rate"
              />
            </Col>
            <Col md="4">
              <NumberField
                name="spirit_duty_rate_special"
                label="Spirit Duty Rate Special ($ Per Liter)"
                placeholder="Duty rate..."
                errorMessage="Enter duty rate"
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col md={9}>
          <LocationSearchInput
            title="Address"
            model={model}
            country={country}
            state={state}
            city={city}
            postalCode={postalCode}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
          />
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
};

export default Form;
