/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { SHIPMENT_STATUSES } from 'constants/shipment';
import useSelector from 'hooks/useSelector';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import Preloader from 'components/Preloader';
import useAction from 'hooks/useAction';
import formatPrice from 'utils/formatPrice';
import { showErrorMessage } from 'utils/notification';
import {
  isPendingSelector as ratesIsPendingSelector,
  shipmentRatesSelector,
  isCreatingSelector,
  createdShipmentSelector,
  callNextActionSelector,
} from 'models/orderShipmentRates/selectors';
import { isPendingActionSelector } from 'models/orders/selectors';
import { actions as orderShipmentsActions } from 'models/orderShipmentRates/slice';

import CompanyPaginatedField from '../PaginatedFields/CompanyPaginatedField';

import styles from './Shipments.scss';

const Shipments = ({
  order,
  buttonTitle,
  nextAction,
  withWhitePreloader,
  withTotal,
  isOnlyShipping,
}) => {
  const [shipmentRate, setShipmentRate] = useState(null);
  const [customShipment, setCustomShipment] = useState({});

  const fetchShipmentRates = useAction(
    orderShipmentsActions.fetchShipmentRates
  );

  const ratesPending = useSelector(ratesIsPendingSelector);
  const ratesCreating = useSelector(isCreatingSelector);
  const orderPending = useSelector(isPendingActionSelector);
  const callNextAction = useSelector(callNextActionSelector);

  const pending = ratesPending || orderPending;

  const shipmentRates = useSelector(shipmentRatesSelector);
  const createdShipment = useSelector(createdShipmentSelector);

  const setNewShipment = useAction(orderShipmentsActions.setNewShipment);
  const cancelCallingNextAction = useAction(
    orderShipmentsActions.cancelCallingNextAction
  );

  const createShipment = useAction(orderShipmentsActions.createShipment);
  const deleteShipment = useAction(orderShipmentsActions.deleteShipment);
  const [freeShipping, setFreeShipping] = useState(false);

  useEffect(() => {
    if (callNextAction) {
      if (nextAction) nextAction();
      cancelCallingNextAction();
    }
  }, [callNextAction]);

  useEffect(() => {
    if (order?.id) {
      fetchShipmentRates(order?.id);
      const currentShipment = order.shipping_info?.shipments?.filter(
        s => s.status === SHIPMENT_STATUSES.PRE_TRANSIT
      )?.[0];
      if (currentShipment && currentShipment !== []) {
        setNewShipment(currentShipment);
      }
    }
  }, [order]);

  useEffect(() => {
    if (createdShipment && createdShipment !== []) {
      setShipmentRate('current');
      setCustomShipment({ company: '', price: '' });
      setFreeShipping(createdShipment?.free_shipping);
    } else {
      setShipmentRate(null);
    }
  }, [createdShipment]);

  const handleShippingButtonClick = () => {
    let requestObject = {};

    const getCommonRequestObject = (type, data) => {
      return {
        order_id: order.id,
        type,
        brand_shipping_company_id:
          data?.brand_shipping_company_id || data?.company?.id,
        currency: 'CAD',
        price: data.price,
      };
    };

    if (shipmentRate === null) {
      requestObject.id = order.id;
    } else if (shipmentRate === 'custom') {
      requestObject = getCommonRequestObject('Manual', customShipment);
      requestObject.free_shipping = freeShipping;
    } else if (shipmentRate !== 'current') {
      requestObject = getCommonRequestObject('Easypost', shipmentRate);
      requestObject.free_shipping = freeShipping;
      requestObject.service = shipmentRate?.service;
      requestObject.carrier = shipmentRate?.carrier;
      requestObject.shipment_ext_id =
        shipmentRate.shipment_id || shipmentRate.shipment_ext_id;
    }

    if (shipmentRate === null) {
      if (createdShipment) {
        deleteShipment(createdShipment.id);
      } else if (nextAction) {
        nextAction();
      }
    } else if (shipmentRate === 'current') {
      nextAction();
    } else if (requestObject?.type) {
      createShipment({
        ...requestObject,
      });
    } else {
      showErrorMessage('Error!', 'Something went wrong. Try another option.');
    }
  };

  const getShipmentPrice = useCallback(() => {
    if (freeShipping) {
      return 0;
    }
    if (shipmentRate === null) {
      return 0;
    } else if (shipmentRate.type) {
      return Number(shipmentRate.price);
    } else if (shipmentRate === 'current') {
      return Number(createdShipment.price);
    } else if (shipmentRate === 'custom') {
      return Number(customShipment.price);
    }
    return 0;
  }, [shipmentRate, createdShipment, customShipment, freeShipping]);

  const handleCustomShipmentInputChange = e => {
    setCustomShipment({ ...customShipment, [e.target.name]: e.target.value });
  };

  const handleCustomShipmentSelectChange = name => e => {
    setCustomShipment({
      ...customShipment,
      brand_shipping_company_id: e.value,
      [name]: e,
    });
  };

  return (
    <>
      {pending ? (
        <Preloader
          className={cx(styles.preloader, {
            [styles.preloaderWhite]: withWhitePreloader,
          })}
        />
      ) : (
        <Row>
          <Col md="12">
            <FormGroup>
              {createdShipment && (
                <div className="d-flex align-items-center mb-2">
                  <input
                    id="current"
                    name="current"
                    type="radio"
                    value="current"
                    checked={shipmentRate === 'current'}
                    onChange={() => setShipmentRate('current')}
                  />
                  <label
                    className="ml-2 mb-0"
                    htmlFor="current"
                    style={{ fontWeight: 'normal' }}
                  >
                    <span className="text-dark font-weight-bold">
                      ${formatPrice(Number(createdShipment.price))}
                    </span>
                    <span className="ml-2 font-weight-bold">
                      Current shipping:{' '}
                      {createdShipment.brand_shipping_company_title}
                    </span>
                  </label>
                </div>
              )}
              <div className="d-flex align-items-center mb-2">
                <input
                  id="no_shipping"
                  name="no_shipping"
                  type="radio"
                  value={null}
                  checked={shipmentRate === null}
                  onChange={() => setShipmentRate(null)}
                />
                <label
                  className="ml-2 mb-0"
                  htmlFor="no_shipping"
                  style={{ fontWeight: 'normal' }}
                >
                  <span>Pickup</span>
                </label>
              </div>
              {shipmentRates?.rates?.map(s => (
                <div key={s.id} className="d-flex align-items-center mb-2">
                  <input
                    id={s.id}
                    name={s.id}
                    type="radio"
                    value={s.id}
                    checked={shipmentRate?.local_id === s.local_id}
                    onChange={() => setShipmentRate({ ...s, type: 'Easypost' })}
                  />
                  <label
                    className="ml-2 mb-0"
                    htmlFor={s.id}
                    style={{ fontWeight: 'normal' }}
                  >
                    <span className="text-dark font-weight-bold">
                      ${formatPrice(s.price)}
                    </span>
                    <span className="ml-2">
                      {s.carrier} {s.service} {s.delivery_days} days shipping
                    </span>
                  </label>
                </div>
              ))}

              <div className="d-flex align-items-center">
                <input
                  id="custom"
                  name="custom"
                  type="radio"
                  value="custom"
                  checked={shipmentRate === 'custom'}
                  onChange={() => {
                    setShipmentRate('custom');
                  }}
                />
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="company">Company</Label>
                    <CompanyPaginatedField
                      name="company"
                      value={customShipment.company}
                      onChange={handleCustomShipmentSelectChange('company')}
                      isMulti={false}
                      brandId={order?.brand?.id || ''}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="price">Price</Label>
                    <Input
                      id="price"
                      name="price"
                      type="number"
                      value={customShipment.price}
                      onFocus={e =>
                        e.target.addEventListener(
                          'wheel',
                          function(evt) {
                            evt.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onChange={handleCustomShipmentInputChange}
                    />
                  </FormGroup>
                </Col>
              </div>
              {!!shipmentRate && !isOnlyShipping && (
                <label className="d-flex align-items-center mb-4">
                  <input
                    id="free_shipping"
                    name="free_shipping"
                    type="checkbox"
                    checked={freeShipping}
                    onChange={event => setFreeShipping(event.target.checked)}
                  />
                  <Label
                    className="ml-2 mb-0"
                    style={{ fontWeight: 'normal' }}
                    htmlFor="free_shipping"
                  >
                    Don&apos;t charge shipping to customer
                  </Label>
                </label>
              )}
              {withTotal && (
                <div className={styles.totalPrice}>
                  <h6>Total:</h6> $
                  {formatPrice(
                    order?.total_price -
                      (order?.shipment_price || 0) +
                      (getShipmentPrice() || 0)
                  )}
                </div>
              )}
              <Button
                color="primary"
                onClick={handleShippingButtonClick}
                disabled={ratesCreating}
              >
                {buttonTitle}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
};

Shipments.propTypes = {
  order: PropTypes.object,
  buttonTitle: PropTypes.string,
  nextAction: PropTypes.func,
  withTotal: PropTypes.bool,
  isOnlyShipping: PropTypes.bool,
  withWhitePreloader: PropTypes.bool,
};

Shipments.defaultProps = {
  isOnlyShipping: false,
};

export default Shipments;
