import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PinInput from 'react-pin-input';
import PopupBase from '../PopupBase';
import styles from './GrantManagerCodePopup.scss';

const GrantManagerCodePopup = ({
  onClose,
  setPin,
  error,
  handlePrivileges,
}) => {
  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <ModalHeader toggle={onClose}>Enter manager code</ModalHeader>
      <ModalBody>
        <PinInput
          length={4}
          initialValue=""
          onChange={value => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          style={{ display: 'flex', justifyContent: 'center' }}
          inputStyle={{
            padding: '0',
            margin: '0 2px',
            textAlign: 'center',
            border: '2px solid rgb(225 227 241);',
            background: 'transparent',
            width: '50px',
            height: '70px',
            borderRadius: '7px',
            marginRight: '10px',
          }}
          inputFocusStyle={{
            border: '2px solid rgb(0, 123, 255)',
          }}
        />
        {error && <span className="text-danger">{error}</span>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handlePrivileges}>
          Grant
        </Button>
      </ModalFooter>
    </PopupBase>
  );
};

GrantManagerCodePopup.propTypes = {
  onClose: PropTypes.func,
  setPin: PropTypes.func,
  error: PropTypes.string,
  handlePrivileges: PropTypes.func,
};

export default memo(GrantManagerCodePopup);
