import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Input } from 'reactstrap';
import { actions as transfersActions } from 'models/transfers/slice';
import useAction from 'hooks/useAction';
import classNames from 'classnames';
import styles from './InventoriesTable.scss';

const Subhead = () => (
  <div className={styles.subhead}>
    <div className={styles.subheadCol}>START</div>
    <div className={styles.subheadCol}>CLOSE</div>
  </div>
);

const InventoriesTable = ({ inventories, className }) => {
  const setQuantity = useAction(transfersActions.setQuantity);
  const [countValues, setCountValues] = useState(
    inventories.reduce((acc, item) => {
      acc[item?.id] = {
        bottleCount: 0,
        caseCount: 0,
        palletCount: 0,
      };
      return acc;
    }, {})
  );

  const isChangedRow = item => {
    return item?.quantity !== 0;
  };

  const calculateQuantity = (
    bottleCount,
    caseCount,
    palletCount,
    bottlesInCase,
    casesInPallet
  ) => {
    const totalBottles =
      bottleCount +
      caseCount * bottlesInCase +
      palletCount * bottlesInCase * casesInPallet;

    return totalBottles;
  };

  const handleCountChange = (itemId, field, value) => {
    setCountValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        [itemId]: {
          ...prevValues[itemId],
          [field]: value,
        },
      };

      const wineItem = inventories.find(item => item.id === itemId);
      if (wineItem && wineItem.resourcetype === 'Wine') {
        const newBottleCount = updatedValues[itemId].bottleCount || 0;
        const newCaseCount = updatedValues[itemId].caseCount || 0;
        const newPalletCount = updatedValues[itemId].palletCount || 0;
        const bottlesInCase = wineItem?.bottles_in_case || 0;
        const casesInPallet = wineItem?.cases_in_pallet || 0;
        const calculatedQuantity = calculateQuantity(
          newBottleCount,
          newCaseCount,
          newPalletCount,
          bottlesInCase,
          casesInPallet
        );

        setQuantity({
          id: itemId,
          value: calculatedQuantity,
        });
      }

      return updatedValues;
    });
  };

  return (
    <Card className={className}>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className={classNames(styles.head)} data-priority="1">
                SKU
              </th>
              <th data-priority="1">Title</th>
              <th
                className={classNames(styles.head, styles.headWithSubhead)}
                data-priority="2"
              >
                Sender Inventory
                <Subhead />
              </th>
              <th className={classNames(styles.head)} data-priority="1">
                Quantity
              </th>
              <th className={classNames(styles.head)} data-priority="1">
                Bottle Count
              </th>
              <th className={classNames(styles.head)} data-priority="1">
                Case Count
              </th>
              <th className={classNames(styles.head)} data-priority="1">
                Pallet Count
              </th>
              <th
                className={classNames(styles.head, styles.headWithSubhead)}
                data-priority="2"
              >
                Receiver Inventory
                <Subhead />
              </th>
            </tr>
          </thead>
          <tbody>
            {inventories.map(item => (
              <tr key={item.id}>
                <td>{item?.sku}</td>
                <td>
                  {item.title} {!!item.vintage && `(${item.vintage})`}
                </td>
                <td>
                  <div className={styles.subContentContainer}>
                    <p className={styles.subContent}>
                      {item.inventories?.[0]?.product_count}
                    </p>
                    <p className={styles.subContent}>
                      {isChangedRow(item)
                        ? item.inventories?.[0]?.product_count - item.quantity
                        : '-'}
                    </p>
                  </div>
                </td>
                <td
                  className={classNames(
                    item.resourcetype === 'Wine' ? styles.emptyCell : ''
                  )}
                >
                  {item.resourcetype !== 'Wine' && (
                    <Input
                      type="number"
                      invalid={item.isError}
                      className={classNames(
                        'form-control',
                        styles.disableInput
                      )}
                      value={item.quantity || ''}
                      id={`quantity_${item.id}`}
                      name={`quantity_${item.id}`}
                      max={item.inventories?.[0]?.product_count}
                      onFocus={e =>
                        e.target.addEventListener(
                          'wheel',
                          function(evt) {
                            evt.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onChange={evt =>
                        setQuantity({
                          id: item?.id,
                          value: Number(evt.target.value),
                        })
                      }
                      disabled={item.resourcetype === 'Wine'}
                    />
                  )}
                </td>
                <td
                  className={
                    item.resourcetype !== 'Wine' ? styles.emptyCell : ''
                  }
                >
                  {item.resourcetype === 'Wine' && (
                    <Input
                      type="number"
                      invalid={item.isError}
                      className={classNames(
                        'form-control',
                        styles.disableInput
                      )}
                      value={countValues[item?.id]?.bottleCount || ''}
                      id={`bottleCount_${item?.id}`}
                      name={`bottleCount_${item?.id}`}
                      max={item.inventories?.[0]?.product_count}
                      onFocus={e =>
                        e.target.addEventListener(
                          'wheel',
                          function(evt) {
                            evt.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onChange={evt =>
                        handleCountChange(
                          item.id,
                          'bottleCount',
                          Number(evt.target.value)
                        )
                      }
                      disabled={item.resourcetype !== 'Wine'}
                    />
                  )}
                </td>
                <td
                  className={
                    item.resourcetype !== 'Wine' ? styles.emptyCell : ''
                  }
                >
                  {item.resourcetype === 'Wine' && (
                    <Input
                      type="number"
                      invalid={item.isError}
                      className={classNames(
                        'form-control',
                        styles.disableInput
                      )}
                      value={countValues[item.id]?.caseCount || ''}
                      id={`caseCount_${item.id}`}
                      name={`caseCount_${item.id}`}
                      max={item.inventories?.[0]?.product_count}
                      onFocus={e =>
                        e.target.addEventListener(
                          'wheel',
                          function(evt) {
                            evt.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onChange={evt =>
                        handleCountChange(
                          item.id,
                          'caseCount',
                          Number(evt.target.value)
                        )
                      }
                      disabled={item.resourcetype !== 'Wine'}
                    />
                  )}
                </td>
                <td
                  className={
                    item.resourcetype !== 'Wine' ? styles.emptyCell : ''
                  }
                >
                  {item.resourcetype === 'Wine' && (
                    <Input
                      type="number"
                      invalid={item.isError}
                      className={classNames(
                        'form-control',
                        styles.disableInput
                      )}
                      value={countValues[item.id]?.palletCount || ''}
                      id={`palletCount_${item.id}`}
                      name={`palletCount_${item.id}`}
                      max={item.inventories?.[0]?.pallet_count}
                      onFocus={e =>
                        e.target.addEventListener(
                          'wheel',
                          function(evt) {
                            evt.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onChange={evt =>
                        handleCountChange(
                          item.id,
                          'palletCount',
                          Number(evt.target.value)
                        )
                      }
                      disabled={item.resourcetype !== 'Wine'}
                    />
                  )}
                </td>
                <td>
                  <div className={styles.subContentContainer}>
                    <p className={styles.subContent}>
                      {item.inventories?.[1]?.product_count}
                    </p>
                    &nbsp;
                    <p className={styles.subContent}>
                      {isChangedRow(item)
                        ? item.inventories?.[1].product_count + item.quantity
                        : '-'}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

InventoriesTable.propTypes = {
  className: PropTypes.string,
  inventories: PropTypes.array,
};

export default InventoriesTable;
