import React from 'react';
import styles from './DataTable.scss';
import PropTypes from 'prop-types';
import SortArrows from 'components/SortArrows';
import { formatDate } from 'utils/formatDate';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import handleSort from 'utils/handleSort';
import { getPersonLabel } from '../../../utils/getPersonLabel';
import CommissionsActionDropdown from './CommissionsActionDropdown';

const SortableHeading = ({ onClick, sortDirection, isActive, children }) => {
  return (
    <th className="text-nowrap" data-priority="3" onClick={onClick}>
      {children}
      <SortArrows isActive={isActive} sortDirection={sortDirection} />
    </th>
  );
};

const CellLink = ({ id, children }) => {
  return (
    <td>
      <Link
        className="text-dark font-weight-bold"
        to={`/reports/commissions/${id}`}
      >
        {children}
      </Link>
    </td>
  );
};

const DataTable = ({
  data,
  isPending,
  invoiceDisabled,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  onDownload,
  onDownloadExcel,
}) => {
  const onSortClickHandler = key => {
    handleSort(key, setSortField, setSortDirection, sortDirection);
  };

  return (
    <>
      <thead>
        <tr>
          <th>Report ID</th>
          <SortableHeading
            onClick={() => onSortClickHandler('created_at')}
            sortDirection={sortDirection}
            isActive={sortField === 'created_at'}
          >
            Created at
          </SortableHeading>
          <th>Sales Rep</th>
          <th>Period Start</th>
          <SortableHeading
            onClick={() => onSortClickHandler('date_range_end')}
            sortDirection={sortDirection}
            isActive={sortField === 'date_range_end'}
          >
            Period End
          </SortableHeading>
          <th>Commission</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className={classNames(isPending && styles.pending)}>
        {data.map(item => (
          <tr key={item.id}>
            <CellLink id={item.id}>{item?.id ?? '-'}</CellLink>
            <CellLink id={item.id}>
              {formatDate(item?.created_at, 'yyyy-MM-dd')}
            </CellLink>
            <CellLink id={item.id}>
              {getPersonLabel(item, 'sales_rep')}
            </CellLink>
            <CellLink id={item.id}>
              {formatDate(item?.period_start, 'yyyy-MM-dd')}
            </CellLink>
            <CellLink id={item.id}>
              {formatDate(item?.period_end, 'yyyy-MM-dd')}
            </CellLink>
            <CellLink id={item.id}>${item?.commissions_total}</CellLink>
            <CellLink id={item.id}>{item?.status}</CellLink>
            <td>
              <Link id={item.id}>
                <CommissionsActionDropdown
                  onDownload={() =>
                    onDownload?.(
                      item.id,
                      getPersonLabel(item, 'sales_rep'),
                      formatDate(item?.period_start, 'yyyy-MM-dd'),
                      formatDate(item?.period_end, 'yyyy-MM-dd')
                    )
                  }
                  isDisabled={invoiceDisabled}
                  onDownloadExcel={() =>
                    onDownloadExcel?.(
                      item.id,
                      getPersonLabel(item, 'sales_rep'),
                      formatDate(item?.period_start, 'yyyy-MM-dd'),
                      formatDate(item?.period_end, 'yyyy-MM-dd')
                    )
                  }
                />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

CellLink.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isPending: PropTypes.bool,
  invoiceDisabled: PropTypes.bool,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  onDownload: PropTypes.func,
  onDownloadExcel: PropTypes.func,
};

SortableHeading.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  sortDirection: PropTypes.string,
  isActive: PropTypes.bool,
};

export default DataTable;
