import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import NavLinkRenderer from 'components/NavLinkRenderer/NavLinkRenderer';
import { sidebarLinks } from './sidebarLinks';
import useSelector from 'hooks/useSelector';
import { rolesSelector, settingsSelector } from 'models/user/selectors';

const SidebarContent = ({ isCondensed, location }) => {
  const settings = useSelector(settingsSelector);
  const roles = useSelector(rolesSelector);
  const menuRef = useRef(null);

  useEffect(() => {
    if (roles.length > 0) {
      // TODO: it is worth using another library for the sidebar, MetisMenu is quite buggy and inconvenient for Reaсt applications
      if (menuRef.current) {
        menuRef.current.dispose();
      }
      menuRef.current = new MetisMenu('#side-menu', { toggle: true });
    }
  }, [settings, roles, isCondensed, location.pathname]);

  return (
    <>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu</li>
          {roles.length > 0 &&
            sidebarLinks.map((item, id) => (
              <NavLinkRenderer
                key={id + item.title}
                node={item}
                roles={roles}
                settings={settings}
              />
            ))}
        </ul>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  isCondensed: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(SidebarContent);
