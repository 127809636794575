import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const discountCodesSlice = createSlice({
  name: 'discountCodes',
  initialState: {
    isPending: false,
    discounts: [],
    code: {},
    pagination: {},
  },
  reducers: {
    resetCode: state => {
      state.Code = null;
    },
    fetchDiscounts: state => {
      state.isPending = true;
    },
    fetchDiscountsSuccess(state, { payload }) {
      state.isPending = false;
      state.discounts = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchDiscountsFailure: state => {
      state.isPending = false;
    },
    fetchCode: state => {
      state.isPending = true;
    },
    fetchCodeSuccess(state, { payload }) {
      state.isPending = false;
      state.code = payload.data;
    },
    fetchCodeFailure: state => {
      state.isPending = false;
    },
    createCode: state => {
      state.isPending = true;
    },
    createCodeSuccess(state, { payload }) {
      state.code = payload.data;
      state.isPending = false;
    },
    createCodeFailure: state => {
      state.isPending = false;
    },
    updateCode: state => {
      state.isPending = true;
    },
    updateCodeSuccess(state, { payload }) {
      state.discounts = state.discounts.map(item => {
        if (item.id === payload.inResponseTo.id) {
          return payload.data;
        }
        if (item.id === payload.data.id) {
          return payload.data;
        }
        return item;
      });
      state.code = payload.data;
      state.isPending = false;
    },
    updateCodeFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(discountCodesSlice.actions);

export default discountCodesSlice.reducer;
