import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import useQuery from 'hooks/useQuery';
import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';
import DataTable from './DataTable';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as reportsActions } from 'models/reports/slice';
import {
  exportHistorySelector,
  isExportHistoryPendingSelector,
  exportHistoryPaginationSelector,
} from 'models/orders/selectors';

const ReportsHistory = ({ title }) => {
  const query = useQuery();
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const pagination = useSelector(exportHistoryPaginationSelector);
  const isPending = useSelector(isExportHistoryPendingSelector);
  const exportHistory = useSelector(exportHistorySelector);
  const fetchExportHistory = useAction(ordersActions.fetchExportHistory);
  const downloadReportsFiles = useAction(reportsActions.downloadReportsFiles);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
  }, []);

  useEffect(() => {
    fetchExportHistory({
      page: currentPage,
      set_querty: true,
      ...filters,
    });
  }, [filters, fetchExportHistory, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const onDownloadClickHandler = data => {
    downloadReportsFiles({ data });
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasPerPageFilter
                    hasBrandsFilter
                    setCurrentPage={setCurrentPage}
                    disabled={isPending}
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  exportHistory={exportHistory}
                  onDownloadClick={onDownloadClickHandler}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ReportsHistory.propTypes = {
  title: PropTypes.string,
};

export default ReportsHistory;
