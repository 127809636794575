import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import formatPrice from 'utils/formatPrice';
import { HUMAN_ORDER_STATUSES, ORDER_TYPES_ITEMS } from 'constants';
import { format } from 'date-fns';
import PaginationTable from 'components/PaginationTable';
import styles from './Orders.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Orders = ({ orders, setCurrentPage, pagination }) => {
  return (
    <Card>
      <CardBody>
        <h3 className={classNames(styles.title, 'mb-3')}>Orders</h3>
        <PaginationTable
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        >
          <thead>
            <tr>
              <th data-priority="1" className="text-nowrap">
                Invoice #
              </th>
              <th data-priority="4">Created at</th>
              <th data-priority="4">Paid at</th>
              <th data-priority="2">Transaction Total</th>
              <th data-priority="2">Taxes</th>
              <th data-priority="2">Tip</th>
              <th data-priority="2">Bottle Deposit</th>
              <th data-priority="2">Discount Total</th>
              <th data-priority="2">Discount %</th>
              <th data-priority="3">Brand</th>
              <th data-priority="3">Store</th>
              <th data-priority="3">Wine Associate</th>
              <th data-priority="3">Order Type</th>
              <th data-priority="3">Order Status</th>
              <th data-priority="3">Customer</th>
            </tr>
          </thead>
          <tbody>
            {(orders || [])?.map(order => (
              <tr>
                <td>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {order?.invoice_number || '-'}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {format(
                      Date.parse(order?.created_at),
                      'yyyy-MM-dd, h:mm aaa'
                    )}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {format(Date.parse(order?.paid_at), 'yyyy-MM-dd, h:mm aaa')}
                  </Link>
                </td>
                <td className={styles.cellBig}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {formatPrice(
                      Number(order?.total_price),
                      order?.total_price_currency
                    )}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {formatPrice(Number(order?.fee), order?.fee_currency)}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {formatPrice(
                      Number(order?.tips_amount),
                      order?.tips_amount_currency
                    )}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {formatPrice(
                      Number(order?.bottle_deposit),
                      order?.total_price_currency
                    )}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {formatPrice(
                      Number(order?.discount_total),
                      order?.total_price_currency
                    )}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >{`${Number(order?.percentage_discount) || 0}%`}</Link>
                </td>
                <td className={styles.cellBig}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {order?.brand || '-'}
                  </Link>
                </td>
                <td className={styles.cellBig}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {order?.store || '-'}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {order?.wine_associate || '-'}
                  </Link>
                </td>
                <td className={styles.cellBig}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {ORDER_TYPES_ITEMS[order?.resourcetype]}
                  </Link>
                </td>
                <td className={styles.cell}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {HUMAN_ORDER_STATUSES[order?.status]}
                  </Link>
                </td>
                <td className={styles.cellBig}>
                  <Link
                    to={`/orders/${order.id}`}
                    className="text-dark font-weight-bold"
                  >
                    {order?.customer || '-'}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </PaginationTable>
      </CardBody>
    </Card>
  );
};

Orders.propTypes = {
  orders: PropTypes.array,
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
};

export default Orders;
