import { createSelector } from 'reselect';

export const rootSelector = state => state.acquirers;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const acquirersSelector = createSelector(
  rootSelector,
  ({ acquirers }) => acquirers
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const acquirerSelector = createSelector(
  rootSelector,
  ({ acquirer }) => acquirer
);
