import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import styles from './LocationSearchInput.scss';
import classnames from 'classnames';
import { getParsedAddress } from 'utils/getParsedAddress';
import { Row, Col, Label, Button, Collapse } from 'reactstrap';
import { COUNTRIES } from './constants';

const LocationSearchInput = ({
  title,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  error,
  isRequired,
  disabled,
  ...props
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['ca', 'us'] },
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    if (value === '' && initialAddress !== '') {
      setValue(initialAddress);
    }
    clearSuggestions();
  });

  const [isTouched, setIsTouched] = useState(false);
  const [isCustomFormOpened, setIsCustomFormOpened] = useState(true);
  const [initialAddress, setInitialAddress] = useState('');
  const [postalCodeError, setPostalCodeError] = useState(null);

  useEffect(() => {
    // Set the initial address on component mount or when address props change
    setInitialAddress(
      [line2, line1, city, state, postalCode, country]
        .filter(Boolean)
        .join(', ')
    );
  }, [country, state, postalCode, city, line1, line2]);

  const resetAddressValues = () => {
    setCountry(undefined);
    setState(undefined);
    setPostalCode(undefined);
    setCity(undefined);
    setLine1(undefined);
    setLine2(undefined);
  };

  const parseAddress = address => {
    const parsedAddress = getParsedAddress(address);
    setCountry(parsedAddress?.country ?? '');
    setState(parsedAddress?.state ?? '');
    setCity(parsedAddress?.city ?? '');
    setPostalCode(parsedAddress?.postalCode ?? '');
    setLine1(parsedAddress?.line1 ?? '');
    setLine2(parsedAddress?.line2 ?? '');
  };

  const validatePostalCode = (code, selectedCountry = 'CA') => {
    if (selectedCountry === 'US') {
      return /^\d{5}(-\d{4})?$/.test(code);
    }
    return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(code);
  };

  const handlePostalCodeBlur = event => {
    const newCode = event.target.value;
    if (validatePostalCode(newCode, country)) {
      setPostalCode(newCode);
      setPostalCodeError(null);
    } else {
      setPostalCodeError('Invalid postal code format.');
    }
  };

  const handleInput = e => {
    setIsTouched(true);
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    resetAddressValues();
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => {
        parseAddress(results[0].address_components);
        setIsCustomFormOpened(true);
        setIsTouched(false);
      })
      .catch(err => {
        console.log('😱 Error: ', err);
      });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className={styles.suggestionItem}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          {main_text} {secondary_text}
        </div>
      );
    });

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      fontWeight: 400,
      fontSize: '0.9rem',
      color: '#505d69',
    }),
    singleValue: provided => ({
      ...provided,
      color: '#505d69',
    }),
    option: provided => ({
      ...provided,
      color: '#505d69',
      fontWeight: 400,
      fontSize: '0.9rem',
    }),
  };

  return (
    <div
      className={classnames(
        styles.container,
        disabled && styles.containerDisabled
      )}
    >
      <Button
        onClick={() => setIsCustomFormOpened(prev => !prev)}
        className={classnames(
          styles.toggleButton,
          isCustomFormOpened && styles.toggleButtonOpened
        )}
      />
      <label className={styles.field} ref={ref}>
        <Label required={isRequired} className={error && styles.errorText}>
          {title}
        </Label>
        <input
          {...props}
          className={classnames(
            'form-control ignore-toggle-outside',
            error && 'is-invalid'
          )}
          value={!isTouched ? initialAddress : value}
          onChange={handleInput}
          onFocus={() => setIsCustomFormOpened(true)}
          disabled={disabled || !ready}
          placeholder="Address(country, province, city, postal code)"
        />
        {status === 'OK' && (
          <div className={styles.suggestion}>{renderSuggestions()}</div>
        )}
        {!!error && <p className="text-danger mt-1">{error}</p>}
      </label>
      <Collapse isOpen={isCustomFormOpened} className={styles.form}>
        <div className={styles.fields}>
          <Row className={styles.field}>
            <Col className="col-md-6">
              <label className={styles.field}>
                <Label required={isRequired}>Country</Label>
                <Select
                  name="country"
                  options={COUNTRIES.map(target_country => ({
                    label: target_country.label,
                    value: target_country.value,
                  }))}
                  onChange={option => setCountry(option?.value)}
                  placeholder="Select Country"
                  value={
                    COUNTRIES.find(
                      target_country => target_country.value === country
                    ) || null
                  }
                  styles={customSelectStyles}
                />
              </label>
            </Col>
            <Col className="col-md-6">
              <label className={styles.field}>
                <Label required={isRequired}>Province</Label>
                <input
                  type="text"
                  className="form-control ignore-toggle-outside"
                  placeholder="Province"
                  onFocus={() => setIsCustomFormOpened(true)}
                  onChange={event => setState(event.target.value)}
                  value={state}
                  disabled={disabled}
                />
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>City</Label>
                <input
                  type="text"
                  className="form-control ignore-toggle-outside"
                  placeholder="City"
                  onFocus={() => setIsCustomFormOpened(true)}
                  onChange={event => setCity(event.target.value)}
                  value={city}
                  disabled={disabled}
                />
              </label>
            </Col>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>Postal Code</Label>
                <input
                  type="text"
                  className={classnames(
                    'form-control ignore-toggle-outside',
                    postalCodeError && 'is-invalid'
                  )}
                  placeholder="Postal Code"
                  onFocus={() => setIsCustomFormOpened(true)}
                  onBlur={handlePostalCodeBlur}
                  defaultValue={postalCode}
                  disabled={disabled}
                />
                {postalCodeError && (
                  <p className="text-danger mt-1">{postalCodeError}</p>
                )}
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>
                  Street Number (Address Line 1)
                </Label>
                <input
                  type="text"
                  className="form-control ignore-toggle-outside"
                  placeholder="Street Number"
                  onFocus={() => setIsCustomFormOpened(true)}
                  onChange={event => setLine1(event.target.value)}
                  value={line1}
                  disabled={disabled}
                />
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label>Unit</Label>
                <input
                  type="text"
                  className="form-control ignore-toggle-outside"
                  placeholder="Unit"
                  onFocus={() => setIsCustomFormOpened(true)}
                  onChange={event => setLine2(event.target.value)}
                  value={line2}
                  disabled={disabled}
                />
              </label>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

LocationSearchInput.defaultProps = {
  title: 'Address',
};

LocationSearchInput.propTypes = {
  title: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default LocationSearchInput;
