import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';

import { createRootReducer } from 'models';

import { actions } from 'src/models/mailingLists/slice';
import { saveToLocalStorage } from 'utils/localStorage';

export default (history, initialState) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [actions.fetchFileSuccess],
        },
      }),
      routerMiddleware(history),
      sagaMiddleware,
    ],
    reducer: createRootReducer(history),
    preloadedState: initialState,
    devTools: process.env.APP_ENV !== 'production',
  });

  store.runSaga = sagaMiddleware.run;
  store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage('token', state.user?.token);
  });
  store.close = () => store.dispatch(END);

  return store;
};
