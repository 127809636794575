import React from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import { disputedCommissionsCountSelector } from 'models/commissions/selectors';

const ReportsTitle = ({ title }) => {
  // Selectors
  const count = useSelector(disputedCommissionsCountSelector);

  return (
    <span>
      {title}
      {count ? <span className="badge badge-light ml-2">{count}</span> : <></>}
    </span>
  );
};

ReportsTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ReportsTitle;
