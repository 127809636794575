import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import {
  addHours,
  addDays,
  eachMonthOfInterval,
  lastDayOfMonth,
  parseISO,
  format,
} from 'date-fns';
import {
  MILLISECONDS_IN_AN_HOUR,
  DAYS_IN_BI_WEEKLY_PERIOD,
  DAYS_TO_END_OF_PERIOD,
  END_OF_DAY_HOURS,
  END_OF_DAY_MINUTES,
  END_OF_DAY_SECONDS,
  END_OF_DAY_MILLISECONDS,
  START_OF_DAY_HOURS,
  START_OF_DAY_MINUTES,
  START_OF_DAY_SECONDS,
  START_OF_DAY_MILLISECONDS,
} from 'constants/time';

const offset = new Date().getTimezoneOffset() / 60;
const hours_in_day = 23.999;

const periodColors = [
  ['#BEDAE3', '#C4E9DA', '#FED5CF'],
  ['#F1B598', '#D3C7E6', '#BEDAE3'],
  ['#C4E9DA', '#FED5CF', '#BEDAE3'],
  ['#C4E9DA', '#FED5CF', '#F1B598'],
  ['#D3C7E6', '#BEDAE3', '#C4E9DA'],
  ['#FED5CF', '#BEDAE3', '#C4E9DA'],
  ['#FED5CF', '#F1B598', '#D3C7E6'],
  ['#BEDAE3', '#C4E9DA', '#FED5CF'],
  ['#F1B598', '#D3C7E6', '#BEDAE3'],
  ['#C4E9DA', '#FED5CF', '#BEDAE3'],
  ['#C4E9DA', '#FED5CF', '#F1B598'],
  ['#D3C7E6', '#BEDAE3', '#C4E9DA'],
];

// Groups time entries by date
export const getTimeEntryByDateDetails = timeEntry => {
  if (!timeEntry) return [];

  // Create a temporary object to group entries by date
  const tempAccumulator = timeEntry.reduce((acc, value) => {
    // Convert UTC date string to Date object
    const dateObject = parseISO(value.started_at);
    // Format the date object to a string in local time (e.g., 'yyyy-MM-dd')
    const localDate = format(dateObject, 'EEE, MMMM dd');
    if (!acc[localDate]) {
      acc[localDate] = [];
    }
    acc[localDate].push(value);
    return acc;
  }, {});

  // Convert the temporary object to an array of objects, each containing a date and its associated entries
  const resultArray = Object.keys(tempAccumulator).map(date => ({
    date,
    entries: tempAccumulator[date],
  }));

  return resultArray;
};

export const getTimeEntryByDate = timeEntry =>
  timeEntry &&
  timeEntry?.reduce((acc, value) => {
    const dateObject = parseISO(value.started_at);
    const localDate = format(dateObject, 'yyyy-MM-dd');
    if (!acc[localDate]) {
      acc[localDate] = [];
    }
    acc[localDate].push(value);
    return acc;
  }, {});

// Generates events for the number of staff working on each date
export const getStaffEvents = timeEntryByDate =>
  timeEntryByDate &&
  Object.keys(timeEntryByDate).map(date => {
    const numOfStaff = uniqBy(timeEntryByDate[date], 'user.id').length;
    return {
      start: addHours(new Date(date), 9),
      end: addHours(new Date(date), 10),
      title: `${numOfStaff} Staff`,
      status: 'STAFF',
      num: numOfStaff,
    };
  });

// Generates events for overtime occurrences on each date
export const getOverTimeEvents = timeEntryByDate =>
  timeEntryByDate &&
  Object.keys(timeEntryByDate).map(date => {
    const entriesByStaff = groupBy(timeEntryByDate[date], 'user.id');
    // Sum multiple entries by the same staff for the same day to determine overtime
    const numOfOvertime = Object.keys(entriesByStaff).filter(
      staff => calculateOvertimeHoursWorked(entriesByStaff[staff]) > 0
    ).length;
    return (
      numOfOvertime > 0 && {
        start: addHours(new Date(date), 10),
        end: addHours(new Date(date), 11),
        title: `${numOfOvertime} Overtime`,
        status: 'DANGER',
        num: numOfOvertime,
      }
    );
  });

// Generates events for late entries on each date
export const getLateEntryEvents = timeEntryByDate =>
  timeEntryByDate &&
  Object.keys(timeEntryByDate).map(date => {
    const numOfLateEntry = timeEntryByDate[date].filter(
      entry =>
        entry.ended_at &&
        new Date(entry.started_at).getDate() !==
          new Date(entry.ended_at).getDate()
    ).length;
    return (
      numOfLateEntry > 0 && {
        start: addHours(new Date(date), 11),
        end: addHours(new Date(date), 12),
        title: `${numOfLateEntry} Late Entry`,
        status: 'INFO',
        num: numOfLateEntry,
      }
    );
  });

// Generates events for incomplete entries on each date
export const getIncompleteEvents = timeEntryByDate =>
  timeEntryByDate &&
  Object.keys(timeEntryByDate).map(date => {
    const numOfIncomplete = timeEntryByDate[date].filter(
      entry => entry.started_at && !entry.ended_at
    ).length;
    return (
      numOfIncomplete > 0 && {
        start: addHours(new Date(date), 12),
        end: addHours(new Date(date), 13),
        title: `${numOfIncomplete} Incomplete`,
        status: 'INFO',
        num: numOfIncomplete,
      }
    );
  });

// Merges various types of events and periods into a single array
export const getTimeEntryEvents = (
  staffEvents,
  overtimeEvents,
  lateEntryEvents,
  incompleteEvents,
  periods
) =>
  staffEvents && overtimeEvents && lateEntryEvents && incompleteEvents
    ? [
        ...staffEvents,
        ...overtimeEvents,
        ...lateEntryEvents,
        ...incompleteEvents,
        ...periods,
      ]
    : [...periods];

// Generates a summary of events for each active pay period
export const getActivePeriodEvents = (
  activePeriods,
  timeEntryByDate,
  overtimeEvents,
  lateEntryEvents,
  incompleteEvents
) =>
  overtimeEvents &&
  lateEntryEvents &&
  incompleteEvents &&
  activePeriods.reduce((acc, activePeriod) => {
    const periodStart = activePeriod.start.toISOString().split('T')[0];

    const numOfOvertime = overtimeEvents.reduce(
      (otAcc, event) =>
        event.start > activePeriod.start && event.start < activePeriod.end
          ? otAcc + event.num
          : otAcc,
      0
    );

    const numOfLateEntry = lateEntryEvents.reduce(
      (otAcc, event) =>
        event.start > activePeriod.start && event.start < activePeriod.end
          ? otAcc + event.num
          : otAcc,
      0
    );

    const staffs = Object.keys(timeEntryByDate)
      .filter(date => {
        const entryDate = addHours(new Date(date), offset);
        const isWithinPeriod =
          entryDate >= new Date(activePeriod.start) &&
          entryDate < new Date(activePeriod.end);

        return isWithinPeriod;
      })
      .map(date => {
        return timeEntryByDate[date].map(entry => {
          return entry.user;
        });
      })
      .flat();

    const uniqueStaff = uniqBy(staffs, user => user.id);

    const numOfStaff = uniqueStaff.length;

    const numOfIncomplete = timeEntryByDate[periodStart]
      ? timeEntryByDate[periodStart].filter(entry => !entry.ended_at).length
      : 0;

    const formattedStart = activePeriod.start.toISOString().split('T')[0];
    const formattedEnd = activePeriod.end.toISOString().split('T')[0];
    const periodEntries = Object.keys(timeEntryByDate)
      .filter(date => {
        return date >= formattedStart && date < formattedEnd;
      })
      .flatMap(date => timeEntryByDate[date]);

    // Group entries by user.id.
    const entriesByUser = periodEntries.reduce((accu, entry) => {
      (accu[entry.user.id] = accu[entry.user.id] || []).push(entry);
      return accu;
    }, {});

    // Check if every user has at least one MANAGER_APPROVED entry.
    // If there are no entries for any user, set approved to false
    let approved = false;
    if (Object.keys(entriesByUser).length > 0) {
      // Check if every user has every entry with 'MANAGER_APPROVED' status
      approved = Object.values(entriesByUser).every(userEntries =>
        userEntries.every(entry => entry.status === 'MANAGER_APPROVED')
      );
    }

    if (!acc[periodStart]) {
      acc[periodStart] = [];
    }
    acc[periodStart].push({
      numOfOvertime,
      numOfLateEntry,
      numOfIncomplete,
      numOfStaff,
      approved,
    });
    return acc;
  }, {});

// Generates periods for a semi-monthly pay schedule
export const getPeriodsByDateSemiMonthly = (startDate, endDate) => {
  const periods = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).flatMap(firstOfMonth => {
    const shortMonthName = firstOfMonth.toLocaleString('default', {
      month: 'short',
    });
    return [
      {
        start: firstOfMonth,
        end: addHours(
          new Date(
            firstOfMonth.getFullYear(),
            firstOfMonth.getMonth(),
            DAYS_IN_BI_WEEKLY_PERIOD
          ),
          hours_in_day
        ),
        title: `${shortMonthName} 1 - ${shortMonthName} 14`,
        status: 'PERIOD',
        color: periodColors[firstOfMonth.getMonth()][0],
      },
      {
        start: new Date(
          firstOfMonth.getFullYear(),
          firstOfMonth.getMonth(),
          15
        ),
        end: addHours(
          new Date(
            firstOfMonth.getFullYear(),
            firstOfMonth.getMonth(),
            lastDayOfMonth(firstOfMonth).getDate()
          ),
          hours_in_day
        ),
        title: `${shortMonthName} 15 - ${shortMonthName} ${lastDayOfMonth(
          firstOfMonth
        ).getDate()}`,
        status: 'PERIOD',
        color: periodColors[firstOfMonth.getMonth()][1],
      },
    ];
  });

  return periods;
};

// Generates periods for a bi-weekly pay schedule
export const getPeriodsByDateBiWeekly = (
  startDate,
  endDate,
  payPeriodStart
) => {
  const payPeriodStartDate = new Date(payPeriodStart);
  let currentPeriodStart = payPeriodStartDate;

  // Adjust currentPeriodStart to align with the startDate
  while (currentPeriodStart > startDate) {
    currentPeriodStart = addDays(currentPeriodStart, -DAYS_IN_BI_WEEKLY_PERIOD);
  }

  const periods = [];

  // Generate past periods if needed
  let pastPeriodStart = currentPeriodStart;
  while (pastPeriodStart > startDate) {
    pastPeriodStart = addDays(pastPeriodStart, -DAYS_IN_BI_WEEKLY_PERIOD);
    const pastPeriodEnd = addDays(pastPeriodStart, DAYS_TO_END_OF_PERIOD);
    const periodEndAdjusted = new Date(
      pastPeriodEnd.setHours(
        END_OF_DAY_HOURS,
        END_OF_DAY_MINUTES,
        END_OF_DAY_SECONDS,
        END_OF_DAY_MILLISECONDS
      )
    );
    const shortMonthNameStart = pastPeriodStart.toLocaleString('default', {
      month: 'short',
    });
    const shortMonthNameEnd = periodEndAdjusted.toLocaleString('default', {
      month: 'short',
    });

    periods.unshift({
      start: new Date(pastPeriodStart.setHours(0, 0, 0, 0)),
      end: periodEndAdjusted,
      title: `${shortMonthNameStart} ${pastPeriodStart.getDate()} - ${shortMonthNameEnd} ${periodEndAdjusted.getDate()}`,
      status: 'PERIOD',
      color: periodColors[pastPeriodStart.getMonth()][periods.length % 3],
    });
  }

  // Generate future periods within the range
  let futurePeriodStart = currentPeriodStart;
  while (futurePeriodStart < endDate) {
    const periodEnd = addDays(futurePeriodStart, DAYS_TO_END_OF_PERIOD);
    const periodEndAdjusted = new Date(
      periodEnd.setHours(
        END_OF_DAY_HOURS,
        END_OF_DAY_MINUTES,
        END_OF_DAY_SECONDS,
        END_OF_DAY_MILLISECONDS
      )
    );
    const shortMonthNameStart = futurePeriodStart.toLocaleString('default', {
      month: 'short',
    });
    const shortMonthNameEnd = periodEndAdjusted.toLocaleString('default', {
      month: 'short',
    });

    periods.push({
      start: new Date(
        futurePeriodStart.setHours(
          START_OF_DAY_HOURS,
          START_OF_DAY_MINUTES,
          START_OF_DAY_SECONDS,
          START_OF_DAY_MILLISECONDS
        )
      ),
      end: periodEndAdjusted,
      title: `${shortMonthNameStart} ${futurePeriodStart.getDate()} - ${shortMonthNameEnd} ${periodEndAdjusted.getDate()}`,
      status: 'PERIOD',
      color: periodColors[futurePeriodStart.getMonth()][periods.length % 3],
    });

    futurePeriodStart = addDays(futurePeriodStart, DAYS_IN_BI_WEEKLY_PERIOD);
  }

  return periods;
};

// Dispatches to either bi-weekly or semi-monthly period generation based on payPeriodType
export const getPeriodsByDate = (
  startDate,
  endDate,
  payPeriodType,
  payPeriodStart
) =>
  payPeriodType === 'BIWEEKLY'
    ? getPeriodsByDateBiWeekly(startDate, endDate, payPeriodStart)
    : getPeriodsByDateSemiMonthly(startDate, endDate);

// Organizes entries by user and date, sorted by start time
export const organizeEntriesByUserAndDate = entries => {
  if (!entries || !Array.isArray(entries)) {
    return [];
  }
  const usersEntries = {};

  entries.forEach(entry => {
    const userId = entry.user.id;
    if (!usersEntries[userId]) {
      usersEntries[userId] = {
        user: entry.user,
        entries: [],
      };
    }
    usersEntries[userId].entries.push(entry);
  });

  Object.keys(usersEntries).forEach(userId => {
    usersEntries[userId].entries.sort(
      (a, b) => new Date(a.started_at) - new Date(b.started_at)
    );
  });

  return Object.values(usersEntries);
};

// Calculates total hours worked from a list of time entries
export const calculateHoursWorked = entries =>
  entries.reduce((acc, entry) => {
    if (entry.ended_at) {
      const start = new Date(entry.started_at);
      const end = new Date(entry.ended_at);
      const duration = (end - start) / MILLISECONDS_IN_AN_HOUR; // Convert milliseconds to hours
      return acc + Math.max(0, duration); // Ensure that we don't add negative durations
    }
    return acc;
  }, 0);

// Calculates total overtime hours from a list of time entries
export const calculateOvertimeHoursWorked = entries => {
  if (!entries) {
    return 0;
  }
  const standardWorkdayHours = 8;
  const entriesByDate = getTimeEntryByDate(entries);
  return Object.values(entriesByDate).reduce((acc, entryGroup) => {
    if (entryGroup.every(entry => entry.ended_at)) {
      const totalHours = calculateHoursWorked(entryGroup);
      if (totalHours > standardWorkdayHours) {
        return acc + (totalHours - standardWorkdayHours);
      }
    }
    return acc;
  }, 0);
};

export const calculateHourDifference = (start, end) => {
  if (!start || !end) {
    // If either start or end time is missing, return 0 hours
    return 0;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  if (endDate < startDate) {
    // If the end date is before the start date, show error
    return 0;
  }

  const differenceInMilliseconds = endDate - startDate;
  return differenceInMilliseconds / MILLISECONDS_IN_AN_HOUR;
};

export const constructTimeValue = (hour, minute, period) => {
  // Convert hour to 24-hour format based on the period
  hour = parseInt(hour, 10);
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  } else if (period === 'AM' && hour === 12) {
    hour = 0;
  }

  // Construct a Date object with the current date but with the specified hour and minute
  const time = new Date();
  time.setHours(hour);
  time.setMinutes(minute);
  time.setSeconds(0); // Reset seconds and milliseconds to zero
  time.setMilliseconds(0);

  return time;
};

export const calculateDaySubmittedHours = dateEntries => {
  let totalSubmittedRegularHours = 0;
  let totalSubmittedOvertimeHours = 0;

  dateEntries.forEach(entry => {
    if (entry.ended_at != null) {
      const submittedHours = parseFloat(
        calculateHourDifference(entry.started_at, entry.ended_at)
      );
      const regularHours = Math.min(
        submittedHours,
        8 - totalSubmittedRegularHours
      );
      const overtimeHours = submittedHours - regularHours;

      totalSubmittedRegularHours += regularHours;
      totalSubmittedOvertimeHours += overtimeHours;
    }
  });

  return {
    daySubmittedRegularHours: totalSubmittedRegularHours,
    daySubmittedOvertimeHours: totalSubmittedOvertimeHours,
  };
};

export const calculateDayApprovedHours = dateEntries => {
  let totalApprovedRegularHours = 0;
  let totalApprovedOvertimeHours = 0;

  dateEntries.forEach(entry => {
    if (entry.status === 'MANAGER_APPROVED' && entry.approved_hours != null) {
      const approvedHours = parseFloat(entry.approved_hours);
      const regularHours = Math.min(
        approvedHours,
        8 - totalApprovedRegularHours
      );
      const overtimeHours = approvedHours - regularHours;

      totalApprovedRegularHours += regularHours;
      totalApprovedOvertimeHours += overtimeHours;
    }
  });

  return {
    dayApprovedRegularHours: totalApprovedRegularHours,
    dayApprovedOvertimeHours: totalApprovedOvertimeHours,
  };
};
