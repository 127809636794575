import { all, select, takeLatest } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { currentWorkspaceSelector } from 'models/workspaces/selectors';

export function* createWineDirectSyncSettingsSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/wine_direct/settings/`,
    data: action.payload,
    successMessage: 'Successfully created new wine direct sync settings :)',
    failureMessage: 'Failed to create new wine direct sync settings :(',
  });
}

export function* updateWineDirectSyncSettingsSaga(action) {
  yield api({
    action,
    method: 'put',
    url: `/wine_direct/settings/update/`,
    data: action.payload,
    successMessage: 'Successfully updated wine direct sync settings :)',
    failureMessage: 'Failed to update wine direct sync settings :(',
  });
}

export function* fetchWineDirectSyncSettingsSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/wine_direct/settings/${action.payload}/`,
  });
}

export function* wineDirectSyncNowSaga(action) {
  yield api({
    action,
    method: 'put',
    url: `/wine_direct/sync/`,
    data: action.payload,
    successMessage: 'A sync has been scheduled:)',
    failureMessage: 'Failed to schedule a sync :(',
  });
}

export function* fetchWineDirectSyncsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/wine_direct/syncs/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: action.payload,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchFailedWineDirectSyncsSaga(action) {
  action.payload.workspace_id = yield select(currentWorkspaceSelector);
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/wine_direct/failed_syncs/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: action.payload,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export default function*() {
  yield all([
    takeLatest(
      actions.createWineDirectSyncSettings,
      createWineDirectSyncSettingsSaga
    ),
    takeLatest(
      actions.updateWineDirectSyncSettings,
      updateWineDirectSyncSettingsSaga
    ),
    takeLatest(
      actions.fetchWineDirectSyncSettings,
      fetchWineDirectSyncSettingsSaga
    ),
    takeLatest(actions.wineDirectSyncNow, wineDirectSyncNowSaga),
    takeLatest(actions.fetchWineDirectSyncs, fetchWineDirectSyncsSaga),
    takeLatest(
      actions.fetchFailedWineDirectSyncs,
      fetchFailedWineDirectSyncsSaga
    ),
  ]);
}
