import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as queryString from 'query-string';
import { history } from 'src/client';

export function* generateURL({
  payload,
  url,
  requestURL,
  options = {},
  setQuery = false,
  setURL = true,
}) {
  const stringified = queryString.stringify(payload, options);
  const _url = `${url}?${stringified}`;
  const _requestURL = `${requestURL}?${stringified}`;

  if (setURL && history.location.pathname.includes(url) && !setQuery) {
    yield put(push(_url));
  }

  if (setURL && setQuery) {
    yield put(push(`?${stringified}`));
  }

  return { url: _url, requestURL: _requestURL };
}
