import React from 'react';
import styles from './AuthLayout.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../../Icon';

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Row className="no-gutters">
        <Col className={styles.content} md={4}>
          <div className={styles.contentWrapper}>
            <Link to="/" className="logo mb-5 d-block">
              <Icon color="black" name="logo" width="200px" />
            </Link>
            <div>{children}</div>
          </div>
        </Col>
        <Col className="p-0" md={8}>
          <div className={styles.background} />
        </Col>
      </Row>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
