import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

const DropdownItemId = Object.freeze({
  EDIT: 'edit',
});

const DropdownButton = ({ isDisabled, editHref }) => {
  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: editHref,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {};

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
    />
  );
};

DropdownButton.propTypes = {
  editHref: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default DropdownButton;
