import React, { memo } from 'react';
import { Row, Spinner } from 'reactstrap';
import BodyModal from '../BodyModal';

const Loader = () => {
  return (
    <BodyModal>
      <Row className="p-5 justify-content-center">
        <Spinner color="primary" />
        <h3 className="ml-4">Loading, please wait...</h3>
      </Row>
    </BodyModal>
  );
};

export default memo(Loader);
