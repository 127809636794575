import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';

const formatRowTitle = title => {
  return title
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};

const WineReturnShowForm = ({ data }) => {
  const {
    period_start,
    period_end,
    due_date,
    seven_percent_return_detail,
    one_percent_return_detail,
    zero_percent_return_detail,
    seven_percent_quantity,
    one_percent_quantity,
    zero_percent_quantity,
    seven_percent_duty_payable,
    one_percent_duty_payable,
    zero_percent_duty_payable,
  } = data;

  const sections = [
    {
      title: 'Opening Inventory',
      rows: ['opening_inventory'],
    },
    {
      title: 'Additions to bulk wine inventory',
      rows: [
        'wine_produced',
        'wine_received_from_wine_licensees',
        'wine_received_from_licensed_users',
        'imported_wine',
        'total_additions',
      ],
    },
    {
      title: 'Reductions from bulk wine inventory',
      rows: [
        'removed_for_delivery_to_wine_licensees',
        'removed_for_delivery_to_licensed_users',
        'exported_wine',
        'packaged',
        'packaged_exempt',
        'packaged_marked',
        'other_non_duty_paid',
        'other_duty_paid',
        'total_reductions',
      ],
    },
    {
      title: 'Inventory Adjustment',
      rows: ['inventory_adjustment'],
    },
    {
      title: 'Closing Inventory',
      rows: ['closing_inventory'],
    },
  ];

  const renderDetailSection = section => {
    return (
      <Card key={section.title}>
        <CardBody>
          <h4 className="card-title mb-4">{section.title}</h4>
          <Table bordered responsive>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>7%</th>
                <th>1%</th>
                <th>0%</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map(row => (
                <tr key={row}>
                  <th>{formatRowTitle(row)}</th>
                  <td>{seven_percent_return_detail[row]}</td>
                  <td>{one_percent_return_detail[row]}</td>
                  <td>{zero_percent_return_detail[row]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  return (
    <div>
      <Row>
        <Col md={4} xl={2} className="mb-2">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                {formatRowTitle('period_start')}
              </h4>
              <p>{period_start}</p>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} xl={2} className="mb-2">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                {formatRowTitle('period_end')}
              </h4>
              <p>{period_end}</p>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} xl={2} className="mb-2">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">{formatRowTitle('due_date')}</h4>
              <p>{due_date}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12} xl={6}>
          {sections.map(section => renderDetailSection(section))}

          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Duty Payable</h4>
              <Table bordered responsive>
                <colgroup>
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th />
                    <th>7%</th>
                    <th>1%</th>
                    <th>0%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Quantity</th>
                    <td>{seven_percent_quantity}</td>
                    <td>{one_percent_quantity}</td>
                    <td>{zero_percent_quantity}</td>
                  </tr>
                  <tr>
                    <th>Duty Payable</th>
                    <td>{seven_percent_duty_payable}</td>
                    <td>{one_percent_duty_payable}</td>
                    <td>{zero_percent_duty_payable}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

WineReturnShowForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WineReturnShowForm;
