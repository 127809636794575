import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { ORDER_TYPES, ORDER_ITEMS_TYPES, FEE_TITLE_TYPES } from 'constants';
import PriceField from '../PriceField';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './TableRow.scss';

const getTitleOption = title => ({ value: title, label: title });

const selectStyles = {
  menuList: base => ({
    ...base,
    maxHeight: 125,
  }),
};

const TableRow = ({ type, error, onChange, onRemove, value }) => {
  const handleChange = (key, newValue) => {
    onChange({
      ...value,
      [key]: newValue,
    });
  };

  const onTitleChange = item => {
    onChange({ ...value, title: item.value });
  };

  const onAliasChange = event => {
    onChange({ ...value, alias: event.target.value });
  };

  return (
    <tr className={classNames({ [styles.errorTableRow]: !!error })}>
      <td>
        <Select
          value={getTitleOption(value?.title)}
          name="title"
          options={Object.values(FEE_TITLE_TYPES).map(getTitleOption)}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={onTitleChange}
          menuPlacement="auto"
          styles={selectStyles}
        />
      </td>
      <td>
        <input
          type="text"
          id="alias-input"
          value={value?.alias}
          placeholder="Alias"
          onChange={onAliasChange}
          className="form-control"
        />
      </td>
      <td>
        <PriceField
          defaultValue={value?.amount}
          onChange={newValue => handleChange('amount', newValue)}
        />
      </td>
      <td>
        <Select
          value={value?.order_class}
          name="order_class"
          options={ORDER_TYPES}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={val => handleChange('order_class', val)}
          getOptionValue={option => option.apiValue}
          getOptionLabel={option => option.userValue}
          menuPlacement="auto"
          styles={selectStyles}
        />
      </td>
      <td>
        <Select
          value={value?.order_item_class}
          name="order_item_class"
          options={ORDER_ITEMS_TYPES[type]}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={val => handleChange('order_item_class', val)}
          getOptionValue={option => option.apiValue}
          getOptionLabel={option => option.userValue}
          isDisabled={type !== 'Wine'}
          menuPlacement="auto"
          styles={selectStyles}
        />
      </td>
      <td>
        <Button
          color="danger"
          type="button"
          className="waves-effect waves-lighttext-nowrap"
          onClick={() => onRemove(value.id)}
        >
          <i className="mdi mdi-trash-can font-size-14" />
        </Button>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  type: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  value: PropTypes.object,
};

export default TableRow;
