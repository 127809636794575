import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { normalize } from 'utils/normalizeById';

const productsPresetsSlice = createSlice({
  name: 'productsPresets',
  initialState: {
    isPending: false,
    productsPresets: {},
    pagination: {},
    productsPreset: null,
  },
  reducers: {
    fetchProductsPresets: state => {
      state.isPending = true;
    },
    fetchProductsPresetsSuccess(state, { payload }) {
      state.isPending = false;
      state.productsPresets = normalize(payload.data.results);
      state.pagination = payload.data.pagination;
    },
    fetchProductsPresetsFailure: state => {
      state.isPending = false;
    },
    fetchProductsPreset: state => {
      state.isPending = true;
    },
    fetchProductsPresetSuccess(state, { payload }) {
      state.isPending = false;
      state.productsPreset = payload.data;
    },
    fetchProductsPresetFailure: state => {
      state.isPending = false;
    },
    createProductsPreset: state => {
      state.isPending = true;
    },
    createProductsPresetSuccess(state, { payload }) {
      state.isPending = false;
      state.productsPresets[payload.data.id] = {
        ...payload.data,
      };
    },
    createProductsPresetFailure: state => {
      state.isPending = false;
    },
    deleteProductsPreset: state => {
      state.isPending = true;
    },
    deleteProductsPresetSuccess(state, { payload }) {
      state.isPending = false;
      delete state.productsPresets[payload.inResponseTo];
    },
    updateProductsPreset: state => {
      state.isPending = true;
    },
    updateProductsPresetSuccess(state, { payload }) {
      state.isPending = false;
      state.productsPresets[payload.inResponseTo] = {
        ...payload.data,
      };
    },
    updateProductsPresetFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(productsPresetsSlice.actions);

export default productsPresetsSlice.reducer;
