import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';

import TagsWrapper from '../TagsWrapper';
import TextField from '../Fields/TextField';
import NumberField from '../Fields/NumberField';
import EmailField from '../Fields/EmailField';
import FileField from '../FileField';
import PhoneField from '../Fields/PhoneField/PhoneField';

const Form = ({
  model,
  tags,
  setTags,
  submitTitle,
  onMobileLogoChange,
  onDashboardLogoChange,
  onEmailLogoChange,
  mobileLogo,
  dashboardLogo,
  emailLogo,
}) => {
  const [isRemoveDashboardLogo, setIsRemoveDashboardLogo] = useState(false);
  const [isRemoveMobileLogo, setIsRemoveMobileLogo] = useState(false);
  const [isRemoveEmailLogo, setIsRemoveEmailLogo] = useState(false);

  const handleRemoveDashboardLogo = () => {
    setIsRemoveDashboardLogo(true);
    onDashboardLogoChange(null);
  };

  const handleRemoveMobileLogo = () => {
    setIsRemoveMobileLogo(true);
    onMobileLogoChange(null);
  };

  const handleRemoveEmailLogo = () => {
    setIsRemoveEmailLogo(true);
    onEmailLogoChange(null);
  };

  return (
    <>
      <Row>
        <Col md="4">
          <TextField
            isRequired
            name="title"
            errorMessage="Enter Title"
            label="Title"
          />
        </Col>
        <Col md="4">
          <PhoneField
            name="contact_phone"
            label="Contact Phone"
            placeholder="Contact Phone"
            isRequired
          />
        </Col>
        <Col md="4">
          <TextField name="contact_email" label="Contact Email" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <TextField name="gst_number" isRequired label="GST number" />
        </Col>
        <Col md="4">
          <TextField name="pst_number" label="PST number" />
        </Col>
        <Col md="4">
          <NumberField name="bcldb_store_number" label="BCLDB store number" />
        </Col>
        <Col md="4">
          <EmailField
            name="stripe_receipt_email"
            label="Stripe Receipt Email"
          />
        </Col>
      </Row>
      {tags && (
        <Row>
          <Col md={8}>
            <TagsWrapper label="Brand Tags" data={tags} onChange={setTags} />
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col md="4">
          <FileField
            label="Mobile logo"
            name="logo"
            isRemoveImage={isRemoveMobileLogo}
            setIsRemoveImage={handleRemoveMobileLogo}
            onChange={onMobileLogoChange}
            preview={mobileLogo || model?.mobile_logo_v_200x200_url}
            accept="image/jpeg,image/png"
          />
        </Col>
        <Col md="4">
          <FileField
            label="Dashboard logo"
            name="logo"
            isRemoveImage={isRemoveDashboardLogo}
            setIsRemoveImage={handleRemoveDashboardLogo}
            onChange={onDashboardLogoChange}
            preview={dashboardLogo || model?.dashboard_logo_v_200x200_url}
            accept="image/jpeg,image/png"
          />
        </Col>
        <Col md="4">
          <FileField
            label="Email logo"
            name="logo"
            isRemoveImage={isRemoveEmailLogo}
            setIsRemoveImage={handleRemoveEmailLogo}
            onChange={onEmailLogoChange}
            preview={emailLogo || model?.email_logo_v_200x200_url}
            accept="image/jpeg,image/png"
          />
        </Col>
      </Row>
      <Button color="primary" type="submit">
        {submitTitle}
      </Button>
    </>
  );
};

Form.propTypes = {
  model: PropTypes.object,
  tags: PropTypes.array,
  submitTitle: PropTypes.string,
  onDashboardLogoChange: PropTypes.func,
  onMobileLogoChange: PropTypes.func,
  onEmailLogoChange: PropTypes.func,
  setTags: PropTypes.func,
  mobileLogo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dashboardLogo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  emailLogo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Form.defaultProps = {
  model: {},
};

export default Form;
