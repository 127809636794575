import { createSelector } from 'reselect';
import { denormalize } from 'utils/normalizeById';

export const rootSelector = state => state.products;

export const productsSelector = createSelector(rootSelector, ({ products }) =>
  denormalize(products)
);

export const productsOptionsSelector = createSelector(
  rootSelector,
  ({ productsOptions }) => productsOptions
);

export const additionsSelector = createSelector(
  rootSelector,
  ({ productsOptions }) => productsOptions.filter(option => option.addition)
);

export const pairingsSelector = createSelector(
  rootSelector,
  ({ productsOptions }) =>
    productsOptions.filter(option => option.resourcetype === 'Wine')
);

export const productSelector = createSelector(
  rootSelector,
  ({ product }) => product
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const isImagesPendingSelector = createSelector(
  rootSelector,
  ({ isImagesPending }) => isImagesPending
);

export const imageFetchingSuccessSelector = createSelector(
  rootSelector,
  ({ imageFetchingSuccess }) => imageFetchingSuccess
);

export const isCreatingSelector = createSelector(
  rootSelector,
  ({ isCreating }) => isCreating
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const foodCategoriesSelector = createSelector(
  rootSelector,
  ({ productsCategories }) =>
    productsCategories?.find(category => category.title === 'Food')
      ?.sub_categories
);

export const foodAllergiesSelector = createSelector(
  rootSelector,
  ({ foodAllergies }) => foodAllergies
);

export const foodAllergySelector = createSelector(
  rootSelector,
  ({ foodAllergy }) => foodAllergy
);

export const foodAllergyPaginationSelector = createSelector(
  rootSelector,
  ({ foodAllergyPagination }) => foodAllergyPagination
);

export const merchandiseCategoriesSelector = createSelector(
  rootSelector,
  ({ productsCategories }) =>
    productsCategories?.find(category => category.title === 'Merchandise')
      ?.sub_categories
);

export const productsCategoriesSelector = createSelector(
  rootSelector,
  ({ productsCategories }) => productsCategories
);

export const productCategorySelector = createSelector(
  rootSelector,
  ({ productCategory }) => productCategory
);

export const wineCategoriesSelector = createSelector(
  rootSelector,
  ({ productsCategories }) =>
    productsCategories?.find(category => category.title === 'Wine')
      ?.sub_categories
);

export const galleryImagesSelector = createSelector(
  rootSelector,
  ({ productImages }) => productImages.gallery
);

export const compositeImagesSelector = createSelector(
  rootSelector,
  ({ productImages }) => productImages.composite
);

export const productCountsSelector = createSelector(
  rootSelector,
  ({ productCounts }) => productCounts
);

export const productCountsPendingSelector = createSelector(
  rootSelector,
  ({ productCountsPending }) => productCountsPending
);
