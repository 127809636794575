import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

import styles from './UserConfirmation.scss';
import { checkPasswordsSame, validatePassword } from 'utils/validatePassword';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions as userActions } from 'models/user/slice';
import useSelector from 'hooks/useSelector';
import {
  confirmationPendingSelector,
  confirmationEmailSentSelector,
  tokenConfirmationErrorSelector,
} from 'models/user/selectors';
import ConfirmationSendEmailPopup from 'components/Popup/ConfirmationSendEmailPopup';
import ConfirmationEmailPopup from 'components/Popup/ConfirmationEmailPopup';
import PasswordField from 'components/Fields/PasswordField';

const UserConfirmation = ({ title }) => {
  const { token } = useParams();
  const confirmUser = useAction(userActions.confirmUser);
  const checkConfirmationToken = useAction(userActions.checkConfirmationToken);
  const sendConfirmationEmail = useAction(userActions.sendConfirmationEmail);
  const confirmationPending = useSelector(confirmationPendingSelector);
  const confirmationEmailSent = useSelector(confirmationEmailSentSelector);
  const tokenConfirmationError = useSelector(tokenConfirmationErrorSelector);

  useEffect(() => {
    checkConfirmationToken(token);
  }, [token]);

  const handleConfirmationEmail = useCallback(() => {
    sendConfirmationEmail(token);
  }, [token]);

  const handleSubmit = useCallback(
    (_, values) => {
      confirmUser({ ...values, token });
    },
    [token]
  );

  return (
    <PageWrapper title={title}>
      {(confirmationPending || tokenConfirmationError) && (
        <ConfirmationSendEmailPopup
          isPending={confirmationPending}
          handleConfirm={handleConfirmationEmail}
          errorMessage={tokenConfirmationError}
        />
      )}
      {confirmationEmailSent !== null &&
        confirmationEmailSent !== undefined && (
          <ConfirmationEmailPopup isConfirmEmailSent={confirmationEmailSent} />
        )}
      <div className={styles.container}>
        <h4 className={styles.title}>Complete host registration</h4>
        <AvForm className={styles.form} onValidSubmit={handleSubmit}>
          <PasswordField
            name="password"
            label="Password"
            icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
            className="auth-form-group-custom mb-2"
            placeholder="Enter password"
            rules={{ customValidation: validatePassword }}
            isRequired
          />
          <PasswordField
            name="password_confirmation"
            label="Password Confirmation"
            icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
            className="auth-form-group-custom mb-4"
            placeholder="Enter password"
            rules={{ customValidation: checkPasswordsSame }}
            isRequired
          />
          <Button color="primary" type="submit">
            Register
          </Button>
        </AvForm>
      </div>
    </PageWrapper>
  );
};

UserConfirmation.propTypes = {
  title: PropTypes.string,
};

export default UserConfirmation;
