import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import TableOfTransfers from './TableOfTransfers';
import styles from '../TransferForm.scss';
import TransfersPaginatedField from 'components/PaginatedFields/TransfersPaginatedField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';

const InventoryAdjustmentFields = ({
  isPending,
  transfer,
  setTransferItems,
  setTransfer,
  setReceiverID,
  setSenderID,
}) => {
  const [defaultProducts, setDefaultProducts] = useState([]);

  const handleChangeTransfer = value => {
    setTransfer(value);
    setReceiverID(value?.receiver?.id);
    setSenderID(value?.sender?.id);
    const items = value?.transfer_items?.map(item => ({
      id: item.product_id,
      is_default: true,
      current_quantity: item.quantity,
      new_quantity: 0,
      product_id: item.product_id,
      product_title: `${item.vintage} - ${item.title} - ${item.sku}`,
    }));
    setDefaultProducts(items);
  };

  return (
    <div className={cx('w-100', isPending && styles.preloader)}>
      <Row>
        <Col lg={7}>
          <FormGroup>
            <Label required htmlFor="receiver_id">
              Transfer
            </Label>
            <TransfersPaginatedField
              menuPlacement="top"
              options={{
                sort_direction: 'desc',
                sort_field: 'created_at',
                is_adjustment_transfer: false,
                type: [
                  'ArrivalTransfer',
                  'WriteOffTransfer',
                  'WarehouseToWarehouseTransfer',
                ],
              }}
              onChange={handleChangeTransfer}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {!!transfer?.sender && (
          <Col lg="4">
            <FormGroup>
              <Label required htmlFor="sender_id">
                Sending Warehouse
              </Label>
              <WarehousesPaginatedField
                key="select-1"
                menuPlacement="top"
                placeholder="Sending Warehouse"
                value={{
                  value: transfer.sender.id,
                  label: transfer.sender.title,
                }}
                isDisabled
              />
            </FormGroup>
          </Col>
        )}
        {transfer?.receiver && (
          <Col lg="4">
            <FormGroup>
              <Label required htmlFor="receiver_id">
                Receiving Warehouse
              </Label>
              <WarehousesPaginatedField
                key="select-2"
                menuPlacement="top"
                placeholder="Receiving Warehouse"
                value={{
                  value: transfer.receiver.id,
                  label: transfer.receiver.title,
                }}
                isDisabled
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        {(typeof transfer?.sender?.id === 'number' ||
          typeof transfer?.receiver?.id === 'number') && (
          <Col lg="12">
            <TableOfTransfers
              isInventory={transfer?.resourcetype !== 'ArrivalTransfer'}
              defaultProducts={defaultProducts}
              warehouseId={transfer?.sender?.id ?? transfer?.receiver?.id}
              onUpdate={setTransferItems}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

InventoryAdjustmentFields.propTypes = {
  isPending: PropTypes.bool,
  transfer: PropTypes.object,
  setTransfer: PropTypes.func,
  setTransferItems: PropTypes.func,
  setReceiverID: PropTypes.func,
  setSenderID: PropTypes.func,
};

export default InventoryAdjustmentFields;
