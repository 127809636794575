import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ImportData from './ImportData';
import FormImport from './FormImport';
import Loader from './Loader';
import SelectData from './SelectData';
import PopupBase from '../PopupBase';
import styles from './ImportBrandPopup.scss';

const ImportModal = ({
  isOpen,
  setIsOpen,
  importHandler,
  isPending,
  data,
  selectedData,
  setSelectedData,
}) => {
  const isImport = !isPending && 'Import';
  const isLoading = isPending && 'Loading';
  const isData = !isPending && Object.keys(data).length && 'Data';
  const isSelectedData =
    !isPending && selectedData.length > 1 && 'SelectedData';
  const component = isData || isSelectedData || isImport || isLoading;

  const components = {
    Loading: () => <Loader />,
    Import: () => (
      <FormImport closeModal={setIsOpen} importHandler={importHandler} />
    ),
    Data: () => <ImportData closeModal={setIsOpen} data={data} />,
    SelectedData: () => (
      <SelectData
        setData={setSelectedData}
        data={selectedData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    ),
  };

  const RenderedComponent = components[component];

  return (
    <PopupBase className={styles.popup} onClose={() => setIsOpen(false)}>
      <RenderedComponent />
    </PopupBase>
  );
};

ImportModal.propTypes = {
  isOpen: PropTypes.number,
  setIsOpen: PropTypes.func,
  importHandler: PropTypes.func,
  isPending: PropTypes.bool,
  data: PropTypes.object,
  selectedData: PropTypes.array,
  setSelectedData: PropTypes.func,
};

export default memo(ImportModal);
