import React from 'react';
import PropTypes from 'prop-types';
import { isOnlyHostSelector } from 'models/user/selectors';
import {
  containerWorldExceptionPaginationSelector,
  lowInventoryPaginationSelector,
  initializedManualCountPaginationSelector,
} from 'models/inventories/selectors';
import useSelector from 'hooks/useSelector';
import { inProgressTransfersNotificationPaginationSelector } from 'models/transfers/selectors';

const InventoryCountTitle = ({ title }) => {
  // Selectors
  const isHost = useSelector(isOnlyHostSelector);
  const initializedManualCountPagination = useSelector(
    initializedManualCountPaginationSelector
  );
  const containerWorldExceptionPagination = useSelector(
    containerWorldExceptionPaginationSelector
  );
  const lowInventoryPagination = useSelector(lowInventoryPaginationSelector);
  const inProgressTransfersPagination = useSelector(
    inProgressTransfersNotificationPaginationSelector
  );

  // Constants
  const countSum =
    (isHost ? 0 : containerWorldExceptionPagination?.total_count || 0) +
    (isHost ? 0 : initializedManualCountPagination?.total_count || 0) +
    (lowInventoryPagination?.total_count || 0) +
    (inProgressTransfersPagination?.total_count || 0);

  return (
    <span>
      {title}
      {countSum !== 0 && (
        <span className="badge badge-light ml-2">{countSum}</span>
      )}
    </span>
  );
};

InventoryCountTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default InventoryCountTitle;
