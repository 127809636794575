const DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL = {
  opening_inventory: '0.00',
  total_additions: '0.00',
  total_non_duty_paid_reductions: '0.00',
  total_duty_paid_reductions: '0.00',
  total_reductions: '0.00',
  inventory_adjustment: '0.00',
  closing_inventory: '0.00',
};

const DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_SPIRITS = {
  ...DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL,
  domestic_non_duty_paid_packaged: '0.00',
  imported_non_duty_paid_packaged: '0.00',
  returned_from_duty_paid_sources: '0.00',
  returned_from_non_duty_paid_sources: '0.00',

  removed_for_delivery_to_registered_users: '0.00',
  removed_for_delivery_to_accredited_representatives: '0.00',
  removed_for_delivery_to_duty_free_shops: '0.00',
  removed_for_delivery_as_ships_stores: '0.00',
  removed_for_delivery_to_licensed_users_domestic: '0.00',
  removed_for_delivery_to_licensed_users_imported: '0.00',

  removed_for_delivery_to_other_excise_warehouses: '0.00',
  exported_spirits: '0.00',
  returned_to_spirits_licensee: '0.00',
  breakage: '0.00',
  spirits_removed_for_other_purposes_non_duty: '0.00',

  packaged_excluding_marked_special_containers: '0.00',
  packaged_in_marked_special_containers: '0.00',
  spirits_removed_for_other_purposes_duty: '0.00',
};

const DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE = {
  ...DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL,
  domestic_wine: '0.00',
  imported_wine: '0.00',
  returned_from_duty_paid_sources: '0.00',
  returned_from_non_duty_paid_sources: '0.00',

  removed_for_delivery_to_accredited_representatives: '0.00',
  removed_for_delivery_to_duty_free_shops: '0.00',
  removed_for_delivery_as_ships_stores: '0.00',
  removed_for_delivery_to_licensed_users: '0.00',
  removed_for_delivery_to_other_excise_warehouses: '0.00',
  exported_wine: '0.00',
  returned_to_wine_licensee: '0.00',
  breakage: '0.00',
  wine_removed_for_other_purposes_non_duty: '0.00',

  packaged_excluding_marked_special_containers: '0.00',
  packaged_in_marked_special_containers: '0.00',
  wine_removed_for_other_purposes_duty: '0.00',
};

export const DEFAULT_VALUES_EXCISE_WAREHOUSE = {
  spirits_more_than_seven_percent_quantity: '0.00',
  spirits_more_than_seven_percent_duty_payable: '0.00',
  spirits_more_than_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_SPIRITS,

  spirits_not_more_than_seven_percent_quantity: '0.00',
  spirits_not_more_than_seven_percent_duty_payable: '0.00',
  spirits_not_more_than_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_SPIRITS,

  spirits_delivered_to_licensed_users_quantity: '0.00',
  spirits_delivered_to_licensed_users_duty_payable: '0.00',

  wine_seven_percent_quantity: '0.00',
  wine_seven_percent_duty_payable: '0.00',
  wine_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE,

  wine_one_percent_quantity: '0.00',
  wine_one_percent_duty_payable: '0.00',
  wine_one_percent_return_detail: DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE,

  wine_zero_percent_quantity: '0.00',
  wine_zero_percent_duty_payable: '0.00',
  wine_zero_percent_return_detail: DEFAULT_VALUES_EXCISE_WAREHOUSE_DETAIL_WINE,
};

export const EXCISE_WAREHOUSE_TABLE_COLUMNS_SPIRITS = [
  {
    key: 'spirits_more_than_seven_percent',
    label: 'Spirits more than 7%',
  },
  {
    key: 'spirits_not_more_than_seven_percent',
    label: 'Spirits not more than 7%',
  },
];
export const EXCISE_WAREHOUSE_TABLE_COLUMNS_WINE = [
  { key: 'wine_seven_percent', label: 'Wine more than 7%' },
  {
    key: 'wine_one_percent',
    label: 'Wine between 1.2% and 7%',
  },
  {
    key: 'wine_zero_percent',
    label: 'Wine not more than 1.2%',
  },
];

export const DATE_FIELDS = [
  { key: 'period_start', label: 'Period Start' },
  { key: 'period_end', label: 'Period End' },
  { key: 'due_date', label: 'Due Date' },
];
export const EXCISE_WAREHOUSE_TABLE_COLUMNS = [
  ...EXCISE_WAREHOUSE_TABLE_COLUMNS_SPIRITS,
  ...EXCISE_WAREHOUSE_TABLE_COLUMNS_WINE,
];

export const COLUMNS_SPIRITS = [
  'spirits_more_than_seven_percent_return_detail',
  'spirits_not_more_than_seven_percent_return_detail',
];
export const COLUMNS_WINE = [
  'wine_seven_percent_return_detail',
  'wine_one_percent_return_detail',
  'wine_zero_percent_return_detail',
];

export const INITIAL_STATE = {
  // SPIRITS DEFAULTS
  spirits_more_than_seven_percent_return_detail_opening_inventory: '0.00',
  spirits_not_more_than_seven_percent_return_detail_opening_inventory: '0.00',
  spirits_more_than_seven_percent_return_detail_total_additions: '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_additions: '0.00',
  spirits_more_than_seven_percent_return_detail_total_non_duty_paid_reductions:
    '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_non_duty_paid_reductions:
    '0.00',
  spirits_more_than_seven_percent_return_detail_total_duty_paid_reductions:
    '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_duty_paid_reductions:
    '0.00',
  spirits_more_than_seven_percent_return_detail_total_reductions: '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_reductions: '0.00',
  spirits_more_than_seven_percent_return_detail_inventory_adjustment: '0.00',
  spirits_not_more_than_seven_percent_return_detail_inventory_adjustment:
    '0.00',
  spirits_more_than_seven_percent_return_detail_closing_inventory: '0.00',
  spirits_not_more_than_seven_percent_return_detail_closing_inventory: '0.00',

  spirits_more_than_seven_percent_return_detail_quantity: '0.00',
  spirits_not_more_than_seven_percent_return_detail_quantity: '0.00',
  spirits_more_than_seven_percent_return_detail_duty_payable: '0.00',
  spirits_not_more_than_seven_percent_return_detail_duty_payable: '0.00',

  // WINE DEFAULTS
  wine_seven_percent_return_detail_opening_inventory: '0.00',
  wine_one_percent_return_detail_opening_inventory: '0.00',
  wine_zero_percent_return_detail_opening_inventory: '0.00',
  wine_seven_percent_return_detail_total_additions: '0.00',
  wine_one_percent_return_detail_total_additions: '0.00',
  wine_zero_percent_return_detail_total_additions: '0.00',
  wine_seven_percent_return_detail_total_non_duty_paid_reductions: '0.00',
  wine_one_percent_return_detail_total_non_duty_paid_reductions: '0.00',
  wine_zero_percent_return_detail_total_non_duty_paid_reductions: '0.00',
  wine_seven_percent_return_detail_total_duty_paid_reductions: '0.00',
  wine_one_percent_return_detail_total_duty_paid_reductions: '0.00',
  wine_zero_percent_return_detail_total_duty_paid_reductions: '0.00',
  wine_seven_percent_return_detail_total_reductions: '0.00',
  wine_one_percent_return_detail_total_reductions: '0.00',
  wine_zero_percent_return_detail_total_reductions: '0.00',
  wine_seven_percent_return_detail_inventory_adjustment: '0.00',
  wine_one_percent_return_detail_inventory_adjustment: '0.00',
  wine_zero_percent_return_detail_inventory_adjustment: '0.00',
  wine_seven_percent_return_detail_closing_inventory: '0.00',
  wine_one_percent_return_detail_closing_inventory: '0.00',
  wine_zero_percent_return_detail_closing_inventory: '0.00',

  wine_seven_percent_return_detail_quantity: '0.00',
  wine_one_percent_return_detail_quantity: '0.00',
  wine_zero_percent_return_detail_quantity: '0.00',
  wine_seven_percent_return_detail_duty_payable: '0.00',
  wine_one_percent_return_detail_duty_payable: '0.00',
  wine_zero_percent_return_detail_duty_payable: '0.00',
};

export const ADDITIONS_TO_INVENTORY_SPIRITS_COLS = [
  {
    key: 'domestic_non_duty_paid_packaged',
    label: 'Domestic non-duty-paid packaged',
  },
  {
    key: 'imported_non_duty_paid_packaged',
    label: 'Imported non-duty-paid packaged',
  },
  {
    key: 'returned_from_duty_paid_sources',
    label: 'Returned duty-paid sources',
  },
  {
    key: 'returned_from_non_duty_paid_sources',
    label: 'Returned non-duty-paid sources',
  },
];

export const ADDITIONS_TO_INVENTORY_WINE_COLS = [
  {
    key: 'domestic_wine',
    label: 'Domestic wine',
  },
  {
    key: 'imported_wine',
    label: 'Imported wine',
  },
  {
    key: 'returned_from_duty_paid_sources',
    label: 'Returned duty-paid sources',
  },
  {
    key: 'returned_from_non_duty_paid_sources',
    label: 'Returned non-duty-paid sources',
  },
];

export const REDUCTIONS_TO_INVENTORY_SPIRITS_NON_DUTY_COLS = [
  {
    key: 'removed_for_delivery_to_registered_users',
    label: 'Removed for delivery to registered users',
  },
  {
    key: 'removed_for_delivery_to_accredited_representatives',
    label: 'Removed for delivery to accredited representatives',
  },
  {
    key: 'removed_for_delivery_to_duty_free_shops',
    label: 'Removed for delivery to duty-free shops',
  },
  {
    key: 'removed_for_delivery_as_ships_stores',
    label: 'Removed for delivery as ships stores',
  },
  {
    key: 'removed_for_delivery_to_licensed_users_domestic',
    label: 'Removed for delivery to licensed users (domestic)',
  },
  {
    key: 'removed_for_delivery_to_licensed_users_imported',
    label: 'Removed for delivery to licensed users (imported)',
  },
  {
    key: 'removed_for_delivery_to_other_excise_warehouses',
    label: 'Removed for delivery to other excise warehouses',
  },
  {
    key: 'exported_spirits',
    label: 'Exported spirits',
  },
  {
    key: 'returned_to_spirits_licensee',
    label: 'Returned to spirits licensee',
  },
  {
    key: 'breakage',
    label: 'Breakage',
  },
  {
    key: 'spirits_removed_for_other_purposes_non_duty',
    label: 'Spirits removed for other purposes',
  },
];

export const REDUCTIONS_TO_INVENTORY_SPIRITS_DUTY_COLS = [
  {
    key: 'packaged_excluding_marked_special_containers',
    label: 'Packaged (excluding marked special containers)',
  },
  {
    key: 'packaged_in_marked_special_containers',
    label: 'Packaged in marked special containers',
  },
  {
    key: 'spirits_removed_for_other_purposes_duty',
    label: 'Spirits removed for other purposes (duty)',
  },
];

export const REDUCTIONS_TO_INVENTORY_WINE_NON_DUTY_COLS = [
  {
    key: 'removed_for_delivery_to_accredited_representatives',
    label: 'Removed for delivery to accredited representatives',
  },
  {
    key: 'removed_for_delivery_to_duty_free_shops',
    label: 'Removed for delivery to duty free shops',
  },
  {
    key: 'removed_for_delivery_as_ships_stores',
    label: 'Removed for delivery as ships stores',
  },
  {
    key: 'removed_for_delivery_to_licensed_users',
    label: 'Removed for delivery to licensed users',
  },
  {
    key: 'removed_for_delivery_to_other_excise_warehouses',
    label: 'Removed for delivery to other excise warehouses',
  },
  { key: 'exported_wine', label: 'Exported wine' },
  {
    key: 'returned_to_wine_licensee',
    label: 'Returned to wine licensee',
  },
  { key: 'breakage', label: 'Breakage' },
  {
    key: 'wine_removed_for_other_purposes_non_duty',
    label: 'Wine removed for other purposes',
  },
];

export const REDUCTIONS_TO_INVENTORY_WINE_DUTY_COLS = [
  {
    key: 'packaged_excluding_marked_special_containers',
    label: 'Packaged (excluding marked special containers)',
  },
  {
    key: 'packaged_in_marked_special_containers',
    label: 'Packaged in marked special containers',
  },
  {
    key: 'wine_removed_for_other_purposes_duty',
    label: 'Wine removed for other purposes (duty)',
  },
];
