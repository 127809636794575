import React, { memo, useCallback, useEffect, useState } from 'react';
import FileInput from './FileInput';
import FileUploaded from './FileUploaded';
import PropTypes from 'prop-types';
import FilePreview from './FilePreview';

const FileField = ({
  label,
  multiple,
  onChange,
  className,
  value,
  preview,
  setIsRemoveImage,
  isRemoveImage,
  error,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (value) {
      setFiles(!multiple ? [value] : value);
    } else {
      setFiles([]);
    }
  }, [value, multiple]);

  const onAddFileHandler = useCallback(
    newFile => {
      const currentFiles = multiple ? [...files, ...newFile] : newFile;
      setFiles(currentFiles);
      onChange?.(multiple ? currentFiles : currentFiles?.[0] ?? null);
    },
    [multiple, files]
  );

  const onDeleteFileHandler = useCallback(
    index => {
      const currentFiles = files.filter((_, fileIndex) => index !== fileIndex);
      setFiles(currentFiles);
      onChange?.(multiple ? currentFiles : currentFiles?.[0] ?? null);
    },
    [files, multiple]
  );

  return (
    <div className={className}>
      <FileInput
        label={label}
        onAddFile={onAddFileHandler}
        multiple={multiple}
        {...props}
      />
      {!isRemoveImage && preview && (
        <FilePreview
          preview={preview}
          onDelete={setIsRemoveImage ? () => setIsRemoveImage(true) : null}
        />
      )}
      {!!error && <span className="text-danger">{error}</span>}
      {files.map((file, index) => (
        <FileUploaded
          key={index}
          name={file?.name ?? file}
          onDelete={() => onDeleteFileHandler(index)}
        />
      ))}
    </div>
  );
};

FileField.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setIsRemoveImage: PropTypes.func,
  isRemoveImage: PropTypes.bool,
  error: PropTypes.string,
};
export default memo(FileField);
