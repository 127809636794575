import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

function* fetchAcquirersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/acquirers/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
function* fetchAcquirerSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/acquirers/${action.payload}`,
  });
}

function* createAcquirerSaga(action) {
  const isStripeConnect = action.payload.resourcetype === 'stripe_connect';

  if (isStripeConnect) {
    yield api({
      action,
      method: 'post',
      url: `/acquirers/`,
      data: action.payload,
      successMessage: 'Please continue creation proccess in the opened link.',
    });
  } else {
    yield api({
      action,
      method: 'post',
      url: `/acquirers/`,
      data: action.payload,
      successMessage: 'Successfully created new acquirer!',
      successNavigateTo: `/acquirers/`,
    });
  }
}

function* createAcquirerSuccessSaga(action) {
  const stripeLink = action.payload.data.connect_link;
  if (stripeLink) {
    yield window.location.replace(stripeLink, '_self');
  }
}

function* updateAcquirerSaga(action) {
  yield api({
    action,
    method: 'put',
    url: `/acquirers/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated acquirer!',
    successNavigateTo: `/acquirers/`,
  });
}

function* setActiveAcquirerSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/acquirers/${action.payload}/set_active`,
    data: action.payload,
    successMessage: 'Successfully set active acquirer!',
    successNavigateTo: `/acquirers/`,
  });
}

function* setStripeConnectAccountIdSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/acquirers/${action.payload.acquirer_id}/set_stripe_connect_account_id`,
    data: {
      stripe_authorization_code: action.payload.stripe_authorization_code,
    },
    successMessage: 'Successfully finished standard account creation!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchAcquirers, fetchAcquirersSaga),
    takeLatest(actions.fetchAcquirer, fetchAcquirerSaga),
    takeLatest(actions.createAcquirer, createAcquirerSaga),
    takeLatest(actions.createAcquirerSuccess, createAcquirerSuccessSaga),
    takeLatest(actions.updateAcquirer, updateAcquirerSaga),
    takeLatest(actions.setActiveAcquirer, setActiveAcquirerSaga),
    takeLatest(
      actions.setStripeConnectAccountId,
      setStripeConnectAccountIdSaga
    ),
  ]);
}
