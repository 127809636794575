import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';
import {
  productSelector,
  foodCategoriesSelector,
  wineCategoriesSelector,
  merchandiseCategoriesSelector,
} from 'models/products/selectors';
import { isPendingSelector as awardsIsPendingSelector } from 'models/awards/selectors';

import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import ProductForm from 'components/ProductForm';

import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import FoodForm from 'components/FoodForm/FoodForm';
import MerchForm from 'components/MerchForm';
import { getRedactedValues } from 'utils/getRedactedValues';

const EditProduct = ({ title }) => {
  const { productID } = useParams();
  const product = useSelector(productSelector, productID);
  const isWrongProduct = product?.id !== Number(productID);
  const awardsIsPending = useSelector(awardsIsPendingSelector);
  const updateProduct = useAction(productsActions.updateProduct);
  const fetchProduct = useAction(productsActions.fetchProduct);
  const fetchProductImages = useAction(productsActions.fetchProductImages);
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const foodCategories = useSelector(foodCategoriesSelector);
  const merchandiseCategories = useSelector(merchandiseCategoriesSelector);
  const wineCategories = useSelector(wineCategoriesSelector);

  const isPending = awardsIsPending;

  useEffect(() => {
    fetchProduct({ id: productID });
    fetchProductImages({ id: productID });
    if (!foodCategories || !merchandiseCategories || !wineCategories) {
      fetchCategories();
    }
  }, []);

  const handleEditProduct = data => {
    updateProduct({ id: data?.id, ...getRedactedValues(product, data) });
  };

  const formType = type => {
    switch (type) {
      case 'Wine':
        return (
          <ProductForm
            model={product}
            submitTitle="Save"
            onSubmit={handleEditProduct}
            disabled={isPending}
            resourcetype="Wine"
            categories={Object.values(wineCategories ?? {})}
          />
        );
      case 'Food':
        return (
          <FoodForm
            model={product}
            submitTitle="Save"
            disabled={isPending}
            onSubmit={handleEditProduct}
            resourcetype="Food"
            categories={Object.values(foodCategories ?? {})}
          />
        );
      case 'Merchandise':
        return (
          <MerchForm
            onSubmit={handleEditProduct}
            model={product}
            categories={Object.values(merchandiseCategories ?? {})}
            submitTitle="Save"
          />
        );
      default:
        return <></>;
    }
  };

  if (isPending && isWrongProduct) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={product?.title || 'Create product'}
        breadcrumbItems={[
          { title: 'Back to products', link: '/products/', withSearch: true },
        ]}
      />
      <Row>
        {product.id === Number(productID) && (
          <Col md="12">{formType(product.resourcetype)}</Col>
        )}
      </Row>
    </PageWrapper>
  );
};

EditProduct.propTypes = {
  title: PropTypes.string,
};

export default EditProduct;
