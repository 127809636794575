import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const footTrafficSlice = createSlice({
  name: 'footTraffic',
  initialState: {
    isPending: false,
    isPolling: false,
    cameras: [],
    selectedCamera: null,
    cameraStats: {},
  },
  reducers: {
    fetchCameras: state => {
      state.cameras = [];
      state.isPending = true;
    },
    fetchCamerasSuccess(state, { payload }) {
      state.cameras = payload.data.results;
    },

    fetchCamerasFailure: state => {
      state.isPending = false;
    },
    fetchCamera: state => {
      state.isPending = true;
    },
    fetchCameraSuccess(state, { payload }) {
      state.selectedCamera = payload.data;
      state.isPending = false;
    },
    fetchCameraFailure: state => {
      state.isPending = false;
    },
    clearSelectedCamera: state => {
      state.selectedCamera = null;
    },
    addCamera: state => {
      state.isPending = true;
    },
    addCameraSuccess(state) {
      state.isPending = false;
    },
    addCameraFailure: state => {
      state.isPending = false;
    },
    removeCamera: state => {
      state.isPending = true;
    },
    removeCameraSuccess(state) {
      state.isPending = false;
    },
    removeCameraFailure: state => {
      state.isPending = false;
    },
    updateCamera: state => {
      state.isPending = true;
    },
    updateCameraSuccess(state) {
      state.isPending = false;
    },
    updateCameraFailure: state => {
      state.isPending = false;
    },
    fetchCameraStats: (state, { payload }) => {
      // clear first
      if (state.cameraStats[payload.statsType]) {
        state.cameraStats[payload.statsType] = {};
      }
    },
    fetchCameraStatsPolling: state => {
      state.isPolling = true;
    },
    fetchCameraStatsStopPolling: state => {
      state.isPolling = false;
    },
    fetchCameraStatsSuccess: (state, { payload }) => {
      const { statsType } = payload.inResponseTo;

      if (!payload.data) {
        state.cameraStats[statsType] = {
          currentValue: -1,
          error: 'Not enough data to show this metric',
        };
      } else {
        const result = payload.data[Object.keys(payload.data)[0]];
        state.cameraStats[statsType] = result;
      }
    },
    fetchCameraStatsFailure: () => {},
  },
});

export const actions = actionTypes(footTrafficSlice.actions);

export default footTrafficSlice.reducer;
