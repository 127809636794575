import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentDidMount from 'hooks/useComponentDidMount';

import { actions as hostsActions } from 'models/hosts/slice';
import {
  hostsSelector,
  isPendingSelector as hostsIsPendingSelector,
  paginationSelector,
} from 'models/hosts/selectors';
import {
  brandsSelector,
  isPendingSelector as brandsIsPendingSelector,
} from 'models/brands/selectors';
import { actions as brandsActions } from 'models/brands/slice';

import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const HostsSummary = ({ title }) => {
  const fetchHosts = useAction(hostsActions.fetchHosts);
  const deleteHost = useAction(hostsActions.deleteHost);
  const fetchBrands = useAction(brandsActions.fetchBrands);
  const hosts = useSelector(hostsSelector);
  const brands = useSelector(brandsSelector);
  const hostsIsPending = useSelector(hostsIsPendingSelector);
  const brandsIsPending = useSelector(brandsIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [hostForDelete, setHostForDelete] = useState(null);

  const [brandID, setBrandID] = useState(query.get('brand_id') || 0);
  const currentPerPage = query.get('per_page');
  const [perPage, setPerPage] = useState(currentPerPage || 10);
  const isPending = hostsIsPending || brandsIsPending;
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    fetchHosts({
      page: currentPage,
      per_page: perPage,
      brand_id: brandID,
    });
  }, [fetchHosts, currentPage, brandID, perPage]);

  useComponentDidMount(() => {
    fetchBrands();
  });

  const handleDeleteTable = () => {
    deleteHost(hostForDelete);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    hasBrandsFilter
                    brands={brands}
                    setBrandID={setBrandID}
                    brandID={parseInt(brandID, 10) || 0}
                    hasPerPageFilter
                    perPage={parseInt(perPage, 10)}
                    setPerPage={setPerPage}
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/host/invite"
                    disabled={isPending}
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  setHostID={setHostForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  hosts={hosts}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    title="Are you sure you want to detach host role from this user?"
                    submitButtonTitle="Detach"
                    onSaveClick={handleDeleteTable}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

HostsSummary.propTypes = {
  title: PropTypes.string,
};

export default HostsSummary;
