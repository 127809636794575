import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Payment from 'components/Payment';
import { AvForm } from 'availity-reactstrap-validation';
import { paymentMethodsSelector } from 'models/customers/selectors';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { brandSelector, isPendingSelector } from 'models/brands/selectors';
import { actions as brandsActions } from 'models/brands/slice';
import { actions as customersActions } from 'models/customers/slice';
import PaymentMethodsTable from '../../PaymentMethodsTable';
import { Link } from 'react-router-dom';

const getFilteredPaymentMethods = (paymentMethods, brand) =>
  brand
    ? paymentMethods.filter(item => item?.brand_id === brand?.id)
    : paymentMethods;

const PaymentForm = ({ brands, currentCustomerId, finishButton }) => {
  const [currentBrand, setCurrentBrand] = useState();
  const [cardOptions, setCardOptions] = useState([]);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const fetchBrand = useAction(brandsActions.fetchBrand);
  const brand = useSelector(brandSelector);
  const isPendingBrand = useSelector(isPendingSelector);

  const filteredPaymentMethods = getFilteredPaymentMethods(
    paymentMethods ?? [],
    currentBrand
  );

  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({ id: methodId, customerId: currentCustomerId });
  };

  const handleBrandChange = item => {
    setCurrentBrand(item);
  };

  useEffect(() => {
    if (currentCustomerId) {
      fetchPaymentMethods(currentCustomerId);
    }
  }, [fetchPaymentMethods, currentCustomerId]);

  useEffect(() => {
    if (currentBrand) {
      fetchBrand(currentBrand.id);
    }
  }, [currentBrand]);

  return (
    <>
      <Row className="mb-2">
        <Col md={5}>
          <Label required htmlFor="brands">
            Brand
          </Label>
          <Select
            name="brands"
            id="brands"
            value={currentBrand}
            options={brands}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleBrandChange}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            menuPlacement="auto"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          {currentBrand && currentCustomerId && (
            <AvForm
              className="needs-validation"
              onValidSubmit={event => event.preventDefault()}
            >
              {isPendingBrand && <p>Loading...</p>}
              {brand?.active_stripe_acquirer?.public_key && !isPendingBrand && (
                // TODO: check components Payment and Payment Tab
                <Payment
                  stripeToken={brand?.active_stripe_acquirer?.public_key}
                  stripeAccount={brand?.active_stripe_acquirer?.account_id}
                  currentCustomerId={currentCustomerId}
                  currentBrand={brand}
                  cardOptions={cardOptions}
                  setCardOptions={setCardOptions}
                  withPaymentMethodsTable={false}
                />
              )}
              {!brand?.active_stripe_acquirer?.is_active && !isPendingBrand && (
                <Alert color="warning">
                  <i className="mdi mdi-information" />
                  <b>No active acquirer</b>
                  <p>Please add one in brands settings.</p>
                </Alert>
              )}
            </AvForm>
          )}
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Link to="/wineclub-customers">
            <Button color="success">{finishButton}</Button>
          </Link>
        </Col>
      </Row>
      {currentCustomerId && filteredPaymentMethods.length > 0 && (
        <PaymentMethodsTable
          paymentMethods={filteredPaymentMethods}
          onRemovePaymentMethod={onRemovePaymentMethod}
        />
      )}
    </>
  );
};

PaymentForm.propTypes = {
  brands: PropTypes.array.isRequired,
  currentCustomerId: PropTypes.number.isRequired,
  finishButton: PropTypes.string,
};
export default PaymentForm;
