import React, { useEffect, useState } from 'react';
import { getParametersWithURL } from 'utils/getParametersWithURL';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { actions as productsActions } from 'models/products/slice';
import useSelector from 'hooks/useSelector';
import { wineCategoriesSelector } from 'models/products/selectors';
import useAction from 'hooks/useAction';
import ProductCategoryTable from 'components/ProductCategoryTable';

const WineCategoriesSummary = ({ title }) => {
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const categories = useSelector(wineCategoriesSelector);
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (!categories) fetchCategories();
  }, [fetchCategories, categories]);

  useEffect(() => {
    if (filters) fetchCategories(getParametersWithURL(filters));
  }, [filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <ProductCategoryTable
                categories={Object.values(categories ?? {})}
                onFiltersChange={setFilters}
                categoryPath="/wine-categories"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

WineCategoriesSummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default WineCategoriesSummary;
