export const CAMERA_STATUS = {
  OFFLINE: 'offline',
  RUNNING: 'running',
  STOPPED: 'stopped',
};

export const CAMERA_ACTIONS = {
  START: 'start',
  STOP: 'stop',
};

export const CAMERA_DIRECTIONS = ['up', 'down'];

export const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 10,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
    xAxes: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export const CAMERA_STATS = [
  {
    live: true,
    darkTheme: true,
    title: 'Active Visitors',
    type: 'activeVisitors',
    url: '/activeVisitors',
  },
  {
    live: false,
    darkTheme: false,
    title: 'Total Visitors',
    type: 'totalVisitors',
    url: '/totalVisitors',
  },
  {
    live: false,
    darkTheme: false,
    title: 'Peak Time of Day',
    type: 'peakTime',
    url: '/peakTime',
    format: "hh:mm aaaaa'm'",
  },
  {
    live: false,
    darkTheme: false,
    title: 'Traffic to Sales',
    type: 'trafficToSales',
    url: '/warehouses/{warehouse_id}/camera/{camera_id}/traffic_to_sales',
    isCoreApi: true,
  },
  {
    live: false,
    darkTheme: false,
    title: 'Avg. Revenue Per Person',
    type: 'avgRevenuePerPerson',
    url:
      '/warehouses/{warehouse_id}/camera/{camera_id}/average_revenue_per_person',
    isCoreApi: true,
  },
  // {
  //   live: false,
  //   darkTheme: false,
  //   title: 'Avg. Duration',
  //   type: 'avgDuration',
  // },
  // {
  //   live: false,
  //   darkTheme: false,
  //   title: 'Staff to Customer Ratio',
  //   type: 'staffToCustomerRatio',
  // },
  {
    live: false,
    darkTheme: false,
    title: 'Staff to Customer Ratio',
    type: 'staffToCustomerRatio',
    url: '/warehouses/{warehouse_id}/camera/{camera_id}/staff_to_customers',
    isCoreApi: true,
  },
  {
    live: false,
    darkTheme: false,
    title: 'Busiest Day',
    type: 'busiestDay',
    url: '/busiestDay',
    format: 'MMMM do, yyyy',
  },
  {
    live: false,
    darkTheme: false,
    title: 'First Guest',
    type: 'firstGuest',
    url: '/firstGuest',
    format: "hh:mm aaaaa'm'",
  },
  {
    live: false,
    darkTheme: false,
    title: 'Last Guest',
    type: 'lastGuest',
    url: '/lastGuest',
    format: "hh:mm aaaaa'm'",
  },
];

export const CAMERA_STATS_RESULTS_MOCK = {
  activeVisitors: {
    lastReportAt: '5:11PM',
    currentValue: 56,
    description: '6% fewer than yesteday',
    pastValues: [20, 35, 40, 35, 42, 13],
    comparisonAt: 'less',
    comparisonValue: '6%',
  },
  totalVisitors: {
    currentValue: 1005,
    description: 'From previous week',
    comparisonAt: 'more',
    comparisonValue: '2.4%',
  },
  peakTime: {
    currentValue: '2:35 PM',
    description: 'Previous Peak Time',
    comparisonAt: 'equal',
    comparisonValue: '1:15 PM',
  },
  trafficToSales: {
    currentValue: '65%',
    description: 'From previous week',
    comparisonAt: 'more',
    comparisonValue: '2.4%',
  },
  avgRevenuePerPerson: {
    currentValue: '$ 85.60',
    description: 'From previous week',
    comparisonAt: 'more',
    comparisonValue: '2.4%',
  },
  avgDuration: {
    currentValue: '37.5 min',
    description: 'From previous week',
    comparisonAt: 'less',
    comparisonValue: '2.4%',
  },
  staffToCustomerRatio: {
    currentValue: '1 / 45',
    description: 'Previous ratio',
    comparisonAt: 'equalGreen',
    comparisonValue: '1 / 40',
  },
  busiestDay: {
    currentValue: 'Saturday',
    description: 'Previously Friday',
    comparisonAt: 'none',
    comparisonValue: '',
  },
  firstAndLastGuest: {
    currentValue: '11:05 AM / 5:59 PM',
    description: 'Was 11:01 AM / 6:01 PM',
    comparisonAt: 'none',
    comparisonValue: '',
  },
};

export const COMPARISON_AT_VALUES = {
  LESS: 'less',
  MORE: 'more',
  EQUAL: 'equal',
  EQUAL_GREEN: 'equalGreen',
  NONE: 'none',
};
