import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import useSelector from 'hooks/useSelector';
import {
  productsSelector,
  isPendingSelector as productsIsPendingSelector,
  paginationSelector,
} from 'models/products/selectors';

import DataTable from './DataTable';
import Filter from './Filter';

const ProductSummary = ({ title }) => {
  const fetchProducts = useAction(productsActions.fetchProducts);
  const updateProduct = useAction(productsActions.updateProduct);
  const products = useSelector(productsSelector);
  const isPending = useSelector(productsIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchProducts({
      page: currentPage,
      sort_field: sortField,
      sort_direction: sortDirection,
      ...filter,
    });
  }, [fetchProducts, currentPage, sortField, sortDirection, filter]);

  useEffect(() => {
    setCurrentPage(parseInt(query.get('page'), 10) || 1);
  }, [sortField, sortDirection, filter]);

  const handleArchive = useCallback(
    id => {
      updateProduct({
        id,
        archived: true,
        page: currentPage,
      });
    },
    [updateProduct, currentPage]
  );

  const handleRestore = useCallback(
    id => {
      updateProduct({
        id,
        archived: false,
        page: currentPage,
      });
    },
    [updateProduct, currentPage]
  );

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Filter
                disabled={isPending}
                filter={filter}
                setFilter={setFilter}
              />
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  products={products}
                  onArchive={handleArchive}
                  onRestore={handleRestore}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ProductSummary.propTypes = {
  title: PropTypes.string,
};

export default ProductSummary;
