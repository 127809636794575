import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const importCustomersSlice = createSlice({
  name: 'importCustomers',
  initialState: {
    isPending: false,
    isUploadModalOpened: false,
    importIsPending: false,
    imports: [],
    pagination: {},
  },
  reducers: {
    fetchImports: state => {
      state.isPending = true;
    },
    fetchImportsSuccess(state, { payload }) {
      state.isPending = false;
      state.imports = payload.data;
      state.pagination = payload.data.pagination;
    },
    fetchImportsFailure: state => {
      state.isPending = false;
    },
    uploadImport: state => {
      state.importIsPending = true;
    },
    uploadImportSuccess(state) {
      state.importIsPending = false;
      state.pagination = {
        current_page: 1,
        per_page: 50,
      };
    },
    uploadImportFailure: state => {
      state.importIsPending = false;
    },
    setIsUploadModalOpened(state, { payload }) {
      state.isUploadModalOpened = payload;
    },
  },
});

export const actions = actionTypes(importCustomersSlice.actions);

export default importCustomersSlice.reducer;
