import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { get } from 'lodash';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    isPending: false,
    sales: {
      salesOverview: [],
      ordersSummary: [],
      salesByBrand: [],
      salesByDepartment: [],
      salesBySku: [],
      salesByOrderType: [],
      tipsSummary: [],
    },
    wineAssociatesSales: {},
    orders: null,
    tasterTracking: null,
    pagination: null,
    totalProductCount: null,
    dashboardUrl: null,
  },
  reducers: {
    fetchSales(state) {
      state.isPending = true;
    },
    fetchSalesSuccess(state, { payload }) {
      state.sales = {
        salesOverview: get(payload.data, 'Sales Overview', []),
        ordersSummary: get(payload.data, 'Orders Summary', []),
        salesByBrand: get(payload.data, 'Sales By Brand', []),
        salesByDepartment: get(payload.data, 'Sales By Department', []),
        salesBySku: get(payload.data, 'Sales By SKU', []),
        salesByOrderType: get(payload.data, 'Sales By Order Type', []),
        salesByPaymentType: get(payload.data, 'Sales By Payment Type', []),
        tipsSummary: get(payload.data, 'Tips Summary', []),
      };
      state.isPending = false;
    },
    fetchSalesFailure(state) {
      state.isPending = false;
    },

    fetchOrders(state) {
      state.isPending = true;
    },
    fetchOrdersSuccess(state, { payload }) {
      state.orders = payload.data.results;
      state.pagination = payload.data.pagination;
      state.isPending = false;
    },
    fetchOrdersFailure(state) {
      state.isPending = false;
    },

    fetchSalesXlsx(state) {
      state.isPending = true;
    },
    fetchSalesXlsxSuccess(state) {
      state.isPending = false;
    },
    fetchSalesXlsxFailure(state) {
      state.isPending = false;
    },
    fetchWineSales(state) {
      state.isPending = true;
    },
    fetchWineSalesSuccess(state, { payload }) {
      state.isPending = false;
      state.pagination = payload.data.pagination;
      state.tasterTracking = Object.keys(
        payload.data.results ?? {}
      ).map(key => ({ interval: key, ...payload.data.results[key] }));
    },
    fetchWineSalesFailure(state) {
      state.isPending = false;
    },
    fetchWineSalesXlsx(state) {
      state.isPending = true;
    },
    fetchWineSalesXlsxSuccess(state) {
      state.isPending = false;
    },
    fetchWineSalesXlsxFailure(state) {
      state.isPending = false;
    },
    fetchInventoryTransfersXlsx(state) {
      state.isPending = true;
    },
    fetchInventoryTransfersXlsxSuccess(state) {
      state.isPending = false;
    },
    fetchInventoryTransfersXlsxFailure(state) {
      state.isPending = false;
    },
    clearInventoryTable(state) {
      state.pagination.inventory = {};
      state.inventory = [];
    },
    fetchBatchXlsx(state) {
      state.isPending = true;
    },
    fetchBatchXlsxSuccess(state) {
      state.isPending = false;
    },
    fetchBatchXlsxFailure(state) {
      state.isPending = false;
    },
    fetchXlsxTransfers: state => {
      state.isPending = true;
    },
    fetchXlsxTransfersSuccess(state) {
      state.isPending = false;
    },
    fetchXlsxTransfersFailure: state => {
      state.isPending = false;
    },

    fetchWineAssociates: state => {
      state.isPending = true;
    },
    fetchWineAssociatesSuccess: (state, { payload }) => {
      state.wineAssociatesSales = payload.data;
      state.isPending = false;
    },
    fetchWineAssociatesFailure: state => {
      state.isPending = false;
    },
    fetchWineAssociatesXslx: state => {
      state.isPending = true;
    },
    fetchWineAssociatesXslxSuccess: state => {
      state.isPending = false;
    },
    fetchWineAssociatesXslxFailure: state => {
      state.isPending = false;
    },
    exportReportsFile(state) {
      state.isPending = true;
    },
    exportReportsFileSuccess(state) {
      state.isPending = false;
    },
    exportReportsFileFailure(state) {
      state.isPending = false;
    },
    fetchDashboardUrl(state) {
      state.isPending = true;
    },
    fetchDashboardUrlSuccess(state, { payload }) {
      state.isPending = false;
      state.dashboardUrl = payload.data;
    },
    fetchDashboardUrlFailure(state) {
      state.isPending = false;
    },
    fetchInventories(state) {
      state.isPending = true;
    },
    fetchInventoriesSuccess(state, { payload }) {
      state.inventories = payload.data.results;
      state.pagination = payload.data.pagination;
      state.totalProductCount = payload.data.total_product_count;
      state.isPending = false;
    },
    fetchInventoriesFailure(state) {
      state.isPending = false;
    },
    fetchInventoriesXlsx: state => {
      state.isPending = true;
    },
    fetchInventoriesXlsxSuccess: state => {
      state.isPending = false;
    },
    fetchInventoriesXlsxFailure: state => {
      state.isPending = false;
    },
    downloadReportsFiles() {},
  },
});

export const actions = actionTypes(reportsSlice.actions);

export default reportsSlice.reducer;
