import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import FoodAllergiesForm from 'components/FoodAllergiesForm';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';

import { actions as productsActions } from 'models/products/slice';
import {
  foodAllergySelector,
  isPendingSelector,
} from 'models/products/selectors';

const EditFoodAllergy = ({ title }) => {
  const { id } = useParams();

  const allergy = useSelector(foodAllergySelector);

  const updateAllergy = useAction(productsActions.updateFoodAllergy);
  const fetchCategory = useAction(productsActions.fetchFoodAllergy);

  const isPending = useSelector(isPendingSelector);

  useEffect(() => {
    fetchCategory({ id });
  }, [fetchCategory, id]);

  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={allergy?.title}
        breadcrumbItems={[
          {
            title: 'Back to allergies',
            link: '/food-allergies/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {allergy && (
            <FoodAllergiesForm
              model={allergy}
              disabled={isPending}
              onSubmit={updateAllergy}
              submitTitle="Save"
            />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

EditFoodAllergy.propTypes = {
  title: PropTypes.string,
};

export default EditFoodAllergy;
