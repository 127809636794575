import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import styles from './SearchField.scss';

const SearchField = ({ value, setValue, className, placeholder }) => {
  const handleSubmit = (_, data) => {
    setValue(data.search);
  };

  const placeholder_text = placeholder || 'Search...';

  return (
    <div className={classNames(className, 'd-flex', styles.wAutoMin)}>
      <AvForm
        className="d-flex align-items-center"
        onValidSubmit={handleSubmit}
      >
        <AvInput
          type="text"
          name="search"
          className={classNames('form-control', styles.wAutoMin, 'mr-1')}
          placeholder={placeholder_text}
          value={value}
        />
        <Button
          style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
          type="submit"
          className="waves-effect waves-light mr-2 text-nowrap"
        >
          <i className="ri-search-line align-middle" />
        </Button>
      </AvForm>
    </div>
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  placeholder: '',
};

export default SearchField;
