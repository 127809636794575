import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';

import { actions as productsActions } from 'models/products/slice';
import { isPendingSelector } from 'models/products/selectors';
import FoodAllergiesForm from 'components/FoodAllergiesForm';

const CreateFoodAllergy = ({ title }) => {
  const createAllergy = useAction(productsActions.createFoodAllergy);

  const isPending = useSelector(isPendingSelector);

  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new allergy"
        breadcrumbItems={[
          {
            title: 'Back to allergies',
            link: '/food-allergies/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FoodAllergiesForm
            disabled={isPending}
            onSubmit={createAllergy}
            submitTitle="Save"
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateFoodAllergy.propTypes = {
  title: PropTypes.string,
};

export default CreateFoodAllergy;
