import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import CardItem from 'components/CardItem';
import Info from './Info';

const CustomerInfo = ({ order }) => {
  return (
    <>
      {order?.customer ? (
        <Info
          title={`${order?.customer?.first_name} ${order?.customer?.last_name}`}
          id={order?.customer?.id}
          address={order?.customer?.address}
          email={order?.customer?.email}
          phone={order?.customer?.phone}
          link={`/wineclub-customers/${order?.customer?.id}`}
        />
      ) : (
        <Row xs="1">
          {!!order?.customer_name && (
            <Col>
              <CardItem title="Customer" value={order?.customer_name} />
            </Col>
          )}
        </Row>
      )}
      {!!order?.organization && (
        <Info
          title={order?.organization?.title}
          address={order?.organization?.address}
          email={order?.organization?.contact_person?.email}
          phone={order?.organization?.contact_person?.phone}
          contactPerson={order?.organization?.contact_person}
        />
      )}
    </>
  );
};

CustomerInfo.propTypes = {
  order: PropTypes.object,
};

export default CustomerInfo;
