import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import {
  tiersSelector,
  isPendingSelector as tiersIsPendingSelector,
  paginationSelector,
} from 'models/tiers/selectors';
import { actions as tiersActions } from 'models/tiers/slice';

import { IS_ACTIVE } from './filterConstants';
import { PERIOD_IN_MONTH } from 'constants';
import DataTable from './DataTable';

const TiersSummary = ({ title }) => {
  const fetchTiers = useAction(tiersActions.fetchTiers);
  const tiers = useSelector(tiersSelector);
  const tiersIsPending = useSelector(tiersIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchTiers({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        content_contains: filters.title_contains,
      });
    }
  }, [fetchTiers, currentPage, sortDirection, sortField, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortDirection, sortField, filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasWineclubsFilter
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    disabled={tiersIsPending}
                    hasFilterByPeriodInMonths
                    periodInMonthsFields={PERIOD_IN_MONTH}
                    hasFilterByActiveState
                    isActiveItemsFields={IS_ACTIVE}
                    createItemLink="/tiers/create"
                    placeholder="Search... (By tier name or description)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  isPending={tiersIsPending}
                  tiers={tiers}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

TiersSummary.propTypes = {
  title: PropTypes.string,
};

export default TiersSummary;
