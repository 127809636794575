import React from 'react';

import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import SortArrows from 'components/SortArrows';
import Preloader from 'components/Preloader';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';
import { TRANSFERS_TYPES, W_TO_W_STATUSES } from 'constants';

const DataTable = ({
  isPending,
  transfers,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  currentProducts,
}) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1" className="text-nowrap">
            Invoice #
          </th>
          <th data-priority="1">Type</th>
          <th data-priority="1">Sender</th>
          <th data-priority="1">Receiver</th>
          <th data-priority="1">Items</th>
          <th data-priority="1">Status</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'created_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Created at
            <SortArrows
              isActive={sortField === 'created_at'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="1">Operator</th>
        </tr>
      </thead>

      <tbody>
        {isPending ? (
          <tr>
            <td colSpan={9}>
              <Preloader className={s.preloader} />
            </td>
          </tr>
        ) : (
          transfers?.map(item => (
            <tr key={item.id}>
              <td>
                <Link
                  to={`/transfers/${item.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {item.invoice_number ?? item.transfer_number ?? '-'}
                </Link>
              </td>
              <td>
                <Link
                  to={`/transfers/${item.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {typeof item.parent_transfer_id === 'number'
                    ? 'Inventory adjustment'
                    : TRANSFERS_TYPES[item.resourcetype]}
                </Link>
              </td>
              <td>
                <Link
                  to={`/warehouses/${item.sender?.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {item.sender?.title}{' '}
                </Link>
              </td>
              <td>
                <Link
                  to={`/warehouses/${item.receiver?.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {item.receiver?.title}{' '}
                </Link>
              </td>

              <td>
                <div className="d-flex flex-wrap">
                  {item.transfer_items
                    ?.filter(
                      transfer =>
                        !currentProducts ||
                        currentProducts.length === 0 ||
                        currentProducts.includes(transfer.product_id)
                    )
                    .map((transfer, index) => (
                      <Link
                        key={`${transfer.id}-${index}`}
                        to={`/products/${transfer.product_id}/show`}
                        className="text-dark font-weight-bold mr-2 d-flex align-items-center"
                      >
                        <strong>{transfer.quantity}</strong>&nbsp;
                        <p className="badge badge-secondary font-size-12">
                          {transfer.title}{' '}
                          {!!transfer.vintage && `(${transfer.vintage})`}
                        </p>
                      </Link>
                    ))}
                </div>
              </td>

              <td className="capitalize">
                {item.resourcetype === 'WarehouseToWarehouseTransfer'
                  ? W_TO_W_STATUSES[item.status]
                  : item.status?.toLowerCase()}
              </td>
              <td>
                {item.created_at
                  ? format(Date.parse(item.created_at), 'yyyy-MM-dd, h:mm aaa')
                  : ''}
              </td>
              <td>{item.operator?.email}</td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  transfers: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  currentProducts: PropTypes.array,
};

export default DataTable;
