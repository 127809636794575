import React, { memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './ImagesGalleryPopup.scss';
import { ModalBody, ModalHeader } from 'reactstrap';
import PopupBase from '../PopupBase';
// import classNames from 'classnames';
import Form from './Form';

import useAction from 'hooks/useAction';
import { actions as imagesActions } from 'models/images/slice';
import useSelector from 'hooks/useSelector';
import {
  isPendingSelector,
  imagesSelector,
  paginationSelector,
} from 'models/images/selectors';
import Gallery from './Gallery';
import Footer from './Footer';

const ImagesGalleryPopup = ({ brandId, onClose, handleSubmit }) => {
  const addImage = useAction(imagesActions.addImage);
  const fetchImages = useAction(imagesActions.fetchImages);

  const isPending = useSelector(isPendingSelector);
  const images = useSelector(imagesSelector);
  const pagination = useSelector(paginationSelector);

  const [currentPage, setCurrentPage] = useState(pagination?.current_page);
  const [filters, setFilters] = useState({});

  const [selectedImagesIds, setSelectedImagesIds] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  // const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchImages({
      page: currentPage ?? 1,
      ...filters,
      brand_id: brandId,
    });
  }, [currentPage, filters]);

  const handleAddImageToTheGallery = useCallback(
    values => {
      addImage({ ...values, brand_id: brandId });
    },
    [brandId]
  );

  const handleAddImageToTheEditor = useCallback(
    values => {
      handleSubmit(values);
    },
    [handleSubmit]
  );

  const handleSelectImage = useCallback(
    (image, isSelected) => {
      if (isSelected) {
        setSelectedImages([...selectedImages, image]);
        setSelectedImagesIds([...selectedImagesIds, image.id]);
      } else {
        const newSelectedImages = selectedImages?.filter(
          i => i.id !== image.id
        );
        setSelectedImages(newSelectedImages);
        setSelectedImagesIds(newSelectedImages.map(i => i.id));
      }
    },
    [setSelectedImages, selectedImages, selectedImagesIds, setSelectedImagesIds]
  );

  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <ModalHeader toggle={() => onClose(false)}>Image Gallery</ModalHeader>
      <ModalBody>
        <Form disabled={isPending} onSubmit={handleAddImageToTheGallery} />
        <div className={styles.divider} />
        <Gallery
          filters={filters}
          images={images}
          pagination={pagination}
          isPending={isPending}
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          selectedImages={selectedImagesIds}
          onImageClick={handleSelectImage}
        />
        <Footer
          selectedImages={selectedImages}
          onClick={handleAddImageToTheEditor}
        />
      </ModalBody>
    </PopupBase>
  );
};

ImagesGalleryPopup.propTypes = {
  brandId: PropTypes.number,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default memo(ImagesGalleryPopup);
