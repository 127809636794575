import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Card, CardBody, Table, Button } from 'reactstrap';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as brandsCompaniesActions } from 'models/brandsCompanies/slice';
import {
  isPendingSelector,
  availableCompaniesForBrands,
  currentBrandCompaniesSelector,
  currentBrandCompaniesListSelector,
  availableCompaniesOptionsForSelectSelector,
} from 'models/brandsCompanies/selectors';

import TableRow from './TableRow';
import styles from './BrandsCompaniesForm.scss';
import PaginationTable from '../PaginationTable';
import PropTypes from 'prop-types';

const BrandsCompaniesForm = ({ setCurrentPage, pagination }) => {
  const { brandID } = useParams();
  const brandId = parseInt(brandID, 10);

  const isPending = useSelector(isPendingSelector);
  const companies = useSelector(currentBrandCompaniesSelector);
  const companiesList = useSelector(currentBrandCompaniesListSelector);
  const availableCompanies = useSelector(availableCompaniesForBrands);
  const availableCompaniesOptions = useSelector(
    availableCompaniesOptionsForSelectSelector
  );

  const addCompany = useAction(brandsCompaniesActions.addCurrentBrandCompany);
  const saveCompanies = useAction(brandsCompaniesActions.saveCompanies);
  const deleteCompany = useAction(brandsCompaniesActions.deleteCompany);
  const deleteCompanyItem = useAction(brandsCompaniesActions.deleteCompanyItem);

  const onSave = useCallback(() => {
    const data = companiesList
      .filter(
        company =>
          company.shipping_company_id !== null &&
          company.shipping_company_title !== null
      )
      .map(company => ({
        itemId: company.itemId,
        entity_id: get(company, 'entity_id', null),
        brand_id: get(company, 'entity_id', null),
        shipping_company_id: company.shipping_company_id,
        shipping_company_title: company.shipping_company_title,
        api_key: company.api_key || '',
        is_active: company.is_active,
      }));
    saveCompanies({ data, brandId: parseInt(brandId, 10) });
  }, [companiesList, brandId, saveCompanies]);

  const deleteItem = useCallback(
    (entityId, itemId) => {
      if (entityId) deleteCompany({ id: entityId, itemId });
      else deleteCompanyItem({ itemId });
    },
    [deleteCompany, deleteCompanyItem]
  );

  const rows = useMemo(
    () =>
      Object.keys(companies).map(id => (
        <TableRow
          id={id}
          key={id}
          item={companies[id]}
          deleteItem={deleteItem}
          companies={availableCompanies}
          companiesOptions={availableCompaniesOptions}
        />
      )),
    [companies, availableCompanies, deleteItem, availableCompaniesOptions]
  );

  return (
    <>
      <PaginationTable setCurrentPage={setCurrentPage} pagination={pagination}>
        <Card className={classNames(styles.root, isPending && styles.loading)}>
          <CardBody className={styles.table}>
            <Table bordered responsive className="table-centered">
              <thead>
                <tr>
                  <th data-priority="1">Company</th>
                  <th data-priority="2">API key</th>
                  <th data-priority="3">Message</th>
                  <th data-priority="4">Active</th>
                </tr>
              </thead>
              <tbody>
                {rows}
                <tr>
                  <td colSpan={3}>
                    <Button
                      type="button"
                      color="success"
                      onClick={() => addCompany()}
                      className="waves-effect waves-light mr-1 text-nowrap"
                    >
                      <i className="ri-add-line align-middle mr-2" /> Add
                      company
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </PaginationTable>

      <div className="d-flex justify-content-end">
        <Button color="primary" type="submit" onClick={onSave}>
          Save
        </Button>
      </div>
    </>
  );
};

BrandsCompaniesForm.propTypes = {
  setCurrentPage: PropTypes.func,
  pagination: PropTypes.object,
};

export default BrandsCompaniesForm;
