import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Filter.scss';
import { Button, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import WineclubsPaginatedField from 'components/PaginatedFields/WineclubsPaginatedField';
import { getQuery } from 'utils/getQuery';
import Select from 'react-select';
import useSelector from 'hooks/useSelector';
import { provincesSelector } from 'models/customers/selectors';
import { selectStyles } from 'constants/selectStyles';
import TiersPaginatedField from 'components/PaginatedFields/TiersPaginatedField';
import useQuery from 'hooks/useQuery';
import { getInitialOption } from 'utils/getInitialOption';
import EmailCustomerPaginatedField from '../../../components/PaginatedFields/EmailCustomerPaginatedField';
import { perPagePagination } from 'constants';
import QueryLink from 'components/QueryLink';
import SearchField from 'components/SearchField';
import { EMAIL_TEMPLATES } from '../../../constants/emailTemplates';
import LastEmailPaginatedField from '../../../components/PaginatedFields/LastEmailPaginatedField';

const PER_PAGE_OPTIONS = perPagePagination.map(per => ({
  value: per,
  label: per,
}));

const getPreparedProvinces = provinces => {
  return (provinces ?? []).map(province => ({
    value: province,
    label: province,
  }));
};

const provinceDefaultOption = {
  value: undefined,
  label: 'All provinces',
};

const customersOptions = [
  { value: undefined, label: 'All customers', filter: undefined },
  {
    value: 'ACTIVE',
    label: 'Active club members',
    filter: 'obligation_status',
  },
  { value: true, label: 'Archived', filter: 'archived' },
  {
    value: 'CANCELLED',
    label: 'Cancelled subscription',
    filter: 'obligation_status',
  },
  { value: '1st_time', label: '1st time buyers', filter: 'activity' },
  { value: 'repeat', label: 'Repeat customers', filter: 'activity' },
  { value: 'prospect', label: 'Prospects', filter: 'activity' },
];

const confirmedOptions = [
  { value: undefined, label: 'All customers' },
  { value: false, label: 'Confirmed' },
  { value: true, label: 'Non confirmed' },
];

const getQueryTiers = (ids, titles) => {
  const arrIds = ids?.split(',');
  const arrTitles = titles?.split(',');

  return (arrIds || []).map((id, idx) => ({
    value: id,
    label: arrTitles?.[idx],
  }));
};

const getTiersIds = tiers => (tiers || []).map(({ value }) => value);
const getTiersTitles = tiers => (tiers || []).map(({ label }) => label);

const Filter = ({
  filter,
  setFilter,
  selectedCustomers,
  setSelectedCustomers,
  handleSendEmail,
}) => {
  const query = useQuery();
  const provinces = useSelector(provincesSelector);
  const [wineclub, setWineclub] = useState(
    getQuery('wineclub', 'All wineclubs')
  );
  const [province, setProvince] = useState(
    query.get('province')
      ? {
          value: query.get('province'),
          label: query.get('province'),
        }
      : provinceDefaultOption
  );
  const [tiers, setTiers] = useState(
    getQueryTiers(query.get('tier_ids'), query.get('tier_titles'))
  );
  const [lastEmailTemplate, setLastEmailTemplate] = useState(
    query.get('last_email_template') || ''
  );
  const [confirmed, setConfirmed] = useState(
    getInitialOption(query, 'non_confirmed', confirmedOptions)
  );
  const [emailType, setEmailType] = useState(null);
  const [perPage, setPerPage] = useState(
    getInitialOption(query, 'per_page', PER_PAGE_OPTIONS)
  );
  const [search, setSearch] = useState(query.get('customer_info_contains'));
  const [activity, setActivity] = useState(
    getInitialOption(query, 'activity_value', customersOptions)
  );

  const provincesOptions = useMemo(
    () => [provinceDefaultOption, ...getPreparedProvinces(provinces)],
    [provinces]
  );

  const isPotentialCustomer =
    activity?.filter === 'archived' ||
    tiers?.length > 0 ||
    confirmed?.value === false;

  const getActivityValue = useCallback(
    filterName => {
      if (activity.filter === filterName) {
        return activity.value;
      }
      return undefined;
    },
    [activity]
  );

  useEffect(() => {
    const activityFilter = {
      activity_value: activity.value,
      archived: getActivityValue('archived'),
      obligation_status: getActivityValue('obligation_status'),
      activity: getActivityValue('activity'),
    };
    if (activity?.filter) {
      activityFilter[activity.filter] = activity.value;
    }
    setFilter({
      ...filter,
      ...activityFilter,
      wineclub_id: wineclub?.value,
      wineclub_title: wineclub?.label,
      province: province?.value,
      tier_ids: getTiersIds(tiers),
      tier_titles: getTiersTitles(tiers),
      non_confirmed: confirmed?.value,
      per_page: perPage?.value,
      potential_customers: isPotentialCustomer ? false : undefined,
      customer_info_contains: search,
      last_email_template: lastEmailTemplate,
    });
  }, [
    wineclub,
    province,
    tiers,
    confirmed,
    perPage,
    search,
    activity,
    lastEmailTemplate,
  ]);

  const handleWineclubChange = value => {
    setConfirmed(confirmedOptions[0]);
    setWineclub(value);
    setTiers([]);
    setSelectedCustomers([]);
  };

  const handleTierChange = data => {
    setTiers(data);
    setSelectedCustomers([]);
  };

  const handleConfirmedChange = data => {
    if (data === confirmed) return;

    setConfirmed(data);
    setSelectedCustomers([]);
    setEmailType(null);
  };

  return (
    <Row className="mb-4">
      <Col md="12" className="d-flex mb-2">
        <div className={classNames(styles.big, 'mr-1')}>
          <WineclubsPaginatedField
            isInitialLabel
            onChange={handleWineclubChange}
            value={wineclub}
          />
        </div>
        <div className={classNames(styles.w250, 'mr-1')}>
          <TiersPaginatedField
            isMulti
            brandId={wineclub?.brand_id}
            value={tiers}
            onChange={handleTierChange}
            styles={selectStyles}
            showWineclubTitle
            placeholder="Select Tier"
          />
        </div>
        <div className={classNames(styles.normal, 'mr-1')}>
          <Select
            options={customersOptions}
            value={activity}
            isSearchable={false}
            onChange={setActivity}
            styles={selectStyles}
          />
        </div>
        <div className={classNames(styles.normal, 'mr-1')}>
          <Select
            options={provincesOptions}
            defaultValue={provinceDefaultOption}
            value={province}
            onChange={setProvince}
            placeholder="All provinces"
            styles={selectStyles}
          />
        </div>
      </Col>
      <Col md="12" className="d-flex mb-2">
        {wineclub?.value && (
          <>
            <div className={classNames(styles.normal, 'mr-1')}>
              <Select
                value={confirmed}
                options={confirmedOptions}
                onChange={handleConfirmedChange}
                isSearchable={false}
                styles={selectStyles}
              />
            </div>
            <div className={classNames(styles.w250, 'mr-5')}>
              <LastEmailPaginatedField
                parameters={{ wineclub_id: wineclub?.value }}
                value={
                  lastEmailTemplate
                    ? {
                        label:
                          EMAIL_TEMPLATES[
                            lastEmailTemplate.replace('v1/', '')
                          ] || lastEmailTemplate,
                        value: lastEmailTemplate,
                      }
                    : null
                }
                placeholder="Last email sent"
                onChange={({ value }) => {
                  setLastEmailTemplate(value);
                }}
              />
            </div>
            {selectedCustomers?.length > 0 && (
              <>
                <div className={classNames(styles.big, 'mr-1')}>
                  <EmailCustomerPaginatedField
                    parameters={{ wineclub_id: wineclub?.value }}
                    value={emailType}
                    onChange={setEmailType}
                  />
                </div>
                <Button
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                  type="button"
                  className="waves-effect waves-light text-nowrap d-flex align-items-center"
                  onClick={() => handleSendEmail(emailType)}
                  disabled={!emailType?.value}
                >
                  <i className="ri-send-plane-fill mr-2" /> Send Email
                </Button>
              </>
            )}
          </>
        )}
      </Col>
      <Col md="12" className="d-flex">
        <div className={classNames(styles.small, 'mr-1')}>
          <Select
            options={PER_PAGE_OPTIONS}
            value={perPage}
            onChange={setPerPage}
            styles={selectStyles}
          />
        </div>
        <div className="mr-3">
          <SearchField
            value={search}
            setValue={setSearch}
            className="d-flex align-items-center"
            placeholder="Search... (By name, email)"
          />
        </div>
        <div>
          <QueryLink to="/wineclub-customers/create">
            <Button
              color="success"
              type="button"
              className="waves-effect waves-light mr-1 text-nowrap"
            >
              <i className="ri-add-line align-middle mr-2" /> Create
            </Button>
          </QueryLink>
        </div>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  selectedCustomers: PropTypes.array,
  setSelectedCustomers: PropTypes.func,
  handleSendEmail: PropTypes.func,
};

export default Filter;
