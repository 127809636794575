/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions  */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import styles from './OrderForm.scss';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { AvForm } from 'availity-reactstrap-validation';
import OrderItemsTable from 'components/OrderItemsTable';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import { actions as warehousesActions } from 'models/warehouses/slice';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as customersActions } from 'models/customers/slice';
import { actions as brandsActions } from 'models/brands/slice';
import { actions as orderShipmentsActions } from 'models/orderShipmentRates/slice';
import useAddressInput from 'hooks/useAddressInputState';
import { ORDER_STATUS_FOR_EDIT } from 'constants/orders';

import {
  currentWarehouseSelector,
  isWarehousePendingSelector as warehouseIsPendingSelector,
} from 'models/warehouses/selectors';

import { customerSelector } from 'models/customers/selectors';
import { createdShipmentSelector } from 'models/orderShipmentRates/selectors';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';

import formatPrice from 'utils/formatPrice';
import CreateNewCustomer from './CreateNewCustomer';
import {
  activeTabSelector,
  createdOrderSelector,
  isPendingSelector as ordersIsPendingSelector,
} from 'models/orders/selectors';
import { brandSelector } from 'models/brands/selectors';
import Preloader from 'components/Preloader';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import capitalize from 'lodash/capitalize';
import OrderInfo from './OrderInfo';
import { Redirect } from 'react-router-dom';
import LocationSearchInput from 'components/LocationSearchInput/LocationSearchInput';
import PaymentTab from 'components/PaymentTab';
import { RETAIL_ORDER_PAYMENT_OPTIONS } from 'constants';
import Shipments from 'components/Shipments';
import CustomerPaginatedField from 'components/PaginatedFields/CustomerPaginatedField';
import { getPreparedCustomerOption } from 'utils/getPreparedCustomerOption';
import CardItem from 'components/CardItem';
import { getRedactedValues } from 'utils/getRedactedValues';
import EmailField from 'components/Fields/EmailField';
import PhoneField from 'components/Fields/PhoneField';
import SelectField from 'components/SelectField/SelectField';
import OrderInfoBlock from 'components/OrderInfoBlock';
import { DISCOUNT_TYPES } from 'constants/discounts';

const getWarehouseOption = data =>
  data
    ? {
        value: data?.warehouse?.id,
        label: `${data?.warehouse?.title} (${data?.brand?.title})`,
      }
    : null;

const getCurrentTier = (wineclubs, brandID) =>
  (wineclubs || []).find(wineclub => wineclub?.brand_id === brandID);

const OrderForm = ({ order, isCreateForm }) => {
  const [createCustomerOpen, setCreateCustomerOpen] = useState(false);
  const [isShipmentMethodSelected, setIsShipmentMethodSelected] = useState(
    false
  );
  const createdOrder = useSelector(createdOrderSelector);

  const [orderItems, setOrderItems] = useState(createdOrder?.order_items ?? {});

  const createdShipment = useSelector(createdShipmentSelector);
  const setNewShipment = useAction(orderShipmentsActions.setNewShipment);

  const currentCustomerInfo = useSelector(customerSelector);

  const [currentCustomer, setCurrentCustomer] = useState(order?.customer ?? '');
  const [currentCard, setCurrentCard] = useState('');
  const [cardOptions, setCardOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    getWarehouseOption(order)
  );

  const [email, setEmail] = useState(
    order?.shipping_info?.email ||
      order?.customer?.email ||
      currentCustomerInfo?.email ||
      ''
  );

  const resetCustomer = useAction(customersActions.resetCustomer);
  useEffect(() => {
    resetCustomer();
  }, [resetCustomer]);

  const [phone, setPhone] = useState('');

  const [discount, setDiscount] = useState(
    createdOrder?.discount_percentage ?? order?.discount_percentage ?? ''
  );
  const [discountType, setDiscountType] = useState(DISCOUNT_TYPES[0]);

  const [paymentType, setPaymentType] = useState(
    order
      ? {
          apiValue: order?.expected_payment_type,
          userValue: capitalize(order?.expected_payment_type),
        }
      : {}
  );
  const [payLater, setPayLater] = useState(false);

  const currentWarehouse = useSelector(currentWarehouseSelector);
  const currentBrand = useSelector(brandSelector);

  const activeTab = useSelector(activeTabSelector);
  const fetchWarehouse = useAction(warehousesActions.fetchWarehouse);
  const fetchCustomer = useAction(customersActions.fetchCustomer);
  const fetchBrand = useAction(brandsActions.fetchBrand);
  const createOrder = useAction(ordersActions.createOrder);
  const updateOrder = useAction(ordersActions.updateOrder);
  const setActiveTab = useAction(ordersActions.setCreateOrderTab);
  const capturePayment = useAction(ordersActions.capturePayment);
  const clearCreatedOrder = useAction(ordersActions.clearCreatedOrder);
  const warehousePending = useSelector(warehouseIsPendingSelector);
  const ordersPending = useSelector(ordersIsPendingSelector);
  const { addressData, setAddressData, isInvalidAddress } = useAddressInput(
    order?.shipping_info || currentCustomerInfo?.customer_profile,
    true
  );
  const isError = order?.shipping_info || currentCustomerInfo?.customer_profile;

  const handleChangeAddress = (value, field) => {
    setAddressData(prev => ({ ...prev, [field]: value }));
  };

  const setCustomerInfo = useCallback(data => {
    setEmail(data?.email ?? '');
    setPhone(data?.phone ?? '');
    handleChangeAddress(data?.phone, 'phone');
    handleChangeAddress(data?.country, 'country');
    handleChangeAddress(data?.state, 'state');
    handleChangeAddress(data?.city, 'city');
    handleChangeAddress(data?.line1, 'line1');
    handleChangeAddress(data?.line2 ?? '', 'line2');
    handleChangeAddress(data?.postal_code, 'postal_code');
  }, []);

  const currentTier = useMemo(
    () =>
      getCurrentTier(currentCustomerInfo?.wine_club_members, currentBrand?.id),
    [currentCustomerInfo?.wine_club_members, currentBrand?.id]
  );
  const orderShipmentPrice = useMemo(
    () => order?.shipping_info?.shipments?.[0]?.price,
    [order]
  );

  useEffect(() => {
    setDiscount(
      createdOrder?.discount_percentage ??
        order?.discount_percentage ??
        createdOrder?.discount_amount ??
        order?.discount_amount ??
        ''
    );
  }, [
    createdOrder?.discount_percentage,
    order?.discount_percentage,
    createdOrder?.discount_amount,
    order?.discount_amount,
  ]);

  useEffect(() => {
    setDiscount('');
  }, [discountType]);

  useEffect(() => {
    isCreateForm && clearCreatedOrder();
  }, [clearCreatedOrder, isCreateForm]);

  useEffect(() => {
    setNewShipment(null);
  }, [setNewShipment]);

  useEffect(() => {
    if (createdOrder?.order_items.length) {
      setOrderItems(
        keyBy(
          createdOrder?.order_items.map(item => ({
            ...item,
            ...Object.values(orderItems).find(
              i => i.product_id === item?.product?.id
            ),
            id: item?.id,
            product_id: item?.product?.id,
            product_title: item?.product?.title,
            discount_amount: item?.discount_amount,
            total_price: item?.total_price,
            number: item?.number,
            product_price: item?.product_price,
            discount_percentage: item?.discount_percentage,
            discounted_price_amount: item?.discounted_price_amount,
            discounted_product_price: item?.discounted_product_price,
            total_order_item_discount: item?.total_order_item_discount,
          })),
          'id'
        )
      );
    }
  }, [createdOrder]);

  useEffect(() => {
    if (selectedWarehouse?.value) {
      fetchWarehouse(selectedWarehouse.value);
    }
  }, [selectedWarehouse, fetchWarehouse]);

  useEffect(() => {
    if (currentCustomer) {
      fetchCustomer(currentCustomer.id);
    }
  }, [currentCustomer, fetchCustomer]);

  useEffect(() => {
    setCustomerInfo(
      currentCustomerInfo?.id === order?.customer?.id
        ? order?.shipping_info
        : { ...currentCustomerInfo?.customer_profile, ...currentCustomerInfo }
    );
  }, [order, currentCustomerInfo]);

  useEffect(() => {
    if (currentWarehouse?.brand_id) {
      fetchBrand(currentWarehouse?.brand_id);
    }
  }, [currentWarehouse, fetchBrand]);

  const handleCustomerChange = customer => {
    setCurrentCustomer(customer);
  };

  const handleWarehouseChange = warehouse => {
    setSelectedWarehouse(warehouse);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleDiscountChange = e => {
    const { value } = e.target;
    if (discountType.value === DISCOUNT_TYPES[0].value && value > 100) {
      setDiscount(100);
    } else if (value < 0) {
      setDiscount(0);
    } else {
      setDiscount(value);
    }
  };

  const isFilledFields = React.useMemo(
    () =>
      currentCustomer &&
      selectedWarehouse?.value &&
      addressData?.postal_code &&
      addressData?.city &&
      addressData?.state &&
      email &&
      addressData?.country &&
      addressData?.line1 &&
      phone &&
      Object.values(orderItems).some(el => el.number > 0),
    [orderItems, email, selectedWarehouse, phone, currentCustomer, addressData]
  );

  const isPaymentMethodsCorrect = React.useMemo(
    () =>
      (paymentType.apiValue === 'CARD' && !payLater && currentCard) ||
      (paymentType.apiValue !== 'CARD' && !payLater && paymentType.apiValue) ||
      (paymentType.apiValue && payLater),
    [currentCard, payLater, paymentType]
  );

  const handleCreateOrder = (changeNextStep = true) => {
    const requestObject = {
      customer_id: currentCustomer.id,
      comment: '',
      city: addressData?.city,
      country: addressData?.country,
      line1: addressData?.line1,
      line2: addressData?.line2,
      phone,
      email,
      postal_code: addressData?.postal_code,
      state: addressData?.state,
      products: Object.values(orderItems).reduce((acc, value) => {
        if (value.product_id && value.number) {
          if (value?.resourcetype) {
            acc.push({
              id: value.id,
              product_id: value.product_id,
              number: value.number,
              discount_amount: Number(value.discount_amount),
              discount_percentage: Number(value.discount_percentage),
            });
          } else {
            acc.push({
              product_id: value.product_id,
              number: value.number,
              discount_amount: Number(value.discount_amount),
              discount_percentage: Number(value.discount_percentage),
            });
          }
        }
        return acc;
      }, []),
    };
    if (discountType.value === 'PERCENTAGE') {
      requestObject.discount_percentage = Number(discount);
    } else if (discountType.value === 'AMOUNT') {
      requestObject.discount_amount = Number(discount);
    }
    if (!createdOrder) {
      requestObject.warehouse_id = selectedWarehouse?.value;
    }
    if (!isInvalidAddress) {
      if (isEmpty(createdOrder)) {
        createOrder({
          ...requestObject,
          changeNextStep,
        });
      } else {
        updateOrder({
          ...getRedactedValues(order, requestObject),
          id: createdOrder?.id,
          changeNextStep,
        });
      }
    }
  };

  const handlePay = () => {
    capturePayment({
      id: createdOrder.id,
      payment_method_id: currentCard?.id,
      shipment_rate_ext_id: createdShipment?.id,
      shipment_ext_id: createdShipment?.shipment_id,
      shipping_company_id: createdShipment?.brand_shipping_company_id,
      price: createdShipment?.price,
      email: createdOrder?.shipping_info?.email,
    });
  };

  useEffect(() => {
    return () => {
      setActiveTab(1);
      clearCreatedOrder();
    };
  }, [setActiveTab, clearCreatedOrder]);

  if (order && !ORDER_STATUS_FOR_EDIT.includes(order?.status)) {
    return <Redirect to="/orders/" />;
  }

  return (
    <AvForm>
      <Row>
        <Col lg={12}>
          <div id="checkout-nav-pills-wizard" className="twitter-bs-wizard">
            <Nav pills justified className="twitter-bs-wizard-nav">
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  className={classNames(activeTab === 1 && 'active')}
                >
                  <span className="step-number">01</span>
                  <span className="step-title">General</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    if (createdOrder) {
                      setActiveTab(2);
                    }
                  }}
                  className={classNames(activeTab === 2 && 'active')}
                  disabled={!isFilledFields}
                >
                  <span
                    className={classNames(
                      'step-number',
                      !isFilledFields && styles.disabled
                    )}
                  >
                    02
                  </span>
                  <span className="step-title">Order Info</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(3);
                  }}
                  active={activeTab === 3}
                  disabled={
                    !isFilledFields ||
                    !createdOrder ||
                    !isShipmentMethodSelected
                  }
                >
                  <span
                    className={classNames(
                      'step-number',
                      (!isFilledFields ||
                        !createdOrder ||
                        !isShipmentMethodSelected) &&
                        styles.disabled
                    )}
                  >
                    03
                  </span>
                  <span className="step-title">Payment Info</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(4);
                    handleCreateOrder(false);
                  }}
                  className={classNames(activeTab === 4 && 'active')}
                  disabled={
                    !isFilledFields || !createdOrder || !isPaymentMethodsCorrect
                  }
                >
                  <span
                    className={classNames(
                      'step-number',
                      (!isFilledFields ||
                        !createdOrder ||
                        !isPaymentMethodsCorrect) &&
                        styles.disabled
                    )}
                  >
                    04
                  </span>
                  <span className="step-title">Total</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>
      <TabContent
        activeTab={activeTab}
        className="twitter-bs-wizard-tab-content"
      >
        <TabPane tabId={1}>
          {ordersPending ? (
            <Preloader className={styles.preloader} />
          ) : (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label required htmlFor="warehouse_id">
                      Warehouse
                    </Label>
                    <WarehousesPaginatedField
                      value={selectedWarehouse}
                      isMulti={false}
                      onChange={handleWarehouseChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label required htmlFor="customer">
                      Customer
                    </Label>
                    <CustomerPaginatedField
                      name="customer"
                      value={getPreparedCustomerOption(currentCustomer)}
                      isDisabled={!selectedWarehouse}
                      onChange={handleCustomerChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="mt-4" md={4}>
                  {createCustomerOpen ? (
                    <Button
                      color="danger"
                      onClick={() => setCreateCustomerOpen(false)}
                      className={styles.button}
                    >
                      Close
                    </Button>
                  ) : (
                    <Button
                      color="success"
                      onClick={() => setCreateCustomerOpen(true)}
                      disabled={!selectedWarehouse}
                    >
                      <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                      New Customer
                    </Button>
                  )}
                </Col>
              </Row>
              <div
                className={classNames(
                  styles.customer,
                  createCustomerOpen && styles.customerActive
                )}
              >
                <CreateNewCustomer
                  setCreateCustomerOpen={setCreateCustomerOpen}
                  setCurrentCustomer={setCurrentCustomer}
                  wineclubId={currentBrand.wine_club_id}
                />
              </div>
              {currentCustomerInfo && selectedWarehouse && (
                <>
                  <Row>
                    <Col md="4">
                      <EmailField
                        isRequired
                        name="email"
                        label="Email"
                        placeholder="Email"
                        errorMessage="Enter Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </Col>
                    <Col md="8">
                      <LocationSearchInput
                        model={
                          order?.shipping_info ||
                          currentCustomerInfo?.customer_profile ||
                          {}
                        }
                        country={addressData?.country}
                        state={addressData?.state}
                        postalCode={addressData?.postal_code}
                        city={addressData?.city}
                        line1={addressData?.line1}
                        line2={addressData?.line2}
                        setCountry={data =>
                          handleChangeAddress(data, 'country')
                        }
                        setState={data => handleChangeAddress(data, 'state')}
                        setPostalCode={data =>
                          handleChangeAddress(data, 'postal_code')
                        }
                        setCity={data => handleChangeAddress(data, 'city')}
                        setLine1={data => handleChangeAddress(data, 'line1')}
                        setLine2={data => handleChangeAddress(data, 'line2')}
                        isRequired
                        error={
                          isError &&
                          isInvalidAddress &&
                          'Please enter a valid address'
                        }
                      />
                    </Col>
                    <Col md="4">
                      <PhoneField
                        isRequired
                        name="phone"
                        label="Phone"
                        placeholder="Phone"
                        errorMessage="Enter Phone"
                        value={phone}
                        onChange={setPhone}
                      />
                    </Col>
                    {!!currentTier?.subscripted_tier?.title && (
                      <Col>
                        <FormGroup>
                          <Label htmlFor="wineclub">Wine Club</Label>
                          <p className={styles.centeredParagraph}>
                            {currentTier?.subscripted_tier?.title}{' '}
                            {currentTier?.subscripted_tier
                              ?.percentage_discount ? (
                              <>
                                {
                                  currentTier.subscripted_tier
                                    .percentage_discount
                                }
                                %
                              </>
                            ) : currentTier?.subscripted_tier
                                ?.discount_percentage ? (
                              <>
                                {
                                  currentTier.subscripted_tier
                                    .discount_percentage
                                }
                                %
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md="12">
                      {warehousePending ? (
                        <Preloader />
                      ) : (
                        <>
                          {currentWarehouse?.id && (
                            <OrderItemsTable
                              warehouseId={currentWarehouse.id}
                              orderItems={orderItems}
                              onOrderItemsChange={setOrderItems}
                              priceType="retail_price"
                            />
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {!!orderShipmentPrice && (
                      <Col md={3}>
                        <CardItem
                          title="Shipment price"
                          value={`$ ${orderShipmentPrice}`}
                        />
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {isFilledFields && (
                <>
                  {createdOrder && (
                    <>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label className="mb-0" htmlFor="discount">
                              {discountType.value === 'PERCENTAGE'
                                ? 'Discount Percentage'
                                : 'Discount Amount'}
                            </Label>
                            <input
                              name={
                                discountType.value === 'PERCENTAGE'
                                  ? 'discount_percentage'
                                  : 'discount_amount'
                              }
                              placeholder="Discount"
                              type="text"
                              className="form-control mt-2"
                              id="discount"
                              value={discount}
                              onFocus={e =>
                                e.target.addEventListener(
                                  'wheel',
                                  function(evt) {
                                    evt.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              onChange={handleDiscountChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <SelectField
                            className="mb-2"
                            name="discount_type"
                            defaultValue={DISCOUNT_TYPES[0]}
                            onChange={option => {
                              setDiscountType(option);
                            }}
                            value={discountType}
                            label="Discount Type"
                            options={DISCOUNT_TYPES}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" s="8" md="8" l="4">
                          <Card>
                            <CardBody>
                              <OrderInfoBlock
                                title="Product Subtotal"
                                value={`${formatPrice(
                                  parseFloat(
                                    createdOrder?.order_items?.reduce(
                                      (sum, oi) =>
                                        sum + oi.product_price * oi.number,
                                      0
                                    )
                                  ) || 0
                                )} ${createdOrder?.total_price_currency}`}
                              />

                              {createdOrder?.total_order_discount > 0 && (
                                <OrderInfoBlock
                                  title="Discounts"
                                  value={`-${formatPrice(
                                    parseFloat(
                                      createdOrder?.total_order_discount
                                    ) || 0
                                  )} ${createdOrder?.total_price_currency}`}
                                />
                              )}

                              {createdOrder?.applied_fees && (
                                <>
                                  {Object.keys(createdOrder?.applied_fees).map(
                                    fee => (
                                      <OrderInfoBlock
                                        title={fee}
                                        value={`${formatPrice(
                                          createdOrder?.applied_fees[fee] ?? 0
                                        )} ${
                                          createdOrder?.total_price_currency
                                        }`}
                                      />
                                    )
                                  )}
                                </>
                              )}

                              {createdShipment && (
                                <OrderInfoBlock
                                  title="Shipping"
                                  value={`${
                                    createdShipment.free_shipping
                                      ? '0.00'
                                      : createdShipment?.price.toFixed(2)
                                  } ${createdOrder?.total_price_currency}`}
                                />
                              )}
                              <hr />

                              <OrderInfoBlock
                                title="Total"
                                value={`${formatPrice(
                                  createdOrder?.total_price
                                ) || 0} ${createdOrder?.total_price_currency}`}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Button
                    color="primary"
                    onClick={() => {
                      handleCreateOrder(false);
                    }}
                    disabled={ordersPending}
                    className="mt-2 mr-2"
                  >
                    {createdOrder ? 'Update' : 'Create Order'}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      // setActiveTab(2);
                      handleCreateOrder();
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    className="mt-2"
                  >
                    Next step
                  </Button>
                </>
              )}
            </>
          )}
        </TabPane>
        <TabPane tabId={2}>
          {activeTab === 2 && !ordersPending && (
            <Shipments
              orderType="Retail"
              order={createdOrder}
              buttonTitle="Next step"
              nextAction={() => {
                setActiveTab(3);
                setIsShipmentMethodSelected(true);
                updateOrder({
                  id: createdOrder?.id,
                });
              }}
            />
          )}
        </TabPane>
        <TabPane tabId={3}>
          {!currentBrand?.active_stripe_acquirer?.public_key && (
            <Alert color="warning">
              <i className="mdi mdi-information" />
              <b>No active acquirer</b>
              <p>Please add one in brands settings.</p>
            </Alert>
          )}
          {createdOrder &&
            currentBrand?.active_stripe_acquirer?.public_key &&
            activeTab === 3 && (
              <>
                <PaymentTab
                  stripeToken={currentBrand?.active_stripe_acquirer?.public_key}
                  stripeAccount={
                    currentBrand?.active_stripe_acquirer?.account_id
                  }
                  currentCustomerId={currentCustomerInfo?.id}
                  currentBrand={currentBrand}
                  cardOptions={cardOptions}
                  setCardOptions={setCardOptions}
                  setCurrentCard={setCurrentCard}
                  currentCard={currentCard}
                  paymentType={paymentType}
                  setActiveTab={setActiveTab}
                  setPaymentType={setPaymentType}
                  payLater={payLater}
                  options={RETAIL_ORDER_PAYMENT_OPTIONS}
                  setPayLater={setPayLater}
                  withoutType
                />
                {!isEmpty(paymentType) && isPaymentMethodsCorrect && (
                  <Button
                    color="primary"
                    className="mt-2"
                    onClick={() => {
                      setActiveTab(4);
                      handleCreateOrder(false);
                    }}
                  >
                    Next step
                  </Button>
                )}
              </>
            )}
        </TabPane>
        <TabPane tabId={4}>
          {isPaymentMethodsCorrect && createdOrder && (
            <OrderInfo
              organization={currentCustomerInfo}
              order={createdOrder}
              handlePay={handlePay}
              createdShipment={createdShipment}
              handleCreateOrder={handleCreateOrder}
              currentCard={currentCard}
              payLater={payLater}
              paymentType={paymentType?.apiValue}
            />
          )}
        </TabPane>
      </TabContent>
    </AvForm>
  );
};

OrderForm.propTypes = {
  order: PropTypes.object,
  isCreateForm: PropTypes.bool,
};

export default OrderForm;
