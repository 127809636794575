import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './ProductInventories.scss';
import PaginationTable from 'components/PaginationTable';

const ProductInventories = ({ inventories, setCurrentPage, pagination }) => {
  return (
    <Card>
      <CardBody>
        <h3 className={classNames(styles.title, 'mb-3')}>Products</h3>
        <PaginationTable
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        >
          <thead>
            <th>SKU</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Warehouses</th>
          </thead>
          <tbody>
            {inventories?.map(product => (
              <tr>
                <td>{product.sku}</td>
                <td>{product.title}</td>
                <td>{product.total_inventory_count}</td>
                <td>
                  <div className="d-flex flex-wrap">
                    {product.inventories?.map((warehouse, index) => (
                      <Link
                        key={`${warehouse.warehouse_id}-${index}`}
                        to={`/warehouses/${warehouse.warehouse_id}/show`}
                        className="text-dark font-weight-bold mr-2 d-flex align-items-center"
                      >
                        <strong>{warehouse.product_count}</strong>&nbsp;
                        <div className="badge badge-secondary font-size-12">
                          {warehouse.warehouse_title}
                        </div>
                      </Link>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </PaginationTable>
        {!inventories ||
          (inventories?.length === 0 && (
            <h5 className={classNames(styles.infoText, 'mb-3', 'my-1')}>
              No results found
            </h5>
          ))}
      </CardBody>
    </Card>
  );
};

ProductInventories.propTypes = {
  inventories: PropTypes.array,
  setCurrentPage: PropTypes.func,
  pagination: PropTypes.object,
};

export default ProductInventories;
