import React, { useState } from 'react';
import { Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import { PERIOD_OPTIONS } from 'constants/commissions';
import CommissionPercentages from './CommissionPercentages';
import PropTypes from 'prop-types';

const SalesRepCommission = ({ onChange, defaultValues }) => {
  const [period, setPeriod] = useState(
    defaultValues?.sales_rep_commission_period
  );
  const [percentages, setPercentages] = useState(
    defaultValues.sales_rep_commission_rates
  );

  const onSelectChangeHandler = newPeriod => {
    setPeriod(newPeriod);
    onChange?.({
      sales_rep_commission_rates: percentages,
      sales_rep_commission_period: newPeriod,
    });
  };

  const onPercentagesChangeHandler = newPercentages => {
    setPercentages(newPercentages);
    onChange?.({
      sales_rep_commission_period: period,
      sales_rep_commission_rates: newPercentages,
    });
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">Sales Rep Commissions</h4>
        <Row className="mt-4">
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="period">Commission Period</Label>
              <Select
                id="period"
                value={period}
                onChange={onSelectChangeHandler}
                options={PERIOD_OPTIONS}
                styles={selectStyles}
              />
            </FormGroup>
          </Col>
          <Col md={9}>
            <FormGroup>
              <Label>Commission Percentages</Label>
              <CommissionPercentages
                percentagesData={percentages}
                onChange={onPercentagesChangeHandler}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

SalesRepCommission.propTypes = {
  onChange: PropTypes.func,
  defaultValues: PropTypes.object,
};

export default SalesRepCommission;
