import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as reportsActions } from 'models/reports/slice';
import { dashboardUrlSelector } from 'models/reports/selectors';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';

const Dashboard = ({ title }) => {
  const fetchDashboardUrl = useAction(reportsActions.fetchDashboardUrl);
  const dashboardUrl = useSelector(dashboardUrlSelector);

  const [selectedBrand, setSelectedBrand] = useState({
    value: undefined,
    label: 'All brands',
  });

  useEffect(() => {
    fetchDashboardUrl(selectedBrand);
  }, [selectedBrand]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col xs="4">
          <Card>
            <CardBody>
              <p>Brand</p>
              <BrandsPaginatedField
                onChange={setSelectedBrand}
                defaultValue={selectedBrand}
                isMulti={false}
                isInitialLabel
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <div style={{ height: 900, overflow: 'hidden' }}>
            <iframe
              title="dashboard"
              src={dashboardUrl}
              frameBorder={0}
              width="100%"
              height={1000}
              allowTransparency
            />
          </div>
        </Col>
      </Row>
    </PageWrapper>
  );
};

Dashboard.propTypes = {
  title: PropTypes.string,
};

export default Dashboard;
