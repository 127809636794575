import React, { useEffect } from 'react';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { Card, CardBody, Alert } from 'reactstrap';
import InventoryTransferTable from 'components/InventoryTransferTable';
import { TRANSFER_TABLE_MODES } from 'components/InventoryTransferTable/constants';
import PropTypes from 'prop-types';
import { actions as inventoriesActions } from 'models/inventories/slice';
import { tasterCountsByWarehouseIdSelector } from 'models/inventories/selectors';

const WineCount = ({
  inventories,
  setEditorMode,
  selectedWarehouse,
  inventoryCount,
  inventoryCountType,
  onSubmit,
  isManual,
}) => {
  const fetchTasterCountByWarehouseId = useAction(
    inventoriesActions.fetchTasterCountByWarehouseId
  );

  const tasterCountsByWarehouseId = useSelector(
    tasterCountsByWarehouseIdSelector
  );

  useEffect(() => {
    fetchTasterCountByWarehouseId(selectedWarehouse?.value);
  }, [selectedWarehouse]);

  const hasTasterBottles =
    tasterCountsByWarehouseId[selectedWarehouse?.value]?.inventory_results
      ?.length > 0;

  return (
    <Card>
      <CardBody>
        {hasTasterBottles && (
          <Alert color="warning">
            <div>
              <i className="mdi mdi-information" />{' '}
              <b>There is a Taster Count that has not been approved</b>
              <p>
                Note that the number of Taster Bottles will automatically be
                included in the Product Sum
              </p>
            </div>
          </Alert>
        )}
        <InventoryTransferTable
          key="WineCountInventoryTransferTable"
          data={inventories}
          editorMode={TRANSFER_TABLE_MODES.PROCESS}
          setEditorMode={setEditorMode}
          isManual={isManual}
          inventoryCount={inventoryCount}
          inventoryCountType={inventoryCountType}
          onSubmit={onSubmit}
          tasterCount={tasterCountsByWarehouseId[selectedWarehouse?.value]}
        />
      </CardBody>
    </Card>
  );
};

WineCount.propTypes = {
  inventories: PropTypes.array.isRequired,
  selectedWarehouse: PropTypes.object.isRequired,
  inventoryCount: PropTypes.bool.isRequired,
  inventoryCountType: PropTypes.string.isRequired,
  setEditorMode: PropTypes.func.isRequired,
  isManual: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default WineCount;
