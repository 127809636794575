import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isOnlyHostSelector } from 'models/user/selectors';
import { actions } from 'models/inventories/slice';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { initializedManualCountPaginationSelector } from 'models/inventories/selectors';

const ManualCountTitle = ({ title }) => {
  // Actions
  const fetchInitializedManualCount = useAction(
    actions.fetchInitializedManualCount
  );

  // Selectors
  const pagination = useSelector(initializedManualCountPaginationSelector);
  const isHost = useSelector(isOnlyHostSelector);

  // Effects
  useEffect(() => {
    if (!isHost) {
      fetchInitializedManualCount({ setQuery: false });
    }
  }, [fetchInitializedManualCount, isHost]);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}{' '}
      {!isHost && totalCount !== 0 && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

ManualCountTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ManualCountTitle;
