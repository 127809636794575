import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Alert } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as brandsActions } from 'models/brands/slice';
import {
  brandsSelector,
  isPendingSelector,
  paginationSelector,
} from 'models/brands/selectors';
import * as importDataSourcesSelectors from 'models/importDataSources/selectors';
import { actions as importDataSourcesActions } from 'models/importDataSources/slice';
import { actions as importCustomerActions } from 'models/importCustomers/slice';

import DataTable from './DataTable';
import ImportBrandPopup from 'components/Popup/ImportBrandPopup';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import { failedWineDirectSyncsSelector } from '../../models/wineDirectSync/selectors';
import { NavLink } from 'react-router-dom';

const getIdsBrandsForImport = data => {
  return data.map(item => item?.brand?.id);
};

const BrandsSummary = ({ title }) => {
  const fetchBrands = useAction(brandsActions.fetchBrands);
  const fetchDataSources = useAction(importDataSourcesActions.fetchDataSources);
  const runImport = useAction(importDataSourcesActions.runImport);
  const deleteBrand = useAction(brandsActions.deleteBrand);
  const importFromFile = useAction(importCustomerActions.uploadImport);
  const resetModalDataImported = useAction(
    importDataSourcesActions.resetModalDataImported
  );

  const pagination = useSelector(paginationSelector);
  const brands = useSelector(brandsSelector);
  const dataSources = useSelector(
    importDataSourcesSelectors.dataSourcesSelector
  );
  const dataImports = useSelector(
    importDataSourcesSelectors.dataImportsSelector
  );
  const isPendingImport = useSelector(
    importDataSourcesSelectors.importIsPendingSelector
  );
  const isPending = useSelector(isPendingSelector);
  const failedWineDirectSyncs = useSelector(failedWineDirectSyncsSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [importedFromFileBrands, setImportedFromFileBrands] = useState([]);

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  const [brandForDelete, setBrandForDelete] = useState(null);
  const [selectedImportData, setSelectedImportData] = useState([]);
  const [importID, setImportID] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmImportFromFile, setConfirmImportFromFile] = useState(false);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  const openImportModal = id => {
    if (Object.keys(dataImports).length) resetModalDataImported();

    const sources = dataSources.filter(item => id === item?.brand?.id);

    if (sources.length > 1) {
      setImportID(sources[0]?.id);
      setSelectedImportData(sources);
    } else {
      setImportID(sources[0]?.id);
    }
  };

  const importHandler = data => {
    runImport({ ...data, id: importID });
    setSelectedImportData([]);
  };

  useEffect(() => {
    fetchDataSources();
  }, [fetchDataSources]);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchBrands({
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        set_querty: true,
        ...filters,
      });
    }
  }, [
    filters,
    fetchBrands,
    currentPage,
    sortDirection,
    sortField,
    dataSources,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortDirection, sortField, dataSources]);

  const handleConfirmationModalSubmit = () => {
    if (brandForDelete) {
      deleteBrand(brandForDelete);
      setBrandForDelete(null);
    }
  };

  const handleImportFromFile = (file, brand) => {
    setImportedFromFileBrands([...importedFromFileBrands, brand]);
    importFromFile({
      file,
      brand_id: brand,
    });
    setConfirmImportFromFile(true);
  };

  const firstBrandWithFailedSync =
    failedWineDirectSyncs?.brands_with_failed_syncs?.[0];
  const failedSyncExists = !!firstBrandWithFailedSync;

  const firstBrandWithFailedEntities =
    failedWineDirectSyncs?.brands_with_failed_entities?.[0];
  const failedEntitiesExistForBrand = !!firstBrandWithFailedEntities;

  const wineDirectSettingsTab = 3;
  const failedSyncLink = `/brands/${firstBrandWithFailedSync}/edit/${wineDirectSettingsTab}`;
  const failedEntitiesLink = `/brands/${firstBrandWithFailedEntities}/edit/${wineDirectSettingsTab}`;

  return (
    <PageWrapper title={title}>
      {failedSyncExists && (
        <NavLink to={failedSyncLink}>
          <Alert color="danger">
            <i className="mdi mdi-information" />
            <b>WineDirect sync failure</b>
            <br />
            Orders may have been placed online that have not been imported into
            Cellar. If this persists contact Cellar support for assistance.
          </Alert>
        </NavLink>
      )}

      {failedEntitiesExistForBrand && !failedSyncExists && (
        <NavLink to={failedEntitiesLink}>
          <Alert color="warning">
            <i className="mdi mdi-information" />
            <b>WineDirect sync failure for some entities</b>
            <br />
            There are customers, products or orders that have not been imported
            into Cellar. If this persists contact Cellar support for assistance.
          </Alert>
        </NavLink>
      )}

      <Breadcrumbs title={title} />

      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/brands/create"
                    disabled={isPending}
                    placeholder="Search... (By brand name)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  brandsForImport={getIdsBrandsForImport(dataSources)}
                  isPending={isPending}
                  isPendingImport={isPendingImport}
                  setBrandId={setBrandForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  openImportModal={openImportModal}
                  brands={brands}
                  onImportFromFile={handleImportFromFile}
                  importedFromFileBrands={importedFromFileBrands}
                />

                {/* TODO: нейминг isOpen, setIsOpen кажется нелогичным в условиях того,
                что конкретно в этих пропсах передается, надо пофиксить */}

                {importID && (
                  <ImportBrandPopup
                    isOpen={importID}
                    setIsOpen={setImportID}
                    importHandler={importHandler}
                    isPending={isPendingImport}
                    data={dataImports}
                    selectedData={selectedImportData}
                    setSelectedData={setSelectedImportData}
                  />
                )}

                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    onSaveClick={handleConfirmationModalSubmit}
                  />
                )}

                {confirmImportFromFile && (
                  <ConfirmationPopup
                    setActive={() => {
                      setConfirmImportFromFile(false);
                    }}
                    title=""
                    submitButtonTitle="Close"
                    submitButtonColor="primary"
                    showCloseButton={false}
                    onSaveClick={() => {
                      setConfirmImportFromFile(false);
                    }}
                  >
                    <p className="text-center font-weight-bold">
                      You can leave this screen as Cellar imports your list in
                      the background. We’ll email you once it’s completed.
                    </p>
                  </ConfirmationPopup>
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

BrandsSummary.propTypes = {
  title: PropTypes.string,
};

export default BrandsSummary;
