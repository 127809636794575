import React, { memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FieldType } from '../types.d';
import cx from 'classnames';

import styles from './NumberField.scss';

const NumberField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  value,
  className,
  fieldClassName,
  min,
  ...props
}) => {
  return (
    <FormGroup className={className}>
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <AvField
        name={name}
        type="number"
        className={cx('form-control', styles.field, fieldClassName)}
        validate={{
          required: { value: !!isRequired },
          ...rules,
        }}
        error
        value={value}
        onFocus={event => {
          event.target.addEventListener(
            'wheel',
            function(e) {
              e.preventDefault();
            },
            { passive: false }
          );
          event.target.select();
        }}
        onChange={onChange}
        min={min}
        {...props}
      />
    </FormGroup>
  );
};

NumberField.propTypes = FieldType;

export default memo(NumberField);
