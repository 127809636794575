import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PopupBase from '../PopupBase';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import styles from './EmailPopup.scss';
import Select from 'react-select';
import EmailField from '../../Fields/EmailField';

const getEmails = brands => {
  const options = [];

  (brands ?? []).forEach(brand => {
    if (brand?.contact_email) {
      options.push({
        value: brand?.contact_email,
        label: brand?.contact_email,
      });
    }
  });

  return options;
};

const EmailPopup = ({ onClose, brands, onSubmit }) => {
  const emailOptions = useMemo(() => getEmails(brands), [brands]);
  const [selectedEmail, setSelectedEmail] = useState(emailOptions[0]?.value);
  const [customEmail, setCustomEmail] = useState('');
  const isDisabled = !brands || emailOptions.length === 0;

  const handleSubmit = () => {
    if (customEmail || selectedEmail) {
      onSubmit({
        email: customEmail || selectedEmail,
      });
    }
  };

  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <AvForm onValidSubmit={handleSubmit}>
        <Row md="12" className={styles.container}>
          <Col md="5">
            <FormGroup>
              <Label>Select Email</Label>
              <Select
                isDisabled={isDisabled}
                name="selectedEmail"
                defaultValue={emailOptions[0]}
                options={emailOptions}
                onChange={data => setSelectedEmail(data?.value)}
              />
            </FormGroup>
          </Col>
          <Col md="1" className={styles.orLabel}>
            or
          </Col>
          <Col md="6">
            <EmailField
              name="customEmail"
              label="Custom Email"
              value={customEmail}
              onChange={event =>
                setCustomEmail(event.target.value.toLowerCase())
              }
            />
          </Col>
        </Row>
        <Button
          className="d-block ml-auto mt-2"
          color="primary"
          disabled={!selectedEmail && !customEmail}
        >
          Continue
        </Button>
      </AvForm>
    </PopupBase>
  );
};

EmailPopup.propTypes = {
  onClose: PropTypes.func,
  brands: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default memo(EmailPopup);
