import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

import { actions as importDataSourcesActions } from 'models/importDataSources/slice';
import { actions as reportsActions } from 'models/reports/slice';

function sharedReducer(state, { payload }) {
  state.isOpenImportDataModal = true;
  state.data = {
    notImportedProducts: payload?.data?.not_imported_products,
    notImportedTiers: payload?.data?.not_imported_tiers,
    notImportedWineClubMembers: payload?.data?.not_imported_wine_club_members,
  };
}

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    data: {},
    isOpenImportDataModal: false,
    isOpenConfirmationModal: false,
    isOpenEmailModal: false,
    isOpenSuccessModal: false,
  },
  reducers: {
    setIsOpenConfirmationModal: state => {
      state.isOpenConfirmationModal = !state.isOpenConfirmationModal;
    },
    setIsOpenImportDataModal(state, { payload }) {
      state.isOpenImportDataModal = payload;
    },
    resetDataModal: state => {
      state.data = {};
    },
    setIsOpenEmailModal(state, { payload }) {
      state.isOpenEmailModal = payload;
    },
    setIsOpenSuccessModal(state, { payload }) {
      state.isOpenSuccessModal = payload;
    },
    resetDemoWorkspace() {},
    resetDemoWorkspaceSuccess() {
      window.location.reload();
    },
  },
  extraReducers: {
    [importDataSourcesActions.runImportSuccess]: sharedReducer,
    [importDataSourcesActions.runImportFailure]: sharedReducer,
    [reportsActions.fetchSalesXlsxSuccess]: state => {
      state.isOpenEmailModal = false;
      state.isOpenSuccessModal = true;
    },
    [reportsActions.fetchInventoriesXlsxSuccess]: state => {
      state.isOpenEmailModal = false;
      state.isOpenSuccessModal = true;
    },
  },
});

export const actions = actionTypes(commonSlice.actions);

export default commonSlice.reducer;
