export const MILLISECONDS_IN_AN_HOUR = 3600000;
export const MILLISECONDS_IN_A_MINUTE = 60000;

export const DAYS_IN_BI_WEEKLY_PERIOD = 14;
export const DAYS_TO_END_OF_PERIOD = 13;

export const END_OF_DAY_HOURS = 23;
export const END_OF_DAY_MINUTES = 59;
export const END_OF_DAY_SECONDS = 59;
export const END_OF_DAY_MILLISECONDS = 999;

export const START_OF_DAY_HOURS = 0;
export const START_OF_DAY_MINUTES = 0;
export const START_OF_DAY_SECONDS = 0;
export const START_OF_DAY_MILLISECONDS = 0;
