import { useEffect, useState } from 'react';
import { getPreparedText } from 'utils/getPreparedText';

export const useEditorState = defaultValue => {
  const [value, setValue] = useState(defaultValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setError(
      touched && !getPreparedText(value) ? 'This field is required' : null
    );
  }, [value, touched]);

  return [value, setValue, error, setTouched];
};
