import { PERMISSION_TYPES } from 'constants/permissions';
import { arrayIntersection } from './arrayIntersection';

export const hasPermissions = (permissions, roles) => {
  const preparedPermissions = permissions ?? [
    PERMISSION_TYPES.MANAGER,
    PERMISSION_TYPES.OWNER,
  ];

  return arrayIntersection(preparedPermissions, roles).length > 0;
};

export const hasSettings = (workspace_settings, settings) =>
  settings && workspace_settings?.some(setting => settings[setting] === true);
