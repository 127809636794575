import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as exciseActions } from 'models/excise/slice';
import { AvForm } from 'availity-reactstrap-validation';
import {
  DATE_FIELDS,
  INITIAL_STATE,
  DEFAULT_VALUES_EXCISE_USER_DETAIL_SPIRITS,
  DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE,
  DEFAULT_VALUES_EXCISE_USER_DETAIL_RESTRICTED_FORMULATIONS,
  EXCISE_USER_TABLE_COLUMNS_SPIRITS,
  EXCISE_USER_TABLE_COLUMNS_WINE,
  COLUMNS_SPIRITS,
  COLUMNS_WINE,
  COLUMNS_RESTRICTED_FORMULATIONS,
  ADDITIONS_TO_INVENTORY_SPIRITS_WINE_COLS,
  REDUCTIONS_TO_INVENTORY_SPIRITS_COLS,
  REDUCTIONS_TO_INVENTORY_WINE_COLS,
  ADDITIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS,
  REDUCTIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS,
} from './constants';
import PropTypes from 'prop-types';
import { Button, Col, Row, Alert } from 'reactstrap';
import styles from 'pages/ExciseReturn/ExciseReturn.scss';
import NumberField from 'components/Fields/NumberField';
import {
  DateFields,
  MultipleFieldRow,
  renderTitleFields,
  TotalRow,
} from 'utils/exciseReturns';
import { exciseReturnsSelector } from 'models/excise/selectors';
import formatPrice from 'utils/formatPrice';

const UserForm = ({
  model,
  onSubmit,
  disabled,
  submitTitle,
  exciseLicense,
}) => {
  let formRef = useRef(null);

  const fetchExciseReturns = useAction(exciseActions.fetchExciseReturns);
  const fetchExciseReturnSummary = useAction(
    exciseActions.fetchExciseReturnSummary
  );
  const exciseReturnsList = useSelector(exciseReturnsSelector);

  const [state, setState] = useState(!model ? INITIAL_STATE : model);
  const [spiritsState, setSpiritsState] = useState(
    DEFAULT_VALUES_EXCISE_USER_DETAIL_SPIRITS
  );
  const [wineState, setWineState] = useState(
    DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE
  );
  const [
    restrictedFormulationsState,
    setRestrictedFormulationsState,
  ] = useState(DEFAULT_VALUES_EXCISE_USER_DETAIL_RESTRICTED_FORMULATIONS);

  const [showForm, setShowForm] = useState(!!model);
  const [dateState, setDateState] = useState({
    period_start: model?.period_start || '',
    period_end: model?.period_end || '',
    due_date: model?.due_date || '',
  });

  const setVariable = (key, value) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };
  const setSpiritsVariable = (key, value) => {
    setSpiritsState(prevState => ({ ...prevState, [key]: value }));
  };
  const setWineVariable = (key, value) => {
    setWineState(prevState => ({ ...prevState, [key]: value }));
  };
  const setRestrictedFormulationsVariable = (key, value) => {
    setRestrictedFormulationsState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const validateDateFields = () => {
    const { period_start, period_end, due_date } = dateState;
    const errors = {};

    if (Date.parse(period_start) >= Date.parse(period_end)) {
      errors.period_start = 'Start date must be before end date.';
      errors.period_end = 'End date must be after start date.';
    }

    if (Date.parse(period_end) >= Date.parse(due_date)) {
      errors.period_end = 'End date must be before due date.';
      errors.due_date = 'Due date must be after end date.';
    }

    return errors;
  };

  const handleChangeDates = useCallback(
    e => {
      const { name, value } = e.target;
      setDateState(prevDateState => ({
        ...prevDateState,
        [name]: value,
      }));

      const { period_start, period_end, due_date } = {
        ...dateState,
        [name]: value,
      };

      // Perform the date validation
      const isStartBeforeEndDate =
        period_start &&
        period_end &&
        Date.parse(period_start) < Date.parse(period_end);
      const areDatesValid = isStartBeforeEndDate;
      setShowForm(areDatesValid);

      // Check if either start date or end date has changed
      const isStartDateChanged =
        name === 'period_start' && value !== dateState.period_start;
      const isEndDateChanged =
        name === 'period_end' && value !== dateState.period_end;

      // Proceed with fetching summary if dates are valid
      if (
        (areDatesValid && exciseLicense && !due_date) ||
        (isStartDateChanged && period_end) ||
        (isEndDateChanged && period_start)
      ) {
        fetchExciseReturnSummary({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
        });
        fetchExciseReturns({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
          page: 1,
          per_page: 999,
        });
      }
    },
    [dateState, exciseLicense, fetchExciseReturnSummary]
  );

  const handleSubmit = useCallback(
    (_, values) => {
      const payload = {
        ...values,
        ...dateState,
      };
      onSubmit({
        id: model?.id,
        excise_license_id: exciseLicense.id,
        license_type: exciseLicense.license_type,
        ...payload,
      });
    },
    [onSubmit, model, dateState, exciseLicense]
  );

  const calculateValues = () => {
    const formValues = formRef.getValues();
    calculateSpiritsValues(formValues);
    calculateWineValues(formValues);
    calculateRestrictedFormulationsValues(formValues);
  };

  const calculateSpiritsValues = formValues => {
    const spiritsMoreThanSevenPercentReturnDetailTotalAdditions =
      Number(
        formValues.spirits_more_than_seven_percent_return_detail.domestic
      ) +
      Number(formValues.spirits_more_than_seven_percent_return_detail.imported);

    const spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions =
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail.domestic
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail.imported
      );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_additions',
      parseFloat(spiritsMoreThanSevenPercentReturnDetailTotalAdditions).toFixed(
        2
      )
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_additions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions
      ).toFixed(2)
    );

    const spiritsMoreThanSevenPercentReturnDetailTotalReductions =
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .used_in_restricted_formulation
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail.used_in_other
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .used_to_produce_vinegar
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .used_to_fortify_wine
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .returned_to_licensee
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail.exported
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .used_in_approved_processes
      ) +
      Number(
        formValues.spirits_more_than_seven_percent_return_detail
          .removed_for_other_purposes
      );

    const spiritsNotMoreThanSevenPercentReturnDetailTotalReductions =
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .used_in_restricted_formulation
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .used_in_other
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .used_to_produce_vinegar
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .used_to_fortify_wine
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .returned_to_licensee
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail.exported
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .used_in_approved_processes
      ) +
      Number(
        formValues.spirits_not_more_than_seven_percent_return_detail
          .removed_for_other_purposes
      );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_total_reductions',
      parseFloat(
        spiritsMoreThanSevenPercentReturnDetailTotalReductions
      ).toFixed(2)
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_total_reductions',
      parseFloat(
        spiritsNotMoreThanSevenPercentReturnDetailTotalReductions
      ).toFixed(2)
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_not_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_not_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(
          formValues.spirits_not_more_than_seven_percent_return_detail
            .opening_inventory
        ) +
          Number(
            formValues.spirits_not_more_than_seven_percent_return_detail
              .inventory_adjustment
          ) +
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalAdditions) -
          Number(spiritsNotMoreThanSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_quantity',
      Number(formValues.spirits_more_than_seven_percent_quantity)
    );

    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_quantity',
      Number(formValues.spirits_not_more_than_seven_percent_quantity)
    );

    setVariable(
      'spirits_more_than_seven_percent_return_detail_duty_payable',
      +(
        state.spirits_more_than_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.spirit_duty_rate_over_7 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'spirits_not_more_than_seven_percent_return_detail_duty_payable',
      +(
        state.spirits_not_more_than_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.spirit_duty_rate_over_7 || 0)
      ).toFixed(2) || '0.00'
    );
  };

  const calculateWineValues = formValues => {
    const wineSevenPercentReturnDetailTotalAdditions =
      Number(formValues.wine_seven_percent_return_detail.domestic) +
      Number(formValues.wine_seven_percent_return_detail.imported);

    const wineOnePercentReturnDetailTotalAdditions =
      Number(formValues.wine_one_percent_return_detail.domestic) +
      Number(formValues.wine_one_percent_return_detail.imported);

    const wineZeroPercentReturnDetailTotalAdditions =
      Number(formValues.wine_zero_percent_return_detail.domestic) +
      Number(formValues.wine_zero_percent_return_detail.imported);

    setVariable(
      'wine_seven_percent_return_detail_total_additions',
      parseFloat(wineSevenPercentReturnDetailTotalAdditions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_additions',
      parseFloat(wineOnePercentReturnDetailTotalAdditions).toFixed(2)
    );
    setVariable(
      'wine_zero_percent_return_detail_total_additions',
      parseFloat(wineZeroPercentReturnDetailTotalAdditions).toFixed(2)
    );

    const wineSevenPercentReturnDetailTotalReductions =
      Number(
        formValues.wine_seven_percent_return_detail
          .used_in_restricted_formulation
      ) +
      Number(formValues.wine_seven_percent_return_detail.used_in_other) +
      Number(
        formValues.wine_seven_percent_return_detail.used_to_produce_vinegar
      ) +
      Number(formValues.wine_seven_percent_return_detail.blended_with_spirits) +
      Number(formValues.wine_seven_percent_return_detail.exported) +
      Number(formValues.wine_seven_percent_return_detail.returned_to_licensee) +
      Number(
        formValues.wine_seven_percent_return_detail.used_in_approved_processes
      ) +
      Number(
        formValues.wine_seven_percent_return_detail.removed_for_other_purposes
      );

    const wineOnePercentReturnDetailTotalReductions =
      Number(
        formValues.wine_one_percent_return_detail.used_in_restricted_formulation
      ) +
      Number(formValues.wine_one_percent_return_detail.used_in_other) +
      Number(
        formValues.wine_one_percent_return_detail.used_to_produce_vinegar
      ) +
      Number(formValues.wine_one_percent_return_detail.blended_with_spirits) +
      Number(formValues.wine_one_percent_return_detail.exported) +
      Number(formValues.wine_one_percent_return_detail.returned_to_licensee) +
      Number(
        formValues.wine_one_percent_return_detail.used_in_approved_processes
      ) +
      Number(
        formValues.wine_one_percent_return_detail.removed_for_other_purposes
      );

    const wineZeroPercentReturnDetailTotalReductions =
      Number(
        formValues.wine_zero_percent_return_detail
          .used_in_restricted_formulation
      ) +
      Number(formValues.wine_zero_percent_return_detail.used_in_other) +
      Number(
        formValues.wine_zero_percent_return_detail.used_to_produce_vinegar
      ) +
      Number(formValues.wine_zero_percent_return_detail.blended_with_spirits) +
      Number(formValues.wine_zero_percent_return_detail.exported) +
      Number(formValues.wine_zero_percent_return_detail.returned_to_licensee) +
      Number(
        formValues.wine_zero_percent_return_detail.used_in_approved_processes
      ) +
      Number(
        formValues.wine_zero_percent_return_detail.removed_for_other_purposes
      );

    setVariable(
      'wine_seven_percent_return_detail_total_reductions',
      parseFloat(wineSevenPercentReturnDetailTotalReductions).toFixed(2)
    );
    setVariable(
      'wine_one_percent_return_detail_total_reductions',
      parseFloat(wineOnePercentReturnDetailTotalReductions).toFixed(2)
    );

    setVariable(
      'wine_zero_percent_return_detail_total_reductions',
      parseFloat(wineZeroPercentReturnDetailTotalReductions).toFixed(2)
    );

    setVariable(
      'wine_seven_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_seven_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_seven_percent_return_detail.inventory_adjustment
          ) +
          Number(wineSevenPercentReturnDetailTotalAdditions) -
          Number(wineSevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_one_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_one_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_one_percent_return_detail.inventory_adjustment
          ) +
          Number(wineOnePercentReturnDetailTotalAdditions) -
          Number(wineOnePercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_zero_percent_return_detail_closing_inventory',
      parseFloat(
        Number(formValues.wine_zero_percent_return_detail.opening_inventory) +
          Number(
            formValues.wine_zero_percent_return_detail.inventory_adjustment
          ) +
          Number(wineZeroPercentReturnDetailTotalAdditions) -
          Number(wineZeroPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_seven_percent_return_detail_quantity',
      Number(formValues.wine_seven_percent_quantity)
    );

    setVariable(
      'wine_one_percent_return_detail_quantity',
      Number(formValues.wine_one_percent_quantity)
    );

    setVariable(
      'wine_zero_percent_return_detail_quantity',
      Number(formValues.wine_zero_percent_quantity)
    );

    setVariable(
      'wine_seven_percent_return_detail_duty_payable',
      +(
        state.wine_seven_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_7 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_one_percent_return_detail_duty_payable',
      +(
        state.wine_one_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_1 || 0)
      ).toFixed(2) || '0.00'
    );

    setVariable(
      'wine_zero_percent_return_detail_duty_payable',
      +(
        state.wine_zero_percent_return_detail_quantity *
        parseFloat(exciseLicense.wine_duty_rate_0 || 0)
      ).toFixed(2) || '0.00'
    );
  };

  const calculateRestrictedFormulationsValues = formValues => {
    const restrictedFormulationsDetailTotalAdditions =
      Number(
        formValues.restricted_formulations_detail
          .restricted_formulation_produced
      ) +
      Number(
        formValues.restricted_formulations_detail.received_from_licensed_users
      ) +
      Number(
        formValues.restricted_formulations_detail
          .imported_restricted_formulation
      );

    setVariable(
      'restricted_formulations_detail_total_additions',
      parseFloat(restrictedFormulationsDetailTotalAdditions).toFixed(2)
    );

    const restrictedFormulationsDetailTotalReductions =
      Number(
        formValues.restricted_formulations_detail
          .used_in_an_approved_formulation_restricted_formulation
      ) +
      Number(
        formValues.restricted_formulations_detail
          .used_in_an_approved_formulation_other
      ) +
      Number(
        formValues.restricted_formulations_detail
          .removed_to_another_licensed_user
      ) +
      Number(formValues.restricted_formulations_detail.exported);

    setVariable(
      'restricted_formulations_detail_total_reductions',
      parseFloat(restrictedFormulationsDetailTotalReductions).toFixed(2)
    );

    setVariable(
      'restricted_formulations_detail_closing_inventory',
      parseFloat(
        Number(formValues.restricted_formulations_detail.opening_inventory) +
          Number(
            formValues.restricted_formulations_detail.inventory_adjustment
          ) +
          Number(restrictedFormulationsDetailTotalAdditions) -
          Number(restrictedFormulationsDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      ref={node => {
        formRef = node;
      }}
    >
      <Row>
        <Col md={2} />
        <DateFields
          fields={DATE_FIELDS}
          disabled={disabled}
          validateDateFields={validateDateFields}
          onInput={handleChangeDates}
        />
      </Row>
      {!!model && exciseReturnsList?.length > 0 && (
        <Alert color="warning">
          <div>
            <i className="mdi mdi-information" />{' '}
            <b>
              There are one or more existing returns that overlap the selected
              period.
            </b>
            <p>
              Please make sure the selected period start and period end dates
              are correct.
            </p>
            <br />
            {exciseReturnsList?.map(item => (
              <div>
                <Link
                  to={`/reports/excise/license/${item.excise_license_id}/return/user/${item.id}`}
                >
                  Excise return #{item.id} from {item.period_start} to{' '}
                  {item.period_end}
                </Link>
              </div>
            ))}
          </div>
        </Alert>
      )}
      <div
        className={classNames(styles.paddedRow, {
          [styles.show]: showForm,
          [styles.hide]: !showForm,
        })}
      >
        {/* ========== SPIRITS ========== */}
        <Row>
          <Col md={2}>
            <h4 className="card-title">SPIRITS</h4>
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} />
          {renderTitleFields(EXCISE_USER_TABLE_COLUMNS_SPIRITS)}
        </Row>
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Opening Inventory"
          name="opening_inventory"
          min="0"
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to inventory</h4>
          </Col>
        </Row>
        {ADDITIONS_TO_INVENTORY_SPIRITS_WINE_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_SPIRITS}
            onBlur={calculateValues}
            state={spiritsState}
            setVariable={setSpiritsVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Total Additions"
          name="total_additions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Reductions to inventory</h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_SPIRITS_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_SPIRITS}
            onBlur={calculateValues}
            setVariable={setSpiritsVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Total Reductions"
          name="total_reductions"
          disabled
        />
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Inventory Adjustment"
          name="inventory_adjustment"
        />
        <TotalRow
          fields={COLUMNS_SPIRITS}
          state={state}
          title="Closing Inventory"
          name="closing_inventory"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Duty Payable</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_more_than_seven_percent_quantity"
              value={
                state.spirits_more_than_seven_percent_return_detail_quantity
              }
              onChange={event => {
                setVariable(
                  'spirits_more_than_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_not_more_than_seven_percent_quantity"
              value={
                state.spirits_not_more_than_seven_percent_return_detail_quantity
              }
              onChange={event => {
                setVariable(
                  'spirits_not_more_than_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_more_than_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.spirits_more_than_seven_percent_return_detail_duty_payable ||
                    '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="spirits_not_more_than_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.spirits_not_more_than_seven_percent_return_detail_duty_payable ||
                    '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        {/* ========== WINE ========== */}
        <Row className={styles.paddedRow}>
          <Col md={2}>
            <h4 className="card-title">WINE</h4>
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} />
          {renderTitleFields(EXCISE_USER_TABLE_COLUMNS_WINE)}
        </Row>
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_WINE}
          state={state}
          title="Opening Inventory"
          name="opening_inventory"
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to inventory</h4>
          </Col>
        </Row>
        {ADDITIONS_TO_INVENTORY_SPIRITS_WINE_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_WINE}
            onBlur={calculateValues}
            state={wineState}
            setVariable={setWineVariable}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Total Additions"
          name="total_additions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Reductions to inventory</h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_WINE_COLS.map((field, index) => (
          <MultipleFieldRow
            key={index}
            title={field.label}
            name={field.key}
            fields={COLUMNS_WINE}
            onBlur={calculateValues}
            min="0"
          />
        ))}
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Total Reductions"
          name="total_reductions"
          disabled
        />
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_WINE}
          state={state}
          title="Inventory Adjustment"
          name="inventory_adjustment"
        />
        <TotalRow
          fields={COLUMNS_WINE}
          state={state}
          title="Closing Inventory"
          name="closing_inventory"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Duty Payable</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_seven_percent_quantity"
              value={state.wine_seven_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_seven_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_one_percent_quantity"
              value={state.wine_one_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_one_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_zero_percent_quantity"
              value={state.wine_zero_percent_return_detail_quantity}
              onChange={event => {
                setVariable(
                  'wine_zero_percent_return_detail_quantity',
                  event.target.value
                );
              }}
              onFocus={event => event.target.select()}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_seven_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_seven_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_one_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_one_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="wine_zero_percent_duty_payable"
              value={formatPrice(
                Number(
                  state.wine_zero_percent_return_detail_duty_payable || '0.00'
                )
              )}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        {/* ========== RESTRICTED FORMULATIONS ========== */}
        <Row className={styles.paddedRow}>
          <Col md={2}>
            <h4 className="card-title">RESTRICTED FORMULATIONS</h4>
          </Col>
        </Row>
        <TotalRow
          onBlur={calculateValues}
          setVariable={setVariable}
          fields={COLUMNS_RESTRICTED_FORMULATIONS}
          state={state}
          title="Opening Inventory"
          name="opening_inventory"
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to inventory</h4>
          </Col>
        </Row>
        {ADDITIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS.map(
          (field, index) => (
            <MultipleFieldRow
              key={index}
              title={field.label}
              name={field.key}
              fields={COLUMNS_RESTRICTED_FORMULATIONS}
              onBlur={calculateValues}
              state={restrictedFormulationsState}
              setVariable={setRestrictedFormulationsVariable}
              min="0"
            />
          )
        )}
        <TotalRow
          fields={COLUMNS_RESTRICTED_FORMULATIONS}
          state={state}
          title="Total Additions"
          name="total_additions"
          disabled
        />
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Reductions to inventory</h4>
          </Col>
        </Row>
        {REDUCTIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS.map(
          (field, index) => (
            <MultipleFieldRow
              key={index}
              title={field.label}
              name={field.key}
              fields={COLUMNS_RESTRICTED_FORMULATIONS}
              onBlur={calculateValues}
              setVariable={setRestrictedFormulationsVariable}
              min="0"
            />
          )
        )}
        <TotalRow
          fields={COLUMNS_RESTRICTED_FORMULATIONS}
          state={state}
          title="Total Reductions"
          name="total_reductions"
          disabled
        />
        <TotalRow
          onBlur={calculateValues}
          setVariable={setRestrictedFormulationsVariable}
          fields={COLUMNS_RESTRICTED_FORMULATIONS}
          state={state}
          title="Inventory Adjustment"
          name="inventory_adjustment"
        />
        <TotalRow
          fields={COLUMNS_RESTRICTED_FORMULATIONS}
          state={state}
          title="Closing Inventory"
          name="closing_inventory"
          disabled
        />
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </div>
    </AvForm>
  );
};

UserForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  exciseLicense: PropTypes.object,
};

export default UserForm;
