import React, { memo, useEffect, useState } from 'react';
import styles from './RoleCard.scss';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import RolesField from '../../RolesField';
import PropTypes from 'prop-types';

const RoleCard = ({
  roles,
  content,
  onChange,
  onDelete,
  defaultValue,
  isEditable,
}) => {
  const [role, setRole] = useState(defaultValue);

  useEffect(() => {
    if (role?.value) {
      onChange(role.value);
    }
  }, [role]);

  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <div className={styles.roleWrapper}>
            <FormGroup className={styles.roleField}>
              <Label>Role</Label>
              <RolesField
                isDisabled={!isEditable}
                value={role}
                onChange={setRole}
                options={roles}
              />
            </FormGroup>
          </div>
        </Col>
        <Col md={8}>{content}</Col>
        {isEditable && (
          <Col md={1}>
            <Button
              onClick={onDelete}
              type="button"
              color="danger"
              className={styles.trashButton}
            >
              <i className="mdi mdi-trash-can" />
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

RoleCard.propTypes = {
  roles: PropTypes.array.isRequired,
  defaultValue: PropTypes.object,
  content: PropTypes.any,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isEditable: PropTypes.bool,
};
export default memo(RoleCard);
