import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const hostsSlice = createSlice({
  name: 'hosts',
  initialState: {
    isPending: false,
    hosts: [],
    host: {},
    pagination: {},
  },
  reducers: {
    fetchHosts: state => {
      state.isPending = true;
    },
    fetchHostsSuccess(state, { payload }) {
      state.isPending = false;
      state.hosts = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchHostsFailure: state => {
      state.isPending = false;
    },
    fetchHost: state => {
      state.isPending = true;
    },
    fetchHostSuccess(state, { payload }) {
      state.isPending = false;
      state.host = payload.data;
    },
    fetchHostFailure: state => {
      state.isPending = false;
    },
    attachHost: state => {
      state.isPending = true;
    },
    attachHostSuccess(state, { payload }) {
      state.hosts[payload.data.id] = {
        ...payload.data,
      };
      state.isPending = false;
    },
    attachHostFailure: state => {
      state.isPending = false;
    },
    inviteHost: state => {
      state.isPending = true;
    },
    inviteHostSuccess(state) {
      state.isPending = false;
    },
    inviteHostFailure: state => {
      state.isPending = false;
    },
    deleteHost: state => {
      state.isPending = true;
    },
    deleteHostSuccess(state, { payload }) {
      state.hosts = state.hosts.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteHostFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(hostsSlice.actions);

export default hostsSlice.reducer;
