import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import * as queryString from 'query-string';
import { selectStyles } from 'constants/selectStyles';
import {
  getProductTitleWithVintage,
  getProductTitleWithSKUVintage,
} from 'utils/preparedProductTitle';

const WarehouseInventoryPaginatedField = ({
  warehouseId,
  onChange,
  withSKU,
  isMulti,
  defaultValue,
  isSubscription,
  options,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchWarehouseInventory = async ({ page = 1, search }) => {
    const parameters = queryString.stringify(
      {
        page,
        title_contains: search,
        product_types: options?.product_types,
        is_it_can_be_used_for_subscription_orders: isSubscription,
      },
      { arrayFormat: 'comma' }
    );
    return apiRequest({
      url: `/warehouses/${warehouseId}/inventories?${parameters}`,
    });
  };

  const loadWarehouseInventory = async (search, loadedOptions, { page }) => {
    try {
      const { pagination, results } = await fetchWarehouseInventory({
        page,
        search,
      });
      return {
        options: results.map(item => ({
          ...item,
          value: item.product_id,
          label: withSKU
            ? getProductTitleWithSKUVintage(
                item?.product_title,
                item?.product_vintage,
                item?.product_sku
              )
            : getProductTitleWithVintage(
                item?.product_title,
                item?.product_vintage
              ),
        })),
        hasMore: pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      console.error(error);
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="brands"
      className="w-100"
      isSearchable
      debounceTimeout={1000}
      cacheUniqs={[options]}
      defaultValue={defaultValue}
      additional={{
        page: 1,
      }}
      loadOptions={warehouseId ? loadWarehouseInventory : null}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

WarehouseInventoryPaginatedField.propTypes = {
  warehouseId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  withSKU: PropTypes.bool,
  defaultValue: PropTypes.object,
  isSubscription: PropTypes.bool,
  options: PropTypes.object,
};

export default WarehouseInventoryPaginatedField;
