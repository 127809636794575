const DEFAULT_VALUES_EXCISE_USER_DETAIL = {
  opening_inventory: '0.00',
  total_additions: '0.00',
  total_reductions: '0.00',
  inventory_adjustment: '0.00',
  closing_inventory: '0.00',
  domestic: '0.00',
  imported: '0.00',
  used_in_restricted_formulation: '0.00',
  used_in_other: '0.00',
  used_to_produce_vinegar: '0.00',
  returned_to_licensee: '0.00',
  exported: '0.00',
  used_in_approved_processes: '0.00',
  removed_for_other_purposes: '0.00',
};

const DEFAULT_VALUES_EXCISE_USER_DETAIL_SPIRITS = {
  ...DEFAULT_VALUES_EXCISE_USER_DETAIL,
  used_to_fortify_wine: '0.00',
};

const DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE = {
  ...DEFAULT_VALUES_EXCISE_USER_DETAIL,
  blended_with_spirits: '0.00',
};

const DEFAULT_VALUES_EXCISE_USER_DETAIL_RESTRICTED_FORMULATIONS = {
  opening_inventory: '0.00',
  total_additions: '0.00',
  total_reductions: '0.00',
  inventory_adjustment: '0.00',
  closing_inventory: '0.00',
  restricted_formulation_produced: '0.00',
  received_from_licensed_users: '0.00',
  imported_restricted_formulation: '0.00',
  used_in_an_approved_formulation_restricted_formulation: '0.00',
  used_in_an_approved_formulation_other: '0.00',
  removed_to_another_licensed_user: '0.00',
  exported: '0.00',
};

export const DEFAULT_VALUES_EXCISE_USER = {
  spirits_more_than_seven_percent_quantity: '0.00',
  spirits_more_than_seven_percent_duty_payable: '0.00',
  spirits_more_than_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_SPIRITS,
  spirits_not_more_than_seven_percent_quantity: '0.00',
  spirits_not_more_than_seven_percent_duty_payable: '0.00',
  spirits_not_more_than_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_SPIRITS,
  wine_seven_percent_quantity: '0.00',
  wine_seven_percent_duty_payable: '0.00',
  wine_seven_percent_return_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE,
  wine_one_percent_quantity: '0.00',
  wine_one_percent_duty_payable: '0.00',
  wine_one_percent_return_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE,
  wine_zero_percent_quantity: '0.00',
  wine_zero_percent_duty_payable: '0.00',
  wine_zero_percent_return_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_WINE,
  restricted_formulations_detail: DEFAULT_VALUES_EXCISE_USER_DETAIL_RESTRICTED_FORMULATIONS,
};

export const DATE_FIELDS = [
  { key: 'period_start', label: 'Period Start' },
  { key: 'period_end', label: 'Period End' },
  { key: 'due_date', label: 'Due Date' },
];

export const EXCISE_USER_TABLE_COLUMNS_SPIRITS = [
  {
    key: 'spirits_more_than_seven_percent',
    label: 'Spirits more than 7%',
  },
  {
    key: 'spirits_not_more_than_seven_percent',
    label: 'Spirits not more than 7%',
  },
];
export const EXCISE_USER_TABLE_COLUMNS_WINE = [
  { key: 'wine_seven_percent', label: 'Wine more than 7%' },
  {
    key: 'wine_one_percent',
    label: 'Wine between 1.2% and 7%',
  },
  {
    key: 'wine_zero_percent',
    label: 'Wine not more than 1.2%',
  },
];

export const EXCISE_USER_TABLE_COLUMNS = [
  ...EXCISE_USER_TABLE_COLUMNS_SPIRITS,
  ...EXCISE_USER_TABLE_COLUMNS_WINE,
];

export const INITIAL_STATE = {
  spirits_more_than_seven_percent_return_detail_opening_inventory: '0.00',
  spirits_not_more_than_seven_percent_return_detail_opening_inventory: '0.00',
  wine_seven_percent_return_detail_opening_inventory: '0.00',
  wine_one_percent_return_detail_opening_inventory: '0.00',
  wine_zero_percent_return_detail_opening_inventory: '0.00',
  restricted_formulations_detail_opening_inventory: '0.00',
  spirits_more_than_seven_percent_return_detail_total_additions: '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_additions: '0.00',
  wine_seven_percent_return_detail_total_additions: '0.00',
  wine_one_percent_return_detail_total_additions: '0.00',
  wine_zero_percent_return_detail_total_additions: '0.00',
  restricted_formulations_detail_total_additions: '0.00',
  spirits_more_than_seven_percent_return_detail_total_reductions: '0.00',
  spirits_not_more_than_seven_percent_return_detail_total_reductions: '0.00',
  wine_seven_percent_return_detail_total_reductions: '0.00',
  wine_one_percent_return_detail_total_reductions: '0.00',
  wine_zero_percent_return_detail_total_reductions: '0.00',
  restricted_formulations_detail_total_reductions: '0.00',
  spirits_more_than_seven_percent_return_detail_inventory_adjustment: '0.00',
  spirits_not_more_than_seven_percent_return_detail_inventory_adjustment:
    '0.00',
  wine_seven_percent_return_detail_inventory_adjustment: '0.00',
  wine_one_percent_return_detail_inventory_adjustment: '0.00',
  wine_zero_percent_return_detail_inventory_adjustment: '0.00',
  restricted_formulations_detail_inventory_adjustment: '0.00',
  spirits_more_than_seven_percent_return_detail_closing_inventory: '0.00',
  spirits_not_more_than_seven_percent_return_detail_closing_inventory: '0.00',
  wine_seven_percent_return_detail_closing_inventory: '0.00',
  wine_one_percent_return_detail_closing_inventory: '0.00',
  wine_zero_percent_return_detail_closing_inventory: '0.00',
  restricted_formulations_detail_closing_inventory: '0.00',
  spirits_more_than_seven_percent_return_detail_quantity: '0.00',
  spirits_not_more_than_seven_percent_return_detail_quantity: '0.00',
  wine_seven_percent_return_detail_quantity: '0.00',
  wine_one_percent_return_detail_quantity: '0.00',
  wine_zero_percent_return_detail_quantity: '0.00',
  spirits_more_than_seven_percent_return_detail_duty_payable: '0.00',
  spirits_not_more_than_seven_percent_return_detail_duty_payable: '0.00',
  wine_seven_percent_return_detail_duty_payable: '0.00',
  wine_one_percent_return_detail_duty_payable: '0.00',
  wine_zero_percent_return_detail_duty_payable: '0.00',
};

export const COLUMNS_SPIRITS = [
  'spirits_more_than_seven_percent_return_detail',
  'spirits_not_more_than_seven_percent_return_detail',
];
export const COLUMNS_WINE = [
  'wine_seven_percent_return_detail',
  'wine_one_percent_return_detail',
  'wine_zero_percent_return_detail',
];

export const COLUMNS_RESTRICTED_FORMULATIONS = [
  'restricted_formulations_detail',
];

export const ADDITIONS_TO_INVENTORY_SPIRITS_WINE_COLS = [
  { key: 'domestic', label: 'Domestic' },
  { key: 'imported', label: 'Imported' },
];

export const REDUCTIONS_TO_INVENTORY_SPIRITS_COLS = [
  {
    key: 'used_in_restricted_formulation',
    label: 'Used in an approved formulation (restricted)',
  },
  { key: 'used_in_other', label: 'Used in an approved formulation (other)' },
  { key: 'used_to_produce_vinegar', label: 'Used to produce vinegar' },
  { key: 'used_to_fortify_wine', label: 'Used to fortify wine' },
  {
    key: 'returned_to_licensee',
    label: 'Returned to spirits licensee or excise warehouse licensee',
  },
  { key: 'exported', label: 'Exported spirits' },
  { key: 'used_in_approved_processes', label: 'Used in approved processes' },
  { key: 'removed_for_other_purposes', label: 'Removed for other purposes' },
];

export const REDUCTIONS_TO_INVENTORY_WINE_COLS = [
  {
    key: 'used_in_restricted_formulation',
    label: 'Used in an approved formulation (restricted)',
  },
  { key: 'used_in_other', label: 'Used in an approved formulation (other)' },
  { key: 'used_to_produce_vinegar', label: 'Used to produce vinegar' },
  { key: 'blended_with_spirits', label: 'Blended with spirits' },
  {
    key: 'returned_to_licensee',
    label: 'Returned to spirits licensee or excise warehouse licensee',
  },
  { key: 'exported', label: 'Exported spirits' },
  { key: 'used_in_approved_processes', label: 'Used in approved processes' },
  { key: 'removed_for_other_purposes', label: 'Removed for other purposes' },
];

export const ADDITIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS = [
  {
    key: 'restricted_formulation_produced',
    label: 'Restricted formulation produced',
  },
  {
    key: 'received_from_licensed_users',
    label: 'Received from licensed users',
  },
  {
    key: 'imported_restricted_formulation',
    label: 'Imported restricted formulation',
  },
];
export const REDUCTIONS_TO_INVENTORY_RESTRICTED_FORMULATIONS_COLS = [
  {
    key: 'used_in_an_approved_formulation_restricted_formulation',
    label: 'Used in an approved formulation (restricted)',
  },
  {
    key: 'used_in_an_approved_formulation_other',
    label: 'Used in an approved formulation (other)',
  },
  {
    key: 'removed_to_another_licensed_user',
    label: 'Removed to another licensed user',
  },
  {
    key: 'exported',
    label: 'Exported',
  },
];
