import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import { selectStyles } from 'constants/selectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';

const getNormalValues = value => {
  return value.map(val => ({ ...val, value: val.id, label: val.title }));
};

const WineclubsPaginatedField = ({
  onChange,
  isMulti,
  parameters,
  value,
  isInitialLabel,
  error,
  classNames,
  onBlur,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchWineclubs = async ({ page = 1 }) => {
    const queryParameters = new URLSearchParams(parameters);
    return apiRequest({
      url: `/wineclubs/?page=${page}&${queryParameters}`,
    });
  };

  const loadWineclubs = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchWineclubs({ page });
      const options = response?.results.map(item => ({
        value: item.id,
        label: item.title,
        ...item,
      }));
      return {
        options: isInitialLabel
          ? [
              {
                label: 'All wineclubs',
                value: '',
              },
              ...options,
            ]
          : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  const getValue = val => {
    if (val) return val;

    if (isInitialLabel)
      return {
        label: 'All wineclubs',
        value: '',
      };

    return null;
  };

  return (
    <>
      <AsyncPaginate
        {...props}
        name="wineclubs"
        isSearchable={false}
        placeholder="Select wineclub..."
        additional={{
          page: 1,
        }}
        defaultValue={value?.length ? getNormalValues(value) : getValue(value)}
        value={value}
        loadOptions={loadWineclubs}
        onChange={onChange}
        isMulti={isMulti}
        styles={selectStyles}
        className={classNames}
        onBlur={onBlur}
      />
      {!!error && <small className="text-danger">{error}</small>}
    </>
  );
};

WineclubsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  parameters: PropTypes.object,
  error: PropTypes.string,
  classNames: PropTypes.string,
  onBlur: PropTypes.func,
};

export default WineclubsPaginatedField;
