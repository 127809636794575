import useQuery from 'hooks/useQuery';

export const getQuery = (key, defaultTitle) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const query = useQuery();

  return {
    value: query.get(`${key}_id`) || undefined,
    label: query.get(`${key}_title`) || defaultTitle,
  };
};
