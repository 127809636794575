import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { defaultCurrency } from 'constants';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchProductsPresetsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/products-presets/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchProductsPresetSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/products-presets/${action.payload}`,
  });
}

export function* createProductsPresetSaga(action) {
  const data = {
    ...action.payload,
    price_currency: defaultCurrency,
  };

  yield api({
    action,
    method: 'post',
    url: `/products-presets/`,
    data,
    successMessage: 'Successfully created new products preset!',
    successNavigateTo: `/products-presets/`,
  });
}

export function* updateProductsPresetSaga(action) {
  const data = {
    ...action.payload,
    price_currency: defaultCurrency,
  };

  yield api({
    action,
    method: 'patch',
    url: `/products-presets/${action.payload.id}`,
    data,
    successMessage: 'Successfully updated product preset!',
    successNavigateTo: `/products-presets/`,
  });
}

export function* deleteProductsPresetSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/products-presets/${action.payload}`,
    successMessage: 'Successfully deleted product preset!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchProductsPresets, fetchProductsPresetsSaga),
    takeLatest(actions.fetchProductsPreset, fetchProductsPresetSaga),
    takeLatest(actions.createProductsPreset, createProductsPresetSaga),
    takeLatest(actions.deleteProductsPreset, deleteProductsPresetSaga),
    takeLatest(actions.updateProductsPreset, updateProductsPresetSaga),
  ]);
}
