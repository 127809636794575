import React, { Children, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';

import { Card, CardBody, Row, Col, Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getStatHolidays } from 'utils/getStatHolidays';

import { format, parse, startOfWeek, getDay, isSameDay } from 'date-fns';
import enCA from 'date-fns/locale/en-CA';

import styles from './TimeEntrySummary.scss';
import TimeEntryPeriod from './TimeEntryPeriod';
import {
  getTimeEntryByDate,
  getStaffEvents,
  getOverTimeEvents,
  getLateEntryEvents,
  getIncompleteEvents,
  getTimeEntryEvents,
  getActivePeriodEvents,
  getPeriodsByDate,
} from 'utils/timeEntry';

import { actions as timeEntryActions } from 'models/timeEntry/slice';
import {
  timeEntrySelector,
  isPendingSelector,
} from 'models/timeEntry/selectors';
import { settingsSelector } from 'models/user/selectors';

const locales = {
  'en-CA': enCA,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const PlainEventWrapper = ({ children }) =>
  React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: 'inherit',
      color: 'black',
      overflowWrap: 'anywhere',
    },
  });

const TimeEntrySummary = ({ title }) => {
  const history = useHistory();

  // Actions
  const fetchTimeEntry = useAction(timeEntryActions.fetchTimeEntry);

  // Selectors
  const timeEntry = useSelector(timeEntrySelector);
  const timeEntryPending = useSelector(isPendingSelector);
  const workspaceSettings = useSelector(settingsSelector);

  // State
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
  );
  const [periods, setPeriods] = useState([]);
  const [statHolidays, setStatHolidays] = useState([]);

  // Effects
  useEffect(() => {
    fetchTimeEntry({
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      per_page: 999999,
    });
    if (workspaceSettings)
      setPeriods(
        getPeriodsByDate(
          startDate,
          endDate,
          workspaceSettings.pay_period_type,
          workspaceSettings.pay_period_start
        )
      );
  }, [startDate, endDate, workspaceSettings]);

  useEffect(() => {
    getStatHolidays(startDate, setStatHolidays);
  }, [startDate]);

  // Parse time entries
  const timeEntryByDate = useMemo(
    () => timeEntry && getTimeEntryByDate(timeEntry?.data?.results),
    [timeEntry]
  );

  const staffEvents = useMemo(() => getStaffEvents(timeEntryByDate), [
    timeEntry,
  ]);

  const overtimeEvents = useMemo(() => getOverTimeEvents(timeEntryByDate), [
    timeEntry,
  ]);

  const lateEntryEvents = useMemo(() => getLateEntryEvents(timeEntryByDate), [
    timeEntry,
  ]);

  const incompleteEvents = useMemo(() => getIncompleteEvents(timeEntryByDate), [
    timeEntry,
  ]);

  const timeEntryEvents = useMemo(
    () =>
      getTimeEntryEvents(
        staffEvents,
        overtimeEvents,
        lateEntryEvents,
        incompleteEvents,
        periods
      ),
    [timeEntry]
  );

  const activePeriods = useMemo(
    () =>
      periods.filter(
        period =>
          (period.start > startDate && period.start < endDate) ||
          (period.end > startDate && period.end < endDate)
      ),
    [timeEntry]
  );

  const activePeriodEvents = useMemo(
    () =>
      getActivePeriodEvents(
        activePeriods,
        timeEntryByDate,
        overtimeEvents,
        lateEntryEvents,
        incompleteEvents
      ),
    [timeEntry]
  );

  const calendarEvents = useMemo(() => {
    return [...timeEntryEvents, ...statHolidays];
  }, [timeEntryEvents, statHolidays]);

  const dayPropGetter = date => {
    const isHoliday = statHolidays.some(holiday =>
      isSameDay(new Date(holiday.start), date)
    );

    if (isHoliday) {
      return {
        className: styles.holidayEvent,
      };
    }
    return {};
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Card>
        <CardBody>
          <Row>
            <Col className={timeEntryPending ? 'filtering' : null}>
              <Calendar
                className={styles.calendar}
                onRangeChange={({ start, end }) => {
                  setStartDate(start);
                  setEndDate(end);
                }}
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                views={['month']}
                events={calendarEvents}
                showAllEvents
                components={{
                  event: ({ event }) => {
                    switch (event.status) {
                      case 'DANGER':
                        return <Badge color="danger">{event.title}</Badge>;
                      case 'INFO':
                        return <Badge color="info">{event.title}</Badge>;
                      case 'STAFF':
                        return (
                          <Badge color="dark" className={styles.staffBadge}>
                            {event.title}
                          </Badge>
                        );
                      case 'PERIOD':
                        return (
                          <div
                            className={`${styles.periodTitle} rbc-event rbc-event-allday rbc-event-content`}
                            style={{ backgroundColor: event.color }}
                          >
                            {event.title}
                          </div>
                        );
                      case 'HOLIDAY':
                        return (
                          <div
                            className={`${styles.holidayEvent} rbc-event rbc-event-allday rbc-event-content`}
                          >
                            {event.title}
                          </div>
                        );
                      default:
                        return <span>{event.title}</span>;
                    }
                  },
                  day: {
                    holiday: date => {
                      const isHoliday = statHolidays.some(holiday =>
                        isSameDay(new Date(holiday.start), date)
                      );
                      return isHoliday ? 'holiday' : null;
                    },
                  },
                  eventWrapper: PlainEventWrapper,
                }}
                selectable
                dayPropGetter={dayPropGetter}
                onSelectSlot={slotInfo => {
                  const { start } = slotInfo;
                  const activePeriod = activePeriods.find(
                    period => start >= period.start && start <= period.end
                  );

                  if (activePeriod) {
                    const activePeriodEvent =
                      activePeriodEvents[
                        activePeriod.start.toISOString().split('T')[0]
                      ][0];

                    if (activePeriodEvent && activePeriodEvent.numOfStaff > 0) {
                      const url = `/timesheet-details?title=${title}&start=${start.toISOString()}&end=${activePeriod.end.toISOString()}`;
                      history.push(url);
                    }
                  }
                }}
              />
            </Col>
            <Col md="3" className={timeEntryPending ? 'filtering' : null}>
              <div className={styles.subHeading}>Pay Periods</div>
              {activePeriodEvents &&
                activePeriods.map(period => {
                  const activePeriodEvent =
                    activePeriodEvents[
                      period.start.toISOString().split('T')[0]
                    ][0];
                  return (
                    <TimeEntryPeriod
                      period={period}
                      numOfStaff={activePeriodEvent.numOfStaff}
                      numOfIncomplete={activePeriodEvent.numOfIncomplete}
                      numOfLateEntry={activePeriodEvent.numOfLateEntry}
                      numOfOvertime={activePeriodEvent.numOfOvertime}
                      approved={activePeriodEvent.approved}
                    />
                  );
                })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

TimeEntrySummary.propTypes = {
  title: PropTypes.string,
};

export default TimeEntrySummary;
