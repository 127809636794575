import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { FEE_PRICE_TYPES } from 'constants';
import PropTypes from 'prop-types';

const selectOptions = Object.entries(FEE_PRICE_TYPES).map(([key, value]) => ({
  label: key,
  value,
}));

const PriceField = ({ onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue?.value ?? 0);
  const [units, setUnits] = useState(
    defaultValue?.units ?? FEE_PRICE_TYPES.Percent
  );

  useEffect(() => {
    onChange({
      units,
      value,
    });
  }, [units, value]);

  const onTypeChange = item => {
    setUnits(item.value);
  };

  const onValueChange = event => {
    const inputValue = event.target.value;
    if (!inputValue) {
      setValue(0);
    } else if (value === 0 && inputValue.match(/^((0[1-9]))/)) {
      const preparedValue = inputValue.replace(/^0+/, '') || 0;
      setValue(preparedValue);
      event.target.value = preparedValue;
    } else if (inputValue.match(/^((0|([1-9][0-9]*))(\.([0-9]+)?)?)$/)) {
      setValue(inputValue);
      event.target.value = inputValue;
    }
  };

  return (
    <Row className="no-gutters">
      <Col md={3} className="mr-2">
        <input
          type="text"
          className="form-control"
          value={value}
          onChange={onValueChange}
        />
      </Col>
      <Col>
        <Select
          defaultValue={selectOptions.find(item => item.value === units)}
          options={selectOptions}
          onChange={onTypeChange}
        />
      </Col>
    </Row>
  );
};

PriceField.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
};

export default PriceField;
