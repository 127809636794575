import { createSelector } from 'reselect';

export const rootSelector = state => state.footTraffic;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const camerasSelector = createSelector(rootSelector, ({ cameras }) => {
  return cameras;
});

export const selectedCameraSelector = createSelector(
  rootSelector,
  ({ selectedCamera }) => selectedCamera
);

export const cameraStatsSelector = createSelector(
  [state => state.footTraffic.cameraStats, (state, statsType) => statsType],
  (items, statsType) => {
    return items[statsType];
  }
);
