/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';

import { Table, Card, CardBody } from 'reactstrap';
import CardItem from 'components/CardItem';

import { ORDER_TYPES_ITEMS } from 'constants';
import LinkCell from 'components/LinkCell';
import { format } from 'date-fns';
import ProductsTable from './ProductsTable';
import NotesTable from 'components/NotesTable';
import PaginationTable from 'components/PaginationTable';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import { getNormalText } from 'utils/getNormalText';
import styles from './Main.scss';

const Main = ({
  orders,
  ordersPagination,
  onOrdersPageChange,
  onRemovePaymentMethod,
  products,
  paymentMethods,
  notes,
}) => {
  const handleOrdersPageChange = page => {
    if (onOrdersPageChange) {
      onOrdersPageChange(page);
    }
  };

  return (
    <>
      {paymentMethods && paymentMethods.length > 0 && (
        <PaymentMethodsTable
          paymentMethods={paymentMethods}
          onRemovePaymentMethod={onRemovePaymentMethod}
        />
      )}
      <CardItem
        className={styles.notes}
        title="Notes"
        value={<NotesTable notes={notes ?? []} isCustomer />}
      />
      {orders?.length > 0 ? (
        <>
          <Card>
            <CardBody>
              <h4 className="card-title">Orders</h4>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <PaginationTable
                    pagination={ordersPagination}
                    setCurrentPage={handleOrdersPageChange}
                  >
                    <Table id="tech-companies-1" bordered responsive>
                      <thead>
                        <tr>
                          <th data-priority="1">Order Number</th>
                          <th data-priority="1">Paid at</th>
                          <th data-priority="1">Total Price</th>
                          <th data-priority="1">Tip</th>

                          <th data-priority="3">Brand</th>
                          <th data-priority="3">Status</th>
                          <th data-priority="3">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(orders ?? []).map(item => (
                          <tr key={item.id}>
                            <LinkCell
                              id={item.id}
                              field={item.id}
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={
                                item.paid_at
                                  ? format(
                                      Date.parse(item.paid_at),
                                      'yyyy-MM-dd, h:mm aaa'
                                    )
                                  : '-'
                              }
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={`${item.total_price} ${item.total_price_currency}`}
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={
                                item.tips_amount
                                  ? `${item.tips_amount} ${item.tips_amount_currency}`
                                  : '-'
                              }
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={item.brand?.title}
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={
                                item.status === 'FULFILLED'
                                  ? 'Pending'
                                  : getNormalText(item.status)
                              }
                              link="/orders/"
                            />
                            <LinkCell
                              id={item.id}
                              field={ORDER_TYPES_ITEMS[item.resourcetype]}
                              link="/orders/"
                            />
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </PaginationTable>
                </div>
              </div>
            </CardBody>
          </Card>
          <CardItem
            title="Purchased products"
            value={<ProductsTable products={products ?? []} />}
            isLargeBlock
          />
        </>
      ) : (
        <h3 className="text-center">The list of order items is empty </h3>
      )}
    </>
  );
};

Main.propTypes = {
  orders: PropTypes.array,
  ordersPagination: PropTypes.object,
  onOrdersPageChange: PropTypes.func,
  products: PropTypes.array,
  notes: PropTypes.array,
  paymentMethods: PropTypes.array,
  onRemovePaymentMethod: PropTypes.func.isRequired,
};

export default Main;
