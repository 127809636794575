import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Preloader from 'components/Preloader';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import ProductsTable from './ProductsTable';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as ordersActions } from 'models/orders/slice';
import {
  orderSelector,
  isPendingSelector as ordersIsPendingSelector,
  isOrderSendRefundPending,
} from 'models/orders/selectors';
import RefundOrderInfo from './RefundOrderInfo';
import { getAvailableRefundedOrderItems } from 'utils/getAvailableRefundedOrderItems';

const getSelectedTotal = (orderItems, selectedItems, shipment_price, tips) => {
  return (
    orderItems.reduce((previous, current) => {
      const { id: currentId, number, total_price } = current;
      const selectedCount = selectedItems[currentId]?.number ?? 0;
      const amount = (total_price * selectedCount) / number;
      return previous + amount;
    }, shipment_price || 0) + tips
  );
};

const getPreparedOrderItemsForRefund = orderItems => {
  return orderItems
    .filter(([, value]) => value.number > 0)
    .map(([key, value]) => ({
      ...value,
      back_to_inventory: value?.back_to_inventory_number > 0,
      order_item_id: +key,
      number: value.number,
    }));
};

const RefundOrder = ({ title }) => {
  const { id } = useParams();

  const fetchOrder = useAction(ordersActions.fetchOrder);
  const fetchRefundOrder = useAction(ordersActions.refundOrder);
  const orderIsPending = useSelector(ordersIsPendingSelector);
  const orderRefundIsPending = useSelector(isOrderSendRefundPending);
  const order = useSelector(orderSelector);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [total, setTotal] = useState(0);
  const [refundType, setRefundType] = useState(null);
  const [comment, setComment] = useState('');
  const [shipping, setShipping] = useState(false);
  const [shouldRefundTips, setShouldRefundTips] = useState(false);

  const { availableOrderItems } = useMemo(
    () => getAvailableRefundedOrderItems(order?.order_items ?? []),
    [order]
  );
  const shipment = useMemo(
    () =>
      !order?.is_shipping_refunded
        ? order?.shipping_info?.shipments?.[0]
        : null,
    [order]
  );

  const isRedirect =
    order?.status === 'RETURNED' ||
    (order?.status === 'SHIPPED' && order?.resourcetype === 'CommercialOrder');

  useEffect(() => {
    fetchOrder(id);
  }, [fetchOrder, id]);

  const onTableChange = data => {
    setSelectedProducts(data);
  };

  useEffect(() => {
    setTotal(
      getSelectedTotal(
        availableOrderItems,
        selectedProducts,
        shipping && +order?.shipping_info?.shipments?.[0]?.price,
        shouldRefundTips && !order?.is_tips_refunded ? order?.tips_amount : 0
      )
    );
  }, [total, order, shipping, selectedProducts, shouldRefundTips]);

  const handleRefundSubmit = () => {
    fetchRefundOrder({
      orderId: id,
      data: {
        refund_type: refundType?.value,
        comment,
        order_items: getPreparedOrderItemsForRefund(
          Object.entries(selectedProducts)
        ),
        reason: 5,
        shipping_refund: shipping,
        refund_tips: shouldRefundTips,
      },
    });
  };

  if (orderIsPending) return <Preloader />;

  if (isRedirect) return <Redirect to={`/orders/${order?.id}`} />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`Order #${id}`}
        breadcrumbItems={[{ title: 'Back to orders', link: '/orders/' }]}
      />
      <Row>
        <Col lg={8}>
          <ProductsTable
            onChange={onTableChange}
            products={availableOrderItems}
            tips={order?.tips_amount}
            isTipsRefunded={order?.is_tips_refunded}
            shouldRefundTips={shouldRefundTips}
            setShouldRefundTips={setShouldRefundTips}
            shipment={shipment}
            shipping={shipping}
            setShipping={setShipping}
          />
        </Col>
        <Col lg={4}>
          <RefundOrderInfo
            setComment={setComment}
            order={order}
            total={total}
            selectedProducts={selectedProducts}
            onSubmit={handleRefundSubmit}
            setRefundType={setRefundType}
            orderRefundIsPending={orderRefundIsPending}
            refundType={refundType}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

RefundOrder.propTypes = {
  title: PropTypes.string,
};

export default RefundOrder;
