import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Table } from 'reactstrap';

import { TRANSFERS_TYPES, WRITE_OFF_REASON_TYPES } from 'constants';
import { getProductTitleWithVintage } from 'utils/preparedProductTitle';
import CardItem from 'components/CardItem';

const OtherTransfers = ({ transfer }) => {
  const isAdjustment = typeof transfer.parent_transfer_id === 'number';
  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Operator" value={transfer?.operator?.email} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Type"
            value={
              isAdjustment
                ? 'Inventory adjustment'
                : TRANSFERS_TYPES[transfer.resourcetype]
            }
          />
        </Col>
        <Col lg="3">
          <CardItem title="Comment" value={transfer.comment} />
        </Col>

        {transfer.resourcetype === 'WriteOffTransfer' && transfer?.reason && (
          <Col lg="3">
            <CardItem
              title="Reason"
              value={WRITE_OFF_REASON_TYPES[transfer?.reason]}
            />
          </Col>
        )}

        <Col lg="3">
          <CardItem title="Status" value={transfer?.status?.toLowerCase()} />
        </Col>
        {isAdjustment && (
          <Col lg="3">
            <CardItem
              title="Parent Transfer"
              value={
                transfer?.sender?.title && (
                  <Link to={`/transfers/${transfer?.parent_transfer_id}/show`}>
                    {transfer?.parent_transfer_id}
                  </Link>
                )
              }
            />
          </Col>
        )}
        <Col lg="3">
          <CardItem
            title="Sender"
            value={
              transfer?.sender?.title && (
                <Link to={`/warehouses/${transfer?.sender?.id}/show`}>
                  {transfer?.sender?.title}
                </Link>
              )
            }
          />
        </Col>
        <Col lg="3">
          <CardItem
            title="Receiver"
            value={
              transfer?.receiver?.title && (
                <Link to={`/warehouses/${transfer?.receiver?.id}/show`}>
                  {transfer?.receiver?.title}
                </Link>
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="p-3">
            <h4 className="card-title mb-4">Transfer Items</h4>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table id="tech-companies-1" bordered responsive>
                  <thead>
                    <tr>
                      <th data-priority="1">Title</th>
                      <th data-priority="1">SKU</th>
                      <th data-priority="1">Quantity</th>
                      <th data-priority="1">Product Price</th>

                      <th data-priority="3">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfer?.transfer_items?.map(item => (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={`/products/${item.product?.id}/show`}
                            className="text-dark font-weight-bold"
                          >
                            {getProductTitleWithVintage(
                              item.title,
                              item?.product?.vintage
                            )}
                          </Link>
                        </td>
                        <td>{item?.product?.sku}</td>
                        <td>{item.quantity} </td>
                        <td>
                          {`${item.product_price} ${item.product_price_currency}`}{' '}
                        </td>
                        <td>
                          {`${item.total_price} ${item.total_price_currency}`}{' '}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

OtherTransfers.propTypes = {
  transfer: PropTypes.object,
};

export default OtherTransfers;
