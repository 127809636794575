import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import styles from './ActiveSubscriptions.scss';

const ActiveSubscriptions = ({ wineclubs }) => (
  <ul className={styles.list}>
    {wineclubs
      .filter(wineclub => wineclub.subscripted_tier)
      .map(wineclub => (
        <li key={wineclub?.wineclub_id}>
          {wineclub?.subscripted_tier?.title} ({wineclub?.wineclub_title})
        </li>
      ))}
  </ul>
);

ActiveSubscriptions.propTypes = {
  wineclubs: PropTypes.array,
};

export default memo(ActiveSubscriptions);
