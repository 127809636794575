import React, { memo } from 'react';
import styles from './EmailTemplatePopup.scss';
import PopupBase from '../PopupBase';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const EmailTemplatePopup = ({ onClose, loading, layout }) => {
  return (
    <PopupBase
      className={styles.popupContainer}
      title="Email Template Preview"
      onClose={onClose}
    >
      <div className={styles.container}>
        {loading ? (
          <Spinner className={styles.spinner} color="primary" />
        ) : (
          <>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: layout }} />
          </>
        )}
      </div>
    </PopupBase>
  );
};

EmailTemplatePopup.propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  layout: PropTypes.string,
};
export default memo(EmailTemplatePopup);
