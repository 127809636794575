import PropTypes from 'prop-types';

export const FieldType = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.element,
  fieldClassName: PropTypes.string,
  min: PropTypes.string,
  maxLength: PropTypes.number,
};
