import React, { useEffect, useState } from 'react';
import styles from './Filter.scss';
import { Col, Row } from 'reactstrap';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import { COMMISSIONS_STATUSES } from 'constants/commissions';
import SalesRepPaginatedField from 'components/PaginatedFields/SalesRepPaginatedField';
import Filters from 'components/Filters';
import { useDateRange } from 'hooks/useDateRange';
import PropTypes from 'prop-types';

const commissionsOptions = Object.entries(COMMISSIONS_STATUSES).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const Filter = ({
  onFiltersChange,
  isExpanded,
  onExport,
  downloadDisabled,
}) => {
  const { dateRange, onDateChangeHandler } = useDateRange();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const startDate = dateRange?.startDate || null;
    const endDate = dateRange?.endDate || null;

    onFiltersChange?.({
      ...filters,
      date_range_start: startDate,
      date_range_end: endDate,
    });
  }, [filters, dateRange]);

  return (
    <Row className="mb-4">
      <Col md={12}>
        {isExpanded && (
          <Row>
            <Col className="d-flex align-items-center" md={12}>
              <label className={styles.selectField}>
                <BrandsPaginatedField
                  placeholder="Select brand"
                  isInitialLabel
                  isMulti={false}
                  onChange={({ value, label }) =>
                    setFilters(prevState => ({
                      ...prevState,
                      brand_id: value,
                      brand_title: label,
                    }))
                  }
                />
              </label>
              <label className={styles.selectField}>
                <Select
                  name="status"
                  placeholder="Select status"
                  options={[
                    { value: undefined, label: 'All statuses' },
                    ...commissionsOptions,
                  ]}
                  styles={selectStyles}
                  onChange={({ value }) =>
                    setFilters(prevState => ({ ...prevState, status: value }))
                  }
                />
              </label>
              <label className={styles.selectField}>
                <SalesRepPaginatedField
                  onChange={({ value }) =>
                    setFilters(prevState => ({
                      ...prevState,
                      sales_rep_id: value,
                    }))
                  }
                />
              </label>
            </Col>
          </Row>
        )}
        <Filters
          filters={filters}
          setFilters={setFilters}
          hasDateRangeFilter
          hasPerPageFilter
          exportButtonText="Export to Excel"
          setDateRange={onDateChangeHandler}
          onExportfunc={onExport}
          disabled={downloadDisabled}
        />
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  isExpanded: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  onExport: PropTypes.func,
  downloadDisabled: PropTypes.bool,
};

export default Filter;
