import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReportsLayout from 'components/ReportsLayout';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import { actions as reportsActions } from 'models/reports/slice';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/reports/selectors';
import { Button, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';

const getPeriodValueAsText = ({ startDate, endDate }) => {
  const template = 'yyyy-MM-dd';
  return `${formatDate(startDate, template)} through ${formatDate(
    endDate,
    template
  )}`;
};

const ReportsExport = ({ title }) => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [lastExportDate, setLastExportDate] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [isIncludeExported, setIncludeExported] = useState(true);

  const exportReportsFile = useAction(reportsActions.exportReportsFile);
  const isPending = useSelector(isPendingSelector);
  const isDisableExportBtn = !selectedBrand || isPending;

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  useEffect(() => {
    if (selectedBrand) {
      const exportDate =
        selectedBrand?.last_export_date ?? selectedBrand?.created_at;
      if (exportDate) {
        setLastExportDate({
          ...dateRange,
          startDate: exportDate,
        });
        setDateRange({
          ...dateRange,
          startDate: exportDate,
        });
      }
    }
  }, [selectedBrand, setDateRange]);

  const onExport = useCallback(() => {
    if (dateRange) {
      const { startDate, endDate } = dateRange;
      exportReportsFile({
        date_range_start: startDate,
        date_range_end: endDate,
        brand_id: selectedBrand.value,
        orders_type: 'ALL',
        type: 'QuickBook',
        include_exported: isIncludeExported,
      });
    }
  }, [exportReportsFile, isIncludeExported, dateRange, selectedBrand]);

  return (
    <>
      <ReportsLayout
        title={title}
        onExport={handleOpenConfirmModal}
        onDateChange={setDateRange}
        date={dateRange}
        isDownloadDisabled={isDisableExportBtn}
        exportButtonText="Download"
        lastExportDate={lastExportDate}
        setLastExportDate={setLastExportDate}
        isExportPage
        onBrandChange={setSelectedBrand}
        actionChildren={
          <label className="d-flex align-items-center ml-4">
            <input
              id="free_shipping"
              name="free_shipping"
              type="checkbox"
              checked={isIncludeExported}
              onChange={event => setIncludeExported(event.target.checked)}
            />
            <Label
              className="ml-2 mb-0"
              style={{ fontWeight: 'normal' }}
              htmlFor="free_shipping"
            >
              Include Previously Exported Orders
            </Label>
          </label>
        }
      >
        <>
          <Link to="/reports/export-history">
            <Button id="SalesDatesPicker" type="button" color="primary">
              View Past Exports
            </Button>
          </Link>
          {confirmModal && (
            <ConfirmationPopup
              title="Confirm export"
              cancelButtonTitle="Cancel"
              submitButtonTitle="Confirm"
              submitButtonColor="primary"
              active={confirmModal}
              setActive={setConfirmModal}
              onSaveClick={onExport}
            >
              <div>
                <p>Brand: {selectedBrand?.label}</p>
                <p>Period: {getPeriodValueAsText(dateRange)}</p>
              </div>
            </ConfirmationPopup>
          )}
        </>
      </ReportsLayout>
    </>
  );
};

ReportsExport.propTypes = {
  title: PropTypes.string,
};

export default ReportsExport;
