import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import CardItem from 'components/CardItem';

const Header = ({ product }) => {
  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Title" value={product?.title} />
        </Col>
        <Col lg="3">
          <CardItem title="Tags" value={product?.tags?.join(', ') ?? '-'} />
        </Col>
        <Col lg="3">
          <CardItem title="Sort" value={product?.sort} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Volume"
            value={`${product?.volume_value}${product?.volume_unit}`}
          />
        </Col>
        <Col lg="3">
          <CardItem title="Brand" value={product?.brand?.title} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Price"
            value={`${product?.price} ${product?.price_currency}`}
          />
        </Col>
        {product?.can_be_a_sample && (
          <>
            <Col lg="3">
              <CardItem
                title="Sample (Glass Pour)"
                value={product?.can_be_a_sample ? 'Yes' : 'No'}
              />
            </Col>

            <Col lg="3">
              <CardItem
                title="Sample Price"
                value={`${product?.sample_price} ${product?.sample_price_currency}`}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Sample Volume"
                value={`${product?.sample_volume_value} ${product?.sample_volume_unit}`}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Sample Volume"
                value={`${product?.sample_volume_value} ${product?.sample_volume_unit}`}
              />
            </Col>
          </>
        )}
        <Col lg="3">
          <CardItem title="Cellar" value={product?.cellar} />
        </Col>
        <Col lg="3">
          <CardItem title="Residual Sugar" value={product?.residual_sugar} />
        </Col>
        <Col lg="3">
          <CardItem title="Vintage" value={product?.vintage} />
        </Col>
        <Col lg="3">
          <CardItem title="Lot" value={product?.lot} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Container World Notification Limit"
            value={product?.container_world_notification_limit ?? '-'}
          />
        </Col>
        <Col lg="3">
          <CardItem title="Acid" value={product?.acid} />
        </Col>
        <Col lg="3">
          <CardItem title="ph" value={product?.ph} />
        </Col>
        <Col lg="3">
          <CardItem title="Aging" value={product?.aging} />
        </Col>
        <Col lg="3">
          <CardItem title="Bottling Date" value={product?.bottling_date} />
        </Col>
        <Col lg="3">
          <CardItem title="Alcohol" value={product?.alcohol} />
        </Col>
        <Col lg="3">
          <CardItem title="Type" value={product?.resourcetype} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Total Inventory Count"
            value={product?.total_inventory_count}
          />
        </Col>
        <Col lg="3">
          <CardItem
            title="Can be used for subscription orders"
            value={product?.is_it_can_be_used_for_subscription_orders}
          />
        </Col>
        <Col lg="3">
          <CardItem title="SKU" value={product?.sku} />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <CardItem title="Notes" value={product?.notes} />
        </Col>
        <Col lg="6">
          <CardItem title="Description" value={product?.description} />
        </Col>
        <Col lg="6">
          <CardItem title="Teaser" value={product?.teaser} />
        </Col>
        <Col lg="6">
          <CardItem title="Winemaker Notes" value={product?.winemaker_notes} />
        </Col>
        <Col lg="6">
          <CardItem title="Food Pairing" value={product?.food_pairing} />
        </Col>
        <Col lg="6">
          <CardItem title="Tasting Notes" value={product?.tasting_notes} />
        </Col>
        <Col lg="6">
          <CardItem title="Production" value={product?.production} />
        </Col>
      </Row>
    </>
  );
};

Header.propTypes = {
  product: PropTypes.object,
};

export default Header;
