import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import s from './DataTable.scss';

const DataTable = ({
  isPending,
  hosts,
  setHostID,
  setIsOpenConfirmationModal,
}) => {
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsOpenConfirmationModal();
    setHostID(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>

          <th data-priority="3">Email</th>
          <th data-priority="3">Name</th>
          <th data-priority="3">Brand</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {hosts?.map(item => {
          const brand = item.user?.brands.find(b => b.id === item.brand_id);

          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.user?.email}</td>
              <td>
                {item.user?.first_name}&nbsp;{item.user?.last_name}
              </td>
              <td>
                <Link
                  to={`/brands/${item.brand_id}/edit`}
                  className="text-dark font-weight-bold"
                >
                  {brand?.title}
                </Link>
              </td>
              <td>
                <>
                  <Link
                    to="#"
                    onClick={e => handleDelete(e, item.id)}
                    className="text-danger"
                    id={`delete${item.id}`}
                  >
                    <i className="mdi mdi-trash-can font-size-18" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target={`delete${item.id}`}
                  >
                    Detach host
                  </UncontrolledTooltip>
                </>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  hosts: PropTypes.array,
  setHostID: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
};

export default DataTable;
