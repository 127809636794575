import React from 'react';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/importDataSources/selectors';
import BrandsDataSourcesForm from './BrandsDataSourcesForm';

const BrandsDataSourcesFormContainer = () => {
  const fetching = useSelector(isPendingSelector);
  if (fetching) return null;
  return <BrandsDataSourcesForm />;
};

export default BrandsDataSourcesFormContainer;
