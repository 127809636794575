import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as exciseActions } from 'models/excise/slice';
import {
  isPendingSelector,
  exciseLicenseSelector,
} from 'models/excise/selectors';

import Form from './Form';

const ExciseLicenseEdit = ({ title }) => {
  const { id } = useParams();

  const exciseLicense = useSelector(exciseLicenseSelector);
  const fetchExciseLicense = useAction(exciseActions.fetchExciseLicense);
  const updateExciseLicense = useAction(exciseActions.updateExciseLicense);
  const isPending = useSelector(isPendingSelector);

  useEffect(() => {
    fetchExciseLicense(id);
  }, [fetchExciseLicense, id]);

  const onSubmit = values => {
    updateExciseLicense({
      id,
      data: values,
    });
  };

  if (isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`Update ${exciseLicense?.business_name} license ${exciseLicense?.account_number}`}
        breadcrumbItems={[
          { title: 'Back to excise licenses', link: '/reports/excise' },
        ]}
      />
      {exciseLicense && (
        <Row>
          <Col md="12">
            <Form
              model={exciseLicense}
              onSubmit={onSubmit}
              disabled={isPending}
              submitTitle="Save"
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

ExciseLicenseEdit.propTypes = {
  title: PropTypes.string,
};

export default ExciseLicenseEdit;
