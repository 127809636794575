import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import cx from 'classnames';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import useAction from 'hooks/useAction';
import { actions } from 'models/footTraffic/slice';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ReactPlaceholder from 'react-placeholder';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { cameraStatsSelector } from '../../models/footTraffic/selectors';
import {
  CHART_OPTIONS,
  COMPARISON_AT_VALUES,
} from '../../pages/FootTraffic/constants';
import styles from './CameraStatsCard.scss';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getFormattedValue = (vl, formatString) => {
  let value = vl;
  if (!formatString) {
    return value;
  }

  if (value.indexOf('+00:00') > -1) {
    value = value.replace('+00:00', '');
  }
  return format(utcToZonedTime(`${value} UTC`, userTimezone, ''), formatString);
};

const CameraStatsCard = ({
  stats,
  startDate,
  endDate,
  deviceId,
  cameraIdentifier,
  warehouseId,
}) => {
  const [statsReturn, setStatsReturn] = useState({});
  const [fetched, setFetched] = useState(false);
  const statsFromState = useSelector(state =>
    cameraStatsSelector(state, stats.type)
  );
  const fetchCameraStats = useAction(actions.fetchCameraStats);
  const fetchCameraStatsPolling = useAction(actions.fetchCameraStatsPolling);
  const fetchCameraStatsStopPolling = useAction(
    actions.fetchCameraStatsStopPolling
  );
  const afterTimeout = false;

  useEffect(() => {
    if (hasContent && fetched) {
      return undefined;
    }

    if (statsFromState) {
      setStatsReturn(statsFromState);
    }

    if (stats.url && !fetched) {
      setFetched(true);

      const start = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      const end = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

      const payload = {
        id: deviceId,
        cameraIdentifier,
        url: stats.url,
        isCoreApi: stats.isCoreApi,
        warehouseId,
        start,
        end,
        date_range_start: start,
        date_range_end: end,
        statsType: stats.type,
      };
      if (stats.live) {
        fetchCameraStatsPolling(payload);
      } else {
        fetchCameraStats(payload);
      }
    }

    return () => {};
  }, [fetchCameraStats, statsFromState, fetched]);

  useEffect(() => {
    return () => {
      fetchCameraStatsStopPolling();
    };
  }, []);

  const hasContent = afterTimeout
    ? true
    : statsReturn.currentValue !== undefined &&
      statsReturn.currentValue === statsFromState.currentValue;

  const isMore =
    statsReturn.comparisonAt === COMPARISON_AT_VALUES.MORE ||
    statsReturn.comparisonAt === COMPARISON_AT_VALUES.EQUAL_GREEN;

  const hasComparisonValue =
    statsReturn.comparisonAt &&
    statsReturn.comparisonAt !== COMPARISON_AT_VALUES.NONE &&
    hasContent;

  const isEqual =
    statsReturn.comparisonAt === COMPARISON_AT_VALUES.EQUAL || !hasContent;

  return (
    <Card
      className={
        stats.live ? styles.cameraStatsCardLive : styles.cameraStatsCardDefault
      }
    >
      <CardBody>
        {!hasContent && (
          <div
            className={cx(styles.loader, !stats.live ? styles.loaderDark : '')}
          >
            <span />
          </div>
        )}
        {stats.live && (
          <>
            <h1>{stats.title}</h1>
            <div className={styles.lastReportAt}>
              {statsReturn.lastReportAt &&
                getFormattedValue(statsReturn.lastReportAt, "hh:mm aaaaa'm'")}
              <i className="ri-time-line" />
            </div>
            <div className={styles.valueLine}>
              <span className={styles.currentValue}>
                {getFormattedValue(statsReturn.currentValue, stats.format)}
              </span>
            </div>
            <div className={styles.graphContainer}>
              {(hasContent || afterTimeout) && (
                <div className={styles.graphLine}>
                  <div className={styles.graph}>
                    <Line
                      options={CHART_OPTIONS}
                      data={{
                        labels: [1, 2, 3, 4, 5, 6, 7],
                        datasets: [
                          {
                            data: statsReturn.pastValues,
                            borderColor: isMore ? '#2f9908' : '#515FCB',
                            tension: 0.4,
                          },
                        ],
                      }}
                    />
                  </div>
                  <div
                    className={cx([
                      styles.liveComparison,
                      isMore
                        ? styles.liveComparisonMore
                        : styles.liveComparisonLess,
                    ])}
                  >
                    <div className={styles.icon} />
                    {statsReturn.comparisonValue}
                  </div>
                </div>
              )}
              <span className={styles.description}>
                {statsReturn.description}
              </span>
            </div>
          </>
        )}
        {!stats.live && (
          <>
            <h1>{stats.title}</h1>
            {!hasContent && !afterTimeout && (
              <ReactPlaceholder
                showLoadingAnimation
                color="#F7F7F7"
                type="rect"
                style={{ width: '100%', height: 48, marginTop: '12px' }}
              />
            )}
            {hasContent && !statsReturn.error && (
              <span className={styles.currentValue}>
                {getFormattedValue(statsReturn.currentValue, stats.format)}
              </span>
            )}
            {hasContent && statsReturn.error && (
              <ReactPlaceholder
                showLoadingAnimation
                color="#FFFFFF"
                type="rect"
                style={{ width: '100%', height: 48, marginTop: '12px' }}
              />
            )}
            <div className={styles.line} />
            <div className={styles.comparisonLine}>
              {hasComparisonValue && (hasContent || afterTimeout) && (
                <div
                  className={cx([
                    styles.comparisonValue,
                    isMore
                      ? styles.comparisonValueMore
                      : styles.comparisonValueLess,
                    isEqual ? styles.comparisonValuesEqual : '',
                  ])}
                >
                  <div className={styles.arrow} />
                  {statsReturn.comparisonValue}
                </div>
              )}
              {!hasContent && !afterTimeout && (
                <ReactPlaceholder
                  showLoadingAnimation
                  color="#F7F7F7"
                  type="rect"
                  style={{ width: '100%', height: 28 }}
                />
              )}
              {hasContent && !statsReturn.error && (
                <span className={styles.description}>
                  {statsReturn.description}
                </span>
              )}
              {hasContent && statsReturn.error && (
                <span className={styles.description}>{statsReturn.error}</span>
              )}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

CameraStatsCard.propTypes = {
  stats: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  cameraIdentifier: PropTypes.string,
  deviceId: PropTypes.string,
  warehouseId: PropTypes.number,
};

export default CameraStatsCard;
