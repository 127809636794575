import React, { memo } from 'react';
import cx from 'classnames';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './CardItem.scss';

const CardItem = ({ title, value, isLargeBlock, className }) => {
  return (
    <Card
      className={cx(className, {
        [styles.card]: !isLargeBlock,
      })}
    >
      <CardBody className={cx(styles.cardBody)}>
        <h4 className="card-title">{title}</h4>
        <div className="mt-4">{value || '-'}</div>
      </CardBody>
    </Card>
  );
};

CardItem.propTypes = {
  title: PropTypes.any.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  isLargeBlock: PropTypes.bool,
};

export default memo(CardItem);
