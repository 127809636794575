import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrderInfoBlock.scss';

const Block = ({ title, value }) => (
  <div className={styles.block}>
    <p className={styles.text}>{title}</p>
    <p className={styles.text}>{value}</p>
  </div>
);

Block.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default Block;
