import axios from 'axios';
import { put, select } from 'redux-saga/effects';
import { tokenSelector } from 'models/user/selectors';
import config from 'config';

import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'utils/notification';

import { push } from 'connected-react-router';
import { getNormalText } from 'utils/getNormalText';

const NETWORK_SYSTEM_ERROR = 'Network Error';
const NETWORK_SYSTEM_ERROR_TIMEOUT = 'timeout of 0ms exceeded';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function* axiosWrapper(params) {
  return yield axios(params).then(response => response.data);
}

// eslint-disable-next-line consistent-return
export default function*({
  action,
  method = 'get',
  url,
  data,
  params,
  successNavigateTo,
  failureNavigateTo,
  headers = {},
  token,
  successMessage,
  failureMessage,
  showResponseError = true,
  isRequestWithoutToken,
  responseType = 'json',
  baseURL = null,
  withResponse,
}) {
  const payload = action?.payload;

  try {
    const totalHeaders = {
      ...defaultHeaders,
      ...headers,
    };
    if (!isRequestWithoutToken) {
      const authToken = yield select(tokenSelector);
      const _token = token || authToken;
      if (_token) {
        totalHeaders.authorization = `Token ${_token}`;
      }
    }

    const json = yield axiosWrapper({
      baseURL: baseURL || config.baseUrl,
      url,
      method,
      headers: totalHeaders,
      params,
      data,
      responseType,
    });

    if (withResponse) {
      return json;
    }

    if (action) {
      yield put({
        type: `${action.type}Success`,
        payload: {
          data: json,
          inResponseTo: payload,
        },
      });
    }

    if (successNavigateTo) {
      yield put(push(successNavigateTo));
    }

    if (successMessage) {
      showSuccessMessage('Success!', successMessage);
    }
  } catch ({ message, response = {} }) {
    const isNetworkError =
      message === NETWORK_SYSTEM_ERROR ||
      message === NETWORK_SYSTEM_ERROR_TIMEOUT;

    if (failureMessage) {
      showErrorMessage('Error!', failureMessage);
    }

    if (isNetworkError) {
      showErrorMessage('Error!', NETWORK_SYSTEM_ERROR);
    } else if (response) {
      const { status } = response;
      if (status >= 500) {
        showErrorMessage('Error!', response?.data?.__all__?.[0] || message);
      } else if (status === 401) {
        showErrorMessage('Unauthorized!', 'please sign in');
      } else if (status === 404) {
        showErrorMessage('Error!', 'Requested resource cannot be found');
      } else if (status === 422) {
        showWarningMessage('Validation Error', 'Request contains invalid data');
        if (action && response?.data) {
          yield put({
            type: `${action.type}Success`,
            payload: {
              data: response.data,
              inResponseTo: payload,
            },
          });
        }
      } else if (showResponseError) {
        Object.entries(response?.data ?? {}).forEach(([key, value]) => {
          const preparedKey = getNormalText(key);
          showErrorMessage(
            preparedKey.toString(),
            value.toString() || 'Bad request'
          );
        });
      }
    }

    yield put({
      type: `${action.type}Failure`,
      payload: {
        err: message,
        isNetworkError,
        response: response.data,
        inResponseTo: payload,
      },
    });

    if (failureNavigateTo) {
      yield put(push(failureNavigateTo));
    }
  }
}
