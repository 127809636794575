import React from 'react';
import styles from './FeeTableForm.scss';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { ORDER_TYPES, ORDER_ITEMS_TYPES, FEE_TITLE_TYPES } from 'constants';
import getUID from 'utils/getUID';

import TableRow from './TableRow';
import PropTypes from 'prop-types';

const FeeTableForm = ({ type, fees, errorFees, setFees }) => {
  const addFee = () => {
    setFees([
      ...fees,
      {
        id: getUID(),
        title: FEE_TITLE_TYPES.PST,
        alias: null,
        amount: null,
        order_class: ORDER_TYPES[0],
        order_item_class: ORDER_ITEMS_TYPES[type][0],
      },
    ]);
  };

  const removeFee = id => {
    setFees(fees.filter(item => item.id !== id));
  };

  const onChange = value => {
    setFees(
      fees.map(item => {
        return item.id === value.id ? value : item;
      })
    );
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Table className={styles.table} bordered responsive>
              <thead>
                <tr>
                  <th className={styles.columnTitle} data-priority="1">
                    Title
                  </th>
                  <th className={styles.columnTitle} data-priority="1">
                    Alias
                  </th>
                  <th className={styles.columnMedium} data-priority="3">
                    Amount
                  </th>
                  <th className={styles.column} data-priority="1">
                    Order Type
                  </th>
                  <th className={styles.column} data-priority="3">
                    Order Item Type
                  </th>
                  <th className={styles.columnSmall}>Action</th>
                </tr>
              </thead>
              <tbody>
                {fees.map(item => (
                  <TableRow
                    key={item.id}
                    value={item}
                    type={type}
                    error={errorFees[item.id]}
                    onChange={onChange}
                    onRemove={removeFee}
                  />
                ))}
                <tr>
                  <td colSpan={6}>
                    <Button
                      onClick={addFee}
                      color="success"
                      type="button"
                      className="waves-effect waves-light mr-1 text-nowrap"
                    >
                      <i className="ri-add-line align-middle mr-2" />
                      Add new Fee
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <p className={styles.error}>{error}</p> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

FeeTableForm.propTypes = {
  type: PropTypes.string.isRequired,
  fees: PropTypes.array.isRequired,
  errorFees: PropTypes.array,
  setFees: PropTypes.func.isRequired,
};
export default FeeTableForm;
