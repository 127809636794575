import { createSelector } from 'reselect';

export const rootSelector = state => state.tags;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const tagsSelector = createSelector(rootSelector, ({ tags }) => tags);
