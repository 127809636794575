import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopupBase from '../PopupBase';
import ShippingInformation from '../../ShippingInformation';
import styles from './AddressPopup.scss';
import { Button, Col, Row } from 'reactstrap';
import useAddressInputState from 'hooks/useAddressInputState';
import GiftInfo from './GiftInfo/GiftInfo';

const AddressPopup = ({ onClose, data, onSubmit }) => {
  const {
    addressData,
    setAddressData,
    isInvalidAddress,
  } = useAddressInputState(data?.address_info);

  const [isGift, setIsGift] = useState(false);
  const [message, setMessage] = useState('');

  const handleChangeGift = e => {
    const { checked } = e.target;
    setIsGift(checked);
    setMessage(null);
  };

  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <Row>
        <ShippingInformation
          addressData={addressData}
          setAddressData={setAddressData}
        />
        <GiftInfo
          isGift={isGift}
          changeGift={handleChangeGift}
          message={message}
          setMessage={setMessage}
        />
        <Col md="12">
          <Button
            className="d-block ml-auto"
            color="success"
            disabled={isInvalidAddress}
            onClick={() =>
              onSubmit({
                address: addressData,
                is_gift: isGift,
                gift_note: message,
                wineclub_id: data?.wineclub_id,
              })
            }
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </PopupBase>
  );
};

AddressPopup.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default AddressPopup;
