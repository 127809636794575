import React, { useMemo, memo, useEffect } from 'react';
import styles from './OrderItemsTable.scss';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Table } from 'reactstrap';
import classNames from 'classnames';
import { OrderItemRow, OrderItemRowWithCases } from './OrderItemRow';
import {
  calculateOrderItemTotalPrice,
  calculateOrderItemTotalDiscountedPrice,
} from 'utils/calculateOrderAmount';
import formatPrice from 'utils/formatPrice';
import getUID from 'utils/getUID';
import { showWarningMessage } from 'utils/notification';
import OrderItemsHead from './OrderItemsHead';
import { getTableHeadList } from './utils';

const OrderItemsTable = ({
  warehouseId,
  orderItems,
  onOrderItemsChange,
  priceType,
  className,
  isCases,
}) => {
  let itemsArray = Object.values(orderItems);

  useEffect(() => {
    itemsArray = Object.values(orderItems);
  }, [orderItems]);

  const selectedProductsId = useMemo(
    () =>
      itemsArray
        .filter(({ product_id }) => !!product_id)
        .map(({ product_id }) => product_id),
    [itemsArray]
  );

  const onCreateHandler = () => {
    if (itemsArray.some(item => item.product_id === null)) {
      showWarningMessage(
        'Product not selected',
        'Please select a product before adding a new one.'
      );
    } else {
      const newItem = {
        id: getUID(),
        product_id: null,
        title: null,
        number: 1,
        total_price: 0,
        product_count: 0,
      };
      onOrderItemsChange(prevState => ({
        ...prevState,
        [newItem.id]: newItem,
      }));
    }
  };

  const onChangeHandler = (id, data) => {
    const preparedData = data;
    onOrderItemsChange(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...preparedData,
      },
    }));
  };

  const onDeleteHandler = id => {
    const copiedItems = { ...orderItems };
    delete copiedItems[id];
    onOrderItemsChange(copiedItems);
  };

  const headList = getTableHeadList(isCases);

  const OrderItemNode = isCases ? OrderItemRowWithCases : OrderItemRow;

  return (
    <div className={classNames(styles.container, className)}>
      <Card>
        <CardBody>
          <Table bordered responsive>
            <OrderItemsHead headList={headList} />
            <tbody>
              <>
                {itemsArray.map(item => {
                  return (
                    <OrderItemNode
                      key={item?.id?.toString()}
                      id={item?.id}
                      warehouseId={warehouseId}
                      number={item?.number}
                      discount={item?.discount}
                      discountAmount={
                        !item?.discount_amount ? '0.00' : item?.discount_amount
                      }
                      discountPercentage={item?.discount_percentage}
                      numberCases={item?.numberCases}
                      productTitle={item?.product_title ?? item?.product?.title}
                      productId={item?.product_id ?? item?.product?.id}
                      productCount={item?.product_count}
                      productPrice={
                        (item?.[priceType] || item?.product_price) ?? 0
                      }
                      productBottlesInCase={item?.product_bottles_in_case ?? 0}
                      onChange={data => onChangeHandler(item?.id, data)}
                      onDelete={() => onDeleteHandler(item?.id)}
                      selectedProductsIds={selectedProductsId}
                      totalPrice={calculateOrderItemTotalPrice(item, priceType)}
                    />
                  );
                })}
              </>
              <tr>
                <td colSpan={headList.length - 2} />
                <td>
                  <strong>Before fees:</strong>
                </td>
                <td>
                  $
                  {formatPrice(
                    calculateOrderItemTotalDiscountedPrice(
                      itemsArray,
                      priceType,
                      isCases
                    ) ?? 0
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={headList.length}>
                  <Button
                    color="success"
                    type="submit"
                    className="waves-effect waves-light mr-1 text-nowrap"
                    onClick={onCreateHandler}
                  >
                    <i className="ri-add-line align-middle mr-2" />
                    Add new product
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

OrderItemsTable.propTypes = {
  warehouseId: PropTypes.number.isRequired,
  orderItems: PropTypes.object.isRequired,
  onOrderItemsChange: PropTypes.func,
  className: PropTypes.string,
  priceType: PropTypes.string,
  isCases: PropTypes.bool,
};

OrderItemsTable.defaultProps = {
  priceType: 'price',
};

export default memo(OrderItemsTable);
