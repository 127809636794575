import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { inventoriesForTransferSelector } from 'models/inventories/selectors';
import { actions as inventoriesActions } from 'models/inventories/slice';

import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';

import styles from './WarehouseToWarehouseFields.scss';
import InventoriesTable from './InventoriesTable';
import SelectField from 'components/SelectField/SelectField';
import {
  REDUCTIONS_EXCISE_REASON_OPTIONS,
  ADDITIONS_EXCISE_REASON_OPTIONS,
  W2W_REDUCTIONS_EXCISE_REASON_OPTIONS,
} from 'constants/transfers';

const WarehouseToWarehouseFields = ({
  isPending,
  receiverID,
  setReceiverID,
  senderID,
  setSenderID,
  resetQuantities,
  setExciseReason,
  exciseReason,
}) => {
  // States
  const [brandID, setBrandId] = useState(0);
  const [warehouseTouched, setWarehouseTouched] = useState(false);
  const [receivingWarehouseTouched, setReceivingWarehouseTouched] = useState(
    false
  );
  const [senderWarehouse, setSenderWarehouse] = useState(null);
  const [receiverWarehouse, setReceiverWarehouse] = useState(null);

  const fetchInventories = useAction(inventoriesActions.fetchInventories);
  const inventories = useSelector(inventoriesForTransferSelector);

  // Effects
  useEffect(() => {
    if (senderID && receiverID && senderID !== receiverID) {
      fetchInventories({
        left_joined_warehouse_ids: `${senderID},${receiverID}`,
        sort_field: 'title',
        sort_direction: 'asc',
        disable_pagination: true,
      });
    }
  }, [fetchInventories, senderID, receiverID]);

  // Handlers
  const handleChangeSendingWarehouse = warehouse => {
    setSenderWarehouse(warehouse);
    setSenderID(warehouse.value || '');
    setBrandId(warehouse.brand_id);
    resetQuantities();

    if (
      warehouse.is_used_as_excise_warehouse &&
      !receiverWarehouse?.is_used_as_excise_warehouse
    ) {
      setExciseReason(REDUCTIONS_EXCISE_REASON_OPTIONS[0]);
    }
  };

  const handleChangeReceivingWarehouse = warehouse => {
    setReceiverID(warehouse.id || '');
    setReceiverWarehouse(warehouse);
    resetQuantities();

    if (
      warehouse.is_used_as_excise_warehouse &&
      !senderWarehouse?.is_used_as_excise_warehouse
    ) {
      setExciseReason(ADDITIONS_EXCISE_REASON_OPTIONS[0]);
    }
  };

  // Get defaults
  const getSenderExciseReasons = () => {
    const senderIsExcise = senderWarehouse?.is_used_as_excise_warehouse;
    const receiverIsExcise = receiverWarehouse?.is_used_as_excise_warehouse;

    // from excise to non excise
    if (senderIsExcise && !receiverIsExcise) {
      return REDUCTIONS_EXCISE_REASON_OPTIONS;
      // from excise to excise
    } else if (senderIsExcise && receiverIsExcise) {
      return W2W_REDUCTIONS_EXCISE_REASON_OPTIONS;
    }
    return [];
  };

  const getReceiverExciseReasons = () => {
    const senderIsExcise = senderWarehouse?.is_used_as_excise_warehouse;
    const receiverIsExcise = receiverWarehouse?.is_used_as_excise_warehouse;

    // from excise to excise
    if (senderIsExcise && receiverIsExcise) {
      return [
        {
          label: 'Domestic Wine',
          value: 'DOMESTIC_WINE',
        },
        {
          label: 'Imported Wine',
          value: 'IMPORTED_WINE',
        },
      ];
      // from non excise to excise
    } else if (!senderIsExcise && receiverIsExcise) {
      return ADDITIONS_EXCISE_REASON_OPTIONS;
    }
    return [];
  };

  return (
    <Row className={classNames(isPending && styles.preloader)}>
      <Col lg="4">
        <FormGroup>
          <Label required htmlFor="sender_id">
            Sending Warehouse
          </Label>
          <WarehousesPaginatedField
            key="select-1"
            menuPlacement="top"
            placeholder="Sending Warehouse"
            onBlur={() => setWarehouseTouched(true)}
            onChange={handleChangeSendingWarehouse}
            classNames={classNames(
              warehouseTouched && !senderID && styles.error
            )}
            error={warehouseTouched && !senderID && 'Enter sending Warehouse'}
          />
          {senderWarehouse?.is_used_as_excise_warehouse && receiverWarehouse && (
            <Row className="mt-3">
              <Col>
                <SelectField
                  key="select-field-1"
                  className="mb-2"
                  name="excise_reason"
                  value={
                    receiverWarehouse?.is_used_as_excise_warehouse &&
                    senderWarehouse?.is_used_as_excise_warehouse
                      ? W2W_REDUCTIONS_EXCISE_REASON_OPTIONS[0]
                      : exciseReason
                  }
                  onChange={value => setExciseReason(value)}
                  options={getSenderExciseReasons()}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                  label="Excise Reason*"
                  disabled={
                    receiverWarehouse?.is_used_as_excise_warehouse &&
                    senderWarehouse?.is_used_as_excise_warehouse
                  }
                />
              </Col>
            </Row>
          )}
        </FormGroup>
      </Col>
      <Col lg="4">
        <FormGroup>
          <Label required htmlFor="receiver_id">
            Receiving Warehouse
          </Label>

          <WarehousesPaginatedField
            key="select-2"
            menuPlacement="top"
            placeholder="Receiving Warehouse"
            onBlur={() => setReceivingWarehouseTouched(true)}
            onChange={handleChangeReceivingWarehouse}
            classNames={classNames(
              receivingWarehouseTouched && !receiverID && styles.error
            )}
            parameters={{ brand_id: brandID }}
            error={
              receivingWarehouseTouched &&
              !receiverID &&
              'Enter receiving Warehouse'
            }
          />
          {receiverWarehouse?.is_used_as_excise_warehouse && senderWarehouse && (
            <Row className="mt-3">
              <Col>
                <SelectField
                  key="select-field-2"
                  className="mb-2"
                  name="excise_reason"
                  onChange={value => {
                    setExciseReason(value);
                  }}
                  options={getReceiverExciseReasons()}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                  label="Excise Reason*"
                />
              </Col>
            </Row>
          )}
        </FormGroup>
      </Col>

      <Col lg="12">
        {inventories.length !== 0 && (
          <InventoriesTable
            className={classNames(isPending && styles.preloader)}
            receiverID={receiverID}
            senderID={senderID}
            inventories={inventories}
          />
        )}
      </Col>
    </Row>
  );
};

WarehouseToWarehouseFields.propTypes = {
  isPending: PropTypes.bool,
  receiverID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  senderID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setReceiverID: PropTypes.func,
  setSenderID: PropTypes.func,
  resetQuantities: PropTypes.func,
  setExciseReason: PropTypes.func,
  exciseReason: PropTypes.object,
};

export default WarehouseToWarehouseFields;
