import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import Select from 'react-select';
import styles from './ReportInventoryFilters.scss';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import { RESOURCE_TYPES } from 'src/constants';

import { totalProductCountSelector } from 'models/reports/selectors';

const ReportInventoryFilters = ({
  filterWarehouseByBrand,
  filterSkuByWarehouse,
  filterSkuByBrand,
  filterSkuByProductType,
  selectedBrands,
  selectedProducts,
  selectedWarehouses,
  selectedProductTypes,
  setSelectedBrands,
  setSelectedProductTypes,
  setSelectedWarehouses,
  setSelectedProducts,
}) => {
  const filteredResourceTypes = RESOURCE_TYPES.filter(
    type => type.value !== undefined
  );

  const totalProductCount = useSelector(totalProductCountSelector);
  // Handlers
  const handleBrandsChange = brands => {
    let filteredBrands = brands;
    if (brands.length > 1) {
      filteredBrands = filteredBrands.filter(
        brand => brand.value !== undefined
      );
    } else if (brands.length < 1) {
      filteredBrands = [
        {
          label: 'All brands',
          value: undefined,
        },
      ];
    }
    setSelectedBrands(filteredBrands);
    setSelectedWarehouses([{ label: 'All warehouses', value: undefined }]);
    setSelectedProducts([{ label: 'All products', value: undefined }]);
  };

  const handleWarehousesChange = warehouses => {
    let filteredWarehouses = warehouses;
    if (warehouses.length < 1) {
      filteredWarehouses = [{ label: 'All warehouses', value: undefined }];
    } else if (warehouses.length > 1) {
      filteredWarehouses = warehouses.filter(
        warehouse => warehouse.value !== undefined
      );
    }
    setSelectedWarehouses(filteredWarehouses);
    setSelectedProducts([{ label: 'All products', value: undefined }]);
  };

  const handleProductTypesChange = productTypes => {
    let filteredProductTypes = productTypes;
    if (productTypes.length < 1) {
      filteredProductTypes = [
        {
          label: 'All types',
          value: undefined,
        },
      ];
    } else if (productTypes.length > 1) {
      filteredProductTypes = productTypes.filter(
        productType => productType.value !== undefined
      );
    }
    setSelectedProductTypes(filteredProductTypes);
    setSelectedProducts([{ label: 'All products', value: undefined }]);
  };

  const handleProductsChange = products => {
    let filteredProducts = products;
    if (products.length < 1) {
      filteredProducts = [
        {
          label: 'All products',
          value: undefined,
        },
      ];
    } else if (products.length > 1) {
      filteredProducts = products.filter(
        product => product.value !== undefined
      );
    }
    setSelectedProducts(filteredProducts);
  };

  // Functions
  const getBrandIds = (filter, brands) =>
    filter && brands.length > 0
      ? { brand_ids: brands.map(item => item.value) }
      : {};

  const getWarehouseIds = (filter, warehouses) =>
    filter && warehouses?.[0]?.value
      ? { warehouse_ids: warehouses.map(item => item.value) }
      : {};

  const getProductTypes = (filter, productTypes) =>
    filter ? { product_types: productTypes?.map(item => item.value) } : {};

  return (
    <Row>
      <Col md="4">
        <Card>
          <CardBody>
            <p className={styles.selectTitle}>Brand</p>
            <BrandsPaginatedField
              className={styles.selectRoot}
              onChange={brands => {
                handleBrandsChange(brands);
              }}
              value={selectedBrands}
              isMulti
              withShortOptions
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <Card>
          <CardBody>
            <p className={styles.selectTitle}>Warehouse</p>
            <WarehousesPaginatedField
              value={selectedWarehouses}
              onChange={warehouses => handleWarehousesChange(warehouses)}
              isMulti
              parameters={{
                ...getBrandIds(filterWarehouseByBrand, selectedBrands),
              }}
              initialLabel="All warehouses"
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <Card>
          <CardBody>
            <p className={styles.selectTitle}>Product Type</p>
            <Select
              className={styles.selectRoot}
              options={filteredResourceTypes}
              isMulti
              name="product_types"
              value={selectedProductTypes}
              onChange={productTypes => handleProductTypesChange(productTypes)}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <Card>
          <CardBody>
            <p className={styles.selectTitle}>SKU</p>
            <ProductsPaginatedField
              id="wine"
              value={selectedProducts}
              onChange={products => handleProductsChange(products)}
              options={{
                ...getBrandIds(filterSkuByBrand, selectedBrands),
                ...getWarehouseIds(filterSkuByWarehouse, selectedWarehouses),
                ...getProductTypes(
                  filterSkuByProductType,
                  selectedProductTypes
                ),
              }}
              isMulti
              withSKU
            />
          </CardBody>
        </Card>
      </Col>
      <Col md="4">
        <Card>
          <CardBody>
            <p className={styles.selectTitle}>
              {selectedProductTypes.length === 1 &&
              selectedProductTypes.some(type => type.label === 'Wine')
                ? 'Total Bottles'
                : 'Total Items'}
            </p>
            <p className="mb-3">{totalProductCount || 0}</p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

ReportInventoryFilters.propTypes = {
  filterWarehouseByBrand: PropTypes.bool,
  filterSkuByBrand: PropTypes.bool,
  filterSkuByWarehouse: PropTypes.bool,
  filterSkuByProductType: PropTypes.bool,
  selectedBrands: PropTypes.array,
  selectedProducts: PropTypes.array,
  selectedWarehouses: PropTypes.array,
  selectedProductTypes: PropTypes.array,
  setSelectedBrands: PropTypes.func,
  setSelectedProductTypes: PropTypes.func,
  setSelectedWarehouses: PropTypes.func,
  setSelectedProducts: PropTypes.func,
};

export default ReportInventoryFilters;
