import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { prepareAddress } from 'utils/prepareAddress';

const Main = ({ exciseLicense }) => {
  return (
    <>
      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Brand</h4>
              <div className="mt-4">{exciseLicense?.brand?.title}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Business Name</h4>
              <div className="mt-4">{exciseLicense?.business_name}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Account Number</h4>
              <div className="mt-4">{exciseLicense?.account_number}</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Address</h4>
              <div className="mt-4">
                {prepareAddress({
                  country: exciseLicense?.country,
                  state: exciseLicense?.state,
                  city: exciseLicense?.city,
                  line1: exciseLicense?.line1,
                  line2: exciseLicense?.line2,
                  postal_code: exciseLicense?.postal_code,
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="2">
          <Card>
            <CardBody>
              <h4 className="card-title">License Type</h4>
              <div className="mt-4">{exciseLicense?.license_type}</div>
            </CardBody>
          </Card>
        </Col>
        {exciseLicense?.license_type === 'WAREHOUSE' && (
          <Col lg="10">
            <Card>
              <CardBody>
                <h4 className="card-title">Warehouse</h4>
                <div className="mt-4">{exciseLicense?.warehouse?.title}</div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg="2">
          <Card>
            <CardBody>
              <h4 className="card-title">Wine Duty Rate under 1.2%</h4>
              <div className="mt-4">{exciseLicense?.wine_duty_rate_0}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="2">
          <Card>
            <CardBody>
              <h4 className="card-title">Wine Duty Rate under 7%</h4>
              <div className="mt-4">{exciseLicense?.wine_duty_rate_1}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="2">
          <Card>
            <CardBody>
              <h4 className="card-title">Wine Duty Rate over 7%</h4>
              <div className="mt-4">{exciseLicense?.wine_duty_rate_7}</div>
            </CardBody>
          </Card>
        </Col>

        {exciseLicense?.license_type !== 'WINE' && (
          <>
            <Col lg="2">
              <Card>
                <CardBody>
                  <h4 className="card-title">Spirits Duty Rate under 7%</h4>
                  <div className="mt-4">
                    {exciseLicense?.spirit_duty_rate_under_7}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2">
              <Card>
                <CardBody>
                  <h4 className="card-title">Spirits Duty Rate over 7%</h4>
                  <div className="mt-4">
                    {exciseLicense?.spirit_duty_rate_over_7}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2">
              <Card>
                <CardBody>
                  <h4 className="card-title">Spirits Special Duty Rate</h4>
                  <div className="mt-4">
                    {exciseLicense?.spirit_duty_rate_special}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

Main.propTypes = {
  exciseLicense: PropTypes.object,
};

export default Main;
