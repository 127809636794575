import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';

import useSelector from 'hooks/useSelector';
import {
  isPendingSelector as warehousesIsPendingSelector,
  warehousesSelector,
  paginationSelector,
} from 'models/warehouses/selectors';
import { actions as warehousesActions } from 'models/warehouses/slice';

import { IS_USED_AS_TASTING_ROOM } from './filterConstants';
import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const WarehousesSummary = ({ title }) => {
  const fetchWarehouses = useAction(warehousesActions.fetchWarehouses);
  const deleteWarehouse = useAction(warehousesActions.deleteWarehouse);
  const pagination = useSelector(paginationSelector);
  const warehouses = useSelector(warehousesSelector);
  const isPendingWarehouses = useSelector(warehousesIsPendingSelector);
  const query = useQuery();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  const [warehouseForDelete, setWarehouseForDelete] = useState(null);
  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );
  const [confirmModal, setConfirmModal] = useState(false);

  const isPending = isPendingWarehouses;

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchWarehouses({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        set_query: true,
      });
    }
  }, [filters, fetchWarehouses, currentPage, sortField, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortField, sortDirection]);

  const handleDeleteWarehouse = () => {
    deleteWarehouse(warehouseForDelete);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasFilterByTastingRoom
                    isTastingRoomFields={IS_USED_AS_TASTING_ROOM}
                    hasCreateItem
                    createItemLink="/warehouses/create"
                    disabled={isPending}
                    placeholder="Search... (By warehouse title)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  setWarehouseId={setWarehouseForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  warehouses={warehouses}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    setActive={setConfirmModal}
                    active={confirmModal}
                    onSaveClick={handleDeleteWarehouse}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

WarehousesSummary.propTypes = {
  title: PropTypes.string,
};

export default WarehousesSummary;
