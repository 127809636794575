import React from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';
import { terminalsLabelMap } from 'constants/terminalModels';

import s from './DataTable.scss';

const DataTable = ({
  isPending,
  terminals,
  setTerminalForDelete,
  setIsOpenConfirmationModal,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
}) => {
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsOpenConfirmationModal(true);
    setTerminalForDelete(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="1"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="1">Model</th>
          <th data-priority="1">Serial Number</th>
          <th data-priority="1">Warehouse</th>
          <th data-priority="2">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {terminals?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/terminals/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/terminals/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>
            </td>
            <td>{get(terminalsLabelMap, item.model, 'N/a')}</td>
            <td>{item.serial_number}</td>
            <td>
              <Link
                to={`/warehouses/${item.warehouse_id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.warehouse_title}
              </Link>
            </td>
            <td>
              <>
                <Link
                  to={`/terminals/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
                <Link
                  to="#"
                  onClick={e => handleDelete(e, item.id)}
                  className="text-danger"
                  id={`delete${item.id}`}
                >
                  <i className="mdi mdi-trash-can font-size-18" />
                </Link>
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${item.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  terminals: PropTypes.array,
  setTerminalForDelete: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};

export default DataTable;
