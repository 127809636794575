import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { prepareAddress } from 'utils/prepareAddress';
import CardItem from 'components/CardItem';

import styles from './Header.scss';
import WineclubRow from './WineclubRow';

const Header = ({
  customer,
  onWineClubMemberAction,
  wineClubMemberPending,
  showCancelReasonModal,
  onCancelSubscription,
  onActivateSubscription,
}) => {
  return (
    <>
      <Row>
        {!!customer?.first_name && !!customer?.last_name && (
          <Col lg="4">
            <CardItem
              title="Customer"
              value={`${customer?.first_name} ${customer?.last_name}`}
            />
          </Col>
        )}
        <Col lg="4">
          <CardItem title="Email" value={customer?.email} />
        </Col>
        <Col lg="4">
          <CardItem title="Birthday" value={customer?.birthday || '-'} />
        </Col>
        <Col lg="4">
          <CardItem
            title="Last Order Date"
            value={
              customer?.last_order_date
                ? format(new Date(customer?.last_order_date), 'yyyy-MM-dd')
                : '-'
            }
          />
        </Col>

        <Col lg="4">
          <CardItem
            title="Life Time Purchase Value"
            value={
              customer?.total_sum_of_paid_orders
                ? `$${customer?.total_sum_of_paid_orders}`
                : null
            }
          />
        </Col>
        <Col lg="4">
          <CardItem
            title="Number Of Shipments"
            value={customer?.total_number_of_shipments ?? '-'}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <CardItem
            title="Shipping Address"
            value={prepareAddress(customer?.customer_profile || {})}
          />
        </Col>
        <Col lg="4">
          <CardItem
            title="Shipping Phone"
            value={customer?.customer_profile?.phone}
          />
        </Col>
        <Col lg="4">
          <CardItem
            title="Billing Address"
            value={prepareAddress(customer?.customer_billing_data || {})}
          />
        </Col>
        <Col lg="4">
          <CardItem
            title="Billing Phone"
            value={customer?.customer_billing_data?.phone}
          />
        </Col>
      </Row>

      {!isEmpty(customer.wine_club_members) && (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody
                className={wineClubMemberPending && styles.loadingContainer}
              >
                <h4 className="card-title">Wine Clubs</h4>
                <Table bordered responsive className={styles.table}>
                  <thead>
                    <tr>
                      <th data-priority="1">ID</th>
                      <th data-priority="1">Brand</th>
                      <th data-priority="1">Member since</th>
                      <th data-priority="1">Subscribed tier</th>
                      <th data-priority="1">Gift</th>
                      <th data-priority="1">Gift message</th>
                      <th data-priority="1">Last order date</th>
                      <th data-priority="1">Cancel date</th>
                      <th data-priority="1">Cancel reason</th>
                      <th data-priority="1">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(customer?.wine_club_members || []).map(item => (
                      <WineclubRow
                        item={item}
                        onWineClubMemberAction={onWineClubMemberAction}
                        showCancelReasonModal={showCancelReasonModal}
                        onCancelSubscription={onCancelSubscription}
                        onActivateSubscription={onActivateSubscription}
                      />
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

Header.propTypes = {
  customer: PropTypes.object,
  showCancelReasonModal: PropTypes.func,
  onWineClubMemberAction: PropTypes.func,
  wineClubMemberPending: PropTypes.bool,
  onCancelSubscription: PropTypes.func,
  onActivateSubscription: PropTypes.func,
};

export default Header;
