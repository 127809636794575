import Holidays from 'date-holidays';

export const getStatHolidays = async (startDate, setStatHolidays) => {
  const year = new Date(startDate).getFullYear();
  try {
    const response = await fetch(
      `https://canada-holidays.ca/api/v1/provinces/BC?year=${year}`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch holidays from API');
    }
    const data = await response.json();
    const apiHolidays = data?.province?.holidays.map(holiday => {
      const date = new Date(holiday.date);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return {
        start: date,
        end: date,
        title: holiday.nameEn,
        allDay: true,
        status: 'HOLIDAY',
      };
    });
    setStatHolidays(apiHolidays);
  } catch (error) {
    console.error('Error fetching statutory holidays:', error);
    const hd = new Holidays('CA', 'BC');
    const libHolidays = hd
      .getHolidays(year)
      .filter(item => item.type === 'public')
      .map(holiday => ({
        start: new Date(holiday.date),
        end: new Date(holiday.date),
        title: holiday.name,
        allDay: true,
        status: 'HOLIDAY',
      }));
    setStatHolidays(libHolidays);
  }
};
