import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions as customersActions } from 'models/customers/slice';
import useSelector from 'hooks/useSelector';
import {
  customerSelector,
  isPendingSelector,
} from 'models/customers/selectors';
import { Col, Row } from 'reactstrap';
import CustomerForm from 'components/CustomerForm';
import { actions as wineclubsActions } from 'models/wineclubs/slice';
import {
  wineclubsSelector,
  isPendingSelector as wineclubsPendingSelector,
} from 'models/wineclubs/selectors';
import Preloader from 'components/Preloader';
import { getRedactedValues } from 'utils/getRedactedValues';

const EditCustomer = ({ title }) => {
  const { id } = useParams();
  const editCustomer = useAction(customersActions.editCustomer);
  const fetchCustomer = useAction(customersActions.fetchCustomer);
  const customer = useSelector(customerSelector);
  const isPending = useSelector(isPendingSelector);
  const fetchWineclubs = useAction(wineclubsActions.fetchWineclubs);
  const wineclubs = useSelector(wineclubsSelector);
  const wineclubsPending = useSelector(wineclubsPendingSelector);

  useEffect(() => {
    fetchWineclubs();
  }, [fetchWineclubs]);

  useEffect(() => {
    fetchCustomer(id);
  }, [id, fetchCustomer]);

  const handleSubmit = data => {
    editCustomer({ id, ...getRedactedValues(customer, data) });
  };

  if (wineclubsPending || isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit customer"
        breadcrumbItems={[
          {
            title: 'Back to customers',
            link: '/wineclub-customers',
            withSearch: true,
          },
        ]}
      />
      {customer && (
        <Row>
          <Col md={12}>
            <CustomerForm
              onSubmit={handleSubmit}
              model={customer}
              wineclubs={wineclubs}
              buttonTitle="Save"
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditCustomer.propTypes = {
  title: PropTypes.string,
};

export default EditCustomer;
