import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Pagination from 'components/Pagination';

const DataTable = ({ pagination, setCurrentPage, children }) => {
  return (
    <div className="table-rep-plugin">
      <div className="mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" bordered>
          {children}
        </Table>
        {pagination && pagination.total_pages > 1 && (
          <Pagination onClick={setCurrentPage} pagination={pagination} />
        )}
      </div>
    </div>
  );
};

DataTable.propTypes = {
  pagination: PropTypes.object,
  children: PropTypes.any,
  setCurrentPage: PropTypes.func,
};

export default DataTable;
