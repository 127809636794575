import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const sharedCustomer = createSlice({
  name: 'sharedCustomer',
  initialState: {
    isPending: false,
    sharedCustomer: {},
    fieldErrors: {},
  },
  reducers: {
    fetchSharedCustomer: state => {
      state.isPending = true;
    },
    fetchSharedCustomerSuccess(state, { payload }) {
      state.isPending = false;
      state.sharedCustomer = payload.data;
    },
    fetchSharedCustomerFailure: state => {
      state.isPending = false;
    },
    updateSharedCustomer: state => {
      state.isPending = true;
    },
    updateSharedCustomerSuccess(state, { payload }) {
      state.isPending = false;
      state.sharedCustomer = payload.data;
    },
    updateSharedCustomerFailure: state => {
      state.isPending = false;
    },
    resetFieldErrors: state => {
      state.fieldErrors = {};
    },
  },
});

export const actions = actionTypes(sharedCustomer.actions);

export default sharedCustomer.reducer;
