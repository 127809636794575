import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { containerWorldExceptionPaginationSelector } from 'models/inventories/selectors';
import useSelector from 'hooks/useSelector';
import { actions } from 'models/inventories/slice';
import useAction from 'hooks/useAction';
import { MILLISECONDS_IN_AN_HOUR } from 'constants/time';

const ContainerWorldExceptionTitle = ({ title }) => {
  // Actions
  const fetchContainerWorldException = useAction(
    actions.fetchContainerWorldException
  );

  // Selectors
  const pagination = useSelector(containerWorldExceptionPaginationSelector);

  // Effects
  useEffect(() => {
    // Check CW exceptions on first render
    fetchContainerWorldException();

    const interval = setInterval(
      fetchContainerWorldException,
      MILLISECONDS_IN_AN_HOUR
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

ContainerWorldExceptionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContainerWorldExceptionTitle;
