import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Input } from 'reactstrap';
import { actions as transfersActions } from 'models/transfers/slice';
import useAction from 'hooks/useAction';

const InventoriesTable = ({ inventories, className }) => {
  const setQuantity = useAction(transfersActions.setQuantity);

  const isChangedRow = useCallback(item => item.quantity !== 0, []);

  return (
    <Card className={className}>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              <th data-priority="1">SKU</th>
              <th data-priority="1">Title</th>
              <th data-priority="1">Sender Quantity</th>
              <th data-priority="1">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {inventories.map(item => (
              <tr key={item.id}>
                <td>{item?.sku}</td>
                <td>
                  {item.title} {!!item.vintage && `(${item.vintage})`}
                </td>
                <td>
                  {isChangedRow(item) ? (
                    <>
                      <p className="badge badge-danger font-size-12">
                        {item.inventories?.[0]?.product_count}
                      </p>
                      &nbsp;
                      <p className="badge badge-success font-size-12">
                        {item.inventories?.[0]?.product_count - item.quantity}
                      </p>
                    </>
                  ) : (
                    item.inventories?.[0]?.product_count
                  )}
                </td>
                <td>
                  <Input
                    type="number"
                    invalid={item.isError}
                    className="form-control"
                    value={item.quantity || ''}
                    id="quantity"
                    name="quantity"
                    max={item.inventories?.[0]?.product_count}
                    onFocus={e =>
                      e.target.addEventListener(
                        'wheel',
                        function(evt) {
                          evt.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onChange={evt =>
                      setQuantity({
                        id: item.id,
                        value: Number(evt.target.value),
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

InventoriesTable.propTypes = {
  className: PropTypes.string,
  inventories: PropTypes.array,
};

export default InventoriesTable;
