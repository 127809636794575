import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/DeviceForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as devicesActions } from 'models/devices/slice';
import { isPendingSelector as devicesIsPendingSelector } from 'models/devices/selectors';

const CreateDevice = ({ title }) => {
  const devicesIsPending = useSelector(devicesIsPendingSelector);
  const createDevice = useAction(devicesActions.createDevice);

  const onSubmit = values => {
    createDevice(values);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new device"
        breadcrumbItems={[
          {
            title: 'Back to devices',
            link: '/devices/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={devicesIsPending}
            submitTitle="Save"
            onSubmit={onSubmit}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateDevice.propTypes = {
  title: PropTypes.string,
};

export default CreateDevice;
