import React from 'react';
import PropTypes from 'prop-types';
import _flatten from 'lodash/flatten';
import { Switch, Route } from 'react-router-dom';
import AuthLayout from 'components/Layouts/AuthLayout';
import MainLayout from 'components/Layouts/MainLayout';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import NotFound from 'pages/NotFound';
import RouterProvider from './RouterProvider';

const Router = ({ routes }) => {
  const mainLayoutRoutes = routes.filter(({ layout }) => layout === 'main');
  const authLayoutRoutes = routes.filter(({ layout }) => layout === 'auth');

  return (
    <RouterProvider>
      <Switch>
        <Route exact path={_flatten(mainLayoutRoutes.map(({ path }) => path))}>
          <MainLayout>
            <Switch>
              {mainLayoutRoutes.map(
                ({ path, component: Component, title, ...routeProps }) => (
                  <ProtectedRoute
                    key={path.toString()}
                    path={path}
                    {...routeProps}
                  >
                    <Component title={title} />
                  </ProtectedRoute>
                )
              )}
            </Switch>
          </MainLayout>
        </Route>
        <Route exact path={_flatten(authLayoutRoutes.map(({ path }) => path))}>
          <AuthLayout>
            <Switch>
              {authLayoutRoutes.map(
                ({ path, exact, component: Component, title }) => (
                  <ProtectedRoute
                    key={path.toString()}
                    path={path}
                    exact={exact}
                  >
                    <Component title={title} />
                  </ProtectedRoute>
                )
              )}
            </Switch>
          </AuthLayout>
        </Route>
        <ProtectedRoute path="/" exact />
        <Route path="*" exact>
          <MainLayout>
            <NotFound />
          </MainLayout>
        </Route>
      </Switch>
    </RouterProvider>
  );
};

Router.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Router;
