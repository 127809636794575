import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import FileField from 'components/FileField';
import TextField from 'components/Fields/TextField';

import useAction from 'hooks/useAction';
import { actions as imagesActions } from 'models/images/slice';
import useSelector from 'hooks/useSelector';
import { shouldFormBeClearedSelector } from 'models/images/selectors';

const Form = ({ onSubmit, disabled }) => {
  const [newImage, setNewImage] = useState(null);
  const [title, setTitle] = useState('');

  const shouldFormBeCleared = useSelector(shouldFormBeClearedSelector);
  const resetShouldFormBeCleared = useAction(
    imagesActions.resetShouldFormBeCleared
  );

  const handleChangeTitle = useCallback(e => {
    setTitle(e?.target.value);
  });

  const handleAddImage = useCallback(() => {
    onSubmit({ title, image: newImage });
  }, [newImage, title]);

  useEffect(() => {
    if (shouldFormBeCleared) {
      setTitle('');
      setNewImage(null);
      resetShouldFormBeCleared();
    }
  }, [shouldFormBeCleared]);

  return (
    <div className={classNames('needs-validation')}>
      <Row>
        <Col md={4}>
          <TextField
            label="Title*"
            name="image_title"
            value={title}
            onChange={handleChangeTitle}
          />{' '}
        </Col>
        <Col md={4}>
          <FileField
            isRequired
            label="Image*"
            name="image"
            onChange={setNewImage}
            value={newImage}
            preview={newImage}
            accept="image/jpeg,image/png"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            type="button"
            onClick={handleAddImage}
            disabled={disabled || !title || !newImage}
          >
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Form);
