import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { failedWineDirectSyncsSelector } from '../../models/wineDirectSync/selectors';

const BrandsTitle = ({ title }) => {
  // Selectors
  const failedWineDirectSyncs = useSelector(failedWineDirectSyncsSelector);

  // Constants
  const brandsWithFailedEntities =
    failedWineDirectSyncs?.brands_with_failed_entities;

  return (
    <span>
      {title}{' '}
      {brandsWithFailedEntities?.length !== 0 && (
        <span className="badge badge-light ml-2">
          {brandsWithFailedEntities?.length}
        </span>
      )}
    </span>
  );
};

BrandsTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BrandsTitle;
