import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './DataTable.scss';

import DropdownButton from './DropdownButton';

const DataTable = ({ isPending, exciseLicenses }) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="3">Business Name</th>
          <th data-priority="3">Account Number</th>
          <th data-priority="1">Type</th>
          <th data-priority="1">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [styles.preloader]: isPending })}>
        {exciseLicenses?.map(item => {
          return (
            <tr key={item.id}>
              <td>
                <Link
                  className="font-weight-bold btn-link"
                  to={`/reports/excise/license/${item.id}`}
                >
                  {item.business_name}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${item.id}`}
                >
                  {item.account_number}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${item.id}`}
                >
                  {item.license_type}
                </Link>
              </td>
              <td className={styles.actionContainer}>
                <DropdownButton
                  editHref={`/reports/excise/license/${item.id}/edit`}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  exciseLicenses: PropTypes.any,
  isPending: PropTypes.bool,
};

export default DataTable;
