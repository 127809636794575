import React from 'react';
import {
  ManualCountTitle,
  ContainerWorldExceptionCountTitle,
  InventoryCountTitle,
  LowInventoryCountTitle,
  ProductMovementTitle,
  OrdersTitle,
  CommercialOrdersTitle,
  DTCOrdersTitle,
  ReportsTitle,
  SettingsTitle,
  BrandsTitle,
} from 'components/SidebarTitle';
import {
  PERMISSION_TYPES,
  WORKSPACE_SETTING_TYPES,
} from 'constants/permissions';

export const sidebarLinks = [
  {
    title: 'Products',
    icon: 'ri-file-list-line',
    path: '/#',
    children: [
      {
        title: 'All Products',
        path: '/products/',
      },
      {
        title: 'Tasting Menus',
        path: '/products-presets/',
      },
      // {
      //   title: 'Food Allergies',
      //   path: '/food-allergies/',
      // },
      {
        title: 'Awards',
        path: '/awards/',
      },
      {
        title: 'Categories',
        path: '/#',
        children: [
          {
            title: 'Wine Categories',
            path: '/wine-categories/',
          },
          {
            title: 'Food Categories',
            path: '/food-categories/',
          },
          {
            title: 'Merchandise Categories',
            path: '/merchandise_categories/',
          },
        ],
      },
      {
        title: 'Discounts',
        path: '/discount-codes/',
      },
    ],
  },

  {
    title: 'Customers',
    icon: 'ri-group-line',
    path: '/#',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
    children: [
      {
        title: 'DTC',
        path: '/wineclub-customers/',
      },
      {
        title: 'Commercial',
        path: '/commercial-customers/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.SALES_REP,
          PERMISSION_TYPES.OWNER,
        ],
      },
      {
        title: 'Commercial Leads',
        path: '/leads/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.SALES_REP,
          PERMISSION_TYPES.OWNER,
        ],
      },
      // {
      //   title: 'Mailing lists',
      //   path: '/mailing-lists/',
      // },
    ],
  },
  {
    title: 'Wine Clubs',
    icon: 'ri-mail-send-line',
    path: '/#',
    children: [
      {
        title: 'Clubs',
        path: '/wineclubs/',
      },
      {
        title: 'Tiers',
        path: '/tiers/',
      },
    ],
  },

  {
    title: <OrdersTitle title="Orders" />,
    icon: 'ri-shopping-bag-line',
    path: '/#',
    children: [
      {
        title: <DTCOrdersTitle title="DTC" />,
        path: '/orders/',
      },
      {
        title: <CommercialOrdersTitle title="Commercial" />,
        path: '/commercial-orders/',
      },
      {
        title: 'Import Orders',
        path: '/import-orders/',
        workspace_settings: [WORKSPACE_SETTING_TYPES.ENABLE_COMMERCE_SEVEN],
      },
    ],
  },
  {
    title: <CommercialOrdersTitle title="Commercial Orders" />,
    icon: 'ri-shopping-bag-line',
    path: '/#',
    permissions: [PERMISSION_TYPES.SALES_REP],
    children: [
      {
        title: 'New Order',
        path: '/commercial-orders/create',
        permissions: [PERMISSION_TYPES.SALES_REP],
      },
      {
        title: <CommercialOrdersTitle title="Order History" />,
        path: '/commercial-orders/',
        permissions: [PERMISSION_TYPES.SALES_REP],
      },
    ],
  },
  {
    title: <ReportsTitle title="Reports" />,
    icon: 'ri-file-chart-line',
    path: '/#',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
      PERMISSION_TYPES.SALES_REP,
    ],
    children: [
      {
        title: 'Sales',
        path: '/reports/sales/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'Product Movement',
        path: '/reports/product-movements/',
        workspace_settings: [
          WORKSPACE_SETTING_TYPES.ENABLE_PRODUCT_MOVEMENT_REPORT,
        ],
      },
      {
        title: 'Taster Tracking',
        path: '/reports/taster-tracking/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'Batch',
        path: '/reports/batch/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'Sales by Associates',
        path: '/reports/sales-by-associates/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'QuickBooks',
        path: '/reports/export/',
        permissions: [PERMISSION_TYPES.BOOKKEEPER],
      },
      {
        title: <ReportsTitle title="Commissions" />,
        path: '/reports/commissions/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.SALES_REP,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'Excise',
        path: '/reports/excise/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
      {
        title: 'Inventory',
        path: '/reports/inventories/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
          PERMISSION_TYPES.SALES_REP,
        ],
      },
    ],
  },
  {
    title: <InventoryCountTitle title="Inventory" />,
    icon: 'ri-store-3-line',
    path: '/#',
    permissions: [
      PERMISSION_TYPES.HOST,
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
    ],
    children: [
      {
        title: <ProductMovementTitle title="Product Movement" />,
        path: '/transfers/',
      },
      {
        title: 'Count Inventory',
        path: '/inventory-count/create',
        permissions: [
          PERMISSION_TYPES.HOST,
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
        ],
      },
      {
        title: <ManualCountTitle title="Inventory Counts" />,
        path: '/inventory-count/',
        permissions: [
          PERMISSION_TYPES.HOST,
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
        ],
      },
      {
        title: <LowInventoryCountTitle title="Low Inventory" />,
        path: '/low-inventory/',
      },
      {
        title: (
          <ContainerWorldExceptionCountTitle title="ContainerWorld Exceptions" />
        ),
        path: '/cw-exceptions/',
      },
    ],
  },
  {
    title: 'Scheduling',
    icon: 'ri-time-line',
    path: '/#',
    children: [
      {
        title: 'Timesheets',
        path: '/timesheets/',
        permissions: [
          PERMISSION_TYPES.MANAGER,
          PERMISSION_TYPES.OWNER,
          PERMISSION_TYPES.BOOKKEEPER,
        ],
      },
    ],
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    title: 'Spaces',
    icon: 'ri-building-2-fill',
    path: '/#',
    children: [
      {
        title: 'Warehouses',
        path: '/warehouses/',
      },
      {
        title: 'Rooms',
        path: '/tasting_rooms/',
      },
      {
        title: 'Tables',
        path: '/tables/',
      },
      {
        title: 'Devices',
        path: '/devices/',
      },
      {
        title: 'Terminals',
        path: '/terminals/',
      },
    ],
  },
  {
    title: <SettingsTitle title="Settings" />,
    icon: 'ri-settings-5-fill',
    path: '/#',
    children: [
      {
        title: <BrandsTitle title="Brands" />,
        path: '/brands/',
      },
      {
        title: 'Email Setup',
        path: '/email-templates/',
      },
    ],
  },

  {
    title: 'Users',
    icon: 'ri-user-settings-line',
    path: '/users/',
  },

  {
    title: 'Payments',
    icon: 'ri-currency-line',
    path: '/acquirers/',
  },
  {
    title: 'Support',
    icon: 'ri-question-line',
    path: '/support/',
  },
  {
    title: 'Payment Methods',
    icon: 'ri-currency-line',
    path: '/payment-methods/',
    permissions: [PERMISSION_TYPES.SHARED_CUSTOMER],
  },
  {
    title: 'Customer Profile',
    icon: 'ri-profile-line',
    path: '/customer-profile/',
    permissions: [PERMISSION_TYPES.SHARED_CUSTOMER],
  },
];
