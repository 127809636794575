import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';

const BrandsPaginatedField = ({
  onChange,
  isMulti,
  defaultValue,
  isInitialLabel,
  key,
  withShortOptions,
  error,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchBrands = ({ page = 1 }) => {
    return apiRequest({
      url: `/brands/?page=${page}`,
    });
  };

  const loadBrands = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchBrands({ page });
      const options = response?.results.map(item =>
        withShortOptions
          ? { value: item.id, label: item.title }
          : {
              value: item.id,
              label: item.title,
              warehouses: item.warehouses,
              ...item,
            }
      );
      const result = {
        options:
          isInitialLabel && page === 1
            ? [{ value: undefined, label: 'All brands' }, ...options]
            : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
      return result;
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <>
      <AsyncPaginate
        {...props}
        name="brands"
        isSearchable={false}
        additional={{
          page: 1,
        }}
        defaultValue={defaultValue}
        loadOptions={loadBrands}
        onChange={onChange}
        isMulti={isMulti}
        styles={selectStyles}
      />
      {!!error && <small className="text-danger">{error}</small>}
    </>
  );
};

BrandsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMulti: PropTypes.bool,
  key: PropTypes.string,
  withShortOptions: PropTypes.bool,
  error: PropTypes.string,
};

BrandsPaginatedField.defaultProps = {
  defaultValue: [],
  isMulti: true,
};

export default BrandsPaginatedField;
