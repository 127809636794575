import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';
import DiscountCodeForm from 'components/DiscountCodeForm';

import useAction from 'hooks/useAction';
import {
  codeSelector,
  discountCodeIsPending,
} from 'models/discounts/selectors';
import { actions as discountCodesActions } from 'models/discounts/slice';
import { getRedactedValues } from 'utils/getRedactedValues';
import DiscountForm from 'components/DiscountForm';

const EditDiscountCode = ({ title }) => {
  const { codeID } = useParams();
  const discountCode = useSelector(codeSelector);
  const updateCode = useAction(discountCodesActions.updateCode);
  const fetchCode = useAction(discountCodesActions.fetchCode);

  useEffect(() => {
    fetchCode(codeID);
  }, [fetchCode, codeID]);

  const onSubmit = values => {
    updateCode({
      id: discountCode?.id,
      ...getRedactedValues(discountCode, values),
    });
  };

  if (discountCodeIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`${discountCode?.coupon ? 'Edit coupon code' : 'Edit discount'}`}
        breadcrumbItems={[
          {
            title: 'Back to discounts',
            link: `/discount-codes/?is_coupon=${!!discountCode?.coupon}`,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {!!discountCode?.coupon && discountCode?.id === Number(codeID) && (
            <DiscountCodeForm
              submitTitle="Update coupon code"
              onSubmit={onSubmit}
              model={discountCode}
              disabled={discountCodeIsPending}
            />
          )}
          {!discountCode?.coupon && discountCode?.id === Number(codeID) && (
            <DiscountForm
              submitTitle="Update discount"
              onSubmit={onSubmit}
              model={discountCode}
              disabled={discountCodeIsPending}
            />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

EditDiscountCode.propTypes = {
  title: PropTypes.string,
};

export default EditDiscountCode;
