import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';
import ProductActionDropdown from '../ProductActionDropdown';
import { Button } from 'reactstrap';

const DataTable = ({
  isPending,
  products,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  onArchive,
  onRestore,
}) => {
  return (
    <>
      <thead>
        <tr>
          <th>SKU</th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="3"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          <th>Vintage</th>
          <th>Image</th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort(
                'updated_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Updated
            <SortArrows
              isActive={sortField === 'updated_at'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort(
                'total_inventory_count',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Total Inventory
            <SortArrows
              isActive={sortField === 'total_inventory_count'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Warehouse</th>
          <th data-priority="3">Type</th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort('price', setSortField, setSortDirection, sortDirection)
            }
            data-priority="3"
          >
            Price
            <SortArrows
              isActive={sortField === 'price'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {products?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/products/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item?.sku ?? '-'}
              </Link>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <Link
                  to={`/products/${item.id}/show`}
                  className="text-dark font-weight-bold"
                >
                  {item.title}
                </Link>
              </div>
            </td>
            <td>{item?.vintage}</td>
            <td>
              <img src={item.main_image_url} alt="" height="60" />
            </td>
            <td>
              <Link
                to={`/products/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.updated_at
                  ? format(Date.parse(item.updated_at), 'yyyy-MM-dd, h:mm aaa')
                  : ''}
              </Link>
            </td>
            <td>
              <Link
                to={`/products/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.total_inventory_count}{' '}
              </Link>
            </td>
            <td>
              <div className="d-flex flex-wrap">
                {item.inventories?.map((warehouse, index) => (
                  <Link
                    key={`${warehouse.warehouse_id}-${index}`}
                    to={`/warehouses/${warehouse.warehouse_id}/show`}
                    className="text-dark font-weight-bold mr-2 d-flex align-items-center"
                  >
                    <strong>{warehouse.product_count}</strong>&nbsp;
                    <div className="badge badge-secondary font-size-12">
                      {warehouse.warehouse_title}
                    </div>
                  </Link>
                ))}
              </div>
            </td>
            <td>
              <Link
                to={`/products/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                {item.resourcetype}{' '}
              </Link>
            </td>
            <td>
              <Link
                to={`/products/${item.id}/show`}
                className="text-dark font-weight-bold"
              >
                ${item.price}
              </Link>
            </td>
            <td>
              {!item.archived ? (
                <ProductActionDropdown
                  onArchive={() => onArchive(item.id)}
                  onRestore={() => onRestore(item.id)}
                  isArchived={item.archived}
                  product={item}
                />
              ) : (
                <Button
                  className="w-100"
                  type="button"
                  onClick={() => onRestore(item.id)}
                  color="success"
                >
                  Restore
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  sortField: PropTypes.string,
  products: PropTypes.array,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  onArchive: PropTypes.func,
  onRestore: PropTypes.func,
};

export default DataTable;
