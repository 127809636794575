import React, { memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

const PASSWORD_FILL = '●●●●●●●●';

const FakePasswordField = ({ name, isFilled, ...props }) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>Password</Label>
      <AvField
        name={name}
        type="text"
        className="form-control"
        placeholder="Enter password"
        defaultValue={isFilled && PASSWORD_FILL}
        {...props}
      />
    </FormGroup>
  );
};

FakePasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isFilled: PropTypes.string,
  onChange: PropTypes.func,
};
export default memo(FakePasswordField);
