import React from 'react';
import PropTypes from 'prop-types';

const OrderItemsHead = ({ headList }) => {
  return (
    <thead>
      <tr>
        {headList.map(headItem => (
          <th key={headItem.label} data-priority={headItem?.priority}>
            {headItem.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};

OrderItemsHead.propTypes = {
  headList: PropTypes.array.isRequired,
};

export default OrderItemsHead;
