import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CardItem from 'components/CardItem';

const MerchandiseHeader = ({ product }) => {
  return (
    <>
      <Row>
        <Col md={3}>
          <CardItem title="Title" value={product?.title} />
        </Col>
        <Col md={3}>
          <CardItem title="Brand" value={product?.brand?.title} />
        </Col>
        <Col md={3}>
          <CardItem title="Price" value={`$${product?.price}`} />
        </Col>
        <Col md={3}>
          <CardItem title="Type" value={product?.resourcetype} />
        </Col>
        {product?.weight_value && (
          <Col md={3}>
            <CardItem
              title="Weight"
              value={`${product?.weight_value} ${product?.weight_unit}`}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={4}>
          <CardItem
            title="Categories"
            value={product?.categories?.map(({ title }) => title).join(', ')}
          />
        </Col>
        <Col md={4}>
          <CardItem title="SKU" value={product?.sku} />
        </Col>
        <Col md={4}>
          <CardItem title="Description" value={product?.description} />
        </Col>
      </Row>
    </>
  );
};

MerchandiseHeader.propTypes = {
  product: PropTypes.object.isRequired,
};
export default MerchandiseHeader;
