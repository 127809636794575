import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as exciseActions } from 'models/excise/slice';
import {
  isPendingSelector,
  exciseLicenseSelector,
  exciseReturnSelector,
} from 'models/excise/selectors';
import ReturnShowForm from './Form/ReturnShowForm';
import Breadcrumbs from 'components/Breadcrumbs';

const ExciseReturnShow = () => {
  const { excise_license_id, excise_license_type, id } = useParams();
  const exciseLicense = useSelector(exciseLicenseSelector);
  const exciseReturn = useSelector(exciseReturnSelector);
  const isPending = useSelector(isPendingSelector);
  const fetchExciseLicense = useAction(exciseActions.fetchExciseLicense);
  const fetchExciseReturn = useAction(exciseActions.fetchExciseReturn);

  useEffect(() => {
    if (excise_license_id && exciseLicense?.id !== excise_license_id) {
      fetchExciseLicense(excise_license_id);
    }
    if (id) {
      fetchExciseReturn({ excise_license_type, id });
    }
  }, [fetchExciseLicense, fetchExciseReturn, excise_license_id, id]);

  if (isPending || !exciseLicense?.id || !exciseReturn?.id) {
    return <Preloader />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <Breadcrumbs
            title="Show Excise Return"
            breadcrumbItems={[
              {
                title: 'Back to Excise License',
                link: `/reports/excise/license/${excise_license_id}`,
                withSearch: true,
              },
            ]}
          />
        </div>
      </div>
      <ReturnShowForm
        licenseType={excise_license_type}
        exciseLicense={exciseLicense}
        data={exciseReturn}
      />
    </div>
  );
};

export default ExciseReturnShow;
