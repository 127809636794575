import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import Payment from 'components/Payment';
import PropTypes from 'prop-types';
import { ORDER_PAYMENT_OPTIONS } from 'constants';
import Select from 'react-select';

const PaymentTab = ({
  stripeToken,
  stripeAccount,
  currentCustomerId,
  currentBrand,
  cardOptions,
  setCardOptions,
  setCurrentCard,
  currentCard,
  paymentType,
  payLater,
  options,
  setPaymentType,
  setPayLater,
  withoutType,
}) => {
  return (
    <>
      {!withoutType && (
        <>
          <Row>
            <Col md={6}>
              <div className="d-flex align-items-center mb-2">
                <input
                  id="checkbox"
                  name="checkbox"
                  key="checkbox"
                  type="checkbox"
                  value="true"
                  checked={payLater}
                  onChange={() => setPayLater(!payLater)}
                />
                <label
                  className="ml-2 mb-0"
                  htmlFor="current"
                  style={{ fontWeight: 'normal' }}
                >
                  <span className="text-dark font-size-16">
                    Payment pending
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-2">
        <Col md={6}>
          <Label>Payment Type</Label>
          <Select
            placeholder="Select payment type"
            options={options}
            onChange={e => setPaymentType(e)}
            value={paymentType}
            getOptionValue={option => option.apiValue}
            getOptionLabel={option => option.userValue}
          />
        </Col>
      </Row>
      {paymentType.apiValue === 'CARD' && !payLater && (
        <Payment
          stripeToken={stripeToken}
          stripeAccount={stripeAccount}
          currentCustomerId={currentCustomerId}
          currentBrand={currentBrand}
          cardOptions={cardOptions}
          setCardOptions={setCardOptions}
          setCurrentCard={setCurrentCard}
          currentCard={currentCard}
          withCardChoice
        />
      )}
    </>
  );
};

PaymentTab.propTypes = {
  stripeToken: PropTypes.string,
  stripeAccount: PropTypes.string,
  currentCustomerId: PropTypes.number,
  currentBrand: PropTypes.object,
  setCurrentCard: PropTypes.func,
  cardOptions: PropTypes.array,
  currentCard: PropTypes.object,
  setCardOptions: PropTypes.func,
  paymentType: PropTypes.object,
  setPaymentType: PropTypes.func,
  payLater: PropTypes.bool,
  setPayLater: PropTypes.func,
  withoutType: PropTypes.bool,
  options: PropTypes.array,
};

PaymentTab.defaultProps = {
  withoutType: false,
  options: ORDER_PAYMENT_OPTIONS,
};

export default PaymentTab;
