import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/ProductsPresetForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import {
  inventoriesSelector,
  isPendingSelector as inventoriesPendingSelector,
} from 'models/inventories/selectors';

import { actions as inventoriesActions } from 'models/inventories/slice';
import { actions as productsPresetsActions } from 'models/productsPresets/slice';

const CreateProductsPreset = ({ title }) => {
  const fetchInventories = useAction(inventoriesActions.fetchInventories);

  const inventories = useSelector(inventoriesSelector);
  const inventoriesIsPending = useSelector(inventoriesPendingSelector);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const isPending = inventoriesIsPending;
  const createProductsPreset = useAction(
    productsPresetsActions.createProductsPreset
  );

  useEffect(() => {
    if (!currentWarehouse) return;
    fetchInventories({
      warehouse_ids: [currentWarehouse.value],
      disable_pagination: true,
    });
  }, [fetchInventories, currentWarehouse]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new tasting menu"
        breadcrumbItems={[
          {
            title: 'Back to tasting menus',
            link: '/products-presets/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            inventories={inventories}
            disabled={isPending}
            submitTitle="Save"
            currentWarehouse={currentWarehouse}
            setCurrentWarehouse={setCurrentWarehouse}
            onSubmit={createProductsPreset}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateProductsPreset.propTypes = {
  title: PropTypes.string,
};

export default CreateProductsPreset;
