import React, { memo } from 'react';
import { selectStyles } from '../../../constants/selectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import useApiRequest from '../../../hooks/useApiRequest';
import {
  emailTemplateTypeName,
  EMAIL_TEMPLATES,
} from '../../../constants/emailTemplates';

const getEmailTitle = data => {
  const template = data.replace('v1/', '');
  return (
    EMAIL_TEMPLATES[template] || emailTemplateTypeName[template] || template
  );
};

const EmailCustomerPaginatedField = ({
  onChange,
  isMulti,
  className,
  parameters,
  initialOptions,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchCustomersEmail = async ({ page = 1, search }) => {
    const queryParameters = new URLSearchParams({
      page,
      title_contains: search,
      ...parameters,
    });

    return apiRequest({
      url: `/customers/last_emails?${queryParameters}`,
    });
  };

  const loadCustomersEmail = async (search, loadedOptions, { page }) => {
    try {
      const { results, pagination } = await fetchCustomersEmail({
        page,
        search,
      });
      const options = results.map(item => ({
        label: getEmailTitle(item.template),
        value: item.template,
      }));
      return {
        options,
        hasMore: pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      console.error(error);
      return {
        options: [],
        hasMore: false,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      additional={{
        page: 1,
      }}
      placeholder="Select email..."
      loadOptions={loadCustomersEmail}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
      className={className}
      {...props}
    />
  );
};

EmailCustomerPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  initialOptions: PropTypes.array,
  parameters: PropTypes.object,
  className: PropTypes.string,
};
export default memo(EmailCustomerPaginatedField);
