import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import s from './Pagination.scss';

const reg = /^(?:[1-9]\d*|\d)$/;

const ButtonComponent = ({ children, ...props }) => (
  <button type="button" {...props}>
    {children}
  </button>
);

const PaginationComponent = ({ pagination, onClick }) => {
  const { next_page, prev_page, current_page, total_pages } = pagination;
  const [currentPage, setCurrentPage] = useState(current_page);

  useEffect(() => {
    setCurrentPage(current_page);
  }, [current_page]);

  const handleOnChange = e => {
    const inputValue = e.target.value;
    if (!inputValue || reg.test(inputValue)) {
      if (inputValue <= total_pages) {
        setCurrentPage(inputValue);
      }
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onClick(currentPage || 1);
    }
  };

  return (
    <div className={s.root}>
      <Pagination aria-label="Page navigation example" className={s.root}>
        <PaginationItem>
          <PaginationLink
            tag={ButtonComponent}
            first
            onClick={() => onClick(1)}
          />
        </PaginationItem>
        {prev_page > 1 && (
          <PaginationItem disabled>
            <PaginationLink tag={ButtonComponent}>...</PaginationLink>
          </PaginationItem>
        )}

        {prev_page && (
          <PaginationItem>
            <PaginationLink
              tag={ButtonComponent}
              onClick={() => onClick(prev_page)}
            >
              {prev_page}
            </PaginationLink>
          </PaginationItem>
        )}

        {current_page && (
          <PaginationItem active>
            <PaginationLink
              tag={ButtonComponent}
              onClick={() => onClick(current_page)}
            >
              {current_page}
            </PaginationLink>
          </PaginationItem>
        )}
        {next_page && (
          <PaginationItem>
            <PaginationLink
              tag={ButtonComponent}
              onClick={() => onClick(next_page)}
            >
              {next_page}
            </PaginationLink>
          </PaginationItem>
        )}
        {next_page && total_pages > next_page && (
          <PaginationItem disabled>
            <PaginationLink tag={ButtonComponent}>...</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem>
          <PaginationLink
            tag={ButtonComponent}
            last
            onClick={() => onClick(total_pages)}
          />
        </PaginationItem>
      </Pagination>
      <div className={s.customNavigation}>
        <Input
          className={s.customNavigationInput}
          value={currentPage}
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
        />
        <span> of {total_pages}</span>
      </div>
    </div>
  );
};

PaginationComponent.propTypes = {
  pagination: PropTypes.object,
  onClick: PropTypes.func,
};

ButtonComponent.propTypes = {
  children: PropTypes.any,
};

export default PaginationComponent;
