import React from 'react';
import useSelector from 'hooks/useSelector';
import { salesSelector } from 'models/reports/selectors';
import { Card, CardBody } from 'reactstrap';
import formatPrice from 'utils/formatPrice';
import styles from './SalesOverviewCard.scss';

const typeNames = {
  productorderitem: 'Product',
  productsampleorderitem: 'Sample',
  tastingmenuorderitem: 'Tasting Menu',
  foodorderitem: 'Food',
};

const SalesOverviewCard = () => {
  const sales = useSelector(salesSelector);
  const { salesOverview } = sales;
  const total = React.useMemo(
    () =>
      salesOverview
        .filter(sale => Object.keys(typeNames).includes(sale['Product Type']))
        .reduce((acc, value) => {
          return acc + value['Net Product Sales'];
        }, 0),
    [salesOverview]
  );
  return (
    <Card>
      <CardBody>
        <div className={styles.title}>
          Products Sales <br /> (without taxes)
        </div>
        <div className={styles.number}>$ {formatPrice(total)}</div>
        <table className="table mt-3">
          <thead>
            <th>Category</th>
            <th>Total</th>
          </thead>
          <tbody>
            {salesOverview
              .filter(sale =>
                Object.keys(typeNames).includes(sale['Product Type'])
              )
              .map((source, index) => (
                <tr key={index}>
                  <td>{typeNames[source['Product Type']]}</td>
                  <td>$ {formatPrice(source['Net Product Sales'])}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SalesOverviewCard;
