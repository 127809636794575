import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import Form from 'components/BrandsForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as brandsActions } from 'models/brands/slice';
import {
  isPendingSelector,
  brandSelector,
  brandsStepSelector,
} from 'models/brands/selectors';
import { AvForm } from 'availity-reactstrap-validation';
import BrandsContainerWorldForm from 'components/BrandsContainerWorldForm';
import { isEmpty } from 'lodash';
import { BRAND_FORM_STEPS } from 'constants/brands';

const isTabDisabled = (step, brand) => {
  return isEmpty(brand)
    ? step === BRAND_FORM_STEPS.CONTAINER_WORLD
    : step === BRAND_FORM_STEPS.GENERAL;
};

const CreateBrand = ({ title }) => {
  const [dashboardLogo, setDashboardLogo] = useState();
  const [mobileLogo, setMobileLogo] = useState();
  const isPending = useSelector(isPendingSelector);
  const brand = useSelector(brandSelector);
  const step = useSelector(brandsStepSelector);
  const createBrand = useAction(brandsActions.createBrand);
  const updateBrand = useAction(brandsActions.updateBrand);
  const resetBrand = useAction(brandsActions.resetBrand);
  const setStep = useAction(brandsActions.setStep);

  const handleSubmit = (_, data) => {
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        delete data[key];
      }
    });

    createBrand({
      ...data,
      dashboard_logo: dashboardLogo ?? '',
      mobile_logo: mobileLogo ?? '',
    });
  };

  const handleContainerWorldSubmit = data => {
    if (!brand) return;
    updateBrand({
      id: brand.id,
      ...data,
      redirectUrl: '/brands',
    });
  };

  useEffect(() => {
    resetBrand();
    setStep(BRAND_FORM_STEPS.GENERAL);
  }, []);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new brand"
        breadcrumbItems={[
          { title: 'Back to brands', link: '/brands/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            {Object.values(BRAND_FORM_STEPS).map(value => (
              <NavItem>
                <NavLink
                  className="font-weight-bold p-3"
                  onClick={() => setStep(value)}
                  active={step === value}
                  disabled={isTabDisabled(value, brand)}
                >
                  {value}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
      <TabContent activeTab={step}>
        <TabPane tabId={BRAND_FORM_STEPS.GENERAL}>
          <Col md="12">
            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
              <Form
                disabled={isPending}
                submitTitle="Save"
                onMobileLogoChange={setMobileLogo}
                onDashboardLogoChange={setDashboardLogo}
              />
            </AvForm>
          </Col>
        </TabPane>
        <TabPane tabId={BRAND_FORM_STEPS.CONTAINER_WORLD}>
          {!isEmpty(brand) && (
            <Col md={12}>
              <BrandsContainerWorldForm
                defaultValues={brand}
                onSubmit={handleContainerWorldSubmit}
              />
            </Col>
          )}
        </TabPane>
      </TabContent>
    </PageWrapper>
  );
};

CreateBrand.propTypes = {
  title: PropTypes.string,
};

export default CreateBrand;
