import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InventoryTransferTable from '../InventoryTransferTable';
import { TRANSFER_TABLE_MODES } from '../InventoryTransferTable/constants';

const InventoryResultsTable = ({
  data,
  onApprove,
  isViewing,
  isFullViewing,
  isEditing,
  onChangeData,
  loading,
  inventoryCountType,
}) => {
  const [editorMode, setEditorMode] = useState(
    isEditing ? TRANSFER_TABLE_MODES.PROCESS : TRANSFER_TABLE_MODES.DEFAULT
  );

  useEffect(() => {
    setEditorMode(
      isEditing ? TRANSFER_TABLE_MODES.PROCESS : TRANSFER_TABLE_MODES.DEFAULT
    );
  }, [isEditing]);

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            {isEditing && (
              <Row className="justify-content-end mb-3">
                <Col md={2}>
                  <Button
                    disabled={loading}
                    onClick={onApprove}
                    className="w-100"
                    color="success"
                  >
                    Approve
                  </Button>
                </Col>
              </Row>
            )}
            <InventoryTransferTable
              data={data}
              editorMode={editorMode}
              isViewing={isViewing}
              isFullViewing={isFullViewing}
              isEditing={isEditing}
              isManual={false}
              disableClearManualData
              setEditorMode={setEditorMode}
              onChangeData={onChangeData}
              inventoryCountType={inventoryCountType}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

InventoryResultsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeData: PropTypes.func,
  onApprove: PropTypes.func,
  isEditing: PropTypes.bool,
  isViewing: PropTypes.bool,
  isFullViewing: PropTypes.bool,
  loading: PropTypes.bool,
  inventoryCountType: PropTypes.string.isRequired,
};
export default InventoryResultsTable;
