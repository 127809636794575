import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as awardsActions } from 'models/awards/slice';
import {
  isPendingSelector,
  awardsSelector,
  paginationSelector,
} from 'models/awards/selectors';

import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const AwardsSummary = ({ title }) => {
  const fetchAwards = useAction(awardsActions.fetchAwards);
  const deleteAward = useAction(awardsActions.deleteAward);

  const pagination = useSelector(paginationSelector);
  const awards = useSelector(awardsSelector);
  const isPending = useSelector(isPendingSelector);
  const query = useQuery();

  const [awardForDelete, setAwardForDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchAwards({
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        ...filters,
      });
    }
  }, [fetchAwards, currentPage, sortField, sortDirection, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortField, sortDirection]);

  const handleDeleteTable = () => {
    deleteAward(awardForDelete);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    disabled={isPending}
                    hasCreateItem
                    createItemLink="/award/create"
                    placeholder="Search... (By award name)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  setAwardID={setAwardForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  isPending={isPending}
                  awards={awards}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    onSaveClick={handleDeleteTable}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

AwardsSummary.propTypes = {
  title: PropTypes.string,
};

export default AwardsSummary;
