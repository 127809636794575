import React from 'react';
import styles from './ContainerWorldExceptionSummaryTable.scss';
import { Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDate } from 'utils/formatDate';

const ContainerWorldExceptionSummaryTable = ({ data, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {data && (
        <Row>
          <Col md={12}>
            <Table className={styles.table} bordered responsive>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Warehouse</th>
                  <th>SKU</th>
                  <th>Total Cellar Count</th>
                  <th>Total ContainerWorld Count</th>
                  <th>Total Difference</th>
                  <th>Reported At</th>
                  <th>Last Checked At</th>
                </tr>
              </thead>
              <tbody>
                {data.map(item => {
                  const brand_inventories = JSON.parse(item.brand_inventories);
                  const reported_inventories = JSON.parse(
                    item.reported_inventories
                  );
                  return [
                    <tr key={item.id?.toString()}>
                      <td>{item.brand.title}</td>
                      <td>{item.warehouse.title}</td>
                      <td>{item.sku}</td>
                      <td>{item.inventory_count}</td>
                      <td>{item.reported_count}</td>
                      <td>{item.diff_quantity}</td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{formatDate(item.updated_at)}</td>
                    </tr>,
                    <tr>
                      <td />
                      <td colSpan={7}>
                        <span>
                          <strong>Cellar&apos;s Count:</strong>
                        </span>
                        <br />
                        {brand_inventories.map(inv => [
                          <span>
                            {inv.sku} ({inv.name} {inv.vintage}): {inv.qty}
                          </span>,
                          <br />,
                        ])}
                        <br />
                        <span>
                          <strong>ContainerWorld&apos;s Count:</strong>
                        </span>
                        <br />
                        {reported_inventories.map(inv => [
                          <span>
                            {inv.sku} ({inv.name} {inv.vintage}): {inv.qty}
                          </span>,
                          <br />,
                        ])}
                      </td>
                    </tr>,
                  ];
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};

ContainerWorldExceptionSummaryTable.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};
export default ContainerWorldExceptionSummaryTable;
