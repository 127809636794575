import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import debounce from 'lodash/debounce';

import useAction from 'hooks/useAction';

import { actions as warehousesActions } from 'models/warehouses/slice';

import styles from './TastingRoomForm.scss';
import { getRedactedValues } from 'utils/getRedactedValues';
import classNames from 'classnames';
import TextField from '../Fields/TextField';

const Form = ({
  currentWarehouse,
  setCurrentWarehouse,
  currentWarehouseTitle,
  warehouses,
  model,
  submitTitle,
  disabled,
  onSubmit,
}) => {
  const fetchWarehouses = useAction(warehousesActions.fetchWarehouses);

  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        id: model?.id,
        ...getRedactedValues(model, {
          ...values,
          warehouse_id: currentWarehouse,
        }),
      });
    },
    [onSubmit, currentWarehouse]
  );
  const filterWarehouses = inputValue => {
    return warehouses?.filter(warehouse =>
      warehouse.title.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    fetchWarehouses({
      title_contains: inputValue,
    });
    callback(filterWarehouses(inputValue));
  };
  const debouncedLoadOptions = debounce(loadOptions, 200);

  const handleWarehousesChange = value => {
    setCurrentWarehouse(value.id);
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="4">
          <TextField
            label="Title"
            placeholder="Title"
            errorMessage="Enter Title"
            name="title"
          />
        </Col>

        <div className={classNames('w-100', disabled && styles.preloader)}>
          <Col lg="12">
            <FormGroup>
              <Label required htmlFor="warehouse_id">
                Warehouse
              </Label>
              {warehouses && (
                <AsyncSelect
                  className="basic-single mr-1"
                  classNamePrefix="select"
                  name="warehouse_id"
                  defaultInputValue={
                    currentWarehouseTitle || warehouses?.[0]?.title
                  }
                  defaultOptions={warehouses}
                  loadOptions={debouncedLoadOptions}
                  onChange={handleWarehousesChange}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.title}
                />
              )}
            </FormGroup>
          </Col>
        </div>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  currentWarehouseTitle: PropTypes.string,
  currentWarehouse: PropTypes.any,
  warehouses: PropTypes.array,
  setCurrentWarehouse: PropTypes.func,
};

export default Form;
