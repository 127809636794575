import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { showErrorMessage } from 'utils/notification';
import { AsyncPaginate } from 'react-select-async-paginate';
import isEmpty from 'lodash/isEmpty';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentWillUnmount from 'hooks/useComponentWillUnmount';

import {
  TRANSFER_TYPES,
  WRITE_OFF_REASON_TYPES,
  TASTER_WRITE_OFF_REASON_TYPES,
  WINE_SOURCE_TYPES,
} from 'constants';
import { customStyles } from 'constants/disabledAsync';
import {
  ADDITIONS_EXCISE_REASON_OPTIONS,
  REDUCTIONS_EXCISE_REASON_OPTIONS,
} from 'constants/transfers';

import {
  transferSelector,
  quantitiesSelector,
} from 'models/transfers/selectors';
import * as inventoriesSelectors from 'models/inventories/selectors';
import { actions as transfersActions } from 'models/transfers/slice';
import { actions as inventoriesActions } from 'models/inventories/slice';
import { currentWarehouseSelector } from 'models/warehouses/selectors';

import ArrivalFields from './ArrivalFields';
import WriteOffFields from './WriteOffFields';
import InventoryAdjustmentFields from './InventoryAdjustmentFields';
import WarehouseToWarehouseFields from './WarehouseToWarehouseFields';
import TextareaField from '../Fields/TextareaField';
import SelectField from '../SelectField/SelectField';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';

// Get defaults
const writeOffReasonTypes = Object.entries(WRITE_OFF_REASON_TYPES).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

const tasterWriteOffReasonTypes = Object.entries(
  TASTER_WRITE_OFF_REASON_TYPES
).map(([key, value]) => ({
  label: value,
  value: key,
}));

const getTransferItemsFromQuantities = quantities => {
  return Object.entries(quantities)
    .filter(arr => !arr.includes(0))
    .map(([key, value]) => ({
      product_id: key,
      quantity: +value,
    }));
};

const Form = ({
  submitTitle,
  disabled,
  receiverID,
  setReceiverID,
  senderID,
  setSenderID,
  onSubmit,
  productIDParam,
  warehouseIDParam,
}) => {
  // Selectors
  const transfer = useSelector(transferSelector);
  const isError = useSelector(
    inventoriesSelectors.hasErrorsInventoriesForTransferSelector
  );
  const quantities = useSelector(quantitiesSelector);
  const currentWarehouse = useSelector(currentWarehouseSelector);

  // States
  const [transferType, setTransferType] = useState({
    value: 'ArrivalTransfer',
    label: 'Add Inventory',
  });
  const [quantity, setQuantity] = useState('');
  const [limitValue, setLimitValue] = useState('');
  const [productId, setProductId] = useState(productIDParam);
  const [productTitle, setProductTitle] = useState('');
  const [productType, setProductType] = useState('');
  const [transferItems, setTransferItems] = useState([]);
  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [warehouse, setWarehouse] = useState(null);
  const [writeOffReason, setWriteOffReason] = useState({});
  const [exciseReason, setExciseReason] = useState({});
  const [hasWineProduct, setHasWineProduct] = useState(false);

  // Actions
  const resetQuantities = useAction(transfersActions.resetQuantities);
  const resetInventories = useAction(inventoriesActions.resetInventories);

  // Handlers
  const isEmptyTransfer = useMemo(() => {
    if (transferType?.value === 'ArrivalTransfer') {
      return transferItems.length === 0;
    }
    if (transferType?.value === 'AdjustmentTransfer') {
      return (
        transferItems.length === 0 ||
        transferItems.some(i => i.product_id === null)
      );
    }
    if (
      (transferType?.value === 'WriteOffTransfer' ||
        transferType?.value === 'TasterWriteOffTransfer') &&
      !writeOffReason?.value
    ) {
      return true;
    }
    return (
      Object.entries(quantities).filter(arr => !arr.includes(0)).length === 0
    );
  }, [transferType, transferItems, quantities, writeOffReason]);

  const isDisabledButton = isError || disabled || isEmptyTransfer;

  useComponentWillUnmount(resetQuantities);

  const handleSubmit = useCallback(
    (event, values) => {
      if (!validateSubmit()) {
        showErrorMessage('Error!', 'Please fill in all required fields');
        return;
      }

      if (transferType?.value === 'AdjustmentTransfer') {
        onSubmit({
          parent_transfer_id: selectedTransfer?.id,
          resourcetype: selectedTransfer?.resourcetype,
          ...values,
          transfer_items: transferItems?.map(item => ({
            product_id: item.product_id || productIDParam,
            quantity: Number(item.new_quantity - item.current_quantity),
          })),
        });
        return;
      }
      if (
        (productIDParam && !validateSubmit()) ||
        (warehouseIDParam && !validateSubmit())
      ) {
        showErrorMessage('Error!', 'Choose product, and quantity first');
        return;
      }
      onSubmit({
        id: transfer?.id,
        write_off_reason: writeOffReason.value,
        excise_reason: exciseReason.value,
        ...values,
        resourcetype: transferType?.value,
        transfer_items:
          transferType?.value === 'ArrivalTransfer'
            ? transferItems
            : getTransferItemsFromQuantities(quantities),
      });
    },
    [onSubmit, selectedTransfer, transferType, transferItems, quantities]
  );
  const handleTypeChange = useCallback(
    data => {
      resetQuantities();
      resetInventories();
      setReceiverID(null);
      setSenderID(null);
      setTransferType(data);
      setTransferItems([]);

      if (data.value === 'TasterWriteOffTransfer') {
        setWriteOffReason(tasterWriteOffReasonTypes[0]);
      } else {
        setWriteOffReason({});
      }

      if (warehouse?.is_used_as_excise_warehouse) {
        if (data.value === 'ArrivalTransfer') {
          setExciseReason(ADDITIONS_EXCISE_REASON_OPTIONS[0]);
        } else {
          setExciseReason(REDUCTIONS_EXCISE_REASON_OPTIONS[0]);
        }
      }
    },
    [resetQuantities, resetInventories, warehouse]
  );

  const validateSubmit = () => {
    switch (transferType.value) {
      case 'ArrivalTransfer':
        return (
          transferItems.some(
            item => item.quantity > 0 && item.warehouse !== null
          ) &&
          (warehouse?.is_used_as_excise_warehouse
            ? !isEmpty(exciseReason)
            : true)
        );
      case 'WriteOffTransfer':
        return warehouse?.is_used_as_excise_warehouse
          ? !isEmpty(exciseReason)
          : true;
      case 'TasterWriteOffTransfer':
        return (
          !isEmpty(writeOffReason) &&
          transferItems.some(
            item => item.quantity > 0 && item.warehouse !== null
          )
        );
      case 'AdjustmentTransfer':
        return transferItems.some(
          item => item.quantity > 0 && item.warehouse !== null
        );
      case 'WarehouseToWarehouseTransfer':
        return (
          !transferItems.some(item => item.quantity > 0) &&
          (warehouse?.is_used_as_excise_warehouse
            ? isEmpty(exciseReason)
            : true)
        );
      default:
        return true;
    }
  };
  const handleChangeWarehouse = wh => {
    // wh is warehouse
    setWarehouse(wh);
    setReceiverID(wh.value);

    if (wh.is_used_as_excise_warehouse) {
      if (transferType?.value === 'ArrivalTransfer') {
        setExciseReason(ADDITIONS_EXCISE_REASON_OPTIONS[0]);
      } else {
        setExciseReason(REDUCTIONS_EXCISE_REASON_OPTIONS[0]);
      }
    } else if (
      !wh.is_used_as_excise_warehouse &&
      transferType?.value === 'ArrivalTransfer'
    ) {
      setExciseReason(WINE_SOURCE_TYPES[0]);
    } else {
      setExciseReason({});
    }
  };

  const getExciseReasons = transfer_type => {
    if (transfer_type === 'ArrivalTransfer') {
      return ADDITIONS_EXCISE_REASON_OPTIONS;
    }
    return REDUCTIONS_EXCISE_REASON_OPTIONS;
  };

  // Effects
  useEffect(() => {
    if (transferItems.some(item => item.product_type === 'Wine')) {
      setHasWineProduct(true);
    } else {
      setHasWineProduct(false);
    }
  }, [transferItems]);

  useEffect(() => {
    if (warehouse?.is_used_as_excise_warehouse) {
      if (transferType?.value === 'ArrivalTransfer') {
        setExciseReason(ADDITIONS_EXCISE_REASON_OPTIONS[0]);
      } else {
        setExciseReason(REDUCTIONS_EXCISE_REASON_OPTIONS[0]);
      }
    } else if (
      !warehouse?.is_used_as_excise_warehouse &&
      transferType?.value === 'ArrivalTransfer'
    ) {
      setExciseReason(WINE_SOURCE_TYPES[0]);
    } else {
      setExciseReason({});
    }
  }, [warehouse, transferType, hasWineProduct]);

  return (
    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
      <Row>
        {!productIDParam && !warehouseIDParam && (
          <Col md="3">
            <SelectField
              className="mb-2"
              defaultValue={transferType}
              onChange={handleTypeChange}
              label="Type of Product Movement"
              name="resourcetype"
              options={TRANSFER_TYPES.slice(0, 3)}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        )}
        {transferType?.value === 'ArrivalTransfer' && (
          <>
            <Col md="3">
              <FormGroup>
                <Label required htmlFor="receiver_id">
                  Receiving Warehouse
                </Label>
                {warehouseIDParam && currentWarehouse ? (
                  <AsyncPaginate
                    name="warehouses"
                    defaultValue={warehouseIDParam}
                    value={currentWarehouse}
                    isDisabled
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    menuPlacement="auto"
                    styles={customStyles}
                    additional={{ page: 1 }}
                  />
                ) : (
                  <WarehousesPaginatedField
                    menuPlacement="bottom"
                    onChange={handleChangeWarehouse}
                    warehouseIDParam={warehouseIDParam}
                    setWarehouse
                  />
                )}
              </FormGroup>
            </Col>
          </>
        )}
      </Row>

      {transferType?.value === 'WriteOffTransfer' && (
        <Row>
          <Col md={3}>
            <SelectField
              className="mb-2"
              name="write_off_reason"
              defaultValue={writeOffReason}
              onChange={setWriteOffReason}
              label="Reason*"
              options={writeOffReasonTypes}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        </Row>
      )}
      {transferType?.value === 'ArrivalTransfer' &&
        warehouse &&
        !warehouse.is_used_as_excise_warehouse &&
        hasWineProduct && (
          <Row>
            <Col md="3">
              <SelectField
                className="mb-2"
                name="excise_reason"
                defaultValue={exciseReason}
                onChange={setExciseReason}
                exciseReason={exciseReason}
                label="Source*"
                options={WINE_SOURCE_TYPES}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
              />
            </Col>
          </Row>
        )}
      {warehouse?.is_used_as_excise_warehouse && (
        <Row>
          <Col md="3">
            <SelectField
              className="mb-2"
              name="excise_reason"
              defaultValue={exciseReason}
              onChange={setExciseReason}
              label="Excise Reason*"
              options={getExciseReasons(transferType?.value)}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        </Row>
      )}
      {transferType?.value === 'TasterWriteOffTransfer' && (
        <Row>
          <Col md={3}>
            <SelectField
              className="mb-2"
              name="write_off_reason"
              defaultValue={writeOffReason}
              onChange={setWriteOffReason}
              label="Reason*"
              options={tasterWriteOffReasonTypes}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg="7">
          <TextareaField name="comment" label="Comment" />
        </Col>
      </Row>
      {transferType?.value === 'ArrivalTransfer' && (
        <ArrivalFields
          isPending={disabled}
          setReceiverID={setReceiverID}
          quantity={quantity}
          limitValue={limitValue}
          productTitle={productTitle}
          productType={productType}
          setQuantity={setQuantity}
          setLimitValue={setLimitValue}
          productId={productId}
          setProductId={setProductId}
          setProductTitle={setProductTitle}
          setProductType={setProductType}
          transferItems={transferItems}
          setTransferItems={setTransferItems}
          productIDParam={productIDParam}
          warehouseIDParam={warehouseIDParam}
          setWarehouse={setWarehouse}
          warehouse={warehouse}
          currentWarehouse={currentWarehouse}
        />
      )}
      {transferType?.value === 'WriteOffTransfer' && (
        <WriteOffFields
          isPending={disabled}
          senderID={senderID}
          setSenderID={setSenderID}
          setWarehouse={setWarehouse}
          resetQuantities={resetQuantities}
        />
      )}

      {transferType?.value === 'TasterWriteOffTransfer' && (
        <WriteOffFields
          isPending={disabled}
          senderID={senderID}
          setSenderID={setSenderID}
          resetQuantities={resetQuantities}
        />
      )}
      {transferType?.value === 'AdjustmentTransfer' && (
        <InventoryAdjustmentFields
          isPending={disabled}
          transfer={selectedTransfer}
          setTransfer={setSelectedTransfer}
          setReceiverID={setReceiverID}
          setSenderID={setSenderID}
          setTransferItems={setTransferItems}
        />
      )}
      {transferType?.value === 'WarehouseToWarehouseTransfer' && (
        <WarehouseToWarehouseFields
          isPending={disabled}
          receiverID={receiverID}
          setReceiverID={setReceiverID}
          senderID={senderID}
          setSenderID={setSenderID}
          resetQuantities={resetQuantities}
          setExciseReason={setExciseReason}
        />
      )}
      <Row>
        <Col md="12">
          {productIDParam || warehouseIDParam ? (
            <Button color="primary" type="submit" onClick={() => handleSubmit}>
              Add Inventory
            </Button>
          ) : (
            <Button color="primary" type="submit" disabled={isDisabledButton}>
              {submitTitle}
            </Button>
          )}
        </Col>
      </Row>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  receiverID: PropTypes.any,
  setReceiverID: PropTypes.func,
  senderID: PropTypes.any,
  setSenderID: PropTypes.func,
  productIDParam: PropTypes.string,
  warehouseIDParam: PropTypes.string,
};

export default Form;
