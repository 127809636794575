import { createSelector } from 'reselect';

export const rootSelector = state => state.terminals;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const terminalsSelector = createSelector(
  rootSelector,
  ({ terminals }) => terminals
);

export const terminalsListSelector = createSelector(
  terminalsSelector,
  terminals => Object.values(terminals)
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const terminalSelector = createSelector(
  rootSelector,
  ({ terminal }) => terminal
);
