import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';
import { useHistory } from 'react-router-dom';

const DropdownItemId = Object.freeze({
  EDIT: 'edit',
  CREATE: 'create_order',
});

const OrganizationActionDropdown = ({ customer }) => {
  const router = useHistory();

  const onItemClickHandler = item => {
    if (item.id === DropdownItemId.CREATE) {
      router.push('/commercial-orders/create', {
        customer,
      });
    }
  };

  const dropdownItem = [
    {
      id: DropdownItemId.CREATE,
      icon: <i className="ri-add-line align-middle font-size-18 mr-1" />,
      label: 'Create Order',
    },
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/commercial-customers/${customer.id}/edit`,
    },
  ];

  return (
    <ActionDropdown
      onItemClick={onItemClickHandler}
      items={dropdownItem}
      right
    />
  );
};

OrganizationActionDropdown.propTypes = {
  customer: PropTypes.object,
};

export default OrganizationActionDropdown;
