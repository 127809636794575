import React, { memo, useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FieldType } from '../types.d';
import classNames from 'classnames';
import styles from './TextareaField.scss';

const TextareaField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  value,
  errorMessage,
  disabled,
  className,
  maxLength,
  isResizeDisabled,
  ...props
}) => {
  const [text, setText] = useState(value || '');
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setText(value);
    setCharCount(value?.length || 0);
  }, [value]);

  const handleTextChange = event => {
    const newValue = event.target.value;
    setText(newValue);
    setCharCount(newValue.length);
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <FormGroup>
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <AvField
        name={name}
        type="textarea"
        className={classNames('form-control', className)}
        validate={{
          required: { value: !!isRequired },
          ...rules,
        }}
        error
        errorMessage={errorMessage}
        value={text}
        onChange={handleTextChange}
        disabled={disabled}
        maxLength={maxLength}
        style={{ resize: isResizeDisabled ? 'none' : 'auto' }}
        rows="5"
        {...props}
      />
      {maxLength && (
        <div className={classNames('text-muted', styles.characterCounter)}>
          {charCount}/{maxLength}
        </div>
      )}
    </FormGroup>
  );
};

TextareaField.propTypes = FieldType;

export default memo(TextareaField);
