import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as exciseActions } from 'models/excise/slice';
import {
  isPendingSelector,
  exciseLicenseSelector,
} from 'models/excise/selectors';

import Main from './Main';
import ExciseReturnsSummary from './Returns/ExciseReturnsSummary';

const ExciseLicenseShow = ({ title }) => {
  const { id } = useParams();

  const exciseLicense = useSelector(exciseLicenseSelector);
  const fetchExciseLicense = useAction(exciseActions.fetchExciseLicense);
  const isPending = useSelector(isPendingSelector);

  useEffect(() => {
    fetchExciseLicense(id);
  }, [fetchExciseLicense, id]);

  if (!exciseLicense && isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/reports/excise/license/${exciseLicense?.id}/edit`}
        title={`Edit ${exciseLicense?.business_name} license ${exciseLicense?.account_number}`}
        breadcrumbItems={[
          { title: 'Back to excise licenses', link: '/reports/excise' },
        ]}
      />
      <Row>
        <Col md="12">
          <Main exciseLicense={exciseLicense} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Returns</h4>
              <div className="mt-4">
                <Link
                  to={`/reports/excise/license/${exciseLicense?.id}/return/create`}
                >
                  <Button
                    color="success"
                    type="button"
                    className="waves-effect waves-light mr-1 text-nowrap"
                  >
                    <i className="ri-add-line align-middle mr-2" /> Create
                  </Button>
                </Link>
              </div>
              <div className="mt-4">
                <ExciseReturnsSummary exciseLicense={exciseLicense} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ExciseLicenseShow.propTypes = {
  title: PropTypes.string,
};

export default ExciseLicenseShow;
