import { takeLatest, all, put } from 'redux-saga/effects';
import * as queryString from 'query-string';

import { partition } from 'lodash';
import api from 'api';

import { actions } from './slice';

export function* fetchBrandsCompaniesSaga(action) {
  const stringified = queryString.stringify(action.payload);
  const url = `/brand_shipping_companies/?${stringified}`;
  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchCompaniesSaga(action) {
  const stringified = queryString.stringify(action.payload);
  const url = `/shipping_companies/?${stringified}`;
  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* saveCompaniesSaga(action) {
  try {
    const [companiesWithoutBrand, companiesWithBrand] = partition(
      action.payload.data,
      c => c.brand_id === null
    );

    yield all([
      ...companiesWithoutBrand.map(c =>
        api({
          action: {
            ...action,
            payload: { ...action.payload, itemId: c.itemId },
          },
          method: 'POST',
          url: `/brand_shipping_companies/attach_to_brand`,
          data: {
            is_active: c.is_active,
            api_key: c.api_key,
            brand_id: action.payload.brandId,
            shipping_company_id: c.shipping_company_id,
          },
        })
      ),
      ...companiesWithBrand.map(c =>
        api({
          action: {
            ...action,
            type: actions.saveUpdateCompaniesSuccess,
            payload: { ...action.payload, itemId: c.itemId },
          },
          method: 'PUT',
          url: `/brand_shipping_companies/${c.entity_id}`,
          data: {
            is_active: c.is_active,
            api_key: c.api_key,
            shipping_company_id: c.shipping_company_id,
            shipping_company_title: c.shipping_company_title,
          },
        })
      ),
    ]);

    yield put({ type: actions.saveCompaniesFinalize });
  } catch (err) {
    console.error(err);
    yield put({ type: actions.saveCompaniesFailure });
  }
}

function* deleteCompanySaga(action) {
  const { id } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/brand_shipping_companies/${id}/detach_from_brand`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.saveCompanies, saveCompaniesSaga),
    takeLatest(actions.fetchBrandsCompanies, fetchBrandsCompaniesSaga),
    takeLatest(actions.fetchCompanies, fetchCompaniesSaga),
    takeLatest(actions.deleteCompany, deleteCompanySaga),
  ]);
}
