import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { COMPANY_TYPES } from 'constants';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import TextField from 'components/Fields/TextField';
import PhoneField from 'components/Fields/PhoneField';
import LocationSearchInput from 'components/LocationSearchInput';
import Preloader from 'components/Preloader';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions as sharedCustomerActions } from 'models/sharedCustomer/slice';
import {
  sharedCustomerSelector,
  isPendingSelector,
} from 'models/sharedCustomer/selectors';
import { getRedactedValues } from 'utils/getRedactedValues';
import { emailSelector } from 'models/user/selectors';

const CustomerProfileSummary = ({ title }) => {
  // Selectors
  const sharedCustomerIsPending = useSelector(isPendingSelector);
  const currentUserEmail = useSelector(emailSelector);
  const model = useSelector(sharedCustomerSelector);

  // Actions
  const fetchSharedCustomer = useAction(
    sharedCustomerActions.fetchSharedCustomer
  );
  const updateSharedCustomer = useAction(
    sharedCustomerActions.updateSharedCustomer
  );

  // States
  const [businessName, setBusinessName] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [companyType, setCompanyType] = useState({ label: '', value: '' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');

  const [isEdited, setIsEdited] = useState(false);

  // Effects
  // Fetch shared customer data
  useEffect(() => {
    if (currentUserEmail) {
      fetchSharedCustomer({ current_email: currentUserEmail });
    }
  }, [fetchSharedCustomer, currentUserEmail]);

  // Update state when the model changes
  useEffect(() => {
    if (model) {
      setBusinessName(model?.title || '');
      setLicenseNumber(model?.license_number || '');
      setCompanyType({
        label: model?.company_type || '',
        value: model?.company_type || '',
      });
      setFirstName(model?.contact_person?.first_name || '');
      setLastName(model?.contact_person?.last_name || '');
      setEmail(model?.contact_person?.email || '');
      setPhone(model?.contact_person?.phone || '');
      setCountry(model?.address?.country || '');
      setState(model?.address?.state || '');
      setPostalCode(model?.address?.postal_code || '');
      setCity(model?.address?.city || '');
      setLine1(model?.address?.line1 || '');
      setLine2(model?.address?.line2 || '');
    }
  }, [model]);

  // Effect to detect form edits
  useEffect(() => {
    const isFormEdited =
      businessName !== (model?.title || '') ||
      licenseNumber !== (model?.license_number || '') ||
      companyType?.value !== (model?.company_type || '') ||
      firstName !== (model?.contact_person?.first_name || '') ||
      lastName !== (model?.contact_person?.last_name || '') ||
      email !== (model?.contact_person?.email || '') ||
      phone !== (model?.contact_person?.phone || '') ||
      country !== (model?.address?.country || '') ||
      state !== (model?.address?.state || '') ||
      city !== (model?.address?.city || '') ||
      line1 !== (model?.address?.line1 || '') ||
      line2 !== (model?.address?.line2 || '') ||
      postalCode !== (model?.address?.postal_code || '');

    setIsEdited(isFormEdited);
  }, [
    businessName,
    licenseNumber,
    companyType,
    firstName,
    lastName,
    email,
    phone,
    country,
    state,
    city,
    line1,
    line2,
    postalCode,
  ]);

  // Handler
  const handleSubmit = (event, values) => {
    const sharedCustomer = {
      ...model,
      contact_person_first_name: model?.contact_person?.first_name,
      contact_person_last_name: model?.contact_person?.last_name,
      contact_person_email: model?.contact_person?.email,
      contact_person_phone: model?.contact_person?.phone,
    };
    if (values.title) {
      // Handle form submission logic here
      const submittedValues = {
        ...values,
        city,
        country,
        line1,
        line2,
        postal_code: postalCode,
        state,
        company_type: companyType?.value,
        license_number: licenseNumber,
        contact_person_first_name: firstName,
        contact_person_last_name: lastName,
        contact_person_email: email,
        contact_person_phone: phone,
        current_email: currentUserEmail,
        title: businessName,
      };

      const redactedValues = getRedactedValues(sharedCustomer, submittedValues);
      updateSharedCustomer({
        id: model?.id,
        ...redactedValues,
      });
    }
  };

  if (sharedCustomerIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <AvForm onValidSubmit={handleSubmit}>
        <Row>
          <Col md={4}>
            <TextField
              label="Business Name"
              name="title"
              placeholder="Business Name"
              isRequired
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <TextField
              placeholder="License Number"
              isRequired
              label="License Number"
              name="license_number"
              value={licenseNumber}
              onChange={e => setLicenseNumber(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="company_type">Company Type</Label>
              <Select
                name="company_type"
                options={COMPANY_TYPES.map(type => ({
                  label: type,
                  value: type,
                }))}
                placeholder="Select Type"
                value={companyType}
                onChange={selectedOption => setCompanyType(selectedOption)}
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <LocationSearchInput
              model={model}
              country={country}
              state={state}
              postalCode={postalCode}
              city={city}
              line1={line1}
              line2={line2}
              setCountry={setCountry}
              setState={setState}
              setPostalCode={setPostalCode}
              setCity={setCity}
              setLine1={setLine1}
              setLine2={setLine2}
              isRequired
            />
          </Col>
        </Row>
        <Row mt={2}>
          <Col md={12}>
            <div className="font-size-18 mb-2 font-weight-bold">
              Contact Person
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <TextField
              isRequired
              name="first_name"
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <TextField
              isRequired
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <TextField
              isRequired
              name="email"
              label="Email"
              placeholder="Email"
              validate={{
                required: { value: true, errorMessage: 'Enter Email' },
                email: { value: true, errorMessage: 'Invalid Email' },
              }}
              value={email}
              onChange={e => setEmail(e.target.value.toLowerCase())}
            />
          </Col>
          <Col md={3}>
            <PhoneField
              name="phone"
              label="Phone Number"
              placeholder="Phone"
              value={phone}
              onChange={value => setPhone(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary" type="submit" disabled={!isEdited}>
              Save
            </Button>
          </Col>
        </Row>
      </AvForm>
    </PageWrapper>
  );
};

CustomerProfileSummary.propTypes = {
  title: PropTypes.string,
};

export default CustomerProfileSummary;
