import React, { useEffect, useState } from 'react';
import styles from './ProductCategoryForm.scss';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import useOnclickOutside from 'react-cool-onclickoutside';
import { ChromePicker } from 'react-color';
import TextField from '../Fields/TextField';
import CheckboxField from '../Fields/CheckboxField';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';

const getDefaultOption = (label, value) => ({ label, value });

const getWarehouseDefaultOption = (label, id) => {
  if (label === null && id === null) {
    return undefined;
  }
  return { label, id };
};

const ProductCategoryForm = ({ submitTitle, onSubmit, model }) => {
  const [selectedBrand, setSelectedBrand] = useState(
    model ? getDefaultOption(model?.brand_title, model?.brand_id) : undefined
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    model
      ? getWarehouseDefaultOption(model?.warehouse_title, model?.warehouse_id)
      : undefined
  );
  const [colour, setColour] = useState(model?.colour || '#EEB6C7');
  const [pickerOpen, setPickerOpen] = useState(false);
  const pickerRef = useOnclickOutside(() => setPickerOpen(false));

  const handleSubmit = (event, values) => {
    onSubmit({
      ...values,
      colour,
      brand_id: selectedBrand?.value,
      warehouse_id: selectedWarehouse?.id || '',
    });
  };

  useEffect(() => {
    if (model) {
      setSelectedBrand(getDefaultOption(model?.brand_title, model?.brand_id));
      setSelectedWarehouse(
        getWarehouseDefaultOption(model?.warehouse_title, model?.warehouse_id)
      );
      setColour(model?.colour);
    }
  }, [model]);

  return (
    <>
      <AvForm
        model={model}
        className="needs-validation"
        onValidSubmit={handleSubmit}
      >
        <Row>
          <Col md={3}>
            <TextField
              label="Title"
              name="title"
              placeholder="Title"
              errorMessage="Enter Title"
              isRequired
            />
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label required htmlFor="brand">
                Brand
              </Label>
              <BrandsPaginatedField
                isMulti={false}
                onChange={setSelectedBrand}
                value={selectedBrand}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="warehouse">Warehouse</Label>
              <WarehousesPaginatedField
                isMulti={false}
                parameters={{ brand_id: selectedBrand?.value }}
                value={selectedWarehouse}
                onChange={setSelectedWarehouse}
                name="warehouse"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className={styles.colorWrapper}>
              {pickerOpen && (
                <div ref={pickerRef} className={styles.colorPicker}>
                  <ChromePicker
                    color={colour}
                    onChange={({ hex }) => setColour(hex)}
                  />
                </div>
              )}
              <Label>Color</Label>
              <div className={styles.colorCardWrapper}>
                <button
                  style={{
                    backgroundColor: colour,
                  }}
                  onClick={() => setPickerOpen(true)}
                  type="button"
                  className={styles.colorCard}
                />
                <p className={styles.color}>{colour}</p>
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <CheckboxField
              label="Is shown on main page"
              name="is_shown_on_main_page"
              checked={model?.is_shown_on_main_page}
            />
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={!selectedBrand}>
          {submitTitle}
        </Button>
      </AvForm>
    </>
  );
};

ProductCategoryForm.propTypes = {
  submitTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  model: PropTypes.object,
};
export default ProductCategoryForm;
