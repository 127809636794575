import { takeLatest, all, select } from 'redux-saga/effects';

import api from 'api';
import { currentWorkspaceSelector } from 'models/workspaces/selectors';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

function* fetchAwardsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/awards/',
  });
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchAwardSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/awards/${action.payload}`,
  });
}

function* createAwardSaga(action) {
  const formData = new FormData();
  const currentWorkspace = yield select(currentWorkspaceSelector);

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });

  formData.append('workspace_id', currentWorkspace);

  yield api({
    action,
    method: 'post',
    url: `/awards/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully created new award!',
    successNavigateTo: `/awards/`,
  });
}

function* updateAwardSaga(action) {
  const formData = new FormData();
  const currentWorkspace = yield select(currentWorkspaceSelector);

  Object.keys(action.payload).forEach(key => {
    formData.append(
      key,
      action.payload[key] === null ? '' : action.payload[key]
    );
  });

  formData.append('workspace_id', currentWorkspace);

  yield api({
    action,
    method: 'PATCH',
    url: `/awards/${action.payload.id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully updated award!',
    successNavigateTo: `/awards/`,
  });
}

function* deleteAwardSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/awards/${action.payload}`,
    successMessage: 'Successfully deleted award!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchAwards, fetchAwardsSaga),
    takeLatest(actions.fetchAward, fetchAwardSaga),
    takeLatest(actions.createAward, createAwardSaga),
    takeLatest(actions.deleteAward, deleteAwardSaga),
    takeLatest(actions.updateAward, updateAwardSaga),
  ]);
}
