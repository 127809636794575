import { useState, useMemo } from 'react';

export default (address, isPhone) => {
  const [addressData, setAddressData] = useState(
    address || {
      phone: '',
      country: null,
      state: null,
      postal_code: null,
      city: null,
      line1: null,
      line2: null,
    }
  );

  const isInvalidAddress = useMemo(() => {
    const { phone, city, state, country, line1, postal_code } = addressData;

    if (isPhone) {
      return !city || !country || !line1 || !postal_code || !state;
    }

    return !phone || !city || !country || !line1 || !postal_code || !state;
  }, [addressData]);

  return {
    isInvalidAddress,
    addressData,
    setAddressData,
  };
};
