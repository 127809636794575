export const TRANSFER_STATUSES = Object.freeze({
  COMPLETED: 'COMPLETED',
  PENDING_RECEIPT: 'PENDING_RECEIPT',
  DISPUTED: 'DISPUTED',
});

export const COMMERCIAL_ORDERS_EXCISE_REASON_OPTIONS = [
  {
    label: 'Removed for delivery to accredited representatives',
    value: 'REMOVED_FOR_DELIVERY_TO_ACCREDITED_REPRESENTATIVES',
  },
  {
    label: 'Removed for delivery to duty free shops',
    value: 'REMOVED_FOR_DELIVERY_TO_DUTY_FREE_SHOPS',
  },
  {
    label: 'Removed for delivery to ships stores',
    value: 'REMOVED_FOR_DELIVERY_TO_SHIPS_STORES',
  },
  {
    label: 'Removed for delivery to licensed users',
    value: 'REMOVED_FOR_DELIVERY_TO_LICENSED_USERS',
  },
  {
    label: 'Exported Wine',
    value: 'EXPORTED_WINE',
  },
];

export const ADDITIONS_EXCISE_REASON_OPTIONS = [
  {
    label: 'Domestic Wine',
    value: 'DOMESTIC_WINE',
  },
  {
    label: 'Imported Wine',
    value: 'IMPORTED_WINE',
  },
  {
    label: 'Returned from Duty Paid Sources',
    value: 'RETURNED_FROM_DUTY_PAID_SOURCES',
  },
  {
    label: 'Returned from Duty Free Sources',
    value: 'RETURNED_FROM_NON_DUTY_PAID_SOURCES',
  },
];

export const REDUCTIONS_EXCISE_REASON_OPTIONS = [
  {
    label: 'Removed for delivery to accredited representatives',
    value: 'REMOVED_FOR_DELIVERY_TO_ACCREDITED_REPRESENTATIVES',
  },
  {
    label: 'Removed for delivery to duty free shops',
    value: 'REMOVED_FOR_DELIVERY_TO_DUTY_FREE_SHOPS',
  },
  {
    label: 'Removed for delivery to ships stores',
    value: 'REMOVED_FOR_DELIVERY_TO_SHIPS_STORES',
  },
  {
    label: 'Removed for delivery to licensed users',
    value: 'REMOVED_FOR_DELIVERY_TO_LICENSED_USERS',
  },
  {
    label: 'Removed for delivery to other excise warehouses',
    value: 'REMOVED_FOR_DELIVERY_TO_OTHER_EXCISE_WAREHOUSES',
  },
  {
    label: 'Exported Wine',
    value: 'EXPORTED_WINE',
  },
  {
    label: 'Return to Wine Licensee',
    value: 'RETURN_TO_WINE_LICENSEE',
  },
  {
    label: 'Breakage',
    value: 'BREAKAGE',
  },
  {
    label: 'Wine removed for other purposes non duty',
    value: 'WINE_REMOVED_FOR_OTHER_PURPOSES_NON_DUTY',
  },
  {
    label: 'Packaged excluding marked special containers',
    value: 'PACKAGED_EXCLUDING_MARKED_SPECIAL_CONTAINERS',
  },
  {
    label: 'Packaged in marked special containers',
    value: 'PACKAGED_IN_MARKED_SPECIAL_CONTAINERS',
  },
  {
    label: 'Wine removed for other purposes duty',
    value: 'WINE_REMOVED_FOR_OTHER_PURPOSES_DUTY',
  },
];

export const W2W_REDUCTIONS_EXCISE_REASON_OPTIONS = [
  {
    label: 'Removed for delivery to other excise warehouses',
    value: 'REMOVED_FOR_DELIVERY_TO_OTHER_EXCISE_WAREHOUSES',
  },
];
