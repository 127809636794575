import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { isEmpty } from 'lodash';

const SelectField = ({
  name,
  options,
  label,
  onChange,
  defaultValue,
  error,
  multiple,
  isRequired,
  value,
  isTouched,
  setIsTouched,
  disabled,
  ...props
}) => {
  const [isError, setIsError] = useState(false);

  const validateSelect = () => {
    if (isRequired && isEmpty(value)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const onChangeHandler = data => {
    onChange(data);
    setIsError(false);
  };

  useEffect(() => {
    if (isTouched) {
      validateSelect();
      setIsTouched(false);
    }
  }, [isTouched, value]);

  return (
    <div className="w-100 m-0">
      <Label className={isError && 'text-danger'}>
        {isRequired ? `${label}*` : label}
      </Label>
      <Select
        name={name}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        isSearchable={false}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.title}
        isMulti={multiple}
        onBlur={validateSelect}
        isDisabled={disabled}
        {...props}
      />
      {isRequired && isError && (
        <small className="text-danger mt-2">This field is required</small>
      )}
      {error && <small className="text-danger mt-2">{error}</small>}
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.object,
  multiple: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.any,
  isTouched: PropTypes.bool,
  setIsTouched: PropTypes.func,
  disabled: PropTypes.bool,
};
export default SelectField;
