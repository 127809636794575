import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import CardItem from 'components/CardItem';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentWillUnmount from 'hooks/useComponentWillUnmount';
import { MONTHS } from 'constants';

import * as ordersSelectors from 'models/orders/selectors';
import { actions as ordersActions } from 'models/orders/slice';
import getAmountOfProductsInOrder from 'utils/getAmountOfProductsInOrder';

import formatPrice from 'utils/formatPrice';
import LocationSearchInput from 'components/LocationSearchInput';

import OrderItemsTable from './OrderItemsTable';
import { getRedactedValues } from 'utils/getRedactedValues';
import { actions as productsActions } from 'models/products/slice';
import TextField from '../Fields/TextField';
import PhoneField from '../Fields/PhoneField';
import SelectField from '../SelectField/SelectField';

const getOrderItemsFromOrder = productsInOrder => {
  return Object.entries(productsInOrder)
    .filter(arr => arr[1].number !== 0)
    .map(([key, value]) => {
      if (value.isNewOrderItem) {
        return { product_id: value.product_id, number: value.number };
      }
      return {
        id: Number(key),
        product_id: value.product_id,
        number: value.number,
      };
    });
};

const Form = ({ submitTitle, order, disabled, onSubmit }) => {
  const productsInOrder = useSelector(ordersSelectors.productsInOrderSelector);
  const resetProductsInOrder = useAction(ordersActions.resetProductsInOrder);
  const setProductsInOrder = useAction(ordersActions.setProductsInOrder);
  const deleteProductsInOrder = useAction(ordersActions.deleteProductsInOrder);
  const {
    country: customerCountry,
    state: customerState,
    city: customerCity,
    line1: customerLine1,
    line2: customerLine2,
    postal_code: customerPostalCode,
  } = order?.shipping_info;

  const [country, setCountry] = useState(customerCountry);
  const [state, setState] = useState(customerState);
  const [postalCode, setPostalCode] = useState(customerPostalCode);
  const [city, setCity] = useState(customerCity);
  const [line1, setLine1] = useState(customerLine1);
  const [line2, setLine2] = useState(customerLine2);

  const fetchProductsCount = useAction(productsActions.fetchProductsCount);
  const productsInOrderIds = useMemo(
    () => order?.order_items.map(({ product }) => product?.id),
    [order]
  );

  useEffect(() => {
    fetchProductsCount({
      warehouseId: order?.warehouse?.id,
      productIds: productsInOrderIds,
      isSubscription: true,
    });
  }, [order, productsInOrderIds]);

  const [month, setMonth] = useState(MONTHS[0]);

  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        id: order?.id,
        ...getRedactedValues(order, {
          ...values,
          execution_month: month?.value,
          country,
          state,
          city,
          postal_code: postalCode,
          line1,
          line2,
          order_items: getOrderItemsFromOrder(productsInOrder),
        }),
      });
    },
    [onSubmit, country, state, city, postalCode, line1, line2, productsInOrder]
  );

  useComponentWillUnmount(resetProductsInOrder);

  return (
    <AvForm
      model={order}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="6">
          <Label>Customer Info</Label>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="3">
          <CardItem
            title="Name"
            value={`${order?.customer?.first_name} ${order?.customer?.last_name}`}
          />
        </Col>
        <Col md="3">
          <CardItem title="Email" value={order?.customer?.email} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <LocationSearchInput
            isRequired
            model={order?.shipping_info}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
          />
        </Col>
        <Col md={4}>
          <PhoneField
            name="phone"
            label="Phone"
            placeholder="Phone"
            isRequired
            value={order?.shipping_info?.phone || order?.customer?.phone}
          />
        </Col>
      </Row>
      {!order?.obligation?.tier?.period_in_months === 'Monthly' && (
        <Row>
          <Col md="4">
            <TextField
              label="Execution Year"
              placeholder="Execution Year"
              name="execution_year"
              rules={{ min: { value: 2020 }, max: { value: 2100 } }}
            />
          </Col>
          <Col md="4">
            <SelectField
              label="Execution Month"
              name="execution_month"
              options={MONTHS}
              defaultValue={month}
              onChange={setMonth}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col md="12">
          <OrderItemsTable
            warehouseId={order?.warehouse?.id}
            orderItems={order?.order_items}
            tierCapacity={order?.tier_capacity}
            setProductsInOrder={setProductsInOrder}
            deleteProductsInOrder={deleteProductsInOrder}
            productsInOrder={productsInOrder}
          />
        </Col>
      </Row>
      <Row>
        {order &&
          Object.keys(order.applied_fees).map(fee => (
            <Col md="3">
              <CardItem
                title={fee}
                value={`${formatPrice(order.applied_fees[fee])} CAD`}
              />
            </Col>
          ))}
        {Boolean(order?.percentage_discount) && (
          <Col md="3">
            <CardItem
              title="Percentage Discount"
              value={`${order?.percentage_discount} %`}
            />
          </Col>
        )}
        {Boolean(order?.shipment_price) && (
          <Col md="3">
            <CardItem
              title="Shipment Price"
              value={`${order?.shipment_price} CAD`}
            />
          </Col>
        )}
        {Boolean(order?.fee) && (
          <Col md="3">
            <CardItem
              title="Total tax"
              value={`${formatPrice(order?.fee)} CAD`}
            />
          </Col>
        )}
        {Boolean(order?.fee) && (
          <Col md="3">
            <CardItem
              title="Total price"
              value={`${formatPrice(order?.total_price)} CAD`}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md="12">
          <Button
            color="primary"
            type="submit"
            disabled={
              disabled ||
              getAmountOfProductsInOrder(productsInOrder) < order?.tier_capacity
            }
          >
            {submitTitle}
          </Button>
        </Col>
      </Row>
    </AvForm>
  );
};

Form.propTypes = {
  order: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
};

export default Form;
