import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Button, Row } from 'reactstrap';
import Select from 'react-select';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import DateRangePicker from 'components/DateRangePicker';
import classNames from 'classnames';
import styles from './Filter.scss';

import { PAID_OPTIONS } from 'constants';
import {
  ALL_ORDER_STATUSES,
  ALL_ORDER_TYPES,
  PER_PAGE_OPTIONS,
  ACTIONS_OPTIONS,
} from '../constants';
import { getInitialOption } from 'utils/getInitialOption';
import { getProductsQuery } from 'utils/getProductsQuery';
import useQuery from 'hooks/useQuery';
import { formatDate } from 'utils/formatDate';
import { getEndDate, getStartDate } from 'utils/date';
import QueryLink from 'components/QueryLink';
import SearchField from 'components/SearchField';

const Filter = ({
  isPending,
  filter,
  setFilter,
  setSortField,
  onPrintClick,
  action,
  setAction,
  withActions,
}) => {
  const query = useQuery();

  const [paid, setPaid] = useState(
    getInitialOption(query, 'paid', PAID_OPTIONS)
  );
  const [statuses, setStatuses] = useState(
    getProductsQuery(query.get('statuses'), query.get('statuses_labels'))
  );

  const [resourcetypes, setResourcetypes] = useState(
    query.get('resourcetypes')?.split(',').length === ALL_ORDER_TYPES.length
      ? []
      : getProductsQuery(
          query.get('resourcetypes'),
          query.get('resourcetypes_labels')
        )
  );
  const [perPage, setPerPage] = useState(
    getInitialOption(query, 'per_page', PER_PAGE_OPTIONS)
  );
  // const [brands, setBrands] = useState(getQuery(query, 'brand', 'All brands'));
  const [brands, setBrands] = useState(
    getProductsQuery(query.get('brands_ids'), query.get('brands_labels'))
  );
  const [date, setDate] = useState({});
  const [search, setSearch] = useState(
    query.get('customer_email_contains') || ''
  );
  const [selectedProducts, setSelectedProducts] = useState(
    getProductsQuery(query.get('product_ids'), query.get('product_labels'))
  );

  useEffect(() => {
    setFilter({
      ...filter,
      statuses: statuses?.map(item => item.value),
      statuses_labels: statuses?.map(item => item.label),
      brands_ids: brands?.map(item => item.value),
      brands_labels: brands?.map(item => item.label),
      paid: paid?.value,
      per_page: perPage?.value,
      resourcetypes: resourcetypes?.map(item => item.value),
      resourcetypes_labels: resourcetypes?.map(item => item.label),
      date_range_start: date?.startDate,
      date_range_end: date?.endDate,
      order_contains: search,
      product_ids: selectedProducts?.map(item => item.value),
      product_labels: selectedProducts?.map(item => item.label),
    });
  }, [
    paid,
    brands,
    statuses,
    resourcetypes,
    perPage,
    date,
    search,
    selectedProducts,
  ]);

  const handleChangePaid = data => {
    setPaid(data);
    setStatuses([]);
    setSortField(String(data?.value) === 'true' ? 'paid_at' : 'updated_at');
  };

  const handleDateChange = value => {
    setDate({
      startDate: formatDate(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: formatDate(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  return (
    <Row className="mb-4">
      <Col md={12} className="mb-2 d-flex">
        <div className={classNames(styles.normal, 'mr-1')}>
          <Select
            value={paid}
            onChange={handleChangePaid}
            options={PAID_OPTIONS}
            placeholder="Select..."
            isSearchable={false}
          />
        </div>
        <div className={classNames(styles.big, 'mr-1')}>
          <BrandsPaginatedField
            name="brands_ids"
            value={brands}
            onChange={setBrands}
            placeholder="Select brand..."
            isMulti
            isInitialLabel
          />
        </div>
        {paid.label === 'Paid' && (
          <div className={classNames(styles.big, 'mr-1')}>
            <Select
              value={statuses}
              onChange={setStatuses}
              options={ALL_ORDER_STATUSES}
              placeholder="Select status..."
              isMulti
              isSearchable={false}
            />
          </div>
        )}
        <div className={classNames(styles.big, 'mr-1')}>
          <Select
            value={resourcetypes}
            onChange={setResourcetypes}
            options={ALL_ORDER_TYPES}
            placeholder="Select type..."
            isMulti
            isSearchable={false}
          />
        </div>
        <div className={classNames(styles.big, 'mr-1')}>
          <ProductsPaginatedField
            id="wine"
            placeholder="Select products..."
            value={selectedProducts}
            onChange={setSelectedProducts}
            isMulti
            withSKU
          />
        </div>
      </Col>
      <Col md={12} className="mb-2 d-flex">
        <div className={classNames(styles.small, 'mr-1')}>
          <Select
            value={perPage}
            onChange={setPerPage}
            options={PER_PAGE_OPTIONS}
            placeholder="Select..."
            isSearchable={false}
          />
        </div>
        <div className="mr-3">
          <DateRangePicker placement="bottom" onDateChange={handleDateChange} />
        </div>
        {withActions && (
          <>
            <div className={classNames(styles.big, 'mr-1')}>
              <Select
                value={action}
                onChange={setAction}
                options={ACTIONS_OPTIONS}
                placeholder="Select..."
                isSearchable={false}
              />
            </div>
            <Button
              color="primary"
              type="button"
              disabled={isPending}
              onClick={onPrintClick}
              className="waves-effect waves-light mr-1 text-nowrap"
            >
              Print
            </Button>
          </>
        )}
      </Col>
      <Col md={12} className="d-flex">
        <SearchField
          className="mr-3"
          value={search}
          setValue={setSearch}
          placeholder="Search... (By invoice id, customer email/name/ID, CW order id)"
        />
        <QueryLink to="/orders/create">
          <Button
            color="success"
            type="button"
            disabled={isPending}
            className="waves-effect waves-light mr-1 text-nowrap"
          >
            <i className="ri-add-line align-middle mr-2" /> Create
          </Button>
        </QueryLink>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  isPending: PropTypes.bool,
  setSortField: PropTypes.func,
  action: PropTypes.object,
  setAction: PropTypes.func,
  withActions: PropTypes.bool,
  onPrintClick: PropTypes.func,
};

export default Filter;
