import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { formatDate } from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';

const getCustomerLabel = organization => {
  const { contact_person, title } = organization;
  const contactPerson = `${contact_person.first_name} ${contact_person.last_name}`;

  return `${title}, ${contactPerson}`;
};

const CommissionsTable = ({ orderList }) => {
  return (
    <Card>
      <CardBody className="pa-0">
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Created at</th>
              <th>Customer</th>
              <th>Total Price</th>
              <th>Commission Rate</th>
              <th>Commission</th>
            </tr>
          </thead>
          <tbody>
            {orderList.map(orderItem => (
              <tr key={orderItem.id}>
                <td>{orderItem.invoice_number}</td>
                <td>{formatDate(orderItem.created_at, 'yyyy-MM-dd')}</td>
                <td>{getCustomerLabel(orderItem.organization)}</td>
                <td>${formatPrice(orderItem?.total_price)}</td>
                <td>
                  {orderItem?.sales_rep_percentage
                    ? `${orderItem?.sales_rep_percentage}%`
                    : '-'}
                </td>
                <td>${formatPrice(orderItem?.commission)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

CommissionsTable.propTypes = {
  orderList: PropTypes.array.isRequired,
};

export default CommissionsTable;
