import { createSelector } from 'reselect';

export const rootSelector = state => state.orderShipmentRates;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const shipmentUpdatedSelector = createSelector(
  rootSelector,
  ({ shipmentUpdated }) => shipmentUpdated
);

export const shippingUpdatePendingSelector = createSelector(
  rootSelector,
  ({ updateShippingPending }) => updateShippingPending
);

export const shipmentRatesSelector = createSelector(
  rootSelector,
  ({ rates }) => rates
);

export const shipmentRatesModalOpenSelector = createSelector(
  rootSelector,
  ({ modalOpen }) => modalOpen
);

export const isCreatingSelector = createSelector(
  rootSelector,
  ({ isCreating }) => isCreating
);

export const createdShipmentSelector = createSelector(
  rootSelector,
  ({ createdShipment }) => createdShipment
);

export const callNextActionSelector = createSelector(
  rootSelector,
  ({ callNextAction }) => callNextAction
);
