export const getFormTabs = (
  handler,
  isOrderInfo,
  isTotal,
  isSalesRep,
  isWarehouseContainerWorld
) => {
  if (isSalesRep || isWarehouseContainerWorld) {
    return {
      details: { id: 1, title: 'Order Details', disabled: false },
      summary: { id: 2, title: 'Summary', disabled: isTotal, handler },
    };
  }
  return {
    details: { id: 1, title: 'Order Details', disabled: false },
    shipping: {
      id: 2,
      title: 'Shipping',
      disabled: isOrderInfo,
      handler,
    },
    summary: { id: 3, title: 'Summary', disabled: isTotal, handler },
  };
};

export const isAnyPaymentMethodAvailable = (brandId, paymentTypes) => {
  return paymentTypes.some(
    type => type.brand_id?.toString() === brandId?.toString() && type.is_default
  );
};
