import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import TerminalForm from 'components/TerminalForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';
import { actions as terminalsActions } from 'models/terminals/slice';

const TerminalCreate = ({ title }) => {
  const createTerminal = useAction(terminalsActions.createTerminal);

  const warehousesPending = useSelector(warehousesIsPendingSelector);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new terminal"
        breadcrumbItems={[
          { title: 'Back to terminals', link: '/terminals/', withSearch: true },
        ]}
      />
      <Row>
        <Col md="12">
          <TerminalForm
            submitTitle="Save"
            disabled={warehousesPending}
            onSubmit={createTerminal}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

TerminalCreate.propTypes = {
  title: PropTypes.string,
};

export default TerminalCreate;
