import { useState } from 'react';

export const useDataPagination = (pagination, initialData) => {
  const [currentPage, setCurrentPage] = useState(
    initialData?.currentPage || pagination?.current_page
  );

  const [sortField, setSortField] = useState(
    initialData?.sortField || 'created_at'
  );
  const [sortDirection, setSortDirection] = useState(
    initialData?.sortDirection || 'desc'
  );

  return {
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  };
};
