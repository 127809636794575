import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchDevicesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/devices/',
    setQuery: action.payload.set_query,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
export function* fetchDeviceSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/devices/${action.payload}`,
  });
}

export function* createDeviceSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/devices/`,
    data: action.payload,
    successMessage: 'Successfully created new device!',
    successNavigateTo: `/devices/`,
  });
}

export function* updateDeviceSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/devices/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated device!',
    successNavigateTo: `/devices/`,
  });
}

export function* deleteDeviceSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/devices/${action.payload}`,
    successMessage: 'Successfully deleted device!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchDevices, fetchDevicesSaga),
    takeLatest(actions.fetchDevice, fetchDeviceSaga),
    takeLatest(actions.createDevice, createDeviceSaga),
    takeLatest(actions.deleteDevice, deleteDeviceSaga),
    takeLatest(actions.updateDevice, updateDeviceSaga),
  ]);
}
