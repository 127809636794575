import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import { pendingFulfillmentCommercialOrdersPaginationSelector } from 'models/orders/selectors';

const CommercialOrdersTitle = ({ title }) => {
  // Actions
  const fetchPendingFulfillmentCommercialOrders = useAction(
    ordersActions.fetchPendingFulfillmentCommercialOrders
  );

  // Selectors
  const pagination = useSelector(
    pendingFulfillmentCommercialOrdersPaginationSelector
  );

  // Effects
  useEffect(() => {
    fetchPendingFulfillmentCommercialOrders({
      resourcetypes: ['CommercialOrder', 'ContainerWorldOrder'],
      setQuery: false,
    });
  }, [fetchPendingFulfillmentCommercialOrders]);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

CommercialOrdersTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CommercialOrdersTitle;
