import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

const TimeField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  errorMessage,
  disabled,
  className,
  icon,
  fieldClassName,
  min,
  timeValue,
  setTimeValue,
  ...props
}) => {
  return (
    <FormGroup className={className}>
      {label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className={fieldClassName}>
        {icon}
        <AvField
          name={name}
          type="time"
          value={timeValue}
          disabled={disabled}
          min={min}
          validate={{
            required: { value: !!isRequired },
            ...rules,
          }}
          errorMessage={errorMessage}
          onChange={event => {
            setTimeValue(event.target.value);
          }}
          {...props}
        />
      </div>
    </FormGroup>
  );
};

TimeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
  fieldClassName: PropTypes.string,
  min: PropTypes.string,
  timeValue: PropTypes.string,
  setTimeValue: PropTypes.func,
};

export default TimeField;
