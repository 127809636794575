import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as productsActions } from 'models/products/slice';
import {
  productSelector,
  isPendingSelector as productsIsPendingSelector,
} from 'models/products/selectors';

import Main from './Main';
import Header from './Header';
import FoodHeader from './FoodHeader';
import MerchandiseHeader from './MerchandiseHeader';
// import Sidebar from './Sidebar';

const ShowProduct = ({ title }) => {
  const { productID } = useParams();
  const fetchProduct = useAction(productsActions.fetchProduct);
  const productIsPending = useSelector(productsIsPendingSelector);
  const product = useSelector(productSelector);

  useEffect(() => {
    fetchProduct({ id: productID });
  }, [fetchProduct, productID]);

  const ProductHeader = useMemo(() => {
    switch (product.resourcetype) {
      case 'Food':
        return <FoodHeader product={product} />;
      case 'Merchandise':
        return <MerchandiseHeader product={product} />;
      default:
        return <Header product={product} />;
    }
  }, [product]);

  if (productIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/products/${product?.id}/edit`}
        title={`Edit ${title}`}
        breadcrumbItems={[
          { title: 'Back to products', link: '/products/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg="12">{ProductHeader}</Col>
      </Row>
      <Row>
        <Col md="12">
          <Main
            awards={product?.awards || []}
            inventories={product?.inventories || []}
            food_additions={product?.food_additions || []}
            wine_pairings={product?.wine_pairings || []}
            productID={product?.id}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

ShowProduct.propTypes = {
  title: PropTypes.string,
};

export default ShowProduct;
