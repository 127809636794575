export const TRANSFER_TABLE_MODES = Object.freeze({
  DEFAULT: 0,
  MANUAL: 1,
  PROCESS: 2,
});

export const PRODUCT_TYPES = [
  { label: 'All products', value: undefined },
  { label: 'Wine', value: 'Wine' },
  { label: 'Merchandise', value: 'Merchandise' },
];
