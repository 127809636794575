import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './NotePopup.scss';
import Select from 'react-select';
import { Button, ModalBody, ModalHeader, FormGroup, Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PopupBase from '../PopupBase';
import classNames from 'classnames';
import TextareaField from 'components/Fields/TextareaField';
import DateField from 'components/Fields/DateField';
import TimeField from 'components/Fields/TimeField';
import { ACTIVITY_TYPES } from 'constants/organizations';

const NotePopup = ({
  onClose,
  handleSubmit,
  header,
  placeholder,
  name,
  isActivity,
  timeValue,
  dateValue,
  setDateValue,
  setTimeValue,
  activityType,
  setActivityType,
}) => {
  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <ModalHeader toggle={() => onClose(false)}>{header}</ModalHeader>
      <ModalBody>
        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
          {isActivity && (
            <>
              <DateField
                name="activityDate"
                label="Date"
                errorMessage="Enter Activity Date"
                isRequired={isActivity}
                value={dateValue}
                onChange={event => setDateValue(event.target.value)}
              />
              <TimeField
                name="activityTime"
                label="Time"
                errorMessage="Enter Activity Time"
                isRequired={isActivity}
                timeValue={timeValue}
                setTimeValue={setTimeValue}
              />
              <FormGroup>
                <Label htmlFor="type">Type*</Label>
                <Select
                  className="basic-single mr-1"
                  classNamePrefix="select"
                  value={activityType}
                  name="type"
                  options={ACTIVITY_TYPES}
                  onChange={setActivityType}
                  isRequired={isActivity}
                />
              </FormGroup>
            </>
          )}
          <TextareaField
            name={name}
            placeholder={placeholder}
            errorMessage={`Enter a ${name}`}
            className={classNames(styles.textarea, 'form-control')}
            isRequired
          />
          <Button color="primary" type="submit">
            Submit
          </Button>
        </AvForm>
      </ModalBody>
    </PopupBase>
  );
};

NotePopup.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  isActivity: PropTypes.bool,
  timeValue: PropTypes.string,
  dateValue: PropTypes.string,
  setDateValue: PropTypes.func,
  setTimeValue: PropTypes.func,
  activityType: PropTypes.string,
  setActivityType: PropTypes.func,
};

export default memo(NotePopup);
