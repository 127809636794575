import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchOrganizationsSaga(action) {
  const { payload } = action;
  const { requestURL } = yield generateURL({
    payload,
    url: '/organizations/',
    requestURL: '/organizations/',
    setQuery: payload.setQuery,
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchOrganizationSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/organizations/${action.payload}`,
  });
}

export function* createOrganizationSaga(action) {
  const isLead = !!action.payload.lead_status;
  yield api({
    action,
    method: 'post',
    url: `/organizations/`,
    data: action.payload,
    successMessage: isLead
      ? 'Successfully created a new Lead!'
      : 'Successfully created a new Commercial Customer!',
    failureMessage: isLead
      ? 'An error occurred while creating a Lead.'
      : 'An error occurred while creating a Commercial Customer.',
    successNavigateTo: isLead ? '/leads/' : '/commercial-customers/',
  });
}

export function* addOrganizationNoteSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: {
      text: payload.note,
    },
    url: `/organizations/${payload.id}/note`,
    successMessage: 'Successfully added organization note!',
    showResponseError: true,
  });
}

export function* addOrganizationActivitySaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: {
      text: payload.comment,
      type: payload.type.value,
      activity_date: payload.activity_date,
    },
    url: `/organizations/${payload.id}/activity`,
    successMessage: 'Successfully added organization activity!',
    showResponseError: true,
  });
}

export function* updateOrganizationSaga(action) {
  const isLead =
    action.payload.isLead ||
    (action.payload.lead_status && action.payload.lead_status !== 'CONNECTED');
  yield api({
    action,
    method: 'patch',
    url: `/organizations/${action.payload.id}`,
    data: action.payload,
    successMessage: isLead
      ? 'Successfully updated a Lead!'
      : 'Successfully updated a Commercial Customer!',
    failureMessage: isLead
      ? 'An error occurred while updating a Lead.'
      : 'An error occurred while updating a Commercial Customer.',
  });
}

export function* updateContactPersonEmailSaga(action) {
  const data = {
    change_contact_person_email: true,
    ...action.payload,
  };
  yield api({
    action,
    method: 'patch',
    url: `/organizations/${action.payload.id}`,
    data,
    successMessage: 'Successfully updated contact person email!',
  });
}

export function* replaceContactPersonSaga(action) {
  const data = {
    replace_contact_person: true,
    ...action.payload,
  };
  yield api({
    action,
    showResponseError: false,
    method: 'patch',
    url: `/organizations/${action.payload.id}`,
    data,
    successMessage: 'Successfully replaced contact person!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchOrganizations, fetchOrganizationsSaga),
    takeLatest(actions.fetchOrganization, fetchOrganizationSaga),
    takeLatest(actions.createOrganization, createOrganizationSaga),

    takeLatest(actions.addOrganizationNote, addOrganizationNoteSaga),
    takeLatest(actions.addOrganizationActivity, addOrganizationActivitySaga),
    takeLatest(actions.updateOrganization, updateOrganizationSaga),
    takeLatest(actions.updateContactPersonEmail, updateContactPersonEmailSaga),
    takeLatest(actions.replaceContactPerson, replaceContactPersonSaga),
  ]);
}
