import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { nanoid } from 'nanoid';

const brandsCompaniesSlice = createSlice({
  name: 'brandsCompanies',
  initialState: {
    isPending: false,

    companies: [],
    brandsCompanies: [],
    brandsCompaniesFetched: false,
    pagination: {},

    currentBrandCompanies: {},
  },
  reducers: {
    fetchCompanies: state => {
      state.isPending = true;
    },
    fetchCompaniesSuccess(state, { payload }) {
      state.isPending = false;
      state.companies = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchCompaniesFailure: state => {
      state.isPending = false;
    },

    fetchBrandsCompanies: state => {
      state.isPending = true;
      state.brandsCompaniesFetched = false;
    },
    fetchBrandsCompaniesSuccess(state, { payload }) {
      const { results } = payload.data;

      state.isPending = false;
      state.pagination = payload.data.pagination;
      state.brandsCompaniesFetched = true;
      state.brandsCompanies = results;

      state.currentBrandCompanies = results.reduce((result, current) => {
        const id = nanoid();
        return {
          ...result,
          [id]: {
            itemId: id,
            entity_id: current.id,
            ...current,
          },
        };
      }, {});
    },
    fetchBrandsCompaniesFailure: state => {
      state.isPending = false;
      state.brandsCompaniesFetched = true;
    },

    changeCurrentBrandCompanyField(state, { payload }) {
      const { itemId, assignedObject } = payload;
      state.currentBrandCompanies[itemId] = {
        ...state.currentBrandCompanies[itemId],
        ...assignedObject,
      };
    },

    addCurrentBrandCompany(state) {
      const itemId = nanoid();
      state.currentBrandCompanies[itemId] = {
        itemId,
        api_key: '',
        is_active: false,
        brand_id: null,
        brand_title: null,
        shipping_company_id: null,
        shipping_company_title: null,
      };
    },

    saveCompanies(state) {
      state.isPending = true;
    },
    saveCompaniesSuccess(state, { payload }) {
      const { itemId } = payload.inResponseTo;
      const { data } = payload;
      state.currentBrandCompanies[itemId].brand_id = data.brand_id;
      state.currentBrandCompanies[itemId].brand_title = data.brand_title;
      state.currentBrandCompanies[itemId].entity_id = data.id;
      state.brandsCompanies.push(data);
      state.isPending = false;
    },
    saveUpdateCompaniesSuccess(state, { payload }) {
      const { itemId } = payload.inResponseTo;
      const { data } = payload;
      state.currentBrandCompanies[itemId] = {
        is_active: data.is_active,
        api_key: data.api_key,
      };
      state.isPending = false;
    },
    saveCompaniesFinalize(state) {
      state.isPending = false;
    },
    saveCompaniesFailure(state) {
      state.isPending = false;
    },

    deleteCompany(state) {
      state.isPending = true;
    },
    deleteCompanySuccess(state, { payload }) {
      const { itemId } = payload.inResponseTo;
      const entityId = state.currentBrandCompanies[itemId].entity_id;
      delete state.currentBrandCompanies[itemId];
      state.brandsCompanies = state.brandsCompanies.filter(
        c => c.id !== entityId
      );
      state.isPending = false;
    },
    deleteCompanyFailure(state) {
      state.isPending = false;
    },
    deleteCompanyItem(state, { payload }) {
      const { itemId } = payload;
      delete state.currentBrandCompanies[itemId];
    },
  },
});

export const actions = actionTypes(brandsCompaniesSlice.actions);

export default brandsCompaniesSlice.reducer;
