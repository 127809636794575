import cx from 'classnames';
import PageWrapper from 'components/PageWrapper';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import useAction from 'hooks/useAction';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import CameraForm from '../../components/CameraForm';
import CamerasNavigationTabs from '../../components/CamerasNavigationTabs';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { CAMERA_STATS, CAMERA_STATUS } from './constants';
import styles from './FootTraffic.scss';

import { camerasSelector } from 'models/footTraffic/selectors';
import { actions as footTrafficActions } from 'models/footTraffic/slice';
import { useSelector } from 'react-redux';
import CameraStatsCard from '../../components/CameraStatsCard';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';

const getTabsForCameras = cameras => {
  if (cameras.length === 0) {
    return [];
  }

  return cameras.map(camera => {
    let status;

    if (!camera.isLoading && camera.status) {
      ({ status } = camera);
    } else if (!camera.isLoading && !camera.status) {
      status = camera.isActive ? CAMERA_STATUS.RUNNING : CAMERA_STATUS.STOPPED;
    }
    return {
      id: camera.id.toString(),
      title: camera.name,
      status,
      isLoading: camera.isLoading,
    };
  });
};

const FootTraffic = ({ title }) => {
  const query = useQuery();
  const router = useHistory();
  const location = useLocation();

  const fetchCameras = useAction(footTrafficActions.fetchCameras);
  const addCamera = useAction(footTrafficActions.addCamera);
  const updateCamera = useAction(footTrafficActions.updateCamera);
  const removeCamera = useAction(footTrafficActions.removeCamera);
  const clearSelectedCamera = useAction(footTrafficActions.clearSelectedCamera);

  const cameras = useSelector(camerasSelector);

  const start = query.get('start');
  const end = query.get('end');
  const createdStartDate = new Date();
  const createdEndDate = new Date();

  createdStartDate.setHours(0, 0, 0, 0);
  createdEndDate.setHours(23, 59, 59, 0);

  const [dateRange, setDateRange] = useState({
    startDate: start ? new Date(parseInt(start, 10)) : createdStartDate,
    endDate: end ? new Date(parseInt(end, 10)) : createdEndDate,
  });
  const [selectedCamera, setSelectedCamera] = useState(query.get('device'));
  const [selectedCameraSettings, setSelectedCameraSettings] = useState(null);
  const [openedCameraSettings, setOpenedCameraSettings] = useState(false);
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const [selectedForRemoval, setSelectedForRemoval] = useState({});
  const [lastCameraIndex, setCameraIndex] = useState(-1);

  useEffect(() => {
    if (selectedCamera) {
      query.set('device', selectedCamera);
    }
    if (dateRange) {
      query.set('start', dateRange.startDate.getTime());
      query.set('end', dateRange.endDate.getTime());
    }
    router.replace(`${location.pathname}?${query.toString()}`);
  }, [selectedCamera, dateRange]);

  useEffect(() => {
    if (cameras.length > 0) {
      if (query.get('device')) {
        setSelectedCamera(query.get('device'));
      } else if (lastCameraIndex !== -1 && cameras[lastCameraIndex]) {
        setSelectedCamera(cameras[lastCameraIndex].id);
      } else {
        setSelectedCamera(cameras[0].id);
      }
    }
  }, [cameras]);

  useEffect(() => {
    fetchCameras();
  }, [fetchCameras]);

  const saveCamera = cameraData => {
    if (cameraData.id) {
      setCameraIndex(cameras.findIndex(camera => camera.id === cameraData.id));
      updateCamera({
        name: cameraData.name,
        description: '',
        id: cameraData.id,
        url: cameraData.url,
        isActive: cameraData.isActive,
        direction: cameraData.direction,
        warehouse_id: cameraData.warehouseId,
      });
    } else {
      setCameraIndex(cameras.length);
      addCamera({
        name: cameraData.name,
        description: '',
        url: cameraData.url,
        isActive: cameraData.isActive,
        direction: cameraData.direction,
        type: 'CAMERA',
        warehouse_id: cameraData.warehouseId,
        brand_id: cameraData.brandId,
      });
    }
  };

  const openConfirmationModal = () => {
    setSelectedForRemoval({
      name: selectedCameraSettings.title,
      id: selectedCameraSettings.id,
    });

    setConfirmRemoval(true);
  };

  const deleteCamera = () => {
    let currentId = cameras.findIndex(
      camera => camera.id === selectedForRemoval.id
    );
    if (currentId > cameras.length - 2) {
      currentId -= 1;
    }
    if (currentId < 0) {
      currentId = 0;
    }

    setCameraIndex(currentId);
    removeCamera(selectedForRemoval.id);
  };

  const getWarehouseId = cameraId => {
    const el = cameras.find(
      idCamera => idCamera.id.toString() === cameraId.toString()
    );

    if (el) {
      return el.warehouse_id;
    }
    return null;
  };

  const getIdentifier = cameraId => {
    const el = cameras.find(
      idCamera => idCamera.id.toString() === cameraId.toString()
    );
    if (el) {
      return el.identifier;
    }
    return null;
  };

  const handleDateChange = dates => {
    if (dates.endDate) {
      dates.endDate.setHours(23, 59, 0, 0);
    }
    setDateRange(dates);
  };

  return (
    <PageWrapper title={title}>
      <Row className="ml-2 mr-2">
        <DateRangePicker
          rangeStyle="literal"
          defaultToToday
          startDate={dateRange ? dateRange.startDate.getTime() : null}
          endDate={dateRange ? dateRange.endDate.getTime() : null}
          onDateChange={handleDateChange}
        />
      </Row>
      <Row className="ml-2 mt-5 mr-2">
        <CamerasNavigationTabs
          selectedId={selectedCamera && selectedCamera.toString()}
          tabs={getTabsForCameras(cameras)}
          onSelectTab={tab => {
            setSelectedCamera(tab);
          }}
          onSelectCameraSettings={camera => {
            setSelectedCameraSettings(camera);
            setOpenedCameraSettings(true);
          }}
          onClickAddCamera={() => {
            setSelectedCameraSettings(null);
            setOpenedCameraSettings(true);
          }}
        />
      </Row>
      {openedCameraSettings && (
        <CameraForm
          cameraId={
            selectedCameraSettings && selectedCameraSettings.id.toString()
          }
          onClose={() => {
            clearSelectedCamera();
            setSelectedCameraSettings(null);
            setOpenedCameraSettings(false);
          }}
          onSave={cameraData => {
            saveCamera(cameraData);
          }}
          onDelete={cameraId => {
            openConfirmationModal(cameraId);
          }}
        />
      )}
      {confirmRemoval && (
        <ConfirmationPopup
          active={confirmRemoval}
          setActive={setConfirmRemoval}
          title={`Delete camera ${selectedForRemoval.name}?`}
          onSaveClick={() => {
            deleteCamera();
          }}
        >
          <p>
            Deleting a camera will permanently remove it from the dashboard.
          </p>
        </ConfirmationPopup>
      )}
      {selectedCamera && getWarehouseId(selectedCamera) && (
        <Row className="ml-2 mr-2 mt-4">
          {CAMERA_STATS.map(stats => (
            <Col
              className={cx([
                'col-12 col-md-6 col-xl-6',
                styles.customXXL,
                styles.cardColumn,
              ])}
              key={`${stats.title}-${selectedCamera.toString()}-${
                dateRange.startDate
              }-${dateRange.endDate}`}
            >
              <CameraStatsCard
                stats={stats}
                warehouseId={selectedCamera && getWarehouseId(selectedCamera)}
                deviceId={selectedCamera && selectedCamera.toString()}
                cameraIdentifier={
                  selectedCamera && getIdentifier(selectedCamera)
                }
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
              />
            </Col>
          ))}
        </Row>
      )}
    </PageWrapper>
  );
};

FootTraffic.propTypes = {
  title: PropTypes.string,
};

export default FootTraffic;
