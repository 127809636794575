import React, { memo, useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import NumberFormat from 'react-number-format';
import { FieldType } from '../types.d';
import classNames from 'classnames';

import styles from './PhoneField.scss';

const PhoneField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  value,
  defaultValue,
  errorMessage,
  disabled,
  className,
  fieldClassName,
  ...props
}) => {
  const [inputRef, setInputRef] = useState(null);
  const [unmaskedValue, setUnmaskedValue] = useState(
    value?.replace(/[^0-9]/g, '')
  );
  const [maskedValue, setMaskedValue] = useState(value?.replace(/[^0-9]/g, ''));

  const handleChangeMasked = e => {
    const newUnmaskedValue = e.target.value?.replace(/[^0-9]/g, '');
    if (onChange) {
      setMaskedValue(newUnmaskedValue.slice(1));
      onChange(newUnmaskedValue);
    } else {
      setUnmaskedValue(newUnmaskedValue);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      const strValue = value ? String(value.replace(/[^0-9]/g, '')) : '';
      const newValue = strValue?.length === 11 ? strValue?.slice(1) : strValue;
      if (strValue?.length === 11 && strValue[0] !== 1) {
        setUnmaskedValue(`1${newValue}`);
      } else {
        setUnmaskedValue(newValue);
      }
      if (strValue.slice(1) !== maskedValue?.replace(/[^0-9]/g, '')) {
        setMaskedValue(newValue);
        onChange?.(`1${newValue}`);
      }
    }
  }, [value]);

  useEffect(() => {
    if (value === undefined) {
      const newPhone = inputRef?.FormCtrl?.getValues()?.[name];
      setUnmaskedValue(newPhone?.replace(/[^0-9]/g, ''));
      if (newPhone !== maskedValue?.replace(/[^0-9]/g, '')) {
        const newValue =
          newPhone?.length === 11 ? newPhone?.slice(1) : newPhone;
        setMaskedValue(newValue);
      }
    }
  }, [inputRef]);

  return (
    <FormGroup className={classNames(styles.container, className)}>
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className={styles.hidden}>
        <AvField ref={r => setInputRef(r)} name={name} value={unmaskedValue} />
      </div>
      <AvField
        name={`${name}-validate`}
        type="phone"
        className={classNames('form-control', fieldClassName)}
        validate={{
          checkLength: v => {
            return (
              !v ||
              v?.replace(/-|_/g, '')?.length >= 10 ||
              'Phone number should be 11 digit number'
            );
          },
          required: {
            value: !!isRequired,
          },
          ...rules,
        }}
        format="1-###-###-####"
        mask="_"
        error
        errorMessage={errorMessage}
        disabled={disabled}
        tag={[NumberFormat]}
        value={maskedValue}
        onChange={handleChangeMasked}
        {...props}
      />
    </FormGroup>
  );
};

PhoneField.propTypes = FieldType;

export default memo(PhoneField);
