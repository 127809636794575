import { Col, Row } from 'reactstrap';
import React from 'react';
import styles from 'pages/ExciseReturn/ExciseReturn.scss';
import PropTypes from 'prop-types';
import NumberField from 'components/Fields/NumberField';

export const MultipleNumberFields = ({ name, disabled, onBlur, min }) => (
  <>
    <Col md={2}>
      <NumberField
        name={`seven_percent_return_detail.${name}`}
        disabled={disabled}
        onBlur={onBlur}
        defaultValue="0.00"
        min={min}
      />
    </Col>
    <Col md={2}>
      <NumberField
        name={`one_percent_return_detail.${name}`}
        disabled={disabled}
        onBlur={onBlur}
        defaultValue="0.00"
        min={min}
      />
    </Col>
    <Col md={2}>
      <NumberField
        name={`zero_percent_return_detail.${name}`}
        disabled={disabled}
        onBlur={onBlur}
        defaultValue="0.00"
        min={min}
      />
    </Col>
  </>
);
MultipleNumberFields.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  min: PropTypes.string,
};

export const MultipleFieldRow = ({ title, name, disabled, onBlur, min }) => (
  <>
    <Row>
      <Col md={2} className={styles.right}>
        {title}
      </Col>
      <MultipleNumberFields
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        min={min}
      />
    </Row>
  </>
);
MultipleFieldRow.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  min: PropTypes.string,
};
