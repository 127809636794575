import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';

export function* fetchDataSourcesSaga(action) {
  yield api({
    action,
    method: 'get',
    url: '/import_data_sources/',
  });
}

export function* fetchDataSourceExtDataSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/import_data_sources/${action.payload.entityId}`,
  });
}

export function* saveSourceDataSaga(action) {
  const { entityId, data, create, brandId } = action.payload;
  if (create) {
    yield api({
      action,
      method: 'POST',
      data: { ...data, brand_id: brandId },
      url: `/import_data_sources/`,
    });
  } else {
    yield api({
      data,
      action,
      method: 'PUT',
      url: `/import_data_sources/${entityId}`,
    });
  }
}

function* deleteSourceDataSaga(action) {
  const { entityId } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/import_data_sources/${entityId}`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.saveSource, saveSourceDataSaga),
    takeLatest(actions.deleteSource, deleteSourceDataSaga),
    takeLatest(actions.fetchDataSources, fetchDataSourcesSaga),
    takeLatest(actions.fetchDataSourceExtData, fetchDataSourceExtDataSaga),
  ]);
}
