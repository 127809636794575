import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import { Label } from 'reactstrap';
import ImagesGalleryPopup from 'components/Popup/ImagesGalleryPopup';

import buttons from './buttons';
import PropTypes from 'prop-types';

import styles from './EditorJodit.scss';

const EditorJodit = ({
  label,
  placeholder,
  defaultValue,
  brandId,
  error,
  onChange,
  onBlur,
}) => {
  const editor = useRef(null);
  const [content, setContent] = useState(defaultValue ?? '');
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleClosePopup = useCallback(() => {
    setIsModalOpened(false);
  });

  const handleOpenPopup = useCallback(() => {
    setIsModalOpened(true);
  });

  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  const handleUpdateContent = useCallback(
    newContent => {
      setContent(newContent);
      onChange(newContent);
      if (onBlur) {
        onBlur();
      }
    },
    [onChange, onBlur]
  );

  const handleAddImage = images => {
    const joditEditor = Jodit.make(editor.current);
    images?.forEach(image => {
      joditEditor.selection.insertHTML(
        `<img src="${image.image_url}" width="${
          image.image_width < 300 ? image.image_width : 300
        }" />`
      );
    });
    handleClosePopup();
  };

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      showXPathInStatusbar: false,
      toolbarAdaptive: false,
      placeholder: placeholder || 'Start typings...',
      buttons: [
        ...buttons,
        {
          icon: 'image',
          name: 'Image Gallery',
          exec: handleOpenPopup,
        },
      ],
    }),
    [placeholder]
  );

  return (
    <div className={styles.container}>
      {label && <Label className={error && styles.errorText}>{label}</Label>}
      {isModalOpened && (
        <ImagesGalleryPopup
          brandId={brandId}
          onClose={handleClosePopup}
          handleSubmit={handleAddImage}
        />
      )}
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={0} // tabIndex of textarea
        onBlur={handleUpdateContent} // preferred to use only this option to update the content for performance reasons
      />
      {!!error && <small className={styles.error}>{error}</small>}
    </div>
  );
};

EditorJodit.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  brandId: PropTypes.number.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default EditorJodit;
