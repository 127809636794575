import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';
import {
  productSelector,
  foodCategoriesSelector,
  merchandiseCategoriesSelector,
  additionsSelector,
  pairingsSelector,
  isPendingSelector as productIsPendingSelector,
} from 'models/products/selectors';

import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import ProductForm from 'components/ProductForm';

import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import FoodForm from 'components/FoodForm';
import MerchForm from 'components/MerchForm';

const DuplicateProduct = ({ title }) => {
  const { productID } = useParams();
  const product = useSelector(productSelector);
  const productIsPending = useSelector(productIsPendingSelector);
  const createProduct = useAction(productsActions.createProduct);
  const fetchProduct = useAction(productsActions.fetchProduct);
  const fetchProductCategories = useAction(
    productsActions.fetchProductsCategories
  );
  const foodCategories = useSelector(foodCategoriesSelector);
  const merchandiseCategories = useSelector(merchandiseCategoriesSelector);
  const pairings = useSelector(pairingsSelector);
  const additions = useSelector(additionsSelector);

  const isPending = productIsPending;

  useEffect(() => {
    fetchProduct({ id: productID });
    fetchProductCategories();
  }, []);

  const handleCreateProduct = data => {
    createProduct({
      id: data?.id,
      ...product,
      sku: '',
      ...data,
      resourcetype: product.resourcetype,
    });
  };

  const formType = type => {
    switch (type) {
      case 'Wine':
        return (
          <ProductForm
            model={{ ...product, sku: '' }}
            submitTitle="Duplicate"
            onSubmit={handleCreateProduct}
            disabled={isPending}
            resourcetype="Wine"
          />
        );
      case 'Food':
        return (
          <FoodForm
            model={{ ...product, sku: '' }}
            submitTitle="Duplicate"
            disabled={isPending}
            onSubmit={handleCreateProduct}
            resourcetype="Food"
            categories={Object.values(foodCategories ?? {})}
            additions={additions}
            pairings={pairings}
          />
        );
      case 'Merchandise':
        return (
          <MerchForm
            model={product}
            submitTitle="Duplicate"
            onSubmit={handleCreateProduct}
            resourcetype="Merchandise"
            categories={Object.values(merchandiseCategories ?? {})}
          />
        );
      default:
        return <></>;
    }
  };
  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={product?.title || 'Create product'}
        breadcrumbItems={[
          { title: 'Back to products', link: '/products/', withSearch: true },
        ]}
      />
      <Row>
        {product.id === Number(productID) && (
          <Col md="12">{formType(product.resourcetype)}</Col>
        )}
      </Row>
    </PageWrapper>
  );
};

DuplicateProduct.propTypes = {
  title: PropTypes.string,
};

export default DuplicateProduct;
