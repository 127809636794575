import React, { useCallback, useEffect, useState } from 'react';
import ReportsLayout from 'components/ReportsLayout';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as reportsActions } from 'models/reports/slice';
import {
  tasterTrackingSelector,
  paginationSelector,
  isPendingSelector,
} from 'models/reports/selectors';
import ReportFilters from 'components/ReportFilters';
import Data from './Data';
import { Alert } from 'reactstrap';

const ReportsTasterTrackingSummary = ({ title }) => {
  // States
  const [dateRange, setDateRange] = useState();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page);
  const [isLongDateRange, setIsLongDateRange] = useState(false);

  // Selectors
  const isPending = useSelector(isPendingSelector);
  const tasterTracking = useSelector(tasterTrackingSelector);
  const pagination = useSelector(paginationSelector);

  // Actions
  const fetchWineSalesXlsxRequest = useAction(
    reportsActions.fetchWineSalesXlsx
  );
  const fetchWineSalesRequest = useAction(reportsActions.fetchWineSales);

  // Effects
  useEffect(() => {
    // Check if date range exceeds 1 month
    const checkLongDateRange = () => {
      const start = new Date(dateRange?.startDate);
      const end = new Date(dateRange?.endDate);

      return (
        start.getMonth() !== end.getMonth() ||
        start.getFullYear() !== end.getFullYear()
      );
    };
    setIsLongDateRange(checkLongDateRange());
  }, [dateRange]);

  useEffect(() => {
    if (dateRange && selectedWarehouses?.length && !isLongDateRange) {
      const { startDate, endDate } = dateRange;
      fetchWineSalesRequest({
        page: currentPage,
        startDate,
        endDate,
        warehouseIds: selectedWarehouses.map(({ value }) => value),
      });
    }
  }, [
    dateRange,
    selectedWarehouses,
    currentPage,
    isLongDateRange,
    fetchWineSalesRequest,
  ]);

  // Callbacks
  const onExport = useCallback(() => {
    const { startDate, endDate } = dateRange;
    fetchWineSalesXlsxRequest({
      startDate,
      endDate,
      warehouseIds: selectedWarehouses.map(({ value }) => value),
    });
  }, [fetchWineSalesXlsxRequest, dateRange, selectedWarehouses]);

  // Functions
  const setFilters = filters => {
    setSelectedWarehouses(filters.selectedWarehouses);
  };

  return (
    <ReportsLayout
      title={title}
      onExport={onExport}
      onDateChange={setDateRange}
      loading={isPending}
      exportButtonText="Export to Excel"
    >
      <ReportFilters
        showWarehouse={!isLongDateRange}
        setFilters={setFilters}
        isMultiBrand={!isLongDateRange}
        isMultiWarehouse={!isLongDateRange}
      />

      {!!dateRange && !!selectedWarehouses.length && !isLongDateRange && (
        <Data
          isPending={isPending}
          data={tasterTracking}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        />
      )}

      {isLongDateRange && (
        <Alert color="warning">
          <i className="mdi mdi-information" />{' '}
          <b>
            For date ranges over 30 days request a taster tracking report using
            the Export to Excel button.
          </b>
        </Alert>
      )}
    </ReportsLayout>
  );
};

ReportsTasterTrackingSummary.propTypes = {
  title: PropTypes.string,
};
export default ReportsTasterTrackingSummary;
