import React from 'react';
import PropTypes from 'prop-types';
import styles from './GiftInfo.scss';
import { Col, CustomInput, Input, Label, Row } from 'reactstrap';

const GiftInfo = ({ isGift, changeGift, message, setMessage }) => {
  return (
    <Row className="mb-4">
      <Col md={12} className="mb-2">
        <label className={styles.label}>
          <CustomInput
            id="gift"
            value={isGift}
            onChange={changeGift}
            className={styles.input}
            type="checkbox"
          />
          Is a gift
        </label>
      </Col>
      {isGift && (
        <Col md={12}>
          <Label htmlFor="message">Gift message</Label>
          <Input
            className={styles.textarea}
            type="textarea"
            id="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Gift message"
          />
        </Col>
      )}
    </Row>
  );
};

GiftInfo.propTypes = {
  isGift: PropTypes.bool,
  changeGift: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
};

export default GiftInfo;
