import { takeLatest, all } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchSubscriptionsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/obligations/',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* createSubscriptionSaga(action) {
  yield api({
    action,
    method: 'post',
    url: '/obligations/',
    data: action.payload,
    failureMessage: 'An error occurred while creating subscription',
  });
}

export function* cancelSubscriptionSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/obligations/${action.payload.id}/cancel/`,
    data: action.payload,
    failureMessage: 'An error occurred while closing subscription',
  });
}

export function* fetchSubscriptionSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/obligations/${action.payload}`,
  });
}

export function* activateSubscriptionSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/obligations/${action.payload.id}/confirm/`,
    data: action.payload.data,
    failureMessage: 'An error occurred while activating subscription',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchSubscriptions, fetchSubscriptionsSaga),
    takeLatest(actions.createSubscription, createSubscriptionSaga),
    takeLatest(actions.cancelSubscription, cancelSubscriptionSaga),
    takeLatest(actions.fetchSubscription, fetchSubscriptionSaga),
    takeLatest(actions.activateSubscription, activateSubscriptionSaga),
  ]);
}
