import { keyBy } from 'lodash';
import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const terminalsSlice = createSlice({
  name: 'terminals',
  initialState: {
    isPending: false,
    terminals: {},
    terminal: {},
    pagination: {},
  },
  reducers: {
    fetchTerminals: state => {
      state.isPending = true;
    },
    fetchTerminalsSuccess(state, { payload }) {
      state.isPending = false;
      state.terminals = keyBy(payload.data.results, 'id');
      state.pagination = payload.data.pagination;
    },
    fetchTerminalsFailure: state => {
      state.isPending = false;
    },
    fetchTerminal: state => {
      state.isPending = true;
    },
    fetchTerminalSuccess(state, { payload }) {
      state.isPending = false;
      state.terminal = payload.data;
    },
    fetchTerminalFailure: state => {
      state.isPending = false;
    },
    createTerminal: state => {
      state.isPending = true;
    },
    createTerminalSuccess(state, { payload }) {
      state.terminals[payload.data.id] = payload.data;
      state.isPending = false;
    },
    createTerminalFailure: state => {
      state.isPending = false;
    },
    deleteTerminal: state => {
      state.isPending = true;
    },
    deleteTerminalSuccess(state, { payload }) {
      delete state.terminals[payload.inResponseTo];
      state.isPending = false;
    },
    deleteTerminalFailure: state => {
      state.isPending = false;
    },
    updateTerminal: state => {
      state.isPending = true;
    },
    updateTerminalSuccess(state, { payload }) {
      state.terminals[payload.inResponseTo.id] = { ...payload.data };
      state.isPending = false;
    },
    updateTerminalFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(terminalsSlice.actions);

export default terminalsSlice.reducer;
