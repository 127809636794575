import React from 'react';

import styles from './NotFound.scss';

const NotFound = () => {
  return (
    <div className={styles.root}>
      <p>Oops, Page was not found!</p>
    </div>
  );
};

export default NotFound;
