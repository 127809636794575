import React, { useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions } from 'models/user/slice';
import useSelector from 'hooks/useSelector';
import {
  rolesSelector,
  userByIdSelector,
  usersPendingSelector,
} from 'models/user/selectors';
import UserForm from 'components/UserForm';
import Preloader from 'components/Preloader';

const getPreparedUser = user => {
  const brands = user?.host_brands
    ? user.host_brands.map(brand => ({
        label: brand?.brand_title,
        value: brand?.brand_id,
      }))
    : [];
  const roles = user?.roles ? user.roles.filter(role => role !== 'staff') : [];
  return {
    ...user,
    is_host_active: user?.host?.is_host_active,
    is_bookkeeper: user?.is_bookkeeper_active,
    brands,
    roles,
  };
};

const EditUser = ({ title }) => {
  const { id } = useParams();
  const fetchUser = useAction(actions.fetchUser);
  const updateUser = useAction(actions.updateUser);
  const user = useSelector(userByIdSelector(id));
  const userPending = useSelector(usersPendingSelector);
  const roles = useSelector(rolesSelector);

  useEffect(() => {
    fetchUser(id);
  }, [fetchUser]);

  const onSubmit = data => {
    updateUser({ ...data, id });
  };

  if (userPending) {
    return <Preloader />;
  }

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          { title: 'Back to users', link: '/users/', withSearch: true },
        ]}
      />
      {user && (
        <UserForm
          defaultValues={getPreparedUser(user)}
          onSubmit={onSubmit}
          isOwnerOrManager={
            roles.includes('owner') || roles.includes('manager')
          }
        />
      )}
    </PageWrapper>
  );
};

EditUser.propTypes = {
  title: PropTypes.string.isRequired,
};
export default EditUser;
