import { ORDER_STATUSES } from 'constants';

const REMOVE_STATUSES = [
  'Waiting For Approval',
  'Customer Approved',
  'Manager Approved',
  'Finished',
  'Shipped',
  'Cancelled',
  'Order Created',
];

export const COMMERCIAL_ORDER_DISPLAY_STATUS = ORDER_STATUSES.filter(
  status => !REMOVE_STATUSES.includes(status.userValue)
);
