import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import CommentPopup from 'components/Popup/CommentPopup';
import PropTypes from 'prop-types';
import { COMMISSIONS_STATUSES } from '../../constants/commissions';

const CommissionActions = ({ isOwner, isSalesRep, status, onSubmit }) => {
  const [popupData, setPopupData] = useState(null);

  const onAcceptClickHandler = () => {
    onSubmit?.({ status: COMMISSIONS_STATUSES.ACCEPTED });
  };

  const onDisputeClickHandler = comment => {
    onSubmit?.({
      status: COMMISSIONS_STATUSES.DISPUTED,
      dispute_comment: comment,
    });
  };

  const onApproveClickHandler = () => {
    onSubmit?.({ status: COMMISSIONS_STATUSES.APPROVED });
  };

  const onArchiveClickHandler = comment => {
    onSubmit?.({
      status: COMMISSIONS_STATUSES.ARCHIVED,
      archive_comment: comment,
    });
  };

  const onPopupSubmitHandler = (_, data) => {
    const { comment } = data;
    if (popupData?.submitHandler && comment) {
      popupData.submitHandler(comment);
    }
  };

  const Actions = useMemo(() => {
    if (isSalesRep && status === COMMISSIONS_STATUSES.INITIALIZED) {
      return (
        <>
          <Button
            onClick={() =>
              setPopupData({
                title: 'Dispute Commissions',
                submitHandler: onDisputeClickHandler,
              })
            }
            className="mr-2"
            color="danger"
          >
            Dispute
          </Button>
          <Button onClick={onAcceptClickHandler} color="success">
            Accept
          </Button>
        </>
      );
    }

    if (
      isOwner &&
      [COMMISSIONS_STATUSES.ACCEPTED, COMMISSIONS_STATUSES.DISPUTED].includes(
        status
      )
    ) {
      return (
        <>
          {status === COMMISSIONS_STATUSES.ACCEPTED && (
            <Button
              onClick={() =>
                setPopupData({
                  title: 'Approve',
                  submitHandler: onApproveClickHandler,
                })
              }
              className="mr-2"
              color="success"
            >
              Approve
            </Button>
          )}
          <Button
            onClick={() =>
              setPopupData({
                title: 'Archive and Create New Report',
                submitHandler: onArchiveClickHandler,
              })
            }
            color="danger"
          >
            Archive and Create New Commission Report
          </Button>
        </>
      );
    }

    return <></>;
  }, [isOwner, isSalesRep, status]);

  return (
    <>
      {popupData != null && (
        <CommentPopup
          title={popupData.title}
          onSubmit={onPopupSubmitHandler}
          onClose={() => setPopupData(null)}
        />
      )}
      <div className="d-flex">{Actions}</div>
    </>
  );
};

CommissionActions.propTypes = {
  status: PropTypes.string,
  isSalesRep: PropTypes.bool,
  isOwner: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default CommissionActions;
