import React, { memo } from 'react';
import styles from './FileField.scss';
import PropTypes from 'prop-types';

const FileUploaded = ({ name, onDelete }) => {
  return (
    <div className={styles.uploadedContainer}>
      <span className={styles.uploadedFile}>{name}</span>
      <button onClick={onDelete} className={styles.deleteButton}>
        <i className="ri-close-fill font-size-20" />
      </button>
    </div>
  );
};

FileUploaded.propTypes = {
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};
export default memo(FileUploaded);
