import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';

const AdditionsPaginatedField = ({
  onChange,
  additions,
  brandId,
  disabled,
}) => {
  const request = useApiRequest();

  const fetchAdditions = ({ page = 1, search }) => {
    return request({
      url: `/products/?page=${page}&addition=true&resourcetype=Food&title_contains=${search}&brand_id=${brandId}`,
    });
  };

  const loadAdditions = async (search, loadedOptions, { page }) => {
    try {
      if (!brandId) {
        return {
          options: [],
          hasMore: true,
          additional: { page },
        };
      }
      const response = await fetchAdditions({ page, search });
      return {
        options: response?.results.map(item => ({
          value: item.id,
          label: item.title,
        })),
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      name="additions"
      cacheUniqs={[brandId]}
      isSearchable
      debounceTimeout={1000}
      isMulti
      additional={{
        page: 1,
      }}
      loadOptions={loadAdditions}
      value={additions}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

AdditionsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  brandId: PropTypes.number,
  additions: PropTypes.array,
  disabled: PropTypes.bool,
};

export default AdditionsPaginatedField;
