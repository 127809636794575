import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';

export function* fetchShipmentRatesSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}/get_shipment_rates`,
  });
}

export function* createShipmentSaga(action) {
  yield api({
    action,
    method: 'post',
    url: '/shipments/',
    data: action.payload,
    successMessage: 'Successfully create new shipment',
  });
}

export function* updateShipmentSaga(action) {
  yield api({
    action,
    method: 'PATCH',
    url: `/shipments/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated',
  });
}

export function* deleteShipmentSaga(action) {
  yield api({
    action,
    method: 'delete',
    url: `/shipments/${action.payload}`,
    successMessage: 'Successfully deleted shipment',
    failureMessage: 'The shipment has already been deleted',
  });
}

export function* updateShippingSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/shipments/${action.payload}/status_update`,
    successMessage: 'Successfully updated delivery status',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchShipmentRates, fetchShipmentRatesSaga),
    takeLatest(actions.createShipment, createShipmentSaga),
    takeLatest(actions.updateShipment, updateShipmentSaga),
    takeLatest(actions.deleteShipment, deleteShipmentSaga),
    takeLatest(actions.updateShipping, updateShippingSaga),
  ]);
}
