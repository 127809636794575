import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { selectStyles } from 'constants/selectStyles';

const ShippingCompaniesField = ({
  brandId,
  onChange,
  isMulti,
  defaultValue,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchShippingCompanies = async ({ page = 1 }) => {
    return apiRequest({
      url: `/brand_shipping_companies/?brand_id=${brandId}&page=${page}`,
    });
  };

  const loadShippingCompanies = async (search, loadedOptions, { page }) => {
    try {
      const { pagination, results } = await fetchShippingCompanies({ page });
      return {
        options: results.map(item => ({
          ...item,
          value: item.id,
          label: item.shipping_company_title,
        })),
        hasMore: pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      console.error(error);
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="shipping_company"
      className="w-100"
      isSearchable={false}
      defaultValue={defaultValue}
      additional={{
        page: 1,
      }}
      loadOptions={loadShippingCompanies}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

ShippingCompaniesField.propTypes = {
  brandId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
};

export default ShippingCompaniesField;
