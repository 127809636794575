import React, { memo } from 'react';
import styles from '../EmailTemplateSummary.scss';
import PaginationTable from 'components/PaginationTable';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { emailTemplateTypeName } from 'constants/emailTemplates';
import { getNormalText } from 'utils/getNormalText';
import QueryLink from 'components/QueryLink';

const DataTable = ({
  data,
  pagination,
  onPageChange,
  loading,
  onPreviewClick,
  onRemoveClick,
  wineClubId,
  isCategoryCustom,
  className,
}) => {
  const getEditLink = id => {
    return isCategoryCustom
      ? `/email/${id}/edit`
      : `/email-template/${wineClubId}/${id}/edit/`;
  };

  return (
    <div className={classNames(loading && styles.loadingContainer, className)}>
      {pagination?.total_count > 0 && (
        <PaginationTable setCurrentPage={onPageChange} pagination={pagination}>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                {!isCategoryCustom && <th>Template Type</th>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id.toString()}>
                  <td>{item?.id}</td>
                  <td>{getNormalText(item?.title)}</td>
                  {!isCategoryCustom && (
                    <td>
                      {emailTemplateTypeName?.[item?.email_template_title] ||
                        getNormalText(item?.email_template_title) ||
                        '-'}
                    </td>
                  )}
                  <td>
                    {(!isCategoryCustom ||
                      (isCategoryCustom && item?.mutable)) && (
                      <>
                        <Button
                          onClick={() => onPreviewClick(item.id)}
                          color="primary"
                          className="ml-2"
                        >
                          Preview
                        </Button>
                        <Button
                          onClick={() => onRemoveClick(item.id)}
                          color="danger"
                          className="ml-2"
                        >
                          <i className="mdi mdi-trash-can font-size-16" />
                        </Button>
                        <>
                          <QueryLink
                            to={getEditLink(item.id)}
                            className="ml-3 text-primary"
                            id={`edit${item.id}`}
                          >
                            <i className="mdi mdi-pencil font-size-18" />
                          </QueryLink>
                          <UncontrolledTooltip
                            placement="top"
                            target={`edit${item.id}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                        </>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PaginationTable>
      )}
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  wineClubId: PropTypes.number,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  isCategoryCustom: PropTypes.bool,
  className: PropTypes.string,
};
export default memo(DataTable);
