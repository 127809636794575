import api from 'api';
import { takeLatest, all, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { showErrorMessage } from 'utils/notification';

import { actions } from './slice';
import * as queryString from 'query-string';

function* fetchFiltersSaga(action) {
  yield api({
    action,
    method: 'get',
    url: '/mailing_lists',
  });
}

function* fetchFileSaga(action) {
  const { id, parameters } = action.payload;
  const queryParameters = queryString.stringify(parameters, {
    arrayFormat: 'comma',
  });
  const url = `/mailing_lists/${id}?${queryParameters}`;
  yield api({
    action,
    method: 'get',
    url,
    responseType: 'blob',
    showResponseError: true,
  });
}

function* fetchFileSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { filterTitle },
    } = payload;
    const filename = `${filterTitle}.csv`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading CSV file'
    );
  }
}

function fetchFileFailureSaga({ payload }) {
  if (payload.err === 'Request failed with status code 400') {
    showErrorMessage(
      'Error!',
      'Customers list with current parameters is empty'
    );
  }
}

export default function*() {
  yield all([
    takeLatest(actions.fetchFilters, fetchFiltersSaga),
    takeLatest(actions.fetchFile, fetchFileSaga),
    takeLatest(actions.fetchFileSuccess, fetchFileSuccessSaga),
    takeLatest(actions.fetchFileFailure, fetchFileFailureSaga),
  ]);
}
