import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as discountCodesActions } from 'models/discounts/slice';
import { codeSelector, isPendingSelector } from 'models/discounts/selectors';

import Main from './Main';

const DiscountCodeShow = ({ title }) => {
  const { codeID } = useParams();

  const code = useSelector(codeSelector);
  const fetchCode = useAction(discountCodesActions.fetchCode);
  const isPending = useSelector(isPendingSelector);

  useEffect(() => {
    fetchCode(codeID);
  }, [fetchCode, codeID]);

  if (isPending || code?.id !== Number(codeID)) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/discount-codes/${code?.id}/edit`}
        title={`Edit ${code?.coupon ? 'coupon code' : 'discount'}`}
        breadcrumbItems={[
          {
            title: 'Back to discounts',
            link: `/discount-codes/`,
            withSearch: `?is_coupon=${!!code?.coupon}`,
          },
        ]}
      />
      <Row>
        <Col md="12">
          <Main code={code} />
        </Col>
      </Row>
    </PageWrapper>
  );
};

DiscountCodeShow.propTypes = {
  title: PropTypes.string,
};

export default DiscountCodeShow;
