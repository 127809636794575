import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './DataTable.scss';
import ProductPresetsActionDropdown from '../ProductPresetsActionDropdown';

const DataTable = ({
  isPending,
  productsPresets,
  setProductsPresetID,
  setIsOpenConfirmationModal,
}) => {
  const handleDelete = id => {
    setIsOpenConfirmationModal(true);
    setProductsPresetID(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th data-priority="1">SKU</th>
          <th data-priority="1">Title</th>
          <th data-priority="1">Price</th>
          <th data-priority="1">Brand</th>
          <th data-priority="1">Warehouse</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {productsPresets?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item?.sku ?? '-'}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.price} {item.price_currency}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.brand_title}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.warehouse_title}
              </Link>
            </td>
            <td className={s.actionColumn}>
              <ProductPresetsActionDropdown
                onDelete={() => handleDelete(item.id)}
                product={item}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  productsPresets: PropTypes.array,
  setProductsPresetID: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
};

export default DataTable;
