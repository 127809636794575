import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import * as queryString from 'query-string';
import { selectStyles } from 'constants/selectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';
import { format } from 'date-fns';

import { TRANSFERS_TYPES } from 'constants';

const getNormalValues = value => {
  return value.map(val => ({
    ...val,
    value: val.id,
    label: `${val?.id} - ${
      TRANSFERS_TYPES[val?.resourcetype]
    } (${getInformation(val?.sender, val?.receiver)}, ${format(
      Date.parse(val?.created_at),
      'yyyy-MM-dd, h:mm aaa'
    )})`,
  }));
};

const getInformation = (sender, receiver) => {
  if (sender && receiver) {
    return `${sender.title} -> ${receiver.title})`;
  }
  if (sender) {
    return `sender: ${sender.title}`;
  }
  if (receiver) {
    return `receiver: ${receiver.title}`;
  }
  return '-';
};

const TransfersPaginatedField = ({
  onChange,
  isMulti,
  parameters,
  defaultValue,
  value,
  options,
  isInitialLabel,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchTransfers = async ({ page = 1, search }) => {
    const stringified = queryString.stringify(
      {
        page,
        title_contains: search,
        ...options,
      },
      {
        arrayFormat: 'comma',
      }
    );
    return apiRequest({
      url: `/transfers/?${stringified}`,
    });
  };

  const loadTransfers = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchTransfers({ page, search });
      const results = response?.results.map(item => ({
        value: item.id,
        label: `${item?.id} - ${
          TRANSFERS_TYPES[item?.resourcetype]
        } (${getInformation(item?.sender, item?.receiver)}, ${format(
          Date.parse(item?.created_at),
          'yyyy-MM-dd, h:mm aaa'
        )})`,
        ...item,
      }));
      return {
        options: isInitialLabel
          ? [
              {
                label: 'All transfers',
                value: 0,
              },
              ...results,
            ]
          : results,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="transfers"
      isSearchable
      debounceTimeout={1000}
      placeholder="Select transfer..."
      additional={{
        page: 1,
      }}
      defaultValue={value?.length ? getNormalValues(value) : value}
      loadOptions={loadTransfers}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

TransfersPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  value: PropTypes.any,
  options: PropTypes.object,
  parameters: PropTypes.object,
};

export default TransfersPaginatedField;
