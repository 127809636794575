import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EmailTemplateForm.scss';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import EmailTemplatePaginatedField from '../PaginatedFields/EmailTemplatePaginatedField';
import FileField from '../FileField';
import { getNormalText } from 'utils/getNormalText';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import WineclubsPaginatedField from '../PaginatedFields/WineclubsPaginatedField';
import { EMAIL_FIELD_TYPES } from 'constants/emailTemplates';
import TextField from '../Fields/TextField';
import EditorJodit from 'components/EditorJodit';

const getDataTypesList = data => {
  const typesList = {
    [EMAIL_FIELD_TYPES.LOGO]: [],
    [EMAIL_FIELD_TYPES.TEXT]: [],
    [EMAIL_FIELD_TYPES.INPUT]: [],
  };
  // eslint-disable-next-line no-restricted-syntax,guard-for-in,no-unused-vars
  for (const item in data) {
    const type = item.split('_')[0];
    if (type !== 'meta') {
      if ([EMAIL_FIELD_TYPES.LOGO, EMAIL_FIELD_TYPES.TEXT].includes(type)) {
        typesList[type].push(item);
      } else {
        typesList[EMAIL_FIELD_TYPES.INPUT].push(item);
      }
    }
  }
  return typesList;
};

const EmailTemplateForm = ({ onSubmit, onPreviewClick, defaultValues }) => {
  const [selectedWineClub, setSelectedWineClub] = useState(
    defaultValues?.wineClub ?? null
  );

  const [selectedTemplate, setSelectedTemplate] = useState(
    defaultValues?.emailTemplate ?? null
  );

  const [data, setData] = useState(defaultValues ?? {});
  const [title, setTitle] = useState(defaultValues?.title ?? '');

  const {
    [EMAIL_FIELD_TYPES.LOGO]: logoFields,
    [EMAIL_FIELD_TYPES.TEXT]: textFields,
    [EMAIL_FIELD_TYPES.INPUT]: inputFields,
  } = useMemo(() => getDataTypesList(selectedTemplate?.data ?? {}), [
    selectedTemplate,
  ]);

  const onDataChangeHandler = useCallback((key, value) => {
    setData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onSubmitHandler = useCallback(
    (_, values) => {
      onSubmit?.({
        email_template_id: selectedTemplate?.value,
        wineClubId: selectedWineClub?.value,
        ...values,
        ...data,
      });
    },
    [data, selectedTemplate, selectedWineClub]
  );

  const onPreviewClickHandler = useCallback(() => {
    onPreviewClick?.({
      email_template_id: selectedTemplate?.value,
      wineClubId: selectedWineClub?.value,
      title,
      ...data,
    });
  }, [data, selectedTemplate, title, selectedWineClub]);

  useEffect(() => {
    const templateData = selectedTemplate?.data;
    setData(() =>
      Object.keys(templateData ?? {}).reduce(
        (previous, current) => ({
          ...previous,
          [current]: templateData[current],
        }),
        {}
      )
    );
  }, [selectedTemplate]);

  return (
    <AvForm onValidSubmit={onSubmitHandler}>
      <Row className="mb-4">
        <Col md={3}>
          <FormGroup>
            <Label required>Wine Club</Label>
            <WineclubsPaginatedField
              value={selectedWineClub}
              onChange={setSelectedWineClub}
              isDisabled={!!defaultValues}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label required>Email template</Label>
            <EmailTemplatePaginatedField
              value={selectedTemplate}
              onChange={setSelectedTemplate}
              isDisabled={!!defaultValues}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <TextField
            label="Title"
            placeholder="Title"
            errorMessage="Enter Title"
            name="title"
            isRequired
            value={title}
            onChange={event => setTitle(event.target.value)}
          />
        </Col>
        {!!(selectedTemplate?.value && selectedWineClub?.id) && (
          <Col md={3}>
            <Button
              className={styles.previewButton}
              color="success"
              onClick={onPreviewClickHandler}
              disabled={!title && !selectedWineClub?.value}
            >
              Preview
            </Button>
          </Col>
        )}
      </Row>
      {!!(selectedWineClub?.id || selectedWineClub?.value) && (
        <>
          <Row>
            <Col md={12}>
              <div className={styles.filesWrapper}>
                {logoFields.map(key => (
                  <FileField
                    label={getNormalText(key)}
                    className={styles.fileField}
                    key={key}
                    onChange={file => onDataChangeHandler(key, file)}
                    value={data?.[key]}
                    accept="image/jpeg,image/png,image/jpg,image/gif"
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            {inputFields.map(key => (
              <Col key={key} md={3}>
                <TextField
                  name={key}
                  label={getNormalText(key)}
                  value={data?.[key]}
                  onChange={event =>
                    onDataChangeHandler(key, event.target.value)
                  }
                />
              </Col>
            ))}
          </Row>
          <Row>
            {textFields.map(key => (
              <Col key={key} md={6}>
                <FormGroup>
                  <Label for={key}>{getNormalText(key)}</Label>
                  <EditorJodit
                    id={key}
                    brandId={selectedWineClub?.brand_id}
                    placeholder={getNormalText(key)}
                    name={key}
                    defaultValue={selectedTemplate?.data?.[key]}
                    onChange={value => onDataChangeHandler(key, value)}
                  />
                </FormGroup>
              </Col>
            ))}
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Button
            disabled={!selectedTemplate?.value || !selectedWineClub?.value}
            color="primary"
            type="submit"
          >
            {defaultValues ? 'Save' : 'Create'}
          </Button>
        </Col>
      </Row>
    </AvForm>
  );
};

EmailTemplateForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onPreviewClick: PropTypes.func,
};
export default memo(EmailTemplateForm);
