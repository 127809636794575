import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const reportsSlice = createSlice({
  name: 'commissions',
  initialState: {
    isPending: false,
    isReportPending: false,
    isInvoicePending: false,
    commissions: [],
    disputedCommissionsCount: null,
    pagination: null,
    currentCommission: null,
  },
  reducers: {
    setCurrentCommission(state, { payload }) {
      state.currentCommission = payload;
    },
    fetchCommissions(state) {
      state.isPending = true;
    },
    fetchCommissionsSuccess(state, { payload }) {
      const { pagination, results } = payload.data;
      state.isPending = false;
      state.pagination = pagination;
      state.commissions = results;
    },
    fetchCommissionsFailure(state) {
      state.isPending = false;
    },
    fetchDisputedCommissions(state) {
      state.isPending = true;
    },
    fetchDisputedCommissionsSuccess(state, { payload }) {
      const { pagination } = payload.data;
      state.isPending = false;
      state.disputedCommissionsCount = pagination.total_count;
    },
    fetchDisputedCommissionsFailure(state) {
      state.isPending = false;
    },
    fetchCommissionById(state) {
      state.isPending = true;
    },
    fetchCommissionByIdSuccess(state, { payload }) {
      const { data } = payload;
      state.isPending = false;
      state.currentCommission = data;
    },
    fetchCommissionByIdFailure(state) {
      state.isPending = false;
    },
    updateCommission(state) {
      state.isPending = true;
    },
    updateCommissionSuccess(state, { payload }) {
      const { data } = payload;
      state.currentCommission = data;
      state.isPending = false;
    },
    updateCommissionFailure(state) {
      state.isPending = true;
    },
    fetchCommissionsReport(state) {
      state.isReportPending = true;
    },
    fetchCommissionsReportSuccess(state) {
      state.isReportPending = false;
    },
    fetchCommissionsReportFailure: state => {
      state.isReportPending = true;
    },
    fetchCommissionsInvoice(state) {
      state.isInvoicePending = true;
    },
    fetchCommissionsInvoiceSuccess(state) {
      state.isInvoicePending = false;
    },
    fetchCommissionsInvoiceFailure: state => {
      state.isInvoicePending = true;
    },
    fetchCommissionsReportById(state) {
      state.isInvoicePending = true;
    },
    fetchCommissionsReportByIdSuccess: state => {
      state.isInvoicePending = false;
    },
    fetchCommissionsReportByIdFailure: state => {
      state.isInvoicePending = true;
    },
  },
});

export const actions = actionTypes(reportsSlice.actions);

export default reportsSlice.reducer;
