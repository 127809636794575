import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const ActionDropdown = ({
  items,
  title,
  color,
  onItemClick,
  isDisabled,
  right,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    if (!isDisabled) {
      setDropdownOpen(prevState => !prevState);
    }
  };

  const onItemClickHandler = item => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <Dropdown disabled={isDisabled} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle disabled={isDisabled} color={color} caret end>
        {title} <i className="ri-arrow-down-s-line align-middle" />
      </DropdownToggle>
      <DropdownMenu right={right}>
        {items.map((item, index) => (
          <>
            {!item?.isHidden && (
              <DropdownItem
                onClick={() => onItemClickHandler(item)}
                key={index.toString()}
                href={item?.href}
              >
                {item?.icon}
                {item.label}
              </DropdownItem>
            )}
          </>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

ActionDropdown.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  right: PropTypes.bool,
};

ActionDropdown.defaultProps = {
  title: 'Actions',
  color: 'primary',
};
export default ActionDropdown;
