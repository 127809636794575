import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    isPending: false,
    brandId: null,
    images: [],
    image: {},
    pagination: {},
    shouldFormBeCleared: false,
  },
  reducers: {
    setBrandId: (state, { payload }) => {
      state.brandId = payload;
    },
    fetchImages: state => {
      state.isPending = true;
    },
    fetchImagesSuccess(state, { payload }) {
      state.isPending = false;
      state.images = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchImagesFailure: state => {
      state.isPending = false;
    },
    addImage: state => {
      state.isPending = true;
    },
    addImageSuccess(state, { payload }) {
      if (state.pagination.current_page === 1) {
        state.images = state.images.slice(0, state.pagination.per_page - 1);
        state.images.unshift(payload.data);
      }
      state.shouldFormBeCleared = true;
      state.isPending = false;
    },
    addImageFailure: state => {
      state.isPending = false;
    },
    resetShouldFormBeCleared: state => {
      state.shouldFormBeCleared = false;
    },
    deleteImage: state => {
      state.isPending = true;
    },
    deleteImageSuccess(state, { payload }) {
      state.images = state.images.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteImageFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(imagesSlice.actions);

export default imagesSlice.reducer;
