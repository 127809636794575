import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Button, Tooltip } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import Select from 'react-select';
import { DEVICE_TYPES } from 'constants';
import TastingRoomsPaginatedField from '../PaginatedFields/TastingRoomsPaginatedField';
import TextField from '../Fields/TextField';

const deviceOptions = DEVICE_TYPES.map(type => ({
  value: type,
  label: `${type.charAt(0).toUpperCase()}${type
    .toLowerCase()
    .substring(1, type.length)}`,
}));

const Form = ({ model, submitTitle, disabled, onSubmit }) => {
  const limit_char = 12; // Mac Address field accepts only 12 characters

  const [MacAddress, setMacAddress] = useState(null);
  const [isValidMacAddress, setIsValidMacAddress] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    model
      ? {
          value: model?.warehouse_id,
          label: model?.warehouse_title,
        }
      : null
  );
  const [selectedDevice, setSelectedDevice] = useState(
    model?.type
      ? deviceOptions.find(option => option.value === model.type)
      : null
  );
  const [selectedTastingRoom, setSelectedTastingRoom] = useState(
    model
      ? {
          value: model?.tasting_room_id,
          label: model?.tasting_room_title,
        }
      : null
  );

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleWarehouseChange = useCallback(data => {
    setSelectedWarehouse(data);
    setSelectedTastingRoom(null);
  }, []);
  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({
        id: model?.id,
        ...values,
        warehouse_id: selectedWarehouse?.value,
        type: selectedDevice?.value,
        tasting_room_id: selectedTastingRoom?.value,
      });
    },
    [onSubmit, selectedDevice, selectedWarehouse, selectedTastingRoom]
  );

  const buttonDisabled = useMemo(
    () =>
      disabled || !selectedDevice || !isValidMacAddress || !selectedWarehouse,
    [selectedDevice, isValidMacAddress, selectedWarehouse, disabled]
  );
  // Check whether the Mac Address is valid
  const handleMACAddressValidation = mac_address => {
    const pure_address = mac_address.replace(/\W/g, '');
    const [rebuilt_mac_address, length] = addColon(pure_address);
    setMacAddress(rebuilt_mac_address.toUpperCase());

    if (length === limit_char) {
      setIsValidMacAddress(true);
    } else {
      setIsValidMacAddress(false);
    }
  };
  // Adds a colon after each 2 characters
  const addColon = pure_address => {
    let rebuilt_string = '';
    pure_address = pure_address.slice(0, limit_char);
    const { length } = pure_address;

    pure_address.split('').forEach((letter, index) => {
      if (index % 2 !== 0 && length > 1 && index < limit_char - 1) {
        rebuilt_string += `${letter}:`;
      } else {
        rebuilt_string += letter;
      }
    });
    return [rebuilt_string, length];
  };
  // =================================================================================
  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="4">
          <TextField
            label="Device Name"
            name="device_name"
            placeholder="Device Name"
            errorMessage="Enter Device Name"
            isRequired
            validate={{ required: true }}
          />
        </Col>
        <Col md="4">
          <TextField
            id="Mac-Address"
            type="text"
            label="MAC Address"
            name="mac_address"
            placeholder="MAC Address"
            errorMessage="Enter MAC Address"
            isRequired
            validate={{ maxLength: { value: limit_char + 5 }, required: true }}
            value={MacAddress}
            onKeyDown={e => {
              const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
              if (!ALPHA_NUMERIC_REGEX.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={e => handleMACAddressValidation(e.target.value)}
          />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={false}
            target="Mac-Address"
            toggle={toggle}
          >
            MAC addresses follow this format, XX:XX:XX:XX:XX:XX
          </Tooltip>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label required htmlFor="warehouse_id">
              Warehouse
            </Label>
            <WarehousesPaginatedField
              name="warehouse_id"
              onChange={handleWarehouseChange}
              value={selectedWarehouse}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label required htmlFor="device_type">
              Device type
            </Label>
            <Select
              className="basic-single mr-1"
              classNamePrefix="select"
              value={selectedDevice}
              name="device_type"
              isSearchable={false}
              options={deviceOptions}
              onChange={setSelectedDevice}
              isDisabled={!!model}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label htmlFor="tasting_room_id">Tasting Room</Label>
            <TastingRoomsPaginatedField
              name="tasting_room_id"
              onChange={setSelectedTastingRoom}
              value={selectedTastingRoom}
              warehouseId={selectedWarehouse?.value}
              isDisabled={!selectedWarehouse?.value}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={buttonDisabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
};

export default Form;
