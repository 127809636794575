import { createSelector } from 'reselect';

export const rootSelector = state => state.timeEntry;

export const timeEntrySelector = createSelector(
  rootSelector,
  ({ timeEntry }) => timeEntry
);

export const timeEntryDetailsSelector = createSelector(
  rootSelector,
  ({ timeEntryDetails }) => timeEntryDetails
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);
