import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';
import DataTable from './DataTable';
import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import useSelector from 'hooks/useSelector';
import {
  orderSelector,
  ordersSelector,
  ordersIdsSelector,
  isReportPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
  paginationSelector,
  pendingFulfillmentAllOrdersSelector,
  pendingFulfillmentAllOrdersPaginationSelector,
} from 'models/orders/selectors';
import { ALL_ORDER_TYPES, ACTIONS_OPTIONS } from './constants';
import { ORDER_PAYMENT_OPTIONS } from 'constants';
import Filter from './Filter';
import SelectPaymentMethodPopup from 'components/Popup/SelectPaymentMethodPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import { actions as subscriptionOrdersActions } from 'models/subscriptionOrders/slice';

import s from './OrdersSummary.scss';
import { useDataPagination } from 'hooks/useDataPagination';

const OrdersSummary = ({ title }) => {
  const query = useQuery();
  const fetchOrders = useAction(ordersActions.fetchOrders);
  const fetchPendingFulfillmentAllOrders = useAction(
    ordersActions.fetchPendingFulfillmentAllOrders
  );
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const handleFetchOrderShipmentLabel = useAction(
    ordersActions.fetchOrderShipmentLabel
  );
  const handleFetchOrderShipmentPublicUrl = useAction(
    ordersActions.fetchOrderShipmentPublicUrl
  );
  const completeOrder = useAction(ordersActions.completeOrder);

  const orders = useSelector(ordersSelector);
  const pendingFulfillmentOrders = useSelector(
    pendingFulfillmentAllOrdersSelector
  );
  const ordersIds = useSelector(ordersIdsSelector);
  const fetchReport = useAction(ordersActions.fetchCommercialReport);
  const printFile = useAction(ordersActions.printFile);
  const ordersIsPending = useSelector(ordersIsPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const pagination = useSelector(paginationSelector);
  const pendingFulfillmentPagination = useSelector(
    pendingFulfillmentAllOrdersPaginationSelector
  );

  const {
    currentPage: fulfillmentPage,
    ...fulfillmentPagination
  } = useDataPagination(pendingFulfillmentPagination);

  const {
    currentPage,
    sortField,
    sortDirection,
    ...completedPagination
  } = useDataPagination(pagination, {
    currentPage: query.get('page'),
    sortField: query.get('sort_field'),
    sortDirection: query.get('sort_direction'),
  });

  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState({});

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [action, setAction] = useState(ACTIONS_OPTIONS[0]);
  const [showShipOrderModal, setShowShipOrderModal] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);

  const shipOrderDetails = useSelector(orderSelector);

  useEffect(() => {
    fetchOrders({
      setQuery: true,
      page: currentPage,
      sort_field: sortField,
      sort_direction: sortDirection,
      ...filter,
      excluded_statuses: ['PENDING_FULFILLMENT'],
      resourcetypes:
        filter.resourcetypes?.length > 0
          ? filter.resourcetypes
          : ALL_ORDER_TYPES.map(type => type.value),
      resourcetypes_labels:
        filter.resourcetypes?.length > 0
          ? filter.resourcetypes_labels
          : ALL_ORDER_TYPES.map(type => type.label),
    });
  }, [fetchOrders, currentPage, sortDirection, filter]);

  useEffect(() => {
    completedPagination.setCurrentPage(1);
    fulfillmentPagination.setCurrentPage(1);
    setSelectedOrders([]);
  }, [filter]);

  useEffect(() => {
    const { paid, ...filterMinusPaid } = filter;
    fetchPendingFulfillmentAllOrders({
      page: fulfillmentPage,
      ...filterMinusPaid,
      excluded_statuses: [],
      resourcetypes:
        filter.resourcetypes?.length > 0
          ? filter.resourcetypes
          : ALL_ORDER_TYPES.map(type => type.value),
    });
  }, [fulfillmentPage, filter]);

  const handleDownload = id => {
    fetchReport({ id });
  };

  const handleSelectAll = useCallback(
    event => {
      if (event.target.checked) {
        setSelectedOrders(ordersIds);
      } else {
        setSelectedOrders([]);
      }
    },
    [ordersIds]
  );

  const handlePrintPackageSlip = id => {
    printFile({ orders_ids: [id], packing_slip: true });
  };

  const handlePrintFile = useCallback(() => {
    const payload = {
      orders_ids: selectedOrders,
      packing_slip: action.value === 'both' || action.value === 'packing_slip',
      shipping_label:
        action.value === 'both' || action.value === 'shipping_label',
    };
    printFile(payload);
  });

  const openPaymentModal = orderId => {
    fetchOrder(orderId);
    setModal(true);
  };

  const openShipModal = orderId => {
    fetchOrder(orderId);
    setShowShipOrderModal(true);
  };

  const sendSubscriptionOrder = useAction(
    subscriptionOrdersActions.sendSubscriptionOrder
  );

  const onSendSubscriptionOrder = id => {
    sendSubscriptionOrder(id);
  };

  const selectedOrdersLength = selectedOrders?.length;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Filter
                isPending={ordersIsPending}
                filter={filter}
                setFilter={setFilter}
                onPrintClick={handlePrintFile}
                action={action}
                setAction={setAction}
                withActions={!!selectedOrdersLength}
              />
              <div className={s.subHeading}>Pending Fulfillment</div>
              {pendingFulfillmentOrders.length ? (
                <>
                  <PaginationTable
                    {...fulfillmentPagination}
                    pagination={pendingFulfillmentPagination}
                  >
                    <DataTable
                      isPending={ordersIsPending}
                      orders={pendingFulfillmentOrders}
                      onFulfill={id => {
                        completeOrder({ id, page: fulfillmentPage });
                      }}
                      paid={filter?.paid}
                      openShipModal={openShipModal}
                      onPrintPackageSlip={handlePrintPackageSlip}
                    />
                  </PaginationTable>
                </>
              ) : (
                <div className={s.infoText}>All Orders Fulfilled</div>
              )}
              <div className={s.header}>
                <div className={s.subHeading}>Completed</div>
                <div className={s.selectedInformation}>
                  {selectedOrdersLength
                    ? `${selectedOrdersLength} of ${
                        pagination.total_count
                      } orders
                  ${selectedOrdersLength === 1 ? 'is' : 'are'} selected`
                    : 'No selected orders'}
                </div>
              </div>
              <PaginationTable {...completedPagination} pagination={pagination}>
                <DataTable
                  {...completedPagination}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  isPending={ordersIsPending}
                  paid={filter?.paid}
                  openPaymentModal={openPaymentModal}
                  handleFetchOrderShipmentLabel={handleFetchOrderShipmentLabel}
                  handleFetchOrderShipmentPublicUrl={
                    handleFetchOrderShipmentPublicUrl
                  }
                  orders={orders}
                  areAllSelected={
                    selectedOrders.length === pagination.total_count
                  }
                  selectedOrders={selectedOrders}
                  updateSelectedOrders={setSelectedOrders}
                  selectAllOrders={handleSelectAll}
                  onDownload={handleDownload}
                  onPrintPackageSlip={handlePrintPackageSlip}
                  downloadDisabled={isReportPending}
                  sendSubscriptionOrder={sendSubscriptionOrder}
                  onSendSubscriptionOrder={onSendSubscriptionOrder}
                  orderType={filter?.resourcetypes}
                  isSortable
                />

                {modal && (
                  <SelectPaymentMethodPopup
                    isOpen={modal}
                    setIsOpen={setModal}
                    options={ORDER_PAYMENT_OPTIONS}
                  />
                )}

                {showShipOrderModal && (
                  <SelectShippingPopup
                    isOpen={showShipOrderModal}
                    setIsOpen={setShowShipOrderModal}
                    withFooter={false}
                    withTotal={false}
                    setIsChargeMode={setIsChargeMode}
                    isChargeMode={isChargeMode}
                    isOnlyShipping
                    order={shipOrderDetails}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

OrdersSummary.propTypes = {
  title: PropTypes.string,
};

export default OrdersSummary;
