import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Popover } from 'reactstrap';
import Datepicker from 'components/Datepicker';
import styles from './SalesDatesForm.scss';

const SalesDatesForm = ({
  onShowReport,
  onExport,
  onChange,
  value,
  label,
  pending,
  exportButtonText,
  isDownloadDisabled,
  calendarButtonText,
  children,
}) => {
  const [datepickerOpened, setDatepickerOpened] = useState(false);
  const toggleDatepickerOpened = () => setDatepickerOpened(!datepickerOpened);

  const handleShowReports = () => {
    toggleDatepickerOpened();
    if (onShowReport) onShowReport();
  };

  return (
    <Row>
      <Col md={9}>
        <div className="d-flex">
          <Button
            id="SalesDatesPicker"
            type="button"
            color="light"
            onClick={toggleDatepickerOpened}
          >
            <i className="ri-calendar-line mr-2 align-middle" />
            <span>{label}</span>
            <i className="ri-arrow-down-s-line ml-4 align-middle" />
          </Button>
          {children}
          <Popover
            placement="bottom-start"
            isOpen={datepickerOpened}
            target="SalesDatesPicker"
            toggle={toggleDatepickerOpened}
          >
            <div className={styles.calendar}>
              <Datepicker value={value} onChange={onChange} />
              <div className={`mt-3 ${styles.reportBtn}`}>
                <Button
                  type="submit"
                  color="success"
                  disabled={pending}
                  onClick={handleShowReports}
                  className="waves-effect waves-light text-nowrap"
                >
                  <i className="ri-line-chart-line align-middle mr-2" />
                  <span>{calendarButtonText}</span>
                </Button>
              </div>
            </div>
          </Popover>
        </div>
      </Col>
      <Col md={3}>
        <div className="d-flex h-100 w-100 justify-content-end align-items-end">
          <Button
            type="button"
            color="secondary"
            onClick={onExport}
            disabled={pending || isDownloadDisabled}
            className="waves-effect waves-light text-nowrap w-100"
          >
            <i className="ri-download-line align-middle mr-2" />
            <span>{exportButtonText}</span>
          </Button>
        </div>
      </Col>
    </Row>
  );
};

SalesDatesForm.propTypes = {
  onExport: PropTypes.func,
  onChange: PropTypes.func,
  onShowReport: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string,
  pending: PropTypes.bool,
  isDownloadDisabled: PropTypes.bool,
  calendarButtonText: PropTypes.string,
  exportButtonText: PropTypes.string,
  children: PropTypes.element,
};

SalesDatesForm.defaultProps = {
  onChange: null,
  exportButtonText: 'Export to Excel',
  calendarButtonText: 'Show report',
};

export default SalesDatesForm;
