import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Gallery.scss';
import { CustomInput } from 'reactstrap';

import cx from 'classnames';

const Item = ({ item, isSelected, onImageClick }) => {
  const handleOnClick = () => {
    onImageClick(item, !isSelected);
  };

  return (
    <div className={styles.galleryItem}>
      <div className={cx(styles.checkbox, 'custom-control custom-checkbox')}>
        <CustomInput
          type="checkbox"
          onChange={handleOnClick}
          checked={isSelected}
          id={item.id}
          name={item.id}
        />
      </div>
      <div className={styles.imageContainer} onClick={handleOnClick}>
        <img className={styles.image} src={item.image_url} alt={item.title} />
      </div>
      <div className={styles.imageTitle}>{item.title}</div>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  onImageClick: PropTypes.func,
};

export default memo(Item);
