import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { getFormData } from 'utils/getFormData';
import { generateURL } from 'utils/generateURL';
import { push } from 'connected-react-router';

const getMetaContentByName = (layout, name) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(layout, 'text/html');
  const meta = doc.querySelector(`meta[name="${name}"]`);
  return meta?.content;
};

function* fetchEmailTemplatesSaga(action) {
  const { wineclub_id } = action.payload;
  const { url } = yield generateURL({
    payload: action.payload,
    url: `wineclubs/${wineclub_id}/email_templates`,
    setQuery: true,
  });

  yield api({
    action,
    url,
    method: 'GET',
  });
}

function* fetchEmailTemplateByIdSaga(action) {
  const { wineClubId, templateId } = action.payload;
  yield api({
    action,
    url: `/wineclubs/${wineClubId}/email_templates/${templateId}`,
    method: 'GET',
  });
}

function* createEmailTemplateSaga(action) {
  const { search } = window.location;
  const { payload } = action;
  const data = getFormData(payload);

  yield api({
    action,
    url: `/wineclubs/${payload.wineClubId}/email_templates`,
    method: 'POST',
    data,
    successNavigateTo: `/email-templates/${search}`,
  });
}

function* emailTemplatePreviewSaga(action) {
  const { payload } = action;
  const data = getFormData(payload);
  data.append('preview', true);

  yield api({
    action,
    url: `/wineclubs/${payload.wineClubId}/email_templates`,
    method: 'POST',
    responseType: 'blob',
    data,
  });
}

function* existsEmailTemplatePreviewSaga(action) {
  const { wineClubId, templateId } = action.payload;

  yield api({
    action,
    url: `/wineclubs/${wineClubId}/email_templates/${templateId}/preview`,
    method: 'GET',
    responseType: 'blob',
  });
}

function* saveTemplateLayout({ payload }) {
  try {
    const { data, inResponseTo } = payload;
    const result = yield data.text();
    const templateId = getMetaContentByName(result, 'id');

    yield put({
      type: actions.setPreviewLayout,
      payload: {
        id: +templateId ?? null,
        wineClubId: inResponseTo?.wineClubId,
        data: result,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function* toggleDefaultEmailTemplateSaga(action) {
  const { wineClubId, templateId } = action.payload;

  yield api({
    action,
    url: `/wineclubs/${wineClubId}/email_templates/${templateId}/toggle_default`,
    method: 'POST',
  });
}

function* deleteEmailTemplateSaga(action) {
  const { wineClubId, templateId } = action.payload;

  yield api({
    action,
    url: `/wineclubs/${wineClubId}/email_templates/${templateId}`,
    method: 'DELETE',
  });
}

function* updateEmailTemplateSaga(action) {
  const { wineClubId, templateId, data } = action.payload;
  const formData = getFormData(data);

  yield api({
    action,
    url: `/wineclubs/${wineClubId}/email_templates/${templateId}`,
    data: formData,
    method: 'PATCH',
    responseType: data?.preview ? 'blob' : 'json',
  });
}

function* updateEmailTemplateSuccessSaga({ payload }) {
  const { preview } = payload.inResponseTo.data;
  if (preview) {
    yield saveTemplateLayout({ payload });
  } else {
    const { search } = window.location;
    yield put(push(`/email-templates/${search}`));
  }
}

function* fetchCustomEmailsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: `/email_templates`,
    setQuery: true,
  });

  yield api({
    action,
    url,
    method: 'GET',
  });
}

function* fetchCustomEmailByIdSaga(action) {
  yield api({
    action,
    url: `/email_templates/${action.payload}`,
    method: 'GET',
  });
}

function* createCustomEmailSaga(action) {
  const { search } = window.location;
  const data = getFormData(action.payload);

  yield api({
    action,
    url: '/email_templates',
    data,
    method: 'POST',
    successNavigateTo: `/email-templates/${search}`,
    successMessage: 'Custom email successfully created!',
  });
}

function* customEmailPreviewSaga(action) {
  const data = getFormData(action.payload);
  data.append('preview', true);

  yield api({
    action,
    url: '/email_templates',
    data,
    method: 'POST',
    responseType: 'blob',
  });
}

function* updateCustomEmailSaga(action) {
  const { id, data } = action.payload;
  const formData = getFormData(data);

  yield api({
    action,
    url: `/email_templates/${id}`,
    data: formData,
    method: 'PATCH',
    responseType: data?.preview ? 'blob' : 'json',
  });
}

function* updateCustomEmailSuccessSaga({ payload }) {
  const { preview } = payload.inResponseTo.data;
  if (preview) {
    yield saveTemplateLayout({ payload });
  } else {
    const { search } = window.location;
    yield put(push(`/email-templates/${search}`));
  }
}

function* deleteCustomEmailSaga(action) {
  yield api({
    action,
    url: `/email_templates/${action.payload}`,
    method: 'DELETE',
  });
}

function* existsCustomEmailPreviewSaga(action) {
  yield api({
    action,
    url: `/email_templates/${action.payload}/preview`,
    method: 'GET',
    responseType: 'blob',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchEmailTemplates, fetchEmailTemplatesSaga),
    takeLatest(actions.fetchEmailTemplateById, fetchEmailTemplateByIdSaga),
    takeLatest(actions.createEmailTemplate, createEmailTemplateSaga),
    takeLatest(actions.emailTemplatePreview, emailTemplatePreviewSaga),
    takeLatest(
      actions.existsEmailTemplatePreview,
      existsEmailTemplatePreviewSaga
    ),
    takeLatest(actions.emailTemplatePreviewSuccess, saveTemplateLayout),
    takeLatest(actions.existsEmailTemplatePreviewSuccess, saveTemplateLayout),
    takeLatest(
      actions.toggleDefaultEmailTemplate,
      toggleDefaultEmailTemplateSaga
    ),
    takeLatest(actions.deleteEmailTemplate, deleteEmailTemplateSaga),
    takeLatest(actions.updateEmailTemplate, updateEmailTemplateSaga),
    takeLatest(
      actions.updateEmailTemplateSuccess,
      updateEmailTemplateSuccessSaga
    ),
    takeLatest(actions.fetchCustomEmails, fetchCustomEmailsSaga),
    takeLatest(actions.fetchCustomEmailById, fetchCustomEmailByIdSaga),
    takeLatest(actions.createCustomEmail, createCustomEmailSaga),
    takeLatest(actions.customEmailPreview, customEmailPreviewSaga),
    takeLatest(actions.existsCustomEmailPreview, existsCustomEmailPreviewSaga),
    takeLatest(actions.customEmailPreviewSuccess, saveTemplateLayout),
    takeLatest(actions.existsCustomEmailPreviewSuccess, saveTemplateLayout),
    takeLatest(actions.deleteCustomEmail, deleteCustomEmailSaga),
    takeLatest(actions.updateCustomEmail, updateCustomEmailSaga),
    takeLatest(actions.updateCustomEmailSuccess, updateCustomEmailSuccessSaga),
  ]);
}
