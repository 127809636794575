import { createSelector } from 'reselect';

export const rootSelector = state => state.warehouses;

export const warehousesSelector = createSelector(
  rootSelector,
  ({ warehouses }) => warehouses
);

export const containerWorldInventoriesSelector = createSelector(
  rootSelector,
  ({ containerWorldInventories }) => containerWorldInventories
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const isWarehousePendingSelector = createSelector(
  rootSelector,
  ({ isWarehousePending }) => isWarehousePending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const currentWarehouseSelector = createSelector(
  rootSelector,
  ({ warehouse }) => warehouse
);

export const warehouseInventoriesPendingSelector = createSelector(
  rootSelector,
  ({ warehouseInventoriesPending }) => warehouseInventoriesPending
);

export const warehouseInventoriesPaginationSelector = createSelector(
  rootSelector,
  ({ warehouseInventoriesPagination }) => warehouseInventoriesPagination
);
export const warehouseInventoriesLastFetchedSelector = createSelector(
  rootSelector,
  ({ warehouseInventoriesLastFetched }) => warehouseInventoriesLastFetched
);

export const warehouseInventoriesSelector = createSelector(
  rootSelector,
  ({ warehouseInventories }) => warehouseInventories
);

export const inventoryLimitsSelector = createSelector(
  rootSelector,
  ({ inventoryLimits }) => inventoryLimits
);

export const inventoryLimitsPaginationSelector = createSelector(
  rootSelector,
  ({ inventoryLimitsPagination }) => inventoryLimitsPagination
);

export const inventoryLimitsPendingSelector = createSelector(
  rootSelector,
  ({ inventoryLimitsPending }) => inventoryLimitsPending
);

export const reportsInventorySelector = createSelector(
  rootSelector,
  ({ reportsInventory }) => reportsInventory
);

export const reportsInventoryPendingSelector = createSelector(
  rootSelector,
  ({ reportsInventoryPending }) => reportsInventoryPending
);

export const reportsInventoryPaginationSelector = createSelector(
  rootSelector,
  ({ reportsInventoryPagination }) => reportsInventoryPagination
);

export const reportsInventoryTotalBottlesSelector = createSelector(
  rootSelector,
  ({ reportsInventoryTotalBottles }) => reportsInventoryTotalBottles
);
