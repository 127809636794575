import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';

// Constants
const DropdownItemId = Object.freeze({
  REPLACE: 'change_contact_person',
  EDIT: 'edit',
});

const ContactPersonActionDropdown = ({ onReplaceContactPerson, onEdit }) => {
  // Handlers
  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.REPLACE]: onReplaceContactPerson,
      [DropdownItemId.EDIT]: onEdit,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  // Dropdown items
  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
    },
    {
      id: DropdownItemId.REPLACE,
      icon: (
        <i className="ri-user-unfollow-fill align-middle font-size-18 mr-2" />
      ),
      label: 'Replace Contact Person',
    },
  ];

  return (
    <ActionDropdown onItemClick={onItemClickHandler} items={dropdownItem} />
  );
};

ContactPersonActionDropdown.propTypes = {
  onReplaceContactPerson: PropTypes.func,
  onEdit: PropTypes.func,
};

export default ContactPersonActionDropdown;
