import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Card, CardBody } from 'reactstrap';
import PaginationTable from 'components/PaginationTable';
import styles from './Data.scss';

const Data = ({ isPending, data, setCurrentPage, pagination }) => {
  return (
    <Card>
      <CardBody>
        <PaginationTable
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        >
          <thead>
            <tr>
              <th data-priority="1">Interval</th>
              <th data-priority="4">Bottles Sold</th>
              <th data-priority="4">Taster Bottles</th>
              <th data-priority="2">Total</th>
              <th data-priority="2">Cases</th>
              <th data-priority="2">Glass Pours</th>
              <th data-priority="2">Oz.</th>
              <th data-priority="2">Bottles for Glass Pours</th>
              <th data-priority="2">Bottles for Tasting</th>
              <th data-priority="3">Ratio of Tasting to Sales</th>
            </tr>
          </thead>
          <tbody className={cx(isPending && styles.preloader)}>
            {(data || [])?.map(report => {
              const [startDate, endDate] = report.interval.split('--');
              return (
                <tr>
                  <td className={styles.cellBig}>
                    <div>{startDate}</div>
                    <div>{endDate}</div>
                  </td>
                  <td className={styles.cell}>
                    {report['Bottles sold'] || '0'}
                  </td>
                  <td className={styles.cell}>
                    {report['Taster bottles'] || '0'}
                  </td>
                  <td className={styles.cell}>{report.Total || '0'}</td>
                  <td className={styles.cell}>
                    {report.Cases.toFixed(2) || '0.00'}
                  </td>
                  <td className={styles.cell}>
                    {report['Glass Pours'] || '0'}
                  </td>
                  <td className={styles.cell}>
                    {report.oz.toFixed(2) || '0.00'}
                  </td>
                  <td className={styles.cell}>
                    {report['Bottles for glass pours'].toFixed(2) || '0.00'}
                  </td>
                  <td className={styles.cell}>
                    {report['Bottles for tasting'].toFixed(2) || '0.00'}
                  </td>
                  <td className={styles.cellBig}>
                    {report['Ratio of tasting to sales'].toFixed(2) || '0.00'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </PaginationTable>
      </CardBody>
    </Card>
  );
};

Data.propTypes = {
  isPending: PropTypes.bool,
  data: PropTypes.array,
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
};

export default Data;
