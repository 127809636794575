import { createSelector } from 'reselect';

export const rootSelector = state => state.wineDirectSync;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const wineDirectSyncSettingsSelector = createSelector(
  rootSelector,
  ({ wineDirectSyncSettings }) => wineDirectSyncSettings
);

export const wineDirectSyncNowSelector = createSelector(
  rootSelector,
  ({ wineDirectSyncNow }) => wineDirectSyncNow
);

export const wineDirectSyncsSelector = createSelector(
  rootSelector,
  ({ wineDirectSyncs }) => wineDirectSyncs
);

export const failedWineDirectSyncsSelector = createSelector(
  rootSelector,
  ({ failedWineDirectSyncs }) => failedWineDirectSyncs
);
