import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const QueryLink = ({ to, search, ...props }) => {
  // const { search: locSearch } = useLocation();

  return <Link to={`${to}${search ?? ''}`} {...props} />;
};

QueryLink.propTypes = {
  to: PropTypes.string.isRequired,
  search: PropTypes.string,
};
export default memo(QueryLink);
