import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import styles from './SalesStatCard.scss';

const SalesStatCard = ({ iconClassName, label, number, subLabel }) => {
  return (
    <Card className={styles.root}>
      <CardBody className={styles.inner}>
        <div className={styles.main}>
          <div className={styles.info}>
            <h4 className={styles.title}>{label}</h4>
            {!!subLabel && <h4 className={styles.title}>{subLabel}</h4>}
          </div>
          <div className={styles.icon}>
            <i className={iconClassName} />
          </div>
        </div>

        <h3 className={styles.number}>{number}</h3>
      </CardBody>
    </Card>
  );
};

SalesStatCard.propTypes = {
  label: PropTypes.string,
  number: PropTypes.string,
  iconClassName: PropTypes.string,
  subLabel: PropTypes.string,
};

export default SalesStatCard;
