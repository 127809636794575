import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DropdownButton from './DropdownButton';

import s from './DataTable.scss';

const DataTable = ({ isPending, exciseReturns, exciseLicense }) => {
  return (
    <>
      <thead>
        <tr>
          <th>Period Start</th>
          <th>Period End</th>
          <th>Due Date</th>
          <th>Wine more than 7%</th>
          <th>Wine 1.2% to 7%</th>
          <th>Wine not more than 1.2%</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {exciseReturns?.map(item => {
          return (
            <tr key={item.id}>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.period_start}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.period_end}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.due_date}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.seven_percent_return_detail?.closing_inventory}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.one_percent_return_detail?.closing_inventory}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item.zero_percent_return_detail?.closing_inventory}
                </Link>
              </td>
              <td>
                <DropdownButton
                  viewHref={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  exciseReturns: PropTypes.any,
  isPending: PropTypes.bool,
  exciseLicense: PropTypes.object.isRequired,
};

export default DataTable;
