import styles from './AppUpdate.scss';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { Button } from 'reactstrap';

const getManifestVersion = () =>
  fetch('/manifest.json', { cache: 'no-cache' })
    .then(response => response.json())
    .then(manifest => manifest?.version);

const AppUpdate = () => {
  const [manifestVersion, setManifestVersion] = useState();
  const [updating, setUpdating] = useState(false);
  // Reload page if manifest version has changed
  const checkManifest = () =>
    getManifestVersion().then(currentManifestVersion => {
      if (updating) return;
      if (!manifestVersion) {
        setManifestVersion(currentManifestVersion);
      } else if (currentManifestVersion !== manifestVersion) {
        store.addNotification({
          title: '✨ Update Available!',
          message: (
            <>
              <p>
                A new version of Cellar is available. Click the button below to
                update.
              </p>
              <div className={styles.updateButton}>
                <Button
                  onClick={() => window.location.reload()}
                  color="success"
                >
                  Update
                </Button>
              </div>
            </>
          ),
          type: 'warning',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 0,
            onScreen: true,
            pauseOnHover: false,
            waitForAnimation: false,
            click: false,
            touch: false,
            showIcon: false,
          },
        });
        setUpdating(true);
      }
    });
  const location = useLocation();
  useEffect(() => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1')
      return {};
    checkManifest();
    const interval = setInterval(() => checkManifest(), 15000);
    return () => {
      clearInterval(interval);
    };
  }, [location, manifestVersion, updating]);
  return <></>;
};

export default AppUpdate;
