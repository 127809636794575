import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { COMPANY_TYPES } from 'constants';
import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as organizationsActions } from 'models/organizations/slice';

import DataTable from './DataTable';

import {
  organizationsSelector,
  isPendingSelector,
  paginationSelector,
} from 'models/organizations/selectors';

const OrganizationsSummary = ({ title }) => {
  const fetchOrganizations = useAction(organizationsActions.fetchOrganizations);

  const organizations = useSelector(organizationsSelector);
  const pending = useSelector(isPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'desc'
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchOrganizations({
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        ...filters,
        content_contains: filters.title_contains,
        setQuery: true,
      });
    }
  }, [filters, fetchOrganizations, currentPage, sortDirection, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortDirection, sortField]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    disabled={pending}
                    hasCreateItem
                    createItemLink="/commercial-customers/create"
                    hasFilterByCompanyType
                    companyTypeOptions={COMPANY_TYPES}
                    placeholder="Search... (By title, license number, contact name, contact email)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={pending}
                  organizations={organizations}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

OrganizationsSummary.propTypes = {
  title: PropTypes.string,
};

export default OrganizationsSummary;
