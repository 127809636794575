import React from 'react';
import PropTypes from 'prop-types';
import styles from './Info.scss';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { prepareAddress } from 'utils/prepareAddress';
import { NavLink } from 'react-router-dom';

const InfoCard = ({ iconClassName, value }) => (
  <div className={styles.cardItem}>
    <i className={iconClassName} />
    <p>{value || '-'}</p>
  </div>
);

const Info = ({ title, id, phone, email, link, address, contactPerson }) => {
  return (
    <Row xs="1">
      <Col>
        <Card>
          <CardBody>
            <div className={styles.cardHeader}>
              <div className={styles.info}>
                {title && <p className={styles.customer}>{title}</p>}
                {id && <p className={styles.customerId}>#{id}</p>}
              </div>
              {!!link && <NavLink to={link}>VIEW</NavLink>}
            </div>
            <div className={styles.cardBody}>
              {contactPerson && (
                <InfoCard
                  iconClassName="ri-user-3-fill"
                  value={
                    <>
                      {contactPerson?.first_name || ''}{' '}
                      {contactPerson?.last_name || ''}
                    </>
                  }
                />
              )}
              {!!email && (
                <InfoCard iconClassName="ri-mail-line" value={email} />
              )}
              {!!phone && (
                <InfoCard iconClassName="ri-phone-line" value={phone} />
              )}
              {!!address && (
                <InfoCard
                  iconClassName="ri-map-pin-line"
                  value={prepareAddress(address)}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  contactPerson: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.object,
};

InfoCard.propTypes = {
  iconClassName: PropTypes.string,
  value: PropTypes.string,
};

export default Info;
