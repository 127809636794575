export const getProductTitleWithVintage = (title, vintage) => {
  const vintageText = vintage ? ` (${vintage})` : '';
  return `${title}${vintageText}`;
};

export const getProductTitleWithSKUVintage = (title, sku, vintage) => {
  const skuText = sku ? `${sku} - ` : '';
  const vintageText = vintage ? ` - ${vintage}` : '';
  return `${skuText}${title}${vintageText}`;
};
