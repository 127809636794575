import React from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import DiscountCodeForm from 'components/DiscountCodeForm';
import useAction from 'hooks/useAction';
import { actions } from 'models/discounts/slice';
import useSelector from 'hooks/useSelector';
import * as discountCodeSelectors from 'models/discounts/selectors';
import DiscountForm from 'components/DiscountForm';

const CreateDiscountCode = ({ title, isDiscountCode }) => {
  const createCode = useAction(actions.createCode);
  const discountCodeIsPending = useSelector(
    discountCodeSelectors.isPendingSelector
  );

  const onSubmit = data => {
    createCode(data);
  };

  if (discountCodeIsPending) {
    return <Preloader />;
  }

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          {
            title: 'Back to discounts',
            link: `/discount-codes/?is_coupon=${isDiscountCode}`,
          },
        ]}
      />
      {isDiscountCode ? (
        <DiscountCodeForm onSubmit={onSubmit} />
      ) : (
        <DiscountForm onSubmit={onSubmit} />
      )}
    </PageWrapper>
  );
};

CreateDiscountCode.propTypes = {
  title: PropTypes.string.isRequired,
  isDiscountCode: PropTypes.bool,
};
export default CreateDiscountCode;
