const production = require('./production').default;
const staging = require('./staging').default;
const testing = require('./testing').default;
const demo = require('./demo').default;

const configs = {
  production,
  staging,
  testing,
  demo,
};

export default {
  port: process.env.PORT,
  baseUrl: 'https://dev.getcellar.co/core-api',
  // baseUrl: 'http://localhost:8000/core-api',
  dashboardUrl: 'https://pos.dev.getcellar.co/',
  googleMapKey: process.env.GOOGLE_API_KEY,
  defaultLocale: 'en',
  htmlMinifier: {
    collapseWhitespace: true,
    removeComments: true,
    trimCustomFragments: true,
    minifyCSS: true,
    minifyJS: true,
    minifyURLs: true,
  },
  mailer: {
    from: 'Mailgun <me@samples.mailgun.org>',
    to: 'alan.hadzaragov@saltpepper.ru',
    domain: 'sandbox46deb311677e43eaac79065c1e310df2.mailgun.org',
    list: 'test-list',
  },
  app: {
    htmlAttributes: { lang: 'en' },
    title: 'Cellar | DTC Winery eCommerce & Point-of-Sale',
    link: [
      {
        rel: 'shortcut icon',
        href: '/favicon.png',
      },
    ],
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
      },
      {
        name: 'description',
        content:
          'Cellar is a new, experiential platform for modern tasting rooms and wine clubs',
      },
    ],
  },
  ...configs[process.env.APP_ENV],
};
