import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip, Button } from 'reactstrap';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';
import FileInput from '../../../components/FileField/FileInput';

const DataTable = ({
  isPending,
  isPendingImport,
  brands,
  sortField,
  setBrandId,
  openImportModal,
  setIsOpenConfirmationModal,
  sortDirection,
  setSortField,
  setSortDirection,
  brandsForImport,
  onImportFromFile,
  importedFromFileBrands,
}) => {
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsOpenConfirmationModal(true);
    setBrandId(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'created_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Created at
            <SortArrows
              isActive={sortField === 'created_at'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Logo</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="1"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {brands?.map(item => {
          const importable = brandsForImport.includes(item.id);
          return (
            <tr key={item.id}>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/brands/${item.id}/edit`}
                >
                  {item.id}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/brands/${item.id}/edit`}
                >
                  {item.created_at
                    ? format(
                        Date.parse(item.created_at),
                        'yyyy-MM-dd, h:mm aaa'
                      )
                    : ''}
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/brands/${item.id}/edit`}
                >
                  <img
                    src={item.mobile_logo_v_200x200_url}
                    alt=""
                    height="60"
                  />
                </Link>
              </td>
              <td>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/brands/${item.id}/edit`}
                >
                  {item.title}
                </Link>
              </td>
              <td>
                <>
                  <Link
                    to={`/brands/${item.id}/edit`}
                    className="mr-3 text-primary"
                    id={`edit${item.id}`}
                  >
                    <i className="mdi mdi-pencil font-size-18" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${item.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                  <Link
                    to="#"
                    className="text-danger"
                    id={`delete${item.id}`}
                    onClick={e => handleDelete(e, item.id)}
                    style={{ display: 'None' }}
                  >
                    <i className="mdi mdi-trash-can font-size-18" />
                  </Link>

                  <UncontrolledTooltip
                    placement="top"
                    target={`delete${item.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                  {false && importable && (
                    <Button
                      className="ml-2"
                      color="primary"
                      disabled={isPendingImport}
                      onClick={() => openImportModal(item.id)}
                    >
                      Import
                    </Button>
                  )}
                  {false && importedFromFileBrands.indexOf(item.id) === -1 && (
                    <FileInput
                      accept="text/csv"
                      onAddFile={files => {
                        onImportFromFile(files[0], item.id);
                      }}
                      multiple={false}
                      label=""
                      buttonLabel="Import from file"
                      className={s.fileInput}
                    />
                  )}
                </>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  brandsForImport: PropTypes.any,
  sortField: PropTypes.string,
  isPending: PropTypes.bool,
  isPendingImport: PropTypes.bool,
  brands: PropTypes.array,
  importedFromFileBrands: PropTypes.array,
  setBrandId: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  openImportModal: PropTypes.func,
  onImportFromFile: PropTypes.func,
};

export default DataTable;
