import React from 'react';
import PropTypes from 'prop-types';
import WineReturnShowForm from '../Wine/WineReturnShowForm';
import UserReturnShowForm from '../User/UserReturnShowForm';
import WarehouseReturnShowForm from '../Warehouse/WarehouseReturnShowForm';

const ReturnShowForm = props => (
  <>
    {props.licenseType === 'wine' && (
      <WineReturnShowForm data={props.data} licenseType={props.licenseType} />
    )}
    {props.licenseType === ('user' || 'USER') && (
      <UserReturnShowForm
        data={props.data}
        licenseType={props.licenseType.toLowerCase()}
      />
    )}
    {props.licenseType === 'warehouse' && (
      <WarehouseReturnShowForm
        data={props.data}
        licenseType={props.licenseType}
      />
    )}
  </>
);

ReturnShowForm.propTypes = {
  licenseType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ReturnShowForm;
