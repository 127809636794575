import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions as inventoriesActions } from '../inventories/slice';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { formatDate } from 'utils/formatDate';

export function* fetchTransfersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/transfers/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchInProgressTransfersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/transfers/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchInProgressTransfersNotificationSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/transfers/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
    setURL: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchCompletedTransfersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/transfers/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: false,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchTransferSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/transfers/${action.payload}`,
  });
}

export function* createTransferSaga(action) {
  const request = {
    action,
    method: 'post',
    url: '/transfers/',
    data: {
      ...action.payload,
      comment:
        action.payload.resourcetype === 'WarehouseToWarehouseTransfer'
          ? `CREATED AT ${formatDate(new Date())}: ${action.payload.comment}`
          : action.payload.comment,
    },
    successMessage: 'Successfully created new transfer!',
  };
  if (!action.payload.redirect) {
    request.successNavigateTo = `/transfers/`;
  } else {
    request.successNavigateTo = action.payload.redirect;
  }
  yield api(request);
}

function* createTransferSagaSuccess() {
  yield put({ type: actions.resetQuantities });
  yield put({ type: inventoriesActions.resetInventories });
}

export function* updateTransferSaga(action) {
  yield api({
    action,
    method: 'put',
    url: `/transfers/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated transfer!',
  });
}

export function* deleteTransferSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/transfers/${action.payload}`,
    successMessage: 'Successfully deleted transfer!',
  });
}

export function* approveTransferSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/transfers/approve`,
    data: {
      ...action.payload,
      comment: `APPROVED ${formatDate(new Date())}: ${action.payload.comment}`,
    },
    successMessage: 'Successfully approved transfer!',
    successNavigateTo: '/transfers/',
  });
}

export function* disputeTransferSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/transfers/dispute`,
    data: {
      ...action.payload,
      disputing_manager_comment: `DISPUTED ${formatDate(new Date())}: ${
        action.payload.disputing_manager_comment
      }`,
    },
    successMessage: 'Successfully disputed transfer.',
    successNavigateTo: '/transfers/',
  });
}

export function* resubmitTransferSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/transfers/resubmit`,
    data: {
      ...action.payload,
      comment: `UPDATED ${formatDate(new Date())}: ${action.payload.comment}`,
    },
    successMessage: 'Successfully disputed transfer.',
    successNavigateTo: '/transfers/',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTransfers, fetchTransfersSaga),
    takeLatest(actions.fetchInProgressTransfers, fetchInProgressTransfersSaga),
    takeLatest(
      actions.fetchInProgressTransfersNotification,
      fetchInProgressTransfersNotificationSaga
    ),
    takeLatest(actions.fetchCompletedTransfers, fetchCompletedTransfersSaga),
    takeLatest(actions.fetchTransfer, fetchTransferSaga),
    takeLatest(actions.createTransfer, createTransferSaga),
    takeLatest(actions.createTransferSuccess, createTransferSagaSuccess),
    takeLatest(actions.deleteTransfer, deleteTransferSaga),
    takeLatest(actions.updateTransfer, updateTransferSaga),
    takeLatest(actions.approveTransfer, approveTransferSaga),
    takeLatest(actions.disputeTransfer, disputeTransferSaga),
    takeLatest(actions.resubmitTransfer, resubmitTransferSaga),
  ]);
}
