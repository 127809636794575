import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import * as queryString from 'query-string';

const WinePairingsPaginatedField = ({ onChange, brandId, pairings }) => {
  const request = useApiRequest();

  const fetchPairings = ({ page = 1, search }) => {
    const parameters = queryString.stringify({
      page,
      resourcetype: 'Wine',
      title_contains: search,
      brand_id: brandId,
    });
    return request({
      url: `/products/?${parameters}`,
    });
  };

  const loadPairings = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchPairings({ page, search });
      return {
        options: response?.results.map(item => ({
          value: item.id,
          label: item.title,
        })),
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      name="pairings"
      cacheUniqs={[brandId]}
      isSearchable
      debounceTimeout={1000}
      isMulti
      additional={{
        page: 1,
      }}
      loadOptions={loadPairings}
      value={pairings}
      onChange={onChange}
    />
  );
};

WinePairingsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  brandId: PropTypes.number,
  pairings: PropTypes.array,
};

export default WinePairingsPaginatedField;
