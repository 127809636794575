import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/AwardsForm';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as awardsActions } from 'models/awards/slice';
import { awardSelector, isPendingSelector } from 'models/awards/selectors';

const AwardEdit = ({ title }) => {
  const { awardID } = useParams();
  const award = useSelector(awardSelector);
  const isPending = useSelector(isPendingSelector);
  const updateAward = useAction(awardsActions.updateAward);
  const fetchAward = useAction(awardsActions.fetchAward);

  useEffect(() => {
    fetchAward(awardID);
  }, [fetchAward, awardID]);

  if (isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={award?.title}
        breadcrumbItems={[
          { title: 'Back to awards', link: '/awards/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Save"
            onSubmit={updateAward}
            model={award}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

AwardEdit.propTypes = {
  title: PropTypes.string,
};

export default AwardEdit;
