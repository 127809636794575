import React from 'react';
import cx from 'classnames';
import s from './DataTable.scss';
import { Link } from 'react-router-dom';
import DropdownButton from './DropdownButton';
import PropTypes from 'prop-types';
import { DATE_FIELDS } from '../../ExciseReturn/Form/User/constants';

const DataTable = ({
  isPending,
  exciseReturns,
  exciseLicense,
  tableColumns,
}) => {
  return (
    <>
      <thead>
        <tr>
          {DATE_FIELDS.map((column, index) => (
            <th key={index}>{column.label}</th>
          ))}
          {tableColumns.map((column, index) => (
            <th key={index}>{column.label}</th>
          ))}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {exciseReturns?.map(item => (
          <tr key={item.id}>
            {DATE_FIELDS.map((column, index) => (
              <td key={index}>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item[column.key]}
                </Link>
              </td>
            ))}
            {tableColumns.map((column, index) => (
              <td key={index}>
                <Link
                  className="text-dark font-weight-bold"
                  to={`/reports/excise/license/${
                    exciseLicense.id
                  }/return/${exciseLicense.license_type.toLowerCase()}/${
                    item.id
                  }`}
                >
                  {item[`${column.key}_return_detail`]?.closing_inventory}
                </Link>
              </td>
            ))}
            <td>
              <DropdownButton
                viewHref={`/reports/excise/license/${
                  exciseLicense.id
                }/return/${exciseLicense.license_type.toLowerCase()}/${
                  item.id
                }`}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  exciseReturns: PropTypes.any,
  isPending: PropTypes.bool,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  exciseLicense: PropTypes.object.isRequired,
};

export default DataTable;
