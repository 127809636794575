import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/TastingRoomForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentDidMount from 'hooks/useComponentDidMount';

import { actions as tastingRoomsActions } from 'models/tastingRooms/slice';
import { isPendingSelector as tastingRoomsIsPendingSelector } from 'models/tastingRooms/selectors';
import {
  warehousesSelector,
  isPendingSelector as warehousesIsPendingSelector,
} from 'models/warehouses/selectors';
import { actions as warehousesActions } from 'models/warehouses/slice';

const CreateTastingRoom = ({ title }) => {
  const fetchWarehouses = useAction(warehousesActions.fetchWarehouses);

  const tastingRoomsIsPending = useSelector(tastingRoomsIsPendingSelector);
  const warehousesIsPending = useSelector(warehousesIsPendingSelector);
  const warehouses = useSelector(warehousesSelector);

  const [currentWarehouseID, setCurrentWarehouseID] = useState(
    warehouses?.[0]?.id
  );
  const isPending = tastingRoomsIsPending || warehousesIsPending;
  const createTastingRoom = useAction(tastingRoomsActions.createTastingRoom);

  useComponentDidMount(() => {
    fetchWarehouses();
  });

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create New Room"
        breadcrumbItems={[
          { title: 'Back to rooms', link: '/tasting_rooms/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            warehouses={warehouses}
            disabled={isPending}
            submitTitle="Save"
            currentWarehouse={currentWarehouseID || warehouses?.[0]?.id}
            setCurrentWarehouse={setCurrentWarehouseID}
            onSubmit={createTastingRoom}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateTastingRoom.propTypes = {
  title: PropTypes.string,
};

export default CreateTastingRoom;
