import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/SubscriptionOrderForm';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import {
  orderSelector,
  isPendingSelector as ordersIsPendingSelector,
} from 'models/orders/selectors';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as subscriptionOrdersActions } from 'models/subscriptionOrders/slice';

const SubscriptionOrderEdit = ({ title }) => {
  const { orderID } = useParams();

  const fetchOrder = useAction(ordersActions.fetchOrder);

  const orderIsPending = useSelector(ordersIsPendingSelector);
  const order = useSelector(orderSelector);

  const isPending = orderIsPending;
  const updateSubscriptionOrder = useAction(
    subscriptionOrdersActions.updateSubscriptionOrder
  );

  const location = useLocation();
  const previousPath = location.state?.previousPath || '/orders/';

  useEffect(() => {
    fetchOrder(orderID);
  }, [orderID]);

  if (isPending || isEmpty(order)) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={`Edit Subscription Order #${order?.id}`}
        breadcrumbItems={[{ title: 'Back to orders', link: previousPath }]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Save"
            order={order}
            onSubmit={updateSubscriptionOrder}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

SubscriptionOrderEdit.propTypes = {
  title: PropTypes.string,
};

export default SubscriptionOrderEdit;
