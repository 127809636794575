import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import classNames from 'classnames';
import PopupBase from '../PopupBase';
import styles from './ConfirmationSendEmailPopup.scss';
import { Link } from 'react-router-dom';

const ErrorTypes = Object.freeze({
  USER_CONFIRMED:
    'User is already confirmed. Please login with existed credentials or reset the password',
  TOKEN_EXPIRED: 'Token is expired',
});

const ConfirmationSendEmailPopup = ({
  isPending,
  errorMessage,
  handleConfirm,
}) => {
  return (
    <PopupBase>
      <div className={styles.popup}>
        {isPending && <Spinner className={styles.spinner} variant="primary" />}
        {!!errorMessage && !isPending && (
          <>
            <p className={classNames(styles.popupText, styles.errorText)}>
              {errorMessage}
            </p>
            {errorMessage === ErrorTypes.USER_CONFIRMED ? (
              <Link to="/login">
                <Button color="primary mt-3">Go to Login</Button>
              </Link>
            ) : (
              <Button color="primary" onClick={handleConfirm}>
                Send Email
              </Button>
            )}
          </>
        )}
      </div>
    </PopupBase>
  );
};

ConfirmationSendEmailPopup.propTypes = {
  isPending: PropTypes.bool,
  handleConfirm: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default memo(ConfirmationSendEmailPopup);
