import React, { useEffect, useState } from 'react';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import Breadcrumbs from 'components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import {
  wineCategoriesSelector,
  isPendingSelector,
} from 'models/products/selectors';
import useSelector from 'hooks/useSelector';
import Preloader from 'components/Preloader';
import ProductCategoryForm from 'components/ProductCategoryForm';

const EditWineCategory = ({ title }) => {
  const { id } = useParams();
  const updateCategory = useAction(productsActions.updateProductCategory);
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const isPending = useSelector(isPendingSelector);
  const wineCategories = useSelector(wineCategoriesSelector);
  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    if (!wineCategories) {
      fetchCategories();
    }
  }, [fetchCategories, wineCategories]);

  useEffect(() => {
    if (wineCategories && id) {
      setCurrentCategory(wineCategories?.[id]);
    }
  }, [wineCategories, id]);

  const handleSubmit = values => {
    updateCategory({
      ...values,
      id,
      redirectUrl: '/wine-categories/',
      withSearch: true,
    });
  };

  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={currentCategory?.title ?? ''}
        breadcrumbItems={[
          { title: 'Back to categories', link: '/wine-categories/' },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {currentCategory && (
        <Row>
          <Col md={12}>
            <ProductCategoryForm
              submitTitle="Save"
              onSubmit={handleSubmit}
              model={currentCategory}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditWineCategory.propTypes = {
  title: PropTypes.string.isRequired,
};
export default EditWineCategory;
