import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Col, Row, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import {
  inventoryManualCountingByIdSelector,
  inventoryManualCountingPendingSelector,
} from 'models/inventories/selectors';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions } from 'models/inventories/slice';
import CardItem from 'components/CardItem';
import { formatDate } from 'utils/formatDate';
import { INVENTORY_STATUSES } from 'constants/inventory';
import InventoryResultsTable from 'components/InventoryResultsTable';
import { isOnlyHostSelector } from 'models/user/selectors';
import { showErrorMessage } from 'utils/notification';
import { inventoryChanged } from 'utils/inventoryChange';
import InventoryExciseModal from '../../components/InventoryExciseModal';
import styles from './InventoryCountShow.scss';

const PersonInfo = props => {
  const {
    title,
    first_name,
    last_name,
    email,
    comment,
    isEditable,
    editableManagersComment,
    setEditableManagersComment,
  } = props;

  const name =
    first_name && last_name ? `${first_name} ${last_name}` : undefined;

  const commentComponent = <CardItem title="Comment" value={comment || '-'} />;

  const editableCommentComponent = (
    <Card>
      <CardBody>
        <FormGroup>
          <Label for="managers_comment">
            <h4 className="card-title">Comment</h4>
          </Label>
          <Input
            id="managers_comment"
            name="managers_comment"
            type="textarea"
            value={editableManagersComment}
            onChange={event => setEditableManagersComment(event.target.value)}
          />
        </FormGroup>
      </CardBody>
    </Card>
  );

  return (
    <div>
      <h4 className="card-title mb-3">{title}</h4>
      <Row>
        <Col md={4}>
          <CardItem title="Name" value={name} />
        </Col>
        <Col md={4}>
          <CardItem title="Email" value={email} />
        </Col>
        <Col md={4}>
          {isEditable ? editableCommentComponent : commentComponent}
        </Col>
      </Row>
    </div>
  );
};

const InventoryCountShow = ({ title }) => {
  const { id } = useParams();
  const [editableManagersComment, setEditableManagersComment] = useState('');

  const [inventoryResults, setInventoryResults] = useState(
    inventoryManualCounting?.inventory_results
  );

  const [isDataChanged, setIsDataChanged] = useState(false);
  const fetchInventoryManualCountingById = useAction(
    actions.fetchInventoryManualCountingById
  );

  const approveInventoryManualCounting = useAction(
    actions.approveInventoryManualCounting
  );

  const inventoryManualCounting = useSelector(
    inventoryManualCountingByIdSelector(id)
  );

  const loading = useSelector(inventoryManualCountingPendingSelector);
  const isHost = useSelector(isOnlyHostSelector);

  const isTableEditing =
    inventoryManualCounting?.status === INVENTORY_STATUSES.INITIALIZED &&
    !isHost;

  const isFullViewing =
    inventoryManualCounting?.status === INVENTORY_STATUSES.REJECTED ||
    inventoryManualCounting?.status === INVENTORY_STATUSES.COMPLETED ||
    (isHost &&
      typeof inventoryManualCounting?.inventory_results?.[0]
        ?.real_product_id === 'number');

  const isViewing =
    inventoryManualCounting?.status === INVENTORY_STATUSES.REJECTED ||
    inventoryManualCounting?.status === INVENTORY_STATUSES.COMPLETED ||
    isHost;

  const getDifferentData = (inventory, changedData, key) => {
    const preparedData = [];
    inventory.forEach(prevValue => {
      const { product_id } = prevValue;
      const real_data = changedData?.[product_id]
        ? Object.keys(changedData[product_id]).reduce(
            (prev, cur) => ({
              ...prev,
              [`${key}${cur}`]: changedData[product_id][cur],
            }),
            {}
          )
        : {};
      preparedData.push({
        ...prevValue,
        ...real_data,
      });
    });
    return preparedData;
  };

  const warehouseIsExcise =
    inventoryManualCounting?.warehouse?.is_used_as_excise_warehouse;

  const [showExciseModal, setShowExciseModal] = useState(false);

  const [exciseResults, setExciseResults] = useState({});

  const [allReasonsSelected, setAllReasonsSelected] = useState(false);

  const processApproval = modalSubmit => {
    if (
      !editableManagersComment &&
      inventoryChanged(
        inventoryResults,
        inventoryManualCounting.inventory_results
      )
    ) {
      showErrorMessage(
        'Comment missing.',
        'Please enter a comment addressing the difference in inventory.'
      );
      return;
    }
    if (
      inventoryChanged(
        inventoryResults,
        inventoryManualCounting.inventory_results
      ) &&
      warehouseIsExcise
    ) {
      const exciseReasonResult = {};
      const incomingResults = {
        ...inventoryResults,
      };
      Object.keys(incomingResults).forEach(productId => {
        const result = incomingResults[productId];
        const originalResult = inventoryManualCounting.inventory_results.find(
          item => item.product_id === parseInt(productId, 10)
        );
        if (originalResult) {
          const exciseDifference =
            result.product_count - originalResult.product_count;
          if (exciseDifference !== 0) {
            exciseReasonResult[productId] = {
              product_title: result.product_title,
              difference: exciseDifference,
            };
          }
        }
      });

      setExciseResults(oldExciseResults => {
        return {
          ...oldExciseResults,
          ...exciseReasonResult,
        };
      });
      setShowExciseModal(true);
      setIsDataChanged(true);
    }

    const payload = isDataChanged
      ? {
          id,
          inventory_results: getDifferentData(
            inventoryManualCounting.inventory_results,
            inventoryResults,
            inventoryManualCounting.count_type === 'Taster' ? '' : 'real_'
          ),
          managers_comment: editableManagersComment,
        }
      : { id };

    if (warehouseIsExcise && allReasonsSelected && modalSubmit) {
      const exciseInventoryResults = isDataChanged
        ? payload.inventory_results
        : inventoryManualCounting.inventory_results;
      const updatedInventoryResults = exciseInventoryResults.map(result => {
        if (exciseResults[result.product_id]) {
          return {
            ...result,
            excise_reason: exciseResults[result.product_id].exciseReason,
          };
        }
        return result;
      });

      const payloadWithExciseReasons = {
        ...payload,
        inventory_results: updatedInventoryResults,
      };

      approveInventoryManualCounting(payloadWithExciseReasons);
    }

    if (
      warehouseIsExcise &&
      !inventoryChanged(
        inventoryResults,
        inventoryManualCounting.inventory_results
      )
    ) {
      approveInventoryManualCounting(payload);
    }

    if (!warehouseIsExcise) {
      approveInventoryManualCounting(payload);
    }
  };

  const onChangeInventoryData = data => {
    setIsDataChanged(true);
    setInventoryResults(data);
  };

  useEffect(() => {
    fetchInventoryManualCountingById(id);
  }, []);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          {
            title: 'Back to Inventory Count',
            link: '/inventory-count/',
            withSearch: true,
          },
        ]}
      />
      {inventoryManualCounting && (
        <>
          <Row>
            <Col md={3}>
              <CardItem
                title="Brand"
                value={inventoryManualCounting?.warehouse?.brand_title}
              />
            </Col>
            <Col md={3}>
              <CardItem
                title="Warehouse"
                value={inventoryManualCounting?.warehouse?.title}
              />
            </Col>
            <Col md={3}>
              <CardItem
                title="Created at"
                value={formatDate(inventoryManualCounting?.created_at)}
              />
            </Col>
            <Col md={3}>
              <CardItem
                title="Status"
                value={
                  <span
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      inventoryManualCounting?.status ===
                      INVENTORY_STATUSES.REJECTED
                        ? styles.redText
                        : inventoryManualCounting?.status ===
                          INVENTORY_STATUSES.INITIALIZED
                        ? styles.blueText
                        : styles.greenText
                    }
                  >
                    {inventoryManualCounting?.status}
                  </span>
                }
              />
            </Col>
          </Row>
          <PersonInfo
            title="Counted By"
            {...inventoryManualCounting.operator}
            comment={inventoryManualCounting.comment}
            isEditable={false}
            editableManagersComment={editableManagersComment}
            setEditableManagersComment={setEditableManagersComment}
          />
          <PersonInfo
            title="Approving Manager"
            {...inventoryManualCounting.controller}
            comment={inventoryManualCounting.managers_comment}
            isEditable={isTableEditing}
            editableManagersComment={editableManagersComment}
            setEditableManagersComment={setEditableManagersComment}
          />
          {inventoryManualCounting?.inventory_results &&
            inventoryManualCounting?.warehouse?.id && (
              <Row>
                <Col md={12}>
                  <InventoryResultsTable
                    data={inventoryManualCounting.inventory_results}
                    isViewing={isViewing}
                    isFullViewing={isFullViewing}
                    isEditing={isTableEditing}
                    onChangeData={onChangeInventoryData}
                    onApprove={() => processApproval(false)}
                    loading={loading}
                    inventoryCountType={inventoryManualCounting.count_type}
                  />
                </Col>
              </Row>
            )}
          {showExciseModal && (
            <InventoryExciseModal
              isOpen={showExciseModal}
              setIsOpen={setShowExciseModal}
              exciseResults={exciseResults}
              setExciseResults={setExciseResults}
              allReasonsSelected={allReasonsSelected}
              setAllReasonsSelected={setAllReasonsSelected}
              processApproval={() => processApproval(true)}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

PersonInfo.propTypes = {
  title: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  comment: PropTypes.string,
  isEditable: PropTypes.bool,
  editableManagersComment: PropTypes.string.isRequired,
  setEditableManagersComment: PropTypes.func.isRequired,
};

InventoryCountShow.propTypes = {
  title: PropTypes.string.isRequired,
};

export default InventoryCountShow;
