import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { selectStyles } from 'constants/selectStyles';
import { getPreparedCustomerOption } from 'utils/getPreparedCustomerOption';
import * as queryString from 'query-string';

const initialLabel = {
  value: undefined,
  label: 'All customers',
};

const CustomerPaginatedField = ({
  onChange,
  isMulti,
  value,
  isInitialLabel,
  warehouseId,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchCustomers = ({ page = 1, search }) => {
    const stringified = queryString.stringify(
      {
        page,
        archived: false,
        customer_info_contains: search,
        warehouse_id: warehouseId,
      },
      {
        arrayFormat: 'comma',
      }
    );
    return apiRequest({
      url: `/customers/?${stringified}`,
    });
  };

  const loadCustomers = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchCustomers({ page, search });
      const options = response?.results.map(getPreparedCustomerOption);
      return {
        options:
          isInitialLabel && page === 1 ? [initialLabel, ...options] : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="brands"
      isSearchable
      debounceTimeout={1000}
      value={value || ''}
      placeholder="Select customer..."
      additional={{
        page: 1,
      }}
      cacheUniqs={[warehouseId]}
      loadOptions={loadCustomers}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

CustomerPaginatedField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  isMulti: PropTypes.bool,
  isInitialLabel: PropTypes.bool,
  warehouseId: PropTypes.number,
};

export default CustomerPaginatedField;
