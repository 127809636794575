import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import TerminalForm from 'components/TerminalForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentDidMount from 'hooks/useComponentDidMount';

import { actions as warehousesActions } from 'models/warehouses/slice';
import {
  warehousesSelector,
  isPendingSelector as warehousesIsPendingSelector,
} from 'models/warehouses/selectors';
import {
  terminalSelector,
  isPendingSelector as terminalsIsPendingSelector,
} from 'models/terminals/selectors';
import { actions as terminalsActions } from 'models/terminals/slice';
import { getRedactedValues } from 'utils/getRedactedValues';

const TerminalCreate = ({ title }) => {
  const { terminalID } = useParams();

  const terminal = useSelector(terminalSelector);
  const warehouses = useSelector(warehousesSelector);
  const terminalPending = useSelector(terminalsIsPendingSelector);
  const warehousesPending = useSelector(warehousesIsPendingSelector);

  const fetchTerminal = useAction(terminalsActions.fetchTerminal);
  const updateTerminal = useAction(terminalsActions.updateTerminal);
  const fetchWarehouses = useAction(warehousesActions.fetchWarehouses);

  const [currentWarehouseId, setCurrentWarehouseId] = useState(null);
  const isPending = warehousesPending || terminalPending;

  useEffect(() => {
    fetchTerminal(terminalID);
  }, [fetchTerminal, terminalID]);

  useComponentDidMount(() => {
    fetchWarehouses();
  });

  useEffect(() => {
    setCurrentWarehouseId(terminal.warehouse_id);
  }, [terminal]);

  const onSubmit = values => {
    updateTerminal({
      id: parseInt(terminalID, 10),
      ...getRedactedValues(terminal, values),
    });
  };

  if (isPending) return null;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Update terminal"
        breadcrumbItems={[
          { title: 'Back to terminals', link: '/terminals/', withSearch: true },
        ]}
      />
      {terminal && (
        <Row>
          <Col md="12">
            <TerminalForm
              model={terminal}
              onSubmit={onSubmit}
              disabled={isPending}
              warehouses={warehouses}
              submitTitle="Save"
              currentWarehouseId={currentWarehouseId}
              setCurrentWarehouseId={setCurrentWarehouseId}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

TerminalCreate.propTypes = {
  title: PropTypes.string,
};

export default TerminalCreate;
