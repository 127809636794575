import React, { useMemo, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FoodForm.scss';
import { Col, Label, Row, FormGroup, Button } from 'reactstrap';
import { WEIGHT_UNIT } from 'constants';
import { isNil, omitBy } from 'lodash';
import AdditionsPaginatedField from '../AdditionsPaginatedField';
import WinePairingsPaginatedField from '../WinePairingsPaginatedField';
import { useCategoriesOptions } from 'hooks/useCategoriesOptions';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import SelectField from '../SelectField/SelectField';
import TextField from '../Fields/TextField';
import NumberField from '../Fields/NumberField';
import TextareaField from '../Fields/TextareaField';
import CheckboxField from '../Fields/CheckboxField';

const constructSelectOptions = options =>
  options
    ? [...options?.map(option => ({ value: option.id, label: option.title }))]
    : [];

const FoodForm = ({
  model,
  submitTitle,
  disabled,
  onSubmit,
  categories,
  resourcetype,
}) => {
  const {
    selectedBrand,
    selectedCategories,
    brandError,
    setBrandTouched,
    onBrandChange,
    onCategoriesChange,
  } = useCategoriesOptions({
    defaultValues: { brand: model?.brand, categories: model?.categories ?? [] },
  });
  const [label, setLabel] = React.useState('');
  const [mainImage, setMainImage] = React.useState('');
  const [isRemoveLabel, setIsRemoveLabel] = React.useState(false);
  const [isRemoveMainImage, setIsRemoveMainImage] = React.useState(false);
  const [foodPairings, setFoodPairings] = React.useState(
    constructSelectOptions(model?.wine_pairings)
  );
  const [foodAdditions, setFoodAdditions] = React.useState(
    constructSelectOptions(model?.food_additions)
  );
  const [weightUnit, setWeightUnit] = useState(
    WEIGHT_UNIT.find(({ value }) => value === model?.weight_unit) ||
      WEIGHT_UNIT[0]
  );

  const availableCategories = useMemo(() => {
    return categories?.filter(
      category => category.brand_id === selectedBrand?.value
    );
  }, [categories, selectedBrand]);

  const handleSubmit = (event, values) => {
    const newValues = omitBy(values, isNil);
    const foodPairingsList = foodPairings.map(pairing => pairing.value);
    const foodAdditionsList = foodAdditions.map(addition => addition.value);
    setBrandTouched(true);

    const data = {
      ...newValues,
      resourcetype: resourcetype?.value || 'Food',
      weight_unit: weightUnit?.value,
      brand_id: selectedBrand?.value,
      food_additions: newValues.addition ? [] : foodAdditionsList,
      categories: selectedCategories.map(({ id }) => id),
      wine_pairings: foodPairingsList,
    };

    if (!selectedBrand) return;

    if (label || isRemoveLabel) {
      data.label = label;
    }
    if (mainImage || isRemoveMainImage) {
      data.main_image = mainImage;
    }

    onSubmit({
      id: model?.id,
      ...data,
    });
  };

  const handleError = () => {
    setBrandTouched(true);
  };

  return (
    <>
      <AvForm
        model={model}
        className="needs-validation"
        onValidSubmit={handleSubmit}
        onInvalidSubmit={handleError}
      >
        <Row>
          <Col md="3">
            <TextField
              isRequired
              name="title"
              label="Title"
              placeholder="Title"
              errorMessage="Enter Title"
            />
          </Col>
          <Col md="3">
            <TextField
              isRequired
              label="Price"
              name="price"
              errorMessage="Enter Price"
              rules={{
                pattern: {
                  value: '^[0-9.]+$',
                  errorMessage: 'Only positive number',
                },
              }}
            />
          </Col>
          <Col md="3">
            <FormGroup>
              <Label required htmlFor="brand_id">
                Brand
              </Label>
              <BrandsPaginatedField
                name="brand_id"
                value={selectedBrand}
                onChange={onBrandChange}
                onBlur={() => setBrandTouched(true)}
                isMulti={false}
              />
              {brandError && (
                <small className="text-danger">Required field</small>
              )}
            </FormGroup>
          </Col>
          <Col md="3">
            <TextField
              isRequired
              name="sku"
              label="SKU"
              value={model?.sku}
              placeholder="SKU"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="3">
            <FormGroup>
              <SelectField
                name="categories"
                options={availableCategories}
                label="Categories"
                onChange={onCategoriesChange}
                value={selectedCategories}
                multiple
                isDisabled={!selectedBrand}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label htmlFor="wine_pairings">Wine Pairings</Label>
              <WinePairingsPaginatedField
                onChange={setFoodPairings}
                brandId={selectedBrand?.value}
                pairings={foodPairings}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label htmlFor="food_additions">Additions</Label>
              <AdditionsPaginatedField
                disabled={!selectedBrand?.value}
                onChange={setFoodAdditions}
                brandId={selectedBrand?.value}
                additions={foodAdditions}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <NumberField
              name="calorific"
              label="Calorific"
              value={model?.calorific}
            />
          </Col>
          <Col md="3">
            <NumberField
              name="weight_value"
              label="Weight"
              value={model?.weight_value}
            />
          </Col>
          <Col md="3">
            <FormGroup>
              <SelectField
                name="weight_unit"
                options={WEIGHT_UNIT}
                label="Weight Unit"
                value={weightUnit}
                onChange={setWeightUnit}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <TextField
              name="alias_title"
              label="POS Title"
              value={model?.alias_title}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg="12">
            <TextareaField
              name="description"
              label="Description"
              value={model?.description ?? ''}
            />
          </Col>
          <Col md="3">
            <div
              className={classNames(
                'custom-control custom-checkbox',
                styles.checkbox
              )}
            >
              <CheckboxField name="addition" label="Is Addition?" />
            </div>
          </Col>
          <Col md="3">
            <div
              className={classNames(
                'custom-control custom-checkbox',
                styles.checkbox
              )}
            >
              <CheckboxField
                name="is_visible_on_website"
                label="Is Visible on Website"
              />
            </div>
          </Col>
          <Col md="3">
            <CheckboxField
              name="is_visible_on_pos"
              label="Is Visible on POS"
              defaultValue={model?.is_visible_on_pos ?? true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor="main_image">Main Image</Label>
              <div>
                {!isRemoveMainImage && model?.main_image_url && (
                  <img
                    className="mr-2"
                    src={model.main_image_url}
                    alt=""
                    height="100"
                  />
                )}
                <input
                  name="main_image"
                  type="file"
                  id="main_image"
                  accept="image/jpeg,image/png"
                  onChange={evt => setMainImage(evt.target.files[0])}
                />
                {!isRemoveMainImage && model?.main_image_url && (
                  <Button
                    onClick={() => setIsRemoveMainImage(true)}
                    style={{
                      backgroundColor: '#ff7e03',
                      borderColor: '#ff7e03',
                    }}
                  >
                    Remove main image
                  </Button>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor="label">Label</Label>
              <div>
                {!isRemoveLabel && model?.label_v_300x300_url && (
                  <img
                    className="mr-2"
                    src={model.label_v_300x300_url}
                    alt=""
                    height="60"
                  />
                )}
                <input
                  name="label"
                  type="file"
                  id="label"
                  accept="image/jpeg,image/png"
                  onChange={evt => setLabel(evt.target.files[0])}
                />
                {!isRemoveLabel && model?.label_v_300x300_url && (
                  <Button
                    onClick={() => setIsRemoveLabel(true)}
                    style={{
                      backgroundColor: '#ff7e03',
                      borderColor: '#ff7e03',
                    }}
                  >
                    Remove label
                  </Button>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </AvForm>
    </>
  );
};

FoodForm.propTypes = {
  model: PropTypes.object,
  submitTitle: PropTypes.string,
  disabled: PropTypes.bool,
  categories: PropTypes.array,
  resourcetype: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default FoodForm;
