import React, { useMemo, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import SelectField from 'components/SelectField/SelectField';
import { WEIGHT_UNIT } from 'constants';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import { useCategoriesOptions } from 'hooks/useCategoriesOptions';
import TextField from 'components/Fields/TextField';
import NumberField from 'components/Fields/NumberField';
import TextareaField from 'components/Fields/TextareaField';
import CheckboxField from 'components/Fields/CheckboxField';

const MerchForm = ({ categories, onSubmit, model, submitTitle }) => {
  const {
    selectedBrand,
    selectedCategories,
    brandError,
    setBrandTouched,
    onBrandChange,
    onCategoriesChange,
  } = useCategoriesOptions({
    defaultValues: { brand: model?.brand, categories: model?.categories ?? [] },
  });
  const [weightUnit, setWeightUnit] = useState(WEIGHT_UNIT[0]);
  const [label, setLabel] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [isRemoveLabel, setIsRemoveLabel] = useState(false);
  const [isRemoveMainImage, setIsRemoveMainImage] = useState(false);

  const availableCategories = useMemo(() => {
    return categories?.filter(
      category => category.brand_id === selectedBrand?.value
    );
  }, [categories, selectedBrand]);

  const handleSubmit = (_, data) => {
    setBrandTouched(true);
    if (selectedBrand) {
      if (label || isRemoveLabel) {
        data.label = label;
      }
      if (mainImage || isRemoveMainImage) {
        data.main_image = mainImage;
      }

      onSubmit({
        id: model?.id,
        ...data,
        brand_id: selectedBrand?.value,
        categories: selectedCategories.map(item => item.id),
        weight_unit: weightUnit?.value,
      });
    }
  };

  const handleError = () => {
    setBrandTouched(true);
  };

  return (
    <AvForm
      className="needs-validation"
      onInvalidSubmit={handleError}
      onValidSubmit={handleSubmit}
      model={model}
    >
      <Row>
        <Col md="3">
          <TextField
            isRequired
            name="title"
            label="Title"
            placeholder="Title"
            errorMessage="Enter Title"
          />
        </Col>
        <Col md="3">
          <NumberField
            isRequired
            name="price"
            label="Price"
            errorMessage="Enter Price"
            rules={{
              pattern: {
                value: '^[0-9.]+$',
                errorMessage: 'Only positive number',
              },
            }}
          />
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>Brand*</Label>
            <BrandsPaginatedField
              name="brand_id"
              value={selectedBrand}
              onChange={onBrandChange}
              onBlur={() => setBrandTouched(true)}
              isMulti={false}
            />
            {brandError && (
              <small className="text-danger">Required field</small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <TextField name="sku" isRequired label="SKU" placeholder="SKU" />
        </Col>
        <Col md="3">
          <FormGroup>
            <SelectField
              name="categories"
              options={availableCategories}
              label="Categories"
              onChange={onCategoriesChange}
              defaultValue={selectedCategories}
              multiple
              isDisabled={!selectedBrand}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <NumberField name="weight_value" label="Weight" />
        </Col>
        <Col md="3">
          <FormGroup>
            <SelectField
              name="weight_unit"
              label="Weight Unit"
              options={WEIGHT_UNIT}
              onChange={setWeightUnit}
              defaultValue={weightUnit}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <TextField
            name="alias_title"
            label="POS Title"
            value={model?.alias_title}
          />
        </Col>
        <Col md="3">
          <CheckboxField
            name="is_visible_on_pos"
            label="Is Visible on POS"
            defaultValue={model?.is_visible_on_pos ?? true}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col lg="12">
          <TextareaField name="description" label="Description" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label htmlFor="label">Label</Label>
            <div>
              {!isRemoveLabel && model?.label_v_300x300_url && (
                <img
                  className="mr-2"
                  src={model.label_v_300x300_url}
                  alt=""
                  height="60"
                />
              )}
              <input
                name="label"
                type="file"
                id="label"
                accept="image/jpeg,image/png"
                onChange={evt => setLabel(evt.target.files[0])}
              />
              {!isRemoveLabel && model?.label_v_300x300_url && (
                <Button
                  onClick={() => setIsRemoveLabel(true)}
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                >
                  Remove label
                </Button>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label htmlFor="main_image">Main Image</Label>
            <div>
              {!isRemoveMainImage && model?.main_image_url && (
                <img
                  className="mr-2"
                  src={model.main_image_url}
                  alt=""
                  height="100"
                />
              )}
              <input
                name="main_image"
                type="file"
                id="main_image"
                accept="image/jpeg,image/png"
                onChange={evt => setMainImage(evt.target.files[0])}
              />
              {!isRemoveMainImage && model?.main_image_url && (
                <Button
                  onClick={() => setIsRemoveMainImage(true)}
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                >
                  Remove main image
                </Button>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" type="submit">
        {submitTitle}
      </Button>
    </AvForm>
  );
};

MerchForm.propTypes = {
  model: PropTypes.object,
  categories: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
};

export default MerchForm;
