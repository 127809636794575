import React from 'react';
import PropTypes from 'prop-types';
import styles from './FileField.scss';
import classNames from 'classnames';
import { Button } from 'reactstrap';

const FilePreview = ({ preview, onDelete }) => {
  return (
    <div className={styles.previewContainer}>
      <img
        alt="preview"
        src={preview?.name ? URL.createObjectURL(preview) : preview}
        className={classNames(styles.preview, 'mb-2')}
      />
      {!!onDelete && (
        <Button color="danger" onClick={onDelete} type="button">
          Remove image
        </Button>
      )}
    </div>
  );
};

FilePreview.propTypes = {
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDelete: PropTypes.func,
};

export default FilePreview;
