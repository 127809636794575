import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import styles from './DataTable.scss';

const DataTable = ({
  isPending,
  tiers,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
}) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'updated_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Updated at
            <SortArrows
              isActive={sortField === 'updated_at'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
            data-priority="3"
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="1">Wineclub</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'capacity',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="3"
          >
            Bottles
            <SortArrows
              isActive={sortField === 'capacity'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Active</th>
          <th data-priority="3">Period in Months</th>
          <th data-priority="3">Active Customers</th>
        </tr>
      </thead>
      <tbody className={classNames(isPending && styles.preloader)}>
        {tiers?.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>
              {item.updated_at
                ? format(Date.parse(item.updated_at), 'yyyy-MM-dd, h:mm aaa')
                : ''}
            </td>
            <td>{item.title}</td>
            <td> {item.wineclub_title} </td>
            <td> {item.capacity} </td>
            <td> {item.is_active ? 'Yes' : 'No'} </td>
            <td> {item.period_in_months} </td>
            <td> {item.active_customers_count} </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  tiers: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};

export default DataTable;
