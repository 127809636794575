import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { getPreparedProductCategories } from 'utils/getPreparedProductCategories';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    isPending: false,
    isCreating: false,
    isImagesPending: false,
    imageFetchingSuccess: null,
    productCountsPending: false,
    products: [],
    productsOptions: [],
    product: {},
    productImages: {
      gallery: [],
      composite: [],
    },
    pagination: {},
    foodAllergies: [],
    foodAllergy: {},
    foodAllergyPagination: {},
    productsCategories: [],
    productCategory: {},
    productCounts: {},
  },
  reducers: {
    fetchProductsOptions: state => {
      state.isPending = true;
    },
    fetchProductsOptionsSuccess(state, { payload }) {
      state.isPending = false;
      state.productsOptions = payload.data.results;
    },

    fetchProductsOptionsFailure: state => {
      state.isPending = false;
    },

    fetchProducts: state => {
      state.isPending = true;
    },
    fetchProductsSuccess(state, { payload }) {
      state.isPending = false;
      state.products = payload.data.results;
      state.pagination = payload.data.pagination;
    },

    fetchProductsFailure: state => {
      state.isPending = false;
    },
    fetchProduct: state => {
      state.isPending = true;
    },
    fetchProductSuccess(state, { payload }) {
      state.isPending = false;
      state.product = payload.data;
    },
    fetchProductFailure: state => {
      state.isPending = false;
    },
    fetchProductImages: state => {
      state.isImagesPending = true;
    },
    fetchProductImagesSuccess(state, { payload }) {
      state.isImagesPending = false;
      state.productImages.gallery = payload.data.filter(
        item => item.type === 'gallery'
      );
      state.productImages.composite = payload.data.filter(
        item => item.type === 'composite'
      );
    },
    fetchProductImagesFailure: state => {
      state.isImagesPending = false;
    },
    setImages(state, { payload }) {
      state.productImages[payload.type] = payload.images;
    },
    createProductImage: state => {
      state.isImagesPending = true;
      state.imageFetchingSuccess = undefined;
    },
    createProductImageSuccess(state, { payload }) {
      state.isImagesPending = false;
      state.productImages[payload.data.type].push(payload.data);
      state.imageFetchingSuccess = true;
    },
    createProductImageFailure: state => {
      state.isImagesPending = false;
    },
    swapProductImages: state => {
      state.isImagesPending = true;
    },
    swapProductImagesSuccess: state => {
      state.isImagesPending = false;
    },
    swapProductImagesFailure: state => {
      state.isImagesPending = false;
    },
    patchProductImage: state => {
      state.isImagesPending = true;
      state.imageFetchingSuccess = undefined;
    },
    patchProductImageSuccess(state, { payload }) {
      state.isImagesPending = true;
      state.imageFetchingSuccess = true;
      const idx = state.productImages[payload.data.type].findIndex(
        image => image.id === payload.data.id
      );
      state.productImages[payload.data.type][idx] = payload.data;
    },
    patchProductImageFailure: state => {
      state.isImagesPending = true;
    },
    deleteProductImage: state => {
      state.isImagesPending = true;
    },
    deleteProductImageSuccess(state, { payload }) {
      state.isImagesPending = true;
      state.productImages[payload.inResponseTo.type] = state.productImages[
        payload.inResponseTo.type
      ].filter(image => image.id !== payload.inResponseTo.id);
    },
    deleteProductImageFailure: state => {
      state.isImagesPending = true;
    },
    createProduct: state => {
      state.isCreating = true;
    },
    createProductSuccess(state, { payload }) {
      state.products = state.products.concat(payload.data);
      state.isCreating = false;
    },
    createProductFailure: state => {
      state.isCreating = false;
    },
    updateProduct: state => {
      state.isCreating = true;
    },
    updateProductSuccess(state, { payload }) {
      const { data } = payload;
      state.product = data;
      state.products = state.products.map(item =>
        item.id === data.id ? data : item
      );
      state.isCreating = false;
    },
    updateProductFailure: state => {
      state.isCreating = false;
    },
    fetchFoodAllergies: state => {
      state.isPending = true;
    },
    fetchFoodAllergiesSuccess(state, { payload }) {
      state.isPending = false;
      state.foodAllergies = payload.data.results;
      state.foodAllergyPagination = payload.data.pagination;
    },
    fetchFoodAllergiesFailure(state) {
      state.isPending = false;
    },
    fetchFoodAllergy: state => {
      state.isPending = true;
    },
    fetchFoodAllergySuccess(state, { payload }) {
      state.isPending = false;
      state.foodAllergy = payload.data;
    },
    fetchFoodAllergyFailure: state => {
      state.isPending = false;
    },
    updateFoodAllergy: state => {
      state.isPending = true;
    },
    updateFoodAllergySuccess(state, { payload }) {
      state.isPending = false;
      if (state.foodAllergies.length !== 0) {
        state.foodAllergies.map(item => {
          if (item.id !== payload.inResponseTo.id) {
            return item;
          }
          return {
            ...payload.data,
          };
        });
      }
    },
    updateFoodAllergyFailure(state) {
      state.isPending = false;
    },
    createFoodAllergy(state) {
      state.isPending = true;
    },
    createFoodAllergySuccess(state, { payload }) {
      state.isPending = false;
      state.foodAllergies.push(payload);
    },
    createFoodAllergyFailure(state) {
      state.isPending = false;
    },
    fetchProductsCategories: state => {
      state.isPending = true;
    },
    fetchProductsCategoriesSuccess(state, { payload }) {
      state.isPending = false;
      state.productsCategories = getPreparedProductCategories(payload.data);
    },
    fetchProductsCategoriesFailure(state) {
      state.isPending = false;
    },
    fetchProductCategory: state => {
      state.isPending = true;
    },
    fetchProductCategorySuccess(state, { payload }) {
      state.isPending = false;
      state.productCategory = payload.data;
    },
    fetchProductCategoryFailure(state) {
      state.isPending = false;
    },
    createProductCategory(state) {
      state.isPending = true;
    },
    createProductCategorySuccess(state) {
      state.isPending = false;
    },
    createProductCategoryFailure(state) {
      state.isPending = false;
    },
    updateProductCategory: state => {
      state.isPending = true;
    },
    updateProductCategorySuccess(state, { payload }) {
      state.isPending = false;
      const { data } = payload;
      const categoryIndex = state.productsCategories.findIndex(
        item => item.id === data.id
      );
      if (categoryIndex !== -1) {
        // Update main category
        state.productsCategories[categoryIndex] = { ...data };
      } else if (data.parent) {
        // Update subcategory
        const parentIndex = state.productsCategories.findIndex(
          item => item.id === data.parent.id
        );
        if (parentIndex !== -1) {
          state.productsCategories[parentIndex].sub_categories[data.id] = {
            ...data,
          };
        }
      }
    },
    updateProductCategoryFailure(state) {
      state.isPending = false;
    },
    deleteProductCategory(state) {
      state.isPending = true;
    },
    deleteProductCategorySuccess(state, { payload }) {
      const category = payload?.inResponseTo;
      const mutatedCategory = state.productsCategories?.find(
        item => item.id === category.parentId
      );
      delete mutatedCategory?.sub_categories?.[category.id];
      state.isPending = false;
    },
    deleteProductCategoryFailure(state) {
      state.isPending = false;
    },

    fetchProductsCount: state => {
      state.productCountsPending = true;
    },
    fetchProductsCountSuccess: (state, { payload }) => {
      const { results } = payload.data;
      const preparedProductCounts = results.reduce(
        (previous, current) => ({
          ...previous,
          [current.product_id]: current.product_count,
        }),
        {}
      );
      state.productCounts = {
        ...state.productCounts,
        ...preparedProductCounts,
      };
      state.productCountsPending = false;
    },
    fetchProductsCountFailure: state => {
      state.productCountsPending = false;
    },
  },
});

export const actions = actionTypes(productsSlice.actions);

export default productsSlice.reducer;
