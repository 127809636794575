import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const tablesSlice = createSlice({
  name: 'tables',
  initialState: {
    isPending: false,
    tables: [],
    table: {},
    pagination: {},
  },
  reducers: {
    fetchTables: state => {
      state.isPending = true;
    },
    fetchTablesSuccess(state, { payload }) {
      state.isPending = false;
      state.tables = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchTablesFailure: state => {
      state.isPending = false;
    },
    fetchTable: state => {
      state.isPending = true;
    },
    fetchTableSuccess(state, { payload }) {
      state.isPending = false;
      state.table = payload.data;
    },
    fetchTableFailure: state => {
      state.isPending = false;
    },
    createTable: state => {
      state.isPending = true;
    },
    createTableSuccess(state, { payload }) {
      state.tables = state.tables.concat(payload.data);
      state.isPending = false;
    },
    createTableFailure: state => {
      state.isPending = false;
    },
    updateTable: state => {
      state.isPending = true;
    },
    updateTableSuccess(state, { payload }) {
      state.tables = state.tables.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateTableFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(tablesSlice.actions);

export default tablesSlice.reducer;
