import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import EmailField from '../Fields/EmailField';

const Form = ({
  submitTitle,
  disabled,
  currentBrand,
  setCurrentBrand,
  onSubmit,
}) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit({ ...values, brand_id: currentBrand });
    },
    [onSubmit, currentBrand]
  );

  return (
    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <EmailField
            isRequired
            name="email"
            label="Email"
            placeholder="Email"
            errorMessage="Enter Email"
            value={email}
            onChange={handleEmailChange}
          />
        </Col>

        <Col lg="6">
          <FormGroup>
            <Label required htmlFor="brand_id">
              Brand
            </Label>
            <BrandsPaginatedField
              name="brand_id"
              defaultValue={currentBrand}
              onChange={e => setCurrentBrand(e.value)}
              isMulti={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  currentBrand: PropTypes.any,
  setCurrentBrand: PropTypes.func,
};

export default Form;
