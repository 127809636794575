import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    isPending: false,
    subscriptions: [],
    subscription: {},
    pagination: {},
  },
  reducers: {
    fetchSubscriptions: state => {
      state.isPending = true;
    },
    fetchSubscriptionsSuccess(state, { payload }) {
      state.isPending = false;
      state.subscriptions = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchSubscriptionsFailure(state) {
      state.isPending = false;
    },
    fetchSubscription: state => {
      state.isPending = true;
    },
    fetchSubscriptionSuccess(state, { payload }) {
      state.isPending = false;
      state.subscription = payload.data;
    },
    fetchSubscriptionFailure: state => {
      state.isPending = false;
    },
    createSubscription: state => {
      state.isPending = true;
    },
    createSubscriptionSuccess: state => {
      state.isPending = false;
    },
    createSubscriptionFailure: state => {
      state.isPending = false;
    },
    activateSubscription: state => {
      state.isPending = true;
    },
    activateSubscriptionSuccess(state) {
      state.isPending = false;
      // state.subscriptions = state.subscriptions.map(item => {
      //   if (item.id === payload.data.id) {
      //     return payload.data;
      //   }
      //   return item;
      // });
    },
    activateSubscriptionFailure: state => {
      state.isPending = false;
    },
    cancelSubscription: state => {
      state.isPending = true;
    },
    cancelSubscriptionSuccess(state) {
      state.isPending = false;
      // state.subscriptions = state.subscriptions.map(item => {
      //   if (item.id === payload.data.id) {
      //     return payload.data;
      //   }
      //   return item;
      // });
    },
    cancelSubscriptionFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(subscriptionsSlice.actions);

export default subscriptionsSlice.reducer;
