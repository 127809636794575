import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import Form from './Form';
import {
  galleryImagesSelector,
  compositeImagesSelector,
} from 'models/products/selectors';
import ImageForm from './ImageForm';
import classNames from 'classnames';

import isEmpty from 'lodash/isEmpty';

const ProductForm = ({
  model,
  submitTitle,
  disabled,
  resourcetype,
  categories,
  onSubmit,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const galleryImages = useSelector(galleryImagesSelector);
  const compositeImages = useSelector(compositeImagesSelector);

  const currentForm = tab => {
    switch (tab) {
      case 1:
        return (
          <Form
            model={model}
            onSubmit={onSubmit}
            categories={categories}
            resourcetype={resourcetype}
            disabled={disabled}
            submitTitle={submitTitle}
          />
        );
      case 2:
        return (
          <ImageForm
            productId={model?.id}
            images={galleryImages}
            type="gallery"
          />
        );
      default:
        return (
          <ImageForm
            productId={model?.id}
            images={compositeImages}
            type="composite"
          />
        );
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink
                className={classNames(
                  'font-weight-bold p-3',
                  activeTab === 1 && 'active'
                )}
                onClick={() => setActiveTab(1)}
              >
                {isEmpty(model) ? 'Create' : 'Edit'}
              </NavLink>
            </NavItem>
            {!isEmpty(model) && (
              <>
                <NavItem>
                  <NavLink
                    className={classNames(
                      'font-weight-bold p-3',
                      activeTab === 2 && 'active'
                    )}
                    onClick={() => setActiveTab(2)}
                  >
                    Gallery
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames(
                      'font-weight-bold p-3',
                      activeTab === 3 && 'active'
                    )}
                    onClick={() => setActiveTab(3)}
                  >
                    Composite Images
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>{currentForm(activeTab)}</Col>
      </Row>
    </>
  );
};

ProductForm.propTypes = {
  model: PropTypes.object,
  categories: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  resourcetype: PropTypes.string,
  onSubmit: PropTypes.func,
};

ProductForm.defaultProps = {
  model: {},
};

export default ProductForm;
