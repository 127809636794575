import { createSelector } from 'reselect';
import { isOnlyHostOrStaff } from 'utils/isOnlyHostOrStaff';
import { isOnlyBookkeeperOrStaff } from 'utils/isOnlyBookkeeperOrStaff';
import { hasOwnerOrManagerRole } from 'utils/hasOwnerOrManagerRole';
import { hasBookkeeperRole } from 'utils/hasBookkeeperRole';
import { isOnlySharedCustomer } from 'utils/isOnlySharedCustomer';

export const rootSelector = state => state.user;

export const emailSelector = createSelector(rootSelector, ({ email }) => email);
export const idSelector = createSelector(rootSelector, ({ id }) => id);
export const hostBrandSelector = createSelector(
  rootSelector,
  ({ hostBrand }) => hostBrand
);
export const isAuthSelector = createSelector(
  rootSelector,
  ({ isAuthenticated }) => isAuthenticated
);
export const workspacesSelector = createSelector(
  rootSelector,
  ({ workspaces }) => workspaces
);
export const settingsSelector = createSelector(
  rootSelector,
  ({ settings }) => settings
);
export const rolesSelector = createSelector(rootSelector, ({ roles }) => roles);

export const isOnlyHostSelector = createSelector(
  rootSelector,
  ({ roles }) => isOnlyHostOrStaff(roles)
  // roles && roles.length === 1 && roles[0] === PERMISSION_TYPES.HOST
);

export const isOnlyBookkeeperSelector = createSelector(
  rootSelector,
  ({ roles }) => isOnlyBookkeeperOrStaff(roles)
);
export const hasOwnerOrManagerRoleSelector = createSelector(
  rootSelector,
  ({ roles }) => hasOwnerOrManagerRole(roles)
);
export const isOnlySharedCustomerSelector = createSelector(
  rootSelector,
  ({ roles }) => isOnlySharedCustomer(roles)
);

export const hasBookkeeperRoleSelector = createSelector(
  rootSelector,
  ({ roles }) => hasBookkeeperRole(roles)
);

export const canEditDefaultWarehouseSelector = createSelector(
  rootSelector,
  ({ canEditDefaultWarehouse }) => canEditDefaultWarehouse
);

export const usersSelector = createSelector(rootSelector, ({ users }) => users);

export const userByIdSelector = id =>
  createSelector(rootSelector, ({ users }) => users?.[id]);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const usersPendingSelector = createSelector(
  rootSelector,
  ({ usersPending }) => usersPending
);

export const confirmationPendingSelector = createSelector(
  rootSelector,
  ({ confirmationPending }) => confirmationPending
);

export const confirmationEmailSentSelector = createSelector(
  rootSelector,
  ({ confirmationEmailSent }) => confirmationEmailSent
);

export const tokenConfirmationErrorSelector = createSelector(
  rootSelector,
  ({ tokenConfirmationError }) => tokenConfirmationError
);

export const tokenSelector = createSelector(rootSelector, ({ token }) => token);

export const currentWorkspaceSelector = createSelector(
  rootSelector,
  ({ currentWorkspace }) => currentWorkspace
);

export const openManualCountSelector = createSelector(
  rootSelector,
  ({ openManualCount }) => openManualCount
);

export const recoverPasswordModalSelector = createSelector(
  rootSelector,
  ({ recoverPasswordModal }) => recoverPasswordModal
);

export const userPinSelector = createSelector(rootSelector, ({ pin }) => pin);

export const userPinPendingSelector = createSelector(
  rootSelector,
  ({ pinPending }) => pinPending
);
