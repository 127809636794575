import { Col, Row } from 'reactstrap';
import React from 'react';
import DateField from 'components/Fields/DateField';
import styles from 'pages/ExciseReturn/ExciseReturn.scss';
import PropTypes from 'prop-types';
import NumberField from 'components/Fields/NumberField';

export const renderDateFields = (fields, disabled, rules, setVariable) => {
  return fields.map(({ key, label }) => (
    <Col md={2} key={key}>
      <DateField
        name={key}
        label={label}
        rules={rules}
        disabled={disabled}
        setVariable={setVariable}
        isRequired
      />
    </Col>
  ));
};

export const renderTitleFields = fields => {
  return fields.map(({ label }) => (
    <Col md={2}>
      <h4 className="card-title">{label}</h4>
    </Col>
  ));
};

export const DateFields = ({
  fields,
  disabled,
  rules,
  onInput,
  setVariable,
  validateDateFields,
}) => {
  return (
    <>
      {fields.map((column, index) => (
        <Col md={2} key={index}>
          <DateField
            name={column.key}
            label={column.label}
            rules={{
              ...rules,
              validateDate: () => {
                const errors = validateDateFields();
                return errors[column.key] || true;
              },
            }}
            disabled={disabled}
            isRequired
            onInput={onInput}
            setVariable={setVariable}
          />
        </Col>
      ))}
    </>
  );
};

DateFields.propTypes = {
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  setVariable: PropTypes.func,
  validateDateFields: PropTypes.func,
};

export const MultipleFieldRow = ({
  title,
  fields,
  name,
  disabled,
  onBlur,
  setVariable,
  min,
}) => (
  <>
    <Row>
      <Col md={2} className={styles.right}>
        {title}
      </Col>
      <MultipleNumberFields
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        fields={fields}
        setVariable={setVariable}
        min={min}
      />
    </Row>
  </>
);
MultipleFieldRow.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  setVariable: PropTypes.func,
  min: PropTypes.string,
};

const MultipleNumberFields = ({
  fields,
  name,
  disabled,
  onBlur,
  setVariable,
  min,
}) => (
  <>
    {fields.map((field, index) => (
      <Col md={2} key={index}>
        <NumberField
          name={`${field}.${name}`}
          disabled={disabled}
          onChange={event => {
            setVariable(`${field}_${name}`, event.target.value);
          }}
          onBlur={onBlur}
          min={min}
        />
      </Col>
    ))}
  </>
);

MultipleNumberFields.propTypes = {
  fields: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  setVariable: PropTypes.func,
  min: PropTypes.string,
};

export const TotalRow = ({
  fields,
  disabled,
  state,
  title,
  name,
  onBlur,
  setVariable,
  min,
}) => {
  return (
    <Row>
      <Col md={2} className={styles.right}>
        <h4 className="card-title">{title}</h4>
      </Col>
      {fields.map((field, index) => (
        <Col md={2} key={index}>
          <NumberField
            name={`${field}.${name}`}
            value={state[`${field}_${name}`]}
            disabled={disabled}
            onChange={event => {
              setVariable(`${field}_${name}`, event.target.value);
            }}
            onBlur={onBlur}
            min={min}
          />
        </Col>
      ))}
    </Row>
  );
};

TotalRow.propTypes = {
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  state: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  setVariable: PropTypes.func,
  min: PropTypes.string,
};
