import React, { useState, useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Card, CardBody, Col, Row, Button, Alert, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import useAction from 'hooks/useAction';
import { actions } from 'models/orders/slice';
import useSelector from 'hooks/useSelector';
import {
  orderImportSyncSelector,
  isPendingSelector,
} from 'models/orders/selectors';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import styles from './OrderImportSummary.scss';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const OrderImportSummary = ({ title }) => {
  const clearOrderImport = useAction(actions.clearOrderImport);
  const createOrderImport = useAction(actions.createOrderImport);
  const orderImportSync = useSelector(orderImportSyncSelector);
  const isPending = useSelector(isPendingSelector);

  const [files, setFiles] = useState(null);
  const [brand, setBrand] = useState(null);

  const onSubmit = async () => {
    createOrderImport({ brand_id: brand, csv_file: files[0] });
  };

  useEffect(() => {
    clearOrderImport();
  }, []);

  useEffect(() => {
    if (!isPending) {
      setFiles(null);
    }
  }, [isPending]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col s={0} md={1} lg={2} xl={3} />
        <Col s={12} md={10} lg={8} xl={6}>
          <Card>
            <CardBody>
              <h4 className="card-title">Commerce7 CSV Import</h4>
              <br />
              <label className={styles.selectField}>
                <BrandsPaginatedField
                  placeholder="Select brand"
                  isInitialLabel={false}
                  isMulti={false}
                  onChange={({ value }) => setBrand(value)}
                />
              </label>
              <br />
              <FilePond
                dropValidation
                credits={false}
                allowMultiple={false}
                required
                maxFiles={1}
                instantUpload={false}
                storeAsFile
                labelButtonProcessItem="Upload"
                files={files}
                onupdatefiles={fileItems => {
                  const updatedFiles = fileItems.map(fileItem => {
                    return fileItem.file;
                  });
                  setFiles(updatedFiles);
                }}
                acceptedFileTypes={['text/csv']}
                allowFileSizeValidation
                maxFileSize="2500KB"
              />
              <Button
                color="primary"
                className="mt-3"
                onClick={() => onSubmit()}
                disabled={!(files && files.length > 0) || !brand || isPending}
              >
                {isPending && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                )}
                Import File
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {orderImportSync?.status && (
        <Row>
          <Col s={0} md={1} lg={2} xl={3} />
          <Col s={12} md={10} lg={8} xl={6}>
            {orderImportSync?.status === 'VALID' && (
              <Alert color="success" className={styles.alert}>
                <i className="mdi mdi-check font-size-22 mr-2" />
                <span>
                  Import started successfully. You will receive an email once
                  the import is complete.
                </span>
              </Alert>
            )}
            {orderImportSync?.status === 'INVALID' && (
              <>
                <Alert color="warning" className={styles.alert}>
                  <i className="mdi mdi-alert-circle font-size-22 mr-2" />
                  <span>
                    This file was found to have the following issues. Please
                    correct them and try again.
                  </span>
                </Alert>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Issues</h4>
                    <br />
                    {orderImportSync.errors_log.map(error => (
                      <p>
                        {Object.keys(error).map(key => (
                          <>
                            <strong>{key}</strong>
                            <br />
                            <span>{error[key]}</span>
                          </>
                        ))}
                      </p>
                    ))}
                  </CardBody>
                </Card>
              </>
            )}
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

OrderImportSummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default OrderImportSummary;
