import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isPendingSelector as brandsIsPendingSelector,
  brandsSelector,
} from 'models/brands/selectors';
import { isPendingSelector as productsIsPendingSelector } from 'models/products/selectors';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';
import { format } from 'date-fns';
import useAction from 'hooks/useAction';
import { getEndDate, getMockDate } from 'utils/date';
import { actions as reportsActions } from 'models/reports/slice';

import {
  inventoriesSelector,
  paginationSelector,
} from 'models/reports/selectors';

import ReportsLayout from 'components/ReportsLayout';
import useQuery from 'hooks/useQuery';
import ReportInventoryFilters from './ReportInventoryFilters';
import ProductInventories from './ProductInventories';
import { actions as brandsActions } from 'models/brands/slice';

const ReportsInventorySummary = ({ title }) => {
  const query = useQuery();

  // Actions
  const fetchInventories = useAction(reportsActions.fetchInventories);
  const fetchInventoriesXlsx = useAction(reportsActions.fetchInventoriesXlsx);
  const fetchBrands = useAction(brandsActions.fetchBrands);

  // Selectors
  const brandsPending = useSelector(brandsIsPendingSelector);
  const productsPending = useSelector(productsIsPendingSelector);
  const warehousesPending = useSelector(warehousesIsPendingSelector);
  const inventories = useSelector(inventoriesSelector);
  const pagination = useSelector(paginationSelector);
  const brands = useSelector(brandsSelector);

  // States
  const [dateRange, setDateRange] = useState({
    startDate:
      query.get('date_range_start') ||
      format(getMockDate(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    endDate:
      query.get('date_range_end') ||
      format(getEndDate(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
  });
  const [selectedBrands, setSelectedBrands] = useState([
    {
      label: 'All brands',
      value: undefined,
    },
  ]);
  const [selectedProducts, setSelectedProducts] = useState([
    {
      label: 'All products',
      value: undefined,
    },
  ]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([
    {
      label: 'All warehouses',
      value: undefined,
    },
  ]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([
    {
      value: 'Wine',
      label: 'Wine',
    },
  ]);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page);

  // Effects
  useEffect(() => {
    fetchBrands({
      page: 1,
      per_page: 1,
    });
  }, []);

  useEffect(() => {
    if (brands.length > 0) {
      const formattedBrands = brands.map(brand => ({
        label: brand.title,
        value: brand.id,
      }));
      setSelectedBrands([formattedBrands[0]]);
    }
  }, [brands]);

  useEffect(() => {
    if (dateRange) {
      const { startDate } = dateRange;
      fetchInventories({
        startDate,
        brand_ids: selectedBrands.map(item => item.value),
        product_ids: selectedProducts.map(item => item.value),
        warehouse_ids: selectedWarehouses.map(item => item.value),
        product_types: selectedProductTypes.map(item => item.value),
        page: currentPage,
      });
    }
  }, [
    dateRange,
    currentPage,
    selectedBrands,
    selectedProducts,
    selectedWarehouses,
    selectedProductTypes,
  ]);

  const onExport = useCallback(() => {
    const { startDate, endDate } = dateRange;
    fetchInventoriesXlsx({
      startDate,
      endDate,
      brand_ids: selectedBrands.map(item => item.value),
      product_ids: selectedProducts.map(item => item.value),
      warehouse_ids: selectedWarehouses.map(item => item.value),
      product_types: selectedProductTypes.map(item => item.value),
    });
  }, [
    fetchInventoriesXlsx,
    selectedBrands,
    selectedProducts,
    selectedWarehouses,
    selectedProductTypes,
  ]);

  return (
    <>
      <ReportsLayout
        title={title}
        loading={productsPending || brandsPending || warehousesPending}
        onDateChange={setDateRange}
        onExport={onExport}
        exportButtonText="Export to Excel"
        isDownloadDisabled={!inventories || inventories.length === 0}
      >
        <ReportInventoryFilters
          filterWarehouseByBrand
          filterSkuByWarehouse
          filterSkuByBrand
          filterSkuByProductType
          selectedBrands={selectedBrands}
          selectedProducts={selectedProducts}
          selectedWarehouses={selectedWarehouses}
          selectedProductTypes={selectedProductTypes}
          setSelectedBrands={setSelectedBrands}
          setSelectedProductTypes={setSelectedProductTypes}
          setSelectedWarehouses={setSelectedWarehouses}
          setSelectedProducts={setSelectedProducts}
        />

        <ProductInventories
          inventories={inventories}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
        />
      </ReportsLayout>
    </>
  );
};

ReportsInventorySummary.propTypes = {
  title: PropTypes.string,
};
export default ReportsInventorySummary;
