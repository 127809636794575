import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import SelectionPopup from 'components/Popup/SelectionPopup';
import Select from 'react-select';
import {
  ADDITIONS_EXCISE_REASONS,
  REDUCTIONS_EXCISE_REASONS,
} from './constants';
import './InventoryExciseModal.scss';

const InventoryExciseModal = ({
  exciseResults,
  setExciseResults,
  isOpen,
  setIsOpen,
  withFooter,
  allReasonsSelected,
  setAllReasonsSelected,
  processApproval,
}) => {
  const [exciseReasons, setExciseReasons] = useState({});
  const [showCaution, setShowCaution] = useState(true);

  const checkAllReasonsSelected = () => {
    setAllReasonsSelected(
      Object.keys(exciseResults).length === Object.keys(exciseReasons).length
    );
    setShowCaution(!allReasonsSelected);
  };

  useEffect(() => {
    checkAllReasonsSelected();
  }, [exciseReasons]);

  const attachExciseReasons = () => {
    const updatedResults = { ...exciseResults };
    Object.keys(exciseReasons).forEach(productId => {
      updatedResults[productId].exciseReason = exciseReasons[productId];
    });
    setExciseResults(oldExciseResults => ({
      ...oldExciseResults,
      ...updatedResults,
    }));
  };

  const onSubmit = () => {
    attachExciseReasons();
    processApproval();
  };

  return (
    <SelectionPopup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSaveClick={onSubmit}
      withFooter={withFooter}
      title="Excise Reason Selection"
      disabled={!allReasonsSelected}
    >
      <Row>
        <Col lg="12">
          {showCaution && (
            <Alert color="light"> *Select a reason for each count item. </Alert>
          )}
          <Card style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="30%">Product Title</th>
                  <th width="5%">Difference</th>
                  <th width="65%">Excise Reason</th>
                </tr>
              </thead>
              <tbody>
                {exciseResults &&
                  Object.keys(exciseResults).map(productId => {
                    const result = exciseResults[productId];
                    return (
                      <tr
                        style={{ minHeight: '100px' }}
                        key={result.product_id}
                      >
                        <td>{result.product_title}</td>
                        <td>{result.difference}</td>
                        <td>
                          <Select
                            options={
                              result.difference > 0
                                ? ADDITIONS_EXCISE_REASONS
                                : REDUCTIONS_EXCISE_REASONS
                            }
                            onChange={selectedOption => {
                              const updatedExciseReasons = {
                                ...exciseReasons,
                              };
                              updatedExciseReasons[productId] =
                                selectedOption.value;
                              setExciseReasons(updatedExciseReasons);
                            }}
                            styles={{
                              menuPortal: provided => ({
                                ...provided,
                                zIndex: 999999,
                              }),
                              menu: provided => ({
                                ...provided,
                                zIndex: 999999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </SelectionPopup>
  );
};

InventoryExciseModal.propTypes = {
  exciseResults: PropTypes.object,
  setExciseResults: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  withFooter: PropTypes.bool,
  allReasonsSelected: PropTypes.bool,
  setAllReasonsSelected: PropTypes.func,
  processApproval: PropTypes.func,
};

export default InventoryExciseModal;
