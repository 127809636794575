import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/WarehousesForm';
import NotificationsForm from './NotificationsForm';

import useAction from 'hooks/useAction';

import { actions as warehousesActions } from 'models/warehouses/slice';
import {
  currentWarehouseSelector,
  isWarehousePendingSelector as warehouseIsPendingSelector,
} from 'models/warehouses/selectors';

const EditWarehouse = ({ title }) => {
  const { warehouseID } = useParams();
  const [tab, setTab] = useState(1);
  const warehouse = useSelector(currentWarehouseSelector);
  const updateWarehouse = useAction(warehousesActions.updateWarehouse);
  const fetchWarehouse = useAction(warehousesActions.fetchWarehouse);
  const warehouseIsPending = useSelector(warehouseIsPendingSelector);

  const isPending = warehouseIsPending;

  useEffect(() => {
    fetchWarehouse(warehouseID);
  }, [fetchWarehouse, warehouseID]);

  if (isPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={warehouse?.title}
        breadcrumbItems={[
          {
            title: 'Back to warehouses',
            link: '/warehouses/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', { active: tab === 1 })}
                onClick={() => setTab(1)}
              >
                Edit
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', { active: tab === 2 })}
                onClick={() => setTab(2)}
              >
                Inventory Limit Notifications
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {tab === 1 && (
            <Form
              disabled={isPending}
              submitTitle="Save"
              onSubmit={updateWarehouse}
              model={warehouse}
            />
          )}
          {tab === 2 && <NotificationsForm warehouseId={warehouseID} />}
        </Col>
      </Row>
    </PageWrapper>
  );
};

EditWarehouse.propTypes = {
  title: PropTypes.string,
};

export default EditWarehouse;
