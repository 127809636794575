import React from 'react';
import styles from './WarehouseInventoryTable.scss';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PaginationTable from '../PaginationTable';

const WarehouseInventoryTable = ({
  title,
  inventories,
  pagination,
  pending,
  onPageChange,
  warehouseID,
}) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="card-title mb-4">{title}</h4>
          <Link
            to={`/transfers/create?warehouseID=${warehouseID}`}
            className="btn btn-primary mb-4"
          >
            Add Inventory
          </Link>
        </div>
        <Row>
          <Col md={12}>
            {pagination?.total_count > 0 ? (
              <PaginationTable
                pagination={pagination}
                setCurrentPage={onPageChange}
              >
                <Table
                  className={pending && styles.pendingContainer}
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th data-priority="1">Product ID</th>
                      <th data-priority="3">Product Title</th>
                      <th data-priority="1">Product Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventories?.map(item => (
                      <tr key={item.product_id}>
                        <td>
                          <Link
                            to={`/products/${item.product_id}/show`}
                            className="text-dark font-weight-bold"
                          >
                            {item.product_id}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/products/${item.product_id}/show`}
                            className="text-dark font-weight-bold"
                          >
                            {item.product_title}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/products/${item.product_id}/show`}
                            className="text-dark font-weight-bold"
                          >
                            {item.product_count}{' '}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </PaginationTable>
            ) : (
              'Missing items in inventory!'
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

WarehouseInventoryTable.propTypes = {
  title: PropTypes.string.isRequired,
  inventories: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  pending: PropTypes.bool,
  onPageChange: PropTypes.func,
  warehouseID: PropTypes.string,
};
export default WarehouseInventoryTable;
