import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { models } from 'constants/terminalModels';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import TextField from '../Fields/TextField';
import SelectField from '../SelectField/SelectField';

const TerminalForm = ({ model, onSubmit, disabled, submitTitle }) => {
  const [terminalModel, setTerminalModel] = useState(
    models?.find(_model => _model?.value === model?.model) || models[0]
  );
  const [warehouseTouched, setWarehouseTouched] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    model
      ? {
          value: model.warehouse_id,
          label: model.warehouse_title,
        }
      : null
  );

  const handleSubmit = useCallback(
    (_, values) => {
      setWarehouseTouched(true);
      if (!selectedWarehouse) return;
      onSubmit({
        ...values,
        warehouse_id: selectedWarehouse?.value,
        model: terminalModel?.value,
      });
    },
    [onSubmit, selectedWarehouse, terminalModel]
  );

  const handleInvalidSubmit = () => {
    setWarehouseTouched(true);
  };

  return (
    <AvForm
      model={model || {}}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      onInvalidSubmit={handleInvalidSubmit}
    >
      <Row>
        <Col md="6">
          <TextField
            label="Title"
            name="title"
            placeholder="Title"
            errorMessage="Enter Title"
            isRequired
          />
        </Col>

        <Col lg="6">
          <TextField
            name="serial_number"
            label="Serial Number"
            placeholder="Serial Number"
            errorMessage="Enter serial number"
            isRequired
          />
        </Col>
        <Col lg="6">
          <SelectField
            name="model"
            label="Model"
            isRequired
            onChange={setTerminalModel}
            value={terminalModel}
            options={models}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
          />
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className={
                warehouseTouched && !selectedWarehouse && 'text-danger'
              }
              required
              htmlFor="warehouse_id"
            >
              Warehouse
            </Label>
            <WarehousesPaginatedField
              value={selectedWarehouse}
              onChange={setSelectedWarehouse}
              onBlur={() => setWarehouseTouched(true)}
              error={warehouseTouched && !selectedWarehouse && 'Required field'}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {submitTitle}
      </Button>
    </AvForm>
  );
};

TerminalForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
};

export default TerminalForm;
