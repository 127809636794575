import React, { useEffect } from 'react';
import styles from './TableOfTransfers.scss';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import getUID from 'utils/getUID';

import TableRow from './TableRow';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableOfTransfers = ({
  isInventory,
  warehouseId,
  defaultProducts,
  onUpdate,
}) => {
  const [products, setProducts] = React.useState(defaultProducts);

  useEffect(() => {
    onUpdate(products);
  }, [products]);

  useEffect(() => {
    setProducts(defaultProducts);
  }, [defaultProducts]);

  const addProduct = () => {
    setProducts([
      ...products,
      {
        id: getUID(),
        product_id: null,
        product_title: null,
        current_quantity: 0,
        new_quantity: 0,
      },
    ]);
  };

  const removeProduct = id => {
    setProducts(products.filter(item => item.id !== id));
  };

  const onChangeProduct = product => {
    setProducts(
      products.map(item => {
        return item.id === product.id ? product : item;
      })
    );
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Table className={classNames(styles.table)} bordered responsive>
              <thead>
                <tr>
                  <th className={styles.columnLarge}>Title</th>
                  <th className={styles.columnMain}>Current Quantity</th>
                  <th className={styles.columnMain}>Actual Quantity</th>
                  <th className={styles.columnSmall}>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map(item => (
                  <TableRow
                    isInventory={isInventory}
                    products={products}
                    key={item.id}
                    value={item}
                    warehouseId={warehouseId}
                    onChange={onChangeProduct}
                    onRemove={removeProduct}
                  />
                ))}
                <tr>
                  <td colSpan={6}>
                    <Button
                      onClick={addProduct}
                      color="success"
                      type="button"
                      className="waves-effect waves-light mr-1 text-nowrap"
                    >
                      <i className="ri-add-line align-middle mr-2" />
                      Add new Product
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <p className={styles.error}>{error}</p> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

TableOfTransfers.propTypes = {
  isInventory: PropTypes.bool,
  warehouseId: PropTypes.number,
  defaultProducts: PropTypes.array,
  onUpdate: PropTypes.func,
};

export default TableOfTransfers;
