import React from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import UserForm from 'components/UserForm';
import useAction from 'hooks/useAction';
import { actions } from 'models/user/slice';

const CreateUser = ({ title }) => {
  const createUser = useAction(actions.createUser);

  const onSubmit = data => {
    createUser(data);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          { title: 'Back to users', link: '/users/', withSearch: true },
        ]}
      />
      <UserForm onSubmit={onSubmit} />
    </PageWrapper>
  );
};

CreateUser.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CreateUser;
