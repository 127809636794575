import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import WineclubsPaginatedField from 'components/PaginatedFields/WineclubsPaginatedField';
import useAction from 'hooks/useAction';
import { actions as emailTemplateActions } from 'models/emailTemplates/slice';
import { actions as wineClubsActions } from 'models/wineclubs/slice';
import useSelector from 'hooks/useSelector';
import {
  emailTemplatesSelector,
  emailTemplatesPaginationSelector,
  previewLayoutSelector,
  previewLayoutModalSelector,
  previewLayoutPendingSelector,
  emailTemplatesPendingSelector,
} from 'models/emailTemplates/selectors';
import EmailTemplatePaginatedField from 'components/PaginatedFields/EmailTemplatePaginatedField';
import DataTable from './DataTable';
import EmailTemplatePopup from 'components/Popup/EmailTemplatePopup/EmailTemplatePopup';
import useQuery from 'hooks/useQuery';
import QueryLink from 'components/QueryLink';
import { wineclubsSelector } from 'models/wineclubs/selectors';
import Select from 'react-select';
import { EMAIL_CATEGORIES } from 'constants/emailTemplates';

const EmailCategoryOptions = [
  {
    value: EMAIL_CATEGORIES.TEMPLATE,
    label: 'Templates',
  },
  {
    value: EMAIL_CATEGORIES.CUSTOM,
    label: 'Custom emails',
  },
];

const getOptionFromQuery = (key, query) => {
  const value = query.get(`${key}_id`);
  const label = query.get(`${key}_title`);
  return value && label ? { value, label } : null;
};

const getCategoryOptionFromQuery = query => {
  const category = query.get('category');
  return category
    ? EmailCategoryOptions.find(({ value }) => value === category)
    : EmailCategoryOptions[0];
};

const EmailTemplateSummary = ({ title }) => {
  const query = useQuery();
  const fetchWineClubs = useAction(wineClubsActions.fetchWineclubs);
  const fetchEmailTemplates = useAction(
    emailTemplateActions.fetchEmailTemplates
  );
  const fetchCustomEmails = useAction(emailTemplateActions.fetchCustomEmails);
  const emailTemplatePreview = useAction(
    emailTemplateActions.existsEmailTemplatePreview
  );
  const customEmailPreview = useAction(
    emailTemplateActions.existsCustomEmailPreview
  );
  const deleteEmailTemplate = useAction(
    emailTemplateActions.deleteEmailTemplate
  );
  const deleteCustomEmail = useAction(emailTemplateActions.deleteCustomEmail);
  const setPreviewLayoutModal = useAction(
    emailTemplateActions.setPreviewLayoutModal
  );
  const previewLayout = useSelector(previewLayoutSelector);
  const previewLayoutModal = useSelector(previewLayoutModalSelector);
  const previewLayoutPending = useSelector(previewLayoutPendingSelector);
  const emailTemplates = useSelector(emailTemplatesSelector);
  const emailTemplatesPagination = useSelector(
    emailTemplatesPaginationSelector
  );
  const emailTemplatesPending = useSelector(emailTemplatesPendingSelector);
  const fetchedWineClubs = useSelector(wineclubsSelector);

  const [selectedCategory, setSelectedCategory] = useState(
    getCategoryOptionFromQuery(query)
  );
  const [selectedWineClub, setSelectedWineClub] = useState(
    getOptionFromQuery('wineclub', query)
  );
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(
    getOptionFromQuery('email_template', query)
  );
  const [page, setPage] = useState(1);

  const emailTemplatesArray = useMemo(() => Object.values(emailTemplates), [
    emailTemplates,
  ]);

  const isCategoryCustom = selectedCategory?.value === EMAIL_CATEGORIES.CUSTOM;

  const onPreviewHandler = useCallback(
    id => {
      if (isCategoryCustom) {
        customEmailPreview(id);
      } else if (selectedWineClub?.value) {
        emailTemplatePreview({
          wineClubId: selectedWineClub.value,
          templateId: id,
        });
      }
    },
    [selectedWineClub, isCategoryCustom]
  );

  const onRemoveHandler = useCallback(
    id => {
      if (isCategoryCustom) {
        deleteCustomEmail(id);
      } else if (selectedWineClub?.value) {
        deleteEmailTemplate({
          wineClubId: selectedWineClub.value,
          templateId: id,
        });
      }
    },
    [selectedWineClub, isCategoryCustom]
  );

  useEffect(() => {
    const action = isCategoryCustom ? fetchCustomEmails : fetchEmailTemplates;
    if (isCategoryCustom || (!isCategoryCustom && selectedWineClub?.value)) {
      action({
        wineclub_id: selectedWineClub.value,
        wineclub_title: selectedWineClub.label,
        email_template_id: selectedEmailTemplate?.value,
        email_template_title: selectedEmailTemplate?.label,
        category: selectedCategory?.value,
        mutable: true,
        page,
      });
    }
  }, [
    selectedCategory,
    isCategoryCustom,
    selectedWineClub,
    selectedEmailTemplate,
    page,
  ]);

  useEffect(() => {
    setPage(1);
  }, [selectedCategory, selectedWineClub, selectedEmailTemplate]);

  useEffect(() => {
    if (!selectedWineClub) {
      fetchWineClubs({ per_page: 1 });
    }
  }, [selectedWineClub]);

  useEffect(() => {
    if (fetchedWineClubs.length > 0 && !selectedWineClub) {
      const firstWineClub = fetchedWineClubs[0];
      setSelectedWineClub({
        label: firstWineClub.title,
        value: firstWineClub.id,
      });
    }
  }, [fetchedWineClubs, selectedWineClub]);

  return (
    <>
      {previewLayoutModal && previewLayout?.data && (
        <EmailTemplatePopup
          onClose={() => setPreviewLayoutModal(false)}
          layout={previewLayout.data}
          loading={previewLayoutPending}
        />
      )}
      <PageWrapper title={title}>
        <Breadcrumbs title={title} />
        <Card>
          <CardBody>
            <Row className="mt-2">
              <Col md={3}>
                <Select
                  placeholder="Select type..."
                  options={EmailCategoryOptions}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                />
              </Col>
              <Col md={3}>
                <WineclubsPaginatedField
                  value={selectedWineClub}
                  onChange={setSelectedWineClub}
                />
              </Col>
              {selectedCategory?.value === EMAIL_CATEGORIES.TEMPLATE && (
                <Col md={3}>
                  <EmailTemplatePaginatedField
                    isInitialLabel
                    value={selectedEmailTemplate}
                    onChange={setSelectedEmailTemplate}
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              <Col>
                <QueryLink to="/email-template/create">
                  <Button color="success">
                    <i className="ri-add-line align-middle mr-2" />
                    Create Email Template
                  </Button>
                </QueryLink>
                <QueryLink to="/email/create">
                  <Button className="ml-2" color="primary">
                    <i className="ri-add-line align-middle mr-2" />
                    Create Custom Email
                  </Button>
                </QueryLink>
              </Col>
            </Row>
            {selectedWineClub?.value && (
              <Row className="mt-4">
                <Col md={12}>
                  <DataTable
                    data={emailTemplatesArray}
                    loading={emailTemplatesPending}
                    pagination={emailTemplatesPagination}
                    onPageChange={setPage}
                    onPreviewClick={onPreviewHandler}
                    onRemoveClick={onRemoveHandler}
                    wineClubId={selectedWineClub.value}
                    isCategoryCustom={isCategoryCustom}
                  />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </PageWrapper>
    </>
  );
};

EmailTemplateSummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default EmailTemplateSummary;
