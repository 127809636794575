import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/TablesForm';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import { isPendingSelector as deviceIsPendingSelector } from 'models/devices/selectors';
import { isPendingSelector as tastingRoomsIsPendingSelector } from 'models/tastingRooms/selectors';
import {
  isPendingSelector as tablesIsPendingSelector,
  tableSelector,
} from 'models/tables/selectors';
import { actions as tablesActions } from 'models/tables/slice';

const EditTable = ({ title }) => {
  const { tableID } = useParams();

  const table = useSelector(tableSelector);

  const tablesIsPending = useSelector(tablesIsPendingSelector);
  const tastingRoomsIsPending = useSelector(tastingRoomsIsPendingSelector);
  const deviceIsPending = useSelector(deviceIsPendingSelector);

  const createTable = useAction(tablesActions.createTable);
  const fetchTable = useAction(tablesActions.fetchTable);

  const [currentWarehouse, setCurrentWarehouse] = useState();
  const [currentTastingRoom, setCurrentTastingRoom] = useState();
  const [currentDevice, setCurrentDevice] = useState(null);

  const isPending = deviceIsPending || tastingRoomsIsPending || tablesIsPending;

  const handleDuplicateTable = data => {
    createTable({ id: data?.id, ...table, ...data });
  };

  useEffect(() => {
    fetchTable(tableID);
  }, [fetchTable, tableID]);

  useEffect(() => {
    setCurrentWarehouse({
      value: table.warehouse_id,
      id: table.warehouse_id,
      label: table.warehouse_title,
    });
    setCurrentTastingRoom({
      id: table.tasting_room_id,
      title: table.tasting_room_title,
    });
    setCurrentDevice({
      id: table.device_id,
      identifier: table.device_title,
    });
  }, [table]);

  if (tablesIsPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={table?.name}
        breadcrumbItems={[
          { title: 'Back to tables', link: '/tables/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">
                Duplicate
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {table && (
            <Form
              model={table}
              disabled={isPending}
              onSubmit={handleDuplicateTable}
              submitTitle="Duplicate"
              currentDevice={currentDevice}
              setCurrentDevice={setCurrentDevice}
              currentWarehouse={currentWarehouse}
              currentTastingRoom={currentTastingRoom}
              setCurrentWarehouse={setCurrentWarehouse}
              setCurrentTastingRoom={setCurrentTastingRoom}
            />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

EditTable.propTypes = {
  title: PropTypes.string,
};

export default EditTable;
