import React from 'react';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import { Col, Row } from 'reactstrap';
import CustomEmailForm from 'components/CustomEmailForm';
import useAction from 'hooks/useAction';
import { actions as emailTemplatesActions } from 'models/emailTemplates/slice';
import useSelector from 'hooks/useSelector';
import {
  previewLayoutModalSelector,
  previewLayoutPendingSelector,
  previewLayoutSelector,
} from 'models/emailTemplates/selectors';
import EmailTemplatePopup from 'components/Popup/EmailTemplatePopup/EmailTemplatePopup';

const CreateCustomEmail = ({ title }) => {
  const createEmail = useAction(emailTemplatesActions.createCustomEmail);
  const customEmailPreview = useAction(
    emailTemplatesActions.customEmailPreview
  );
  const deleteCustomEmail = useAction(emailTemplatesActions.deleteCustomEmail);
  const setPreviewLayoutModal = useAction(
    emailTemplatesActions.setPreviewLayoutModal
  );
  const previewLayout = useSelector(previewLayoutSelector);
  const previewLayoutModal = useSelector(previewLayoutModalSelector);
  const previewLayoutPending = useSelector(previewLayoutPendingSelector);

  const onSubmitHandler = data => {
    createEmail(data);
  };

  const onPreviewHandler = data => {
    customEmailPreview(data);
  };

  const onPreviewModalClose = () => {
    const { id } = previewLayout;
    if (id) {
      deleteCustomEmail(id);
    }
    setPreviewLayoutModal(false);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create Custom Email"
        breadcrumbItems={[
          {
            title: 'Back to email templates',
            link: `/email-templates/`,
          },
        ]}
      />
      {previewLayoutModal && (
        <EmailTemplatePopup
          onClose={onPreviewModalClose}
          layout={previewLayout?.data}
          loading={previewLayoutPending}
        />
      )}
      <Row>
        <Col md={12}>
          <CustomEmailForm
            onSubmit={onSubmitHandler}
            onPreviewClick={onPreviewHandler}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateCustomEmail.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CreateCustomEmail;
