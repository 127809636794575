import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import Form from 'components/TransferForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as transfersActions } from 'models/transfers/slice';
import { isPendingSelector as transfersIsPendingSelector } from 'models/transfers/selectors';

const CreateTransfer = ({ title }) => {
  const transfersIsPending = useSelector(transfersIsPendingSelector);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productIDParam = queryParams.get('productID');
  const warehouseIDParam = queryParams.get('warehouseID');
  const [receiverID, setReceiverID] = useState(null);
  const [senderID, setSenderID] = useState(null);
  const isPending = transfersIsPending;
  const createTransfer = useAction(transfersActions.createTransfer);

  const onSubmit = data => {
    createTransfer({
      ...data,
      receiver_id: receiverID || warehouseIDParam,
      sender_id: senderID,
    });
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={
          productIDParam || warehouseIDParam
            ? 'Add inventory'
            : 'Create Product Movement'
        }
        breadcrumbItems={[
          {
            title: 'Back to Product Movements',
            link: '/transfers/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Save"
            receiverID={receiverID}
            setReceiverID={setReceiverID}
            senderID={senderID}
            setSenderID={setSenderID}
            onSubmit={onSubmit}
            productIDParam={productIDParam}
            warehouseIDParam={warehouseIDParam}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateTransfer.propTypes = {
  title: PropTypes.string,
};

export default CreateTransfer;
