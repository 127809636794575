import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { actions as inventoriesActions } from '../inventories/slice';
import { normalize } from '../../utils/normalizeById';
import { PERMISSION_TYPES } from '../../constants/permissions';

const getPreparedRoles = data => {
  const isHostActive = data?.host?.is_host_active;
  const isManagerActive = data?.is_manager_active;
  const rolesData = data?.roles ?? [];
  if (rolesData.includes(PERMISSION_TYPES.SALES_REP)) {
    return [PERMISSION_TYPES.SALES_REP];
  }
  return rolesData.filter(role => {
    // eslint-disable-next-line no-nested-ternary
    return role === PERMISSION_TYPES.MANAGER
      ? isManagerActive
      : role === PERMISSION_TYPES.HOST
      ? isHostActive
      : true;
  });
};

const setUser = (state, { payload }) => {
  const { auth_token: authToken, email, id, workspaces } = payload.data;
  state.token = authToken;
  state.email = email;
  state.id = id;
  state.isAuthenticated = !!authToken;
  state.workspaces = workspaces;
  if (workspaces?.length > 0 && workspaces[0]?.settings) {
    state.settings = workspaces[0]?.settings;
  }
  state.roles = getPreparedRoles(payload.data);
  state.isPending = false;
  state.hostBrand = payload.data?.host_brands;
  state.openManualCount = payload.data?.open_manual_inventory_requests_count;
  state.canEditDefaultWarehouse = payload.data?.can_edit_default_warehouse;
};

const resetUser = state => {
  state.token = null;
  state.email = null;
  state.id = null;
  state.isAuthenticated = false;
  state.workspaces = [];
  state.settings = {};
  state.roles = [];
  state.hostBrand = null;
  state.openManualCount = null;
  state.canEditDefaultWarehouse = null;
  localStorage.clear();
};

function updateUserInList(state, { payload }) {
  state.users = state.users.map(item => {
    if (item.id !== payload.inResponseTo.id) {
      return item;
    }
    return {
      ...payload.data,
    };
  });
  state.isPending = false;
}

export const userInitialState = {
  email: null,
  id: null,
  openManualCount: null,
  workspaces: [],
  settings: {},
  roles: [],
  isPending: false,
  usersPending: false,
  confirmationPending: false,
  tokenConfirmationError: null,
  confirmationEmailSent: null,
  recoverPasswordModal: false,
  pagination: {},
  users: {},
  hostBrand: null,
  pin: null,
  pinPending: false,
  canEditDefaultWarehouse: null,
  isPaymentInfoRequested: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    login: state => state,
    loginSuccess: setUser,
    register: state => state,
    logout: resetUser,
    logoutSuccess: state => state,
    logoutFailure: state => state,
    getCurrentUser: state => {
      state.isPending = true;
    },
    getCurrentUserSuccess: setUser,
    getCurrentUserFailure: state => {
      state.isPending = false;
      state.token = null;
    },
    fetchUsers: state => {
      state.usersPending = true;
    },
    fetchUsersSuccess(state, { payload }) {
      state.usersPending = false;
      state.users = normalize(payload.data.results);
      state.pagination = payload.data.pagination;
    },
    fetchUsersFailure: state => {
      state.usersPending = false;
    },
    grantManagerPrivileges: state => {
      state.isPending = true;
    },
    grantManagerPrivilegesSuccess: updateUserInList,
    grantManagerPrivilegesFailure: state => {
      state.isPending = false;
    },
    denyManagerPrivileges: state => {
      state.isPending = true;
    },
    denyManagerPrivilegesSuccess: updateUserInList,
    denyManagerPrivilegesFailure: state => {
      state.isPending = false;
    },
    setRecoverPasswordModal: (state, { payload }) => {
      state.recoverPasswordModal = payload;
    },
    recoverPassword: state => {
      state.isPending = true;
      state.recoverPasswordModal = true;
    },
    recoverPasswordSuccess: state => {
      state.isPending = false;
      state.recoverPasswordModal = true;
    },
    recoverPasswordFailure: state => {
      state.isPending = false;
      state.recoverPasswordModal = false;
    },
    resetPassword: state => {
      state.isPending = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.isPending = false;
      setUser(state, action);
    },
    resetPasswordFailure: state => {
      state.isPending = false;
    },
    fetchUser: state => {
      state.usersPending = true;
    },
    fetchUserSuccess: (state, { payload }) => {
      const { data } = payload;
      state.usersPending = false;
      state.users[data.id] = data;
    },
    fetchUserFailure: state => {
      state.usersPending = false;
    },
    createUser: state => {
      state.usersPending = true;
    },
    createUserSuccess: state => {
      state.usersPending = false;
    },
    createUserFailure: state => {
      state.usersPending = false;
    },
    updateUser: state => {
      state.usersPending = true;
    },
    updateUserSuccess: (state, { payload }) => {
      const { data } = payload;
      state.users[data.id] = data;
      state.usersPending = false;
    },
    updateUserFailure: state => {
      state.usersPending = false;
    },
    checkConfirmationToken: state => {
      state.confirmationPending = true;
      state.tokenConfirmationError = null;
    },
    checkConfirmationTokenSuccess: state => {
      state.confirmationPending = false;
    },
    checkConfirmationTokenFailure: (state, { payload }) => {
      const { response } = payload;
      state.confirmationPending = false;
      state.tokenConfirmationError = response?.token?.[0];
    },
    sendConfirmationEmail: state => {
      state.confirmationEmailSent = null;
      state.confirmationPending = true;
    },
    sendConfirmationEmailSuccess: state => {
      state.confirmationEmailSent = true;
      state.confirmationPending = false;
    },
    sendConfirmationEmailFailure: state => {
      state.confirmationEmailSent = false;
      state.confirmationPending = false;
    },
    confirmUser: state => {
      state.confirmationPending = true;
    },
    confirmUserSuccess: (state, action) => {
      state.confirmationPending = false;
      setUser(state, action);
    },
    confirmUserFailure: state => {
      state.confirmationPending = false;
    },
    resetPin: state => {
      state.pin = '';
    },
    generatePin: state => {
      state.pinPending = true;
    },
    generatePinSuccess: (state, { payload }) => {
      const { pin } = payload.data;
      state.pinPending = false;
      state.pin = pin;
    },
    generatePinFailure: state => {
      state.pinPending = false;
    },
    requestPaymentInformation: state => {
      state.isPaymentInfoRequested = true;
    },
    requestPaymentInformationSuccess: state => {
      state.isPaymentInfoRequested = false;
    },
    requestPaymentInformationFailure: state => {
      state.isPaymentInfoRequested = false;
    },
  },
  extraReducers: {
    [inventoriesActions.createInventoryManualCountingSuccess]: state => {
      state.openManualCount += 1;
    },
    [inventoriesActions.approveInventoryManualCountingSuccess]: state => {
      state.openManualCount -= 1;
      if (state.openManualCount < 0) {
        state.openManualCount = 0;
      }
    },
    [inventoriesActions.rejectInventoryManualCountingSuccess]: state => {
      state.openManualCount -= 1;
      if (state.openManualCount < 0) {
        state.openManualCount = 0;
      }
    },
  },
});

export const actions = actionTypes(userSlice.actions);

export default userSlice.reducer;
