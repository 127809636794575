export const IN_STOCK = [
  { value: undefined, label: 'Is in stock' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const ARCHIVED_OPTIONS = [
  { value: false, label: 'Not archived' },
  { value: true, label: 'Archived' },
];
