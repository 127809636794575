import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './Footer.scss';
import { Button } from 'reactstrap';

const Footer = ({ selectedImages, onClick }) => {
  const { length } = selectedImages;
  const titles = selectedImages.map(i => i.title).join(', ');

  if (!length) return null;

  return (
    <div className={styles.footer}>
      <div styles={styles.selectedInformation}>
        {length} {length === 1 ? 'image is' : 'images are'} selected ({titles})
      </div>
      <Button color="primary" onClick={() => onClick(selectedImages)}>
        Add image(s)
      </Button>
    </div>
  );
};

Footer.propTypes = {
  selectedImages: PropTypes.array,
  onClick: PropTypes.func,
};

export default memo(Footer);
