import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const tastingRoomsSlice = createSlice({
  name: 'tastingRooms',
  initialState: {
    isPending: false,
    tastingRooms: [],
    tastingRoom: {},
    pagination: {},
  },
  reducers: {
    fetchTastingRooms: state => {
      state.isPending = true;
    },
    fetchTastingRoomsSuccess(state, { payload }) {
      state.isPending = false;
      state.tastingRooms = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchTastingRoomsFailure: state => {
      state.isPending = false;
    },
    fetchTastingRoom: state => {
      state.isPending = true;
    },
    fetchTastingRoomSuccess(state, { payload }) {
      state.isPending = false;
      state.tastingRoom = payload.data;
    },
    fetchTastingRoomFailure: state => {
      state.isPending = false;
    },
    createTastingRoom: state => {
      state.isPending = true;
    },
    createTastingRoomSuccess(state, { payload }) {
      state.tastingRooms = state.tastingRooms.concat(payload.data);
      state.isPending = false;
    },
    createTastingRoomFailure: state => {
      state.isPending = false;
    },
    deleteTastingRoom: state => {
      state.isPending = true;
    },
    deleteTastingRoomSuccess(state, { payload }) {
      state.tastingRooms = state.tastingRooms.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteTastingRoomFailure: state => {
      state.isPending = false;
    },
    updateTastingRoom: state => {
      state.isPending = true;
    },
    updateTastingRoomSuccess(state, { payload }) {
      state.tastingRooms.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateTastingRoomFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(tastingRoomsSlice.actions);

export default tastingRoomsSlice.reducer;
