import React from 'react';
import { Card, CardBody } from 'reactstrap';
import InventoryTransferTable from 'components/InventoryTransferTable';
import { TRANSFER_TABLE_MODES } from 'components/InventoryTransferTable/constants';
import PropTypes from 'prop-types';

const MerchCount = ({
  inventories,
  setEditorMode,
  inventoryCount,
  inventoryCountType,
  onSubmit,
  isManual,
}) => (
  <Card>
    <CardBody>
      <InventoryTransferTable
        key="MerchCountInventoryTransferTable"
        data={inventories}
        editorMode={TRANSFER_TABLE_MODES.PROCESS}
        setEditorMode={setEditorMode}
        isManual={isManual}
        inventoryCount={inventoryCount}
        inventoryCountType={inventoryCountType}
        onSubmit={onSubmit}
      />
    </CardBody>
  </Card>
);

MerchCount.propTypes = {
  inventories: PropTypes.array.isRequired,
  inventoryCount: PropTypes.bool.isRequired,
  inventoryCountType: PropTypes.string.isRequired,
  setEditorMode: PropTypes.func.isRequired,
  isManual: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default MerchCount;
