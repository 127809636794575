import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as tablesActions } from 'models/tables/slice';
import useSelector from 'hooks/useSelector';
import {
  tablesSelector,
  isPendingSelector as tablesIsPendingSelector,
  paginationSelector,
} from 'models/tables/selectors';
import { isPendingSelector as tastingRoomsIsPendingSelector } from 'models/tastingRooms/selectors';

import DataTable from './DataTable';

const TablesSummary = ({ title }) => {
  const fetchTables = useAction(tablesActions.fetchTables);
  const tables = useSelector(tablesSelector);
  const tastingRoomsIsPending = useSelector(tastingRoomsIsPendingSelector);
  const tableIsPending = useSelector(tablesIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const isPending = tableIsPending || tastingRoomsIsPending;

  const [sortField, setSortField] = useState(query.get('sort_field') || '');
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'asc'
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchTables({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        set_query: true,
      });
    }
  }, [filters, fetchTables, currentPage, sortDirection, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortDirection, sortField]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasFilterByRoom
                    hasWarehousesFilter
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    hasCreateItem
                    createItemLink="/tables/create"
                    disabled={isPending}
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  tables={tables}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

TablesSummary.propTypes = {
  title: PropTypes.string,
};

export default TablesSummary;
