/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  Alert,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { CUSTOMER_PAYMENT_TYPES, HUMAN_LEADS_STATUSES } from 'constants';
import useAction from 'hooks/useAction';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as customersActions } from 'models/customers/slice';
import Payment from 'components/Payment';
import useSelector from 'hooks/useSelector';
import { tabs } from 'constants/organizations';
import Main from './Main';
import {
  createdOrganizationSelector,
  currentTabSelector,
  fieldErrorsSelector,
  blockMainInformationTabSelector,
  existingCustomerSelector,
} from 'models/organizations/selectors';
import {
  hasOwnerOrManagerRoleSelector,
  rolesSelector,
  canEditDefaultWarehouseSelector,
} from 'models/user/selectors';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
  paymentTypesAvailableSelector,
} from 'models/customers/selectors';
import { allBrandsSelector } from 'models/brands/selectors';
import PreloadedBrandsField from 'components/PaginatedFields/PreloadedBrandsField';
import styles from './OrganizationForm.scss';
import { getRedactedValues } from 'utils/getRedactedValues';
import PaymentDropdown from 'components/PaymentDropdown';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import PaymentTypesTable from 'components/PaymentTypesTable';
import capitalize from 'lodash/capitalize';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';
import PopupBase from '../Popup/PopupBase';

// Constants
const snakeToCamel = str =>
  capitalize(
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', ' ')
        .replace('_', ' ')
    )
  );

const OrganizationForm = ({
  disabled,
  disableEditing,
  onSubmit,
  model,
  submitTitle,
  isLead,
  isEditing,
  isCreate,
}) => {
  // Selectors
  const allBrands = useSelector(allBrandsSelector);
  const currentTab = useSelector(currentTabSelector);
  const blockMainInformationTab = useSelector(blockMainInformationTabSelector);
  const existingCustomer = useSelector(existingCustomerSelector);
  const createdOrganization = useSelector(createdOrganizationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const paymentTypesAvailable = useSelector(paymentTypesAvailableSelector);
  const fieldErrors = useSelector(fieldErrorsSelector);
  const canEditDefaultWarehouse = useSelector(canEditDefaultWarehouseSelector);
  const hasOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const roles = useSelector(rolesSelector);

  // Variables
  const { lead_status } = model || {};
  const {
    country: customerCountry = '',
    state: customerState = '',
    city: customerCity = '',
    line1: customerLine1 = '',
    line2: customerLine2 = '',
    postal_code: customerPostalCode = '',
  } = model?.address || {};

  // States
  const [currentType, setCurrentType] = useState(
    model
      ? {
          label: model?.company_type,
          value: model?.company_type,
        }
      : null
  );
  const [currentLeadStatus, setCurrentLeadStatus] = useState(
    lead_status
      ? {
          label: HUMAN_LEADS_STATUSES[lead_status],
          value: lead_status,
        }
      : null
  );
  const [followUpDate, setFollowUpDate] = useState(
    model?.follow_up_date || null
  );
  const [defaultWarehouse, setDefaultWarehouse] = useState(null);
  const [salesRep, setSalesRep] = useState(
    model?.sales_rep
      ? {
          value: model.sales_rep.id,
          label: `${model.sales_rep.first_name} ${model.sales_rep.last_name}`,
        }
      : null
  );
  const [licenseNumber, setLicenseNumber] = useState(model?.license_number);
  const [
    isOpenExistingCustomerModal,
    setIsOpenExistingCustomerModal,
  ] = useState(false);
  const [currentBrand, setCurrentBrand] = useState({});
  const [isPaymentFormOpen, setPaymentFormOpen] = useState(false);
  const [isPaymentTypeOpen, setPaymentTypeOpen] = useState(false);
  const [isReplaceContactPerson, setIsReplaceContactPerson] = useState(false);
  const [contactPersonEditable, setContactPersonEditable] = useState(false);
  const [country, setCountry] = useState(customerCountry);
  const [state, setState] = useState(customerState);
  const [postalCode, setPostalCode] = useState(customerPostalCode);
  const [city, setCity] = useState(customerCity);
  const [line1, setLine1] = useState(customerLine1);
  const [line2, setLine2] = useState(customerLine2);
  const [businessName, setBusinessName] = useState(model?.title);
  const [cardOptions, setCardOptions] = useState([]);
  const [paymentType, setPaymentType] = useState();
  const [contactFirstName, setContactFirstName] = useState(
    model?.contact_person_first_name || ''
  );
  const [contactLastName, setContactLastName] = useState(
    model?.contact_person_last_name || ''
  );
  const [contactEmail, setContactEmail] = useState(
    model?.contact_person_email || ''
  );
  const [contactPhone, setContactPhone] = useState(
    model?.contact_person_phone || ''
  );

  // Actions
  const setCurrentTab = useAction(organizationsActions.setCurrentTab);
  const resetFieldErrors = useAction(organizationsActions.resetFieldErrors);
  const resetBlockMainInformationTab = useAction(
    organizationsActions.resetBlockMainInformationTab
  );
  const updateContactPersonEmail = useAction(
    organizationsActions.updateContactPersonEmail
  );
  const replaceContactPerson = useAction(
    organizationsActions.replaceContactPerson
  );
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const createPaymentType = useAction(customersActions.createPaymentType);
  const removePaymentType = useAction(customersActions.removePaymentType);
  const makeDefaultPaymentType = useAction(
    customersActions.makeDefaultPaymentType
  );
  const fetchPaymentTypesAvailable = useAction(
    customersActions.fetchPaymentTypesAvailable
  );
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const makeDefaultPaymentMethod = useAction(
    customersActions.makeDefaultPaymentMethod
  );

  const isPaymentVisible =
    currentBrand?.id && createdOrganization && paymentTypes;
  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const paymentTypesData = paymentTypes.filter(
    type => type?.brand_id === currentBrand.id
  );
  const isCardTypeDefault = paymentTypesData.some(
    item => item.type === CUSTOMER_PAYMENT_TYPES.CARD && item.is_default
  );

  // Memos
  const paymentTypeOptions = useMemo(() => {
    const options = paymentTypesAvailable.map(value => ({
      value,
      label: snakeToCamel(value),
    }));
    if (!paymentTypesAvailable.includes(CUSTOMER_PAYMENT_TYPES.CARD)) {
      options.push({ value: CUSTOMER_PAYMENT_TYPES.CARD, label: 'Card' });
    }
    if (
      isSalesRep &&
      !paymentTypesAvailable.includes(CUSTOMER_PAYMENT_TYPES.CASH)
    ) {
      options.push({ value: CUSTOMER_PAYMENT_TYPES.CASH, label: 'Cash' });
    }

    return options;
  }, [paymentTypesAvailable, isSalesRep]);

  const isPaymentMethodTableVisible = useMemo(() => {
    const isVisible = !isPaymentFormOpen && !isPaymentTypeOpen;
    if (hasOwnerOrManagerRole) {
      return isVisible && isCardTypeDefault;
    }
    return isVisible;
  }, [
    isCardTypeDefault,
    isPaymentFormOpen,
    isPaymentTypeOpen,
    hasOwnerOrManagerRole,
  ]);

  const inPaymentTypeButtonVisible = useMemo(() => {
    if (isSalesRep) {
      return paymentTypesData.length === 0;
    }
    return hasOwnerOrManagerRole;
  }, [hasOwnerOrManagerRole, paymentTypesData, isSalesRep]);

  const isDefaultWarehouseDisabled = useMemo(() => {
    if (isSalesRep) {
      return disableEditing ? !canEditDefaultWarehouse : false;
    }
    return !hasOwnerOrManagerRole;
  }, [
    hasOwnerOrManagerRole,
    isSalesRep,
    canEditDefaultWarehouse,
    disableEditing,
  ]);

  // Handlers
  const onPaymentTypeSaveHandler = () => {
    if (paymentType?.value && currentBrand?.id) {
      const customerId = createdOrganization.contact_person.id;
      createPaymentType({
        customerId,
        type: paymentType.value,
        brand_id: currentBrand.id,
      });
    }
  };

  const onPaymentTypeRemoveHandler = item => {
    const customerId = createdOrganization.contact_person.id;
    const id = item?.id;
    if (id && customerId) {
      removePaymentType({ customerId, id, type: item?.type });
    }
  };

  const onPaymentTypeMakeDefaultHandler = item => {
    const customerId = createdOrganization.contact_person.id;
    const id = item?.id;
    if (id && customerId) {
      makeDefaultPaymentType({ customerId, id });
    }
  };

  const onPaymentSuccessHandler = () => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      fetchPaymentTypes(customerId);
    }
    setPaymentFormOpen(false);
    setPaymentTypeOpen(false);
    setPaymentType(null);
  };

  const onPaymentRemoveHandler = methodId => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      removePaymentMethod({ id: methodId, customerId });
    }
  };

  const onPaymentMakeDefaultHandler = methodId => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      makeDefaultPaymentMethod({ id: methodId, customerId });
    }
  };

  const onPaymentTypeChangeHandler = data => {
    setPaymentFormOpen(data.value === 'CARD');
    setPaymentType(data);
  };

  const onAddPaymentTypeHandler = () => {
    setPaymentTypeOpen(true);
  };

  const onAddCardHandler = () => {
    setPaymentFormOpen(true);
  };

  const handleEmailChange = event => {
    const submittedValues = {
      contact_person_email: event.email,
      confirm_contact_person_email: event.confirm_email,
    };
    const redactedValues = getRedactedValues(model, submittedValues);
    updateContactPersonEmail({
      id: model?.id,
      ...redactedValues,
    });
  };

  const handleReplaceContactPerson = values => {
    if (!isOpenExistingCustomerModal) {
      setContactPersonEditable(false);
      setIsReplaceContactPerson(false);
    }
    replaceContactPerson({
      id: model?.id,
      ...values,
    });
  };

  const handleBrandChange = selectedOption => {
    const fullBrand = allBrands.find(
      brand => brand.id === selectedOption.value
    );
    setCurrentBrand(fullBrand || {});
  };

  // Effects
  useEffect(() => {
    if (existingCustomer) {
      setIsOpenExistingCustomerModal(true);
    }
  }, [existingCustomer]);

  const handleSubmit = (event, values) => {
    if (isReplaceContactPerson) {
      handleReplaceContactPerson(values);
    } else if (values.title) {
      const submittedValues = {
        ...values,
        city,
        country,
        line1,
        line2,
        postal_code: postalCode,
        default_warehouse_id: defaultWarehouse?.id,
        follow_up_date: followUpDate,
        state,
        company_type: currentType?.value,
        lead_status: currentLeadStatus?.value,
        sales_rep_id: salesRep?.value,
        license_number: licenseNumber,
        contact_person_first_name: contactFirstName,
        contact_person_last_name: contactLastName,
        contact_person_email: contactEmail,
        contact_person_phone: contactPhone,
        title: businessName,
      };
      if (!isOpenExistingCustomerModal) {
        setIsReplaceContactPerson(false);
      }

      const redactedValues = getRedactedValues(model, submittedValues);

      onSubmit({
        id: model?.id,
        ...redactedValues,
        isLead,
      });
    }
  };

  useEffect(() => {
    resetFieldErrors();
  }, []);

  useEffect(() => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      fetchPaymentTypes(customerId);
    }
  }, [hasOwnerOrManagerRole, createdOrganization]);

  useEffect(() => {
    const customerId = createdOrganization?.contact_person?.id;
    const brandId = currentBrand?.id;

    if (hasOwnerOrManagerRole && customerId && brandId) {
      fetchPaymentTypesAvailable({ customerId, brandId });
    }
  }, [hasOwnerOrManagerRole, createdOrganization, currentBrand]);

  useEffect(() => {
    return () => {
      resetBlockMainInformationTab();
      setCurrentTab(tabs[0]);
    };
  }, [resetBlockMainInformationTab, setCurrentTab]);

  useEffect(() => {
    setPaymentType(null);
  }, [paymentTypesAvailable]);

  useEffect(() => {
    setPaymentFormOpen(false);
    setPaymentTypeOpen(false);
    setPaymentType(null);
  }, [paymentTypes]);

  useEffect(() => {
    if (model?.default_warehouse) {
      const warehouseId = model.default_warehouse.id;
      const warehouseTitle = model.default_warehouse.title;
      setDefaultWarehouse({
        value: warehouseId,
        label: warehouseTitle,
      });
    }
  }, [model]);

  return (
    <>
      {!isLead && (
        <Row>
          <Col lg={12}>
            <Nav tabs className="nav-tabs-simple mb-4">
              <NavItem>
                <NavLink
                  className={classNames(
                    'font-weight-bold p-3',
                    tabs[0] === currentTab && 'active',
                    blockMainInformationTab && 'disabled'
                  )}
                  onClick={() => {
                    if (!blockMainInformationTab) {
                      setCurrentTab(tabs[0]);
                    }
                  }}
                >
                  {tabs[0]}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames(
                    'font-weight-bold p-3',
                    tabs[1] === currentTab && 'active'
                  )}
                  onClick={() => {
                    if (createdOrganization) {
                      setCurrentTab(tabs[1]);
                    }
                  }}
                >
                  {tabs[1]}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <AvForm
            model={model}
            className="needs-validation"
            onValidSubmit={handleSubmit}
          >
            {(currentTab === tabs[0] || isLead) && (
              <Main
                model={model}
                disabled={disabled}
                disableEditing={disableEditing}
                currentType={currentType}
                currentLeadStatus={currentLeadStatus}
                setCurrentType={setCurrentType}
                setCurrentLeadStatus={setCurrentLeadStatus}
                defaultWarehouse={defaultWarehouse}
                handleDefaultWarehouseChange={setDefaultWarehouse}
                defaultWarehouseDisabled={isDefaultWarehouseDisabled}
                country={country}
                state={state}
                postalCode={postalCode}
                city={city}
                line1={line1}
                line2={line2}
                setCountry={setCountry}
                setState={setState}
                setPostalCode={setPostalCode}
                setCity={setCity}
                setLine1={setLine1}
                setLine2={setLine2}
                submitTitle={submitTitle}
                handleConfirm={handleSubmit}
                handleEmailChange={handleEmailChange}
                handleReplaceContactPerson={handleReplaceContactPerson}
                fieldErrors={fieldErrors}
                isSalesRep={isSalesRep}
                isLead={isLead}
                followUpDate={followUpDate}
                setFollowUpDate={setFollowUpDate}
                salesRep={salesRep}
                setSalesRep={setSalesRep}
                setLicenseNumber={setLicenseNumber}
                licenseNumber={licenseNumber}
                isEditing={isEditing}
                isReplaceContactPerson={isReplaceContactPerson}
                setIsReplaceContactPerson={setIsReplaceContactPerson}
                contactFirstName={contactFirstName}
                setContactFirstName={setContactFirstName}
                contactLastName={contactLastName}
                setContactLastName={setContactLastName}
                contactEmail={contactEmail}
                setContactEmail={setContactEmail}
                contactPhone={contactPhone}
                setContactPhone={setContactPhone}
                isCreate={isCreate}
                businessName={businessName}
                setBusinessName={setBusinessName}
                contactPersonEditable={contactPersonEditable}
                setContactPersonEditable={setContactPersonEditable}
              />
            )}
            {currentTab === tabs[1] && !isLead && (
              <>
                <Row className="d-flex align-items-end mb-2">
                  <Col md={3}>
                    <Label required htmlFor="brands">
                      Brand
                    </Label>
                    <PreloadedBrandsField
                      name="brand_id"
                      value={currentBrand}
                      onChange={handleBrandChange}
                    />
                  </Col>
                  {currentBrand?.id &&
                    currentBrand?.active_stripe_acquirer?.public_key && (
                      <Col className="d-flex justify-content-end">
                        {inPaymentTypeButtonVisible ? (
                          <Button
                            onClick={onAddPaymentTypeHandler}
                            color="success"
                          >
                            <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                            Add Payment Type
                          </Button>
                        ) : (
                          <Button onClick={onAddCardHandler} color="success">
                            <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                            Add Card
                          </Button>
                        )}
                      </Col>
                    )}
                </Row>
                {isPaymentVisible && paymentTypeOptions.length > 0 && (
                  <Row className="my-2 align-items-center">
                    {isPaymentTypeOpen && (
                      <>
                        <Col md={3}>
                          <Select
                            id="payment_type"
                            value={paymentType}
                            onChange={onPaymentTypeChangeHandler}
                            options={paymentTypeOptions}
                            styles={selectStyles}
                            placeholder="Select Payment type"
                          />
                        </Col>
                        {paymentType?.value != null &&
                          paymentType.value !== 'CARD' && (
                            <Col md={3}>
                              <Button
                                onClick={onPaymentTypeSaveHandler}
                                color="primary"
                              >
                                Save
                              </Button>
                            </Col>
                          )}
                      </>
                    )}
                    <Col className="mt-2" md={12}>
                      {currentBrand?.active_stripe_acquirer?.public_key ? (
                        <Payment
                          stripeToken={
                            currentBrand?.active_stripe_acquirer?.public_key
                          }
                          currentCustomerId={
                            createdOrganization?.contact_person?.id
                          }
                          currentBrand={currentBrand}
                          cardOptions={cardOptions}
                          setCardOptions={setCardOptions}
                          withPaymentMethodsTable={false}
                          isFormHidden={!isPaymentFormOpen}
                          onSuccess={onPaymentSuccessHandler}
                        />
                      ) : (
                        <Alert color="warning">
                          <i className="mdi mdi-information" />
                          <b>No active acquirer</b>
                          <p>Please add one in brands settings.</p>
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}
                {currentBrand?.active_stripe_acquirer?.public_key &&
                currentBrand?.id ? (
                  <Row>
                    <Col md={12}>
                      <PaymentTypesTable
                        paymentTypes={paymentTypesData}
                        onMakeDefault={onPaymentTypeMakeDefaultHandler}
                        onDelete={onPaymentTypeRemoveHandler}
                        isActionVisible={hasOwnerOrManagerRole}
                      >
                        {isSalesRep && paymentTypesData?.length > 0 ? (
                          <Alert color="warning">
                            <p>
                              <i className="mdi mdi-information font-size-14 mr-2" />
                              Contact winery to manage payment types if not
                              using card payments.
                            </p>
                          </Alert>
                        ) : null}
                      </PaymentTypesTable>
                    </Col>
                    {isPaymentMethodTableVisible ? (
                      <Col md={12}>
                        <PaymentMethodsTable
                          title="Default Card for Card Transactions"
                          paymentMethods={paymentMethods.filter(
                            method => method?.brand_id === currentBrand.id
                          )}
                          actionNode={id => (
                            <PaymentDropdown
                              onMakeDefault={() =>
                                onPaymentMakeDefaultHandler(id)
                              }
                              onDelete={() => onPaymentRemoveHandler(id)}
                            />
                          )}
                        />
                      </Col>
                    ) : null}
                  </Row>
                ) : null}
                <NavLink className={styles.buttonLink}>
                  <Button
                    type="button"
                    className={styles.button}
                    color="primary"
                  >
                    Save
                  </Button>
                </NavLink>
              </>
            )}
          </AvForm>
        </Col>
      </Row>
      {isOpenExistingCustomerModal && (
        <PopupBase
          className={styles.narrowModal}
          onClose={() => setIsOpenExistingCustomerModal(false)}
        >
          <p className="mb-3">
            Looks like this person&apos;s email address already exists. Is{' '}
            <span className="font-weight-bold">
              {existingCustomer?.contact_person_first_name}{' '}
              {existingCustomer?.contact_person_last_name}
            </span>{' '}
            the person you&apos;re trying to add?
          </p>
          <div className="d-flex flex-row justify-content-around">
            <Button
              className="my-1 mr-2 w-100"
              onClick={() => {
                handleReplaceContactPerson({
                  contact_person_email: existingCustomer.contact_person_email,
                  confirmation: true,
                });
              }}
            >
              Yes
            </Button>
            <Button
              className="my-1 ml-2 w-100"
              onClick={() => {
                setIsOpenExistingCustomerModal(false);
              }}
            >
              No
            </Button>
          </div>
        </PopupBase>
      )}
    </>
  );
};

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disableEditing: PropTypes.bool,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  isLead: PropTypes.bool,
  isEditing: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default OrganizationForm;
