import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'reactstrap';
import Datepicker from 'components/Datepicker';
import styles from './DateRangePicker.scss';
import { format } from 'date-fns';
import cx from 'classnames';

const getSelectionLabel = (dateRange, rangeStyle) => {
  if (!dateRange.startDate && !dateRange.endDate) {
    return 'Date range';
  }

  const ft = rangeStyle === 'basic' ? 'MM/dd/yyyy' : 'MMMM do, yyyy';
  const start = format(dateRange.startDate, ft);
  const end = format(dateRange.endDate, ft);
  if (start === end && rangeStyle === 'literal') {
    return start;
  }
  return `${start} - ${end}`;
};

const getRequestFormatDate = dataRange => {
  return {
    startDate: dataRange.startDate,
    endDate: dataRange.endDate,
  };
};

const DateRangePicker = ({
  onDateChange,
  placement,
  defaultToToday,
  rangeStyle,
  startDate,
  endDate,
  ...props
}) => {
  const [datepickerOpened, setDatepickerOpened] = useState(false);
  const toggleDatepickerOpened = () => setDatepickerOpened(!datepickerOpened);
  const popOverRef = useRef();

  let dateStart;
  let dateEnd;
  if (startDate) {
    dateStart = new Date(startDate);
  } else if (defaultToToday) {
    dateStart = new Date();
  }

  if (endDate) {
    dateEnd = new Date(endDate);
  } else if (defaultToToday) {
    dateEnd = new Date();
  }
  const [dateRange, setDateRange] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: 'selection',
    },
  ]);

  const updateDateRange = range => {
    setDateRange(range);
  };

  const label =
    dateRange && dateRange.length > 0
      ? getSelectionLabel(dateRange[0], rangeStyle)
      : 'Select date range';

  const checkClickOutside = useCallback(event => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setDatepickerOpened(false);
    }
  }, []);

  useEffect(() => {
    if (datepickerOpened === true) {
      document.removeEventListener('click', checkClickOutside);
      document.addEventListener('click', checkClickOutside);
    } else {
      document.removeEventListener('click', checkClickOutside);
    }
  }, [datepickerOpened]);

  useEffect(() => {
    if (onDateChange) {
      if (!dateRange[0].startDate && !dateRange[0].endDate) {
        return;
      }
      onDateChange(getRequestFormatDate(dateRange[0]));
    }
  }, [dateRange]);

  return (
    <div>
      {rangeStyle === 'basic' && (
        <Button
          id="DateRangePicker"
          type="button"
          className={styles.daterange}
          color="light"
          onClick={toggleDatepickerOpened}
        >
          <i className="ri-calendar-line mr-2 align-middle" />
          <span>{label}</span>
          <i className="ri-arrow-down-s-line ml-4 align-middle" />
        </Button>
      )}
      {rangeStyle === 'literal' && (
        <div className={styles.toggleLiteral} onClick={toggleDatepickerOpened}>
          <h2>{label}</h2>
          <Button
            id="DateRangePicker"
            type="button"
            color="light"
            className={styles.roundedButton}
          >
            <i className={cx('ri-calendar-2-line align-middle', styles.icon)} />
          </Button>
        </div>
      )}
      <Popover
        placement={placement}
        isOpen={datepickerOpened}
        target="DateRangePicker"
        strategy="absolute"
        popperClassName="no-max-width"
        modifiers={{ name: 'preventOverflow', enabled: false }}
        toggle={toggleDatepickerOpened}
      >
        <div className={styles.calendar} ref={popOverRef}>
          <Datepicker value={dateRange} onChange={updateDateRange} {...props} />
        </div>
      </Popover>
    </div>
  );
};

DateRangePicker.propTypes = {
  onDateChange: PropTypes.func,
  placement: PropTypes.oneOf([
    'bottom-start',
    'bottom-end',
    'auto-end',
    'bottom',
  ]),
  rangeStyle: PropTypes.oneOf(['basic', 'literal']),
  defaultToToday: PropTypes.bool,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
};

DateRangePicker.defaultProps = {
  onDateChange: null,
  placement: 'bottom-start',
  defaultToToday: false,
  rangeStyle: 'basic',
};

export default DateRangePicker;
