import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

const DropdownItemId = Object.freeze({
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  SEND_CONFIRMATION_EMAIL: 'send_confirmation_email',
});

const UserActionDropdown = ({
  user,
  onArchive,
  onRestore,
  onSendConfirmationEmail,
  isArchived,
  isCurrentUser,
}) => {
  const dropdownItem = [
    {
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/users/${user.id}/edit`,
    },
    {
      id: DropdownItemId.SEND_CONFIRMATION_EMAIL,
      icon: <i className="mdi mdi-email font-size-18 mr-2" />,
      label: 'Send Confirmation Email',
      isHidden: !!user.confirmed_at || !user.confirmation_token,
    },
    {
      id: DropdownItemId.RESTORE,
      icon: <i className="mdi mdi-restore font-size-18 mr-2" />,
      label: 'Restore',
      isHidden: !isArchived,
    },
    {
      id: DropdownItemId.ARCHIVE,
      icon: <i className="mdi mdi-archive font-size-18 mr-2" />,
      label: 'Archive',
      isHidden: isArchived || isCurrentUser,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.ARCHIVE]: onArchive,
      [DropdownItemId.RESTORE]: onRestore,
      [DropdownItemId.SEND_CONFIRMATION_EMAIL]: onSendConfirmationEmail,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      right
    />
  );
};

UserActionDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onSendConfirmationEmail: PropTypes.func.isRequired,
  isArchived: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
};

export default UserActionDropdown;
