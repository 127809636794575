import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import TextField from '../Fields/TextField';
import FileField from '../FileField';

const Form = ({ model, submitTitle, onSubmit }) => {
  const [image, setImage] = useState(null);
  const [imageError, setError] = useState(null);

  const handleSubmit = useCallback(
    (_, values) => {
      const payload = { ...values };
      if (!image && !model.image_url) {
        setError('Please select an image');
        return;
      }
      if (image) {
        payload.image = image;
      }

      onSubmit({ id: model?.id, ...payload });
    },
    [onSubmit, image, model.image_url]
  );

  const handleSetImage = data => {
    setError(null);
    setImage(data);
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
    >
      <Row>
        <Col md="4">
          <TextField
            name="title"
            label="Title"
            errorMessage="Enter Title"
            isRequired
          />
        </Col>
        <Col md="4">
          <TextField name="competition" label="Competition" isRequired />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FileField
            label="Logo"
            onChange={handleSetImage}
            preview={image || model?.image_v_100x100_url}
            accept="image/jpeg,image/png"
            error={imageError}
          />
        </Col>
      </Row>
      <Button color="primary" type="submit">
        {submitTitle}
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  submitTitle: PropTypes.string,
};

Form.defaultProps = {
  model: {},
};

export default Form;
