export const getInitialOption = (
  query,
  key,
  optionsList,
  findField = 'value'
) => {
  const currentValue = query.get(key);
  return (
    optionsList?.find(option => {
      return String(option?.[findField]) === currentValue;
    }) ?? optionsList[0]
  );
};
