import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const orderShipmentRates = createSlice({
  name: 'orderShipmentRates',
  initialState: {
    isPending: false,
    isCreating: false,
    shipmentUpdated: false,
    createdShipment: null,
    rates: [],
    modalOpen: false,
    callNextAction: false,
    updateShippingPending: false,
  },
  reducers: {
    fetchShipmentRates(state) {
      state.isPending = true;
      state.rates = [];
    },
    fetchShipmentRatesSuccess(state, { payload }) {
      state.isPending = false;
      state.rates = {
        ...payload.data,
        rates: payload.data.rates?.map((i, index) => ({
          ...i,
          local_id: index + 1,
        })),
      };
    },
    fetchShipmentRatesFailure(state) {
      state.isPending = false;
    },

    setShipmentRatesModalOpen(state, { payload }) {
      state.modalOpen = payload;
    },
    createShipment(state) {
      state.isCreating = true;
    },
    createShipmentSuccess(state, { payload }) {
      state.isCreating = false;
      state.createdShipment = payload.data;
      state.callNextAction = true;
    },
    createShipmentFailure(state) {
      state.isCreating = false;
    },
    updateShipment(state) {
      state.isPending = true;
      state.shipmentUpdated = false;
    },
    updateShipmentSuccess(state) {
      state.isPending = false;
      state.shipmentUpdated = true;
    },
    updateShipmentFailure(state) {
      state.isPending = false;
    },
    deleteShipment(state) {
      state.isCreating = true;
    },
    deleteShipmentSuccess(state) {
      state.isCreating = false;
      state.createdShipment = null;
      state.callNextAction = true;
    },
    deleteShipmentFailure(state) {
      state.isCreating = false;
    },
    setNewShipment(state, { payload }) {
      state.createdShipment = payload;
    },
    cancelCallingNextAction(state) {
      state.callNextAction = false;
    },
    updateShipping: state => {
      state.updateShippingPending = true;
    },
    updateShippingSuccess: state => {
      state.updateShippingPending = false;
    },
    updateShippingFailure: state => {
      state.updateShippingPending = false;
    },
  },
});

export const actions = actionTypes(orderShipmentRates.actions);

export default orderShipmentRates.reducer;
