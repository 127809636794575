import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

function updateOrder(state, { payload }) {
  state.subscriptionOrders = state.subscriptionOrders.map(item => {
    if (item.id !== payload.data.id) {
      return item;
    }
    return {
      ...item,
      ...payload.data,
    };
  });
  state.isPending = false;
}

const subscriptionOrdersSlice = createSlice({
  name: 'subscriptionOrders',
  initialState: {
    isPending: false,
    subscriptionOrders: [],
    pagination: {},
  },
  reducers: {
    fetchSubscriptionOrders: state => {
      state.isPending = true;
    },
    fetchSubscriptionOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.subscriptionOrders = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchSubscriptionOrdersFailure: state => {
      state.isPending = false;
    },
    updateSubscriptionOrder: state => {
      state.isPending = true;
    },
    updateSubscriptionOrderSuccess: updateOrder,

    updateSubscriptionOrderFailure: state => {
      state.isPending = false;
    },
    cancelObligation: state => {
      state.isPending = true;
    },
    cancelObligationSuccess(state) {
      state.isPending = false;
    },
    cancelObligationFailure: state => {
      state.isPending = false;
    },
    captureSubscriptionOrder: state => {
      state.isPending = true;
    },
    captureSubscriptionOrderSuccess: updateOrder,
    captureSubscriptionOrderFailure: state => {
      state.isPending = false;
    },
    sendSubscriptionOrder: state => {
      state.isPending = true;
    },
    sendSubscriptionOrderSuccess: updateOrder,
    sendSubscriptionOrderFailure: state => {
      state.isPending = false;
    },
    sendApprovalEmailSubscriptionOrder: state => {
      state.isPending = true;
    },
    sendApprovalEmailSubscriptionOrderSuccess: updateOrder,
    sendApprovalEmailSubscriptionOrderFailure: state => {
      state.isPending = false;
    },
    skipSubscriptionOrder: state => {
      state.isPending = true;
    },
    skipSubscriptionOrderSuccess: updateOrder,
    skipSubscriptionOrderFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(subscriptionOrdersSlice.actions);

export default subscriptionOrdersSlice.reducer;
