import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/CommercialOrderForm';

import useSelector from 'hooks/useSelector';

import useAction from 'hooks/useAction';

import Preloader from 'components/Preloader';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';
import { useParams } from 'react-router-dom';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as productsActions } from 'models/products/slice';

import useComponentDidMount from 'hooks/useComponentDidMount';
import {
  createdOrderSelector,
  isPendingSelector,
} from 'models/orders/selectors';

const EditCommercialOrder = ({ title }) => {
  const { orderID } = useParams();
  const fetchProductsCount = useAction(productsActions.fetchProductsCount);
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const warehousesIsPending = useSelector(warehousesIsPendingSelector);
  const orderIsPending = useSelector(isPendingSelector);
  const order = useSelector(createdOrderSelector);

  const isPending = warehousesIsPending || orderIsPending;

  useComponentDidMount(() => {
    fetchOrder(orderID);
  });

  useEffect(() => {
    if (order) {
      const orderItems = order?.order_items ?? [];
      const warehouseId = order?.warehouse.id;
      const productIds = orderItems.map(orderItem => orderItem.product.id);
      fetchProductsCount({ warehouseId, productIds });
    }
  }, [order]);

  if (isPending) {
    return <Preloader />;
  }

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Commercial Order"
        breadcrumbItems={[
          {
            title: 'Back to commercial orders',
            link: '/commercial-orders/',
            withSearch: true,
          },
        ]}
      />
      {!isEmpty(order) && (
        <Row>
          <Col md="12">
            <Form order={order} isCreateForm={false} />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditCommercialOrder.propTypes = {
  title: PropTypes.string,
};

export default EditCommercialOrder;
