import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import {
  getProductTitleWithVintage,
  getProductTitleWithSKUVintage,
} from 'utils/preparedProductTitle';

const selectStyles = {
  menuList: base => ({
    ...base,
    maxHeight: 165,
  }),
};

const ProductsPaginatedField = ({
  onChange,
  isMulti,
  warehouseIds,
  className,
  options,
  withSKU,
  withInventories,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchProducts = ({ page = 1, search }) => {
    const stringified = queryString.stringify(
      {
        page,
        title_contains: search,
        warehouse_ids: warehouseIds,
        ...options,
      },
      {
        arrayFormat: 'comma',
      }
    );

    return apiRequest({
      url: `/products/?${stringified}`,
    });
  };

  const loadProducts = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchProducts({ page, search });
      return {
        options: response?.results.map(item => {
          const inventories = withInventories
            ? { inventories: item?.inventories }
            : {};
          return {
            id: item.id,
            value: item.id,
            label: withSKU
              ? getProductTitleWithSKUVintage(
                  item.title,
                  item?.vintage,
                  item?.sku
                )
              : getProductTitleWithVintage(item.title, item?.vintage),
            ...inventories,
            ...item,
          };
        }),
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="warehouses"
      cacheUniqs={[warehouseIds, options]}
      isSearchable
      debounceTimeout={1000}
      additional={{
        page: 1,
      }}
      loadOptions={loadProducts}
      onChange={onChange}
      isMulti={isMulti}
      className={className}
      styles={selectStyles}
    />
  );
};

ProductsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  warehouseIds: PropTypes.number,
  className: PropTypes.string,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  withSKU: PropTypes.bool,
  withInventories: PropTypes.bool,
};

ProductsPaginatedField.defaultProps = {
  defaultValue: {},
};

export default ProductsPaginatedField;
