import React from 'react';
import PropTypes from 'prop-types';
import styles from './DataTable.scss';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Button } from 'reactstrap';

const DataTable = ({ isPending, exportHistory, onDownloadClick }) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th>Title</th>
          <th data-priority="3">Export Date</th>
          <th data-priority="3">Date range</th>
          <th data-priority="3">User</th>
          <th data-priority="3">Status</th>
          <th data-priority="3">File</th>
        </tr>
      </thead>
      <tbody className={classNames(isPending && styles.preloader)}>
        {exportHistory?.map(item => (
          <tr key={item.id}>
            <td className="text-dark font-weight-bold">{item.id}</td>
            <td className="text-dark font-weight-bold">{item.brand.title}</td>
            <td
              className={classNames(styles.width, 'text-dark font-weight-bold')}
            >
              {item.updated_at ? (
                format(Date.parse(item.updated_at), 'yyyy-MM-dd')
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  No last export date
                </div>
              )}
            </td>
            <td className="text-dark font-weight-bold">
              <div className="d-flex flex-column justify-content-between">
                <div>{`Start ${format(
                  Date.parse(item.date_range_start),
                  'yyyy-MM-dd'
                )}`}</div>
                <div>
                  {`End ${format(
                    Date.parse(item.date_range_end),
                    'yyyy-MM-dd'
                  )}`}
                </div>
              </div>
            </td>
            <td className="text-dark font-weight-bold">
              <div className="d-flex flex-column justify-content-between">
                <div>{`${item.user.first_name} ${item.user.last_name}`}</div>
                <div>{item.user.email}</div>
              </div>
            </td>
            <td className="text-dark font-weight-bold">{item.status}</td>
            <td className="text-dark font-weight-bold">
              <Button onClick={() => onDownloadClick?.(item)} color="primary">
                Download
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  exportHistory: PropTypes.array,
  onDownloadClick: PropTypes.func,
};

export default DataTable;
