import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchExciseLicensesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/excise_license',
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchExciseLicenseSaga(action) {
  const id = action.payload;
  yield api({
    action,
    url: `/excise_license/${id}`,
    method: 'GET',
  });
}

function* updateExciseLicenseSaga(action) {
  const { id, data } = action.payload;
  yield api({
    action,
    url: `/excise_license/${id}`,
    method: 'PUT',
    data,
    successMessage: 'Successfully updated excise license!',
    successNavigateTo: `/reports/excise/`,
  });
}

function* createExciseLicenseSaga(action) {
  const { data } = action.payload;
  yield api({
    action,
    url: `/excise_license`,
    method: 'POST',
    data,
    successMessage: 'Successfully created new excise license!',
    successNavigateTo: `/reports/excise/`,
  });
}

export function* fetchExciseReturnSaga(action) {
  const { excise_license_type, id } = action.payload;

  yield api({
    action,
    method: 'GET',
    url: `/excise_return/${excise_license_type.toLowerCase()}/${id}`,
  });
}

export function* fetchExciseReturnsSaga(action) {
  const {
    excise_license_id,
    license_type,
    page,
    ...optionalParams
  } = action.payload;

  const queryString = new URLSearchParams({
    excise_license_id,
    ...(page !== undefined && { page: Math.floor(Number(page)) }),
    ...optionalParams,
  }).toString();

  yield api({
    action,
    method: 'GET',
    url: `/excise_return/${license_type.toLowerCase()}?${queryString}`,
  });
}

export function* createExciseReturnSaga(action) {
  const { data } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/excise_return/${data.license_type.toLowerCase()}`,
    data,
    successMessage: 'Successfully created new excise return!',
    successNavigateTo: `/reports/excise/license/${data.excise_license_id}`,
  });
}
export function* fetchExciseReturnSummarySaga(action) {
  const {
    excise_license_id,
    license_type,
    period_start,
    period_end,
  } = action.payload;

  yield api({
    action,
    method: 'GET',
    url: `/excise_return/${license_type.toLowerCase()}/summary?excise_license_id=${excise_license_id}&period_start=${period_start}&period_end=${period_end}`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchExciseLicenses, fetchExciseLicensesSaga),
    takeLatest(actions.fetchExciseLicense, fetchExciseLicenseSaga),
    takeLatest(actions.updateExciseLicense, updateExciseLicenseSaga),
    takeLatest(actions.createExciseLicense, createExciseLicenseSaga),
    takeLatest(actions.fetchExciseReturns, fetchExciseReturnsSaga),
    takeLatest(actions.createExciseReturn, createExciseReturnSaga),
    takeLatest(actions.fetchExciseReturn, fetchExciseReturnSaga),
    takeLatest(actions.fetchExciseReturnSummary, fetchExciseReturnSummarySaga),
  ]);
}
