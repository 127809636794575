import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import styles from './Filter.scss';
import classNames from 'classnames';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import { getQuery } from 'utils/getQuery';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import { PER_PAGE_OPTIONS, SORTS_OF_WINE_OPTIONS } from 'constants';
import { getInitialOption } from 'utils/getInitialOption';
import useQuery from 'hooks/useQuery';
import { ARCHIVED_OPTIONS, IN_STOCK } from '../filterConstants';
import { RESOURCE_TYPES } from 'src/constants';
import SearchField from 'components/SearchField';
import { Link } from 'react-router-dom';

const Filter = ({ filter, setFilter, disabled }) => {
  const query = useQuery();
  const [brand, setBrand] = useState(getQuery('brand', 'All brands'));
  const [perPage, setPerPage] = useState(
    getInitialOption(query, 'per_page', PER_PAGE_OPTIONS)
  );
  const [sort, setSort] = useState(
    getInitialOption(query, 'sort', SORTS_OF_WINE_OPTIONS)
  );
  const [type, setType] = useState(
    getInitialOption(query, 'resourcetype', RESOURCE_TYPES)
  );
  const [stock, setStock] = useState(
    getInitialOption(query, 'in_stock', IN_STOCK)
  );
  const [archived, setArchived] = useState(
    getInitialOption(query, 'archived', ARCHIVED_OPTIONS)
  );

  const [search, setSearch] = useState(null);

  useEffect(() => {
    setFilter({
      ...filter,
      archived: archived.value,
      brand_id: brand?.value,
      brand_title: brand?.label,
      per_page: perPage?.value,
      sort: sort?.value,
      resourcetype: type?.value,
      in_stock: stock?.value,
      title_contains: search,
    });
  }, [brand, perPage, sort, type, stock, archived, search]);

  return (
    <Row className="mb-4">
      <Col md="12" className="d-flex mb-2">
        <div className={classNames('mr-1', styles.w200)}>
          <BrandsPaginatedField
            isInitialLabel
            defaultValue={brand}
            onChange={setBrand}
            isMulti={false}
          />
        </div>
        <div className={classNames('mr-1', styles.w100)}>
          <Select
            options={PER_PAGE_OPTIONS}
            value={perPage}
            onChange={setPerPage}
            styles={selectStyles}
          />
        </div>
        <div className={classNames('mr-1', styles.w150)}>
          <Select
            options={RESOURCE_TYPES}
            value={type}
            onChange={setType}
            styles={selectStyles}
          />
        </div>
        {type?.value === 'Wine' && (
          <>
            <div className={classNames('mr-1', styles.w150)}>
              <Select
                options={SORTS_OF_WINE_OPTIONS}
                value={sort}
                onChange={setSort}
                styles={selectStyles}
              />
            </div>
            <div className={classNames('mr-1', styles.w150)}>
              <Select
                options={IN_STOCK}
                value={stock}
                onChange={setStock}
                styles={selectStyles}
              />
            </div>
          </>
        )}
        <div className={classNames('mr-1', styles.w150)}>
          <Select
            options={ARCHIVED_OPTIONS}
            value={archived}
            onChange={setArchived}
            styles={selectStyles}
          />
        </div>
      </Col>
      <Col md="12" className="d-flex">
        <SearchField
          value={search}
          setValue={setSearch}
          className="mr-2"
          placeholder="Search... (By title, sku)"
        />
        <Link to="/product/create">
          <Button
            color="success"
            type="button"
            disabled={disabled}
            className="waves-effect waves-light mr-1 text-nowrap"
          >
            <i className="ri-add-line align-middle mr-2" /> Create
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Filter;
