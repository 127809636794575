import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { TRANSFER_TABLE_MODES } from './constants';
import styles from './InventoryTransferTable.scss';

const TableCell = ({
  item,
  isViewing,
  isEditing,
  editorMode,
  handleInputChange,
  getColor,
  tableState,
  disabled,
  field,
  tasterBottles,
  ...props
}) => {
  const getSign = value => (value > 0 ? `+${value}` : value);
  const manualValue = tableState[item.product_id][field];
  const manualTextColor = getColor(Number(manualValue), item[field]);
  const processTextColor = getColor(item[field] - manualValue, 0);
  const inputField = useRef();

  const handleChange = event => {
    handleInputChange(item.product_id)(event);
  };

  const handleBlur = () => {
    localStorage.setItem('inventory_reports', JSON.stringify(tableState));
  };

  const isDisabled = disabled || isViewing;

  return (
    <div
      className={cx('d-flex', styles.inputCell, props.className, {
        [styles.cellContainer]: !isEditing && !isViewing,
      })}
    >
      <div className={styles.cell}>{item[field] ?? '-'}</div>
      {(editorMode !== TRANSFER_TABLE_MODES.DEFAULT || isViewing) && (
        <input
          type="text"
          pattern="\d*"
          inputMode="decimal"
          name={field}
          ref={inputField}
          disabled={isDisabled}
          className={cx(styles.input, styles.cell)}
          onChange={event => {
            handleChange(event);
          }}
          onBlur={handleBlur}
          style={{
            color: manualTextColor,
          }}
          value={
            !isDisabled && editorMode === TRANSFER_TABLE_MODES.DEFAULT
              ? ''
              : manualValue
          }
          onFocus={event => event.target.select()}
        />
      )}
      {(editorMode === TRANSFER_TABLE_MODES.PROCESS || isViewing) && (
        <div className={styles.cell} style={{ color: processTextColor }}>
          {getSign(manualValue + tasterBottles - item[field])}
        </div>
      )}
    </div>
  );
};

TableCell.propTypes = {
  item: PropTypes.object,
  isViewing: PropTypes.bool,
  isEditing: PropTypes.bool,
  editorMode: PropTypes.number,
  handleInputChange: PropTypes.func,
  getColor: PropTypes.func,
  disabled: PropTypes.bool,
  tableState: PropTypes.object,
  field: PropTypes.string,
  tasterBottles: PropTypes.number,
  className: PropTypes.string,
};

TableCell.defaultProps = {
  tasterBottles: 0,
};

export default TableCell;
