import React from 'react';
import useSelector from 'hooks/useSelector';
import { brandsCompaniesFetchedSelector } from 'models/brandsCompanies/selectors';
import BrandsCompaniesForm from './BrandsCompaniesForm';

const BrandsCompaniesFormContainer = () => {
  const fetched = useSelector(brandsCompaniesFetchedSelector);
  if (!fetched) return null;
  return <BrandsCompaniesForm />;
};

export default BrandsCompaniesFormContainer;
