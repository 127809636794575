import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';

const CompanyPaginatedField = ({
  onChange,
  isMulti,
  defaultValue,
  isInitialLabel,
  brandId,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchCompanies = ({ page = 1, brand_id }) => {
    return apiRequest({
      url: `/brand_shipping_companies/?page=${page}&brand_id=${brand_id}`,
    });
  };

  const loadCompanies = async (search, loadedOptions, { page, brand_id }) => {
    try {
      const response = await fetchCompanies({ page, brand_id });
      const options = response?.results.map(item => ({
        value: item.id,
        label: item.shipping_company_title,
      }));

      return {
        options: isInitialLabel
          ? [
              {
                label: 'All companies',
                value: undefined,
              },
              ...options,
            ]
          : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="company"
      isSearchable={false}
      defaultValue={defaultValue}
      additional={{
        page: 1,
        brand_id: brandId,
      }}
      loadOptions={loadCompanies}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

CompanyPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool,
  brandId: PropTypes.number,
};

CompanyPaginatedField.defaultProps = {
  defaultValue: [],
  isMulti: true,
};

export default CompanyPaginatedField;
