import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import ProductForm from 'components/ProductForm';
import FoodForm from 'components/FoodForm';
import {
  isPendingSelector as productIsPendingSelector,
  foodCategoriesSelector,
  merchandiseCategoriesSelector,
  wineCategoriesSelector,
} from 'models/products/selectors';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as productsActions } from 'models/products/slice';
import MerchForm from 'components/MerchForm';
import SelectField from 'components/SelectField/SelectField';
import { RESOURCE_TYPES } from 'src/constants';

const getPreparedCategories = categories => {
  const categoriesValues = Object.values(categories ?? {});
  return categoriesValues.map(category => {
    const warehouseTitle = category?.warehouse_title
      ? ` (${category.warehouse_title})`
      : '';
    return {
      ...category,
      title: `${category.title}${warehouseTitle}`,
    };
  });
};

const CreateProduct = ({ title }) => {
  const filteredResourceTypes = RESOURCE_TYPES.filter(
    type => type.value !== undefined
  );
  const [resourcetype, setResourcetype] = useState(filteredResourceTypes[0]);

  const createProduct = useAction(productsActions.createProduct);
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const isPending = useSelector(productIsPendingSelector);
  const foodCategories = useSelector(foodCategoriesSelector);
  const merchandiseCategories = useSelector(merchandiseCategoriesSelector);
  const wineCategories = useSelector(wineCategoriesSelector);

  useEffect(() => {
    if (!foodCategories || !merchandiseCategories || !wineCategories) {
      fetchCategories();
    }
  }, []);

  const formType = type => {
    switch (type) {
      case 'Wine':
        return (
          <ProductForm
            submitTitle="Save"
            onSubmit={createProduct}
            disabled={isPending}
            resourcetype={resourcetype}
            categories={getPreparedCategories(wineCategories)}
          />
        );
      case 'Food':
        return (
          <FoodForm
            submitTitle="Save"
            disabled={isPending}
            onSubmit={createProduct}
            resourcetype={resourcetype}
            categories={getPreparedCategories(foodCategories)}
          />
        );
      case 'Merchandise':
        return (
          <MerchForm
            onSubmit={data =>
              createProduct({ ...data, resourcetype: resourcetype.value })
            }
            categories={getPreparedCategories(merchandiseCategories)}
            submitTitle="Save"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new product"
        breadcrumbItems={[{ title: 'Back to products', link: '/products/' }]}
      />
      <Row>
        <Col md="3" className="mb-4">
          <SelectField
            name="resourcetype"
            label="Type"
            onChange={setResourcetype}
            defaultValue={resourcetype}
            options={RESOURCE_TYPES}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
          />
        </Col>
        <Col md="12">{formType(resourcetype.value)}</Col>
      </Row>
    </PageWrapper>
  );
};

CreateProduct.propTypes = {
  title: PropTypes.string,
};

export default CreateProduct;
