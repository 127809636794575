import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/ProductsPresetForm';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import {
  productsPresetSelector,
  isPendingSelector as productsPresetIsPendingSelector,
} from 'models/productsPresets/selectors';
import {
  inventoriesSelector,
  isPendingSelector as inventoriesPendingSelector,
} from 'models/inventories/selectors';
import { actions as inventoriesActions } from 'models/inventories/slice';
import { isPendingSelector as warehousesIsPendingSelector } from 'models/warehouses/selectors';
import { actions as productsPresetsActions } from 'models/productsPresets/slice';

const EditProductsPreset = ({ title }) => {
  const { productsPresetID } = useParams();
  const fetchInventories = useAction(inventoriesActions.fetchInventories);
  const productsPreset = useSelector(productsPresetSelector);
  const inventoriesIsPending = useSelector(inventoriesPendingSelector);
  const warehousesIsPending = useSelector(warehousesIsPendingSelector);
  const productsPresetIsPending = useSelector(productsPresetIsPendingSelector);
  const updateProductsPreset = useAction(
    productsPresetsActions.updateProductsPreset
  );
  const fetchProductsPreset = useAction(
    productsPresetsActions.fetchProductsPreset
  );
  const inventories = useSelector(inventoriesSelector);
  const [currentWarehouse, setCurrentWarehouse] = useState();

  const isPending =
    productsPresetIsPending || inventoriesIsPending || warehousesIsPending;

  useEffect(() => {
    fetchProductsPreset(productsPresetID);
  }, [fetchProductsPreset, productsPresetID]);

  useEffect(() => {
    const warehouse = productsPreset?.warehouse;
    if (warehouse) {
      setCurrentWarehouse({
        value: warehouse.id,
        label: warehouse.title,
      });
    }
  }, [productsPreset]);

  useEffect(() => {
    if (!currentWarehouse) return;
    fetchInventories({
      warehouse_ids: [currentWarehouse.value],
      disable_pagination: true,
    });
  }, [fetchInventories, currentWarehouse]);

  if (productsPresetIsPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={productsPreset?.title}
        breadcrumbItems={[
          {
            title: 'Back to tasting menus',
            link: '/products-presets/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {productsPreset && (
        <Row>
          <Col md="12">
            <Form
              submitTitle="Save"
              onSubmit={updateProductsPreset}
              model={productsPreset}
              currentWarehouse={currentWarehouse}
              setCurrentWarehouse={setCurrentWarehouse}
              inventories={inventories}
              disabled={isPending}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditProductsPreset.propTypes = {
  title: PropTypes.string,
};

export default EditProductsPreset;
