import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useQuery from 'hooks/useQuery';
import useSelector from 'hooks/useSelector';

import { actions as customersActions } from 'models/customers/slice';
import DataTable from './DataTable';
import {
  customersInvitedSelector,
  customersSelector,
  isPendingSelector,
  paginationSelector,
  customersIdsSelector,
} from 'models/customers/selectors';
import { EMAIL_TEMPLATE_TYPE } from 'constants/emailTemplates';
import Filter from './Filter';

const CustomersSummary = ({ title }) => {
  const query = useQuery();
  const fetchCustomers = useAction(customersActions.fetchCustomers);

  const customers = useSelector(customersSelector);
  const pending = useSelector(isPendingSelector);
  const pagination = useSelector(paginationSelector);
  const customersInvited = useSelector(customersInvitedSelector);
  const allCustomerIds = useSelector(customersIdsSelector);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const inviteCustomers = useAction(customersActions.inviteCustomers);
  const sendCustomEmail = useAction(customersActions.sendCustomEmail);
  const wineNotificationCustomers = useAction(
    customersActions.wineNotificationCustomers
  );
  const archiveCustomer = useAction(customersActions.archiveCustomer);
  const restoreCustomer = useAction(customersActions.restoreCustomer);
  const sendRecoverPasswordEmail = useAction(
    customersActions.sendRecoverPasswordEmail
  );

  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(
    query.get('page') ?? pagination?.current_page
  );

  const [filter, setFilter] = useState({});
  const [recoverPasswordSent, setRecoverPasswordSent] = useState([]);

  const handleSendEmail = useCallback(
    emailType => {
      const templateType = emailType?.parent_email_template_title;
      const inviteTemplateTypes = [
        EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION,
        EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION_WINEDIRECT,
      ];
      if (inviteTemplateTypes.includes(templateType)) {
        inviteCustomers({
          customer_ids: selectedCustomers,
          wineclub_id: filter?.wineclub_id,
          template_id: emailType?.value,
          template_type:
            templateType === EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION
              ? 'invite'
              : 'invite_winedirect',
        });
      } else {
        if (templateType === EMAIL_TEMPLATE_TYPE.WINE_SELECTION_NOTIFICATION) {
          wineNotificationCustomers({
            customer_ids: selectedCustomers,
            wine_club_id: filter?.wineclub_id,
            template_id: emailType?.value,
          });
          return;
        }
        sendCustomEmail({
          customer_ids: selectedCustomers,
          email_template_id: emailType?.value,
        });
      }
    },
    [selectedCustomers]
  );

  const updateSelectedCustomers = newCustomersList => {
    setIsSelectedAll(newCustomersList.length === allCustomerIds.length);
    setSelectedCustomers(newCustomersList);
  };

  const handleCustomerArchive = id => {
    archiveCustomer(id);
  };

  const handleCustomerRestore = id => {
    restoreCustomer(id);
  };

  const handleCustomerSelectAll = value => {
    if (value) {
      setIsSelectedAll(true);
      setSelectedCustomers(allCustomerIds);
    } else {
      setIsSelectedAll(false);
      setSelectedCustomers([]);
    }
  };

  const handleCustomerRecoverPassword = id => {
    setRecoverPasswordSent([...recoverPasswordSent, id]);
    sendRecoverPasswordEmail({
      customers_ids: [id],
      wineclub_id: filter?.wineclub_id,
    });
  };

  useEffect(() => {
    fetchCustomers({
      page: currentPage,
      sort_field: sortField,
      sort_direction: sortDirection,
      setQuery: true,
      ...filter,
    });
  }, [fetchCustomers, currentPage, sortField, sortDirection, filter]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    if (customersInvited) {
      setSelectedCustomers([]);
    }
  }, [customersInvited]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Filter
                filter={filter}
                setFilter={setFilter}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                handleSendEmail={handleSendEmail}
              />
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={pending}
                  customers={customers}
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  isSelectable={filter?.wineclub_id}
                  isSelectedAll={isSelectedAll}
                  selectedCustomers={selectedCustomers}
                  recoverPasswordSent={recoverPasswordSent}
                  onChange={updateSelectedCustomers}
                  onArchiveClick={handleCustomerArchive}
                  onRestoreClick={handleCustomerRestore}
                  onSelectAll={handleCustomerSelectAll}
                  onRecoverPasswordClick={handleCustomerRecoverPassword}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

CustomersSummary.propTypes = {
  title: PropTypes.string,
};

export default CustomersSummary;
