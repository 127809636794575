import React, { useEffect, useMemo, useState } from 'react';
import styles from './OrderItemRow.scss';
import { UncontrolledTooltip } from 'reactstrap';
import WarehouseInventoryPaginatedField from '../../PaginatedFields/WarehouseInventoryPaginatedField';
import useSelector from 'hooks/useSelector';
import { productCountsSelector } from 'models/products/selectors';
import formatPrice from 'utils/formatPrice';
import { OrderItemRowProps } from './OrderItemRowProps';

const OrderItemRow = ({
  id,
  number,
  warehouseId,
  productTitle,
  productCount,
  productId,
  productPrice,
  discountAmount,
  discountPercentage,
  selectedProductsIds,
  onChange,
  onDelete,
}) => {
  const [count, setCount] = useState(0);
  const productCounts = useSelector(productCountsSelector);

  const isDisabledRow = useMemo(
    () => !selectedProductsIds.includes(productId),
    [selectedProductsIds, productId]
  );

  useEffect(() => {
    const actualProductCount = productCount ?? productCounts?.[productId] ?? 0;
    setCount(actualProductCount);
  }, [productId, productCount, productCounts]);

  const adjustedDiscountValue = (type, value, quantity) => {
    if (Number(value) < 0) return 0;

    if (type === 'discount_amount') {
      const totalProductPrice = (productPrice * quantity).toFixed(2);
      return value && Number(value) > Number(totalProductPrice)
        ? Number(totalProductPrice)
        : value || 0;
    }
    return value && Number(value) > 100 ? 100 : value || 0;
  };

  const totalPrice = () => {
    if (discountPercentage)
      return Number(
        number * productPrice * (1 - discountPercentage / 100)
      ).toFixed(2);
    if (discountAmount)
      return Number(number * productPrice - discountAmount).toFixed(2);
    return number * (productPrice || 0);
  };

  const isNumeric = value =>
    typeof value !== 'string'
      ? false
      : // eslint-disable-next-line no-restricted-globals
        !isNaN(value) && !isNaN(parseFloat(value));

  const onDiscountChange = event => {
    const { name, value } = event.target;
    if (name === 'discount_amount') {
      if (value && !isNumeric(event.target.value)) return;
      onChange?.({
        discount_amount: adjustedDiscountValue(name, value, number) || 0,
      });
      onChange?.({ discount_percentage: 0 });
    } else if (name === 'discount_percentage') {
      if (value && !isNumeric(event.target.value)) {
        if (!discountPercentage) {
          onChange?.({ discount_percentage: 0 });
        }
        return;
      }
      onChange?.({
        discount_percentage: adjustedDiscountValue(name, value) || 0,
      });
      onChange?.({ discount_amount: '0.00' });
    }
  };

  const onValueChange = event => {
    const { value } = event.target;
    let preparedInputValue = '';

    if (value.match(/^((0[1-9]))/)) {
      const preparedValue = value.replace(/^0+/, '') || 0;
      preparedInputValue = Math.min(+preparedValue, count);
    } else if (value.match(/^((0|([1-9][0-9]*)))$/)) {
      preparedInputValue = Math.min(Number(value), count);
    }

    preparedInputValue = Math.max(Number(preparedInputValue), 1);

    onChange?.({ number: preparedInputValue });
    onChange?.({
      discount_amount:
        adjustedDiscountValue(
          'discount_amount',
          Math.max(Number(discountAmount), 0),
          Number(preparedInputValue)
        ) || 0,
    });
  };

  const onValueBlur = event => {
    const { value } = event.target;

    onChange?.({ number: Number(value) || 1 });
  };

  const onDiscountBlur = event => {
    const { name, value } = event.target;
    if (name === 'discount_amount') {
      onChange?.({ discount_amount: formatPrice(value) });
    } else if (name === 'discount_percentage') {
      onChange?.({ discount_percentage: value || 0 });
    }
  };

  const onProductChange = data => {
    onChange?.({
      ...data,
      number: 1,
    });
  };

  return (
    <tr>
      <td className={styles.cell}>
        <div className={styles.inventoryCell}>
          <button
            onClick={onDelete}
            type="button"
            className={styles.trashButton}
          >
            <i className="mdi mdi-trash-can" id={`delete${id}`} />
          </button>
          <UncontrolledTooltip placement="top" target={`delete${id}`}>
            Delete this product from order
          </UncontrolledTooltip>
          <WarehouseInventoryPaginatedField
            onChange={onProductChange}
            warehouseId={warehouseId}
            options={{
              product_types: ['Wine', 'Merchandise'],
            }}
            filterOption={({ value }) => !selectedProductsIds.includes(value)}
            defaultValue={{
              value: productId,
              label: productTitle,
            }}
          />
        </div>
      </td>
      <td
        className={`${styles.quantityCell} ${
          isDisabledRow ? styles.disabledCell : ''
        }`}
      >
        <input
          type="text"
          name="quantity"
          onFocus={event => event.target.select()}
          value={number}
          className="form-control"
          onChange={onValueChange}
          onBlur={onValueBlur}
          disabled={isDisabledRow}
        />
      </td>
      <td className={isDisabledRow && styles.disabledCell}>{count}</td>
      <td className={isDisabledRow && styles.disabledCell}>
        ${formatPrice(productPrice)}
      </td>
      <td
        className={`${styles.discountCell} ${
          isDisabledRow ? styles.disabledCell : ''
        }`}
      >
        <div className={styles.discountField}>
          <input
            type="text"
            name="discount_percentage"
            onFocus={event => event.target.select()}
            className={`form-control ${
              discountAmount > 0 ? styles.disabledNumberField : ''
            }`}
            value={discountPercentage}
            onChange={onDiscountChange}
            onBlur={onDiscountBlur}
            defaultValue={discountPercentage || 0}
            disabled={isDisabledRow || discountAmount > 0}
          />
        </div>
      </td>
      <td
        className={`${styles.discountCell} ${
          isDisabledRow ? styles.disabledCell : ''
        }`}
      >
        <div className={styles.discountField}>
          <span style={{ marginRight: '3px' }}>$</span>
          <input
            type="text"
            name="discount_amount"
            onFocus={event => {
              event.target.addEventListener(
                'wheel',
                function(e) {
                  e.preventDefault();
                },
                { passive: false }
              );
              event.target.select();
            }}
            className={`form-control ${
              discountPercentage > 0 ? styles.disabledNumberField : ''
            }`}
            defaultValue={formatPrice(discountAmount)}
            value={discountAmount}
            onChange={onDiscountChange}
            onBlur={onDiscountBlur}
            disabled={isDisabledRow || discountPercentage > 0}
          />
        </div>
      </td>

      <td className={isDisabledRow && styles.disabledCell}>
        ${formatPrice(totalPrice())}
      </td>
    </tr>
  );
};

OrderItemRow.propTypes = OrderItemRowProps;
export default OrderItemRow;
