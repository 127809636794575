import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { projects } from 'constants/projects';
import TextField from '../Fields/TextField';
import SelectField from '../SelectField/SelectField';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import CustomerPaginatedField from '../PaginatedFields/CustomerPaginatedField';
import CheckboxField from '../Fields/CheckboxField';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage } from 'utils/notification';

import { formatDate } from 'utils/formatDate';
import { getEndDate, getStartDateNow } from 'utils/date';
import DateRangePicker from 'components/DateRangePicker';
import { endOfYear, isSameDay, endOfWeek, endOfMonth } from 'date-fns';

import styles from './DiscountCodeForm.scss';

const checkSelected = (range, definedRange) => {
  return (
    isSameDay(range.startDate, definedRange.startDate) &&
    isSameDay(range.endDate, definedRange.endDate)
  );
};
const getDate = date => {
  if (date) {
    return formatDate(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }
  return undefined;
};

// Current date initilize for date validation
const today = new Date();

const inputRanges = [
  {
    label: 'days starting today',
    range(value) {
      if (value <= 0) {
        value = 1;
      }
      const todayN = new Date();
      const startDateN = new Date(todayN.setDate(todayN.getDate()));
      const endDateN = new Date(
        todayN.setDate(todayN.getDate() + parseInt(value - 1, 10))
      );
      endDateN.setDate(endDateN.getDate());
      this.getCurrentValue([startDateN, endDateN]);
      return {
        startDate: startDateN,
        endDate: endDateN,
      };
    },
    getCurrentValue(range) {
      const todayN = new Date();
      const startDateN = new Date(todayN.setDate(todayN.getDate()));
      const daysFromToday = Math.round(
        (range.endDate - startDateN) / (1000 * 60 * 60 * 24)
      );
      if (
        !range.startDate ||
        range.startDate.toDateString() !== startDateN.toDateString()
      ) {
        return '-';
      }
      return `${daysFromToday}`;
    },
  },
];

const DiscountCodeForm = ({ model, onSubmit, disabled }) => {
  const apiRequest = useApiRequest();

  const [project, setProject] = useState(projects[0]);
  const [brandTouched, setBrandTouched] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState({
    value: undefined,
    label: 'All customers',
  });
  const [code, setCode] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: Date.parse(model?.promotion_start_date, 'yyyy-MM-dd'),
    endDate: Date.parse(model?.promotion_end_date, 'yyyy-MM-dd'),
  });

  useEffect(() => {
    if (model) {
      setSelectedCustomer(
        model.customer
          ? {
              value: model.customer.id,
              label: `${model.customer.first_name} ${model.customer.last_name} (${model.customer.email})`,
            }
          : {
              value: undefined,
              label: 'All customers',
            }
      );
      setSelectedBrand({
        value: model.brand.id,
        label: model.brand.title,
      });
      setProject(
        projects?.find(
          _project => _project?.value === model?.redeemable_location
        ) || projects[0]
      );
      setDateRange({
        startDate: Date.parse(model?.promotion_start_date),
        endDate: Date.parse(model?.promotion_end_date),
      });
      setCode(model.coupon);
    }
  }, [model]);

  const handleDateChange = value => {
    setDateRange({
      startDate: getStartDateNow(value.startDate),
      endDate: getEndDate(value.endDate),
    });
  };

  const handleSubmit = useCallback(
    (_, values) => {
      setBrandTouched(true);
      if (!selectedBrand) return;
      onSubmit({
        ...values,
        brand_id: selectedBrand?.value,
        redeemable_location: project?.value,
        discount_method: 'PERCENTAGE',
        promotion_start_date: getDate(dateRange?.startDate),
        promotion_end_date: getDate(dateRange?.endDate),
        customer_id: selectedCustomer?.value,
      });
    },
    [onSubmit, selectedBrand, project, dateRange, selectedCustomer]
  );

  const handleInvalidSubmit = () => {
    setBrandTouched(true);
  };

  const handleChangeCode = useCallback(e => {
    setCode(e.target.value);
  }, []);

  const generateCode = () => {
    return apiRequest({
      url: `/discount_codes/generate?brand_id=${selectedBrand?.value}`,
    });
  };

  const onGenerateCode = async () => {
    try {
      const response = await generateCode();
      setCode(response?.coupon);
    } catch (err) {
      showErrorMessage('Error!', err);
    }
  };

  return (
    <AvForm
      model={model || { use_once: true }}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      onInvalidSubmit={handleInvalidSubmit}
    >
      <Row>
        <Col md="4">
          <TextField
            label="Title"
            name="title"
            placeholder="Title"
            isRequired
          />
        </Col>
        <Col md="4">
          <FormGroup>
            <Label
              className={brandTouched && !selectedBrand && 'text-danger'}
              required
              htmlFor="brand_id"
            >
              Brand
            </Label>
            <BrandsPaginatedField
              value={selectedBrand}
              onChange={setSelectedBrand}
              isMulti={false}
              onBlur={() => setBrandTouched(true)}
              error={brandTouched && !selectedBrand && 'This field is invalid'}
              isDisabled={model?.brand.id}
            />
          </FormGroup>
        </Col>

        {selectedBrand?.value && (
          <>
            <Col lg={12}>
              <Nav tabs className="nav-tabs-simple mb-4">
                <NavItem>
                  <NavLink className="font-weight-bold p-3 active">
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col lg="4">
              <FormGroup className={styles.pinWrapper}>
                <Label required htmlFor="pin">
                  Coupon
                </Label>
                <AvInput
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'This field is invalid',
                    },
                  }}
                  name="coupon"
                  placeholder="Coupon"
                  id="coupon"
                  value={code}
                  onChange={handleChangeCode}
                  className={styles.field}
                />
                <button
                  onClick={onGenerateCode}
                  type="button"
                  className={styles.pinButton}
                >
                  <i className="ri-qr-code-line font-size-20" />
                </button>
              </FormGroup>
            </Col>

            <Col lg="4">
              <TextField
                name="percentage_amount"
                label="Discount Rate"
                placeholder="Discount Rate"
                min="1"
                max="100"
                errorMessage="Discount Rate should be between 1 and 100"
                isRequired
              />
            </Col>
            <Col lg="4">
              <TextField
                name="minimum_spend_amount"
                label="Minimum Spend Amount"
                placeholder="Minimum Spend Amount"
                min="0"
              />
            </Col>
            <Col lg="4">
              <SelectField
                name="where_code"
                label="Where Code Can Be Applied"
                onChange={setProject}
                value={project}
                options={projects}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
              />
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label htmlFor="customer">For a Specific Customer</Label>
                <CustomerPaginatedField
                  value={selectedCustomer}
                  onChange={setSelectedCustomer}
                  isMulti={false}
                  isInitialLabel
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <Label htmlFor="date">Time Limit</Label>
              <DateRangePicker
                placement="bottom-start"
                startDate={Date.parse(model?.promotion_start_date)}
                endDate={Date.parse(model?.promotion_end_date)}
                onDateChange={handleDateChange}
                minDate={today}
                staticRanges={[
                  {
                    label: 'This Year',
                    range() {
                      return {
                        startDate: new Date(today.setDate(today.getDate())),
                        endDate: endOfYear(new Date()),
                      };
                    },
                    isSelected(range) {
                      const definedRange = this.range();
                      return checkSelected(range, definedRange);
                    },
                  },
                  {
                    label: 'This Month',
                    range() {
                      return {
                        startDate: new Date(today.setDate(today.getDate())),
                        endDate: endOfMonth(new Date()),
                      };
                    },
                    isSelected(range) {
                      const definedRange = this.range();
                      return checkSelected(range, definedRange);
                    },
                  },
                  {
                    label: 'This Week',
                    range() {
                      return {
                        startDate: new Date(today.setDate(today.getDate())),
                        endDate: endOfWeek(new Date()),
                      };
                    },
                    isSelected(range) {
                      const definedRange = this.range();
                      return checkSelected(range, definedRange);
                    },
                  },
                ]}
                inputRanges={inputRanges}
              />
            </Col>
            <Col className={styles.checkbox} lg="8">
              <div
                className="custom-control custom-checkbox p-0"
                style={{ zIndex: 0 }}
              >
                <CheckboxField
                  className="d-flex justify-content-end flex-row-reverse"
                  name="use_once"
                  label="Use once per customer (Does not limit usage by Guests)"
                />
              </div>
            </Col>
          </>
        )}
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {model ? 'Save' : 'Create'}
      </Button>
    </AvForm>
  );
};

DiscountCodeForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DiscountCodeForm;
