import { createSelector } from 'reselect';

export const rootSelector = state => state.devices;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const devicesSelector = createSelector(
  rootSelector,
  ({ devices }) => devices
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const deviceSelector = createSelector(
  rootSelector,
  ({ device }) => device
);
