import React, { memo } from 'react';
import styles from './OrderTable.scss';
import PropTypes from 'prop-types';
import { Card, CardBody, Table } from 'reactstrap';
import PaginationTable from '../PaginationTable/PaginationTable';
import { format } from 'date-fns';
import { getNormalText } from 'utils/getNormalText';
import { ORDER_TYPES_ITEMS } from 'constants';
import LinkCell from '../LinkCell';

const OrderTable = ({ data, pagination, loading, onPageChange, className }) => {
  return (
    <Card className={className}>
      <CardBody>
        <h4 className="card-title">Orders</h4>
        <PaginationTable pagination={pagination} setCurrentPage={onPageChange}>
          <Table
            className={loading && styles.loadingContainer}
            bordered
            responsive
          >
            <thead>
              <tr>
                <th data-priority="1">Id</th>
                <th data-priority="1">Created at</th>
                <th data-priority="1">Total Price</th>
                <th data-priority="1">Tips Amount</th>

                <th data-priority="3">Brand</th>
                <th data-priority="3">Status</th>
                <th data-priority="3">Type</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id}>
                  <LinkCell id={item.id} field={item.id} link="/orders/" />
                  <LinkCell
                    id={item.id}
                    field={
                      item.created_at
                        ? format(
                            Date.parse(item.created_at),
                            'yyyy-MM-dd, h:mm aaa'
                          )
                        : ''
                    }
                    link="/orders/"
                  />
                  <LinkCell
                    id={item.id}
                    field={`$${item.total_price}`}
                    link="/orders/"
                  />
                  <LinkCell
                    id={item.id}
                    field={item.tips_amount ? `$${item.tips_amount}` : '-'}
                    link="/orders/"
                  />
                  <LinkCell
                    id={item.id}
                    field={item.brand.title}
                    link="/orders/"
                  />
                  <LinkCell
                    id={item.id}
                    field={
                      item.status === 'FULFILLED'
                        ? 'Pending'
                        : getNormalText(item.status)
                    }
                    link="/orders/"
                  />
                  <LinkCell
                    id={item.id}
                    field={ORDER_TYPES_ITEMS[item.resourcetype]}
                    link="/orders/"
                  />
                </tr>
              ))}
            </tbody>
          </Table>
        </PaginationTable>
      </CardBody>
    </Card>
  );
};

OrderTable.propTypes = {
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
export default memo(OrderTable);
