import React, { useEffect, useCallback } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import * as queryString from 'query-string';
import styles from './NotificationsForm.scss';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import getUID from 'utils/getUID';

import TableRow from './TableRow';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

const types = {
  CUSTOM: 1,
  NEW: 2,
};

const NotificationsForm = ({ warehouseId }) => {
  const apiRequest = useApiRequest();

  const [products, setProducts] = React.useState([]);
  const [pagination, setPagination] = React.useState({});

  const fetchLimitsRequest = ({ page = 1, warehouse_id }) => {
    const data = { page, warehouse_id };
    return apiRequest({
      url: `/inventory_limits/?${queryString.stringify(data, {
        arrayFormat: 'comma',
      })}`,
    });
  };

  const removeLimit = async id => {
    const request = apiRequest({
      url: `/inventory_limits/${id}`,
      method: 'DELETE',
      headers: defaultHeaders,
    });
    try {
      await request;
      removeLocalNotification(id);
    } catch (error) {
      console.log(error);
    }
  };

  const createLimit = async ({ product_id, limit_value }) => {
    const formData = new FormData();
    formData.append('product_id', product_id);
    formData.append('limit_value', limit_value);
    formData.append('warehouse_id', warehouseId);
    const request = apiRequest({
      url: '/inventory_limits/',
      method: 'POST',
      data: formData,
      headers: defaultHeaders,
    });
    try {
      const response = await request;
      setProducts(
        products.map(i =>
          i.product_id === product_id
            ? {
                ...response,
                prev_limit: response.limit_value,
                type: types.CUSTOM,
              }
            : i
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const updateLimit = async ({ id, limit_value }) => {
    const formData = new FormData();
    formData.append('limit_value', limit_value);
    const request = apiRequest({
      url: `/inventory_limits/${id}`,
      method: 'PATCH',
      data: formData,
      headers: defaultHeaders,
    });
    try {
      const response = await request;
      setProducts(
        products.map(i =>
          i.id === id
            ? {
                ...response,
                prev_limit: response.limit_value,
                type: types.CUSTOM,
              }
            : i
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadLimits = async page => {
    const response = await fetchLimitsRequest({
      page,
      warehouse_id: warehouseId,
    });
    if (response) {
      setPagination(response.pagination ?? {});
      setProducts(
        response.results
          ? [
              ...products,
              ...response.results.map(i => ({
                ...i,
                prev_limit: i.limit_value,
                type: types.CUSTOM,
              })),
            ]
          : products
      );
    }
  };

  const loadMore = useCallback(() => {
    loadLimits(pagination?.next_page);
  }, [pagination, products]);

  useEffect(() => {
    if (warehouseId) {
      loadLimits(1);
    }
  }, []);

  const addNotification = () => {
    setProducts([
      ...products,
      {
        id: getUID(),
        product_id: null,
        product_title: null,
        limit_value: 0,
        warehouse_id: warehouseId,
        type: types.NEW,
      },
    ]);
  };

  const removeLocalNotification = id => {
    setProducts(products.filter(item => item.id !== id));
  };

  const removeNotification = product => {
    if (product.type === types.CUSTOM) {
      removeLimit(product.id);
    } else {
      removeLocalNotification(product.id);
    }
  };

  const onChangeNotification = product => {
    setProducts(
      products.map(item => {
        return item.id === product.id ? product : item;
      })
    );
  };

  const onSubmitNotification = product => {
    if (product.type === types.NEW) {
      createLimit(product);
    } else {
      updateLimit(product);
    }
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Table className={classNames(styles.table)} bordered responsive>
              <thead>
                <tr>
                  <th className={styles.columnTitle} data-priority="1">
                    Title
                  </th>
                  <th className={styles.columnTitle} data-priority="2">
                    Limit
                  </th>
                  <th className={styles.columnSmall}>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map(item => (
                  <TableRow
                    key={item.id}
                    products={products.filter(i => i.type === types.CUSTOM)}
                    value={item}
                    warehouseId={warehouseId}
                    onChange={onChangeNotification}
                    onSubmit={onSubmitNotification}
                    onRemove={removeNotification}
                  />
                ))}
                <tr>
                  <td colSpan={6}>
                    <Button
                      onClick={loadMore}
                      disabled={!pagination?.next_page}
                      color="light"
                      type="button"
                      className="waves-effect waves-light mr-1 text-nowrap"
                    >
                      Load more
                    </Button>
                    <Button
                      onClick={addNotification}
                      color="success"
                      type="button"
                      className="waves-effect waves-light mr-1 text-nowrap"
                    >
                      <i className="ri-add-line align-middle mr-2" />
                      Add new Notification
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <p className={styles.error}>{error}</p> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

NotificationsForm.propTypes = {
  warehouseId: PropTypes.number,
};

export default NotificationsForm;
