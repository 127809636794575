import React, { useState } from 'react';
import styles from './ResetPassword.scss';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions } from 'models/user/slice';
import PasswordField from 'components/Fields/PasswordField';

const PasswordRequiredError = 'Enter password';
const PasswordNotEqualError = 'Passwords are different';

const ResetPassword = ({ title }) => {
  const { token } = useParams();
  const resetPassword = useAction(actions.resetPassword);

  const [passwordError, setPasswordError] = useState(null);

  const onSubmit = (_, data) => {
    resetPassword({
      ...data,
      reset_password_token: token,
    });
  };

  const validatePassword = (value, context, input, cb) => {
    if (!value) {
      setPasswordError(PasswordRequiredError);
      cb(false);
      return;
    }
    if (value !== context.password) {
      setPasswordError(PasswordNotEqualError);
      cb(false);
      return;
    }
    cb(true);
  };

  return (
    <PageWrapper title={title}>
      <div className={styles.container}>
        <h4 className={styles.title}>Reset Password</h4>
        <AvForm className={styles.form} onValidSubmit={onSubmit}>
          <PasswordField
            name="password"
            label="Password"
            className="auth-form-group-custom mb-2"
            icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
            errorMessage={PasswordRequiredError}
            placeholder="Enter password"
            isRequired
          />
          <PasswordField
            name="password_confirmation"
            label="Password Confirmation"
            className="auth-form-group-custom mb-4"
            icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
            errorMessage={passwordError}
            placeholder="Enter password"
            rules={{ custom: validatePassword }}
            isRequired
          />
          <Button color="primary" type="submit">
            Reset Password
          </Button>
        </AvForm>
      </div>
    </PageWrapper>
  );
};

ResetPassword.propTypes = {
  title: PropTypes.string.isRequired,
};
export default ResetPassword;
