import React, { useEffect, useMemo, useState } from 'react';
import styles from './ProductCategoriesTable.scss';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Label,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import SelectField from '../SelectField/SelectField';
import useAction from 'hooks/useAction';
import { actions as productActions } from 'models/products/slice';

import { getQuery } from 'utils/getQuery';

const warehouseInitialLabel = {
  title: 'All Warehouses',
  id: undefined,
};

const ProductCategoryTable = ({
  categories,
  onFiltersChange,
  categoryPath,
}) => {
  const [selectedBrand, setSelectedBrand] = useState(
    getQuery('brand', 'All brands')
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    getQuery('warehouse')
  );
  const updateCategory = useAction(productActions.updateProductCategory);
  const deleteCategory = useAction(productActions.deleteProductCategory);

  const availableWarehouses = useMemo(() => {
    return [warehouseInitialLabel, ...(selectedBrand?.warehouses ?? [])];
  }, [selectedBrand]);

  const onShowMainPageButtonClick = item => {
    updateCategory({
      id: item.id,
      is_shown_on_main_page: !item?.is_shown_on_main_page,
    });
  };

  const onDelete = item => {
    deleteCategory(item);
  };

  useEffect(() => {
    setSelectedWarehouse(warehouseInitialLabel);
  }, [selectedBrand]);

  useEffect(() => {
    if (
      onFiltersChange &&
      (selectedBrand !== undefined || selectedWarehouse !== undefined)
    ) {
      onFiltersChange({
        brand_id: selectedBrand?.value,
        brand_title: selectedBrand?.label,
        warehouse_id: selectedWarehouse?.id,
        warehouse_title: selectedWarehouse.label,
      });
    }
  }, [onFiltersChange, selectedBrand, selectedWarehouse]);

  return (
    <>
      <Row className="mb-4">
        <Col md={4}>
          <Label>Brand</Label>
          <BrandsPaginatedField
            isMulti={false}
            value={selectedBrand}
            onChange={setSelectedBrand}
            isInitialLabel
          />
        </Col>
        <Col className={styles.wineclubSelect} md={4}>
          <SelectField
            name="Warehouse"
            label="Warehouse"
            placeholder="Select warehouse"
            options={availableWarehouses}
            value={selectedWarehouse}
            onChange={setSelectedWarehouse}
            isDisabled={
              !availableWarehouses || availableWarehouses?.length === 1
            }
          />
        </Col>
        {categoryPath && (
          <Col className="d-flex align-items-end">
            <Link to={`${categoryPath}/create`}>
              <Button color="success">
                <i className="ri-add-line align-middle mr-2" />
                Create
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Table>
        <thead>
          <tr>
            <th className="text-nowrap" data-priority="3">
              Title
            </th>
            <th className={styles.brandColumn} data-priority="3">
              Brand
            </th>
            <th className="text-nowrap" data-priority="3">
              Warehouse
            </th>
            <th data-priority="3">Color</th>
            <th data-priority="3">Action</th>
          </tr>
        </thead>
        <tbody>
          {categories?.map(item => (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td>{item.brand_title}</td>
              <td>{item.warehouse_title}</td>
              <td>
                {item?.colour ? (
                  <div className={styles.colorWrapper}>
                    <div
                      className={styles.colorCard}
                      style={{
                        backgroundColor: item?.colour,
                      }}
                    />
                    <p className={styles.colorText}>{item?.colour}</p>
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <Button
                  color={item?.is_shown_on_main_page ? 'danger' : 'primary'}
                  onClick={() => onShowMainPageButtonClick(item)}
                >
                  {item?.is_shown_on_main_page
                    ? 'Hide from POS'
                    : 'Show on POS'}
                </Button>
                {categoryPath && item?.brand_id && (
                  <>
                    <Link
                      to={`${categoryPath}/${item.id}/edit`}
                      className="ml-2"
                      id={`edit${item.id}`}
                    >
                      <button
                        type="button"
                        className="btn btn-sm text-primary btn-transparent"
                      >
                        <i className="mdi mdi-pencil font-size-22" />
                      </button>
                    </Link>
                    <UncontrolledTooltip
                      placement="top"
                      target={`edit${item.id}`}
                    >
                      Edit
                    </UncontrolledTooltip>

                    <button
                      type="button"
                      className="btn btn-sm btn-transparent text-danger"
                      id={`remove${item.id}`}
                      onClick={() => onDelete(item)}
                    >
                      <i className="mdi mdi-trash-can font-size-22" />
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target={`remove${item.id}`}
                    >
                      Remove
                    </UncontrolledTooltip>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

ProductCategoryTable.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryPath: PropTypes.string,
  onFiltersChange: PropTypes.func,
};
export default ProductCategoryTable;
