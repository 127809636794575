import { createSelector } from 'reselect';

export const rootSelector = state => state.wineclubs;

export const wineclubsSelector = createSelector(
  rootSelector,
  ({ wineclubs }) => wineclubs
);

export const wineclubSelector = createSelector(
  rootSelector,
  ({ wineclub }) => wineclub
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);
