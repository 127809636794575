import React from 'react';
import styles from './Toggle.scss';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Toggle = ({
  value,
  name,
  checkedLabel,
  uncheckedLabel,
  disabled,
  className,
  onChange,
}) => {
  return (
    <div
      className={cx(styles.toggle, className, disabled ? styles.disabled : '')}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        disabled={disabled}
        value={value}
        checked={value}
        onChange={event => {
          if (onChange) {
            onChange(event.target.checked);
          }
        }}
      />
      <div className={styles.bullet} />
      {(checkedLabel || uncheckedLabel) && (
        <label htmlFor={name} className={styles.label}>
          {value ? checkedLabel : uncheckedLabel}
        </label>
      )}
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Toggle;
