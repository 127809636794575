import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { nanoid } from 'nanoid';

const brandsSourcesSlice = createSlice({
  name: 'brandsSources',
  initialState: {
    isPending: false,
    brandsSourcesFetched: false,
    currentBrandSources: {},
    editingItemsIds: [],
    formBusy: false,
  },
  reducers: {
    fetchDataSources: state => {
      state.isPending = true;
      state.currentBrandSources = {};
    },
    fetchDataSourcesSuccess(state, { payload }) {
      const { data } = payload;
      const { brandId } = payload.inResponseTo;
      state.isPending = false;
      state.currentBrandSources = data
        .filter(c => c.brand?.id === brandId)
        .reduce((result, current) => {
          const id = nanoid();
          return {
            ...result,
            [id]: {
              itemId: id,
              brand: current.brand,
              entityId: current.id,
              username: current.username,
              resourcetype: current.resourcetype,
            },
          };
        }, {});
    },
    fetchDataSourcesFailure: state => {
      state.isPending = false;
    },

    fetchDataSourceExtData(state) {
      state.formBusy = true;
    },
    fetchDataSourceExtDataSuccess(state, { payload }) {
      const { itemId } = payload.inResponseTo;
      state.formBusy = false;
      state.editingItemsIds.push(itemId);
      state.currentBrandSources[itemId].assets_base_url =
        payload.data.assets_base_url;
      state.currentBrandSources[itemId].password = '';
    },
    fetchDataSourceExtDataFailure(state) {
      state.formBusy = false;
    },

    changeCurrentBrandSourcesField(state, { payload }) {
      const { itemId, assignedObject } = payload;
      state.currentBrandSources[itemId] = {
        ...state.currentBrandSources[itemId],
        ...assignedObject,
      };
    },

    addCurrentBrandSource(state) {
      const itemId = nanoid();
      state.currentBrandSources[itemId] = {
        itemId,
        username: '',
        password: '',
        assets_base_url: '',
        resourcetype: 'WineDirect',
      };
      state.editingItemsIds.push(itemId);
    },

    saveSource(state) {
      state.formBusy = true;
    },
    saveSourceSuccess(state, { payload }) {
      const { itemId, create } = payload.inResponseTo;

      state.formBusy = false;
      state.editingItemsIds = state.editingItemsIds.filter(i => i !== itemId);

      if (create) {
        state.currentBrandSources[itemId].brand = payload.data.brand;
        state.currentBrandSources[itemId].entityId = payload.data.id;
      }
    },
    saveSourceFailure(state) {
      state.formBusy = false;
    },

    deleteSource(state) {
      state.formBusy = true;
    },
    deleteSourceSuccess(state, { payload }) {
      const { itemId } = payload.inResponseTo;

      state.formBusy = false;
      state.editingItemsIds = state.editingItemsIds.filter(i => i !== itemId);

      delete state.currentBrandSources[itemId];
    },
    deleteSourceFailure(state) {
      state.formBusy = false;
    },

    deleteSourceItem(state, { payload }) {
      const { itemId } = payload;

      state.editingItemsIds = state.editingItemsIds.filter(i => i !== itemId);

      delete state.currentBrandSources[itemId];
    },
  },
});

export const actions = actionTypes(brandsSourcesSlice.actions);

export default brandsSourcesSlice.reducer;
