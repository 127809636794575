import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import manageBodyClass from 'utils/manageBodyClass';

const Preloader = ({ className }) => {
  useEffect(() => {
    manageBodyClass('overflow-hidden', 'add');

    return () => manageBodyClass('overflow-hidden', 'remove');
  }, []);

  return (
    <div id="preloader" className={className}>
      <div id="status">
        <div className="spinner">
          <i className="ri-loader-line spin-icon" />
        </div>
      </div>
    </div>
  );
};

Preloader.propTypes = {
  className: PropTypes.string,
};

export default Preloader;
