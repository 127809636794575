import React from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import {
  pendingFulfillmentAllOrdersPaginationSelector,
  pendingFulfillmentCommercialOrdersPaginationSelector,
} from 'models/orders/selectors';

const OrdersTitle = ({ title }) => {
  // Selectors
  const allPagination = useSelector(
    pendingFulfillmentAllOrdersPaginationSelector
  );
  const commercialPagination = useSelector(
    pendingFulfillmentCommercialOrdersPaginationSelector
  );

  // Constants
  const totalCount =
    (allPagination?.total_count || 0) +
    (commercialPagination?.total_count || 0);

  return (
    <span>
      {title}
      {totalCount !== 0 && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

OrdersTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OrdersTitle;
