import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getParametersWithURL } from 'utils/getParametersWithURL';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as productsActions } from 'models/products/slice';

import { foodCategoriesSelector } from 'models/products/selectors';
import ProductCategoryTable from 'components/ProductCategoryTable';

const FoodCategoriesSummary = ({ title }) => {
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const categories = useSelector(foodCategoriesSelector);
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (!categories) fetchCategories();
  }, [categories, fetchCategories]);

  useEffect(() => {
    if (filters) fetchCategories(getParametersWithURL(filters));
  }, [filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <ProductCategoryTable
                categories={Object.values(categories ?? {})}
                onFiltersChange={setFilters}
                categoryPath="/food-categories"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

FoodCategoriesSummary.propTypes = {
  title: PropTypes.string,
};

export default FoodCategoriesSummary;
