import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import { actions as transfersActions } from 'models/transfers/slice';
import useAction from 'hooks/useAction';
import { inProgressTransfersNotificationPaginationSelector } from 'models/transfers/selectors';
import { MILLISECONDS_IN_AN_HOUR } from 'constants/time';

const ProductMovementTitle = ({ title }) => {
  // Actions
  const fetchInProgressTransfers = useAction(
    transfersActions.fetchInProgressTransfersNotification
  );

  // Selectors
  const pagination = useSelector(
    inProgressTransfersNotificationPaginationSelector
  );

  // Effects
  useEffect(() => {
    fetchInProgressTransfers({
      type: 'WarehouseToWarehouseTransfer',
      brand_title: 'All brands',
      receiver_title: 'All receivers',
      sender_title: 'All senders',
      statuses: 'DISPUTED,PENDING_RECEIPT',
      per_page: 999,
      setQuery: false,
    });

    const interval = setInterval(
      fetchInProgressTransfers({
        type: 'WarehouseToWarehouseTransfer',
        brand_title: 'All brands',
        receiver_title: 'All receivers',
        sender_title: 'All senders',
        statuses: 'DISPUTED,PENDING_RECEIPT',
        per_page: 999,
        setQuery: false,
      }),
      MILLISECONDS_IN_AN_HOUR
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

ProductMovementTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ProductMovementTitle;
