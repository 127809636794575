import React, { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { actions as emailTemplatesActions } from 'models/emailTemplates/slice';
import useSelector from 'hooks/useSelector';
import {
  currentEmailTemplateSelector,
  previewLayoutModalSelector,
  previewLayoutPendingSelector,
  previewLayoutSelector,
} from 'models/emailTemplates/selectors';
import { Col, Row } from 'reactstrap';
import CustomEmailForm from 'components/CustomEmailForm';
import EmailTemplatePopup from 'components/Popup/EmailTemplatePopup/EmailTemplatePopup';
import { getRedactedValues } from 'utils/getRedactedValues';
import { actions as wineClubsActions } from 'models/wineclubs/slice';
import { wineclubSelector } from 'models/wineclubs/selectors';
import { isEmpty } from 'lodash';
import { EMAIL_FIELD_TYPES } from 'constants/emailTemplates';

const getPreparedDataForUpdate = (defaultValues, values) => {
  const redactedValues = getRedactedValues(defaultValues, values);
  return Object.entries(redactedValues).reduce((previous, current) => {
    const [key, value] = current;
    if (key.split('_')[0] === EMAIL_FIELD_TYPES.LOGO && value == null) {
      return {
        ...previous,
        [`delete_${key}`]: true,
      };
    }
    return { ...previous, [key]: value };
  }, {});
};

const EditCustomEmail = ({ title }) => {
  const { id } = useParams();
  const fetchCustomEmail = useAction(
    emailTemplatesActions.fetchCustomEmailById
  );
  const currentCustomEmail = useSelector(currentEmailTemplateSelector);
  const updateCustomEmail = useAction(emailTemplatesActions.updateCustomEmail);
  const fetchWineClub = useAction(wineClubsActions.fetchWineclub);
  const setPreviewLayoutModal = useAction(
    emailTemplatesActions.setPreviewLayoutModal
  );
  const previewLayout = useSelector(previewLayoutSelector);
  const previewLayoutModal = useSelector(previewLayoutModalSelector);
  const previewLayoutPending = useSelector(previewLayoutPendingSelector);
  const wineClub = useSelector(wineclubSelector);

  const customEmailDefaultValues = {
    ...currentCustomEmail,
    ...currentCustomEmail?.data,
    wineClub: {
      label: wineClub?.title,
      value: wineClub?.id,
      brand_id: wineClub?.brand?.id,
    },
  };

  useEffect(() => {
    fetchCustomEmail(id);
  }, []);

  useEffect(() => {
    if (currentCustomEmail?.wineclub_id) {
      fetchWineClub(currentCustomEmail.wineclub_id);
    }
  }, [currentCustomEmail]);

  const onSubmitHandler = data => {
    const preparedData = getPreparedDataForUpdate(
      customEmailDefaultValues,
      data
    );
    updateCustomEmail({ id, data: preparedData });
  };

  const onPreviewModalClose = () => {
    setPreviewLayoutModal(false);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Custom Template"
        breadcrumbItems={[
          {
            title: 'Back to email list',
            link: `/email-templates/`,
          },
        ]}
      />
      {previewLayoutModal && (
        <EmailTemplatePopup
          onClose={onPreviewModalClose}
          layout={previewLayout?.data}
          loading={previewLayoutPending}
        />
      )}
      {currentCustomEmail && !isEmpty(wineClub) && (
        <Row>
          <Col md={12}>
            <CustomEmailForm
              onSubmit={onSubmitHandler}
              onPreviewClick={data =>
                onSubmitHandler({ ...data, preview: true })
              }
              defaultValues={customEmailDefaultValues}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditCustomEmail.propTypes = {
  title: PropTypes.string.isRequired,
};
export default EditCustomEmail;
