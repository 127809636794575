import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ImportData.scss';
import BodyModal from '../BodyModal';
import { Button, CardHeader } from 'reactstrap';

const DataBlock = ({ title, children }) => (
  <div>
    <h4 className="mb-2">{title}</h4>
    <div>{children}</div>
  </div>
);

const ImportData = ({ data, closeModal }) => {
  return (
    <BodyModal
      title="There's data that cannot be imported"
      setIsOpen={closeModal}
      footer={
        <Button color="light" onClick={() => closeModal(null)}>
          Ok
        </Button>
      }
    >
      {Object.keys(data).length !== 0 && (
        <>
          {(data?.customers_created >= 0 ||
            data?.customers_updated >= 0 ||
            data?.customers_processed >= 0 ||
            data?.customers_skipped >= 0 ||
            data?.customers_with_errors) && (
            <DataBlock title="Customers imported information">
              <ul className={styles.navbar}>
                <li>Customers Created: {data?.customers_created}</li>
                <li>Processed: {data?.customers_processed}</li>
                <li>Customers skipped: {data?.customers_skipped}</li>
                <li>Updated: {data?.customers_updated}</li>
                <li>Any errors: {data?.customers_with_errors}</li>
              </ul>
            </DataBlock>
          )}
          {!!data?.not_imported_products && (
            <DataBlock title="Not Imported Products">
              <ul className={styles.navbar}>
                {Object.keys(data?.not_imported_products || {}).map(key => (
                  <li key={key}>
                    {key}: &ldquo;sort&rdquo; -{' '}
                    {data.not_imported_products[key].sort?.[0]}
                  </li>
                ))}
              </ul>
            </DataBlock>
          )}
          {!!data?.not_imported_tiers && (
            <DataBlock title="Not Imported Tiers">
              {Object.keys(data?.not_imported_tiers || {}).map((key, index) => (
                <>
                  <h6 className={classNames('mb-0', index === 0 && 'mt-2')}>
                    {key}
                  </h6>
                  <ul className={styles.navbar}>
                    {(data?.not_imported_tiers[key] || []).map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </>
              ))}
            </DataBlock>
          )}
          {!!data?.not_imported_wine_club_members &&
            Object.keys(data?.not_imported_wine_club_members ?? {}).length !==
              0 && (
              <DataBlock title="Not Imported Wineclub Members">
                <ul className={styles.navbar}>
                  {Object.keys(data?.not_imported_wine_club_members).map(
                    key => (
                      <li key={key}>
                        {key}: {data?.not_imported_wine_club_members[key]}
                      </li>
                    )
                  )}
                </ul>
              </DataBlock>
            )}
          {!!data?.imported_with_warnings_wine_club_members &&
            Object.values(data?.imported_with_warnings_wine_club_members ?? {})
              .length !== 0 && (
              <DataBlock title="Imported With Warnings Wineclub Members">
                <ul className={styles.navbar}>
                  {Object.keys(
                    data?.imported_with_warnings_wine_club_members
                  ).map(key => (
                    <li key={key}>
                      {data?.imported_with_warnings_wine_club_members[key]}
                    </li>
                  ))}
                </ul>
              </DataBlock>
            )}
        </>
      )}
      <CardHeader>
        Customers Lifetime value and Last order date will be updated on the
        background within 5-10 minutes.
      </CardHeader>
    </BodyModal>
  );
};

DataBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array,
};

ImportData.propTypes = {
  data: PropTypes.object,
  closeModal: PropTypes.func,
};

export default memo(ImportData);
