import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import { pendingFulfillmentAllOrdersPaginationSelector } from 'models/orders/selectors';
import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import { ALL_ORDER_TYPES } from 'pages/OrdersSummary/constants';

const DTCOrdersTitle = ({ title }) => {
  // Actions
  const fetchPendingFulfillmentAllOrders = useAction(
    ordersActions.fetchPendingFulfillmentAllOrders
  );

  // Selectors
  const pagination = useSelector(pendingFulfillmentAllOrdersPaginationSelector);

  // Effects
  useEffect(() => {
    fetchPendingFulfillmentAllOrders({
      resourcetypes: ALL_ORDER_TYPES.map(type => type.value),
    });
  }, [fetchPendingFulfillmentAllOrders]);

  // Constants
  const totalCount = pagination?.total_count || null;

  return (
    <span>
      {title}
      {totalCount && (
        <span className="badge badge-light ml-2">{totalCount}</span>
      )}
    </span>
  );
};

DTCOrdersTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DTCOrdersTitle;
