import React from 'react';
import ActionDropdown from '../ActionDropdown';
import PropTypes from 'prop-types';

const PaymentDropdown = ({ onDelete, onMakeDefault }) => {
  const dropdownItem = [
    {
      id: 'payment_default',
      label: 'Make Default',
    },
    {
      id: 'payment_delete',
      icon: <i className="mdi mdi-trash-can font-size-18 mr-1 align-middle " />,
      label: 'Delete',
    },
  ];

  const onItemClickHandler = item => {
    if (item.id === 'payment_default') {
      onMakeDefault?.();
      return;
    }
    onDelete?.();
  };

  return (
    <ActionDropdown items={dropdownItem} onItemClick={onItemClickHandler} />
  );
};

PaymentDropdown.propTypes = {
  onDelete: PropTypes.func,
  onMakeDefault: PropTypes.func,
};

export default PaymentDropdown;
