import React, { memo } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import PropTypes from 'prop-types';
import CheckboxField from '../Fields/CheckboxField';

const HostFields = ({ selectedBrands, setSelectedBrands, isEditable }) => {
  return (
    <Row className="align-items-center">
      <Col md={4}>
        <FormGroup>
          <Label required htmlFor="brands">
            Active Brands
          </Label>
          <BrandsPaginatedField
            value={selectedBrands}
            onChange={setSelectedBrands}
            id="brands"
          />
        </FormGroup>
      </Col>
      {isEditable && (
        <Col md={3}>
          <CheckboxField
            name="is_host_active"
            label="Is Host Active"
            className="d-flex flex-row-reverse justify-content-end mt-4"
          />
        </Col>
      )}
    </Row>
  );
};

HostFields.propTypes = {
  selectedBrands: PropTypes.array.isRequired,
  setSelectedBrands: PropTypes.func,
  isEditable: PropTypes.bool,
};
export default memo(HostFields);
