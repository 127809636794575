import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { format } from 'date-fns';
import classNames from 'classnames';

import styles from './Header.scss';
import TiersPaginatedField from 'components/PaginatedFields/TiersPaginatedField/TiersPaginatedField';

const selectStyles = {
  menuList: base => ({
    ...base,
    maxHeight: 125,
  }),
};

const WineclubRow = ({
  item,
  onWineClubMemberAction,
  showCancelReasonModal,
  onActivateSubscription,
  onCancelSubscription,
}) => {
  const [selectedTier, setSelectedTier] = useState({
    ...(item?.subscripted_tier ?? {}),
    label: item?.subscripted_tier?.title,
    value: item?.subscripted_tier?.id,
  });

  useEffect(() => {
    setSelectedTier({
      ...(item?.subscripted_tier ?? {}),
      label: item?.subscripted_tier?.title,
      value: item?.subscripted_tier?.id,
    });
  }, [item]);

  const buttonTitle = useMemo(() => {
    if (item?.subscripted_tier?.id) {
      if (selectedTier?.value === item?.subscripted_tier?.id) {
        return 'Cancel subscription';
      } else if (selectedTier?.value) {
        return 'Change subscription';
      }
      return 'Activate subscription';
    }
    return 'Activate subscription';
  }, [selectedTier?.value, item?.subscripted_tier]);

  const buttonColor = useMemo(() => {
    if (
      item?.subscripted_tier?.id &&
      selectedTier?.value === item?.subscripted_tier?.id
    ) {
      return 'danger';
    }
    return 'success';
  }, [selectedTier?.value, item?.subscripted_tier]);

  const handleButtonClick = () => {
    if (
      item?.subscripted_tier?.id &&
      selectedTier?.value === item?.subscripted_tier?.id
    ) {
      onCancelSubscription(item?.obligation_id, item?.wineclub_id);
    } else {
      onActivateSubscription(selectedTier?.value, item?.wineclub_id);
    }
  };

  return (
    <tr key={item?.wineclub_id}>
      <td>{item?.wineclub_id || ''}</td>
      <td>{item?.wineclub_title || ''}</td>
      <td>{format(new Date(item.date_joined), 'yyyy-MM-dd, h:mm aaa')}</td>
      <td className={styles.w300}>
        <TiersPaginatedField
          brandId={item?.brand_id}
          value={selectedTier}
          isMulti={false}
          styles={selectStyles}
          isDisabled
          reqOptions={{ is_available: true }}
        />
      </td>
      <td>{item?.is_gift ? 'Yes' : 'No'}</td>
      <td>{item?.gift_note ?? '-'}</td>
      <td>
        {item?.last_order_date
          ? format(new Date(item.last_order_date), 'yyyy-MM-dd, h:mm aaa')
          : '-'}
      </td>
      <td>
        {item?.tier_cancel_date
          ? format(new Date(item.tier_cancel_date), 'yyyy-MM-dd, h:mm aaa')
          : '-'}
      </td>
      <td>
        {item?.tier_cancel_reason ? (
          <div
            className={styles.cancelReasonWrapper}
            onClick={() => showCancelReasonModal(item?.tier_cancel_reason)}
          >
            <h6
              className={classNames('text-truncate', styles.cancelReasonTitle)}
            >
              {item?.tier_cancel_reason}
            </h6>
          </div>
        ) : (
          '-'
        )}
      </td>
      <td className={styles.actionColumn}>
        {item?.is_active && (
          <Button
            onClick={handleButtonClick}
            color={buttonColor}
            disabled={!item?.subscripted_tier?.id && !selectedTier?.value}
            className={styles.subscriptionActionButton}
          >
            {buttonTitle}
          </Button>
        )}
        <Button
          onClick={() => onWineClubMemberAction?.(item.id)}
          color={item?.is_active ? 'primary' : 'success'}
        >
          {item?.is_active ? 'Archive' : 'Restore'}
        </Button>
      </td>
    </tr>
  );
};

WineclubRow.propTypes = {
  item: PropTypes.object,
  showCancelReasonModal: PropTypes.func,
  onWineClubMemberAction: PropTypes.func,
  onCancelSubscription: PropTypes.func,
  onActivateSubscription: PropTypes.func,
};

export default WineclubRow;
