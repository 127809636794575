import React from 'react';
import PropTypes from 'prop-types';
import ExciseReturnsWineSummary from './Wine/ExciseReturnsWineSummary';
import ExciseReturnsUserSummary from './User/ExciseReturnsUserSummary';
import ExciseReturnsWarehouseSummary from './Warehouse/ExciseReturnsWarehouseSummary';

// Conditional component for Wine, User, and Warehouse Licenses
const ExciseReturnsSummary = ({ exciseLicense }) => (
  <>
    {exciseLicense?.license_type === 'WINE' && (
      <ExciseReturnsWineSummary exciseLicense={exciseLicense} />
    )}
    {exciseLicense?.license_type === 'USER' && (
      <ExciseReturnsUserSummary exciseLicense={exciseLicense} />
    )}
    {exciseLicense?.license_type === 'WAREHOUSE' && (
      <ExciseReturnsWarehouseSummary exciseLicense={exciseLicense} />
    )}
  </>
);

ExciseReturnsSummary.propTypes = {
  exciseLicense: PropTypes.object.isRequired,
};

export default ExciseReturnsSummary;
