import { createSelector } from 'reselect';

export const rootSelector = state => state.organizations;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const organizationsSelector = createSelector(
  rootSelector,
  ({ organizations }) => organizations
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const currentTabSelector = createSelector(
  rootSelector,
  ({ currentTab }) => currentTab
);

export const organizationSelector = createSelector(
  rootSelector,
  ({ organization }) => organization
);

export const createdOrganizationSelector = createSelector(
  rootSelector,
  ({ createdOrganization }) => createdOrganization
);

export const isPendingNotesSelector = createSelector(
  rootSelector,
  ({ notesPending }) => notesPending
);

export const isPendingActivitiesSelector = createSelector(
  rootSelector,
  ({ activitiesPending }) => activitiesPending
);

export const blockMainInformationTabSelector = createSelector(
  rootSelector,
  ({ blockMainInformationTab }) => blockMainInformationTab
);

export const fieldErrorsSelector = createSelector(
  rootSelector,
  ({ fieldErrors }) => fieldErrors
);

export const existingCustomerSelector = createSelector(
  rootSelector,
  ({ existingCustomer }) => existingCustomer
);
