import { createSelector } from 'reselect';

export const rootSelector = state => state.tastingRooms;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const tastingRoomsSelector = createSelector(
  rootSelector,
  ({ tastingRooms }) => tastingRooms
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const tastingRoomSelector = createSelector(
  rootSelector,
  ({ tastingRoom }) => tastingRoom
);
