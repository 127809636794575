import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { normalize } from 'utils/normalizeById';

const getPrepearedTemplatesAfterMakeDefault = (data, templates) => {
  return Object.values(templates).reduce((previous, current) => {
    if (current.id === data.id) {
      previous[current.id] = { ...current, default: data?.default };
    } else if (current.email_template_id === data.email_template_id) {
      previous[current.id] = { ...current, default: false };
    } else {
      previous[current.id] = current;
    }

    return previous;
  }, {});
};

const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState: {
    emailTemplates: {},
    emailTemplatesPagination: null,
    currentEmailTemplate: false,
    isPending: false,
    previewLayout: null,
    previewLayoutModal: false,
    previewLayoutPending: false,
  },
  reducers: {
    fetchEmailTemplates: state => {
      state.isPending = true;
    },
    fetchEmailTemplatesSuccess: (state, { payload }) => {
      const { results, pagination } = payload.data;
      state.isPending = false;
      state.emailTemplates = normalize(results);
      state.emailTemplatesPagination = pagination;
    },
    fetchEmailTemplatesFailure: state => {
      state.isPending = false;
    },
    fetchEmailTemplateById: state => {
      state.isPending = true;
      state.currentEmailTemplate = null;
    },
    fetchEmailTemplateByIdSuccess: (state, { payload }) => {
      const { data } = payload;
      state.isPending = false;
      state.currentEmailTemplate = data;
    },
    fetchEmailTemplateByIdFailure: state => {
      state.isPending = false;
    },
    createEmailTemplate: state => {
      state.isPending = true;
    },
    createEmailTemplateSuccess: state => {
      state.isPending = false;
    },
    createEmailTemplateFailure: state => {
      state.isPending = false;
    },
    setPreviewLayout: (state, { payload }) => {
      state.previewLayout = payload;
    },
    setPreviewLayoutModal: (state, { payload }) => {
      state.previewLayoutModal = payload;
    },
    emailTemplatePreview: state => {
      state.previewLayoutPending = true;
      state.previewLayoutModal = true;
      state.previewLayout = null;
    },
    emailTemplatePreviewSuccess: state => {
      state.previewLayoutPending = false;
    },
    emailTemplatePreviewFailure: state => {
      state.previewLayoutPending = false;
      state.previewLayoutModal = false;
    },
    existsEmailTemplatePreview: state => {
      state.previewLayoutPending = true;
      state.previewLayoutModal = true;
      state.previewLayout = null;
    },
    existsEmailTemplatePreviewSuccess: state => {
      state.previewLayoutPending = false;
    },
    existsEmailTemplatePreviewFailure: state => {
      state.previewLayoutPending = false;
      state.previewLayoutModal = false;
    },
    toggleDefaultEmailTemplate: state => {
      state.isPending = true;
    },
    toggleDefaultEmailTemplateSuccess: (state, { payload }) => {
      const { id, email_template_id } = payload.data;
      state.emailTemplates = getPrepearedTemplatesAfterMakeDefault(
        { id, email_template_id, default: payload?.data?.default },
        state.emailTemplates
      );
      state.isPending = false;
    },
    toggleDefaultEmailTemplateFailure: state => {
      state.isPending = false;
    },
    deleteEmailTemplate: state => {
      state.isPending = true;
    },
    deleteEmailTemplateSuccess: (state, { payload }) => {
      const { templateId } = payload.inResponseTo;
      state.isPending = false;
      delete state.emailTemplates[templateId];
    },
    deleteEmailTemplateFailure: state => {
      state.isPending = false;
    },
    updateEmailTemplate: (state, { payload }) => {
      const { preview } = payload.data;
      if (preview) {
        state.previewLayoutPending = true;
        state.previewLayoutModal = true;
        state.previewLayout = null;
      }
      state.isPending = true;
    },
    updateEmailTemplateSuccess: state => {
      state.isPending = false;
      state.previewLayoutPending = false;
    },
    updateEmailTemplateFailure: state => {
      state.isPending = false;
      state.previewLayoutPending = false;
    },
    fetchCustomEmails: state => {
      state.isPending = true;
    },
    fetchCustomEmailsSuccess: (state, { payload }) => {
      const { results, pagination } = payload.data;
      state.isPending = false;
      state.emailTemplates = normalize(results);
      state.emailTemplatesPagination = pagination;
    },
    fetchCustomEmailsFailure: state => {
      state.isPending = false;
    },
    fetchCustomEmailById: state => {
      state.isPending = true;
      state.currentEmailTemplate = null;
    },
    fetchCustomEmailByIdSuccess: (state, { payload }) => {
      const { data } = payload;
      state.isPending = false;
      state.currentEmailTemplate = data;
    },
    fetchCustomEmailByIdFailure: state => {
      state.isPending = false;
    },
    createCustomEmail: state => {
      state.isPending = true;
    },
    createCustomEmailSuccess: state => {
      state.isPending = true;
    },
    createCustomEmailFailure: state => {
      state.isPending = true;
    },
    customEmailPreview: state => {
      state.previewLayoutPending = true;
      state.previewLayoutModal = true;
      state.previewLayout = null;
    },
    customEmailPreviewSuccess: state => {
      state.previewLayoutPending = false;
    },
    customEmailPreviewFailure: state => {
      state.previewLayoutPending = false;
      state.previewLayoutModal = false;
    },
    updateCustomEmail: (state, { payload }) => {
      const { preview } = payload.data;
      if (preview) {
        state.previewLayoutPending = true;
        state.previewLayoutModal = true;
        state.previewLayout = null;
      }
      state.isPending = true;
    },
    updateCustomEmailSuccess: state => {
      state.isPending = false;
      state.previewLayoutPending = false;
    },
    updateCustomEmailFailure: state => {
      state.isPending = false;
      state.previewLayoutPending = false;
    },
    deleteCustomEmail: state => {
      state.isPending = true;
    },
    deleteCustomEmailSuccess: (state, { payload }) => {
      const { inResponseTo } = payload;
      state.isPending = false;
      delete state.emailTemplates[inResponseTo];
    },
    deleteCustomEmailFailure: state => {
      state.isPending = false;
    },
    existsCustomEmailPreview: state => {
      state.previewLayoutPending = true;
      state.previewLayoutModal = true;
      state.previewLayout = null;
    },
    existsCustomEmailPreviewSuccess: state => {
      state.previewLayoutPending = false;
    },
    existsCustomEmailPreviewFailure: state => {
      state.previewLayoutPending = false;
      state.previewLayoutModal = false;
    },
  },
});

export const actions = actionTypes(emailTemplatesSlice.actions);

export default emailTemplatesSlice.reducer;
