import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/TastingRoomForm';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useComponentDidMount from 'hooks/useComponentDidMount';
import {
  tastingRoomSelector,
  isPendingSelector as tastingRoomIsPendingSelector,
} from 'models/tastingRooms/selectors';

import {
  warehousesSelector,
  isPendingSelector as warehousesIsPendingSelector,
} from 'models/warehouses/selectors';
import { actions as warehousesActions } from 'models/warehouses/slice';
import { actions as tastingRoomsActions } from 'models/tastingRooms/slice';

const EditTastingRoom = ({ title }) => {
  const { tastingRoomID } = useParams();

  const fetchWarehouses = useAction(warehousesActions.fetchWarehouses);
  const tastingRoom = useSelector(tastingRoomSelector);
  const warehousesIsPending = useSelector(warehousesIsPendingSelector);
  const tastingRoomIsPending = useSelector(tastingRoomIsPendingSelector);
  const updateTastingRoom = useAction(tastingRoomsActions.updateTastingRoom);
  const fetchTastingRoom = useAction(tastingRoomsActions.fetchTastingRoom);
  const warehouses = useSelector(warehousesSelector);

  const [currentWarehouseID, setCurrentWarehouseID] = useState(
    tastingRoom?.warehouse_id
  );
  const currentWarehouseTitle = tastingRoom?.warehouse_title;

  const isPending = tastingRoomIsPending || warehousesIsPending;

  useEffect(() => {
    fetchTastingRoom(tastingRoomID);
  }, [fetchTastingRoom, tastingRoomID]);

  useEffect(() => {
    setCurrentWarehouseID(tastingRoom?.warehouse_id);
  }, [setCurrentWarehouseID, tastingRoom]);

  useComponentDidMount(() => {
    fetchWarehouses();
  });

  if (tastingRoomIsPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={tastingRoom?.name}
        breadcrumbItems={[
          { title: 'Back to rooms', link: '/tasting_rooms/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            submitTitle="Save"
            onSubmit={updateTastingRoom}
            model={tastingRoom}
            currentWarehouse={currentWarehouseID}
            currentWarehouseTitle={currentWarehouseTitle}
            setCurrentWarehouse={setCurrentWarehouseID}
            warehouses={warehouses}
            disabled={isPending}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

EditTastingRoom.propTypes = {
  title: PropTypes.string,
};

export default EditTastingRoom;
