import axios from 'axios';
import config from 'config';

import useSelector from 'hooks/useSelector';
import { tokenSelector } from 'models/user/selectors';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export default () => {
  const authToken = useSelector(tokenSelector);

  return ({
    url,
    data,
    token,
    params,
    headers = {},
    method = 'get',
    responseType = 'json',
    isRequestWithoutToken,
  }) => {
    const totalHeaders = {
      ...defaultHeaders,
      ...headers,
    };

    if (!isRequestWithoutToken) {
      const _token = token || authToken;
      if (_token) {
        totalHeaders.authorization = `Token ${_token}`;
      }
    }

    return axios({
      url,
      data,
      params,
      method,
      responseType,
      headers: totalHeaders,
      baseURL: config.baseUrl,
    }).then(response => response.data);
  };
};
