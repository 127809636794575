/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';

import PaymentTab from 'components/PaymentTab';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as brandsActions } from 'models/brands/slice';
import { actions as ordersActions } from 'models/orders/slice';
import { orderSelector, isPendingSelector } from 'models/orders/selectors';
import {
  brandSelector,
  isPendingSelector as brandIsPendingSelector,
} from 'models/brands/selectors';

import {
  ORDER_PAYMENT_STATUSES,
  PAYMENT_TYPES_FOR_COMMERCIAL_ORDERS,
} from 'constants';
import SelectionPopup from '../SelectionPopup';

const SelectPaymentMethodPopup = ({ options, isOpen, setIsOpen }) => {
  const order = useSelector(orderSelector);

  const [currentCard, setCurrentCard] = useState('');
  const [cardOptions, setCardOptions] = useState([]);
  const [paymentType, setPaymentType] = useState({});

  const fetchBrand = useAction(brandsActions.fetchBrand);
  const changeOrderStatus = useAction(ordersActions.changeOrderStatus);
  const capturePayment = useAction(ordersActions.captureCommercialPayment);
  const brandPending = useSelector(brandIsPendingSelector);
  const orderPending = useSelector(isPendingSelector);
  const brand = useSelector(brandSelector);

  const [comment, setComment] = useState('');

  const pending = brandPending || orderPending;

  useEffect(() => {
    if (!isEmpty(order) && isEmpty(brand)) {
      fetchBrand(order?.brand?.id);
    }
  }, [order, fetchBrand]);

  useEffect(() => {
    setPaymentType({
      apiValue: order?.expected_payment_type,
      userValue: capitalize(
        PAYMENT_TYPES_FOR_COMMERCIAL_ORDERS[order?.expected_payment_type]
          ?.userValue
      ),
      orderType: order?.resourcetype,
    });
    setComment('');
  }, [order]);

  const handlePayButtonClick = () => {
    if (paymentType.apiValue === 'CARD') {
      capturePayment({
        id: order.id,
        payment_method_id: currentCard.id,
        shipment_rate_ext_id: order?.shipping_info?.shippings?.[0].id,
        shipment_ext_id: order?.shipping_info?.shippings?.[0].shipment_id,
        shipping_company_id:
          order?.shipping_info?.shippings?.[0].brand_shipping_company_id,
        price: order?.shipping_info?.shippings?.[0].price,
        email: order?.shipping_info?.email,
      });
    } else {
      changeOrderStatus({
        id: order?.id,
        orderType: order?.resourcetype,
        status: ORDER_PAYMENT_STATUSES[paymentType.apiValue],
        comment,
        email: order?.shipping_info?.email,
      });
    }
    setIsOpen(false);
  };

  return (
    <SelectionPopup
      title="Select payment method"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      withFooter={false}
    >
      {pending ? (
        <div>Loading...</div>
      ) : (
        <Row>
          <Col md={12}>
            <PaymentTab
              stripeToken={brand?.active_stripe_acquirer?.public_key}
              stripeAccount={brand?.active_stripe_acquirer?.account_id}
              currentCustomerId={order?.organization?.contact_person?.id}
              currentBrand={brand}
              cardOptions={cardOptions}
              setCardOptions={setCardOptions}
              setCurrentCard={setCurrentCard}
              currentCard={currentCard}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              options={options}
              withoutType
            />
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col md={12}>
          <FormGroup>
            <Label htmlFor="comment">Comment</Label>
            <textarea
              id="comment"
              className="form-control"
              rows="5"
              style={{ resize: 'none' }}
              value={comment}
              onChange={event => setComment(event.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button
        className="mt-1"
        color="primary"
        onClick={handlePayButtonClick}
        loading={pending}
        disabled={pending}
      >
        Pay
      </Button>
    </SelectionPopup>
  );
};

SelectPaymentMethodPopup.propTypes = {
  options: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default memo(SelectPaymentMethodPopup);
