import React, { useCallback, useState } from 'react';
import ReportsLayout from 'components/ReportsLayout';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions as reportsActions } from 'models/reports/slice';
import ReportFilters from '../../components/ReportFilters';

const ReportsBatchSummary = ({ title }) => {
  const [dateRange, setDateRange] = useState();
  const [selectedBrand, setSelectedBrand] = useState(null);

  const fetchBatchXlsx = useAction(reportsActions.fetchBatchXlsx);

  const onExport = useCallback(() => {
    const { startDate, endDate } = dateRange;
    fetchBatchXlsx({
      startDate,
      endDate,
      brandID: selectedBrand?.value,
    });
  }, [fetchBatchXlsx, dateRange, selectedBrand]);

  const setFilters = filters => {
    setSelectedBrand(filters.selectedBrands);
  };

  return (
    <ReportsLayout
      title={title}
      exportButtonText="Export to CSV"
      onExport={onExport}
      onDateChange={setDateRange}
      isDownloadDisabled={Boolean(selectedBrand === null)}
    >
      <ReportFilters setFilters={setFilters} showBrand />
    </ReportsLayout>
  );
};

ReportsBatchSummary.propTypes = {
  title: PropTypes.string,
};
export default ReportsBatchSummary;
