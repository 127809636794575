import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';

import s from './DataTable.scss';

const DataTable = ({
  isPending,
  devices,
  setDeviceID,
  setIsOpenConfirmationModal,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
}) => {
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsOpenConfirmationModal(true);
    setDeviceID(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer"
            onClick={() =>
              handleSort(
                'device_name',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Device Name
            <SortArrows
              isActive={sortField === 'device_name'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Mac Address</th>
          <th data-priority="3">Active Session</th>
          <th data-priority="3">Warehouse title</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {devices?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/devices/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/devices/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.device_name}
              </Link>
            </td>
            <td>
              <Link
                to={`/devices/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.mac_address}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/devices/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.has_an_active_session ? 'Yes' : 'No'}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/warehouses/${item.warehouse_id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.warehouse_title}
              </Link>{' '}
            </td>
            <td>
              <>
                <Link
                  to={`/devices/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
                <Link
                  to="#"
                  onClick={e => handleDelete(e, item.id)}
                  className="text-danger"
                  id={`delete${item.id}`}
                >
                  <i className="mdi mdi-trash-can font-size-18" />
                </Link>
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${item.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  devices: PropTypes.array,
  setDeviceID: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};

export default DataTable;
