import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HUMAN_ORDER_STATUSES, ORDER_TYPES_ITEMS } from 'constants';

import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';
import { rolesSelector } from 'models/user/selectors';
import useSelector from 'hooks/useSelector';

import s from './DataTable.scss';
import CommercialActionDropdown from './CommercialActionDropdown';
import { PERMISSION_TYPES } from 'constants/permissions';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { getPersonLabel } from 'utils/getPersonLabel';

const DataTable = ({
  isPending,
  orders,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  onDownload,
  onPrintPackageSlip,
  downloadDisabled,
  openPaymentModal,
  onFulfill,
  isSortable,
  isPendingFulfillment,
  openShipModal,
}) => {
  const roles = useSelector(rolesSelector);

  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1" className="text-nowrap">
            Invoice #
          </th>
          <th
            className={isSortable && 'cursor-pointer'}
            onClick={() =>
              handleSort(
                'created_at',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Created at
            {isSortable && (
              <SortArrows
                isActive={sortField === 'created_at'}
                sortDirection={sortDirection}
              />
            )}
          </th>
          <th
            className={isSortable && 'cursor-pointer'}
            onClick={() =>
              handleSort(
                'total_price',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="1"
          >
            Total Price
            {isSortable && (
              <SortArrows
                isActive={sortField === 'total_price'}
                sortDirection={sortDirection}
              />
            )}
          </th>
          <th data-priority="1">Brand</th>
          <th data-priority="3">Customer</th>
          <th data-priority="3">Status</th>
          <th data-priority="3">Shipment Status</th>
          {!isSalesRep && <th data-priority="3">Sales associate</th>}
          {!isPendingFulfillment && <th data-priority="3">Type</th>}
          {!isSalesRep && <th data-priority="3">Action</th>}
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {orders?.map(item => {
          const orderLink = {
            pathname: `/commercial-orders/${item.id}`,
            state: {
              previousPath: '/commercial-orders/',
            },
          };
          return (
            <tr key={item.id}>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.invoice_number}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.created_at
                    ? format(
                        Date.parse(item.created_at),
                        'yyyy-MM-dd, h:mm aaa'
                      )
                    : ''}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {formatPrice(item.total_price)} {item.total_price_currency}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.brand?.title}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.resourcetype === 'ContainerWorldOrder' ? (
                    <>
                      CW Order id: {item.customer?.container_world_order_id}
                      <br />
                      Customer id: {item.customer?.customer_number}
                    </>
                  ) : (
                    <> {item.organization?.title}</>
                  )}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {HUMAN_ORDER_STATUSES[item.status]}
                </Link>
              </td>
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item?.shipment_status ?? '-'}
                </Link>
              </td>
              {!isSalesRep && (
                <td>
                  <Link to={orderLink} className="text-dark font-weight-bold">
                    {getPersonLabel(item, 'created_by')}
                  </Link>
                </td>
              )}
              {!isPendingFulfillment && (
                <td>
                  <Link to={orderLink} className="text-dark font-weight-bold">
                    {ORDER_TYPES_ITEMS[item.resourcetype]}
                  </Link>
                </td>
              )}
              {!isSalesRep && (
                <td className={s.actionColumn}>
                  <CommercialActionDropdown
                    order={item}
                    onDownload={() => onDownload?.(item.id)}
                    onFulfill={() => onFulfill?.(item.id)}
                    onComplete={() => openPaymentModal?.(item.id)}
                    onPrintPackageSlip={() => onPrintPackageSlip?.(item.id)}
                    isDisabled={!!downloadDisabled}
                    onShip={() => openShipModal?.(item.id)}
                  />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  orders: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  onDownload: PropTypes.func,
  onPrintPackageSlip: PropTypes.func,
  downloadDisabled: PropTypes.bool,
  openPaymentModal: PropTypes.func,
  onFulfill: PropTypes.func,
  isSortable: PropTypes.bool,
  isPendingFulfillment: PropTypes.bool,
  openShipModal: PropTypes.func,
};

export default DataTable;
