import React, { useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions } from 'models/inventories/slice';
import useSelector from 'hooks/useSelector';
import { containerWorldExceptionSelector } from 'models/inventories/selectors';
import ContainerWorldExceptionSummaryTable from 'components/ContainerWorldExceptionSummaryTable';

const ContainerWorldExceptionSummary = ({ title }) => {
  const fetchContainerWorldException = useAction(
    actions.fetchContainerWorldException
  );
  const containerWorldException = useSelector(containerWorldExceptionSelector);

  useEffect(() => {
    fetchContainerWorldException();
  }, []);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col md={12}>
          <Card>
            {containerWorldException.length > 0 && (
              <CardBody>
                <div className="alert alert-warning" role="alert">
                  <i className="mdi mdi-information" />{' '}
                  <b>
                    ContainerWorld is reporting inventory that is out of balance
                    with Cellar.
                  </b>{' '}
                  <br />
                  This may be expected if there is a transfer en-route to
                  ContainerWorld. If not then contacting ContainerWorld may be
                  required to investigate the difference.
                </div>
                <br />
                <ContainerWorldExceptionSummaryTable
                  data={containerWorldException}
                />
              </CardBody>
            )}
            {containerWorldException.length === 0 && (
              <CardBody>
                <div>There are currently no exceptions.</div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ContainerWorldExceptionSummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default ContainerWorldExceptionSummary;
