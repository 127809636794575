import React from 'react';
import styles from './OrderErrorsTable.scss';
import { Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OrderErrorsTable = ({ data, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Row>
        <Col md={12}>
          <Table className={styles.table} bordered responsive>
            <thead>
              <tr>
                <th>Code</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id?.toString()}>
                  <td>{item.code}</td>
                  <td>{item.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

OrderErrorsTable.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};
export default OrderErrorsTable;
