import { createSelector } from 'reselect';

export const rootSelector = state => state.discountCodes;

export const discountsSelector = createSelector(
  rootSelector,
  ({ discounts }) => discounts
);
export const codeSelector = createSelector(rootSelector, ({ code }) => code);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);
