import { createSelector } from 'reselect';
import { keyBy, get } from 'lodash';

export const rootSelector = state => state.brandsCompanies;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const brandsCompaniesSelector = createSelector(
  rootSelector,
  ({ brandsCompanies }) => brandsCompanies
);

export const companiesSelector = createSelector(
  rootSelector,
  ({ companies }) => companies
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const brandsCompaniesFetchedSelector = createSelector(
  rootSelector,
  ({ brandsCompaniesFetched }) => brandsCompaniesFetched
);

export const brandsCompaniesByCompanyIdSelector = createSelector(
  brandsCompaniesSelector,
  brandsCompanies => keyBy(brandsCompanies, 'shipping_company_id')
);

export const currentBrandCompaniesSelector = createSelector(
  rootSelector,
  ({ currentBrandCompanies }) => currentBrandCompanies
);

export const currentBrandCompaniesListSelector = createSelector(
  [currentBrandCompaniesSelector],
  companies => Object.values(companies)
);

export const currentBrandCompaniesByCompanyIdSelector = createSelector(
  [currentBrandCompaniesListSelector],
  currentBrandCompaniesList =>
    keyBy(currentBrandCompaniesList, 'shipping_company_id')
);

export const availableCompaniesForBrands = createSelector(
  [currentBrandCompaniesByCompanyIdSelector, companiesSelector],
  (currentBrandCompaniesByCompanyId, companies) =>
    keyBy(
      companies
        .map(company => {
          const brandCompany = get(
            currentBrandCompaniesByCompanyId,
            company.id,
            null
          );

          return {
            shipping_company_title: company.title,
            shipping_company_id: company.id,
            entity_id: brandCompany?.id ?? null,
            is_active: brandCompany?.is_active ?? false,
            api_key: brandCompany?.api_key ?? '',
            brand_id: brandCompany?.brand_id ?? null,
            brand_title: brandCompany?.brand_title ?? null,
          };
        })
        .filter(company => !company.brand_id),
      'shipping_company_id'
    )
);

export const availableCompaniesOptionsForSelectSelector = createSelector(
  availableCompaniesForBrands,
  companies =>
    Object.values(companies).map(c => ({
      value: c.shipping_company_id,
      label: c.shipping_company_title,
    }))
);
