import React, { useEffect, useState } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';

import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import { useParams } from 'react-router-dom';
import {
  merchandiseCategoriesSelector,
  isPendingSelector,
} from 'models/products/selectors';
import Preloader from 'components/Preloader';
import ProductCategoryForm from 'components/ProductCategoryForm';

const EditMerchCategory = ({ title }) => {
  const { id } = useParams();
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const updateCategory = useAction(productsActions.updateProductCategory);
  const merchandiseCategories = useSelector(merchandiseCategoriesSelector);
  const pending = useSelector(isPendingSelector);
  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    if (merchandiseCategories && id) {
      setCurrentCategory(merchandiseCategories?.[id]);
    }
  }, [merchandiseCategories, setCurrentCategory, id]);

  useEffect(() => {
    if (!merchandiseCategories) {
      fetchCategories();
    }
  }, [fetchCategories, merchandiseCategories]);

  const handleSubmit = data => {
    updateCategory({
      ...data,
      id,
      redirectUrl: '/merchandise_categories/',
    });
  };

  if (pending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Update merch category"
        breadcrumbItems={[
          {
            title: 'Back to categories',
            link: '/merchandise_categories/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Edit</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {currentCategory && (
        <Row>
          <Col md="12">
            <ProductCategoryForm
              onSubmit={handleSubmit}
              submitTitle="Save"
              model={currentCategory}
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditMerchCategory.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EditMerchCategory;
