import { createSelector } from 'reselect';

export const rootSelector = state => state.importDataSources;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const importIsPendingSelector = createSelector(
  rootSelector,
  ({ importIsPending }) => importIsPending
);

export const dataSourcesSelector = createSelector(
  rootSelector,
  ({ dataSources }) => dataSources
);

export const dataImportsSelector = createSelector(
  rootSelector,
  ({ dataImports }) => dataImports
);
