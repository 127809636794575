import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PopupBase from '../PopupBase';
import styles from './CancelReasonPopup.scss';

const CancelReasonPopup = ({ onClose, title }) => {
  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      {title}
    </PopupBase>
  );
};

CancelReasonPopup.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default memo(CancelReasonPopup);
