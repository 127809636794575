import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as mailingListsActions } from 'models/mailingLists/slice';
import { filtersSelector } from 'models/mailingLists/selectors';
import { actions as brandsActions } from 'models/brands/slice';
import { actions as tiersActions } from 'models/tiers/slice';

import { Button, Col, Label, Row } from 'reactstrap';
import SelectField from 'components/SelectField/SelectField';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import TiersPaginatedField from 'components/PaginatedFields/TiersPaginatedField';

const filtersOrdering = [
  'customers_all',
  'customers_with_subscriptions',
  'customers_by_tier',
];

const filtersTitles = {
  customers_all: 'All customers',
  customers_with_subscriptions: 'Wine club members',
  customers_by_tier: 'Wine club members with subscription',
};

const buttonStates = {
  customers_all: false,
  customers_with_subscriptions: false,
  customers_by_tier: true,
};

const MailingLists = ({ title }) => {
  const fetchBrands = useAction(brandsActions.fetchBrands);
  const fetchFilters = useAction(mailingListsActions.fetchFilters);
  const fetchTiers = useAction(tiersActions.fetchTiers);
  const fetchFile = useAction(mailingListsActions.fetchFile);
  const filters = useSelector(filtersSelector);
  const [currentBrandId, setCurrentBrandId] = useState();
  const [currentFilter, setCurrentFilter] = useState();
  const [additional, setAdditional] = useState();
  const [filtersOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    setFilterOptions(
      filters
        .map(filter => ({
          ...filter,
          title: filtersTitles[filter.title],
          name: filter.title,
          order: filtersTitles.index,
        }))
        .sort(
          (a, b) =>
            filtersOrdering.indexOf(a.name) - filtersOrdering.indexOf(b.name)
        )
    );
  }, [filters]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  useEffect(() => {
    setAdditional(undefined);
    if (currentFilter?.name === 'customers_by_tier') {
      fetchTiers({
        brand_id: currentBrandId,
      });
    }
  }, [currentFilter, currentBrandId]);

  const handlerChangeCurrentBrand = item => setCurrentBrandId(item.value);

  const AdditionalSelects = {
    customers_by_tier: useMemo(() => (
      <>
        <Col lg={3}>
          <Label htmlFor="brand_id">Brand*</Label>
          <BrandsPaginatedField
            key="brand_1"
            id="brand_id"
            name="brand_id"
            onChange={item => handlerChangeCurrentBrand(item)}
            isMulti={false}
          />
        </Col>
        {!!currentBrandId && (
          <Col lg={3}>
            <Label htmlFor="tier_id">Tiers*</Label>
            <TiersPaginatedField
              id="tier_id"
              name="tier_id"
              brandId={currentBrandId}
              onChange={item => setAdditional({ tier_id: item.value })}
              isMulti={false}
            />
          </Col>
        )}
      </>
    )),
    customers_with_subscriptions: useMemo(() => (
      <>
        <Col lg={3}>
          <Label htmlFor="brand_id">Brand*</Label>
          <BrandsPaginatedField
            key="brand_2"
            id="brand_id"
            name="brand_id"
            onChange={item => {
              setAdditional({
                brand_ids: item.map(i => i.id),
              });
            }}
            isMulti
          />
        </Col>
      </>
    )),
  };

  const handleSubmit = event => {
    event.preventDefault();
    fetchFile({
      id: currentFilter.id,
      parameters: additional,
      filterTitle: currentFilter.name,
    });
  };

  const handleChangeFilter = item => {
    if (item === currentFilter) {
      return;
    }

    setCurrentBrandId(null);
    setAdditional({});
    setCurrentFilter(item);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={3}>
            <SelectField
              options={filtersOptions}
              label="Filters*"
              name="filters"
              onChange={item => handleChangeFilter(item)}
            />
          </Col>
          {AdditionalSelects[currentFilter?.name] &&
            AdditionalSelects[currentFilter?.name]}
        </Row>
        <Button
          className="mt-3"
          color="primary"
          disabled={
            currentFilter === undefined ||
            (buttonStates[currentFilter?.name] &&
              AdditionalSelects[currentFilter?.name] &&
              additional === undefined)
          }
        >
          Download CSV
        </Button>
      </form>
    </PageWrapper>
  );
};

MailingLists.propTypes = {
  title: PropTypes.string,
};

export default MailingLists;
