import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

import { SORTS_OF_WINE_OPTIONS, VOLUME_UNIT, WEIGHT_UNIT } from 'constants';
import TagsWrapper from '../../TagsWrapper';
import NumberField from '../../Fields/NumberField';
import TextField from '../../Fields/TextField';
import SelectField from '../../SelectField/SelectField';
import TextareaField from '../../Fields/TextareaField';
import CheckboxField from '../../Fields/CheckboxField';
import DateField from '../../Fields/DateField';

const WineFields = ({
  model,
  tags,
  isRemoveLabel,
  isRemoveMainImage,
  canBeASample,
  setLabel,
  setMainImage,
  setIsRemoveLabel,
  setIsRemoveMainImage,
  setCanBeASample,
  setTags,
  selectedBrand,
  weightUnit,
  setWeightUnit,
  sortOfWine,
  setSortOfWine,
}) => {
  return (
    <>
      <Row>
        <Col md="3">
          <SelectField
            isRequired
            name="sort"
            label="Sort of Wine"
            options={SORTS_OF_WINE_OPTIONS.filter(i => i.value !== undefined)}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            value={sortOfWine}
            onChange={setSortOfWine}
          />
        </Col>
        <Col md="3">
          <NumberField
            isRequired
            name="volume_value"
            label="Volume"
            value={model?.volume_value}
          />
        </Col>
        <Col md="3">
          <FormGroup>
            <Label required htmlFor="volume_unit">
              Volume Unit
            </Label>
            <AvField
              type="select"
              name="volume_unit"
              validate={{ required: { value: true } }}
              className="custom-select"
              value={model?.volume_unit || VOLUME_UNIT[0].value}
            >
              {VOLUME_UNIT.map(item => (
                <option key={`volume_unit_${item.value}`} value={item.value}>
                  {item.label}
                </option>
              ))}
            </AvField>
          </FormGroup>
        </Col>

        <Col md="3">
          <TextField name="varietal" label="Varietal" value={model?.varietal} />
        </Col>

        <Col md="3">
          <NumberField
            label="Weight Value"
            name="weight_value"
            value={model?.weight_value}
          />
        </Col>
        <Col md="3">
          <FormGroup>
            <SelectField
              name="weight_unit"
              options={WEIGHT_UNIT}
              label="Weight Unit"
              value={weightUnit}
              onChange={setWeightUnit}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <NumberField
            name="bottles_in_case"
            label="Bottles in Case"
            value={model?.bottles_in_case}
          />
        </Col>
        <Col md="3">
          <NumberField
            name="cases_in_pallet"
            label="Cases in Pallet"
            value={model?.cases_in_pallet}
          />
        </Col>
        <Col md="3">
          <TextField
            name="alias_title"
            label="POS Title"
            value={model?.alias_title}
          />
        </Col>
        <Col md="3">
          <TextField
            name="ecom_title"
            label="Ecom Title"
            value={model?.ecom_title}
          />
        </Col>
        <Col md="6">
          <TextField name="teaser" label="Ecom Teaser" value={model?.teaser} />
        </Col>
        <Col lg="12">
          <TextareaField
            label="Ecom Description"
            name="ecom_description"
            value={model?.ecom_description ?? ''}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <TagsWrapper
            data={tags}
            onChange={setTags}
            creatable
            label="Product Tags"
            disabled={!selectedBrand}
          />
        </Col>
      </Row>
      {canBeASample && (
        <Row>
          <Col md={3}>
            <NumberField
              name="sample_price"
              label="Sample Price"
              value={model?.sample_price}
            />
          </Col>
          <Col md={3}>
            <TextField
              isRequired={canBeASample}
              name="sample_volume_value"
              label="Sample Volume"
              rules={{ required: { value: canBeASample } }}
              value={model?.sample_volume_value}
            />
          </Col>
          <Col md="3">
            <FormGroup>
              <Label required htmlFor="sample_volume_unit">
                Sample Volume Unit
              </Label>
              <AvField
                type="select"
                name="sample_volume_unit"
                validate={{ required: { value: true } }}
                className="custom-select"
                value={model?.sample_volume_unit || VOLUME_UNIT[0].value}
              >
                {VOLUME_UNIT.map(item => (
                  <option
                    key={`sample_volume_unit_${item.value}`}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))}
              </AvField>
            </FormGroup>
          </Col>
          <Col md={3}>
            <TextField
              name="sample_sku"
              label="Sample SKU"
              value={model?.sample_sku}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md="3">
          <CheckboxField name="has_deposit_tax" label="Has deposit tax?" />
        </Col>
        <Col md="3">
          <CheckboxField
            name="can_be_a_sample"
            label="Can be a glass pour?"
            value={canBeASample}
            onChange={() => setCanBeASample(!canBeASample)}
          />
        </Col>
        <Col md="3">
          <CheckboxField
            name="is_it_can_be_used_for_subscription_orders"
            label="Can be used for subscription orders?"
          />
        </Col>
        <Col md="3">
          <CheckboxField
            name="is_visible_on_website"
            label="Is Visible on Website"
          />
        </Col>
        <Col md="3">
          <CheckboxField
            name="is_visible_on_pos"
            label="Is Visible on POS"
            defaultValue={model?.is_visible_on_pos ?? true}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <TextareaField name="description" label="Guest App Description" />
        </Col>
        <Col lg="6">
          <TextareaField name="notes" label="Notes" />
        </Col>
        <Col lg="6">
          <TextareaField
            name="winemaker_notes"
            label="Winemaker Notes"
            value={model?.winemaker_notes ?? ''}
          />
        </Col>
        <Col lg="6">
          <TextareaField
            name="food_pairing"
            label="Food Pairing"
            value={model?.food_pairing ?? ''}
          />
        </Col>
        <Col lg="6">
          <TextareaField
            name="tasting_notes"
            label="Tasting Notes"
            value={model?.tasting_notes ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <TextField name="production" label="Production" />
        </Col>
        <Col md="3">
          <NumberField name="acid" label="Acid" />
        </Col>
        <Col md="3">
          <NumberField name="ph" label="PH" />
        </Col>
        <Col md="3">
          <TextField name="aging" label="Aging" />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <DateField name="bottling_date" label="Bottling Date" />
        </Col>
        <Col md="3">
          <TextField name="alcohol" label="Alcohol" />
        </Col>
        <Col md="3">
          <TextField name="cellar" label="Cellar" />
        </Col>
        <Col md="3">
          <TextField name="residual_sugar" label="Residual Sugar" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label htmlFor="label">Label</Label>
            <div>
              {!isRemoveLabel && model?.label_v_300x300_url && (
                <img
                  className="mr-2"
                  src={model.label_v_300x300_url}
                  alt=""
                  height="60"
                />
              )}
              <input
                name="label"
                type="file"
                id="label"
                accept="image/jpeg,image/png"
                onChange={evt => setLabel(evt.target.files[0])}
              />
              {!isRemoveLabel && model?.label_v_300x300_url && (
                <Button
                  onClick={() => setIsRemoveLabel(true)}
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                >
                  Remove label
                </Button>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label htmlFor="main_image">Main Image</Label>
            <div>
              {!isRemoveMainImage && model?.main_image_url && (
                <img
                  className="mr-2"
                  src={model.main_image_url}
                  alt=""
                  height="100"
                />
              )}
              <input
                name="main_image"
                type="file"
                id="main_image"
                accept="image/jpeg,image/png"
                onChange={evt => setMainImage(evt.target.files[0])}
              />
              {!isRemoveMainImage && model?.main_image_url && (
                <Button
                  onClick={() => setIsRemoveMainImage(true)}
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                >
                  Remove main image
                </Button>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

WineFields.propTypes = {
  setLabel: PropTypes.func,
  setMainImage: PropTypes.func,
  isRemoveLabel: PropTypes.bool,
  setIsRemoveLabel: PropTypes.func,
  isRemoveMainImage: PropTypes.bool,
  setIsRemoveMainImage: PropTypes.func,
  canBeASample: PropTypes.bool,
  setCanBeASample: PropTypes.func,
  model: PropTypes.object,
  tags: PropTypes.array,
  setTags: PropTypes.func,
  selectedBrand: PropTypes.object,
  weightUnit: PropTypes.object,
  setWeightUnit: PropTypes.func,
  sortOfWine: PropTypes.object,
  setSortOfWine: PropTypes.func,
};

export default WineFields;
