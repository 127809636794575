import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import ShippingCompaniesField from 'components/PaginatedFields/ShippingCompaniesField';
import PopupBase from '../PopupBase';
import styles from './ShipmentEditPopup.scss';
import { getNormalText } from 'utils/getNormalText';
import {
  SHIPMENT_STATUS_LABELS,
  SHIPMENT_STATUSES,
  SHIPMENT_TYPES,
} from 'constants/shipment';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import TextField from '../../Fields/TextField';
import NumberField from '../../Fields/NumberField';

const notAvailableStatuses = [SHIPMENT_STATUSES.RETURNED];

const shipmentStatusOptions = Object.keys(SHIPMENT_STATUSES)
  .filter(status => !notAvailableStatuses.includes(status))
  .map(status => ({
    label: SHIPMENT_STATUS_LABELS?.[status] ?? getNormalText(status),
    value: status,
  }));

const getCompanyOption = data => ({
  value: data?.brand_shipping_company_id,
  label: data?.brand_shipping_company_title,
});

const getStatusOption = data => {
  if (!data?.status) return null;
  return shipmentStatusOptions.find(option => option.value === data?.status);
};

const ShipmentEditPopup = ({
  onClose,
  onSubmit,
  defaultValues,
  brandId,
  price,
  cantPriceBeChanged,
}) => {
  const [company, setCompany] = useState(getCompanyOption(defaultValues));
  const [status, setStatus] = useState(getStatusOption(defaultValues));
  const [freeShipping, setFreeShipping] = useState(
    defaultValues?.free_shipping
  );

  const preparedShipmentStatusOptions = useMemo(
    () =>
      shipmentStatusOptions.filter(
        option => option.value !== SHIPMENT_STATUSES.CANCELLED
      ),
    [defaultValues]
  );

  const onSubmitHandler = (_, data) => {
    const preparedData = Object.fromEntries(
      Object.entries({
        ...data,
        brand_shipping_company_id: company?.value,
        status: status?.value,
        free_shipping: freeShipping,
      }).filter(([key, value]) => {
        if (key === 'price' || key === 'free_shipping') {
          return !(!company || cantPriceBeChanged);
        }
        return !!value;
      })
    );
    onSubmit(preparedData);
  };

  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      <ModalHeader toggle={onClose} closeButton>
        Edit Shipment
      </ModalHeader>
      <ModalBody>
        <AvForm model={defaultValues} onValidSubmit={onSubmitHandler}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Status</Label>
                <Select
                  placeholder="Select status..."
                  options={preparedShipmentStatusOptions}
                  styles={selectStyles}
                  value={status}
                  onChange={setStatus}
                />
              </FormGroup>
            </Col>
            {defaultValues?.type === SHIPMENT_TYPES.MANUAL && (
              <>
                <Col md={12}>
                  <TextField
                    name="tracking_number"
                    label="Tracking Number"
                    placeholder="Tracking Number"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    name="public_url"
                    label="Public Url"
                    placeholder="Public Url"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    name="label_url"
                    label="Label Url"
                    placeholder="Label Url"
                  />
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Company</Label>
                    <ShippingCompaniesField
                      brandId={brandId}
                      value={company}
                      onChange={setCompany}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <NumberField
                    label="Price"
                    name="price"
                    placeholder="Shipment Price"
                    disabled={!company || cantPriceBeChanged}
                    value={price}
                  />
                </Col>
                <Col>
                  <label className="d-flex align-items-center mb-4">
                    <input
                      id="free_shipping"
                      name="free_shipping"
                      type="checkbox"
                      checked={freeShipping}
                      onChange={event => setFreeShipping(event.target.checked)}
                      disabled={!company || cantPriceBeChanged}
                    />
                    <Label
                      className="ml-2 mb-0"
                      style={{ fontWeight: 'normal' }}
                      htmlFor="free_shipping"
                    >
                      Don&apos;t charge shipping to customer
                    </Label>
                  </label>
                </Col>
              </>
            )}
          </Row>
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light"
          >
            Save
          </Button>
        </AvForm>
      </ModalBody>
    </PopupBase>
  );
};

ShipmentEditPopup.propTypes = {
  brandId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  price: PropTypes.number,
  cantPriceBeChanged: PropTypes.bool,
};

export default memo(ShipmentEditPopup);
