import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';
import { useHistory } from 'react-router-dom';

const DropdownItemId = Object.freeze({
  ARCHIVE: 'archive',
  RESTORE: 'restore',
  EDIT: 'edit',
  CREATE: 'create_order',
});

const CustomerActionDropdown = ({
  customer,
  onArchive,
  onRestore,
  isArchived,
}) => {
  const router = useHistory();

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.ARCHIVE]: onArchive,
      [DropdownItemId.RESTORE]: onRestore,
    };

    if (item.id === DropdownItemId.CREATE) {
      router.push('/orders/create', {
        customer,
      });
    } else if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  const dropdownItem = [
    {
      id: DropdownItemId.CREATE,
      icon: <i className="ri-add-line align-middle font-size-18 mr-1" />,
      label: 'Create Order',
    },
    {
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/commercial-customers/${customer.id}/edit`,
    },
    {
      id: DropdownItemId.RESTORE,
      icon: <i className="mdi mdi-restore font-size-18 mr-2" />,
      label: 'Restore',
      isHidden: !isArchived,
    },

    {
      id: DropdownItemId.ARCHIVE,
      icon: <i className="mdi mdi-archive font-size-18 mr-2" />,
      label: 'Archive',
      isHidden: isArchived,
    },
  ];

  return (
    <ActionDropdown
      onItemClick={onItemClickHandler}
      items={dropdownItem}
      right
    />
  );
};

CustomerActionDropdown.propTypes = {
  customer: PropTypes.object,
  onArchive: PropTypes.func,
  onRestore: PropTypes.func,
  isArchived: PropTypes.bool,
};

export default CustomerActionDropdown;
