import { createSelector } from 'reselect';

export const rootSelector = state => state.tiers;

export const tiersSelector = createSelector(rootSelector, ({ tiers }) => tiers);
export const tierSelector = createSelector(rootSelector, ({ tier }) => tier);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const tiersStepSelector = createSelector(
  rootSelector,
  ({ step }) => step
);

export const selectedPrefillSelector = createSelector(
  rootSelector,
  ({ selectedPrefill }) => selectedPrefill
);

export const prefillsSelector = createSelector(
  rootSelector,
  ({ prefills }) => prefills
);
