import React, { useEffect } from 'react';
import { Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import MainForm from './MainForm';
import PaymentForm from './PaymentForm';
import { CUSTOMER_FORM_STEPS } from './constants';
import useSelector from 'hooks/useSelector';
import { stepSelector } from 'models/customers/selectors';
import useAction from 'hooks/useAction';
import { actions as customersAction } from 'models/customers/slice';

const CustomerForm = ({ onSubmit, model, buttonTitle, needFormLock }) => {
  const step = useSelector(stepSelector);
  const setStep = useAction(customersAction.setStep);
  const brands = (model?.wine_club_members ?? []).map(item => ({
    id: item?.brand_id || item?.brand?.id,
    title: item?.brand_title || item?.brand?.title,
  }));

  useEffect(() => {
    return () => {
      setStep(CUSTOMER_FORM_STEPS.MAIN);
    };
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink
                className="font-weight-bold p-3"
                active={step === CUSTOMER_FORM_STEPS.MAIN}
                onClick={() => setStep(CUSTOMER_FORM_STEPS.MAIN)}
                disabled={needFormLock && !!model}
              >
                {CUSTOMER_FORM_STEPS.MAIN}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="font-weight-bold p-3"
                active={step === CUSTOMER_FORM_STEPS.PAYMENT}
                onClick={() => setStep(CUSTOMER_FORM_STEPS.PAYMENT)}
                disabled={!model}
              >
                {CUSTOMER_FORM_STEPS.PAYMENT}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {step === CUSTOMER_FORM_STEPS.MAIN ? (
            <MainForm
              buttonTitle={buttonTitle}
              onSubmit={onSubmit}
              model={{
                ...model,
                billing_phone: model?.customer_billing_data?.phone,
              }}
            />
          ) : (
            <PaymentForm
              brands={brands}
              currentCustomerId={model?.id}
              finishButton={
                needFormLock ? 'Finish Registration' : 'Finish Editing'
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};

CustomerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  model: PropTypes.object,
  buttonTitle: PropTypes.string.isRequired,
  needFormLock: PropTypes.bool,
};

export default CustomerForm;
