import cx from 'classnames';
import useAction from 'hooks/useAction';
import { selectedCameraSelector } from 'models/footTraffic/selectors';
import { actions as footTrafficActions } from 'models/footTraffic/slice';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import { CAMERA_DIRECTIONS } from '../../pages/FootTraffic/constants';
import {
  validateEmail,
  validateTimeFrame,
  validateURL,
} from '../../utils/validations';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import Toggle from '../Toggle';
import styles from './CameraForm.scss';

const alignModalToObject = (modalObject, targetObject, toRight) => {
  const objectRectangle = modalObject.getBoundingClientRect();
  const targetRectangle = document
    .getElementById(targetObject)
    .getBoundingClientRect();

  let diffX = 0;

  if (toRight) {
    diffX = targetRectangle.x + targetRectangle.width - objectRectangle.x - 4;
  } else {
    diffX =
      targetRectangle.x +
      targetRectangle.width -
      objectRectangle.width -
      objectRectangle.x;
  }

  modalObject.style.transform = `translate(${diffX}px, 0px)`;
  modalObject.style.marginTop = `${targetRectangle.y -
    objectRectangle.y -
    40}px`;
};

const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, '');
};

const CameraForm = ({ cameraId, onClose, onSave, onDelete }) => {
  const fetchCamera = useAction(footTrafficActions.fetchCamera);
  const cameraInfo = useSelector(selectedCameraSelector);

  const [cameraFormState, setCameraFormState] = useState({
    direction: 'up',
    isActive: true,
    url: '',
    email: '',
    name: '',
    notificationsEnabled: false,
    brandId: null,
    warehouseId: null,
    workingHoursDate: null,
    workingHoursStartTime: '07:00 a.m.',
    workingHoursEndTime: '07:00 p.m.',
    workingHoursFrequency: null,
    workingHoursAlwaysOn: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();

  const handleOnOpened = () => {
    if (cameraId) {
      alignModalToObject(
        ref.current.parentNode,
        `camera-tab-${cameraId}`,
        true
      );
    } else {
      alignModalToObject(ref.current.parentNode, 'add-new-camera-button');
    }
  };

  useEffect(() => {
    if (cameraId !== null) {
      setIsLoading(true);
      fetchCamera({ id: cameraId });
    }
  }, [cameraId]);

  useEffect(() => {
    if (!cameraInfo) {
      return;
    }

    const {
      direction,
      isActive,
      name,
      id,
      url,
      email,
      brand_id,
      brand_title,
      warehouse_id,
      warehouse_title,
      notificationsEnabled,
      workingHoursDate,
      workingHoursStartTime,
      workingHoursEndTime,
      workingHoursFrequency,
      workingHoursAlwaysOn,
    } = cameraInfo;
    setCameraFormState({
      direction,
      id,
      isActive,
      url,
      name,
      email,
      brandId: brand_id,
      brandName: brand_title,
      warehouseId: warehouse_id,
      warehouseName: warehouse_title,
      notificationsEnabled,
      workingHoursDate,
      workingHoursStartTime: workingHoursStartTime || '07:00 a.m.',
      workingHoursEndTime: workingHoursEndTime || '07:00 p.m.',
      workingHoursFrequency,
      workingHoursAlwaysOn,
    });

    setIsLoading(false);
  }, [cameraInfo]);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};
    if (cameraFormState.name === '') {
      newErrors = {
        ...newErrors,
        name: true,
      };
    }
    if (!validateURL(cameraFormState.url)) {
      newErrors = {
        ...newErrors,
        url: true,
      };
    }

    if (!cameraFormState.brandId) {
      newErrors = {
        ...newErrors,
        brand: true,
      };
    }

    if (!cameraFormState.warehouseId) {
      newErrors = {
        ...newErrors,
        warehouse: true,
      };
    }

    if (
      cameraFormState.notificationsEnabled &&
      !validateEmail(cameraFormState.email)
    ) {
      newErrors = {
        ...newErrors,
        email: true,
      };
    }
    if (
      !validateTimeFrame(
        cameraFormState.workingHoursStartTime,
        cameraFormState.workingHoursEndTime
      )
    ) {
      newErrors = {
        ...newErrors,
        times: true,
      };
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setIsLoading(true);
    onSave(cameraFormState);
    onClose();
  };

  return (
    <Modal
      isOpen
      toggle={onClose}
      contentClassName={cx(styles.cameraFormModal)}
      onOpened={handleOnOpened}
      backdrop
      keyboard={!isLoading}
    >
      <div
        className={cx('pt-4 pb-4 pl-4 pr-4', styles.modalContent, {
          [styles.disabledButton]: isLoading,
        })}
        key={`modal-${cameraFormState.id}`}
        ref={ref}
      >
        <div className={styles.topLine}>
          <div className={styles.cameraFormHeader}>
            <Toggle
              checkedLabel="Active"
              name="cameraActive"
              uncheckedLabel="Disabled"
              value={cameraFormState.isActive}
              disabled={isLoading}
              onChange={value => {
                setCameraFormState({
                  ...cameraFormState,
                  isActive: value,
                });
              }}
            />
            <h3 className={styles.title}>
              {cameraId ? 'Camera Settings' : 'Add a Camera'}
            </h3>
            <div className={styles.direction}>
              Direction: <span>{camelize(cameraFormState.direction)}</span>
              <select
                id="camera-direction"
                name="camera-direction"
                value={cameraFormState.direction}
                onChange={event => {
                  setCameraFormState({
                    ...cameraFormState,
                    direction: event.target.value,
                  });
                }}
              >
                {CAMERA_DIRECTIONS.map(currentDirection => (
                  <option key={currentDirection} value={currentDirection}>
                    {camelize(currentDirection)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <input
          type="text"
          name="cameraName"
          placeholder="Set camera name"
          className={cx(styles.textInput, { [styles.inputError]: errors.name })}
          value={cameraFormState.name}
          onChange={event =>
            setCameraFormState({ ...cameraFormState, name: event.target.value })
          }
        />
        <input
          type="text"
          name="cameraURL"
          placeholder="Enter camera URL"
          className={cx(styles.textInput, { [styles.inputError]: errors.url })}
          value={cameraFormState.url}
          onChange={event =>
            setCameraFormState({ ...cameraFormState, url: event.target.value })
          }
        />

        <BrandsPaginatedField
          isMulti={false}
          className={cx(styles.customSelect, {
            [styles.inputError]: errors.brand,
          })}
          defaultValue={
            cameraFormState.brandId && {
              id: cameraFormState.brandId,
              value: cameraFormState.brandId,
              label: cameraFormState.brandName,
            }
          }
          onChange={event =>
            setCameraFormState({
              ...cameraFormState,
              brandId: event.id,
              warehouseId: null,
            })
          }
        />
        <WarehousesPaginatedField
          isMulti={false}
          defaultValue={
            cameraFormState.warehouseId
              ? {
                  id: cameraFormState.warehouseId,
                  value: cameraFormState.warehouseId,
                  label: cameraFormState.warehouseName,
                }
              : {}
          }
          isDisabled={!cameraFormState.brandId}
          classNames={cx(styles.customSelect, {
            [styles.inputError]: errors.warehouse,
          })}
          disabled={!cameraFormState.brandId}
          parameters={{
            brand_id: cameraFormState.brandId,
          }}
          onChange={event => {
            setCameraFormState({
              ...cameraFormState,
              warehouseId: event.id,
            });
          }}
        />

        {/* <EmailNotificationForm
          email={cameraFormState.email}
          emailError={errors.email}
          notificationsEnabled={cameraFormState.notificationsEnabled}
          onChange={({ email, notificationsEnabled }) => {
            setCameraFormState({
              ...cameraFormState,
              email,
              notificationsEnabled,
            });
          }}
        />
        <WorkingHoursForm
          date={
            cameraFormState.workingHoursDate
              ? new Date(cameraFormState.workingHoursDate)
              : new Date()
          }
          frequency={
            cameraFormState.workingHoursFrequency
              ? cameraFormState.workingHoursFrequency
              : FREQUENCIES[0].value
          }
          alwaysOn={cameraFormState.alwaysOn || false}
          startHour={cameraFormState.workingHoursStartTime || '07:00 a.m.'}
          endHour={cameraFormState.workingHoursEndTime || '07:00 p.m.'}
          onChange={value => {
            setCameraFormState({
              ...cameraFormState,
              workingHoursDate: value.date,
              workingHoursAlwaysOn: value.alwaysOn,
              workingHoursStartTime: value.startHour,
              workingHoursEndTime: value.endHour,
              workingHoursFrequency: value.frequency,
            });
          }}
          timeErrors={errors.times}
        /> */}

        <div className={styles.cameraFormFooter}>
          {cameraId && (
            <button
              className={cx(styles.cameraFormDelete, {
                [styles.disabledButton]: isLoading,
              })}
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true);
                onDelete(cameraId);
                onClose();
              }}
            >
              <i className="ri-delete-bin-2-fill" />
              <span>Delete camera</span>
            </button>
          )}
          {!cameraId && (
            <button
              className={cx(styles.cameraFormDelete, {
                [styles.disabledButton]: isLoading,
              })}
              disabled={isLoading}
              onClick={() => {
                onClose();
              }}
            >
              <i className="ri-close-fill" />
              <span>Cancel</span>
            </button>
          )}
          <Button color="primary" onClick={validate} disabled={isLoading}>
            Save Settings
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CameraForm.propTypes = {
  cameraId: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CameraForm;
