import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { push } from 'connected-react-router';

export function* fetchUsersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/users/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchUserSaga(action) {
  yield api({
    action,
    method: 'GET',
    url: `/users/${action.payload}`,
  });
}

// random cute login messages and wine puns
const loginMessages = [
  'After all those tries, you finally got it! 🎉',
  'Grape, you did it! 🍇',
  'Time to crush those sales and sip on success. 🍷',
  "Welcome back! We've missed you a bunch. 🍇",
  "Glad you're back! We missed your bubbly personality. 🍾",
  'Happy to see you back in our vineyard! 🍇',
  'How can we help you cultivate success today? 🌱',
  "Let's make this a fruitful day! 🍓",
  'Welcome back. Ready to crush it? 💪',
  "You're in! What's fermenting today? 🤔",
];

export function* loginSaga(action) {
  const message =
    loginMessages[Math.floor(Math.random() * loginMessages.length)];
  yield api({
    action,
    method: 'post',
    url: '/auth/token/login/',
    data: action.payload,
    successMessage: `Logged in. ${message}`,
    isRequestWithoutToken: true,
  });
}

export function* getCurrentUserSaga(action) {
  yield api({
    action,
    method: 'get',
    url: '/users/current',
    data: action.payload,
    failureNavigateTo: '/login',
  });
}

export function* grantManagerPrivilegesSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/users/${action.payload.id}/grant_manager_privileges`,
    data: action.payload,
    failureNavigateTo: '/users/',
    successMessage: 'Successfully granted managers privileges.',
  });
}

export function* denyManagerPrivilegesSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/users/${action.payload.id}/deny_manager_privileges`,
    data: action.payload,
    failureNavigateTo: '/users/',
    successMessage: 'Successfully denied managers privileges.',
  });
}

function* recoverPasswordSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: '/users/recover_password',
    data: action.payload,
    showResponseError: false,
  });
}

function* resetPasswordSaga(action) {
  yield api({
    action,
    method: 'PATCH',
    url: '/users/reset_password',
    data: action.payload,
  });
}

function* resetPasswordSuccessSaga({ payload }) {
  const { data } = payload;
  if (!data?.auth_token) {
    yield put(push('/login'));
  }
}

function* createUserSaga(action) {
  yield api({
    action,
    url: '/users/',
    data: action.payload,
    method: 'POST',
    successNavigateTo: '/users',
  });
}

function* updateUserSaga(action) {
  const { payload } = action;
  yield api({
    action,
    url: `/users/${payload.id}`,
    data: payload,
    method: 'PATCH',
    successNavigateTo: '/users',
  });
}

function* registerSaga(action) {
  yield api({
    action,
    url: '/users/register',
    data: action.payload,
    method: 'POST',
    successNavigateTo: '/login?customer=1',
  });
}

function* checkConfirmationTokenSaga(action) {
  yield api({
    action,
    url: `/users/confirmation/${action.payload}/check`,
    method: 'GET',
  });
}

function* sendConfirmationEmailSaga(action) {
  yield api({
    action,
    url: `/users/confirmation/${action.payload}/send_email`,
    method: 'POST',
    successMessage: 'Mail successfully sent.',
  });
}

function* confirmUserSaga(action) {
  const { payload } = action;

  yield api({
    action,
    url: `/users/confirmation/${payload.token}`,
    method: 'POST',
    data: payload,
  });
}

function* generatePinSaga(action) {
  yield api({
    action,
    url: `/users/generate_pin`,
    method: 'GET',
  });
}

function* requestPaymentInformationSaga(action) {
  const { payload } = action;
  yield api({
    action,
    url: `/users/request_payment_info`,
    params: payload,
    method: 'GET',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.login, loginSaga),
    takeLatest(actions.register, registerSaga),
    takeLatest(actions.fetchUsers, fetchUsersSaga),
    takeLatest(actions.fetchUser, fetchUserSaga),
    takeLatest(actions.getCurrentUser, getCurrentUserSaga),
    takeLatest(actions.grantManagerPrivileges, grantManagerPrivilegesSaga),
    takeLatest(actions.denyManagerPrivileges, denyManagerPrivilegesSaga),
    takeLatest(actions.recoverPassword, recoverPasswordSaga),
    takeLatest(actions.resetPassword, resetPasswordSaga),
    takeLatest(actions.resetPasswordSuccess, resetPasswordSuccessSaga),
    takeLatest(actions.createUser, createUserSaga),
    takeLatest(actions.updateUser, updateUserSaga),
    takeLatest(actions.checkConfirmationToken, checkConfirmationTokenSaga),
    takeLatest(actions.confirmUser, confirmUserSaga),
    takeLatest(actions.sendConfirmationEmail, sendConfirmationEmailSaga),
    takeLatest(actions.generatePin, generatePinSaga),
    takeLatest(
      actions.requestPaymentInformation,
      requestPaymentInformationSaga
    ),
  ]);
}
