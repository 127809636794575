import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import Select from 'react-select';
import styles from './ReportFilters.scss';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';
import useQuery from 'hooks/useQuery';
import WarehousesPaginatedField from '../PaginatedFields/WarehousesPaginatedField';
import AssociatesPaginatedField from '../PaginatedFields/AssociatesPaginatedField';
import { ORDER_TYPES_OPTIONS } from 'src/constants';

// Utils
const getQuery = (ids, labels, emails = '') => {
  const arrTitle = labels?.split(',');
  const arrEmails = emails?.split(',');

  if (!ids) {
    return [];
  }

  return ids?.split(',')?.map((id, index) => ({
    value: Number(id),
    label: arrTitle?.[index],
    contact_email: arrEmails?.[index],
  }));
};

const getQueryTypes = (orderTypesList, types) => {
  const _types = types?.split(',');
  return orderTypesList?.filter(type => _types?.includes(type?.value));
};

const ReportFilters = ({
  showBrand,
  showOrderType,
  showSKU,
  setFilters,
  showWarehouse,
  showAssociate,
  isMultiBrand,
  isMultiWarehouse,
  isMultiAssociate,
  isInitialLabelBrand,
  orderTypes,
  orderTypesOptions,
  isMultiOrderType,
}) => {
  // Queries
  const query = useQuery();
  const orderTypesList = orderTypes || orderTypesOptions || ORDER_TYPES_OPTIONS;
  const initialBrandLabel = getQuery(
    query.get('brand_ids'),
    query.get('brand_titles'),
    query.get('brand_emails')
  );

  // States
  const [selectedBrands, setSelectedBrands] = useState(
    !initialBrandLabel?.[0]?.value && isInitialLabelBrand
      ? { value: undefined, label: 'All brands' }
      : initialBrandLabel
  );
  const [selectedOrderTypes, setSelectedOrderTypes] = useState(
    getQueryTypes(orderTypesList, query.get('order_types'))
  );
  const [selectedProducts, setSelectedProducts] = useState(
    getQuery(query.get('product_ids'), query.get('product_labels'))
  );
  const [selectedAssociate, setSelectedAssociate] = useState(
    isMultiAssociate
      ? []
      : {
          value: undefined,
          label: 'All associates',
        }
  );
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);

  // Effects
  useEffect(() => {
    if (!setFilters) {
      return;
    }
    setFilters({
      selectedBrands,
      selectedOrderTypes,
      selectedProducts,
      selectedWarehouses,
      selectedAssociate,
    });
  }, [
    selectedBrands,
    selectedOrderTypes,
    selectedProducts,
    selectedWarehouses,
    selectedAssociate,
  ]);

  return (
    <Row>
      {showBrand && (
        <Col xs="4">
          <Card>
            <CardBody>
              <p className={styles.selectTitle}>Brand</p>
              <BrandsPaginatedField
                className={styles.selectRoot}
                onChange={setSelectedBrands}
                defaultValue={selectedBrands}
                isMulti={!!isMultiBrand}
                isInitialLabel={isInitialLabelBrand}
                withShortOptions
              />
            </CardBody>
          </Card>
        </Col>
      )}
      {showOrderType && (
        <Col md={4}>
          <Card>
            <CardBody>
              <p className={styles.selectTitle}>Order Type</p>
              <Select
                className={styles.selectRoot}
                options={orderTypesList}
                isMulti={!!isMultiOrderType}
                name="order_types"
                placeholder="Select an Order Type"
                defaultValue={selectedOrderTypes}
                onChange={items => setSelectedOrderTypes(items)}
              />
            </CardBody>
          </Card>
        </Col>
      )}
      {showSKU && (
        <Col md={4}>
          <Card>
            <CardBody>
              <p className={styles.selectTitle}>SKU</p>
              <ProductsPaginatedField
                id="wine"
                value={selectedProducts}
                onChange={setSelectedProducts}
              />
            </CardBody>
          </Card>
        </Col>
      )}
      {showWarehouse && (
        <Col md={4}>
          <Card>
            <CardBody>
              <p className={styles.selectTitle}>Warehouse</p>
              <WarehousesPaginatedField
                onChange={setSelectedWarehouses}
                isMulti={!!isMultiWarehouse}
              />
            </CardBody>
          </Card>
        </Col>
      )}
      {showAssociate && (
        <Col md={4}>
          <Card>
            <CardBody>
              <p className={styles.selectTitle}>Associate</p>
              <AssociatesPaginatedField
                onChange={setSelectedAssociate}
                isMulti={!!isMultiAssociate}
                value={selectedAssociate}
                isInitialLabel={!isMultiAssociate}
              />
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

ReportFilters.propTypes = {
  isMultiBrand: PropTypes.bool,
  isMultiWarehouse: PropTypes.bool,
  isMultiAssociate: PropTypes.bool,
  showBrand: PropTypes.bool,
  showOrderType: PropTypes.bool,
  showSKU: PropTypes.bool,
  showWarehouse: PropTypes.bool,
  showAssociate: PropTypes.bool,
  setFilters: PropTypes.func,
  isInitialLabelBrand: PropTypes.bool,
  orderTypes: PropTypes.array,
  orderTypesOptions: PropTypes.array,
  isMultiOrderType: PropTypes.bool,
};

export default ReportFilters;
