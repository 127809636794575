import React, { useState, useEffect } from 'react';
import { actions as userActions } from 'models/user/slice';
import LoadingOverlay from 'react-loading-overlay';
import useAction from 'hooks/useAction';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const logout = useAction(userActions.logout);

  useEffect(() => {
    const handleLogout = async () => {
      await logout();
      window.location.href = '/login';
      setLoading(false);
    };

    handleLogout();
  });

  return <LoadingOverlay active={loading} spinner text="Logging out..." />;
};

export default Logout;
