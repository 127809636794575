import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchTablesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/tables/',
    setQuery: action.payload.set_query,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
export function* fetchTableSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/tables/${action.payload}`,
  });
}

export function* createTableSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/tables/`,
    data: action.payload,
    successMessage: 'Successfully created new table!',
    successNavigateTo: `/tables/`,
  });
}

export function* updateTableSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/tables/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated table!',
    successNavigateTo: `/tables/`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchTables, fetchTablesSaga),
    takeLatest(actions.fetchTable, fetchTableSaga),
    takeLatest(actions.createTable, createTableSaga),
    takeLatest(actions.updateTable, updateTableSaga),
  ]);
}
