import React, { useCallback, useEffect, useMemo } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import {
  actions,
  actions as emailTemplatesActions,
} from 'models/emailTemplates/slice';
import { actions as wineclubsActions } from 'models/wineclubs/slice';
import { Col, Row } from 'reactstrap';
import EmailTemplateForm from 'components/EmailTemplateForm';
import useSelector from 'hooks/useSelector';
import {
  currentEmailTemplateSelector,
  previewLayoutModalSelector,
  previewLayoutPendingSelector,
  previewLayoutSelector,
} from 'models/emailTemplates/selectors';
import { wineclubSelector } from 'models/wineclubs/selectors';
import {
  EMAIL_FIELD_TYPES,
  emailTemplateTypeName,
} from 'constants/emailTemplates';
import { getNormalText } from 'utils/getNormalText';
import { isEmpty } from 'lodash';
import { getRedactedValues } from 'utils/getRedactedValues';
import EmailTemplatePopup from 'components/Popup/EmailTemplatePopup/EmailTemplatePopup';

const getPreparedEmailTemplate = (template, wineClub) => {
  if (!template || isEmpty(wineClub)) {
    return null;
  }
  const { email_template_id, email_template_title, title } = template;
  return {
    ...template.data,
    title,
    wineClub: {
      label: wineClub.title,
      value: wineClub.id,
      brand_id: wineClub.brand.id,
    },
    emailTemplate: {
      value: email_template_id,
      data: template.data,
      label:
        emailTemplateTypeName?.[email_template_title] ??
        getNormalText(email_template_title),
    },
  };
};

const getPreparedDataForUpdate = (defaultValues, values) => {
  const redactedValues = getRedactedValues(defaultValues, values);
  return Object.entries(redactedValues).reduce((previous, current) => {
    const [key, value] = current;
    if (key.split('_')[0] === EMAIL_FIELD_TYPES.LOGO && value == null) {
      return {
        ...previous,
        [`delete_${key}`]: true,
      };
    }
    return { ...previous, [key]: value };
  }, {});
};

const EditEmailTemplate = ({ title }) => {
  const { templateId, wineClubId } = useParams();
  const fetchEmailTemplateById = useAction(
    emailTemplatesActions.fetchEmailTemplateById
  );
  const updateEmailTemplate = useAction(
    emailTemplatesActions.updateEmailTemplate
  );
  const fetchWineClub = useAction(wineclubsActions.fetchWineclub);
  const setPreviewLayoutModal = useAction(actions.setPreviewLayoutModal);
  const previewLayout = useSelector(previewLayoutSelector);
  const previewLayoutModal = useSelector(previewLayoutModalSelector);
  const previewLayoutPending = useSelector(previewLayoutPendingSelector);
  const currentEmailTemplate = useSelector(currentEmailTemplateSelector);
  const wineClub = useSelector(wineclubSelector);

  const preparedEmailTemplateData = useMemo(
    () => getPreparedEmailTemplate(currentEmailTemplate, wineClub),
    [currentEmailTemplate, wineClub]
  );

  useEffect(() => {
    fetchEmailTemplateById({ templateId, wineClubId });
  }, []);

  useEffect(() => {
    fetchWineClub(wineClubId);
  }, []);

  const onSubmitDataHandler = useCallback(
    values => {
      const data = getPreparedDataForUpdate(preparedEmailTemplateData, values);
      updateEmailTemplate({ data, templateId, wineClubId });
    },
    [preparedEmailTemplateData, templateId, wineClubId]
  );

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Email Template"
        breadcrumbItems={[
          {
            title: 'Back to email templates',
            link: `/email-templates/`,
          },
        ]}
      />
      {previewLayoutModal && (
        <EmailTemplatePopup
          onClose={() => setPreviewLayoutModal(false)}
          layout={previewLayout?.data}
          loading={previewLayoutPending}
        />
      )}
      {preparedEmailTemplateData && (
        <Row>
          <Col md={12}>
            <EmailTemplateForm
              defaultValues={preparedEmailTemplateData}
              onSubmit={onSubmitDataHandler}
              onPreviewClick={values =>
                onSubmitDataHandler({ ...values, preview: true })
              }
            />
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
};

EditEmailTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};
export default EditEmailTemplate;
