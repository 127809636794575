import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useAction from 'hooks/useAction';
import { Row, Col, Label, Button, Alert } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PreloadedBrandsField from 'components/PaginatedFields/PreloadedBrandsField';
import Payment from 'components/Payment';
import { actions as customersActions } from 'models/customers/slice';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
} from 'models/customers/selectors';
import { actions as sharedCustomerActions } from 'models/sharedCustomer/slice';
import { sharedCustomerSelector } from 'models/sharedCustomer/selectors';
import { emailSelector } from 'models/user/selectors';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import PaymentDropdown from 'components/PaymentDropdown';
import { allBrandsSelector } from 'models/brands/selectors';

const PaymentMethodsSummary = ({ title }) => {
  // Selectors
  const currentUserEmail = useSelector(emailSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const sharedCustomer = useSelector(sharedCustomerSelector);
  const allBrands = useSelector(allBrandsSelector);

  // Actions
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const fetchSharedCustomer = useAction(
    sharedCustomerActions.fetchSharedCustomer
  );
  const makeDefaultPaymentMethod = useAction(
    customersActions.makeDefaultPaymentMethod
  );
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);

  // States
  const [currentWinery, setCurrentWinery] = useState({});
  const [isPaymentFormOpen, setPaymentFormOpen] = useState(false);
  const [cardOptions, setCardOptions] = useState([]);
  const [isPaymentVisible, setIsPaymentVisible] = useState(false);

  // Effects
  useEffect(() => {
    if (currentUserEmail) {
      fetchSharedCustomer({ current_email: currentUserEmail });
    }
  }, [fetchSharedCustomer, currentUserEmail]);

  useEffect(() => {
    if (sharedCustomer?.contact_person?.id) {
      fetchPaymentTypes(sharedCustomer.contact_person.id);
    }
  }, [fetchPaymentTypes, sharedCustomer]);

  useEffect(() => {
    // Determine if the payment is visible based on the currentWinery
    if (
      currentWinery?.active_stripe_acquirer?.public_key &&
      sharedCustomer?.contact_person?.id
    ) {
      setIsPaymentVisible(true);
    } else {
      setIsPaymentVisible(false);
    }
  }, [currentWinery, sharedCustomer]);

  // Handlers
  const handleWineryChange = selectedOption => {
    const fullWinery = allBrands.find(
      brand => brand.id === selectedOption.value
    );
    setCurrentWinery(fullWinery || {});
  };

  const onAddCardHandler = () => {
    setPaymentFormOpen(true);
  };

  const onPaymentSuccessHandler = () => {
    if (sharedCustomer?.contact_person?.id) {
      fetchPaymentTypes(sharedCustomer.contact_person.id);
    }
    setPaymentFormOpen(false);
  };

  const onPaymentMakeDefaultHandler = methodId => {
    if (sharedCustomer?.contact_person?.id) {
      const customerId = sharedCustomer.contact_person.id;
      makeDefaultPaymentMethod({ id: methodId, customerId });
    }
  };

  const onPaymentRemoveHandler = methodId => {
    if (sharedCustomer?.contact_person?.id) {
      const customerId = sharedCustomer.contact_person.id;
      removePaymentMethod({ id: methodId, customerId });
    }
  };

  // Variables
  const paymentTypesData = paymentTypes.filter(
    type => type?.brand_id === currentWinery?.id
  );

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row className="d-flex align-items-end mb-2">
        <Col md={4}>
          <Label htmlFor="winery">Select Winery</Label>
          <PreloadedBrandsField
            value={
              currentWinery
                ? { value: currentWinery.id, label: currentWinery.title }
                : null
            }
            onChange={handleWineryChange}
          />
        </Col>
        {currentWinery?.id && isPaymentVisible && (
          <Col className="d-flex justify-content-end">
            <Button onClick={onAddCardHandler} color="success">
              <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
              Add Card
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12} className="mt-2">
          {isPaymentVisible ? (
            <Payment
              stripeToken={currentWinery?.active_stripe_acquirer.public_key}
              currentCustomerId={sharedCustomer?.contact_person?.id}
              currentBrand={currentWinery}
              cardOptions={cardOptions}
              setCardOptions={setCardOptions}
              isFormHidden={!isPaymentFormOpen}
              onSuccess={onPaymentSuccessHandler}
              isSharedCustomer
            />
          ) : (
            <Alert color="warning">
              <i className="mdi mdi-information" />
              <b>Payment methods are not available</b>
              <br />
              {`There are no payment methods available for this Winery. Please contact ${currentWinery?.title} for support.`}
            </Alert>
          )}
        </Col>
      </Row>

      <Row>
        {paymentTypesData?.length > 0 && (
          <Col md={12}>
            <PaymentMethodsTable
              title="Credit Cards"
              paymentMethods={paymentMethods.filter(
                method => method?.brand_id === currentWinery?.id
              )}
              actionNode={id => (
                <PaymentDropdown
                  onMakeDefault={() => onPaymentMakeDefaultHandler(id)}
                  onDelete={() => onPaymentRemoveHandler(id)}
                />
              )}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  );
};

PaymentMethodsSummary.propTypes = {
  title: PropTypes.string,
};

export default PaymentMethodsSummary;
