export const EMAIL_TEMPLATES = {
  core_api_email_confirmation: 'Email Confirmation',
  core_api_email_confirmation_host: 'Email Confirmation (Host)',
  core_api_email_confirmation_manager: 'Email Confirmation (Manager)',
  core_api_email_confirmation_staff: 'Email Confirmation (Staff)',
  core_api_email_confirmation_therapy: 'Email Confirmation (Therapy)',
  core_api_notify_brand_owner: 'Notify Brand Owner',
  core_api_pick_up_order: 'Pickup Order',
  core_api_shipped_order: 'Shipped Order',
  core_api_subscription_order_approval_email: 'Order Approval (Subscription)',
  core_api_subscription_order_change_due_date: 'Change Due Date (Subscription)',
  core_api_wine_selection_notification: 'Wine Selection Notification',
  core_api_refund: 'Refund',
  'v1/refund': 'Refund',
  customer_api_customer_recover_password: 'Customer Recover Password',
  customer_api_notify_managers_about_join: 'Notify Manager About Join',
  customer_api_register_email_confirmation: 'Register Email Confirmation',
  customer_api_subscription_order_approval_email:
    'Order Approval (Subscription)',
  customer_api_subscription_order_change_due_date:
    'Change Due Date (Subscription)',
  customer_api_shipped_order: 'Shipped Order (customer)',
  customer_api_pick_up_order: 'Pickup Order (customer)',
  ecommerce_api_pick_up_order: 'Pickup Order (ecommerce)',
  ecommerce_api_register_email_confirmation:
    'Register Email Confirmation (ecommerce)',
  ecommerce_api_shipped_order: 'Shipped Order (ecommerce)',
  email_confirmation: 'Email Confirmation',
  email_confirmation_host: 'Email Confirmation (Host)',
  email_confirmation_user: 'Email Confirmation (User)',
  core_api_email_confirmation_winedirect: 'Email Confirmation (Imported)',
  email_order_bill: 'Email Order Bill',
  email_verification: 'Email Verification',
  guest_api_email_confirmation: 'Email Confirmation (Guest)',
  guest_api_email_verification: 'Email Verification (Guest)',
  image_clearable_file_input: 'Image Clearable File Input',
  recover_password: 'Recover Password',
  register_email_confirmation: 'Register Email Confirmation',
  therapy_api_pick_up_order: 'Pickup Order (Therapy)',
  therapy_api_shipped_order: 'Shipped Order (Therapy)',
  therapy_register_email_confirmation: 'Register Email Confirmation (Therapy)',
};

export const EMAIL_TEMPLATE_TYPE = Object.freeze({
  EMAIL_CONFIRMATION: 'core_api_email_confirmation',
  WINE_SELECTION_NOTIFICATION: 'core_api_wine_selection_notification',
  EMAIL_CONFIRMATION_WINEDIRECT: 'core_api_email_confirmation_winedirect',
  RECOVER_PASSWORD: 'customer_api_customer_recover_password',
});

export const emailTemplateTypeName = Object.freeze({
  [EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION]: 'Invite',
  [EMAIL_TEMPLATE_TYPE.WINE_SELECTION_NOTIFICATION]: 'Wine selection',
  [EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION_WINEDIRECT]:
    'Invite imported customer',
  [EMAIL_TEMPLATE_TYPE.RECOVER_PASSWORD]: 'Recover Password',
});

export const defaultEmailTypesOptions = [
  {
    value: EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION,
    label: emailTemplateTypeName[EMAIL_TEMPLATE_TYPE.EMAIL_CONFIRMATION],
  },
  {
    value: EMAIL_TEMPLATE_TYPE.WINE_SELECTION_NOTIFICATION,
    label:
      emailTemplateTypeName[EMAIL_TEMPLATE_TYPE.WINE_SELECTION_NOTIFICATION],
  },
  {
    value: EMAIL_TEMPLATE_TYPE.RECOVER_PASSWORD,
    label: emailTemplateTypeName[EMAIL_TEMPLATE_TYPE.RECOVER_PASSWORD],
  },
];

export const EMAIL_FIELD_TYPES = Object.freeze({
  LOGO: 'logo',
  TEXT: 'text',
  INPUT: 'input',
});

export const EMAIL_CATEGORIES = Object.freeze({
  TEMPLATE: 'Template',
  CUSTOM: 'Custom',
});
