import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { actions as userActions } from 'models/user/slice';

export function* fetchSharedCustomerSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/shared_customer/`,
    data: action.payload,
  });
}

export function* updateSharedCustomerSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/shared_customer/`,
    data: action.payload,
    successMessage: 'Successfully updated shared customer!',
    failureMessage: 'An error occurred while updating shared customer.',
  });
}

export function* updateSharedCustomerSuccessSaga(action) {
  const { payload } = action;
  if (payload.data.contact_person && payload.data.contact_person.email) {
    yield put({
      type: userActions.getCurrentUser,
    });
  }
}

export default function*() {
  yield all([
    takeLatest(actions.fetchSharedCustomer, fetchSharedCustomerSaga),
    takeLatest(actions.updateSharedCustomer, updateSharedCustomerSaga),
    takeLatest(
      actions.updateSharedCustomerSuccess,
      updateSharedCustomerSuccessSaga
    ),
  ]);
}
