import React, { memo } from 'react';
import TextField from '../TextField';
import PropTypes from 'prop-types';

const LinkError = 'This field is invalid!';

export const isLinkValid = value => {
  const linkPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return linkPattern.test(value);
};

const LinkField = ({ name, label, isRequired, ...props }) => {
  const validateLink = (value, context, input, cb) => {
    if ((!value && isRequired) || (value && !isLinkValid(value))) {
      cb(false);
      return;
    }
    cb(true);
  };

  return (
    <TextField
      name={name || 'link'}
      label={label || 'Link'}
      placeholder="Enter Link"
      errorMessage={LinkError}
      rules={{ custom: validateLink }}
      {...props}
    />
  );
};

LinkField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};
export default memo(LinkField);
