import React, { memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FieldType } from '../types.d';
import classNames from 'classnames';

const TextField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  value,
  errorMessage,
  disabled,
  className,
  icon,
  fieldClassName,
  ...props
}) => {
  return (
    <FormGroup className={className}>
      {!!icon && icon}
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <AvField
        name={name}
        type="text"
        className={classNames('form-control', fieldClassName)}
        validate={{
          required: { value: !!isRequired },
          ...rules,
        }}
        error
        errorMessage={errorMessage}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
    </FormGroup>
  );
};

TextField.propTypes = FieldType;

export default memo(TextField);
