import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';
import * as queryString from 'query-string';

const TastingRoomsPaginatedField = ({
  onChange,
  isMulti,
  parameters,
  defaultValue,
  onBlur,
  error,
  classNames,
  isInitialLabel,
  warehouseId,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchTastingRooms = ({ page = 1 }) => {
    const queryParameters = new URLSearchParams(parameters);
    const stringified = queryString.stringify({
      page,
      warehouse_id: warehouseId,
    });

    return apiRequest({
      url: `/tasting_rooms/?${stringified}&${queryParameters}`,
    });
  };

  const loadRooms = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchTastingRooms({ page });

      const options = response?.results.map(item => ({
        value: item.id,
        label: item.title,
        ...item,
      }));

      return {
        options: isInitialLabel
          ? [
              {
                label: 'All rooms',
                value: undefined,
              },
              ...options,
            ]
          : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <>
      <AsyncPaginate
        {...props}
        name="tastingrooms"
        cacheUniqs={[warehouseId]}
        isSearchable={false}
        additional={{
          page: 1,
        }}
        defaultValue={defaultValue}
        loadOptions={loadRooms}
        onChange={onChange}
        isMulti={isMulti}
        onBlur={onBlur}
        styles={selectStyles}
        placeholder="Select room"
        className={classNames}
      />
      {!!error && <small className="text-danger">{error}</small>}
    </>
  );
};

TastingRoomsPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  parameters: PropTypes.object,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  classNames: PropTypes.string,
  isInitialLabel: PropTypes.bool,
  warehouseId: PropTypes.number,
  option: PropTypes.any,
};

export default TastingRoomsPaginatedField;
