import React, { useCallback, useState } from 'react';
import ReportsLayout from '../../components/ReportsLayout';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions as reportsActions } from 'models/reports/slice';
import ReportFilters from '../../components/ReportFilters';

const ReportsInventoryTransfersSummary = ({ title }) => {
  const [dateRange, setDateRange] = useState();
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedWarehouse, setSelectedWarehouse] = useState({});

  const fetchInventoryTransfersXlsxRequest = useAction(
    reportsActions.fetchInventoryTransfersXlsx
  );

  const setFilters = filters => {
    setSelectedBrand(filters.selectedBrands);
    setSelectedWarehouse(filters.selectedWarehouses);
  };

  const onExport = useCallback(() => {
    const { startDate, endDate } = dateRange;
    fetchInventoryTransfersXlsxRequest({
      startDate,
      endDate,
      warehouseId: selectedWarehouse?.value,
      brandId: selectedBrand?.value,
    });
  }, [
    fetchInventoryTransfersXlsxRequest,
    dateRange,
    selectedBrand,
    selectedWarehouse,
  ]);

  return (
    <ReportsLayout
      title={title}
      onExport={onExport}
      onDateChange={setDateRange}
    >
      <ReportFilters showBrand showWarehouse setFilters={setFilters} />
    </ReportsLayout>
  );
};

ReportsInventoryTransfersSummary.propTypes = {
  title: PropTypes.string,
};
export default ReportsInventoryTransfersSummary;
