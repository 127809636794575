import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchDiscountsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/discount_codes/',
    setQuery: true,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchCodeSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/discount_codes/${action.payload}`,
  });
}

export function* createCodeSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/discount_codes/`,
    data: action.payload,
    successMessage: 'Successfully created new coupon code!',
    successNavigateTo: `/discount-codes/`,
  });
}

export function* updateCodeSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/discount_codes/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated coupon code!',
    successNavigateTo: `/discount-codes/`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchDiscounts, fetchDiscountsSaga),
    takeLatest(actions.fetchCode, fetchCodeSaga),
    takeLatest(actions.createCode, createCodeSaga),
    takeLatest(actions.updateCode, updateCodeSaga),
  ]);
}
