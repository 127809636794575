import React, { useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  Table as BTable,
  UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import styles from './PaymentMethodsTable.scss';

const PaymentMethodsTable = ({
  paymentMethods,
  title,
  onRemovePaymentMethod,
  actionNode,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1);

  const handleDelete = (e, id) => {
    e.preventDefault();
    setIdToDelete(id);
    setConfirmModal(true);
  };

  const removeCard = () => {
    setConfirmModal(false);
    onRemovePaymentMethod(idToDelete);
  };

  return (
    <Row className="mt-2">
      <Col md={12}>
        <Card>
          <CardBody>
            <h4 className="card-title mt-1 mb-3">{title}</h4>
            <BTable bordered>
              <thead>
                <tr>
                  <th data-priority="1">Card Brand</th>
                  <th data-priority="1">Card Number</th>
                  <th data-priority="1">Card Expiration Date</th>
                  <th data-priority="1">Brand</th>
                  <th data-priority="1">Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentMethods.map(method => (
                  <tr key={method.id}>
                    <td>
                      <span style={{ textTransform: 'capitalize' }}>
                        {method?.card_brand ?? '-'}
                      </span>{' '}
                      {method?.is_active && (
                        <Badge className="ml-1" color="info">
                          Default
                        </Badge>
                      )}
                    </td>
                    <td>**** **** **** {method?.card_last_digits ?? '****'}</td>
                    <td>
                      {method?.card_exp_month ?? '**'}/
                      {method?.card_exp_year ?? '**'}
                    </td>
                    <td>{method?.brand_title ?? '-'}</td>
                    <td>
                      {actionNode ? (
                        actionNode?.(method?.id)
                      ) : (
                        <>
                          <Link
                            to="#"
                            onClick={e => handleDelete(e, method?.id)}
                            className="text-danger"
                            id={`delete${method?.id}`}
                          >
                            <i className="mdi mdi-trash-can font-size-18" />
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target={`delete${method?.id}`}
                          >
                            Delete
                          </UncontrolledTooltip>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </BTable>
            {paymentMethods.length < 1 && (
              <div className={styles.textCenter}>
                <p>No credit cards found</p>
              </div>
            )}
          </CardBody>
          {confirmModal && (
            <ConfirmationPopup
              active={confirmModal}
              setActive={setConfirmModal}
              title="Are you sure you want to remove this card?"
              onSaveClick={removeCard}
            >
              <p />
            </ConfirmationPopup>
          )}
        </Card>
      </Col>
    </Row>
  );
};

PaymentMethodsTable.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  title: PropTypes.string,
  onRemovePaymentMethod: PropTypes.func,
  actionNode: PropTypes.node,
};

PaymentMethodsTable.defaultProps = {
  title: 'Card Payment Details',
};
export default PaymentMethodsTable;
