import React, { memo, useRef } from 'react';
import styles from './FileField.scss';
import { FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FileInput = ({
  label,
  onAddFile,
  multiple,
  buttonLabel,
  className,
  ...props
}) => {
  const inputRef = useRef(null);

  const inputChangeHandler = event => {
    onAddFile?.(Array.prototype.slice.call(event.target.files));
    event.target.value = '';
  };

  const buttonClickHandler = event => {
    event.preventDefault();
    const input = inputRef.current;
    input.click();
  };

  return (
    <FormGroup className={cx([styles.inputContainer, className])}>
      <Label>{label || 'File'}</Label>
      <input
        onChange={inputChangeHandler}
        ref={inputRef}
        className={styles.input}
        type="file"
        multiple={multiple}
        {...props}
      />
      <button
        type="button"
        onClick={buttonClickHandler}
        className={styles.button}
      >
        <i className="ri-upload-cloud-2-line font-size-20 mr-2" />
        {buttonLabel ?? 'Upload'}
      </button>
    </FormGroup>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  onAddFile: PropTypes.func,
  multiple: PropTypes.bool,
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
};
export default memo(FileInput);
