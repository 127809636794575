import React from 'react';
import PageWrapper from 'components/PageWrapper';
import PropTypes from 'prop-types';
import Breadcrumbs from 'components/Breadcrumbs';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import { isPendingSelector } from 'models/products/selectors';
import useSelector from 'hooks/useSelector';
import Preloader from 'components/Preloader';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import ProductCategoryForm from 'components/ProductCategoryForm';

const CreateWineCategory = ({ title }) => {
  const createCategory = useAction(productsActions.createProductCategory);
  const isPending = useSelector(isPendingSelector);

  const handleSubmit = values => {
    createCategory({
      ...values,
      parent: 'Wine',
      redirectUrl: '/wine-categories/',
    });
  };

  if (isPending) return <Preloader />;
  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new category"
        breadcrumbItems={[
          {
            title: 'Back to categories',
            link: '/wine-categories/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ProductCategoryForm submitTitle="Save" onSubmit={handleSubmit} />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateWineCategory.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CreateWineCategory;
