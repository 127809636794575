import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button } from 'reactstrap';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const TableOfTransfers = ({ transferItems, setTransferItems }) => {
  const [transferForDelete, setTransferForDelete] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteTransferItem = () => {
    const newTransferItems = transferItems.filter(
      item => item.id !== transferForDelete
    );
    setTransferItems(newTransferItems);
    setTransferForDelete(null);
  };

  const handleOpenConfirmation = id => {
    setConfirmModal(true);
    setTransferForDelete(id);
  };

  return (
    <Card>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              <th data-priority="1">Title</th>
              <th data-priority="1">Quantity</th>
              <th data-priority="1">Notification Limit</th>
              <th data-priority="1" style={{ width: '100px' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {transferItems.map(item => (
              <tr key={item.id}>
                <td> {item.productTitle}</td>
                <td> {item.quantity}</td>
                <td> {item.limit_value || '-'}</td>
                <td>
                  <Button
                    color="danger"
                    className="waves-effect waves-light mr-3 text-nowrap "
                    onClick={() => handleOpenConfirmation(item.id)}
                  >
                    <i className="mdi mdi-trash-can font-size-18" />
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      {confirmModal && (
        <ConfirmationPopup
          active={confirmModal}
          setActive={setConfirmModal}
          onSaveClick={handleDeleteTransferItem}
        />
      )}
    </Card>
  );
};

TableOfTransfers.propTypes = {
  transferItems: PropTypes.array,
  setTransferItems: PropTypes.func,
};

export default TableOfTransfers;
