import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const awardsSlice = createSlice({
  name: 'awards',
  initialState: {
    isPending: false,
    awards: [],
    award: {},
    pagination: {},
  },
  reducers: {
    fetchAwards: state => {
      state.isPending = true;
    },
    fetchAwardsSuccess(state, { payload }) {
      state.isPending = false;
      state.awards = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchAwardsFailure: state => {
      state.isPending = false;
    },
    fetchAward: state => {
      state.isPending = true;
    },
    fetchAwardSuccess(state, { payload }) {
      state.isPending = false;
      state.award = payload.data;
    },
    fetchAwardFailure: state => {
      state.isPending = false;
    },
    createAward: state => {
      state.isPending = true;
    },
    createAwardSuccess(state, { payload }) {
      state.awards = state.awards.concat(payload.data);
      state.isPending = false;
    },
    createAwardFailure: state => {
      state.isPending = false;
    },
    deleteAward: state => {
      state.isPending = true;
    },
    deleteAwardSuccess(state, { payload }) {
      state.awards = state.awards.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteAwardFailure: state => {
      state.isPending = false;
    },
    updateAward: state => {
      state.isPending = true;
    },
    updateAwardSuccess(state, { payload }) {
      state.awards = state.awards.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateAwardFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(awardsSlice.actions);

export default awardsSlice.reducer;
