import { createSelector } from 'reselect';
import { sumBy, get } from 'lodash';

export const reportsSelector = state => state.reports;

export const isPendingSelector = createSelector(
  reportsSelector,
  ({ isPending }) => isPending
);

export const salesSelector = createSelector(
  reportsSelector,
  ({ sales }) => sales
);

export const wineAssociatesSalesSelector = createSelector(
  reportsSelector,
  ({ wineAssociatesSales }) => wineAssociatesSales
);

export const reportsOrdersSelector = createSelector(
  reportsSelector,
  ({ orders }) => orders
);

export const tasterTrackingSelector = createSelector(
  reportsSelector,
  ({ tasterTracking }) => tasterTracking
);

export const paginationSelector = createSelector(
  reportsSelector,
  ({ pagination }) => pagination
);

export const salesPendingSelector = createSelector(
  reportsSelector,
  ({ isPending }) => isPending
);

export const salesRevenueSelector = createSelector(salesSelector, sales =>
  get(
    sales.salesOverview.find(s => s['Product Type'] === 'Total'),
    'Net Product Sales',
    0
  )
);

export const salesAllSelector = createSelector(salesSelector, sales =>
  get(
    (sales.salesByPaymentType || []).find(s => s['Payment Type'] === 'TOTAL'),
    'Total',
    0
  )
);

export const salesRefundsTotalSelector = createSelector(salesSelector, sales =>
  get(
    sales.salesOverview.find(s => s['Product Type'] === 'Refunds'),
    'Net Product Sales',
    undefined
  )
);

export const salesCountSelector = createSelector(salesSelector, sales =>
  sumBy(sales.ordersSummary, 'Number of orders')
);

export const averagePriceSelector = createSelector(
  salesSelector,
  sales =>
    sumBy(sales.ordersSummary, 'Average Order Value') /
    sales.ordersSummary.length
);

export const footTrafficSelector = createSelector(salesSelector, sales =>
  sumBy(sales.ordersSummary, 'Foot traffic')
);

export const salesSourcesSelector = createSelector(
  salesSelector,
  sales => sales.salesBySku
);

export const salesSourcesPriceSummarySelector = createSelector(
  salesSourcesSelector,
  sources => sumBy(sources, 'Net Sales')
);

export const salesByOrderTypeSelector = createSelector(
  salesSelector,
  ({ salesByOrderType }) => salesByOrderType
);

export const salesTastingRoomTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'tastingroomorder'),
      'Total',
      undefined
    )
);

export const salesSubscriptionsTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'subscriptionorder'),
      'Total',
      undefined
    )
);

export const salesCommercialTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'commercialorder'),
      'Total',
      undefined
    )
);

export const salesContainerWorldTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'containerworldorder'),
      'Total',
      undefined
    )
);

export const salesEcommerceTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'ecommerceorder'),
      'Total',
      undefined
    )
);

export const salesRetailTotalSelector = createSelector(
  salesByOrderTypeSelector,
  salesByOrderType =>
    get(
      salesByOrderType.find(s => s['Order Type'] === 'retailorder'),
      'Total',
      undefined
    )
);

export const inventoriesSelector = createSelector(
  reportsSelector,
  ({ inventories }) => inventories
);

export const totalProductCountSelector = createSelector(
  reportsSelector,
  ({ totalProductCount }) => totalProductCount
);

export const exportFileUrlSelector = createSelector(
  reportsSelector,
  ({ exportFileUrl }) => exportFileUrl
);

export const dashboardUrlSelector = createSelector(
  reportsSelector,
  ({ dashboardUrl }) => dashboardUrl
);
