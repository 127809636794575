import React from 'react';
import styles from './LowInventorySummaryTable.scss';
import { Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LowInventorySummaryTable = ({ data, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {data && (
        <Row>
          <Col md={12}>
            <Table className={styles.table} bordered responsive>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Inventory Notification Limit</th>
                  <th>Inventory Count</th>
                </tr>
              </thead>
              <tbody>
                {data.map(item => (
                  <tr
                    key={item.id?.toString()}
                    className={item.out_of_stock ? styles.outOfStock : ''}
                  >
                    <td>{item.product_title}</td>
                    <td>{item.limit_value}</td>
                    <td>
                      {item.out_of_stock
                        ? 'OUT OF STOCK'
                        : item.latest_product_count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};

LowInventorySummaryTable.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};
export default LowInventorySummaryTable;
