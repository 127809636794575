import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { terminalsLabelMap } from 'constants/terminalModels';

const Main = ({ terminal }) => {
  return (
    <>
      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">ID</h4>
              <div className="mt-4">{terminal?.id}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Model</h4>
              <div className="mt-4">
                {get(terminalsLabelMap, terminal?.model, 'N/a')}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Serial Number</h4>
              <div className="mt-4">{terminal?.serial_number}</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Title</h4>
              <div className="mt-4">{terminal?.title}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Warehouse</h4>
              <div className="mt-4">
                <Link to={`/warehouses/${terminal?.warehouse_id}/show`}>
                  {terminal?.warehouse_title}
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="4">
          {terminal?.tables?.length > 0 && (
            <Card>
              <CardBody>
                <h4 className="card-title">Tables</h4>
                <div className="mt-4">
                  <ul className="nav d-flex flex-column">
                    {terminal?.tables?.map(table => (
                      <li key={table.id} className="mb-2">
                        <Link to={`/tables/${table.id}/edit`}>
                          - {table.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

Main.propTypes = {
  terminal: PropTypes.object,
};

export default Main;
