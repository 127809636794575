import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import { formatFees } from './utils';
import LocationSearchInput from 'components/LocationSearchInput';
import FeeTableForm from '../FeeTableForm';
import { FEE_TYPES } from 'constants';
import classNames from 'classnames';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';

import styles from './WarehouseForm.scss';
import { getRedactedValues } from 'utils/getRedactedValues';
import TextField from '../Fields/TextField';
import PhoneField from 'components/Fields/PhoneField';
import CheckboxField from '../Fields/CheckboxField';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions as warehousesActions } from 'models/warehouses/slice';
import { warehousesSelector } from 'models/warehouses/selectors';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';

const addressErrorMessage = type => `Invalid address field: ${type} is missing`;

const getAddressFieldError = (
  country,
  state,
  city,
  postalCode,
  line1,
  line2
) => {
  if ([country, state, city, postalCode, line1, line2].every(item => !item))
    return 'Required field';
  if (!country) return addressErrorMessage('country');
  if (!state) return addressErrorMessage('state');
  if (!city) return addressErrorMessage('city');
  if (!postalCode) return addressErrorMessage('postalCode');
  if (!line1) return addressErrorMessage('line1');
  return undefined;
};

const getDuplicatesInFees = fees => {
  const preparedFeesString = fees.map(
    item =>
      `${item?.title}-${item?.order_class?.apiValue}-${item?.order_item_class?.apiValue}`
  );
  const duplicates = preparedFeesString.reduce(
    (acc, v, i, arr) =>
      arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
    []
  );

  return fees
    .filter(
      item =>
        duplicates.indexOf(
          `${item?.title}-${item?.order_class?.apiValue}-${item?.order_item_class?.apiValue}`
        ) !== -1
    )
    .reduce((prev, cur) => {
      return { ...prev, [cur.id]: cur };
    }, {});
};

const getTableErrorMessage = errorObject => {
  const errorTypes = Object.entries(errorObject)
    .filter(([, value]) => value?.length)
    .map(([key]) => key);
  if (Object.values(errorTypes).length > 0) {
    return `Tables filled incorrectly: ${errorTypes.join(', ')}`;
  }
  return undefined;
};

const Form = ({ model, submitTitle, disabled, onSubmit }) => {
  const [country, setCountry] = useState(model?.country || '');
  const [state, setState] = useState(model?.state || '');
  const [brand, setBrand] = useState(
    { value: model?.brand_id, label: model?.brand_title } || {}
  );
  const [postalCode, setPostalCode] = useState(model?.postal_code || '');
  const [city, setCity] = useState(model?.city || '');
  const [line1, setLine1] = useState(model?.line1 || '');
  const [line2, setLine2] = useState(model?.line2 || '');
  const [type, setType] = useState(FEE_TYPES.Wine);
  const [fees, setFees] = useState({
    [FEE_TYPES.Wine]: formatFees(model, FEE_TYPES.Wine),
    [FEE_TYPES.Food]: formatFees(model, FEE_TYPES.Food),
    [FEE_TYPES.Merchandise]: formatFees(model, FEE_TYPES.Merchandise),
  });
  const [formTouched, setFormTouched] = useState();
  const [addressError, setAddressError] = useState('');
  const [tableFormError, setTableFormError] = useState({
    [FEE_TYPES.Wine]: {},
    [FEE_TYPES.Food]: {},
    [FEE_TYPES.Merchandise]: {},
  });
  const fetchSenderWarehouses = useAction(
    warehousesActions.fetchSenderWarehouses
  );
  const senderWarehouses = useSelector(warehousesSelector);

  useEffect(() => {
    if (brand?.value && brand?.label) {
      fetchSenderWarehouses({
        brand_title: brand.label,
        brand_id: brand.value,
      });
    }
  }, [fetchSenderWarehouses, brand]);
  const [existingWarehouse, setExistingWarehouse] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isUsedAsSenderForOrders, setIsUsedAsSenderForOrders] = useState(false);
  const [confirmedWarehouses, setConfirmedWarehouses] = useState([]);
  const [
    isUsedAsSenderForEcommerceOrders,
    setIsUsedAsSenderForEcommerceOrders,
  ] = useState(false);
  const [isUsedBySalesReps, setIsUsedBySalesReps] = useState(
    model?.is_used_by_sales_reps || false
  );

  const isUsedAsSenderExists = useCallback(
    (property, propertyValue) => {
      if (!propertyValue) {
        setExistingWarehouse(null);
        return false;
      }

      const findExistingWarehouse = senderWarehouses.find(
        findWarehouse => findWarehouse[property] === propertyValue
      );

      if (findExistingWarehouse && findExistingWarehouse.id === model?.id) {
        return false;
      }

      setExistingWarehouse(findExistingWarehouse);
      return !!findExistingWarehouse;
    },
    [senderWarehouses, model?.id]
  );

  const handleCheckboxChange = useCallback(
    event => {
      if (showConfirmationModal) {
        return;
      }

      const propertyName = event.target.name;
      const propertyValue = event.target.checked;

      const warehouseId = model?.id;
      const warehouseAlreadyConfirmed = confirmedWarehouses.includes(
        warehouseId
      );

      if (propertyValue && isUsedAsSenderExists(propertyName, propertyValue)) {
        setShowConfirmationModal(true);

        if (!warehouseAlreadyConfirmed) {
          setConfirmedWarehouses(prevConfirmedWarehouses => [
            ...prevConfirmedWarehouses,
            warehouseId,
          ]);
        }
      } else {
        setShowConfirmationModal(false);
      }
    },
    [
      confirmedWarehouses,
      isUsedAsSenderExists,
      model?.id,
      showConfirmationModal,
    ]
  );

  const handleConfirmCurrentWarehouse = () => {
    if (existingWarehouse.is_used_as_sender_for_orders) {
      setIsUsedAsSenderForOrders(true);
    } else if (existingWarehouse.is_used_as_sender_for_ecommerce_orders) {
      setIsUsedAsSenderForEcommerceOrders(true);
    }
    setShowConfirmationModal(false);
  };

  const onCancelConfirmationModal = () => {
    setIsUsedAsSenderForOrders(false);
    setIsUsedAsSenderForEcommerceOrders(false);
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    if (!showConfirmationModal && isUsedAsSenderForOrders) {
      setIsUsedAsSenderForOrders(true);
    }
  }, [showConfirmationModal, isUsedAsSenderForOrders]);

  useEffect(() => {
    setAddressError(
      getAddressFieldError(country, state, city, postalCode, line1, line2)
    );
  }, [country, state, city, postalCode, line1, line2]);

  useEffect(() => {
    getTableErrorMessage(tableFormError);
  }, [tableFormError]);

  const handleSubmit = useCallback(
    (event, values) => {
      setFormTouched(true);
      const feesError = {
        [FEE_TYPES.Wine]: getDuplicatesInFees(fees[FEE_TYPES.Wine]),
        [FEE_TYPES.Food]: getDuplicatesInFees(fees[FEE_TYPES.Food]),
        [FEE_TYPES.Merchandise]: getDuplicatesInFees(
          fees[FEE_TYPES.Merchandise]
        ),
      };
      setTableFormError(feesError);
      if (
        addressError ||
        Object.values(feesError).some(item => Object.values(item).length)
      ) {
        return;
      }

      const getFeeInformation = (fee, product_class) => ({
        title: fee.title,
        alias: fee.alias,
        product_class,
        order_class: fee.order_class.apiValue,
        order_item_class: fee.order_item_class.apiValue,
        units: fee.amount.units,
        amount: fee.amount.value,
      });

      const submittedFees = [
        ...fees[FEE_TYPES.Wine].map(item =>
          getFeeInformation(item, FEE_TYPES.Wine)
        ),
        ...fees[FEE_TYPES.Food].map(item =>
          getFeeInformation(item, FEE_TYPES.Food)
        ),
        ...fees[FEE_TYPES.Merchandise].map(item =>
          getFeeInformation(item, FEE_TYPES.Merchandise)
        ),
      ];

      onSubmit({
        id: model?.id,
        ...getRedactedValues(model, {
          ...values,
          container_world_warehouse_id:
            Number(values.container_world_warehouse_id) || null,
          country,
          state,
          city,
          postal_code: postalCode,
          line1,
          line2,
          fees: submittedFees,
          brand_id: brand?.value,
        }),
      });
    },
    [
      fees,
      addressError,
      onSubmit,
      country,
      state,
      city,
      postalCode,
      line1,
      line2,
      brand,
    ]
  );

  const onInvalidSubmit = useCallback(() => {
    setFormTouched(true);
    setTableFormError({
      [FEE_TYPES.Wine]: getDuplicatesInFees(fees[FEE_TYPES.Wine]),
      [FEE_TYPES.Food]: getDuplicatesInFees(fees[FEE_TYPES.Food]),
      [FEE_TYPES.Merchandise]: getDuplicatesInFees(fees[FEE_TYPES.Merchandise]),
    });
  }, [fees]);

  const onFeesChange = data => {
    const updatedObject = {
      [type]: data,
    };
    setFees({ ...fees, ...updatedObject });
  };

  return (
    <>
      <AvForm
        model={model}
        className="needs-validation"
        onValidSubmit={handleSubmit}
        onInvalidSubmit={onInvalidSubmit}
      >
        <Row>
          <Col lg="3">
            <TextField
              isRequired
              label="Title"
              name="title"
              errorMessage="Enter Title"
            />
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label required htmlFor="brand_id">
                Brand
              </Label>
              <BrandsPaginatedField
                onChange={setBrand}
                defaultValue={brand}
                isMulti={false}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <PhoneField
              name="phone"
              label="Phone"
              placeholder="Phone"
              isRequired
            />
          </Col>
          <Col lg="3">
            <TextField
              name="container_world_warehouse_id"
              label="Container World Building Id"
            />
          </Col>
          <Col lg="3">
            <TextField
              name="container_world_warehouse_title"
              label="Container World Warehouse Title"
              placeholder="Container World Warehouse Title"
            />
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col className={styles.checkbox} lg="3">
            <div className="custom-control custom-checkbox p-0">
              <CheckboxField
                name="is_used_as_tasting_room"
                label="Is used as a tasting room?"
              />
            </div>
          </Col>
          <Col className={styles.checkbox} lg="3">
            <div className="custom-control custom-checkbox p-0">
              <CheckboxField
                name="is_used_as_sender_for_orders"
                label="Is used as a sender for orders?"
                checked={isUsedAsSenderForOrders}
                onChange={event => handleCheckboxChange(event)}
              />
            </div>
          </Col>
          <Col className={styles.checkbox} lg="3">
            <div className="custom-control custom-checkbox p-0">
              <CheckboxField
                name="is_used_as_sender_for_ecommerce_orders"
                label="Is used as a sender for ecommerce orders?"
                checked={isUsedAsSenderForEcommerceOrders}
                onChange={event => handleCheckboxChange(event)}
              />
            </div>
          </Col>
          <Col className={styles.checkbox} lg="3">
            <div className="custom-control custom-checkbox p-0">
              <CheckboxField
                name="is_used_by_sales_reps"
                label="Is used by sales reps?"
                checked={isUsedBySalesReps}
                onChange={() => setIsUsedBySalesReps(true)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md="12">
            <LocationSearchInput
              model={model}
              isRequired
              country={country}
              state={state}
              postalCode={postalCode}
              city={city}
              line1={line1}
              line2={line2}
              setCountry={setCountry}
              setState={setState}
              setPostalCode={setPostalCode}
              setCity={setCity}
              setLine1={setLine1}
              setLine2={setLine2}
              error={formTouched && addressError ? addressError : undefined}
              onBlur={() => setFormTouched(true)}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Label>Taxes</Label>
            <Nav tabs className="nav-tabs-simple mb-4">
              {Object.entries(FEE_TYPES).map(([key, value]) => (
                <NavItem onClick={() => setType(value)} key={key}>
                  <NavLink
                    active={value === type}
                    className={classNames(
                      'font-weight-bold p-3',
                      !!Object.values(tableFormError[value]).length &&
                        'text-danger'
                    )}
                  >
                    {key}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col md={12}>
            <FeeTableForm
              type={type}
              fees={fees[type]}
              errorFees={tableFormError[type]}
              setFees={onFeesChange}
              error={getTableErrorMessage(tableFormError)}
              formTouched={formTouched}
            />
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </AvForm>
      {showConfirmationModal && (
        <ConfirmationPopup
          title="Existing Warehouse Found"
          submitButtonTitle="Confirm"
          cancelButtonTitle="Cancel"
          submitButtonColor="primary"
          onSaveClick={handleConfirmCurrentWarehouse}
          setActive={setShowConfirmationModal}
          active={showConfirmationModal}
          onCancel={onCancelConfirmationModal}
        >
          <p>
            <a
              href={`/warehouses/${existingWarehouse.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {existingWarehouse.title}
            </a>{' '}
            is already used as a sender of{' '}
            {existingWarehouse.is_used_as_sender_for_orders
              ? 'orders '
              : 'ecommerce '}
            for this brand. Do you want the current warehouse to be the sender
            instead?
          </p>
        </ConfirmationPopup>
      )}
    </>
  );
};

Form.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  submitTitle: PropTypes.string,
};

Form.defaultProps = {
  model: {},
};

export default Form;
