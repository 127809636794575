import React, { useEffect, useState } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
} from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './CreateInventory.scss';
import { selectStyles } from 'constants/selectStyles';

import Select from 'react-select';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import useAction from 'hooks/useAction';
import { actions as inventoriesActions } from 'models/inventories/slice';
import { actions as transfersActions } from 'models/transfers/slice';
import { inProgressTransfersSelector } from 'models/transfers/selectors';

import { Link } from 'react-router-dom';

import useSelector from 'hooks/useSelector';
import cx from 'classnames';
import { TRANSFER_TABLE_MODES } from 'components/InventoryTransferTable/constants';
import { hostBrandSelector, isOnlyHostSelector } from 'models/user/selectors';
import { inventoriesSelector } from 'models/inventories/selectors';
import { showErrorMessage } from 'utils/notification';
import { inventoryChanged } from 'utils/inventoryChange';
import {
  WineCount,
  FoodCount,
  MerchCount,
} from '../../components/InventoryCounts';

const COUNT_TYPE_OPTIONS = [
  { label: 'Wine', value: 'Wine' },
  { label: 'Merchandise', value: 'Merchandise' },
];

const getDifferentData = (inventory, changedData) => {
  const preparedData = [];
  inventory.forEach(prevValue => {
    const { product_id } = prevValue;
    const real_data = changedData?.[product_id]
      ? Object.keys(changedData[product_id]).reduce(
          (prev, cur) => ({
            ...prev,
            [`real_${cur}`]: changedData[product_id][cur],
          }),
          {}
        )
      : {};
    preparedData.push({
      ...prevValue,
      ...real_data,
    });
  });
  return preparedData;
};

const clearStateLocalStorage = () => {
  localStorage.removeItem('inventory_brand');
  localStorage.removeItem('inventory_warehouse');
  localStorage.removeItem('inventory_products');
};

const CreateInventoryCount = ({ title }) => {
  const fetchInventoryWarehouse = useAction(
    inventoriesActions.fetchInventoryWarehouse
  );
  const createInventoryManualCounting = useAction(
    inventoriesActions.createInventoryManualCounting
  );
  const isHost = useSelector(isOnlyHostSelector);
  const hostBrand = useSelector(hostBrandSelector);
  const hostBrandsOptions = hostBrand?.map(item => ({
    label: item.brand_title,
    value: item.brand_id,
  }));

  const [selectedWarehouse, setSelectedWarehouse] = useState(
    JSON.parse(localStorage.getItem('inventory_warehouse'))?.[0] ?? null
  );
  const [selectedBrand, setSelectedBrand] = useState(
    JSON.parse(localStorage.getItem('inventory_brand')) ?? null
  );
  const [comment, setComment] = useState('');
  const [editorMode, setEditorMode] = useState(TRANSFER_TABLE_MODES.MANUAL);
  const onBrandChange = value => {
    setSelectedBrand(value);
    setSelectedWarehouse(null);
  };
  const [inventoryCountType, setInventoryCountType] = useState(
    COUNT_TYPE_OPTIONS[0]
  );

  const inventories = useSelector(inventoriesSelector);
  const filteredInventories =
    inventories?.filter(i => i.product_type === inventoryCountType.value) || [];

  const onSubmit = data => {
    if (!comment && inventoryChanged(data, filteredInventories)) {
      showErrorMessage(
        'Comment missing.',
        'Please enter a comment addressing the difference in inventory.'
      );
      return;
    }

    const preparedData = getDifferentData(filteredInventories, data);

    createInventoryManualCounting({
      warehouse_id: selectedWarehouse?.value,
      inventory_results: preparedData,
      comment,
      count_type: inventoryCountType?.value,
    });
    clearStateLocalStorage();
  };
  const fetchInProgressTransfers = useAction(
    transfersActions.fetchInProgressTransfers
  );
  const inProgressTransfers = useSelector(inProgressTransfersSelector);

  useEffect(() => {
    if (selectedBrand && selectedWarehouse) {
      localStorage.setItem('inventory_brand', JSON.stringify(selectedBrand));
      const previousWarehouse = JSON.parse(
        localStorage.getItem('inventory_warehouse')
      )?.[0];
      if (previousWarehouse?.value !== selectedWarehouse.value) {
        localStorage.setItem(
          'inventory_warehouse',
          JSON.stringify([selectedWarehouse])
        );
        localStorage.removeItem('inventory_products');
        localStorage.removeItem('inventory_reports');
      }
      setEditorMode(TRANSFER_TABLE_MODES.MANUAL);
      setInventoryCountType(COUNT_TYPE_OPTIONS[0]);
      fetchInventoryWarehouse({
        warehouse_ids: [selectedWarehouse?.value],
        brand_id: selectedBrand?.value,
      });
    }
  }, [selectedBrand, selectedWarehouse]);

  useEffect(() => {
    if (isHost && hostBrand?.length === 1) {
      const { brand_title, brand_id } = hostBrand[0];
      setSelectedBrand({
        label: brand_title,
        value: brand_id,
      });
    }
  }, [isHost, hostBrand]);

  useEffect(() => {
    if (!isHost) {
      fetchInProgressTransfers({
        type: 'WarehouseToWarehouseTransfer',
        brand_title: selectedBrand?.title,
        receiver_title: selectedWarehouse?.title,
        receiver_id: selectedWarehouse?.id,
        sender_title: 'All senders',
        sort_field: 'created_at',
        sort_direction: 'desc',
        statuses: 'PENDING_RECEIPT,DISPUTED',
        per_page: 999,
      });
    }
  }, [fetchInProgressTransfers, selectedBrand, selectedWarehouse, isHost]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          {
            title: 'Back to Inventory Count',
            link: '/inventory-count/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        {!isHost && (
          <Col md={4}>
            <Card>
              <CardBody>
                <h3 className={styles.selectTitle}>Brand</h3>
                <BrandsPaginatedField
                  defaultValue={selectedBrand}
                  isMulti={false}
                  onChange={onBrandChange}
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {isHost && (
          <Col md={4}>
            <Card>
              <CardBody>
                <h3 className={styles.selectTitle}>Brand</h3>
                <Select
                  options={hostBrandsOptions}
                  value={selectedBrand}
                  onChange={onBrandChange}
                  styles={selectStyles}
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {!!selectedBrand && (
          <Col md={4}>
            <Card>
              <CardBody>
                <h3 className={styles.selectTitle}>Warehouse</h3>
                <WarehousesPaginatedField
                  onChange={setSelectedWarehouse}
                  value={selectedWarehouse}
                  parameters={{
                    brand_id: selectedBrand?.value,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        )}

        {!!selectedWarehouse && (
          <Col md={4}>
            <Card>
              <CardBody>
                <h3 className={styles.selectTitle}>Count type</h3>
                <Select
                  options={COUNT_TYPE_OPTIONS}
                  value={inventoryCountType}
                  onChange={e => {
                    localStorage.removeItem('inventory_reports');
                    localStorage.removeItem('inventory_products');
                    setInventoryCountType(e);
                  }}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                  className={styles.select}
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {!!selectedWarehouse && (
          <Col md={8}>
            <Card>
              <CardBody>
                <FormGroup>
                  <Label for="comment">
                    <h3 className={cx(styles.selectTitle)}>Comment</h3>
                  </Label>
                  <Input
                    id="comment"
                    name="comment"
                    type="textarea"
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>

      {selectedBrand && selectedWarehouse && inProgressTransfers?.length > 0 && (
        <Alert color="warning">
          <div>
            <i className="mdi mdi-information" />{' '}
            <b>There are unreceived transfers to this warehouse.</b>
            <p>
              The transfers below have not been received, which may cause
              discrepancies in your count.
            </p>
            <br />
            {inProgressTransfers?.map(item => (
              <div>
                <Link
                  to={`/transfers/${item.parent_transfer_id || item.id}/show`}
                >
                  Transfer #{item.parent_transfer_id || item.id} from{' '}
                  {item.sender.title} ({item.transfer_items.length} items)
                </Link>
              </div>
            ))}
          </div>
        </Alert>
      )}
      <Row>
        <Col md={12}>
          {selectedWarehouse?.value &&
            inventoryCountType?.value &&
            filteredInventories.length === 0 && (
              <Card>
                <CardBody>
                  There is no inventory of this type at this warehouse.
                </CardBody>
              </Card>
            )}
          {selectedWarehouse?.value &&
            filteredInventories?.length > 0 &&
            Boolean(inventoryCountType?.value) && (
              <>
                {inventoryCountType?.value === 'Wine' && (
                  <WineCount
                    inventories={filteredInventories}
                    editorMode={editorMode}
                    setEditorMode={setEditorMode}
                    selectedWarehouse={selectedWarehouse}
                    inventoryCount
                    inventoryCountType={inventoryCountType.value}
                    onSubmit={onSubmit}
                    isEditing
                  />
                )}
                {inventoryCountType?.value === 'Merchandise' && (
                  <MerchCount
                    inventories={filteredInventories}
                    editorMode={editorMode}
                    setEditorMode={setEditorMode}
                    selectedWarehouse={selectedWarehouse}
                    inventoryCount
                    inventoryCountType={inventoryCountType.value}
                    onSubmit={onSubmit}
                    isEditing
                  />
                )}
                {inventoryCountType?.value === 'Food' && <FoodCount />}
              </>
            )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateInventoryCount.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CreateInventoryCount;
