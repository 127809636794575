import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Alert } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import NumberField from 'components/Fields/NumberField';
import styles from 'pages/ExciseReturn/ExciseReturn.scss';
import formatPrice from 'utils/formatPrice';
import DateField from 'components/Fields/DateField';
import { MultipleFieldRow } from './utils';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as exciseActions } from 'models/excise/slice';
import { exciseReturnsSelector } from 'models/excise/selectors';

const WineForm = ({
  model,
  onSubmit,
  disabled,
  submitTitle,
  exciseLicense,
}) => {
  let formRef = useRef(null);

  const fetchExciseReturns = useAction(exciseActions.fetchExciseReturns);
  const fetchExciseReturnSummary = useAction(
    exciseActions.fetchExciseReturnSummary
  );
  const exciseReturnsList = useSelector(exciseReturnsSelector);
  const [dateState, setDateState] = useState({
    period_start: model?.period_start || '',
    period_end: model?.period_end || '',
    due_date: model?.due_date || '',
  });
  const [showForm, setShowForm] = useState(Object.keys(model).length > 1);

  const validateDateFields = () => {
    const { period_start, period_end, due_date } = dateState;
    const isStartBeforeEndDate =
      Date.parse(period_start) < Date.parse(period_end);
    const isEndBeforeDueDate = Date.parse(period_end) < Date.parse(due_date);
    const errors = {};

    if (!!period_start && !!period_end && !isStartBeforeEndDate) {
      errors.period_end = 'End date must be after start date.';
    }
    if (!!period_end && !!due_date && !isEndBeforeDueDate) {
      errors.due_date = 'Due date must be after end date.';
    }

    return errors;
  };

  const handleChangeDates = useCallback(
    e => {
      const { name, value } = e.target;
      setDateState(prevDateState => ({
        ...prevDateState,
        [name]: value,
      }));

      const { period_start, period_end, due_date } = {
        ...dateState,
        [name]: value,
      };

      // Perform the date validation
      const isStartBeforeEndDate =
        period_start &&
        period_end &&
        Date.parse(period_start) < Date.parse(period_end);
      const areDatesValid = isStartBeforeEndDate;
      setShowForm(areDatesValid);

      // Check if either start date or end date has changed
      const isStartDateChanged =
        name === 'period_start' && value !== dateState.period_start;
      const isEndDateChanged =
        name === 'period_end' && value !== dateState.period_end;

      // Proceed with fetching summary if dates are valid
      if (
        (areDatesValid && exciseLicense && !due_date) ||
        (isStartDateChanged && period_end) ||
        (isEndDateChanged && period_start)
      ) {
        fetchExciseReturnSummary({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
        });
        fetchExciseReturns({
          excise_license_id: exciseLicense.id,
          license_type: exciseLicense.license_type,
          period_start,
          period_end,
          page: 1,
          per_page: 999,
        });
      }
    },
    [dateState, exciseLicense, fetchExciseReturnSummary]
  );

  const [
    seven_percent_return_detail_opening_inventory,
    setSevenPercentReturnDetailOpeningInventory,
  ] = useState(model?.seven_percent_return_detail?.opening_inventory || '0.00');
  const [
    one_percent_return_detail_opening_inventory,
    setOnePercentReturnDetailOpeningInventory,
  ] = useState(model?.one_percent_return_detail?.opening_inventory || '0.00');
  const [
    zero_percent_return_detail_opening_inventory,
    setZeroPercentReturnDetailOpeningInventory,
  ] = useState(model?.zero_percent_return_detail?.opening_inventory || '0.00');
  const [
    seven_percent_return_detail_total_additions,
    setSevenPercentReturnDetailTotalAdditions,
  ] = useState(model?.seven_percent_return_detail?.total_additions || '0.00');
  const [
    one_percent_return_detail_total_additions,
    setOnePercentReturnDetailTotalAdditions,
  ] = useState(model?.one_percent_return_detail?.total_additions || '0.00');
  const [
    zero_percent_return_detail_total_additions,
    setZeroPercentReturnDetailTotalAdditions,
  ] = useState(model?.zero_percent_return_detail?.total_additions || '0.00');
  const [
    seven_percent_return_detail_total_reductions,
    setSevenPercentReturnDetailTotalReductions,
  ] = useState(model?.seven_percent_return_detail?.total_reductions || '0.00');
  const [
    one_percent_return_detail_total_reductions,
    setOnePercentReturnDetailTotalReductions,
  ] = useState(model?.one_percent_return_detail?.total_reductions || '0.00');
  const [
    zero_percent_return_detail_total_reductions,
    setZeroPercentReturnDetailTotalReductions,
  ] = useState(model?.zero_percent_return_detail?.total_reductions || '0.00');
  const [
    seven_percent_return_detail_inventory_adjustment,
    setSevenPercentReturnDetailInventoryAdjustment,
  ] = useState(
    model?.seven_percent_return_detail?.inventory_adjustment || '0.00'
  );
  const [
    one_percent_return_detail_inventory_adjustment,
    setOnePercentReturnDetailInventoryAdjustment,
  ] = useState(
    model?.one_percent_return_detail?.inventory_adjustment || '0.00'
  );
  const [
    zero_percent_return_detail_inventory_adjustment,
    setZeroPercentReturnDetailInventoryAdjustment,
  ] = useState(
    model?.zero_percent_return_detail?.inventory_adjustment || '0.00'
  );
  const [
    seven_percent_return_detail_closing_inventory,
    setSevenPercentReturnDetailClosingInventory,
  ] = useState(model?.seven_percent_return_detail?.closing_inventory || '0.00');
  const [
    one_percent_return_detail_closing_inventory,
    setOnePercentReturnDetailClosingInventory,
  ] = useState(model?.one_percent_return_detail?.closing_inventory || '0.00');
  const [
    zero_percent_return_detail_closing_inventory,
    setZeroPercentReturnDetailClosingInventory,
  ] = useState(model?.zero_percent_return_detail?.closing_inventory || '0.00');
  const [
    seven_percent_return_detail_quantity,
    setSevenPercentReturnDetailQuantity,
  ] = useState(model?.seven_percent_return_detail?.quantity || '0.00');
  const [
    one_percent_return_detail_quantity,
    setOnePercentReturnDetailQuantity,
  ] = useState(model?.one_percent_return_detail?.quantity || '0.00');
  const [
    zero_percent_return_detail_quantity,
    setZeroPercentReturnDetailQuantity,
  ] = useState(model?.zero_percent_return_detail?.quantity || '0.00');
  const [
    seven_percent_return_detail_duty_payable,
    setSevenPercentReturnDetailDutyPayable,
  ] = useState(model?.seven_percent_return_detail?.duty_payable || '0.00');
  const [
    one_percent_return_detail_duty_payable,
    setOnePercentReturnDetailDutyPayable,
  ] = useState(model?.one_percent_return_detail?.duty_payable || '0.00');
  const [
    zero_percent_return_detail_duty_payable,
    setZeroPercentReturnDetailDutyPayable,
  ] = useState(model?.zero_percent_return_detail?.duty_payable || '0.00');
  const [
    seven_percent_return_detail_packaged,
    setSevenPercentReturnDetailPackaged,
  ] = useState(model?.seven_percent_return_detail?.packaged || '0.00');
  const [
    one_percent_return_detail_packaged,
    setOnePercentReturnDetailPackaged,
  ] = useState(model?.one_percent_return_detail?.packaged || '0.00');
  const [
    zero_percent_return_detail_packaged,
    setZeroPercentReturnDetailPackaged,
  ] = useState(model?.zero_percent_return_detail?.packaged || '0.00');

  const [
    seven_percent_return_detail_packaged_exempt,
    setSevenPercentReturnDetailPackagedExempt,
  ] = useState(model?.seven_percent_return_detail?.packaged_exempt || '0.00');
  const [
    one_percent_return_detail_packaged_exempt,
    setOnePercentReturnDetailPackagedExempt,
  ] = useState(model?.one_percent_return_detail?.packaged_exempt || '0.00');
  const [
    zero_percent_return_detail_packaged_exempt,
    setZeroPercentReturnDetailPackagedExempt,
  ] = useState(model?.zero_percent_return_detail?.packaged_exempt || '0.00');

  const handleSubmit = useCallback(
    (_, values) => {
      const payload = {
        ...values,
      };
      onSubmit({
        id: model?.id,
        excise_license_id: exciseLicense.id,
        license_type: exciseLicense.license_type,
        ...payload,
      });
    },
    [onSubmit, model]
  );

  const calculateValues = () => {
    const formValues = formRef.getValues();
    const sevenPercentReturnDetailTotalAdditions = parseFloat(
      Number(formValues.seven_percent_return_detail.wine_produced) +
        Number(
          formValues.seven_percent_return_detail
            .wine_received_from_wine_licensees
        ) +
        Number(
          formValues.seven_percent_return_detail
            .wine_received_from_licensed_users
        ) +
        Number(formValues.seven_percent_return_detail.imported_wine) +
        Number(
          formValues.seven_percent_return_detail.packaged_wine_returned_to_bulk
        )
    ).toFixed(2);
    setSevenPercentReturnDetailTotalAdditions(
      sevenPercentReturnDetailTotalAdditions
    );
    const onePercentReturnDetailTotalAdditions = parseFloat(
      Number(formValues.one_percent_return_detail.wine_produced) +
        Number(
          formValues.one_percent_return_detail.wine_received_from_wine_licensees
        ) +
        Number(
          formValues.one_percent_return_detail.wine_received_from_licensed_users
        ) +
        Number(formValues.one_percent_return_detail.imported_wine) +
        Number(
          formValues.one_percent_return_detail.packaged_wine_returned_to_bulk
        )
    ).toFixed(2);
    setOnePercentReturnDetailTotalAdditions(
      onePercentReturnDetailTotalAdditions
    );
    const zeroPercentReturnDetailTotalAdditions = parseFloat(
      Number(formValues.zero_percent_return_detail.wine_produced) +
        Number(
          formValues.zero_percent_return_detail
            .wine_received_from_wine_licensees
        ) +
        Number(
          formValues.zero_percent_return_detail
            .wine_received_from_licensed_users
        ) +
        Number(formValues.zero_percent_return_detail.imported_wine) +
        Number(
          formValues.zero_percent_return_detail.packaged_wine_returned_to_bulk
        )
    ).toFixed(2);
    setZeroPercentReturnDetailTotalAdditions(
      zeroPercentReturnDetailTotalAdditions
    );
    const sevenPercentReturnDetailTotalReductions = parseFloat(
      Number(
        formValues.seven_percent_return_detail
          .removed_for_delivery_to_wine_licensees
      ) +
        Number(
          formValues.seven_percent_return_detail
            .removed_for_delivery_to_licensed_users
        ) +
        Number(formValues.seven_percent_return_detail.exported_wine) +
        Number(formValues.seven_percent_return_detail.packaged) +
        Number(formValues.seven_percent_return_detail.packaged_exempt) +
        Number(formValues.seven_percent_return_detail.packaged_marked) +
        Number(formValues.seven_percent_return_detail.other_non_duty_paid) +
        Number(formValues.seven_percent_return_detail.other_duty_paid)
    ).toFixed(2);

    setSevenPercentReturnDetailTotalReductions(
      sevenPercentReturnDetailTotalReductions
    );
    const onePercentReturnDetailTotalReductions = parseFloat(
      Number(
        formValues.one_percent_return_detail
          .removed_for_delivery_to_wine_licensees
      ) +
        Number(
          formValues.one_percent_return_detail
            .removed_for_delivery_to_licensed_users
        ) +
        Number(formValues.one_percent_return_detail.exported_wine) +
        Number(formValues.one_percent_return_detail.packaged) +
        Number(formValues.one_percent_return_detail.packaged_exempt) +
        Number(formValues.one_percent_return_detail.packaged_marked) +
        Number(formValues.one_percent_return_detail.other_non_duty_paid) +
        Number(formValues.one_percent_return_detail.other_duty_paid)
    ).toFixed(2);
    setOnePercentReturnDetailTotalReductions(
      onePercentReturnDetailTotalReductions
    );
    const zeroPercentReturnDetailTotalReductions = parseFloat(
      Number(
        formValues.zero_percent_return_detail
          .removed_for_delivery_to_wine_licensees
      ) +
        Number(
          formValues.zero_percent_return_detail
            .removed_for_delivery_to_licensed_users
        ) +
        Number(formValues.zero_percent_return_detail.exported_wine) +
        Number(formValues.zero_percent_return_detail.packaged) +
        Number(formValues.zero_percent_return_detail.packaged_exempt) +
        Number(formValues.zero_percent_return_detail.packaged_marked) +
        Number(formValues.zero_percent_return_detail.other_non_duty_paid) +
        Number(formValues.zero_percent_return_detail.other_duty_paid)
    ).toFixed(2);
    setZeroPercentReturnDetailTotalReductions(
      zeroPercentReturnDetailTotalReductions
    );
    setSevenPercentReturnDetailClosingInventory(
      parseFloat(
        Number(formValues.seven_percent_return_detail.opening_inventory) +
          Number(seven_percent_return_detail_inventory_adjustment) +
          Number(sevenPercentReturnDetailTotalAdditions) -
          Number(sevenPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setOnePercentReturnDetailClosingInventory(
      parseFloat(
        Number(formValues.one_percent_return_detail.opening_inventory) +
          Number(one_percent_return_detail_inventory_adjustment) +
          Number(onePercentReturnDetailTotalAdditions) -
          Number(onePercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setZeroPercentReturnDetailClosingInventory(
      parseFloat(
        Number(formValues.zero_percent_return_detail.opening_inventory) +
          Number(zero_percent_return_detail_inventory_adjustment) +
          Number(zeroPercentReturnDetailTotalAdditions) -
          Number(zeroPercentReturnDetailTotalReductions)
      ).toFixed(2) || '0.00'
    );
    setZeroPercentReturnDetailDutyPayable(
      +(
        Number(zero_percent_return_detail_quantity) *
        parseFloat(exciseLicense.wine_duty_rate_0)
      ).toFixed(2) || '0.00'
    );
    setOnePercentReturnDetailDutyPayable(
      +(
        Number(one_percent_return_detail_quantity) *
        parseFloat(exciseLicense.wine_duty_rate_1)
      ).toFixed(2) || '0.00'
    );
    setSevenPercentReturnDetailDutyPayable(
      +(
        Number(seven_percent_return_detail_quantity) *
        parseFloat(exciseLicense.wine_duty_rate_7)
      ).toFixed(2) || '0.00'
    );
  };

  return (
    <AvForm
      model={model}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      ref={node => {
        formRef = node;
      }}
    >
      <Row>
        <Col md={2} />
        <Col md={2}>
          <DateField
            name="period_start"
            label="Period Start"
            disabled={disabled}
            isRequired
            validate={{
              validateDate: () => {
                const errors = validateDateFields();
                return errors.period_start || true;
              },
            }}
            onInput={handleChangeDates}
          />
        </Col>
        <Col md={2}>
          <DateField
            name="period_end"
            label="Period End"
            disabled={disabled}
            isRequired
            validate={{
              validateDate: () => {
                const errors = validateDateFields();
                return errors.period_end || true;
              },
            }}
            onInput={handleChangeDates}
          />
        </Col>
        <Col md={2}>
          <DateField
            name="due_date"
            label="Due Date"
            disabled={disabled}
            isRequired
            validate={{
              validateDate: () => {
                const errors = validateDateFields();
                return errors.due_date || true;
              },
            }}
            onInput={handleChangeDates}
          />
        </Col>
      </Row>
      {!!model &&
        Object.keys(model).length > 1 &&
        exciseReturnsList?.length > 0 && (
          <Alert color="warning">
            <div>
              <i className="mdi mdi-information" />{' '}
              <b>
                There are one or more existing returns that overlap the selected
                period.
              </b>
              <p>
                Please make sure the selected period start and period end dates
                are correct.
              </p>
              <br />
              {exciseReturnsList?.map(item => (
                <div>
                  <Link
                    to={`/reports/excise/license/${item.excise_license_id}/return/wine/${item.id}`}
                  >
                    Excise return #{item.id} from {item.period_start} to{' '}
                    {item.period_end}
                  </Link>
                </div>
              ))}
            </div>
          </Alert>
        )}
      <div
        className={classNames(styles.paddedRow, {
          [styles.show]: showForm,
          [styles.hide]: !showForm,
        })}
      >
        <Row className={styles.paddedRow}>
          <Col md={2} />
          <Col md={2}>
            <h4 className="card-title">Containing more than 7%</h4>
          </Col>
          <Col md={2}>
            <h4 className="card-title">Containing between 1.2% and 7%</h4>
          </Col>
          <Col md={2}>
            <h4 className="card-title">Containing not more than 1.2%</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Opening Inventory</h4>
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.opening_inventory"
              value={seven_percent_return_detail_opening_inventory}
              onChange={event => {
                setSevenPercentReturnDetailOpeningInventory(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.opening_inventory"
              value={one_percent_return_detail_opening_inventory}
              onChange={event => {
                setOnePercentReturnDetailOpeningInventory(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.opening_inventory"
              value={zero_percent_return_detail_opening_inventory}
              onChange={event => {
                setZeroPercentReturnDetailOpeningInventory(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Additions to bulk wine inventory</h4>
          </Col>
        </Row>
        <MultipleFieldRow
          title="Wine produced"
          name="wine_produced"
          onBlur={calculateValues}
          onChange={event => {
            setSevenPercentReturnDetailQuantity(Number(event.target.value));
          }}
          min="0"
        />
        <MultipleFieldRow
          title="Wine received from wine licensees"
          name="wine_received_from_wine_licensees"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Wine received from licensed users"
          name="wine_received_from_licensed_users"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Imported wine"
          name="imported_wine"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Packaged wine returned to bulk"
          name="packaged_wine_returned_to_bulk"
          onBlur={calculateValues}
          min="0"
        />
        <Row>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Total Additions</h4>
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.total_additions"
              value={seven_percent_return_detail_total_additions}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.total_additions"
              value={one_percent_return_detail_total_additions}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.total_additions"
              value={zero_percent_return_detail_total_additions}
              disabled
            />
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Reductions to bulk wine inventory</h4>
          </Col>
        </Row>
        <MultipleFieldRow
          title="Removed for delivery to wine licensees"
          name="removed_for_delivery_to_wine_licensees"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Removed for delivery to licensed users"
          name="removed_for_delivery_to_licensed_users"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Exported wine"
          name="exported_wine"
          onBlur={calculateValues}
          min="0"
        />
        <Row>
          <Col md={2} className={styles.right}>
            Packaged
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.packaged"
              value={seven_percent_return_detail_packaged}
              onChange={event => {
                setSevenPercentReturnDetailPackaged(Number(event.target.value));
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.packaged"
              value={one_percent_return_detail_packaged}
              onChange={event => {
                setOnePercentReturnDetailPackaged(Number(event.target.value));
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.packaged"
              value={zero_percent_return_detail_packaged}
              onChange={event => {
                setZeroPercentReturnDetailPackaged(Number(event.target.value));
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Packaged exempt
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.packaged_exempt"
              value={seven_percent_return_detail_packaged_exempt}
              onChange={event => {
                setSevenPercentReturnDetailPackagedExempt(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.packaged_exempt"
              value={one_percent_return_detail_packaged_exempt}
              onChange={event => {
                setOnePercentReturnDetailPackagedExempt(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.packaged_exempt"
              value={zero_percent_return_detail_packaged_exempt}
              onChange={event => {
                setZeroPercentReturnDetailPackagedExempt(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
              min="0"
            />
          </Col>
        </Row>
        <MultipleFieldRow
          title="Packaged marked"
          name="packaged_marked"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Other non duty paid"
          name="other_non_duty_paid"
          onBlur={calculateValues}
          min="0"
        />
        <MultipleFieldRow
          title="Other duty paid"
          name="other_duty_paid"
          onBlur={calculateValues}
          min="0"
        />
        <Row>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Total Reductions</h4>
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.total_reductions"
              value={seven_percent_return_detail_total_reductions}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.total_reductions"
              value={one_percent_return_detail_total_reductions}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.total_reductions"
              value={zero_percent_return_detail_total_reductions}
              disabled
            />
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Inventory Adjustment</h4>
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.inventory_adjustment"
              value={seven_percent_return_detail_inventory_adjustment}
              onChange={event => {
                setSevenPercentReturnDetailInventoryAdjustment(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.inventory_adjustment"
              value={one_percent_return_detail_inventory_adjustment}
              onChange={event => {
                setOnePercentReturnDetailInventoryAdjustment(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.inventory_adjustment"
              value={zero_percent_return_detail_inventory_adjustment}
              onChange={event => {
                setZeroPercentReturnDetailInventoryAdjustment(
                  Number(event.target.value)
                );
              }}
              onBlur={calculateValues}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Closing Inventory</h4>
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_return_detail.closing_inventory"
              value={parseFloat(
                seven_percent_return_detail_closing_inventory
              ).toFixed(2)}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_return_detail.closing_inventory"
              value={parseFloat(
                one_percent_return_detail_closing_inventory
              ).toFixed(2)}
              disabled
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_return_detail.closing_inventory"
              value={parseFloat(
                zero_percent_return_detail_closing_inventory
              ).toFixed(2)}
              disabled
            />
          </Col>
        </Row>
        <Row className={styles.paddedRow}>
          <Col md={2} className={styles.right}>
            <h4 className="card-title">Duty Payable</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Quantity
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_quantity"
              value={seven_percent_return_detail_quantity}
              onChange={event => {
                setSevenPercentReturnDetailQuantity(Number(event.target.value));
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_quantity"
              value={one_percent_return_detail_quantity}
              onChange={event => {
                setOnePercentReturnDetailQuantity(Number(event.target.value));
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_quantity"
              value={zero_percent_return_detail_quantity}
              onChange={event => {
                setZeroPercentReturnDetailQuantity(Number(event.target.value));
              }}
              disabled={disabled}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className={styles.right}>
            Duty Payable ($)
          </Col>
          <Col md={2}>
            <NumberField
              name="seven_percent_duty_payable"
              value={formatPrice(
                Number(seven_percent_return_detail_duty_payable)
              )}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="one_percent_duty_payable"
              value={formatPrice(
                Number(one_percent_return_detail_duty_payable)
              )}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
          <Col md={2}>
            <NumberField
              name="zero_percent_duty_payable"
              value={formatPrice(
                Number(zero_percent_return_detail_duty_payable)
              )}
              onBlur={calculateValues}
              isRequired
              min="0"
            />
          </Col>
        </Row>
        <Button color="primary" type="submit" disabled={disabled}>
          {submitTitle}
        </Button>
      </div>
    </AvForm>
  );
};

WineForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  exciseLicense: PropTypes.object,
};

WineForm.defaultProps = {
  model: {},
};

export default WineForm;
