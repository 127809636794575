import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import CardItem from 'components/CardItem';

const FoodHeader = ({ product }) => {
  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Title" value={product?.title} />
        </Col>
        <Col lg="3">
          <CardItem title="Brand" value={product?.brand?.title} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Is Addition?"
            value={product?.addition ? 'Yes' : 'No'}
          />
        </Col>
        <Col lg="3">
          <CardItem
            title="Price"
            value={`${product?.price} ${product?.price_currency}`}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <CardItem
            title="Categories"
            value={product?.categories?.map(item => item?.title).join(', ')}
          />
        </Col>
        {!isEmpty(product?.food_additions) && (
          <Col lg="3">
            <CardItem
              title="Food Additions"
              value={product?.food_additions.map(el => el.title).join(', ')}
            />
          </Col>
        )}
        {!isEmpty(product?.wine_pairings) && (
          <Col lg="3">
            <CardItem
              title="Food Additions"
              value={product?.wine_pairings.map(el => el.title).join(', ')}
            />
          </Col>
        )}

        {product?.weight_value && (
          <Col lg="3">
            <CardItem
              title="Weight"
              value={`${product?.weight_value} ${product?.weight_unit}`}
            />
          </Col>
        )}
        <Col lg="3">
          <CardItem title="Calorific" value={product?.calorific} />
        </Col>
        <Col lg="3">
          <CardItem title="Type" value={product?.resourcetype} />
        </Col>
        <Col lg="6">
          <CardItem title="SKU" value={product?.sku} />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <CardItem title="Description" value={product?.description} />
        </Col>
      </Row>
    </>
  );
};

FoodHeader.propTypes = {
  product: PropTypes.object,
};

export default FoodHeader;
