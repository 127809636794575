import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { format } from 'date-fns';
import { Row, Col, Card, Table } from 'reactstrap';
import CardItem from 'components/CardItem';

import { TRANSFERS_TYPES, ORDER_TYPES_ITEMS } from 'constants';
import { getProductTitleWithVintage } from 'utils/preparedProductTitle';
import { getNormalText } from 'utils/getNormalText';

const SaleTransfer = ({ transfer }) => {
  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Operator" value={transfer?.operator?.email} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Type"
            value={TRANSFERS_TYPES[transfer?.resourcetype]}
          />
        </Col>
        {transfer?.comment && (
          <Col lg="3">
            <CardItem title="Comment" value={transfer.comment} />
          </Col>
        )}
        <Col lg="3">
          <CardItem title="Status" value={getNormalText(transfer?.status)} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Sender"
            value={
              <>
                {`Type - ${transfer?.sender_type}`}
                <br />
                {`Title - ${transfer?.sender?.title}`}
                <br />
                {`Tasting Room - ${
                  transfer?.sender?.is_used_as_tasting_room ? 'Yes' : 'No'
                }`}
              </>
            }
          />
        </Col>
      </Row>
      {Object.keys(transfer?.receiver || {}).length > 0 && (
        <>
          <h4 className="mt-4 mb-4">Receiver</h4>
          <Row>
            <Col lg="3">
              <CardItem
                title="Brand"
                value={
                  <Link to={`/brands/${transfer?.receiver?.brand?.id}/edit`}>
                    {transfer?.receiver?.brand?.title}
                  </Link>
                }
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Warehouse"
                value={
                  <Link
                    to={`/warehouses/${transfer?.receiver?.warehouse?.id}/show`}
                  >
                    {transfer?.receiver?.warehouse?.title}
                  </Link>
                }
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Status"
                value={getNormalText(transfer?.receiver?.status)}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Type"
                value={ORDER_TYPES_ITEMS[transfer?.receiver?.resourcetype]}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Customer Name"
                value={transfer?.receiver?.customer_name}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col lg="12">
          <h4 className="mt-4 mb-3">Order Items</h4>
        </Col>
        <Col lg="12">
          <Card>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table id="tech-companies-1" bordered responsive>
                  <thead>
                    <tr>
                      <th data-priority="1">ID</th>
                      <th data-priority="1">Title</th>
                      <th data-priority="1">SKU</th>
                      <th data-priority="1">Number</th>
                      <th data-priority="1">Price</th>
                      <th data-priority="3">Total Price</th>
                      <th data-priority="3">Resource Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(transfer?.transfer_items || []).map(item => (
                      <tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>
                          {getProductTitleWithVintage(
                            item?.title,
                            item?.product?.vintage
                          )}
                        </td>
                        <td>{item?.product?.sku} </td>
                        <td>{item?.quantity} </td>
                        <td>
                          {item?.product_price}&nbsp;
                          {item?.product_price_currency}{' '}
                        </td>
                        <td>
                          {item?.total_price}&nbsp;{item?.total_price_currency}{' '}
                        </td>
                        <td>
                          {ORDER_TYPES_ITEMS[item?.product?.resourcetype]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

SaleTransfer.propTypes = {
  transfer: PropTypes.object,
};

export default SaleTransfer;
