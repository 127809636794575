import { createSelector } from 'reselect';

export const rootSelector = state => state.workspaces;

export const workspacesSelector = createSelector(
  rootSelector,
  ({ workspaces }) => workspaces
);

export const currentWorkspaceSelector = createSelector(
  rootSelector,
  ({ currentWorkspace }) => currentWorkspace
);
