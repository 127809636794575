import React, { useMemo, useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CustomInput, Table } from 'reactstrap';
import { normalize } from 'utils/normalizeById';

import { SHIPMENT_STATUSES } from 'constants/shipment';

import styles from './ProductsTable.scss';
import ProductRow from './ProductRow';

const getSelectedAllItems = orderItems =>
  (orderItems || []).reduce(
    (previous, current) => ({
      ...previous,
      [current.id]: {
        resourcetype: current.resourcetype,
        number: current.number,
        back_to_inventory_number:
          current.resourcetype === 'ProductOrderItem' ? current.number : 0,
      },
    }),
    {}
  );

const STATUS_SHIPMENT_REFUND = [
  SHIPMENT_STATUSES.PRE_TRANSIT,
  SHIPMENT_STATUSES.UNKNOWN,
  SHIPMENT_STATUSES.RETURN_TO_SENDER,
  SHIPMENT_STATUSES.FAILURE,
  SHIPMENT_STATUSES.ERROR,
];

const getSelectedAdditions = (normalizedOrderItems, additions, isSelected) =>
  (additions || [])
    .filter(addition => addition.id in normalizedOrderItems)
    .reduce(
      (previous, current) => ({
        ...previous,
        [current.id]: {
          number: +isSelected,
          resourcetype: current.resourcetype,
          back_to_inventory_number: 0,
        },
      }),
      {}
    );

const isAdditionDisabled = (selectedOrderItems, parentId) =>
  selectedOrderItems?.[parentId]?.number > 0;

const getOrderItemTotalPrice = item => {
  return item?.after_return_number
    ? +item?.after_return_total_price
    : +item?.total_price;
};

const ProductsTable = ({
  shipment,
  products,
  onChange,
  tips,
  isTipsRefunded,
  shouldRefundTips,
  setShouldRefundTips,
  setShipping,
  shipping,
}) => {
  const [selectedOrderItems, setSelectedOrderItems] = useState({});

  const normalizedOrderItems = useMemo(() => normalize(products || []), [
    products,
  ]);

  useEffect(() => {
    onChange?.(selectedOrderItems);
  }, [selectedOrderItems]);

  const onRowChange = (item, data) => {
    const { id, resourcetype } = item;

    let selectedAdditions = {};
    if (resourcetype === 'FoodOrderItem' && !item.addition) {
      selectedAdditions = getSelectedAdditions(
        normalizedOrderItems,
        item?.food_order_items,
        data.number > 0
      );
    }

    setSelectedOrderItems({
      ...selectedOrderItems,
      [id]: {
        ...data,
        resourcetype,
        back_to_inventory_number:
          resourcetype === 'ProductOrderItem'
            ? data.back_to_inventory_number
            : 0,
      },
      ...selectedAdditions,
    });
  };

  const onDefaultChange = event => {
    const { checked } = event.target;
    setSelectedOrderItems(checked ? getSelectedAllItems(products) : {});
    setShouldRefundTips(checked);
    if (
      shipment &&
      STATUS_SHIPMENT_REFUND.includes(shipment?.status) &&
      shipment?.is_can_be_returned
    )
      setShipping(checked);
  };

  const hasSelectedItems = useMemo(
    () =>
      Object.values(selectedOrderItems).some(item => item.number > 0) ||
      shipping,
    [selectedOrderItems, shipping]
  );

  const getOrderItemProps = useCallback(
    item => ({
      ...item,
      total_price: getOrderItemTotalPrice(item),
      selectedCount: selectedOrderItems[item.id]?.number ?? 0,
      backToInventoryCount:
        selectedOrderItems[item.id]?.back_to_inventory_number ?? 0,
      onChange: data => onRowChange(item, data),
      disabled: isAdditionDisabled(
        selectedOrderItems,
        item?.food_order_item_id
      ),
    }),
    [selectedOrderItems]
  );

  return (
    <Card className={styles.card}>
      <CardBody>
        <Table className={styles.table}>
          <thead>
            <tr>
              <th data-priority="1" className={styles.cellCheckbox}>
                <div className={styles.checkbox}>
                  <CustomInput
                    id="onDefaultChange"
                    onChange={onDefaultChange}
                    checked={hasSelectedItems}
                    type="checkbox"
                  />
                </div>
              </th>
              <th data-priority="3">Item</th>
              <th data-priority="1">Quantity</th>
              <th data-priority="1">Cost</th>
            </tr>
          </thead>
          <tbody>
            {products?.map(item => (
              <>
                {!item.addition && (
                  <>
                    <ProductRow
                      key={item.id.toString()}
                      {...getOrderItemProps(item)}
                    />
                    {item?.food_order_items?.map(addition => (
                      <>
                        {addition.id in normalizedOrderItems && (
                          <ProductRow
                            key={item.id.toString()}
                            {...getOrderItemProps(addition)}
                          />
                        )}
                      </>
                    ))}
                  </>
                )}
              </>
            ))}
            {!!shipment && (
              <ProductRow
                title={`Shipping (${shipment?.brand_shipping_company_title})`}
                onChange={() => setShipping(!shipping)}
                total_price={+shipment?.price}
                selectedCount={shipping ? 1 : null}
                number={1}
                disabled={
                  shipment?.is_can_be_returned === false ||
                  !STATUS_SHIPMENT_REFUND.includes(shipment?.status)
                }
              />
            )}
            {!!tips && !isTipsRefunded && (
              <ProductRow
                title="Tips"
                total_price={tips}
                number={1}
                onChange={() => setShouldRefundTips(!shouldRefundTips)}
                selectedCount={shouldRefundTips ? 1 : null}
              />
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.array,
  onChange: PropTypes.func,
  tips: PropTypes.number,
  isTipsRefunded: PropTypes.bool,
  shouldRefundTips: PropTypes.bool,
  setShouldRefundTips: PropTypes.func,
  shipment: PropTypes.object,
  setShipping: PropTypes.func,
  shipping: PropTypes.bool,
};

export default memo(ProductsTable);
