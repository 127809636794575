import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import manageBodyClass from 'utils/manageBodyClass';
import Header from './Header';
import Sidebar from './Sidebar';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/user/selectors';
import LayoutPreloader from './LayoutPreloader';

const MainLayout = ({ children, leftSideBarTheme, isMobile }) => {
  const [isCondensedMenu, setIsCondensedMenu] = useState(false);
  const loading = useSelector(isPendingSelector);

  const toggleMenuCallback = useCallback(() => {
    if (isCondensedMenu) {
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      setIsCondensedMenu(false);
    } else {
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('vertical-collpsed', 'add');
      setIsCondensedMenu(true);
    }
  }, [isCondensedMenu]);

  return (
    <>
      {loading && <LayoutPreloader />}
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          isCondensed={isCondensedMenu}
          isMobile={isMobile}
        />
        <div className="main-content">
          <div className="page-content">
            <Container fluid>{children}</Container>
          </div>
        </div>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
  leftSideBarTheme: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default MainLayout;
