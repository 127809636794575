import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import styles from './FormImport.scss';
import BodyModal from '../BodyModal';
import { Button } from 'reactstrap';
import CheckboxField from '../../../Fields/CheckboxField';

const FormImport = ({ closeModal, importHandler }) => {
  const [error, setError] = useState(null);

  const handleSubmit = (event, _, values) => {
    if (Object.keys(values).filter(key => values[key]).length) {
      setError(null);
      importHandler(values);
    } else {
      setError('Choose at least one block of data');
    }
  };

  const closeModalHandler = () => {
    setError(null);
    closeModal();
  };

  return (
    <BodyModal
      title="Choose the details for import"
      setIsOpen={closeModalHandler}
    >
      <div>
        <AvForm className={styles.form} onSubmit={handleSubmit}>
          <CheckboxField
            className="d-flex flex-row-reverse justify-content-end"
            name="import_products"
            label="Import products"
          />
          <CheckboxField
            className="d-flex flex-row-reverse justify-content-end"
            name="import_wine_club_members"
            label="Import wine club members"
          />
          {error && <p className={styles.error}>{error}</p>}
          <Button color="primary" className={styles.button}>
            Import
          </Button>
        </AvForm>
      </div>
    </BodyModal>
  );
};

FormImport.propTypes = {
  importHandler: PropTypes.func,
  closeModal: PropTypes.func,
};

export default memo(FormImport);
