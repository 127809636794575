import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import PaginationTable from 'components/PaginationTable';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as exciseActions } from 'models/excise/slice';
import {
  isPendingSelector,
  paginationSelector,
  exciseReturnsSelector,
} from 'models/excise/selectors';

const ExciseReturnsWineSummary = ({ exciseLicense }) => {
  const isPending = useSelector(isPendingSelector);
  const pagination = useSelector(paginationSelector);
  const exciseReturns = useSelector(exciseReturnsSelector);

  const fetchExciseReturns = useAction(exciseActions.fetchExciseReturns);

  const [currentPage, setCurrentPage] = useState(pagination?.current_page);

  useEffect(() => {
    fetchExciseReturns({
      excise_license_id: exciseLicense?.id,
      license_type: exciseLicense?.license_type,
      page: currentPage,
    });
  }, [fetchExciseReturns, currentPage]);

  return (
    <PaginationTable setCurrentPage={setCurrentPage} pagination={pagination}>
      <DataTable
        isPending={isPending}
        exciseReturns={exciseReturns}
        exciseLicense={exciseLicense}
      />
    </PaginationTable>
  );
};

ExciseReturnsWineSummary.propTypes = {
  exciseLicense: PropTypes.object.isRequired,
};

export default ExciseReturnsWineSummary;
