import { useCallback, useState } from 'react';

export const useCategoriesOptions = ({
  defaultValues: { brand, categories },
}) => {
  const [brandTouched, setBrandTouched] = useState(false);
  const [categoriesTouched, setCategoriesTouched] = useState(false);
  const [selectedBrand, setBrand] = useState(
    brand ? { label: brand.title, value: brand.id } : null
  );
  const [selectedCategories, setCategories] = useState(categories);

  const onBrandChange = useCallback(data => {
    setBrand(data);
    setCategories([]);
  }, []);

  const onCategoriesChange = useCallback(data => setCategories(data), []);

  return {
    selectedBrand,
    selectedCategories,
    brandError: brandTouched && selectedBrand == null,
    categoriesError: categoriesTouched && selectedCategories?.length === 0,
    setBrandTouched,
    setCategoriesTouched,
    onBrandChange,
    onCategoriesChange,
  };
};
