export const INFORMATION_WINE_DIRECT = Object.freeze({
  wd_club_membership_id: 'WD Club Membership Id',
  wd_club_id: 'WD Club Id',
  wd_club_title: 'Tier',
  lifetime_value: 'Lifetime value',
  last_order_date: 'Last order date',
  cancel_date: 'Subscription Cancel Date',
  club_cancellation_reason: 'Subscription Cancel Reason',
  birthdate: 'Birth Date',
  contact_id: 'Contact Id',
  customer_number: 'WD Customer Number',
  email: 'Bill Email',
  first_name: 'Bill First Name',
  last_name: 'Bill Last Name',
  phone: 'Bill Phone',
  current_club_membership_status: 'Club Status',
  current_number_of_shipments: 'Shipments',
  date_added: 'Date Added',
  date_modified: 'Date Modified',
  gift_message: 'Club Gift Message',
  is_gift: 'Club Is Gift',
  is_pickup: 'Is Pickup',
  is_pre_pay: 'Is Pre Pay',
  last_processed_date: 'Last Processed Date',
  notes: 'Club Notes',
  on_hold_start_date: 'On Hold Start',
  on_hold_until_date: 'On Hold End',
  pickup_handling_fee: 'Pickup Handling Fee',
  pickup_location_code: 'Pickup Location Code',
  retain_club_privileges: 'Retain Club Privileges',
  ship_to: 'Ship To',
});
