import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/CommercialOrderForm';
import { actions as warehousesActions } from 'models/warehouses/slice';
import { actions as brandsActions } from 'models/brands/slice';

import useAction from 'hooks/useAction';

import { actions as ordersActions } from 'models/orders/slice';

const CreateOrder = ({ title }) => {
  const clearCreatedOrder = useAction(ordersActions.clearCreatedOrder);
  const resetWarehouse = useAction(warehousesActions.resetWarehouse);
  const resetBrand = useAction(brandsActions.resetBrand);

  useEffect(() => {
    clearCreatedOrder();
    resetWarehouse();
    resetBrand();
  }, []);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create Commercial Order"
        breadcrumbItems={[
          {
            title: 'Back to commercial orders',
            link: '/commercial-orders/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col md="12">
          <Form isCreateForm submitTitle="Save" />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateOrder.propTypes = {
  title: PropTypes.string,
};

export default CreateOrder;
