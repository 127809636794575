import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { normalize } from 'utils/normalizeById';

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState: {
    isPending: false,
    inventories: [],
    pagination: {},
    initializedManualCountPagination: {},
    inventoryManualCounting: {},
    manualCountingPending: false,
    manualCountingPagination: {},
    inventoryStatusBeforeCheck: [],
    inventoryStatusPending: false,
    inventoryStatusPagination: {},
    initializedManualCountPending: false,
    initializedManualCount: {},
    totalBottles: 0,
    containerWorldException: [],
    containerWorldExceptionPagination: {},
    lowInventory: [],
    lowInventoryPagination: {},
    tasterCountsByWarehouseId: {},
  },
  reducers: {
    fetchInventories: state => {
      state.isPending = true;
    },
    fetchInventoriesSuccess(state, { payload }) {
      state.isPending = false;
      state.inventories = payload.data.results;
      if (payload.data.pagination) {
        state.pagination = payload.data.pagination;
      }
    },
    fetchInventoriesFailure: state => {
      state.inventories = [];
      state.isPending = false;
    },
    resetInventories(state) {
      state.inventories = [];
    },

    fetchInventoryManualCounting: state => {
      state.manualCountingPending = true;
    },
    fetchInventoryManualCountingSuccess: (state, { payload }) => {
      const { results, pagination } = payload.data;
      state.manualCountingPagination = pagination;
      state.inventoryManualCounting = normalize(results);
      state.manualCountingPending = false;
    },
    fetchInventoryManualCountingFailure: state => {
      state.manualCountingPending = false;
    },
    fetchInitializedManualCount: state => {
      state.initializedManualCountPending = true;
    },
    fetchInitializedManualCountSuccess: (state, { payload }) => {
      const { data } = payload;
      state.initializedManualCount = normalize(data.results);
      state.initializedManualCountPagination = data.pagination;
      state.initializedManualCountPending = false;
    },
    fetchInitializedManualCountFailure: state => {
      state.initializedManualCountPending = false;
    },
    fetchInventoryManualCountingById: state => {
      state.manualCountingPending = true;
    },
    fetchInventoryManualCountingByIdSuccess: (state, { payload }) => {
      const { data } = payload;
      state.inventoryManualCounting[data.id] = data;
      state.manualCountingPending = false;
    },
    fetchInventoryManualCountingByIdFailure: state => {
      state.manualCountingPending = false;
    },
    fetchInventoryStatusBeforeCheck: state => {
      state.inventoryStatusPending = true;
    },
    fetchInventoryStatusBeforeCheckSuccess: (state, { payload }) => {
      const { pagination, results } = payload.data;
      state.inventoryStatusBeforeCheck = results;
      state.inventoryStatusPagination = pagination;
      state.inventoryStatusPending = false;
    },
    fetchInventoryStatusBeforeCheckFailure: state => {
      state.inventoryStatusPending = false;
    },
    createInventoryManualCounting: state => {
      state.manualCountingPending = true;
    },
    createInventoryManualCountingSuccess: state => {
      state.manualCountingPending = false;
    },
    createInventoryManualCountingFailure: state => {
      state.manualCountingPending = false;
    },
    approveInventoryManualCounting: state => {
      state.manualCountingPending = true;
    },
    approveInventoryManualCountingSuccess: (state, { payload }) => {
      const { data } = payload;
      state.inventoryManualCounting[data.id] = data;
      state.manualCountingPending = false;
    },
    approveInventoryManualCountingFailure: state => {
      state.manualCountingPending = false;
    },
    rejectInventoryManualCounting: state => {
      state.manualCountingPending = true;
    },
    rejectInventoryManualCountingSuccess: (state, { payload }) => {
      const { data } = payload;
      state.inventoryManualCounting[data.id] = data;
      state.manualCountingPending = false;
    },
    rejectInventoryManualCountingFailure: state => {
      state.manualCountingPending = false;
    },
    fetchInventoryWarehouse: state => {
      state.totalBottles = 0;
      state.isPending = true;
    },
    fetchInventoryWarehouseSuccess: (state, { payload }) => {
      const { results, pagination, total_bottles_count } = payload.data;
      state.inventories = results;
      state.pagination = pagination;
      state.totalBottles = total_bottles_count;
      state.isPending = false;
    },
    fetchInventoryWarehouseFailure: state => {
      state.isPending = false;
    },
    fetchContainerWorldException: state => {
      state.isPending = true;
    },
    fetchContainerWorldExceptionSuccess: (state, { payload }) => {
      const { data } = payload;
      state.containerWorldException = data.results;
      state.containerWorldExceptionPagination = data.pagination;
      state.isPending = false;
    },
    fetchContainerWorldExceptionFailure: state => {
      state.isPending = false;
    },
    fetchLowInventory: state => {
      state.isPending = true;
    },
    fetchLowInventorySuccess: (state, { payload }) => {
      const { data } = payload;
      state.lowInventory = data.results;
      state.lowInventoryPagination = data.pagination;
      state.isPending = false;
    },
    fetchLowInventoryFailure: state => {
      state.isPending = false;
    },
    fetchTasterCountByWarehouseId: state => {
      state.isPending = true;
    },
    fetchTasterCountByWarehouseIdSuccess: (state, { payload }) => {
      const { data } = payload;
      const tasterCountsByWarehouseId = { ...state.tasterCountsByWarehouseId };
      tasterCountsByWarehouseId[data.warehouse.id] = data;
      state.tasterCountsByWarehouseId = tasterCountsByWarehouseId;
      state.isPending = false;
    },
    fetchTasterCountByWarehouseIdFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(inventoriesSlice.actions);

export default inventoriesSlice.reducer;
