import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  CardBody,
  Collapse,
  CardHeader,
  Row,
  Col,
  Input,
  Badge,
  FormGroup,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { getStatHolidays } from 'utils/getStatHolidays';
import moment from 'moment-timezone';

import useAction from 'hooks/useAction';
import { actions as timeEntryActions } from 'models/timeEntry/slice';

import useSelector from 'hooks/useSelector';
import { timeEntryDetailsSelector } from 'models/timeEntry/selectors';
import { isOnlyBookkeeperSelector } from 'models/user/selectors';

import {
  organizeEntriesByUserAndDate,
  getTimeEntryByDateDetails,
  calculateHourDifference,
  calculateDaySubmittedHours,
  constructTimeValue,
  calculateDayApprovedHours,
  getTimeEntryByDate,
  getIncompleteEvents,
} from 'utils/timeEntry';
import { showErrorMessage } from 'utils/notification';

import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import styles from './TimeEntryDetails.scss';
import upArrow from 'assets/images/up-arrow.svg';
import downArrow from 'assets/images/down-arrow.svg';
import {
  START_OF_DAY_HOURS,
  START_OF_DAY_MINUTES,
  START_OF_DAY_SECONDS,
  START_OF_DAY_MILLISECONDS,
  MILLISECONDS_IN_AN_HOUR,
} from 'constants/time';

const TimeEntryDetails = ({ title }) => {
  // Params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [startDate] = useState(queryParams.get('start'));
  const [endDate] = useState(queryParams.get('end'));
  const [dateTitle] = useState(queryParams.get('title'));

  const localEndDate = new Date(endDate);
  localEndDate.setMinutes(
    localEndDate.getMinutes() - localEndDate.getTimezoneOffset()
  );

  const currentDate = new Date();
  currentDate.setHours(
    START_OF_DAY_HOURS,
    START_OF_DAY_MINUTES,
    START_OF_DAY_SECONDS,
    START_OF_DAY_MILLISECONDS
  ); // Reset time to start of the day
  const isBeforeEndDate = currentDate < localEndDate;

  // Actions
  const fetchTimeEntryDetails = useAction(
    timeEntryActions.fetchTimeEntryDetails
  );
  const approveTimeEntry = useAction(timeEntryActions.approveTimeEntry);

  // States
  const [toggledCardId, setToggledCardId] = useState(null);
  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});
  const [toBeApprovedHours, setToBeApprovedHours] = useState({});
  const [approvedHours, setApprovedHours] = useState({});
  const [submittedHours, setSubmittedHours] = useState({});
  const [holidays, setHolidays] = useState([]);

  // Selectors
  const timeEntryDetails = useSelector(timeEntryDetailsSelector);
  const isOnlyBookkeeper = useSelector(isOnlyBookkeeperSelector);

  // Variables
  const userData = organizeEntriesByUserAndDate(
    timeEntryDetails?.data?.results
  );

  // Functions
  // A helper function to parse the time into parts
  const parseTime = timeString => {
    if (!timeString) return { hour: '1', minute: '00', period: 'AM' };

    const date = new Date(timeString);
    let hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hour %= 12;
    hour = hour || 12; // the hour '0' should be '12'

    // Pad the minutes with leading zero if needed
    const formattedMinute = minute < 10 ? `0${minute}` : minute.toString();

    return {
      hour: hour.toString(),
      minute: formattedMinute,
      period,
    };
  };

  // Function to determine if all entries have been approved
  const areAllEntriesApproved = entries => {
    return entries.every(entry => entry.status === 'MANAGER_APPROVED');
  };

  const isHoliday = (date, holiday) => {
    // Extract the year from startDate queryParam
    const year = new Date(startDate).getFullYear();
    const entryDateFormatted = new Date(`${date}, ${year}`);

    return holiday.some(h => {
      const holidayDate = new Date(h.start);
      return (
        entryDateFormatted.setHours(
          START_OF_DAY_HOURS,
          START_OF_DAY_MINUTES,
          START_OF_DAY_SECONDS,
          START_OF_DAY_MILLISECONDS
        ) ===
        holidayDate.setHours(
          START_OF_DAY_HOURS,
          START_OF_DAY_MINUTES,
          START_OF_DAY_SECONDS,
          START_OF_DAY_MILLISECONDS
        )
      );
    });
  };

  const isEntryLate = (startedAt, endedAt, createdAt) => {
    // Create date objects from the timestamps
    if (endedAt) {
      const startedDate = new Date(startedAt).toDateString();
      const endedDate = new Date(endedAt).toDateString();
      const creationDate = new Date(createdAt).toDateString();
      // Check if the creation date is different from the start and end dates
      return (
        startedDate !== endedDate ||
        startedDate !== creationDate ||
        endedDate !== creationDate
      );
    }
    return false;
  };

  const constructTimeValueForApprove = (hour, minute, period, startedAt) => {
    // Convert hour to 24-hour format based on the period
    hour = parseInt(hour, 10);
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }

    const utcDate = new Date(startedAt);
    const localDateString = utcDate.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    // Construct the local date-time string in the format 'YYYY-MM-DD HH:mm:ss'
    const localDateTimeString = `${localDateString} ${hour
      .toString()
      .padStart(2, '0')}:${minute.padStart(2, '0')}:00`;

    // Detect the user's current timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Use moment-timezone to parse the local time in the user's timezone
    const localDateTime = moment.tz(localDateTimeString, userTimeZone);

    // Convert the local time to UTC
    const utcDateTime = localDateTime.utc().format();

    return utcDateTime;
  };

  // Effects
  useEffect(() => {
    if (!startDate || !endDate) return;
    fetchTimeEntryDetails({
      start_date: startDate,
      end_date: endDate,
      per_page: 999999,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (timeEntryDetails?.data?.results) {
      const data = organizeEntriesByUserAndDate(timeEntryDetails.data.results);
      const newStartTimes = {};
      const newEndTimes = {};
      const initialHours = {};
      const userTotalHours = {};

      data.forEach(entry => {
        userTotalHours[entry.user.id] = 0;
        initialHours[entry.user.id] = {};

        // Process each time entry for the user
        entry.entries.forEach(item => {
          // Determine if the entry is approved
          const startTimestamp = item.started_at;
          const endTimestamp = item.ended_at || item.started_at;

          // Calculate hours if both start and end times are present
          const hoursWorked = item.ended_at
            ? parseFloat(
                calculateHourDifference(item.started_at, item.ended_at)
              )
            : 0;
          userTotalHours[entry.user.id] += hoursWorked;
          initialHours[entry.user.id][item.id] = hoursWorked;

          // Parse and set the default values for start and end time inputs based on approval status
          newStartTimes[entry.user.id] = newStartTimes[entry.user.id] || {};
          newEndTimes[entry.user.id] = newEndTimes[entry.user.id] || {};

          newStartTimes[entry.user.id][item.id] = parseTime(startTimestamp);
          newEndTimes[entry.user.id][item.id] = parseTime(endTimestamp);
        });

        // Set the sum of hours for the user
        userTotalHours[entry.user.id] = !Number.isNaN(
          userTotalHours[entry.user.id]
        )
          ? userTotalHours[entry.user.id]
          : 0;
      });

      // Update the state with the new initial values
      setStartTime(newStartTimes);
      setEndTime(newEndTimes);
    }
  }, [timeEntryDetails, toggledCardId]);

  useEffect(() => {
    if (Object.keys(startTime).length > 0 && Object.keys(endTime).length > 0) {
      // Initialize a new object to hold the calculated hours
      const newToBeApprovedHours = {};

      // Loop over userData to calculate to be approved hours for each user
      userData.forEach(userEntry => {
        const userId = userEntry.user.id;
        newToBeApprovedHours[userId] = newToBeApprovedHours[userId] || {};

        // Loop over the dates for the current user
        userEntry.entries.forEach(entry => {
          const entryDate = format(parseISO(entry.started_at), 'EEE, MMMM dd');

          // Check if the date object already exists, if not create one
          if (!newToBeApprovedHours[userId][entryDate]) {
            newToBeApprovedHours[userId][entryDate] = {
              dayToBeApprovedRegularHours: 0,
              dayToBeApprovedOvertimeHours: 0,
            };
          }

          // Extract start and end time for this specific entry
          const startInput = startTime[userId]?.[entry.id];
          const endInput = endTime[userId]?.[entry.id];

          // If both start and end times are available, calculate the hours
          if (startInput && endInput) {
            const start = constructTimeValue(
              startInput.hour,
              startInput.minute,
              startInput.period
            );
            const end = constructTimeValue(
              endInput.hour,
              endInput.minute,
              endInput.period
            );

            // Calculate the duration
            const duration = (end - start) / MILLISECONDS_IN_AN_HOUR;
            let regularHours = Math.min(8, duration);
            let overtimeHours = Math.max(0, duration - 8);

            // Ensure regular hours do not exceed 8 after adding
            const existingRegularHours =
              newToBeApprovedHours[userId][entryDate]
                .dayToBeApprovedRegularHours;
            if (existingRegularHours + regularHours > 8) {
              overtimeHours += existingRegularHours + regularHours - 8; // Add excess to overtime
              regularHours = 8 - existingRegularHours; // Adjust regular hours not to exceed 8
            }

            // Add to the current total for the date
            newToBeApprovedHours[userId][
              entryDate
            ].dayToBeApprovedRegularHours += regularHours;
            newToBeApprovedHours[userId][
              entryDate
            ].dayToBeApprovedOvertimeHours += overtimeHours;
          }
        });
      });

      // After calculations, update the state
      setToBeApprovedHours(newToBeApprovedHours);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (timeEntryDetails?.data?.results) {
      const newSubmittedHours = {};
      const newApprovedHours = {};
      const userDataForSubmittedAndApproved = organizeEntriesByUserAndDate(
        timeEntryDetails.data.results
      );

      userDataForSubmittedAndApproved.forEach(userEntry => {
        const userId = userEntry.user.id;
        newSubmittedHours[userId] = {};
        newApprovedHours[userId] = {};

        const entriesByDate = getTimeEntryByDateDetails(userEntry.entries);

        entriesByDate.forEach(entryByDate => {
          const { date } = entryByDate;
          let totalSubmittedHoursForDay = 0;
          let totalApprovedHoursForDay = 0;

          entryByDate.entries.forEach(item => {
            if (item.ended_at) {
              totalSubmittedHoursForDay += parseFloat(
                calculateHourDifference(item.started_at, item.ended_at)
              );
            }
            if (item.status === 'MANAGER_APPROVED') {
              totalApprovedHoursForDay += parseFloat(item.approved_hours) || 0;
            }
          });

          const daySubmittedRegularHours = Math.min(
            totalSubmittedHoursForDay,
            8
          );
          const daySubmittedOvertimeHours = Math.max(
            totalSubmittedHoursForDay - 8,
            0
          );

          const dayApprovedRegularHours = Math.min(totalApprovedHoursForDay, 8);
          const dayApprovedOvertimeHours = Math.max(
            0,
            totalApprovedHoursForDay - 8
          );

          newSubmittedHours[userId][date] = {
            daySubmittedRegularHours,
            daySubmittedOvertimeHours,
          };
          newApprovedHours[userId][date] = {
            dayApprovedRegularHours,
            dayApprovedOvertimeHours,
          };
        });
      });

      setSubmittedHours(newSubmittedHours);
      setApprovedHours(newApprovedHours);
    }
  }, [timeEntryDetails]);

  useEffect(() => {
    getStatHolidays(startDate, setHolidays);
  }, [startDate]);

  // Handlers
  const handleStartTimeChange = (userId, itemId, field, value) => {
    setStartTime(prevStartTimes => ({
      ...prevStartTimes,
      [userId]: {
        ...(prevStartTimes[userId] || {}),
        [itemId]: {
          ...prevStartTimes[userId]?.[itemId],
          [field]: value,
        },
      },
    }));
  };

  const handleEndTimeChange = (userId, itemId, field, value) => {
    setEndTime(prevEndTimes => ({
      ...prevEndTimes,
      [userId]: {
        ...(prevEndTimes[userId] || {}),
        [itemId]: {
          ...prevEndTimes[userId]?.[itemId],
          [field]: value,
        },
      },
    }));
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title={title}
        breadcrumbItems={[
          {
            title: <div> Back to Timesheets</div>,
            link: '/timesheets',
          },
        ]}
      />
      <Card>
        <CardBody>
          <div className={styles.mainButtonContainer}>
            <h4 className={styles.heading}>{dateTitle}</h4>
          </div>
          {/* USER data starts here */}
          {userData?.map(entry => {
            const entriesByDate = entry.entries
              ? getTimeEntryByDateDetails(entry.entries)
              : [];
            const allEntriesApproved = areAllEntriesApproved(entry.entries);
            const incompleteEvents = getIncompleteEvents(
              getTimeEntryByDate(entry?.entries)
            ).filter(item => Boolean(item));

            let totalSubmittedRegularHours = 0;
            let totalSubmittedOvertimeHours = 0;
            let totalToBeApprovedRegularHours = 0;
            let totalToBeApprovedOvertimeHours = 0;
            let totalApprovedRegularHours = 0;
            let totalApprovedOvertimeHours = 0;

            // Calculate the totals for the current user
            const calcTotalToBeApprovedHours =
              toBeApprovedHours[entry.user.id] || {};
            Object.values(calcTotalToBeApprovedHours).forEach(dateHours => {
              totalToBeApprovedRegularHours +=
                dateHours.dayToBeApprovedRegularHours || 0;
              totalToBeApprovedOvertimeHours +=
                dateHours.dayToBeApprovedOvertimeHours || 0;
            });

            const calcTotalSubmittedHours = submittedHours[entry.user.id] || {};
            Object.values(calcTotalSubmittedHours).forEach(dateHours => {
              totalSubmittedRegularHours +=
                dateHours.daySubmittedRegularHours || 0;
              totalSubmittedOvertimeHours +=
                dateHours.daySubmittedOvertimeHours || 0;
            });

            const calcTotalApprovedHours = approvedHours[entry.user.id] || {};
            Object.values(calcTotalApprovedHours).forEach(dateHours => {
              totalApprovedRegularHours +=
                dateHours.dayApprovedRegularHours || 0;
              totalApprovedOvertimeHours +=
                dateHours.dayApprovedOvertimeHours || 0;
            });

            // Add the entry's hours to the total for the day
            return (
              <Card key={entry.user.id}>
                <CardHeader
                  onClick={() =>
                    setToggledCardId(
                      toggledCardId === entry.user.id ? null : entry.user.id
                    )
                  }
                  className={styles.cardHeader}
                >
                  <span className="font-weight-bold">
                    {entry.user.first_name} {entry.user.last_name}
                  </span>

                  {incompleteEvents.length !== 0 && (
                    <span>
                      <Badge color="info" className={styles.badge}>
                        {incompleteEvents.length} Incomplete
                      </Badge>
                    </span>
                  )}
                  <span className={classNames(styles.boldText, styles.spacing)}>
                    {allEntriesApproved
                      ? `${parseFloat(
                          (
                            totalApprovedRegularHours +
                            totalApprovedOvertimeHours
                          ).toFixed(2)
                        )} Total Hours Approved`
                      : `${parseFloat(
                          (
                            totalSubmittedRegularHours +
                            totalSubmittedOvertimeHours
                          ).toFixed(2)
                        )} Total Hours Submitted`}
                    {(totalSubmittedOvertimeHours > 0 ||
                      totalApprovedOvertimeHours > 0) && (
                      <Badge color="danger" className={styles.badge}>
                        {allEntriesApproved
                          ? `${parseFloat(
                              totalApprovedOvertimeHours.toFixed(2)
                            )} Hours Overtime Approved`
                          : `${parseFloat(
                              totalSubmittedOvertimeHours.toFixed(2)
                            )} Hours Overtime Submitted`}
                      </Badge>
                    )}
                  </span>
                  <span className={styles.headerRight}>
                    {allEntriesApproved ? (
                      <Badge color="success" className="float-right">
                        Approved
                      </Badge>
                    ) : (
                      <Badge color="secondary" className={styles.badge}>
                        Pending
                      </Badge>
                    )}
                    <button
                      className={styles.toggleButton}
                      onClick={e => {
                        e.stopPropagation();
                        setToggledCardId(
                          toggledCardId === entry.user.id ? null : entry.user.id
                        );
                      }}
                      aria-label="Toggle Collapse"
                    >
                      <img
                        src={
                          toggledCardId === entry.user.id ? upArrow : downArrow
                        }
                        alt="Toggle"
                        className={styles.toggleArrow}
                      />
                    </button>
                  </span>
                </CardHeader>
                <Collapse isOpen={toggledCardId === entry.user.id}>
                  <CardBody>
                    {/* DATE wise entry for a user */}
                    {entriesByDate?.map((entryByDate, index, array) => {
                      const isLast = index === array.length - 1;
                      const showHolidayBadge = isHoliday(
                        entryByDate.date,
                        holidays
                      );
                      const userToBeApprovedHours =
                        toBeApprovedHours[entry.user.id] || {};
                      const dateToBeApprovedHours = userToBeApprovedHours?.[
                        entryByDate.date
                      ] ?? {
                        dayToBeApprovedRegularHours: 0,
                        dayToBeApprovedOvertimeHours: 0,
                      };
                      const {
                        daySubmittedRegularHours,
                        daySubmittedOvertimeHours,
                      } = calculateDaySubmittedHours(entryByDate.entries);
                      const {
                        dayApprovedRegularHours,
                        dayApprovedOvertimeHours,
                      } = calculateDayApprovedHours(entryByDate.entries);
                      return (
                        <>
                          <div
                            className={classNames(styles.dateEntryContainer, {
                              [styles.lastDateEntryContainer]: isLast,
                            })}
                          >
                            {/*
                          | Date {3}    | Submitted {2} | Approved {3} | Comments {4} |
                          | Brand {3}   | Hours {2}     | Time {2}     | Comments {4} |
                          | Name {3}    | Submitted {2} | Approved {3} | Button {4}   |
                          | Regular {3} | Reg Hours {2} | Reg Hours {3}|              |
                          | Overtime {3}| Overtime {2}  | Overtime {3} |              |
                          | Total {3}   | Total Hrs {2} | Total Hrs {3}|              |
                          */}
                            <Row key={entryByDate.date} className="mt-2">
                              <Col md={3} className={styles.flexCenter}>
                                <h5>{entryByDate.date}</h5>
                              </Col>
                              <Col md={2}>
                                <h6>Submitted</h6>
                              </Col>
                              <Col md={3}>
                                {allEntriesApproved ? (
                                  <h6>Approved</h6>
                                ) : (
                                  <h6>To be Approved</h6>
                                )}
                              </Col>
                              <Col md={4}>
                                <h6>Comments</h6>
                              </Col>
                            </Row>
                            {/* MULTIPLE ENTRIES for the same date */}
                            {entryByDate.entries.map(item => {
                              const lateEntry = isEntryLate(
                                item.started_at,
                                item.ended_at,
                                item.created_at
                              );

                              return (
                                <div
                                  className={styles.entryContainer}
                                  key={item.id}
                                >
                                  <Row>
                                    {/* COL 1 */}
                                    <Col
                                      md={3}
                                      className={styles.badgeMainContainer}
                                    >
                                      <div className={styles.brandTitle}>
                                        {item.warehouse?.brand_title}
                                      </div>
                                      <div>{item.warehouse?.title}</div>
                                      {lateEntry && (
                                        <Badge
                                          color="danger"
                                          className={styles.badge}
                                        >
                                          Late Entry
                                        </Badge>
                                      )}
                                      {showHolidayBadge && (
                                        <span className={styles.holidayBadge}>
                                          Holiday
                                        </span>
                                      )}
                                    </Col>
                                    {/* COL 2 */}
                                    <Col md={2} className={styles.colStyle}>
                                      {/* Submitted started at */}
                                      <div>
                                        {format(
                                          new Date(item.started_at),
                                          'h:mm aa'
                                        )}
                                      </div>
                                      <div>
                                        {/* Submitted ended at */}
                                        {item.ended_at ? (
                                          format(
                                            new Date(item.ended_at),
                                            'h:mm aa'
                                          )
                                        ) : (
                                          <Badge
                                            color="info"
                                            className={styles.staffBadge}
                                          >
                                            Incomplete
                                          </Badge>
                                        )}
                                      </div>
                                    </Col>
                                    {/* COL 3 */}
                                    <Col
                                      md={3}
                                      className={
                                        item.status === 'MANAGER_APPROVED'
                                          ? styles.colStyle
                                          : ''
                                      }
                                    >
                                      {item.status === 'MANAGER_APPROVED' ? (
                                        <div>
                                          {/* Approved started at */}
                                          {item.approved_started_at
                                            ? format(
                                                new Date(
                                                  item.approved_started_at
                                                ),
                                                'h:mm aa'
                                              )
                                            : '-'}
                                        </div>
                                      ) : (
                                        <div>
                                          {/* To be approved started at input */}
                                          <FormGroup
                                            style={{
                                              display: 'flex',
                                              gap: '0.5rem',
                                            }}
                                          >
                                            {/* Hours Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              f
                                              type="select"
                                              name="hour"
                                              id="startTimeHour"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                startTime[item.user.id]?.[
                                                  item.id
                                                ]?.hour
                                              }
                                              onChange={e => {
                                                handleStartTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'hour',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {[...Array(12)].map((_, i) => (
                                                <option key={i} value={i + 1}>
                                                  {i + 1}
                                                </option>
                                              ))}
                                            </Input>
                                            <span
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {' '}
                                              :{' '}
                                            </span>
                                            {/* Minutes Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              type="select"
                                              name="minute"
                                              id="startTimeMinute"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                startTime[item.user.id]?.[
                                                  item.id
                                                ]?.minute
                                              }
                                              onChange={e => {
                                                handleStartTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'minute',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {['00', '15', '30', '45'].map(
                                                minute => (
                                                  <option
                                                    key={minute}
                                                    value={minute}
                                                  >
                                                    {minute}
                                                  </option>
                                                )
                                              )}
                                            </Input>
                                            <span> </span>
                                            {/* AM/PM Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              type="select"
                                              name="period"
                                              id="startTimePeriod"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                startTime[item.user.id]?.[
                                                  item.id
                                                ]?.period
                                              }
                                              onChange={e => {
                                                handleStartTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'period',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {['AM', 'PM'].map(period => (
                                                <option
                                                  key={period}
                                                  value={period}
                                                >
                                                  {period}
                                                </option>
                                              ))}
                                            </Input>
                                          </FormGroup>
                                        </div>
                                      )}
                                      {item.status === 'MANAGER_APPROVED' ? (
                                        <div>
                                          {/* Approved ended at */}
                                          {item.approved_ended_at
                                            ? format(
                                                new Date(
                                                  item.approved_ended_at
                                                ),
                                                'h:mm aa'
                                              )
                                            : '-'}
                                        </div>
                                      ) : (
                                        <div>
                                          {/* To be approved ended at input */}
                                          <FormGroup
                                            style={{
                                              display: 'flex',
                                              gap: '0.5rem',
                                            }}
                                          >
                                            {/* Hours Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              type="select"
                                              name="hour"
                                              id="endTimeHour"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                endTime[item.user.id]?.[item.id]
                                                  ?.hour
                                              }
                                              onChange={e => {
                                                handleEndTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'hour',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {[...Array(12)].map((_, i) => (
                                                <option key={i} value={i + 1}>
                                                  {i + 1}
                                                </option>
                                              ))}
                                            </Input>
                                            <span
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              {' '}
                                              :{' '}
                                            </span>
                                            {/* Minutes Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              type="select"
                                              name="minute"
                                              id="endTimeMinute"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                endTime[item.user.id]?.[item.id]
                                                  ?.minute
                                              }
                                              onChange={e => {
                                                handleEndTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'minute',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {['00', '15', '30', '45'].map(
                                                minute => (
                                                  <option
                                                    key={minute}
                                                    value={minute}
                                                  >
                                                    {minute}
                                                  </option>
                                                )
                                              )}
                                            </Input>
                                            <span> </span>
                                            {/* AM/PM Dropdown */}
                                            <Input
                                              className={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                                  ? styles.disabledDropdown
                                                  : ''
                                              }
                                              type="select"
                                              name="period"
                                              id="endTimePeriod"
                                              disabled={
                                                allEntriesApproved ||
                                                isOnlyBookkeeper
                                              }
                                              value={
                                                endTime[item.user.id]?.[item.id]
                                                  ?.period
                                              }
                                              onChange={e => {
                                                handleEndTimeChange(
                                                  item.user.id,
                                                  item.id,
                                                  'period',
                                                  e.target.value
                                                );
                                              }}
                                              bsSize="sm"
                                            >
                                              {['AM', 'PM'].map(period => (
                                                <option
                                                  key={period}
                                                  value={period}
                                                >
                                                  {period}
                                                </option>
                                              ))}
                                            </Input>
                                          </FormGroup>
                                        </div>
                                      )}
                                    </Col>
                                    {/* COL 4 */}
                                    <Col md={4} className={styles.colStyle}>
                                      <div>{item.started_comment}</div>
                                      <div>{item.ended_comment}</div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                          <Row>
                            {/* DAY summary */}
                            <Col md={3} />
                            {/* Submitted total and overtime by Date */}
                            <Col md={2} className={styles.submittedTotal}>
                              <div
                                className={classNames(
                                  styles.calcHoursForDate,
                                  styles.totalForDate
                                )}
                              >
                                {/* Display regular hours */}
                                {parseFloat(
                                  daySubmittedRegularHours.toFixed(2)
                                )}{' '}
                                hours
                              </div>
                              {daySubmittedOvertimeHours > 0 && (
                                <div
                                  className={classNames(
                                    styles.calcHoursForDate,
                                    styles.overtimeForDate
                                  )}
                                >
                                  {/* Display overtime hours */}
                                  {parseFloat(
                                    daySubmittedOvertimeHours.toFixed(2)
                                  )}{' '}
                                  hours
                                </div>
                              )}
                            </Col>
                            {/* To be Approved/ Approved Total of the same date */}
                            <Col md={3} className={styles.submittedTotal}>
                              <div
                                className={classNames(
                                  styles.calcHoursForDate,
                                  styles.totalForDate
                                )}
                              >
                                {dayApprovedRegularHours > 0
                                  ? `${dayApprovedRegularHours} hours`
                                  : `${parseFloat(
                                      dateToBeApprovedHours?.dayToBeApprovedRegularHours.toFixed(
                                        2
                                      )
                                    )}
                                hours`}
                              </div>
                              {((dayApprovedOvertimeHours > 0 &&
                                allEntriesApproved) ||
                                (parseFloat(
                                  dateToBeApprovedHours?.dayToBeApprovedOvertimeHours.toFixed(
                                    2
                                  )
                                ) > 0 &&
                                  !allEntriesApproved)) && (
                                <div
                                  className={
                                    allEntriesApproved
                                      ? classNames(
                                          styles.calcHoursForDate,
                                          styles.overtimeForDate
                                        )
                                      : classNames(
                                          styles.calcHoursForDate,
                                          styles.overtimeForDate
                                        )
                                  }
                                >
                                  {/* Display to be approved overtime hours */}
                                  {allEntriesApproved
                                    ? `${dayApprovedOvertimeHours} hours`
                                    : `${dateToBeApprovedHours?.dayToBeApprovedOvertimeHours}
                                hours`}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    <div className={styles.summary}>
                      <Row className={styles.flexEnd}>
                        <Col md={3}>
                          <h5>
                            {entry.user.first_name} {entry.user.last_name}
                          </h5>
                        </Col>
                        <Col md={2}>
                          <h6>Submitted</h6>
                        </Col>
                        <Col md={3}>
                          <h6>
                            {allEntriesApproved
                              ? 'Approved'
                              : 'Hours to be Approved'}
                          </h6>
                        </Col>
                      </Row>
                      <Row className={styles.flexEnd}>
                        <Col md={3}>
                          <span className={styles.regular}>Regular Hours</span>
                        </Col>
                        <Col md={2}>
                          {/* Regular submitted hours */}
                          <span className={styles.regular}>
                            {parseFloat(totalSubmittedRegularHours.toFixed(2))}{' '}
                            hours
                          </span>
                        </Col>
                        <Col md={3}>
                          {/* Regular hours to be approved */}
                          <span className={styles.regular}>
                            {totalApprovedRegularHours > 0
                              ? parseFloat(totalApprovedRegularHours.toFixed(2))
                              : parseFloat(
                                  totalToBeApprovedRegularHours.toFixed(2)
                                )}{' '}
                            hours
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.flexEnd}>
                        <Col md={3}>
                          <h6 className={styles.danger}>Overtime Hours</h6>
                        </Col>
                        <Col md={2}>
                          {/* Overtime submitted */}
                          <h6 className={styles.danger}>
                            {totalSubmittedOvertimeHours} hours
                          </h6>
                        </Col>
                        <Col md={3}>
                          {/* Overtime to be approved */}
                          <h6 className={styles.danger}>
                            {allEntriesApproved
                              ? parseFloat(
                                  totalApprovedOvertimeHours.toFixed(2)
                                )
                              : parseFloat(
                                  totalToBeApprovedOvertimeHours.toFixed(2)
                                )}{' '}
                            hours
                          </h6>
                        </Col>
                      </Row>
                      <Row className={styles.flexCenter}>
                        <Col md={3}>
                          <h6 className={styles.total}>Total Hours</h6>
                        </Col>
                        <Col md={2}>
                          {/* Total submitted */}
                          <h6 className={styles.total}>
                            {parseFloat(
                              (
                                totalSubmittedRegularHours +
                                totalSubmittedOvertimeHours
                              ).toFixed(2)
                            )}{' '}
                            hours
                          </h6>
                        </Col>
                        <Col md={3}>
                          {/* Total to be approved */}
                          <h6
                            className={
                              allEntriesApproved
                                ? styles.approvedTotal
                                : styles.total
                            }
                          >
                            {allEntriesApproved
                              ? parseFloat(
                                  (
                                    totalApprovedRegularHours +
                                    totalApprovedOvertimeHours
                                  ).toFixed(2)
                                )
                              : parseFloat(
                                  (
                                    totalToBeApprovedRegularHours +
                                    totalToBeApprovedOvertimeHours
                                  ).toFixed(2)
                                )}{' '}
                            hours
                          </h6>
                        </Col>
                        <Col md={3} className={styles.approveButtonContainer}>
                          {!allEntriesApproved && !isOnlyBookkeeper && (
                            <Button
                              color="primary"
                              onClick={() => {
                                if (isBeforeEndDate) {
                                  showErrorMessage(
                                    'You cannot approve a timesheet for the current period',
                                    'Please wait until the period ends before approving this timesheet.'
                                  );
                                  return;
                                }

                                let isInvalidTime = false;
                                const entriesToApprove = entry.entries.map(
                                  item => {
                                    const userId = item.user.id; // Get the userId
                                    const itemId = item.id; // Get the itemId

                                    // Get the startTime and endTime of the entry from input
                                    const toBeApprovedStartInput =
                                      startTime[userId]?.[itemId];
                                    const toBeApprovedEndInput =
                                      endTime[userId]?.[itemId];

                                    const toBeApprovedStartTime = constructTimeValueForApprove(
                                      toBeApprovedStartInput.hour,
                                      toBeApprovedStartInput.minute,
                                      toBeApprovedStartInput.period,
                                      item.started_at
                                    );

                                    const toBeApprovedEndTime = constructTimeValueForApprove(
                                      toBeApprovedEndInput.hour,
                                      toBeApprovedEndInput.minute,
                                      toBeApprovedEndInput.period,
                                      item.started_at
                                    );

                                    // Check if the end time is before the start time
                                    if (
                                      toBeApprovedEndTime &&
                                      toBeApprovedStartTime &&
                                      new Date(toBeApprovedEndTime) <
                                        new Date(toBeApprovedStartTime)
                                    ) {
                                      showErrorMessage(
                                        'One of the entries has an end time before its start time.',
                                        `Check the to-be-approved entry starting at ${toBeApprovedStartInput.hour}:${toBeApprovedStartInput.minute} ${toBeApprovedStartInput.period} and ending at ${toBeApprovedEndInput.hour}:${toBeApprovedEndInput.minute} ${toBeApprovedEndInput.period}`
                                      );
                                      isInvalidTime = true;
                                    }

                                    // Return the approved entry object
                                    return {
                                      id: itemId,
                                      status: 'MANAGER_APPROVED',
                                      approved_at: new Date().toISOString(),
                                      approved_comment: 'Approved by admin',
                                      approved_started_at: toBeApprovedStartTime,
                                      approved_ended_at: toBeApprovedEndTime,
                                      approved_hours: parseFloat(
                                        calculateHourDifference(
                                          toBeApprovedStartTime,
                                          toBeApprovedEndTime
                                        ).toFixed(2)
                                      ),
                                    };
                                  }
                                );
                                if (isInvalidTime) {
                                  return;
                                }
                                const payload = {
                                  user: entry.user,
                                  entries: entriesToApprove,
                                  start_date: startDate,
                                  end_date: endDate,
                                  per_page: 999999,
                                };
                                approveTimeEntry(payload);
                              }}
                            >
                              Approve Timesheet
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            );
          })}
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

TimeEntryDetails.propTypes = {
  title: PropTypes.string,
};

export default TimeEntryDetails;
