import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HUMAN_LEADS_STATUSES } from 'constants';
import { HUMAN_ACTIVITY_TYPES } from 'constants/organizations';
import s from './DataTable.scss';
import LeadsActionDropdown from './LeadsActionDropdown';

const DataTable = ({ isPending, organizations }) => {
  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th data-priority="3">Leads Name</th>
          <th data-priority="2">Leads Status</th>
          <th data-priority="3">Last Activity</th>
          <th data-priority="3">Last Activity Date</th>
          <th data-priority="3">Follow-up Date</th>
          <th data-priority="1">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {organizations?.map(item => {
          const orderLink = {
            pathname: `/leads/${item.id}`,
            state: {
              previousPath: '/leads/',
            },
          };
          return (
            <tr key={item.id}>
              {/* ID */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.id}
                </Link>
              </td>
              {/* Leads Name */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {`${item.title}`}
                </Link>
              </td>
              {/* Leads Status */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {HUMAN_LEADS_STATUSES[item.lead_status]}
                </Link>
              </td>
              {/* Last Activity */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {HUMAN_ACTIVITY_TYPES[item.last_activity_type] || '-'}
                </Link>
              </td>
              {/* Last Activity Date */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.last_activity_date
                    ? format(
                        new Date(item.last_activity_date),
                        'yyyy-MM-dd, h:mm aaa'
                      )
                    : '-'}
                </Link>
              </td>
              {/* Follow up Date */}
              <td>
                <Link to={orderLink} className="text-dark font-weight-bold">
                  {item.follow_up_date || '-'}
                </Link>
              </td>
              {/* Action */}
              <td width="12%">
                <LeadsActionDropdown order={item} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  organizations: PropTypes.array,
};

export default DataTable;
