export const SHIPMENT_TYPES = Object.freeze({
  MANUAL: 'Manual',
  EASY_POST: 'Easypost',
});

export const SHIPMENT_STATUSES = Object.freeze({
  PRE_TRANSIT: 'PRE_TRANSIT',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
  RETURN_TO_SENDER: 'RETURN_TO_SENDER',
  FAILURE: 'FAILURE',
  CANCELLED: 'CANCELLED',
  RETURNED: 'RETURNED',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
});

export const SHIPMENT_STATUS_LABELS = Object.freeze({
  [SHIPMENT_STATUSES.PRE_TRANSIT]: 'Pre-Transit',
  [SHIPMENT_STATUSES.IN_TRANSIT]: 'In-Transit',
  [SHIPMENT_STATUSES.OUT_FOR_DELIVERY]: 'Out For Delivery',
  [SHIPMENT_STATUSES.DELIVERED]: 'Delivered',
  [SHIPMENT_STATUSES.RETURN_TO_SENDER]: 'Return To Sender',
  [SHIPMENT_STATUSES.FAILURE]: 'Failure',
  [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
  [SHIPMENT_STATUSES.RETURNED]: 'Returned',
  [SHIPMENT_STATUSES.ERROR]: 'Error',
  [SHIPMENT_STATUSES.UNKNOWN]: 'Unknown',
});
