import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './ImageForm.scss';
import { Draggable } from 'react-beautiful-dnd';

const ImageCard = ({
  item,
  index,
  handleDeleteImage,
  handleUpdateImage,
  imageFetchingSuccess,
}) => {
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  const resetFile = () => {
    setFile(null);
    fileRef.current.value = '';
  };

  const onFileChange = event => {
    setFile(event.target.files[0]);
  };

  const onUpdateClick = () => {
    handleUpdateImage?.(file);
  };

  useEffect(() => {
    if (imageFetchingSuccess) {
      resetFile();
    }
  }, [imageFetchingSuccess]);

  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Card>
            <CardBody>
              <div className={styles.main}>
                <img
                  className={styles.image}
                  src={item?.image_url}
                  alt=""
                  height="60"
                />
                <input
                  name="image"
                  type="file"
                  id="image"
                  accept="image/jpeg,image/png"
                  onChange={onFileChange}
                  ref={fileRef}
                />
                <Button color="danger" onClick={handleDeleteImage(item.id)}>
                  Delete
                </Button>
                {file && (
                  <div className={styles.changeWrapper}>
                    <Button onClick={onUpdateClick} color="primary">
                      Update
                    </Button>
                    <button
                      onClick={resetFile}
                      type="button"
                      className={styles.link}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

ImageCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  loading: PropTypes.bool,
  handleDeleteImage: PropTypes.func,
  handleUpdateImage: PropTypes.func,
  imageFetchingSuccess: PropTypes.bool,
};

export default ImageCard;
