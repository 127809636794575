import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Form from 'components/AcquirersForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import useComponentDidMount from 'hooks/useComponentDidMount';

import { actions as brandsActions } from 'models/brands/slice';
import {
  brandsSelector,
  isPendingSelector as brandsIsPendingSelector,
} from 'models/brands/selectors';

import { actions as acquirersActions } from 'models/acquirers/slice';
import { isPendingSelector as acquirersIsPendingSelector } from 'models/acquirers/selectors';

const AcquirerCreate = ({ title }) => {
  const createAcquirer = useAction(acquirersActions.createAcquirer);
  const fetchBrands = useAction(brandsActions.fetchBrands);

  const acquirersIsPending = useSelector(acquirersIsPendingSelector);
  const brandsIsPending = useSelector(brandsIsPendingSelector);
  const brands = useSelector(brandsSelector);

  const [currentBrandID, setCurrentBrandID] = useState(brands?.[0]?.id);

  const isPending = acquirersIsPending || brandsIsPending;

  useComponentDidMount(() => {
    fetchBrands();
  });

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new acquirer"
        breadcrumbItems={[
          { title: 'Back to acquirers', link: '/acquirers/', withSearch: true },
        ]}
      />
      <Row>
        <Col md="12">
          <Form
            brands={brands}
            disabled={isPending}
            submitTitle="Save"
            currentBrand={currentBrandID}
            setCurrentBrand={setCurrentBrandID}
            onSubmit={createAcquirer}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

AcquirerCreate.propTypes = {
  title: PropTypes.string,
};

export default AcquirerCreate;
