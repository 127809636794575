import { takeLatest, all, put } from 'redux-saga/effects';

import api from 'api';

import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { actions as orderActions } from '../orders/slice';

export function* fetchSubscriptionOrdersSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/subscription_orders',
    setQuery: true,
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* updateSubscriptionOrderSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/subscription_orders/${action.payload.id}`,
    data: action.payload,
    successMessage: 'Successfully updated subscription order!',
    successNavigateTo: `/orders/`,
  });
}

export function* captureSubscriptionOrderSaga(action) {
  const data = {
    id: action?.payload?.data?.order_id || action?.payload?.orderId,
    email: action?.payload?.data?.email,
  };

  if (action?.payload?.force_capture) {
    data.force_capture = action.payload.force_capture;
  }

  yield api({
    action,
    method: 'post',
    data,
    url: `/subscription_orders/${data.id}/capture`,
    successMessage: 'Payment was successfully completed!',
  });
}

export function* captureSubscriptionOrderSuccessSaga(action) {
  const info = action.payload.inResponseTo;

  const { orderId, email } = info;
  yield put({
    type: orderActions.sendOrderBill,
    payload: {
      data: {
        id: orderId,
        shipping_info: { email },
      },
    },
  });
}

export function* cancelObligationSaga(action) {
  yield api({
    action,
    method: 'post',
    data: action.payload,
    url: `/subscription_orders/${action.payload}/cancel_obligation`,
    successMessage: 'Subscription was successfully canceled!',
  });
}

export function* sendSubscriptionOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    data: action.payload,
    url: `/subscription_orders/${action.payload}/send`,
    successMessage: 'Order was successfully sent!',
  });
}

export function* sendApprovalEmailSubscriptionOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    data: action.payload,
    url: `/subscription_orders/${action.payload.id}/send_approval_email`,
  });
}

export function* skipSubscriptionOrderSaga(action) {
  yield api({
    action,
    method: 'post',
    data: action.payload,
    url: `/subscription_orders/${action.payload}/skip`,
    successMessage: 'Order was successfully skipped!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchSubscriptionOrders, fetchSubscriptionOrdersSaga),
    takeLatest(actions.updateSubscriptionOrder, updateSubscriptionOrderSaga),
    takeLatest(actions.cancelObligation, cancelObligationSaga),
    takeLatest(actions.captureSubscriptionOrder, captureSubscriptionOrderSaga),
    takeLatest(
      actions.captureSubscriptionOrderSuccess,
      captureSubscriptionOrderSuccessSaga
    ),
    takeLatest(actions.sendSubscriptionOrder, sendSubscriptionOrderSaga),
    takeLatest(
      actions.sendApprovalEmailSubscriptionOrder,
      sendApprovalEmailSubscriptionOrderSaga
    ),
    takeLatest(actions.skipSubscriptionOrder, skipSubscriptionOrderSaga),
  ]);
}
