import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PopupBase from '../PopupBase';
import styles from './ConfirmationEmailPopup.scss';

const ConfirmationEmailPopup = ({ isConfirmEmailSent }) => {
  return (
    <PopupBase className={styles.popup}>
      <div>
        {!isConfirmEmailSent ? (
          <p className={classNames(styles.popupText, styles.errorText)}>
            An error occurred while sending the email, try again later.
          </p>
        ) : (
          <p className={styles.popupText}>Mail successfully sent!</p>
        )}
      </div>
    </PopupBase>
  );
};

ConfirmationEmailPopup.propTypes = {
  isConfirmEmailSent: PropTypes.bool,
};

export default memo(ConfirmationEmailPopup);
