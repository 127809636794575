export const getPreparedProductCategories = categories => {
  return categories.map(category => {
    const subCategories = (category?.sub_categories ?? []).reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: {
          ...current,
          parentId: category.id,
        },
      }),
      {}
    );
    return { ...category, sub_categories: subCategories };
  });
};
