export const models = [
  { label: 'Select model', value: '' },
  {
    label: 'WisePad 3',
    value: 'wise_pad_3',
  },
  { value: 'verifone_p400', label: 'Verifone p400' },
];

export const terminalsLabelMap = models.reduce(
  (res, curr) => ({ ...res, [curr.value]: curr.label }),
  {}
);
