import React, { memo, useEffect } from 'react';
import styles from './MainLayout.scss';
import Preloader from '../../Preloader';

const LayoutPreloader = () => {
  useEffect(() => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.marginRight = `${scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'inherit';
      document.body.style.marginRight = '0';
    };
  });

  return (
    <div className={styles.preloaderContainer}>
      <Preloader />
    </div>
  );
};

export default memo(LayoutPreloader);
