import { PERMISSION_TYPES } from 'constants/permissions';

const bookkeeperOrStaffRoles = [
  PERMISSION_TYPES.BOOKKEEPER,
  PERMISSION_TYPES.STAFF,
];

export const isOnlyBookkeeperOrStaff = roles => {
  return roles.every(role => bookkeeperOrStaffRoles.includes(role));
};
