import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import LocationSearchInput from 'components/LocationSearchInput/LocationSearchInput';
import TextField from 'components/Fields/TextField';
import EmailField from 'components/Fields/EmailField';
import PhoneField from 'components/Fields/PhoneField';
import useAction from 'hooks/useAction';
import { actions } from 'models/customers/slice';
import useSelector from 'hooks/useSelector';
import { isPendingSelector } from 'models/customers/selectors';

const CreateNewCustomer = ({ setCreateCustomerOpen, wineclubId }) => {
  const createCustomer = useAction(actions.createCustomer);
  const isPending = useSelector(isPendingSelector);

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [email, setEmail] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleCreateCustomer = (_, values) => {
    createCustomer({
      ...values,
      city,
      country,
      line1,
      line2,
      postal_code: postalCode,
      state,
      wine_clubs_ids: [wineclubId],
    });

    setCreateCustomerOpen(false);
  };

  return (
    <AvForm onValidSubmit={handleCreateCustomer}>
      <Row>
        <Col md="4">
          <TextField
            isRequired
            name="first_name"
            label="First Name"
            placeholder="Name"
            errorMessage="Enter First Name"
          />
        </Col>
        <Col md="4">
          <TextField
            isRequired
            name="last_name"
            label="Last Name"
            placeholder="Name"
            errorMessage="Enter Last Name"
          />
        </Col>
        <Col md="4">
          <EmailField
            name="email"
            label="Email"
            isRequired
            placeholder="Email"
            errorMessage="Enter Email"
            value={email}
            onChange={handleEmailChange}
          />
        </Col>
        <Col md="8">
          <LocationSearchInput
            model={{}}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            isRequired
          />
        </Col>
        <Col md="4">
          <PhoneField
            isRequired
            name="customerPhone"
            label="Phone"
            placeholder="Phone"
            errorMessage="Enter Phone"
          />
        </Col>
      </Row>
      <Button color="primary" disabled={isPending}>
        Create
      </Button>
    </AvForm>
  );
};

CreateNewCustomer.propTypes = {
  setCreateCustomerOpen: PropTypes.func,
  wineclubId: PropTypes.number,
};

export default CreateNewCustomer;
