import React from 'react';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import WarehouseInventoryPaginatedField from 'components/PaginatedFields/WarehouseInventoryPaginatedField';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from '../TableOfTransfers.scss';

const TableRow = ({
  isInventory,
  products,
  value,
  warehouseId,
  onChange,
  onRemove,
}) => {
  const creationDisabled =
    products.filter(i => i.product_id === value.product_id)?.length > 1;

  const onProductChange = item => {
    onChange({
      ...value,
      product_id: item.value,
      product_title: item.label,
      current_quantity: 0,
    });
  };

  const onQuantityChange = event => {
    const inputValue = event.target.value;
    if (!inputValue) {
      onChange({ ...value, new_quantity: 0 });
    } else if (inputValue.match(/^((0[1-9]))/)) {
      const preparedValue = inputValue.replace(/^0+/, '') || 0;
      onChange({ ...value, new_quantity: Number(preparedValue) });
    } else if (inputValue.match(/^((0|([1-9][0-9]*)))$/)) {
      onChange({ ...value, new_quantity: Number(inputValue) });
    }
  };

  return (
    <tr>
      <td className={styles.withoutPadding}>
        {isInventory ? (
          <WarehouseInventoryPaginatedField
            onChange={onProductChange}
            warehouseId={warehouseId}
            withSKU
            options={{
              product_types: ['Wine', 'Merchandise'],
            }}
            defaultValue={
              value?.product_title
                ? { label: value?.product_title, value: value?.product_id }
                : {}
            }
            isDisabled={value?.is_default}
          />
        ) : (
          <ProductsPaginatedField
            warehouseIds={warehouseId}
            withSKU
            withInventories
            onChange={onProductChange}
            options={{
              product_types: ['Wine', 'Merchandise'],
            }}
            defaultValue={
              value?.product_title
                ? { label: value?.product_title, value: value?.product_id }
                : {}
            }
            isDisabled={value?.is_default}
          />
        )}
        {creationDisabled && (
          <p className={styles.error}>
            You already have data for this product.
          </p>
        )}
      </td>
      <td className={styles.cell}>{value?.current_quantity}</td>
      <td>
        <input
          type="text"
          id="quantity-input"
          value={value?.new_quantity}
          placeholder="Quantity"
          onChange={onQuantityChange}
          className="form-control"
        />
      </td>
      <td>
        {!value?.is_default && (
          <Button
            color="danger"
            type="button"
            className="waves-effect waves-light text-nowrap"
            onClick={() => onRemove(value?.id)}
          >
            <i className="mdi mdi-trash-can font-size-14" />
          </Button>
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  isInventory: PropTypes.bool,
  products: PropTypes.array,
  warehouseId: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  value: PropTypes.object,
};

export default TableRow;
