import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Filters from 'components/Filters';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as usersActions } from 'models/user/slice';
import {
  usersSelector,
  usersPendingSelector,
  paginationSelector,
  idSelector,
} from 'models/user/selectors';

import { actions as commonActions } from 'models/common/slice';

import { ROLES } from 'constants';

import DataTable from './DataTable';

const activeOptions = [
  { userValue: 'Active', apiValue: true },
  { userValue: 'Archived', apiValue: false },
];

const confirmedOptions = [
  { label: 'All statuses', value: undefined },
  { label: 'Confirmed', value: true },
  { label: 'Not Confirmed', value: false },
];

const UsersSummary = ({ title }) => {
  // Query
  const query = useQuery();

  // Selectors
  const currentUserId = useSelector(idSelector);
  const users = useSelector(usersSelector);
  const usersIsPending = useSelector(usersPendingSelector);
  const pagination = useSelector(paginationSelector);

  // Actions
  const fetchUsers = useAction(usersActions.fetchUsers);
  const grantManagerPrivileges = useAction(usersActions.grantManagerPrivileges);
  const denyManagerPrivileges = useAction(usersActions.denyManagerPrivileges);
  const updateUser = useAction(usersActions.updateUser);
  const sendConfirmationEmail = useAction(usersActions.sendConfirmationEmail);
  const setIsOpenConfirmationModal = useAction(
    commonActions.setIsOpenConfirmationModal
  );

  // States
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    is_active: true,
    is_active_title: 'Active',
  });
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const isPending = usersIsPending;

  // Effects
  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchUsers({
        ...filters,
        page: currentPage,
        customer_info_contains: filters.title_contains,
        role_is: ['staff', filters.role],
      });
    }
  }, [filters, fetchUsers, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  // Handlers
  const onUserToggleActivate = (user, isActivate) => {
    const { id, roles } = user;
    if (roles?.length === 0) {
      return;
    }
    updateUser({
      id,
      is_active: isActivate,
    });
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="d-flex justify-content-between align-items-center mb-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasTextSearch
                    hasPerPageFilter
                    hasFilterByActiveState
                    isActiveItemsFields={activeOptions}
                    hasFilterByConfirmed
                    confirmedOptions={confirmedOptions}
                    setCurrentPage={setCurrentPage}
                    disabled={isPending}
                    hasFilterByRoleIs
                    rolesFields={ROLES.filter(
                      ({ apiValue }) =>
                        !['staff', 'customer'].includes(apiValue)
                    )}
                    hasCreateItem
                    createItemLink="/users/create"
                    placeholder="Search... (By user name, user email)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  users={Object.values(users ?? {})}
                  grantManagerPrivileges={grantManagerPrivileges}
                  denyManagerPrivileges={denyManagerPrivileges}
                  setIsOpenConfirmationModal={setIsOpenConfirmationModal}
                  onArchiveClick={user => onUserToggleActivate(user, false)}
                  onRestoreClick={user => onUserToggleActivate(user, true)}
                  onConfirmationEmailClick={sendConfirmationEmail}
                  currentUserId={currentUserId}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

UsersSummary.propTypes = {
  title: PropTypes.string,
};

export default UsersSummary;
