import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { TIERS_FORM_STEPS } from 'components/TiersForm/constants';

const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {
    isPending: false,
    tiers: [],
    tier: {},
    pagination: {},
    step: TIERS_FORM_STEPS.MAIN,
    prefillsPending: false,
    prefills: [],
    selectedPrefill: null,
  },
  reducers: {
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    resetTier: state => {
      state.tier = null;
    },
    fetchTiers: state => {
      state.isPending = true;
    },
    fetchTiersSuccess(state, { payload }) {
      state.isPending = false;
      state.tiers = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchTiersFailure: state => {
      state.isPending = false;
    },
    fetchTier: state => {
      state.isPending = true;
    },
    fetchTierSuccess(state, { payload }) {
      state.isPending = false;
      state.tier = payload.data;
    },
    fetchTierFailure: state => {
      state.isPending = false;
    },
    createTier: state => {
      state.isPending = true;
    },
    createTierSuccess(state, { payload }) {
      state.tier = payload.data;
      state.step = TIERS_FORM_STEPS.OPTIONS;
      state.isPending = false;
    },
    createTierFailure: state => {
      state.isPending = false;
    },
    updateTier: state => {
      state.isPending = true;
    },
    updateTierSuccess(state, { payload }) {
      state.tiers = state.tiers.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateTierFailure: state => {
      state.isPending = false;
    },
    setSelectedPrefill: (state, { payload }) => {
      state.selectedPrefill = payload;
    },
    fetchTierPrefills: state => {
      state.prefillsPending = true;
    },
    fetchTierPrefillsSuccess: (state, { payload }) => {
      const { data } = payload;
      state.prefills = data;
      state.prefillsPending = false;
    },
    fetchTierPrefillsFailure: state => {
      state.prefillsPending = false;
    },
    createTierPrefill: state => {
      state.prefillsPending = true;
    },
    createTierPrefillSuccess: (state, { payload }) => {
      const { data } = payload;
      state.prefillsPending = false;
      state.prefills = [...state.prefills, data];
      state.selectedPrefill = null;
    },
    createTierPrefillFailure: state => {
      state.prefillsPending = false;
    },
    updateTierPrefill: state => {
      state.prefillsPending = true;
    },
    updateTierPrefillSuccess: (state, { payload }) => {
      const { data } = payload;
      state.prefillsPending = false;
      state.prefills = state.prefills.map(prefill =>
        data.id === prefill.id ? data : prefill
      );
      state.selectedPrefill = null;
    },
    updateTierPrefillFailure: state => {
      state.prefillsPending = false;
    },
    deleteTierPrefill: state => {
      state.prefillsPending = true;
    },
    deleteTierPrefillSuccess: (state, { payload }) => {
      const { id } = payload.inResponseTo;
      state.prefillsPending = false;
      state.prefills = state.prefills.filter(prefill => prefill.id !== id);
    },
    deleteTierPrefillFailure: state => {
      state.prefillsPending = false;
    },
  },
});

export const actions = actionTypes(tiersSlice.actions);

export default tiersSlice.reducer;
