export const getParsedAddress = addressValue => {
  const address = {};
  const addressTypes = {
    country: 'country',
    administrative_area_level_1: 'state',
    administrative_area_level_2: 'city', // considering admistrative areas 2 and 3 as cities as well, level of priority -> administrative area 2, 3 and locality
    administrative_area_level_3: 'city',
    locality: 'city',
    street_number: 'line1',
    route: 'line1',
    subpremise: 'line2',
    postal_code: 'postalCode',
  };

  const exclusives = ['city'];

  // sort address by addressTypes priorities
  const keys = Object.keys(addressTypes);
  addressValue.sort(function(a, b) {
    return keys.indexOf(a.types[0]) - keys.indexOf(b.types[0]);
  });

  addressValue.forEach(item => {
    const type = addressTypes[item.types[0]];

    if (type) {
      address[type] =
        address[type] && exclusives.indexOf(type) === -1
          ? `${address[type]} ${item.long_name}`
          : item.long_name;
    }
  });
  return address;
};
