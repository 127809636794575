import React from 'react';
import useSelector from 'hooks/useSelector';
import { salesSelector } from 'models/reports/selectors';
import { Card, CardBody } from 'reactstrap';
import formatPrice from 'utils/formatPrice';
import styles from './SalesTipsCard.scss';

const SalesTipsCard = () => {
  const sales = useSelector(salesSelector);

  const totalPrice = React.useMemo(
    () => sales.tipsSummary.reduce((acc, value) => acc + value['Tips Sum'], 0),
    [sales]
  );

  return (
    <Card>
      <CardBody>
        <div className={styles.title}>Wine Associates</div>
        <div className={styles.number}>$ {formatPrice(totalPrice)}</div>
        <table className="table mt-3">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Tips sum</th>
          </thead>
          <tbody>
            {sales.tipsSummary.map((source, index) => (
              <tr key={index}>
                <td>{`${source['User First Name'] || ''} ${source[
                  'User Last Name'
                ] || ''} `}</td>
                <td>{source['User Email']}</td>
                <td>$ {formatPrice(source['Tips Sum'])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SalesTipsCard;
