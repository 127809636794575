import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import DataTable from './DataTable';

import useAction from 'hooks/useAction';
import { actions as exciseActions } from 'models/excise/slice';
import useSelector from 'hooks/useSelector';
import {
  isPendingSelector,
  paginationSelector,
  exciseLicensesSelector,
} from 'models/excise/selectors';

import useQuery from 'hooks/useQuery';

const ExciseLicensesSummary = ({ title }) => {
  const query = useQuery();
  const isPending = useSelector(isPendingSelector);
  const exciseLicenses = useSelector(exciseLicensesSelector);
  const pagination = useSelector(paginationSelector);

  const fetchExciseLicenses = useAction(exciseActions.fetchExciseLicenses);

  // useStates
  const [filters, setFilters] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchExciseLicenses({
        page: currentPage,
        set_querty: true,
        ...filters,
      });
    }
  }, [filters, fetchExciseLicenses, currentPage]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasCreateItem
                    createItemLink="/reports/excise/license/create"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={isPending}
                  exciseLicenses={exciseLicenses}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

ExciseLicensesSummary.propTypes = {
  title: PropTypes.string,
};

export default ExciseLicensesSummary;
