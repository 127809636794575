import React from 'react';
import s from './DataTable.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SortArrows from 'components/SortArrows';
import handleSort from 'utils/handleSort';
import { Link } from 'react-router-dom';
import { rolesSelector } from 'models/user/selectors';
import useSelector from 'hooks/useSelector';
import OrganizationActionDropdown from './OrganizationActionDropdown';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const DataTable = ({
  isPending,
  organizations,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
}) => {
  const roles = useSelector(rolesSelector);

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th
            className="text-nowrap"
            data-priority="3"
            onClick={() =>
              handleSort('title', setSortField, setSortDirection, sortDirection)
            }
          >
            Title
            <SortArrows
              isActive={sortField === 'title'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="text-nowrap"
            data-priority="3"
            onClick={() =>
              handleSort(
                'license_number',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
          >
            License Number
            <SortArrows
              isActive={sortField === 'license_number'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="3">Company Type</th>
          <th data-priority="3">Contact Person</th>
          <th data-priority="3">Contact Person Phone</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {organizations?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>
            </td>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >
                {item.license_number}
              </Link>
            </td>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >
                {item.company_type}
              </Link>
            </td>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >{`${item.contact_person.first_name} ${item.contact_person.last_name}`}</Link>
            </td>
            <td>
              <Link
                to={`/commercial-customers/${item.id}`}
                className="text-dark font-weight-bold"
              >
                {formatPhoneNumber(item.contact_person.phone)}
              </Link>
            </td>
            <td className={s.actionColumn}>
              <OrganizationActionDropdown customer={item} roles={roles} />
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  organizations: PropTypes.array,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};

export default DataTable;
