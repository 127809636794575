import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import { selectStyles } from 'constants/selectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';

const getNormalValues = value => {
  return value.map(val => ({
    ...val,
    value: val.id,
    label: `${val.title} (${val.competition})`,
  }));
};

const AwardPaginatedField = ({
  onChange,
  isMulti,
  parameters,
  defaultValue,
  value,
  isInitialLabel,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchWineclubs = async ({ page = 1 }) => {
    return apiRequest({
      url: `/awards/?page=${page}`,
    });
  };

  const loadWineclubs = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchWineclubs({ page });
      const options = response?.results.map(item => ({
        value: item.id,
        label: `${item?.title} (${item?.competition})`,
        ...item,
      }));
      return {
        options: isInitialLabel
          ? [
              {
                label: 'All awards',
                value: 0,
              },
              ...options,
            ]
          : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="awards"
      isSearchable={false}
      placeholder="Select awards..."
      additional={{
        page: 1,
      }}
      defaultValue={value?.length ? getNormalValues(value) : value}
      loadOptions={loadWineclubs}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

AwardPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  value: PropTypes.any,
  parameters: PropTypes.object,
};

export default AwardPaginatedField;
