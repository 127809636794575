import { createSelector } from 'reselect';

export const rootSelector = state => state.emailTemplates;

export const emailTemplatesSelector = createSelector(
  rootSelector,
  ({ emailTemplates }) => emailTemplates
);

export const currentEmailTemplateSelector = createSelector(
  rootSelector,
  ({ currentEmailTemplate }) => currentEmailTemplate
);

export const emailTemplatesPaginationSelector = createSelector(
  rootSelector,
  ({ emailTemplatesPagination }) => emailTemplatesPagination
);

export const emailTemplatesPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const previewLayoutSelector = createSelector(
  rootSelector,
  ({ previewLayout }) => previewLayout
);

export const previewLayoutPendingSelector = createSelector(
  rootSelector,
  ({ previewLayoutPending }) => previewLayoutPending
);

export const previewLayoutModalSelector = createSelector(
  rootSelector,
  ({ previewLayoutModal }) => previewLayoutModal
);
