import { createSlice } from 'redux-starter-kit';

import actionTypes from 'utils/actionTypes';

const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState: {
    isPending: false,
    isWarehousePending: false,
    warehouses: [],
    warehouse: {},
    currentWarehouse: null,
    pagination: {},
    warehouseInventoriesLastFetched: null,
    warehouseInventories: [],
    warehouseInventoriesPagination: null,
    warehouseInventoriesPending: false,
    inventoryLimits: [],
    inventoryLimitsPagination: null,
    inventoryLimitsPending: false,
    reportsInventory: [],
    reportsInventoryPending: false,
    reportsInventoryPagination: null,
    reportsInventoryTotalBottles: null,
  },
  reducers: {
    resetWarehouse: state => {
      state.warehouse = {};
    },
    fetchWarehouses: state => {
      state.isPending = true;
    },
    fetchWarehousesSuccess(state, { payload }) {
      state.isPending = false;
      state.warehouses = payload.data.results;
      // eslint-disable-next-line prefer-destructuring
      state.warehouse = payload.data.results[0];
      state.pagination = payload.data.pagination;
    },
    fetchWarehousesFailure: state => {
      state.isPending = false;
    },
    fetchSenderWarehouses: state => {
      state.isPending = true;
    },
    fetchSenderWarehousesSuccess(state, { payload }) {
      state.isPending = false;
      state.warehouses = payload.data.results;
    },
    fetchSenderWarehousesFailure: state => {
      state.isPending = false;
    },
    fetchWarehouse: state => {
      state.isWarehousePending = true;
    },
    fetchWarehouseSuccess(state, { payload }) {
      state.warehouse = payload.data;
      state.isWarehousePending = false;
    },
    fetchWarehouseFailure: state => {
      state.isWarehousePending = false;
    },
    createWarehouse: state => {
      state.isPending = true;
    },
    createWarehouseSuccess(state, { payload }) {
      state.warehouses = state.warehouses.concat(payload.data);
      state.isPending = false;
    },
    createWarehouseFailure: state => {
      state.isPending = false;
    },
    deleteWarehouse: state => {
      state.isPending = true;
    },
    deleteWarehouseSuccess(state, { payload }) {
      state.warehouses = state.warehouses.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteWarehouseFailure: state => {
      state.isPending = false;
    },
    updateWarehouse: state => {
      state.isPending = true;
    },
    updateWarehouseSuccess(state, { payload }) {
      state.warehouses.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    updateWarehouseFailure: state => {
      state.isPending = false;
    },

    fetchWarehouseInventories: state => {
      state.warehouseInventoriesPending = true;
    },
    fetchWarehouseInventoriesSuccess: (state, { payload }) => {
      const { pagination, results, last_fetched_at } = payload.data;
      state.warehouseInventoriesLastFetched = last_fetched_at;
      state.warehouseInventories = results;
      state.warehouseInventoriesPagination = pagination;
      state.warehouseInventoriesPending = false;
    },
    fetchWarehouseInventoriesFailure: state => {
      state.warehouseInventoriesPending = false;
    },

    updateContainerWorldInventoryStatus: state => {
      state.warehouseInventoriesPending = true;
    },
    updateContainerWorldInventoryStatusSuccess: (state, { payload }) => {
      const { results, pagination, last_fetched_at } = payload.data;
      state.warehouseInventoriesLastFetched = last_fetched_at;
      state.warehouseInventories = results;
      state.warehouseInventoriesPagination = pagination;
      state.warehouseInventoriesPending = false;
    },
    updateContainerWorldInventoryStatusFailure: state => {
      state.warehouseInventoriesPending = false;
    },

    fetchInventoryLimits: state => {
      state.inventoryLimitsPending = true;
    },
    fetchInventoryLimitsSuccess: (state, { payload }) => {
      const { results, pagination } = payload.data;
      state.inventoryLimits = results;
      state.inventoryLimitsPagination = pagination;
      state.inventoryLimitsPending = false;
    },
    fetchInventoryLimitsFailure: state => {
      state.inventoryLimitsPending = false;
    },

    fetchReportsInventory: state => {
      state.reportsInventoryPending = true;
    },
    fetchReportsInventorySuccess: (state, { payload }) => {
      const { results, total_bottles_count } = payload.data;
      state.reportsInventory = results;
      state.reportsInventoryTotalBottles = total_bottles_count;
      state.reportsInventoryPending = false;
    },
    fetchReportsInventoryFailure: state => {
      state.reportsInventoryPending = false;
    },
  },
});

export const actions = actionTypes(warehousesSlice.actions);

export default warehousesSlice.reducer;
