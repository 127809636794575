import getUID from 'utils/getUID';
import { ORDER_TYPES_ITEMS } from 'constants';

export const formatFees = (model, product_class) => {
  if (model?.warehouses_fees) {
    return model?.warehouses_fees
      .filter(fee => fee.product_class === product_class)
      .map(item => ({
        id: getUID(),
        title: item.title,
        alias: item.alias,
        amount: {
          value: Number(item.amount),
          units: item.units,
        },
        order_class: {
          apiValue: item.order_class,
          userValue: ORDER_TYPES_ITEMS[item.order_class],
        },
        order_item_class: {
          apiValue: item.order_item_class,
          userValue: ORDER_TYPES_ITEMS[item.order_item_class],
        },
      }));
  }
  return [];
};
