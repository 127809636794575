import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

const DropdownItemId = Object.freeze({
  EDIT: 'edit',
});

const LeadsActionDropdown = ({ order }) => {
  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/lead/${order.id}/edit`,
    },
  ];

  return <ActionDropdown items={dropdownItem} right />;
};

LeadsActionDropdown.propTypes = {
  order: PropTypes.object.isRequired,
};

export default LeadsActionDropdown;
