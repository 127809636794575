import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as wineclubsActions } from 'models/wineclubs/slice';
import useSelector from 'hooks/useSelector';
import {
  wineclubsSelector,
  isPendingSelector as wineclubsIsPendingSelector,
  paginationSelector,
} from 'models/wineclubs/selectors';
import { isPendingSelector as brandsIsPendingSelector } from 'models/brands/selectors';

import DataTable from './DataTable';

const WineclubSummary = ({ title }) => {
  const fetchWineclubs = useAction(wineclubsActions.fetchWineclubs);
  const wineclubs = useSelector(wineclubsSelector);
  const brandsIsPending = useSelector(brandsIsPendingSelector);
  const wineclubsIsPending = useSelector(wineclubsIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const isPending = wineclubsIsPending || brandsIsPending;

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    setFilters({ ...filters, ...result });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchWineclubs({
        ...filters,
        page: currentPage,
        content_contains: filters.title_contains,
      });
    }
  }, [fetchWineclubs, currentPage, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasTextSearch
                    hasPerPageFilter
                    setCurrentPage={setCurrentPage}
                    createItemLink="/wineclub/create"
                    disabled={isPending}
                    placeholder="Search... (By wineclub name or description)"
                  />
                </Col>
              </Row>
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable isPending={isPending} wineclubs={wineclubs} />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

WineclubSummary.propTypes = {
  title: PropTypes.string,
};

export default WineclubSummary;
