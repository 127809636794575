import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Gallery.scss';
import Preloader from 'components/Preloader/Preloader';
import Pagination from 'components/Pagination';
import Filters from 'components/Filters';
import Item from './Item';

const Gallery = ({
  filters,
  images,
  selectedImages,
  pagination,
  isPending,
  setCurrentPage,
  setFilters,
  onImageClick,
}) => {
  if (isPending) {
    return <Preloader className={styles.preloader} />;
  }

  return (
    <div className={styles.root}>
      <Filters
        filters={filters}
        setFilters={setFilters}
        hasTextSearch
        disabled={isPending}
      />
      <div className={styles.gallery}>
        {images?.length ? (
          <>
            {images.map(item => (
              <Item
                item={item}
                isSelected={selectedImages?.includes(item.id)}
                onImageClick={onImageClick}
              />
            ))}
          </>
        ) : (
          <div className={styles.message}>There are no images.</div>
        )}
      </div>
      {pagination && pagination.total_pages > 1 && (
        <Pagination onClick={setCurrentPage} pagination={pagination} />
      )}
    </div>
  );
};

Gallery.propTypes = {
  filters: PropTypes.array,
  images: PropTypes.array,
  selectedImages: PropTypes.array,
  isPending: PropTypes.bool,
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
  setFilters: PropTypes.func,
  onImageClick: PropTypes.func,
};

export default memo(Gallery);
