import { createSelector } from 'reselect';

export const rootSelector = state => state.images;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const imagesSelector = createSelector(
  rootSelector,
  ({ images }) => images
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const brandIdSelector = createSelector(
  rootSelector,
  ({ brandId }) => brandId
);

export const shouldFormBeClearedSelector = createSelector(
  rootSelector,
  ({ shouldFormBeCleared }) => shouldFormBeCleared
);
