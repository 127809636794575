import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import FeeTable from './FeeTable';
import { FEE_TYPES } from 'constants';
import PropTypes from 'prop-types';

import s from './FeeLayout.scss';

const FeeLayout = ({ fees }) => {
  const [type, setType] = useState(FEE_TYPES.Wine);
  const [data, setData] = useState({});

  useEffect(() => {
    if (fees) {
      setData(
        fees.reduce((result, current) => {
          if (result[current.product_class]) {
            result[current.product_class].push(current);
          } else {
            result[current.product_class] = [current];
          }
          return result;
        }, {})
      );
    }
  }, [fees]);

  return (
    <div className={s.root}>
      <Row>
        <Col md={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            {Object.entries(FEE_TYPES).map(([key, value]) => (
              <NavItem onClick={() => setType(value)} key={key}>
                <NavLink
                  active={value === type}
                  className="font-weight-bold p-3"
                >
                  {key}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FeeTable data={data[type]} />
        </Col>
      </Row>
    </div>
  );
};

FeeLayout.propTypes = {
  fees: PropTypes.array,
};

export default FeeLayout;
