import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Simple bar
import SimpleBar from 'simplebar-react';

import SidebarContent from './SidebarContent';
import SidebarAlert from 'components/SidebarAlert/SidebarAlert';

const Sidebar = ({ isCondensed }) => (
  <>
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        {!isCondensed ? (
          <SimpleBar style={{ maxHeight: '100%' }}>
            <SidebarAlert />
            <SidebarContent isCondensed={isCondensed} />
          </SimpleBar>
        ) : (
          <SidebarContent isCondensed={isCondensed} />
        )}
      </div>
    </div>
  </>
);

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};

Sidebar.propTypes = {
  isCondensed: PropTypes.bool,
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
