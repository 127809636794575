import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { formatDate } from 'utils/formatDate';
import { projects } from 'constants/projects';

const Main = ({ code }) => {
  return (
    <>
      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Title</h4>
              <div className="mt-4">{code?.title}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Brand</h4>
              <div className="mt-4">
                <Link to={`/brands/${code?.brand?.id}/edit`}>
                  {code?.brand?.title}
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Description</h4>
              <div className="mt-4">{code?.description}</div>
            </CardBody>
          </Card>
        </Col>
        {code?.coupon && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Coupon</h4>
                <div className="mt-4">{code?.coupon}</div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Discount Rate</h4>
              <div className="mt-4">{code?.percentage_amount}%</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Minimum Spend Amount</h4>
              <div className="mt-4">{code?.minimum_spend_amount ?? '-'}</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {code?.warehouses.length > 0 && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Warehouses</h4>
                <div className="mt-4">
                  {code?.warehouses.map(warehouse => (
                    <p key={warehouse.id}>{warehouse.title}</p>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {code?.included_categories.length > 0 && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Included Categories</h4>
                <div className="mt-4">
                  {code?.included_categories.map(category => (
                    <p key={category.id}>{category.title}</p>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {code?.excluded_categories.length > 0 && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Excluded Categories</h4>
                <div className="mt-4">
                  <ul>
                    {code?.excluded_categories.map(category => (
                      <p key={category.id}>{category.title}</p>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {code?.included_skus.length > 0 && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Included SKUs</h4>
                <div className="mt-4">
                  {code?.included_skus.map(sku => (
                    <p key={sku.id}>{sku.title}</p>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {code?.excluded_skus.length > 0 && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Excluded SKUs</h4>
                <div className="mt-4">
                  {code?.excluded_skus.map(sku => (
                    <p key={sku.id}>{sku.title}</p>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        {code?.coupon && (
          <Col lg="4">
            <Card>
              <CardBody>
                <h4 className="card-title">Where Code Can Be Applied</h4>
                <div className="mt-4">
                  {
                    (
                      projects?.find(
                        _project =>
                          _project?.value === code?.redeemable_location
                      ) || projects[0]
                    )?.label
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        {code?.coupon && (
          <>
            <Col lg="4">
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    Use once per customer (Does not limit usage by Guests)
                  </h4>
                  <div className="mt-4">{code?.use_once ? 'Yes' : 'No'}</div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="4">
              <Card>
                <CardBody>
                  <h4 className="card-title">Specific Customer</h4>
                  <div className="mt-4">
                    {code?.customer?.id ? (
                      <Link to={`/wineclub-customers/${code.customer.id}`}>
                        {code.customer.first_name} {code.customer.last_name}
                      </Link>
                    ) : (
                      'All customers'
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Start Date</h4>
              <div className="mt-4">
                {code?.promotion_start_date
                  ? formatDate(code?.promotion_start_date, 'yyyy-MM-dd')
                  : '-'}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">End Date</h4>
              <div className="mt-4">
                {code?.promotion_end_date
                  ? formatDate(code?.promotion_end_date, 'yyyy-MM-dd')
                  : '-'}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Created At</h4>
              <div className="mt-4">{formatDate(code?.created_at)}</div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Updated At</h4>
              <div className="mt-4">{formatDate(code?.updated_at)}</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

Main.propTypes = {
  code: PropTypes.object,
};

export default Main;
