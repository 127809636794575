import React, { useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import { Card, CardBody, Col, Row, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { actions } from 'models/inventories/slice';
import useAction from 'hooks/useAction';
import { lowInventorySelector } from 'models/inventories/selectors';
import useSelector from 'hooks/useSelector';
import LowInventorySummaryTable from 'components/LowInventorySummaryTable';
import { Link } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

const LowInventorySummary = ({ title }) => {
  const lowInventory = useSelector(lowInventorySelector);
  const fetchLowInventory = useAction(actions.fetchLowInventory);
  useEffect(() => {
    fetchLowInventory();
  }, []);

  const warehouses = uniqBy(
    lowInventory?.map(item => ({
      warehouse_id: item.warehouse_id,
      warehouse_title: item.warehouse_title,
    })),
    'warehouse_id'
  );

  const isLowInventory = Boolean(lowInventory?.length > 0);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col>
          <Alert color="info">
            <i className="mdi mdi-information" />{' '}
            <b>
              Please note that low inventory counts may be delayed by up to 24
              hours.
            </b>
            <br />
            If you would like to change the Inventory Notification Limits for
            any warehouse, find the appropriate{' '}
            <Link to="/warehouses/">warehouse</Link> and edit it.
          </Alert>
          {!isLowInventory && (
            <Alert color="success">All inventory is above set limits.</Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {warehouses.map(warehouse => [
            <h5>{warehouse.warehouse_title}</h5>,
            <Card>
              <CardBody>
                <LowInventorySummaryTable
                  data={lowInventory.filter(
                    item => item.warehouse_id === warehouse.warehouse_id
                  )}
                />
              </CardBody>
            </Card>,
          ])}
        </Col>
      </Row>
    </PageWrapper>
  );
};

LowInventorySummary.propTypes = {
  title: PropTypes.string.isRequired,
};
export default LowInventorySummary;
