import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const BodyModal = ({ title, children, setIsOpen, footer }) => (
  <>
    {!!title && (
      <ModalHeader toggle={() => setIsOpen(null)}>{title}</ModalHeader>
    )}
    <ModalBody>{children}</ModalBody>
    {!!footer && <ModalFooter>{footer}</ModalFooter>}
  </>
);

BodyModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  setIsOpen: PropTypes.func,
  footer: PropTypes.element,
};

export default memo(BodyModal);
