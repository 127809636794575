import { useState } from 'react';
import { format } from 'date-fns';

export const useDateRange = () => {
  const [dateRange, setDateRange] = useState({});

  const getStartDate = date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getEndDate = date => {
    date.setHours(23, 59, 59, 999);
    return date;
  };

  const onDateChangeHandler = value => {
    setDateRange({
      startDate: format(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: format(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  return {
    dateRange,
    setDateRange,
    onDateChangeHandler,
  };
};
