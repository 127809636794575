import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'reactstrap';
import CardItem from 'components/CardItem';

import styles from './InformationWineDirect.scss';
import { INFORMATION_WINE_DIRECT } from './constants';
import classNames from 'classnames';

const InformationWineDirect = ({ title, importeData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.block}>
      <button className={styles.button} onClick={toggleOpenHandler}>
        <i
          className={classNames(
            styles.arrow,
            isOpen && 'ri-arrow-down-s-fill',
            !isOpen && 'ri-arrow-right-s-fill'
          )}
        />
        Information from WineDirect - {title}
      </button>
      <Collapse isOpen={isOpen}>
        <Row>
          {(importeData || []).map(
            data =>
              INFORMATION_WINE_DIRECT[data.key] && (
                <Col lg="3" key={data.key}>
                  <CardItem
                    title={INFORMATION_WINE_DIRECT[data.key]}
                    value={
                      typeof data.value === 'number'
                        ? String(data.value)
                        : data.value
                    }
                  />
                </Col>
              )
          )}
        </Row>
      </Collapse>
    </div>
  );
};

InformationWineDirect.propTypes = {
  title: PropTypes.string,
  importeData: PropTypes.array,
};

export default InformationWineDirect;
