import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const initialState = {
  filters: [],
  isPending: false,
};

const mailingListsSlice = createSlice({
  name: 'mailingLists',
  initialState,
  reducers: {
    fetchFilters: state => {
      state.isPending = true;
    },
    fetchFiltersSuccess: (state, { payload }) => {
      state.isPending = false;
      state.filters = payload.data;
    },
    fetchFiltersFailure: state => {
      state.isPending = false;
    },
    fetchFile: state => {
      state.isPending = true;
    },
    fetchFileSuccess: state => {
      state.isPending = false;
    },
    fetchFileFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(mailingListsSlice.actions);

export default mailingListsSlice.reducer;
