export const getBaseData = item => {
  return {
    ...item,
    bottles_in_case: item.bottles_in_case,
    cases_in_pallet: item.cases_in_pallet,
    product_id: item.product_id,
  };
};

export const normalizeTableData = data =>
  data.reduce((acc, value) => {
    acc[value.product_id] = {
      ...value,
      bottles_count: '',
      cases_count: '',
      pallets_count: '',
      product_count: '',
    };

    return acc;
  }, {});

export const resetToBaseData = data => {
  const resetData = normalizeTableData(data);
  localStorage.setItem('inventory_reports', JSON.stringify(resetData));
  return resetData;
};

export const reorganizeTableData = (oldData, data) =>
  data.reduce((acc, value) => {
    if (!acc[value.product_id]) {
      acc[value.product_id] = getBaseData(value);
    }
    return acc;
  }, oldData);
