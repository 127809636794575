export const PERMISSION_TYPES = Object.freeze({
  HOST: 'host',
  OWNER: 'owner',
  MANAGER: 'manager',
  STAFF: 'staff',
  BOOKKEEPER: 'bookkeeper',
  SALES_REP: 'sales_rep',
  SHARED_CUSTOMER: 'shared_customer',
});

export const PERMISSION_MAIN_LINKS = Object.freeze({
  [PERMISSION_TYPES.HOST]: '/inventory-count/',
  [PERMISSION_TYPES.OWNER]: '/products/?',
  [PERMISSION_TYPES.MANAGER]: '/products/?',
});

export const WORKSPACE_SETTING_TYPES = Object.freeze({
  ENABLE_COMMERCE_SEVEN: 'enable_commerce_seven',
  ENABLE_PRODUCT_MOVEMENT_REPORT: 'enable_product_movement_report',
});
