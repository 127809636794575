import React, { memo } from 'react';
import styles from './TransferPopup.scss';
import PopupBase from '../PopupBase';
import PropTypes from 'prop-types';
import { ModalHeader, Button } from 'reactstrap';
import TextareaField from '../../Fields/TextareaField/TextareaField';
import classNames from 'classnames';
import { AvForm } from 'availity-reactstrap-validation';

const TransferPopup = ({ active, setActive, handleSubmit, heading }) => {
  const toggleModal = () => {
    setActive(!active);
  };
  const isCommentRequired =
    heading === 'Dispute Transfer' || heading === 'Resubmit Transfer';

  return (
    <PopupBase className={styles.popupContainer} onclose={toggleModal}>
      <ModalHeader toggle={toggleModal} className="border-0">
        {heading}
      </ModalHeader>
      <div className={styles.container}>
        <h6 className={styles.subheading}>
          Comment {isCommentRequired ? '(required)' : '(optional)'}
        </h6>
        <AvForm
          className="needs-validation"
          onValidSubmit={(event, values) => handleSubmit(values)}
        >
          <TextareaField
            name="comment"
            placeholder=""
            errorMessage="Enter note"
            className={classNames(styles.textarea, 'form-control')}
            isRequired={isCommentRequired}
          />
          <Button color="primary" type="submit">
            Confirm
          </Button>
        </AvForm>
      </div>
    </PopupBase>
  );
};

TransferPopup.propTypes = {
  handleSubmit: PropTypes.func,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  heading: PropTypes.string,
};
export default memo(TransferPopup);
