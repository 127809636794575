import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SelectionPopup from '../SelectionPopup';
import Shipments from 'components/Shipments';

const SelectShippingPopup = ({
  setIsChargeMode,
  captureSubscriptionOrder,
  isChargeMode,
  isOpen,
  setIsOpen,
  withFooter,
  order,
  isOnlyShipping,
  withTotal,
}) => {
  return (
    <SelectionPopup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      withFooter={withFooter}
      title="Select shipping option"
    >
      <Shipments
        order={order}
        withWhitePreloader
        withTotal={withTotal}
        buttonTitle={isChargeMode ? 'Charge' : 'Attach'}
        nextAction={() => {
          if (isChargeMode) {
            setIsChargeMode(false);
            captureSubscriptionOrder({
              orderId: order?.id,
              force_capture: false,
            });
          }
          setIsOpen(false);
        }}
        isOnlyShipping={isOnlyShipping}
      />
    </SelectionPopup>
  );
};

SelectShippingPopup.propTypes = {
  setIsChargeMode: PropTypes.func,
  captureSubscriptionOrder: PropTypes.func,
  isChargeMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  withFooter: PropTypes.bool,
  order: PropTypes.object,
  isOnlyShipping: PropTypes.bool,
  withTotal: PropTypes.bool,
};

SelectShippingPopup.defaultProps = {
  isOnlyShipping: false,
  withTotal: true,
};

export default memo(SelectShippingPopup);
