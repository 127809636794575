import React, { useEffect, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap';
import { useParams } from 'react-router-dom';

import useSelector from 'hooks/useSelector';

import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Form from 'components/BrandsForm';
import CompaniesForm from 'components/BrandsCompaniesForm';
import DataSourcesForm from 'components/BrandsDataSourcesForm';

import useAction from 'hooks/useAction';
import { actions as brandsActions } from 'models/brands/slice';
import { actions as tagsActions } from 'models/tags/slice';
import { actions as brandsCompaniesActions } from 'models/brandsCompanies/slice';
import { actions as brandsDataSourcesActions } from 'models/brandsDataSources/slice';
import { brandSelector, isPendingSelector } from 'models/brands/selectors';
import BrandsContainerWorldForm from 'components/BrandsContainerWorldForm';
import { getRedactedValues } from 'utils/getRedactedValues';
import { isEmpty } from 'lodash';
import { tagsSelector } from 'models/tags/selectors';

import { paginationSelector } from 'models/brandsCompanies/selectors';
import { failedWineDirectSyncsSelector } from '../../models/wineDirectSync/selectors';

const EditProduct = ({ title }) => {
  const { brandID, selectedTab } = useParams();
  const brand = useSelector(brandSelector);
  const isPending = useSelector(isPendingSelector);
  const updateBrand = useAction(brandsActions.updateBrand);
  const tags = useSelector(tagsSelector);
  const fetchDataSources = useAction(brandsDataSourcesActions.fetchDataSources);
  const fetchTags = useAction(tagsActions.fetchTags);
  const fetchBrand = useAction(brandsActions.fetchBrand);
  const fetchCompanies = useAction(brandsCompaniesActions.fetchCompanies);
  const fetchBrandsCompanies = useAction(
    brandsCompaniesActions.fetchBrandsCompanies
  );
  const failedWineDirectSyncs = useSelector(failedWineDirectSyncsSelector);

  const [dashboardLogo, setDashboardLogo] = useState();
  const [mobileLogo, setMobileLogo] = useState();
  const [emailLogo, setEmailLogo] = useState();
  const [selectedTags, setSelectedTags] = useState({});

  const pagination = useSelector(paginationSelector);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page);

  useEffect(() => {
    setDashboardLogo(brand?.dashboard_logo_url);
    setMobileLogo(brand?.mobile_logo_url);
    setEmailLogo(brand?.email_logo_url);
  }, [brand]);

  useEffect(() => {
    fetchCompanies();
    fetchDataSources({ brandId: brandID });
    fetchBrandsCompanies({
      per_page: 10,
      page: currentPage,
      brand_id: brandID,
    });
  }, [
    fetchCompanies,
    fetchBrandsCompanies,
    fetchDataSources,
    brandID,
    currentPage,
  ]);

  useEffect(() => {
    fetchTags({ brandId: brandID });
  }, []);

  const [activeTab, setActiveTab] = useState(selectedTab || '1');
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(selectedTab || '1');
  }, [selectedTab]);

  useEffect(() => {
    fetchBrand(brandID);
  }, [fetchBrand, brandID]);

  useEffect(() => {
    if (brand && tags) {
      const activeTags =
        brand?.displayed_on_site_products_tags?.split(',') ?? [];
      setSelectedTags(
        tags.reduce(
          (previous, current) => ({
            ...previous,
            [current.name]: activeTags.includes(current.name),
          }),
          {}
        )
      );
    }
  }, [brand, tags]);

  const handleSubmit = (_, data) => {
    const logos = {};
    if (dashboardLogo !== brand?.dashboard_logo_url) {
      logos.dashboard_logo = dashboardLogo;
    }
    if (mobileLogo !== brand?.mobile_logo_url) {
      logos.mobile_logo = mobileLogo;
    }
    if (emailLogo !== brand?.email_logo_url) {
      logos.email_logo = emailLogo;
    }
    const displayed_on_site_products_tags = Object.keys(selectedTags)
      .filter(key => selectedTags[key])
      ?.join(',');
    updateBrand({
      id: brandID,
      ...getRedactedValues(brand, {
        ...data,
        displayed_on_site_products_tags,
        ...logos,
      }),
      redirectUrl: '/brands',
    });
  };

  const handleContainerWorldSubmit = data => {
    updateBrand({
      id: brandID,
      ...data,
    });
  };

  const failedSyncExists = failedWineDirectSyncs?.brands_with_failed_syncs?.includes(
    parseInt(brandID, 10)
  );

  const failedEntitiesExistForBrand = failedWineDirectSyncs?.brands_with_failed_entities?.includes(
    parseInt(brandID, 10)
  );

  return (
    <PageWrapper title={title}>
      {isPending && <Preloader />}
      {failedSyncExists && (
        <Alert color="danger">
          <i className="mdi mdi-information" />
          <b>WineDirect sync failure</b>
          <br />
          Orders may have been placed online that have not been imported into
          Cellar. If this persists contact Cellar support for assistance.
        </Alert>
      )}
      {failedEntitiesExistForBrand && !failedSyncExists && (
        <Alert color="warning">
          <i className="mdi mdi-information" />
          <b>WineDirect sync failure for some entities</b>
          <br />
          There are customers, products or orders that have not been imported
          into Cellar. If this persists contact Cellar support for assistance.
        </Alert>
      )}
      <Breadcrumbs
        title={brand?.title}
        breadcrumbItems={[
          { title: 'Back to brands', link: '/brands/', withSearch: true },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '1',
                })}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '2',
                })}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                Shipping
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '3',
                })}
                onClick={() => {
                  toggleTab('3');
                }}
              >
                Wine Direct
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx('font-weight-bold p-3', {
                  active: activeTab === '4',
                })}
                onClick={() => {
                  toggleTab('4');
                }}
              >
                Container World
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            {!isEmpty(brand) && (
              <Col md="12">
                <AvForm
                  model={brand}
                  className="needs-validation"
                  onValidSubmit={handleSubmit}
                >
                  <Form
                    mobileLogo={mobileLogo}
                    dashboardLogo={dashboardLogo}
                    emailLogo={emailLogo}
                    disabled={isPending}
                    submitTitle="Save"
                    model={brand}
                    tags={selectedTags}
                    setTags={setSelectedTags}
                    onMobileLogoChange={setMobileLogo}
                    onDashboardLogoChange={setDashboardLogo}
                    onEmailLogoChange={setEmailLogo}
                  />
                </AvForm>
              </Col>
            )}
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col md="12">
              <CompaniesForm
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col md="12">
              <DataSourcesForm />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            {!isEmpty(brand) && (
              <Col md="12">
                <BrandsContainerWorldForm
                  defaultValues={brand}
                  onSubmit={handleContainerWorldSubmit}
                />
              </Col>
            )}
          </Row>
        </TabPane>
      </TabContent>
    </PageWrapper>
  );
};

EditProduct.propTypes = {
  title: PropTypes.string,
};

export default EditProduct;
