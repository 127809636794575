import { COMPANY_TYPES } from 'constants';
import { PERIOD_OPTIONS } from 'constants/commissions';

export const getCommissionInitialData = sourceData => {
  const initialRates = sourceData
    ? sourceData.sales_rep_commission_rates
    : null;
  const initialPeriod = sourceData
    ? PERIOD_OPTIONS.find(
        periodItem =>
          periodItem.value === sourceData.sales_rep_commission_period
      )
    : PERIOD_OPTIONS[0];
  const rates = COMPANY_TYPES.reduce((previous, current) => {
    const companyType = current.toLowerCase();
    return {
      ...previous,
      [companyType]: initialRates ? initialRates?.[companyType] : null,
    };
  }, {});
  return {
    sales_rep_commission_rates: rates,
    sales_rep_commission_period: initialPeriod,
  };
};

export const getPreparedCommissions = commissions => {
  const ratesValue = commissions?.sales_rep_commission_rates ?? {};
  const periodValue = commissions?.sales_rep_commission_period;

  return {
    sales_rep_commission_period: periodValue?.value,
    sales_rep_commission_rates: Object.entries(ratesValue).reduce(
      (previousValue, currentValue) => {
        const [key, value] = currentValue;
        return {
          ...previousValue,
          [key]: value ?? 0,
        };
      },
      {}
    ),
  };
};
