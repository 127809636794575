import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import Form from 'components/WarehousesForm';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as warehousesActions } from 'models/warehouses/slice';

import { isPendingSelector as warehouseIsPendingSelector } from 'models/warehouses/selectors';

const CreateWarehouse = ({ title }) => {
  const createWarehouse = useAction(warehousesActions.createWarehouse);
  const isPending = useSelector(warehouseIsPendingSelector);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new building"
        breadcrumbItems={[
          {
            title: 'Back to warehouses',
            link: '/warehouses/',
            withSearch: true,
          },
        ]}
      />
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs-simple mb-4">
            <NavItem>
              <NavLink className="active font-weight-bold p-3">Create</NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form
            disabled={isPending}
            submitTitle="Save"
            onSubmit={createWarehouse}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

CreateWarehouse.propTypes = {
  title: PropTypes.string,
};

export default CreateWarehouse;
