import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { selectStyles } from 'constants/selectStyles';
import { getNormalText } from 'utils/getNormalText';
import { emailTemplateTypeName } from 'constants/emailTemplates';

const initialLabel = {
  label: 'All templates',
  value: undefined,
};

const EmailTemplatePaginatedField = ({
  onChange,
  isMulti,
  value,
  isInitialLabel,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchEmailTemplates = ({ page = 1, search }) => {
    return apiRequest({
      url: `/email_templates?page=${page}&title_contains=${search}&email_template_type=Wineclub`,
    });
  };

  const loadEmailTemplates = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchEmailTemplates({ page, search });
      const { results, pagination } = response;
      const preparedResult = results.map(item => ({
        ...item,
        label:
          emailTemplateTypeName?.[item?.title] ?? getNormalText(item?.title),
        value: item?.id,
      }));
      return {
        options:
          isInitialLabel && page === 1
            ? [initialLabel, ...preparedResult]
            : preparedResult,
        hasMore: pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="email_template"
      placeholder="Select template..."
      additional={{
        page: 1,
      }}
      loadOptions={loadEmailTemplates}
      value={isInitialLabel && !value ? initialLabel : value}
      onChange={onChange}
      isMulti={isMulti}
      styles={selectStyles}
    />
  );
};

EmailTemplatePaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  value: PropTypes.object,
  isMulti: PropTypes.bool,
};

export default EmailTemplatePaginatedField;
