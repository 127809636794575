import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Label } from 'reactstrap';
import PopupBase from 'components/Popup/PopupBase';
import styles from './SubscriptionCancelPopup.scss';
import classNames from 'classnames';

const SubscriptionCancelPopup = ({ onClose, onSubmit }) => {
  const [reason, setReason] = useState('');
  const isButtonDisabled = reason.trim().length > 1024;

  return (
    <PopupBase onClose={onClose}>
      <div className={styles.container}>
        <Col>
          <Label htmlFor="reason" className={classNames('mb-2', styles.label)}>
            Reason for cancellation of subscription
          </Label>
          <div className={styles.textAreaWrapper}>
            <Input
              type="textarea"
              rows="5"
              resize
              id="reason"
              style={{ resize: 'none' }}
              name="reason"
              onChange={evt => {
                setReason(evt.target.value);
              }}
            />
          </div>

          <Button
            type="button"
            color="success"
            title="Confirm"
            className="mt-3"
            disabled={isButtonDisabled}
            onClick={() => onSubmit(reason.trim())}
          >
            Confirm
          </Button>
        </Col>
      </div>
    </PopupBase>
  );
};

SubscriptionCancelPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default memo(SubscriptionCancelPopup);
