import React, { memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FieldType } from '../types.d';

const DateField = ({
  name,
  label,
  rules,
  isRequired,
  onChange,
  errorMessage,
  className,
  ...props
}) => {
  return (
    <FormGroup className={className}>
      {!!label && (
        <Label required={isRequired} htmlFor={name}>
          {label}
        </Label>
      )}
      <AvField
        name={name}
        type="date"
        validate={{
          date: {
            format: 'yyyy-mm-dd',
            errorMessage: 'Date format should be yyyy-mm-dd',
          },
          required: { value: !!isRequired },
          ...rules,
        }}
        error
        errorMessage={errorMessage}
        onChange={event => onChange && onChange(event)}
        {...props}
      />
    </FormGroup>
  );
};

DateField.propTypes = FieldType;

export default memo(DateField);
